import discoverabilityDefinitions from "./DiscoverabilityDefinitions";
import scoreMetaDefinitions from './score/ScoreMetaDefinitions';
import rubricStateDefinitions from './EditabilityDefinitions';
import rubricUtil from './RubricUtil';
import session from '../auth/Session';
import util from '../../util/Util';
import Definition from './definition/Definition';
import Statement from './definition/Statement';
import I18N from '../i18n/I18N';

export default class RubricDefinitionBuilder {

  rows: any[];
  definition: Definition;
  statementBuilder: undefined | Function;
  guidanceBuilder: undefined | Function;

  constructor() {
    this.rows = [];
    const name = 'New - ' + util.formatDate(new Date());
    const nameLower = name.toLowerCase();
    const ownerEmailDomain = '';
    this.definition = {
      uuid: util.uuid(),
      editability: rubricStateDefinitions.fullEditabilityType,
      name: name,
      nameLower: nameLower,
      aspectHeaderName: I18N.Area,
      description: '',
      ownerId: '',
      ownerEmailDomain: ownerEmailDomain,
      allowForking: true,
      discoverability: discoverabilityDefinitions.publicDiscoverabilityType,
      labels: [],
      statementUuidsToLabels: {},
      options: {
        hideCompletedAspects: false,
        hideZeroScores: true,
        hideColumnTotals: false,
        scoreMetaType: scoreMetaDefinitions.standardScoreMetaType
      },
      metadata: {},
      style: {
        borderColor: '#CCC',
        hoverColor: '#F9F9F9'
      },
      columns: [],
      groups: [{
        rows: this.rows
      }],
      updateTimestamp: new Date().getTime()
    };
  }

  setUuid = (uuid) => {
    this.definition.uuid = uuid;
    return this;
  };

  setEditability = (editability) => {
    this.definition.editability = editability;
    return this;
  };

  setName = (name) => {
    this.definition.name = name;
    return this;
  };

  setAspectHeaderName = (aspectHeaderName) => {
    this.definition.aspectHeaderName = aspectHeaderName;
    return this;
  };

  setDescription = (description) => {
    this.definition.description = description;
    return this;
  };

  addDescriptionText = (text) => {
    this.definition.description += text;
    return this;
  };

  addDescriptionTextBreak = () => {
    this.definition.description += '\n\n';
    return this;
  };

  // setOwnerId = (ownerId) => {
  //   this.definition.ownerId = ownerId;
  //   return this;
  // };

  setOwner = (owner) => {
    const emailDomain = owner.getEmailDomain();
    this.definition.ownerId = owner.getId();
    this.definition.ownerEmailDomain = emailDomain
    return this;
  };

  setAllowForking = (allowForking) => {
    this.definition.allowForking = allowForking;
    return this;
  };

  setDiscoverability = (discoverability) => {
    this.definition.discoverability = discoverability;
    return this;
  };

  setLabels = (labels) => {
    this.definition.labels = labels;
    return this;
  };

  setColumnNames = (columnNames) => {
    if (this.rows.length) {
      alert('Columns must be defined before adding rows');
    }
    this.definition.columns = [];
    for (let i = 0; i < columnNames.length; i++) {
      const columnName = columnNames[i];
      const column = new ColumnBuilder()
        .setName(columnName)
        .build();
      this.definition.columns.push(column);
    }
    return this;
  };

  setRowNames = (rowNames) => {
    for (let rowIndex = 0; rowIndex < rowNames.length; rowIndex++) {
      const rowName = rowNames[rowIndex];
      this._createAndAddRow(rowName, rowIndex);
    }
    return this;
  };

  setRowCount = (rowCount) => {
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      const rowName = 'Area ' + (rowIndex + 1);
      this._createAndAddRow(rowName, rowIndex);
    }
    return this;
  };

  addColumn = (column) => {
    this.definition.columns.push(column);
    return this;
  };

  addRow = (row) => {
    this.rows.push(row);
    return this;
  };

  setOptionHideCompletedAspects = (hideCompletedAspects) => {
    this.definition.options.hideCompletedAspects = hideCompletedAspects;
    return this;
  };

  setOptionHideZeroScores = (hideZeroScores) => {
    this.definition.options.hideZeroScores = hideZeroScores;
    return this;
  };

  setOptionHideColumnTotals = (hideColumnTotals) => {
    this.definition.options.hideColumnTotals = hideColumnTotals;
    return this;
  };

  setOptionScoreMetaType = (scoreMetaType) => {
    rubricUtil.setScoreMetaType(this.definition, scoreMetaType);
    return this;
  };

  setBorderColor = (borderColor) => {
    this.definition.style.borderColor = borderColor;
    return this;
  };

  setHoverColor = (hoverColor) => {
    this.definition.style.hoverColor = hoverColor;
    return this;
  };

  setStatementBuilder = (statementBuilder: Function) => {
    this.statementBuilder = statementBuilder;
    return this;
  };

  setGuidanceBuilder = (guidanceBuilder: Function) => {
    this.guidanceBuilder = guidanceBuilder;
    return this;
  };

  build = (): Definition => {
    if (!this.definition.ownerId) {
      const currentUser = session.getCurrentUser();
      if (!currentUser) {
        throw new Error("Unable to build definition anonymously");
      }
      this.definition.ownerId = currentUser.getId();
    }
    if (this.definition.columns.length === 0) {
      const columnNames = ['Bad', 'Good'];
      for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
        const columnName = columnNames[columnIndex];
        const column = {
          uuid: util.uuid(),
          name: columnName
        };
        this.definition.columns.push(column);
      }
    }

    if (this.rows.length === 0) {
      this.setRowCount(2);
    }

    const context = {
      definition: this.definition
    };
    const callback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context) => {
      const statementLabels = statement._statementLabelsTemp;
      rubricUtil.setStatementLabels(context.definition, statement.uuid, statementLabels);
      if (this.guidanceBuilder) {
        const guidance = this.guidanceBuilder(columnIndex, rowIndex, statement, statementIndex);
        if (guidance) {
          statement.guidance = guidance;
        }
      }
      delete statement._statementLabelsTemp;
    };
    rubricUtil.iterateStatements(this.definition, callback, context);

    return this.definition;
  };

  _createAndAddRow = (rowName, rowIndex) => {
    const rowBuilder = new RowBuilder();
    for (let columnIndex = 0; columnIndex < this.definition.columns.length; columnIndex++) {
      const columnNumber = columnIndex + 1;
      const statementResult = this.statementBuilder ? this.statementBuilder(columnIndex, rowIndex) : 'Statement ' + columnNumber;
      let cellBuilder = new CellBuilder();
      if (Array.isArray(statementResult)) {
        for (const statementText of statementResult) {
          cellBuilder = cellBuilder.addStatementText(statementText);
        }
      } else {
        cellBuilder = cellBuilder.addStatementText(statementResult);
      }
      const cell = cellBuilder.build();
      rowBuilder.addCell(cell);
    }
    const row = rowBuilder
      .setName(rowName)
      .build();
    this.addRow(row);
  };

}

export class ColumnBuilder {

  name: string;

  constructor() {
    this.name = '';
  }

  setName = (name) => {
    this.name = name;
    return this;
  };

  build = () => {
    return {
      uuid: util.uuid(),
      name: this.name
    };
  }

}

export class RowBuilder {

  uuid: string;
  name: string;
  weight: number;
  themeName: string;
  cells: any[];
  lastAddedCell: undefined | any;

  constructor(uuid?: string) {
    this.uuid = uuid ? uuid : util.uuid();
    this.name = '';
    this.weight = 1;
    this.themeName = 'Theme ?';
    this.cells = [];
  }

  setName = (name) => {
    this.name = name;
    return this;
  };

  setWeight = (weight) => {
    this.weight = weight;
    return this;
  };

  setThemeName = (themeName) => {
    this.themeName = themeName;
    return this;
  };

  addCell = (cell) => {
    this.cells.push(cell);
    this.lastAddedCell = cell;
    return this;
  };

  setCells = (cells) => {
    this.cells = cells;
    return this;
  };

  build = () => {
    return {
      uuid: this.uuid,
      themeName: this.themeName,
      name: this.name,
      weight: this.weight,
      cells: this.cells
    };
  }

}

export class CellBuilder {

  uuid: string;
  statements: any[];

  constructor(uuid?: string) {
    this.uuid = uuid ? uuid : util.uuid();
    this.statements = [];
  }

  addStatementText = (statementText: string, labels?: string[]) => {
    const statement = new StatementBuilder()
      .setText(statementText)
      .setLabels(labels ? labels : [])
      .build();
    this.statements.push(statement);
    return this;
  };

  build = () => {
    return {
      uuid: this.uuid,
      statements: this.statements
    };
  }

}

export class StatementBuilder {

  uuid: string;
  text: string;
  labels: string[];

  constructor(uuid?: string) {
    this.uuid = uuid ? uuid : util.uuid();
    this.text = '';
    this.labels = [];
  }

  setText = (text) => {
    this.text = text;
    return this;
  };

  setLabels = (labels) => {
    this.labels = labels;
    return this;
  };

  build = (): Statement => {
    return {
      uuid: this.uuid,
      text: this.text,
      _statementLabelsTemp: this.labels
    };
  }

}
