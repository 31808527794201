import React, { FormEvent, PureComponent } from "react";
import Button, { ButtonGroup } from '@atlaskit/button';
import constants from "../../../shared/model/Constants";
import currentRubric from '../../../shared/model/rubric/CurrentRubric';
import driveDAO from '../../../backend/data/DriveDAO';
import driveDefinitions from '../../../shared/model/drive/DriveDefinitions';
import DriveFolderBuilder from '../../../shared/model/drive/DriveFolderBuilder';
import driveGraph from './DriveGraph';
import driveUtil from '../../../shared/model/drive/DriveUtil';
import IconButton from '../widget/IconButton';
import ErrorMessageBanner from '../widget/banner/ErrorMessageBanner';
import LiftedPanel from '../widget/LiftedPanel';
import ModalDialog from '@atlaskit/modal-dialog';
import {
  AddFolderIcon,
  AssessmentIcon,
  BreadcrumbSeparatorIcon,
  DeleteFolderIcon,
  RemoveRubricFromFolderIcon,
  EditNameFilledIcon,
  FolderFilledIcon,
  RubricIcon,
  SubmitNameIcon
} from '../icon/NamedIcons';
import rubricUtil from '../../../shared/model/rubric/RubricUtil';
import session from '../../../shared/model/auth/Session';
import Tooltip from "@atlaskit/tooltip";
import util from '../../../shared/util/Util';
import SafePopupIconButton from '../widget/SafePopupIconButton';
import DriveFolder from '../../../shared/model/drive/DriveFolder';
import FormlessTextField from '../widget/FormlessTextField';
import ToolbarLeft from '../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../widget/toolbar/ToolbarItem';

interface Props {
  showHeading?: boolean
  showEditTools?: boolean
  showEmptyFoldersMessage?: boolean
}

interface State {
  rootFolder?: DriveFolder
  currentFolder: DriveFolder
  selectedFolder?: DriveFolder
  selectedItem?: any
  renamingFolderUuid?: string
  newFolderNameErrorMessage?: string
}

export default class DriveView extends PureComponent<Props, State> {

  mounted: boolean = false;
  folderUuidsToNewNameDetails: any;

  constructor(props: Props) {
    super(props);
    this.mounted = false;
    this.folderUuidsToNewNameDetails = {};
    this.state = this.buildStateFromProps(props);
    const driveGraphState = driveGraph.getState();
    this.onDriveGraphChange(driveGraphState);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    driveGraph.registerListener(this.onDriveGraphChange);
    const driveGraphState = driveGraph.getState();
    if (driveGraphState) {
      let currentFolder = driveGraphState.rootFolder;
      if (this.state.currentFolder) {
        currentFolder = driveGraph.getFolderByUuid(this.state.currentFolder.uuid);
        if (!currentFolder) {
          currentFolder = driveGraphState.rootFolder;
        }
      }
      this.setState({
        rootFolder: driveGraphState.rootFolder,
        currentFolder: currentFolder
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    driveGraph.unregisterListener(this.onDriveGraphChange);
  }

  buildStateFromProps = (props: Props): State => {
    const initial: boolean = this.state === undefined;
    let state: State;
    if (initial) {
      const driveGraphState = driveGraph.getState();
      state = {
        currentFolder: driveGraphState.rootFolder,
        renamingFolderUuid: undefined
      }
    } else {
      // let currentFolder: undefined | DriveFolder = this.state.currentFolder;
      // if (!currentFolder) {
      //   const user = session.getCurrentUser();
      //   if (user) {
      //     currentFolder = driveDAO.getRootFolder(user);
      //   }
      // }
      // state.currentFolder = currentFolder;
      state = {
        currentFolder: this.state.currentFolder
      }
    }
    return state;
  };

  triggerFolderRenaming = (folder) => {
    this.folderUuidsToNewNameDetails[folder.uuid] = {
      newName: folder.name,
      errorMessage: ''
    };
    this.setState({
      renamingFolderUuid: folder.uuid,
      newFolderNameErrorMessage: ''
    });
  };

  getRenameDetails = (folder) => {
    let details = this.folderUuidsToNewNameDetails[folder.uuid];
    return details;
  };

  onDriveGraphChange = (driveGraphState) => {
    let currentFolder = driveGraphState.rootFolder;
    if (this.state.currentFolder) {
      currentFolder = driveGraph.getFolderByUuid(this.state.currentFolder.uuid);
      if (!currentFolder) {
        currentFolder = driveGraphState.rootFolder;
      }
    }
    if (this.mounted) {
      this.setState({
        rootFolder: driveGraphState.rootFolder,
        currentFolder: currentFolder
      });
      this.forceUpdate();
    } else {
      // Ignore - it will be updated in UNSAFE_componentWillMount().
    }
  };

  onDeleteFolder = (folder) => {
    driveGraph.removeFolder(folder);
    const user = session.getCurrentUser();
    if (user) {
      driveDAO.saveFolder(this.state.rootFolder)
      .then(() => {
        driveDAO.getRootFolder(user)
          .then((rootFolder) => {
            let currentFolder = driveGraph.getFolderByUuid(this.state.currentFolder.uuid);
            if (!currentFolder) {
              currentFolder = rootFolder;
            }
            this.setState({
              rootFolder: rootFolder,
              currentFolder: currentFolder
            });
          });
      });
    }
  };

  onRemoveFileFromFolder = (folder, fileReference) => {
    driveGraph.removeFileReferenceFromFolder(folder, fileReference);
  };

  onCreateFolderClick = () => {
    const childFolder = new DriveFolderBuilder()
      .setParentFolder(this.state.currentFolder)
      .setName(driveDefinitions.defaultNewFolderName)
      .build();
    driveGraph.addFolder(childFolder, this.state.currentFolder);
    driveDAO.saveFolder(this.state.rootFolder)
      .then(() => {
        this.triggerFolderRenaming(childFolder);
      });
  };

  onRenameFolderClick = (folder) => {
    this.triggerFolderRenaming(folder);
  };

  onFolderRenameChange = (event: FormEvent<HTMLInputElement>, folder) => {
    const newName = event.currentTarget.value.trim();
    const validationResult = driveUtil.validateFolderName(newName);
    const details = this.getRenameDetails(folder);
    if (validationResult.isValid()) {
      details.newName = newName;
      details.errorMessage = '';
    } else {
      details.errorMessage = validationResult.getErrorMessage();
    }
    this.setState({
      newFolderNameErrorMessage: details.errorMessage
    });
  };

  onSubmitFolderRenameClick = (folder) => {
    const details = this.getRenameDetails(folder);
    const newName = details.newName;
    const validationResult = driveUtil.validateFolderName(newName);
    if (validationResult.isValid()) {
      folder.name = newName;
      driveDAO.saveFolder(this.state.rootFolder)
        .then(() => {

        });
      this.setState({
        renamingFolderUuid: undefined
      });
    }
  };

  onCancelFolderRenameClick = () => {
    this.setState({
      renamingFolderUuid: undefined
    });
  };

  onVisitFolderClick = (folder) => {
    this.setState({
      currentFolder: folder
    });
  };

  onVisitFileClick = (fileReference) => {
    currentRubric.setRubricReference(fileReference, false);
  };

  renderFolderLink = (folder, showIcon) => {
    return (
      <div
        key={folder.uuid}
        style={{
          display: 'inline'
        }}
      >
        <Button
          appearance="subtle"
          iconBefore={showIcon ? <FolderFilledIcon label="folder"/> : undefined}
          onClick={() => this.onVisitFolderClick(folder)}
        >
          {folder.name}
        </Button>
      </div>
    );
  };

  fileReferenceToString = (fileReference) => {
    const isAssessmentReference = rubricUtil.isAssessmentReference(fileReference);
    if (isAssessmentReference) {
      return fileReference.definitionName + ': ' + fileReference.assessmentName;
    } else {
      return fileReference.definitionName;
    }
  };

  renderFileLink = (fileReference) => {
    const isAssessmentReference = rubricUtil.isAssessmentReference(fileReference);
    const icon = isAssessmentReference ? <AssessmentIcon/> : <RubricIcon/>
    const text = this.fileReferenceToString(fileReference);
    return (
      <div
        key={fileReference.definitionUuid}
        style={{
          display: 'inline'
        }}
      >
        <Button
          appearance="subtle-link"
          iconBefore={icon}
          onClick={() => this.onVisitFileClick(fileReference)}
        >
          {text}
        </Button>
      </div>
    );
  };

  getCurrentChildFolders = () => {
    const currentFolder = this.state.currentFolder;
    if (currentFolder && currentFolder.childFolders) {
      return currentFolder.childFolders;
    } else {
      return [];
    }
  };

  renderRenameCurrentFolderButton = (currentFolder: DriveFolder) => {
    return (
      <ToolbarLeft>
        <ToolbarItem>
          <Tooltip content={`Rename folder "${currentFolder.name}"`}>
            <Button
              appearance="subtle"
              iconBefore={<EditNameFilledIcon label="Rename" />}
              onClick={(event) => this.onRenameFolderClick(currentFolder)}
            >
              Rename folder <em>{currentFolder.name}</em>
            </Button>
          </Tooltip>
        </ToolbarItem>
      </ToolbarLeft>
    );
  };

  renderRenameFolderButton = (folder) => {
    return (
      <Tooltip content="Rename this folder">
        <IconButton
          normalIcon={<EditNameFilledIcon label="Rename"/>}
          hoverIcon={<EditNameFilledIcon label="Rename"/>}
          onClick={() => this.onRenameFolderClick(folder)}
        />
      </Tooltip>
    );
  };

  renderDeleteFolderButton = (folder) => {
    return (
      <SafePopupIconButton
        confirmationMessage={`Are you sure you want to delete folder "${folder.name}"?`}
        normalIcon={<DeleteFolderIcon label="Delete"/>}
        hoverIcon={<DeleteFolderIcon label="Delete"/>}
        tooltip={`Delete folder "${folder.name}"`}
        onConfirmation={() => this.onDeleteFolder(folder)}
      />
    );
  };

  renderDeleteDefinitionButton = (folder, fileReference) => {
    const text = this.fileReferenceToString(fileReference);
    return (
      <ButtonGroup>
        <SafePopupIconButton
          confirmationMessage={`Are you sure you want to remove "${text}" from folder "${folder.name}?"`}
          normalIcon={<RemoveRubricFromFolderIcon label="Remove"/>}
          hoverIcon={<RemoveRubricFromFolderIcon label="Remove"/>}
          tooltip={`Remove rubric "${text}" from this folder`}
          onConfirmation={() => this.onRemoveFileFromFolder(folder, fileReference)}
        />
      </ButtonGroup>
    );
  };

  addBreadCrumbLinksToPathToolbar = (currentFolder, toolbarComponents) => {
    const folderUuids = driveUtil.getParentFolderUuids(currentFolder);
    let isFirst = true;
    let separatorIndex = 0;
    for (const folderUuid of folderUuids) {
      const folder = driveGraph.getFolderByUuid(folderUuid);
      let folderLinkComponent: any = undefined;
      if (folder) {
        if (folderUuid === currentFolder.uuid) {
          folderLinkComponent = (
            <div key={folder.uuid} style={{ display: 'inline-block', marginTop: '12px', marginBottom: '12px' }}>
              {folder.name}
            </div>
          );
        } else {
          folderLinkComponent = this.renderFolderLink(folder, false);
        }
      } else {
        folderLinkComponent = (
          <div style={{display: 'inline', color: 'red'}}>
            [{folderUuid}]
          </div>
        );
      }
      if (!isFirst) {
        const separator = (
          <ToolbarItem key={'separator-' + separatorIndex} style={{height: '24px'}}>
            <BreadcrumbSeparatorIcon label="Separator"/>
          </ToolbarItem>
        );
        toolbarComponents.push(separator);
        separatorIndex++;
      }
      const folderItem = (
        <ToolbarItem key={'folder-' + folderUuid}>
          <h2>
            {folderLinkComponent}
          </h2>
        </ToolbarItem>
      );
      toolbarComponents.push(folderItem);
      isFirst = false;
    }
  };

  renderCreateNewFolderButton = (currentFolder) => {
    return (
      <Tooltip content={`Create a new folder within "${currentFolder.name}"`}>
        <Button
          appearance="subtle"
          iconBefore={<AddFolderIcon/>}
          onClick={this.onCreateFolderClick}
        >
          Create folder
        </Button>
      </Tooltip>
    );
  };

  renderFolderToolbar = (currentFolder: DriveFolder) => {
    const isRootFolder = currentFolder.isRoot || currentFolder.uuid === driveDefinitions.rootFolderUuid;
    return (
      <ToolbarLeft>
        {isRootFolder ? null : (
          <ToolbarItem>
            {this.props.showEditTools ? this.renderRenameCurrentFolderButton(currentFolder) : null}
          </ToolbarItem>
        )}
        <ToolbarItem>
          {this.props.showEditTools ? this.renderCreateNewFolderButton(currentFolder) : null}
        </ToolbarItem>
      </ToolbarLeft>
    );
  };

  addChildFolders = (currentFolder, folderContentItems) => {
    const childFolders = this.getCurrentChildFolders();
    util.sortObjectsByField(childFolders, 'name');
    if (childFolders.length) {
      for (let childFolder of childFolders) {
        const childFolderItem = (
          <tr key={'child-' + childFolder.uuid}>
            <td>
              {this.renderFolderLink(childFolder, true)}
            </td>
            <td>
              {this.props.showEditTools ? this.renderRenameFolderButton(childFolder) : null}
            </td>
            <td>
              {this.props.showEditTools ? this.renderDeleteFolderButton(childFolder) : null}
            </td>
          </tr>
        );
        folderContentItems.push(childFolderItem);
      }
    }
  };

  addFilesInFolder = (currentFolder, folderContentItems) => {
    util.sortObjectsByField(currentFolder.fileReferences, 'definitionName');
    for (let fileReference of currentFolder.fileReferences) {
      const key = fileReference.assessmentUuid ?
        'assessment-' + fileReference.assessmentUuid :
        'definition-' + fileReference.definitionUuid;
      const childFolderItem = (
        <tr key={key}>
          <td>
            {this.renderFileLink(fileReference)}
          </td>
          <td>
            &nbsp;
          </td>
          <td>
            {this.renderDeleteDefinitionButton(currentFolder, fileReference)}
          </td>
        </tr>
      );
      folderContentItems.push(childFolderItem);
    }
  };

  renderEmptyFolder = (folder, definitionsCount) => {
    if (this.props.showEmptyFoldersMessage && folder !== this.state.rootFolder) {
      const renderedMessage = (
        <div style={{marginLeft: '10px'}}>
          {`The folder "${folder.name}" has no rubrics in it.
        To add rubrics, visit a rubric and click the folder icon at the top right.`}
        </div>
      );
      return renderedMessage;
    } else {
      return null;
    }
  };

  renderFolderContents = (currentFolder) => {
    const folderContentItems = [];
    this.addChildFolders(currentFolder, folderContentItems);
    let renderedEmptyFolder: any = undefined;
    const definitionsCount = currentFolder.fileReferences ?
      currentFolder.fileReferences.length : 0;
    if (definitionsCount) {
      this.addFilesInFolder(currentFolder, folderContentItems);
    } else {
      renderedEmptyFolder = this.renderEmptyFolder(currentFolder, definitionsCount);
    }
    return (
      <div style={{marginLeft: '0px'}}>
        {this.renderFolderToolbar(currentFolder)}
        <table style={{width: 'auto'}}>
          <tbody style={{borderBottom: 'none'}}>
            {folderContentItems}
          </tbody>
        </table>
        {renderedEmptyFolder}
      </div>
    );
  };

  renderCurrentFolder = () => {
    const currentFolder = this.state.currentFolder;
    const toolbarComponents = [];
    this.addBreadCrumbLinksToPathToolbar(currentFolder, toolbarComponents);
    const folderToolbar = (
      <ToolbarLeft style={{alignItems: 'center'}}>
        {toolbarComponents}
      </ToolbarLeft>
    );
    return (
      <div>
        {folderToolbar}
        {this.renderFolderContents(currentFolder)}
      </div>
    );
  };

  renderNoCurrentFolder = () => {
    return (
      <div>
        Current folder not set!
      </div>
    );
  };

  renderHeading = () => {
    return (
      <h2>
        Drive
      </h2>
    );
  };

  renderErrorBanner = (message) => {
    return (
      <ErrorMessageBanner>
        {message}
      </ErrorMessageBanner>
    );
  };

  renderRenameFolderModal = () => {
    const folder = driveGraph.getFolderByUuid(this.state.renamingFolderUuid);
    const errorMessage = this.state.newFolderNameErrorMessage;
    return (
      <ModalDialog
        key="rename-folder"
        heading={'Rename folder'}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        onClose={undefined}
      >
        {errorMessage ? this.renderErrorBanner(this.state.newFolderNameErrorMessage) : null}
        <FormlessTextField
          name="rename"
          label="Rename folder"
          minLength={constants.minDriveFolderNameLength}
          maxLength={constants.maxDriveFolderNameLength}
          shouldFitContainer={true}
          value={folder.name}
          onChange={(event: FormEvent<HTMLInputElement>) => this.onFolderRenameChange(event, folder)}
        />
        <div className="centredContent" style={{marginTop: '10px', marginBottom: '10px'}}>
          <ToolbarItem>
            <Button
              appearance="primary"
              iconBefore={<SubmitNameIcon label="Rename folder"/>}
              isDisabled={false}
              onClick={() => this.onSubmitFolderRenameClick(folder)}
            >
              Rename folder
            </Button>
            <Button
              appearance="subtle"
              isDisabled={false}
              onClick={this.onCancelFolderRenameClick}
            >
              Cancel
            </Button>
          </ToolbarItem>
        </div>
      </ModalDialog>
    );
  };

  onFolderSelection = (folder) => {
    // console.log('Selected folder', folder);
    this.setState({
      selectedFolder: folder
    });
  };

  onItemSelection = (item) => {
    // console.log('Selected item', item);
    this.setState({
      selectedItem: item
    });
  };

  render_FolderSelector = () => {
    return null;
    // return (
    //   <div>
    //     <h3>Folder selection test</h3>
    //     <h4>Selected folder</h4>
    //     {this.state.selectedFolder ? (
    //       <pre><code>{JSON.stringify(this.state.selectedFolder, null, 2)}</code></pre>
    //     ) : null}
    //     <h4>Selected item</h4>
    //     {this.state.selectedItem ? (
    //       <pre><code>{JSON.stringify(this.state.selectedItem, null, 2)}</code></pre>
    //     ) : null}
    //     <h4>Selector</h4>
    //     <FolderSelector
    //       allowFolderSelection={true}
    //       allowItemSelection={true}
    //       onFolderSelection={this.onFolderSelection}
    //       onItemSelection={this.onItemSelection}
    //     />
    //     <hr/>
    //   </div>
    // );
  }

  render() {
    const currentFolder = this.state.currentFolder;
    return (
      <div>
        {this.render_FolderSelector()}
        {this.state.renamingFolderUuid ? this.renderRenameFolderModal() : null}
        {this.props.showHeading ? this.renderHeading() : null}
        <LiftedPanel width="100%">
          {currentFolder ? this.renderCurrentFolder() : this.renderNoCurrentFolder()}
        </LiftedPanel>
      </div>
    );
  }

}
