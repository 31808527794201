import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import Tooltip from "@atlaskit/tooltip";

interface Props {
  text: string
  tooltip?: string
  contentForeignKey: any
  context?: any
  stopPropagation: boolean
  iconAfter?: any
  contentRenderer: any
  initiallyExpanded?: undefined | boolean
  display?: any
  onExpanded?: (event: any) => void
  onCollapsed?: (event: any) => void
}

interface State {
  text: string
  tooltip?: string
  contentForeignKey: any
  context: any
  stopPropagation: boolean
  iconAfter: any
  contentRenderer: any
  expanded?: boolean
}

export default class Expand extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props, true);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props, false));
    
    // This is necessary since the contentRenderer executes outside the
    // context of this component. Use the contentForeignKey prop to pass
    // in a prop that will trigger this update.
    this.forceUpdate();
  }

  buildStateFromProps = (props: Props, updateExpanded: boolean) => {
    const stateDelta: State = {
      contentForeignKey: props.contentForeignKey,
      context: props.context,
      text: props.text,
      tooltip: props.tooltip,
      stopPropagation: props.stopPropagation,
      // expanded: this.state && this.state.expanded !== undefined ? this.state.expanded : props.initiallyExpanded,
      iconAfter: props.iconAfter,
      contentRenderer: props.contentRenderer
    };
    if (updateExpanded) {
      stateDelta.expanded = props.initiallyExpanded;
    }
    return stateDelta;
  };

  onToggleExpandClick = (event) => {
    // debugger;
    if (this.state.stopPropagation) {
      event.stopPropagation();
    }
    const newExpanded = !this.state.expanded;
    this.setState({
      expanded: newExpanded
    });
    if (newExpanded) {
      if (this.props.onExpanded) {
        this.props.onExpanded(event);
      }
    } else {
      if (this.props.onCollapsed) {
        this.props.onCollapsed(event);
      }
    }
  };

  renderExpanded = () => {
    const renderedContent = this.props.contentRenderer(this.state.context);
    return (
      <div style={{marginTop: '10px'}}>
        {renderedContent}
      </div>
    );
  };

  renderLink = () => {
    return (
      <Button
        appearance="link"
        spacing="none"
        shouldFitContainer={false}
        iconBefore={this.state.expanded ? <ChevronDownIcon label="expanded" /> : <ChevronRightIcon label="collapsed"/>}
        iconAfter={this.state.iconAfter}
        onClick={this.onToggleExpandClick}
      >
        {this.state.text}
      </Button>
    );
  };

  renderTooltipLink = () => {
    return (
      <Tooltip content={this.state.tooltip}>
        {this.renderLink()}
      </Tooltip>
    );
  };

  render() {
    return (
      <div style={{ display: this.props.display ? this.props.display : undefined}}>
        {this.state.tooltip ? this.renderTooltipLink() : this.renderLink()}
        {this.state.expanded ? this.renderExpanded() : null}
      </div>
    );
  }

}
