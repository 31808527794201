import React, { PureComponent } from 'react';
import Button from '@atlaskit/button';
import constants from '../../../shared/model/Constants';
import EmptyState, { Sizes } from '@atlaskit/empty-state';
import emptyStateImages from '../widget/EmptyStateImages';
import GlobalSpinner from '../global/GlobalSpinner';
import session from '../../../shared/model/auth/Session';
import SignInWithGoogleButton from '../widget/SignInWithGoogleButton';

interface Props {
  message?: string
  size?: Sizes
}

interface State {
  errorMessage?: string
  inPasswordFormModel: boolean
  // inPasswordFormModel: boolean
  username: string
  passwordEntered: boolean
}

export default class LoginView extends PureComponent<Props, State> {

  mounted: boolean = false;;

  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: '',
      inPasswordFormModel: false,
      username: '',
      passwordEntered: false
    };
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onMockUserLoginClick = (user) => {
    session.onMockUserLogin(user);
  };

  onCantLogin = () => {
    alert('Not implemented');
  };

  renderPasswordLoginForm = () => {
    return (
      <div>
        <SignInWithGoogleButton/>
      </div>
    );
  };

  renderMockUserLoginButton = (user) => {
    return (
      <div
        key={user.getId()}
        style={{marginTop: '20px'}}
      >
        <Button
          appearance="primary"
          onClick={() => this.onMockUserLoginClick(user)}
        >
          Sign in as {user.getDisplayName()}
        </Button>
      </div>
    );
  };

  renderUserLoginButtons = () => {
    return session.getMockUsers().map((user) => {
      return this.renderMockUserLoginButton(user);
    });
  };

  renderCantLoginButton = () => {
    return null;
    // return (
    //   <Button
    //     appearance="subtle-link"
    //     onClick={this.onCantLogin}
    //   >
    //     {i18n.Cant_login}
    //   </Button>
    // );
  };

  renderLoginErrorMessage = () => {
    return (
      <div className="error">
        {this.state.errorMessage}
      </div>
    );
  };

  renderLoginView = () => {
    return (
     <div>
       <GlobalSpinner/>
       {this.state.errorMessage ? this.renderLoginErrorMessage(): null}
       {this.renderPasswordLoginForm()}
       {this.renderUserLoginButtons()}
     </div>
    );
  };

  render() {
    const description = this.props.message ? this.props.message : constants.appByline;
    const size = this.props.size ? this.props.size : 'wide';
    const dimensions = size === 'narrow' ? {width: 450, height: 100} : {width: 900, height: 200};
    return (
      <EmptyState
        header="Sign in"
        description={description}
        imageUrl={emptyStateImages.getAnyEmptyStateImage()}
        size={size}
        maxImageWidth={dimensions.width}
        maxImageHeight={dimensions.height}
        primaryAction={this.renderLoginView()}
        tertiaryAction={this.renderCantLoginButton()}
      />
    );
  }

}
