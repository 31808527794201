import React, { PureComponent } from 'react';
import reportTypeDefinitions from '../../../../shared/model/rubric/ReportTypeDefinitions';
import SelectOption from '../../../../shared/model/widget/SelectOption';
import SingleSelect from '../../widget/SingleSelect';

interface Props {
  label: string
  reportType?: string
  customReportTypeChecker: (options: SelectOption[]) => void
  onChange: (reportType: string) => void
}

interface State {
  label: string
  reportType?: string
  dirty?: true
}

export default class ReportTypeSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      label: props.label,
      reportType: props.reportType === undefined ? this.state.reportType : props.reportType
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      reportType: selection.value
    });
    this.props.onChange(selection.value);
  };

  render() {
    const reportType = this.state.reportType;
    const options = reportTypeDefinitions.buildOptions(this.props.customReportTypeChecker);
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === reportType) {
        selectedOption = option;
      }
    }
    return (
      <div>
        <SingleSelect
          label={this.state.label}
          options={options}
          defaultSelected={selectedOption}
          placeholder={undefined}
          noMatchesFound={undefined}
          hasAutocomplete
          appearance="default"
          onSelected={this.onChange}
        />
      </div>
    );
  };

}
