import React, { FormEvent, PureComponent } from "react";
import browserType from '../../../../shared/model/browser/BrowserType';
import DiscreteScoreInput from './DiscreteScoreInput';
import Label from '../../widget/Label';
import ScoreSliderInput from './ScoreSliderInput';
// import scoreTypes from '../../../../shared/model/rubric/score/ScoreTypes';
import Tooltip from "@atlaskit/tooltip";
import measurementTypes from '../../../../shared/model/rubric/score/MeasurementTypes';
import AssessmentItem from '../../../../shared/model/rubric/score/AssessmentItem';
import ScoreMeta from '../../../../shared/model/rubric/score/ScoreMeta';
import FormlessTextField from '../../widget/FormlessTextField';

interface Common {
  label: undefined | string
  score: number
  editable: boolean
  assessmentItem: AssessmentItem
  scoreMeta: ScoreMeta
  containerWidth: number
}

interface Props extends Common {
  onScoreChange: (scorePercent: number) => void;
}

interface State extends Common {
  textInputError?: string
}

export default class ScoreInput extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      label: props.label,
      containerWidth: props.containerWidth,
      scoreMeta: props.scoreMeta,
      score: props.score,
      editable: props.editable,
      assessmentItem: props.assessmentItem
    };
  };

  onScoreTextChange = (event: FormEvent<HTMLInputElement>) => {
    try {
      const scoreText = event.currentTarget.value;
      const value = parseInt(scoreText, 10);
      if (isNaN(value)) {
        this.setState({
          textInputError: 'The score must be an integer from 0 to 100'
        });
      } else if (value < 0) {
        this.setState({
          textInputError: 'The score must be an integer from 0 to 100'
        });
      } else if (value > 100) {
        this.setState({
          textInputError: 'The score must be an integer from 0 to 100'
        });
      } else {
        this.setState({
          textInputError: ''
        });
        this.props.onScoreChange(value);
      }
    } catch (exception) {
      this.setState({
        textInputError: 'The score must be an integer from 0 to 100'
      });
    }
  };

  renderNumericInput = () => {
    const tooltip = this.state.textInputError ? this.state.textInputError : 'Score this statement by entering a value from 0 to 100';
    return (
      <Tooltip content={tooltip}>
        <FormlessTextField
          name="score"
          label={this.state.label}
          maxLength={4}
          isInvalid={this.state.textInputError ? true : false}
          value={this.state.score.toString()}
          onChange={this.onScoreTextChange}
        />
      </Tooltip>
    );
  };

  renderScoreInnapplicability = () => {
    return (
      <p className="error">
        This statement has been marked as innapplicable for this assessment so it can not have a score.
      </p>
    );
  };

  renderRangeInput = () => {
    const renderedLabel = this.state.label ? <Label text={this.state.label}/> : null;
    return (
      <React.Fragment>
        {renderedLabel}
        <div style={{ width: this.props.containerWidth}}>
          <ScoreSliderInput
            score={this.state.score}
            editable={this.state.editable}
            assessmentItem={this.state.assessmentItem}
            scoreMeta={this.state.scoreMeta}
            containerWidth={this.state.containerWidth}
            onScoreChange={this.props.onScoreChange}
          />
        </div>
      </React.Fragment>
    );  
  };

  renderDiscreteScoreInput = () => {
    return (
      <DiscreteScoreInput
        label={this.props.label}
        editable={this.state.editable}
        scoreMeta={this.props.scoreMeta}
        width={this.props.containerWidth}
        scorePercent={this.props.score}
        onScoreChange={this.props.onScoreChange}
      />
    );
  };

  render() {
    if (this.state.assessmentItem.innapplicable) {
      return this.renderScoreInnapplicability();
    } else {
      if (browserType.isMobile()) {
        return this.renderNumericInput();
      } else {
        const measurementType = this.state.scoreMeta.getMeasurementType ? this.state.scoreMeta.getMeasurementType() : undefined;
        if (measurementType === measurementTypes.discreteType) {
          return this.renderDiscreteScoreInput();
        } else {
          return this.renderRangeInput();
        }
      }
    }
  }

}
