import React, { Component, } from "react";
import Analytics from './Analytics';
// import GoogleAnalytics from "react-ga";
// import ReactGA from 'react-ga4';

const withPageViewTracker = (WrappedComponent, options = {}) => {

  const trackPage = (page: string) => {
    // GoogleAnalytics.set({
    //   page,
    //   ...options,
    // });
    // GoogleAnalytics.pageview(page);
    // ReactGA.send("pageview");

    Analytics.trackPage(page);
  };

  interface Props {
    location: any
  }

  interface State {

  }

  const HOC = class extends Component<Props, State> {
    componentDidMount() {
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withPageViewTracker;
