import React, { PureComponent } from 'react';
import adg from '../../../commonbase/adg';
import PathBuilder from '../svg/PathBuilder';
import OpacityHelper from './OpacityHelper';

export default class BlankIcon extends PureComponent {

  constructor(props) {
    super(props);
    this.opacityHelper = new OpacityHelper(this);
  }

  render = () => {
    const width = this.props.width;
    const height = this.props.height;
    const mainFill = new PathBuilder('main-fill')
      .setPathColor(adg.none)
      .setFill(adg.none)
      .setPathWidth(2)
      .moveTo(0, 0)
      .lineTo(0, height)
      .lineTo(width, height)
      .lineTo(width, 0)
      .close()
      .render();
    return (
      <svg
        style={this.opacityHelper.buildStyle()}
        width={width}
        height={height}
        onMouseEnter={this.opacityHelper.onMouseEnter}
        onMouseLeave={this.opacityHelper.onMouseLeave}
        onClick={this.props.onClick}
      >
        {mainFill}
      </svg>
    );
  }

}