import SelectOption from '../widget/SelectOption';
import { Editability } from './definition/Definition'

interface EditabilityOption extends SelectOption {
  value: Editability,
}

class EditabilityDefinitions {

  publicEditabilityOption: EditabilityOption = {
    value: 'fullEdit',
    label: 'Additions and removals'
  };
  emailDomainEditabilityOption: EditabilityOption = {
    value: 'partialEdit',
    label: 'Additions only'
  };
  linkEditabilityOption: EditabilityOption = {
    value: 'immutable',
    label: 'Immutable'
  };
  options: EditabilityOption[] = [
    this.publicEditabilityOption,
    this.emailDomainEditabilityOption,
    this.linkEditabilityOption
  ];

  fullEditabilityType: Editability = 'fullEdit';
  partialEditabilityType: Editability = 'partialEdit';
  immutableEditabilityType: Editability = 'immutable';
  typesToNames = [];

  constructor() {
    for (const option of this.options) {
      this.typesToNames[option.value] = option.label;
    }
  }

  getEditability = (discoverabilityTypeOrName: string): undefined | Editability => {
    for (const option of this.options) {
      if (option.value === discoverabilityTypeOrName || option.label === discoverabilityTypeOrName) {
        return option.value as Editability;
      }
    }
    return undefined;
  }

  getOptions = (): EditabilityOption[] => {
    return this.options;
  }

  // buildEditabilityTypeEnumItemsContext = (currentType) => {
  //   const fullEditStateItem: SelectItem = {
  //     label: this.typesToNames[this.fullEditabilityType],
  //     value: this.fullEditabilityType
  //   };
  //   const partialEditItem: SelectItem = {
  //     label: this.typesToNames[this.partialEditabilityType],
  //     value: this.partialEditabilityType
  //   };
  //   const immutableItem: SelectItem = {
  //     label: this.typesToNames[this.immutableEditabilityType],
  //     value: this.immutableEditabilityType
  //   };
  //   const items: SelectItem[] = [
  //     fullEditStateItem,
  //     partialEditItem,
  //     immutableItem];
  //   const selectItems: SelectItemsGroup[] = [{
  //     items: items
  //   }];
  //   let selectedItem: undefined | SelectItem = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: selectItems,
  //     selectedItem: selectedItem
  //   };
  // };

  // getDefaultType = () => {
  //   return this.fullEditabilityType;
  // };

}

export default new EditabilityDefinitions();
