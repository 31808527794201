import persistenceFactory from './PersistenceFactory';
import driveDefinitions from '../../shared/model/drive/DriveDefinitions';
import session from '../../shared/model/auth/Session';

class DriveDAO {

  constructor() {
    this.drivePersistence = persistenceFactory.drivePersistence;
  }

  saveFolder = (folder) => {
    const user = session.getCurrentUser();
    return this.drivePersistence.saveFolder(user, folder);
  };

  getRootFolder = (user) => {
    return this.drivePersistence.getFolder(user, driveDefinitions.rootFolderPath);
  };

}

export default new DriveDAO();
