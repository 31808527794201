import analytics from '../../shared/model/analytics/Analytics';
import Assessment from '../../shared/model/rubric/score/Assessment';
import AssessmentDAI from '../../backend/data/AssessmentDAI';
import collectionsUtil from '../../commonbase/util/collectionsUtil';
import commonConstants from '../../commonbase/commonConstants';
import dataStats from './DataStats';
import DeletionResult from './DeletionResult';
import discoverabilityDefinitions from '../../shared/model/rubric/DiscoverabilityDefinitions';
import firebaseApp from '../firebase/FirebaseApp';
import roleDefinitions from '../../shared/model/auth/RoleDefinitions';
// import util from '../../shared/util/Util';
import IUser from '../../shared/model/auth/IUser';
import RubricAssessmentBuilder from '../../shared/model/rubric/RubricAssessmentBuilder';
import DataSource from '../../shared/model/rubric/DataSource';
import Subscription from './Subscription';

class FirestoreAssessmentDAO implements AssessmentDAI, DataSource {

  assessmentsCollectionKey = 'assessments';
  assessmentAnalyticsEntityName = 'assessment';

  getDataSourceId = (): string => {
    return 'firestore-assessments-collection';
  }

  getDataSourceName = (): string => {
    return commonConstants.appTitle;
  }

  isAssessmentSnapshottingEnababled = (): boolean => {
    return true;
  }

  registerRemoteAssessmentChangeListener = (assessmentUuid: string, onRemoteAssessmentChange: (assessment: Assessment) => void): Subscription => {
    // https://cloud.google.com/firestore/docs/query-data/listen
    const options = {
      includeMetadataChanges: false
    };
    const unsubscribeToAssessmentChanges = firebaseApp
      .getFirestore()
      .collection(this.assessmentsCollectionKey)
      .doc(assessmentUuid)
      .onSnapshot(options, (doc) => {
        dataStats.logFirestoreRead();
        analytics.databaseRead(this.assessmentAnalyticsEntityName);
        const localUpdate = doc.metadata.hasPendingWrites;
        if (!localUpdate && !doc.metadata.fromCache) {
          const assessment = this._assessmentDocToAssessment(doc);
          onRemoteAssessmentChange(assessment);
        }
      });
    const subscription: Subscription = {
      unsubscribe: () => {
        unsubscribeToAssessmentChanges();
      }
    }
    return subscription;
  };

  unregisterRemoteAssessmentChangeListener = (subscription: Subscription): void => {
    subscription.unsubscribe();
  };

  getAssessmentByUuid = (assessmentUuid: string): Promise<Assessment> => {
    const trapError = false;
    return this._getAssessmentByUuid(assessmentUuid, trapError);
  };

  _getAssessmentByUuid = (assessmentUuid: string, trapError?: boolean): Promise<Assessment> => {
    return firebaseApp
      .getFirestore()
      .collection(this.assessmentsCollectionKey)
      .doc(assessmentUuid)
      .get()
      .then((doc) => {
        dataStats.logFirestoreRead();
        analytics.databaseRead(this.assessmentAnalyticsEntityName);
        return this._assessmentDocToAssessment(doc);
      })
      .catch((error) => {
        if (trapError) {
          console.warn("Error reading assessment: ", error);
          // debugger;
        } else {
          throw error;
        }
      });
  };

  getAssessmentsByUuids = (assessmentUuids: string[], querySemantics?: boolean): Promise<Assessment[]> => {
    // Can't use .where('uuid', 'in', assessmentUuids) because it can't
    // be combined with array-contains-any.
    if (assessmentUuids && assessmentUuids.length) {
      const promises: Promise<Assessment>[] = [];
      for (const assessmentUuid of assessmentUuids) {
        // If using querySemantics we set trapError to true so that the inability to retrieve
        // one assessment (e.g. due to being missing or change of permissions) doesn't affect 
        // the returning of other assessments.
        const trapError = querySemantics;
        // const promise = this.getAssessmentByUuid(assessmentUuid, trapError);
        const promise = this._getAssessmentByUuid(assessmentUuid, trapError);
        promises.push(promise);
      }
      return Promise.all(promises)
        .then((assessments) => {
          return collectionsUtil.filterNilsFromArray(assessments);
        });
    } else {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  };

  getAssessmentsByDefinitionUuidAndUser = async (definitionUuid: string, user: IUser, includeArchivedAssessments?: boolean): Promise<Assessment[]> => {
    const publicAssessmentsPromise = this._getAssessmentsByDefinitionUuidAndUser(definitionUuid, user, false, includeArchivedAssessments);
    const roleAssessmentsPromise = this._getAssessmentsByDefinitionUuidAndUser(definitionUuid, user, true, includeArchivedAssessments);
    return Promise.all([publicAssessmentsPromise, roleAssessmentsPromise]).then(results => {
      const publicAssessments = results[0] as Assessment[];
      const roleAssessments = results[1] as Assessment[];
      return collectionsUtil.mergeArrays(publicAssessments, roleAssessments, 'uuid');
    });
  };

  _getAssessmentsByDefinitionUuidAndUser = async (definitionUuid: string, user: IUser, useRolePermissions: boolean, includeArchivedAssessments?: boolean): Promise<Assessment[]> => {
    const email = user.getEmail();
    const emailDomain = user.getEmailDomain();
    const anyReaderRole = '*>>>>' + roleDefinitions.readerRoleType;
    const domainReaderRole = '*@' + emailDomain + '>>>>' + roleDefinitions.readerRoleType;
    const exactReaderRole = email + '>>>>' + roleDefinitions.readerRoleType;
    const rolesToSearch = [anyReaderRole, domainReaderRole, exactReaderRole];
    let query = firebaseApp
      .getFirestore()
      .collection(this.assessmentsCollectionKey)
      .where('definitionUuid', '==', definitionUuid);
    if (useRolePermissions) {
      query = query.where('roles', 'array-contains-any', rolesToSearch);
    } else {
      query = query.where('discoverability', '==', 'public');
    }
    if (!includeArchivedAssessments) {
      query = query.where('archivedTimestamp', '==', 0);
    }
    return query
      .get()
      .then((querySnapshot) => {
        const assessments: Assessment[] = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          const assessment = doc.data() as Assessment;
          assessments.push(assessment);
        });
        dataStats.logFirestoreReads(assessments.length);
        analytics.databaseRead(this.assessmentAnalyticsEntityName, assessments.length);
        return assessments;
      })
      .catch((error) => {
        console.error('Error:', error);
        // debugger;
        throw error;
      });
  };

  applyDefaultAssessmentPermissions = (assessmentBuilder: RubricAssessmentBuilder): RubricAssessmentBuilder => {
    // Add no reader or writer roles
    return assessmentBuilder
      .setDiscoverability(discoverabilityDefinitions.privateDiscoverabilityType);
  }

  storeSanitisedAssessment = (assessment: Assessment): Promise<Assessment> => {
    dataStats.logFirestoreReadWrite();
    analytics.databaseWrite(this.assessmentAnalyticsEntityName);
    return new Promise<Assessment>((resolve, reject) => {
      firebaseApp
        .getFirestore()
        .collection(this.assessmentsCollectionKey)
        .doc(assessment.uuid)
        .set(assessment)
        .then(() => {
          //console.log("Assessment successfully written!");
          resolve(assessment);
        })
        .catch((error) => {
          console.error("Error writing assessment: ", error);
          debugger;
          reject(error);
        });
    });
  };

  deleteAssessment = (assessment: Assessment): Promise<DeletionResult> => {
    dataStats.logFirestoreDelete();
    analytics.databaseDelete(this.assessmentAnalyticsEntityName);
    const payload = {
      assessmentUuid: assessment.uuid
    };
    const functions = firebaseApp.getFunctions();
    const deleteFn = functions.httpsCallable('recursiveDeleteAssessment');
    return deleteFn(payload)
      .then((result) => {
        //console.log('Delete success: ' + JSON.stringify(result));
        return {deleted: true};
      })
      .catch((error) => {
        console.error('Delete failed:', error);
        // debugger;
        console.warn(error);
        return {deleted: false};
      });
  };

  _assessmentDocToAssessment = (doc) => {
    if (doc.exists) {
      const assessment = doc.data();
      //console.log("assessment:", assessment);
      return assessment;
    } else {
      console.warn("No such assessment!");
      return null;
    }
  };

}

export default new FirestoreAssessmentDAO();