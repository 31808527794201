import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import DashboardView from '../components/rubric/dashboard/DashboardView';
import LiftedPageTitle from '../components/widget/LiftedPageTitle';
import rubricPageHelper from './RubricPageHelper';

interface Props {

}

interface State {

}

export default class DashboardPage extends PureComponent<Props, State> {

  componentDidMount() {
    rubricPageHelper.updateTitle('Dashboard');
  }

  render() {
    return (
      <ContentWrapper>
        <LiftedPageTitle>Dashboard</LiftedPageTitle>
        <DashboardView
          showHeading={false}
          showAssessmentGroups={false}
          allowSpinner={true}
        />
      </ContentWrapper>
    );
  }

}
