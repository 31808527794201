
import SelectOption from '../widget/SelectOption';

class ReportTypeDefinitions {

  defaultType = 'default';
  crispType = 'crisp';
  atlassianAnnualAssessmentType = 'atlassian-annual-assessment';
  atlassianPromotionType = 'atlassian-promotion';
  customType = 'custom';
  defaultOption: SelectOption = {
    value: this.defaultType,
    label: 'Default'
  };
  crispOption: SelectOption = {
    value: this.crispType,
    label: 'Crisp'
  };
  atlassianAnnualAssessmentOption: SelectOption = {
    value: this.atlassianAnnualAssessmentType,
    label: 'Atlassian annual assessment'
  };
  atlassianPromotionOption: SelectOption = {
    value: this.atlassianPromotionType,
    label: 'Atlassian promotion justification'
  };
  customOption: SelectOption = {
    value: this.customType,
    label: 'Custom'
  };
  options: SelectOption[] = [
    this.defaultOption,
    this.crispOption,
    this.atlassianAnnualAssessmentOption,
    this.atlassianPromotionOption,
    this.customOption
  ];

  typesToDefinitions = [];

  constructor() {
    for (const option of this.options) {
      this.typesToDefinitions[option.value] = option.label;
    }
  }

  buildOptions = (customReportTypeChecker?: (options: SelectOption[]) => void): SelectOption[] => {
    const options = [
      this.defaultOption,
      this.crispOption
    ];
    if (customReportTypeChecker) {
      customReportTypeChecker(options);
    }
    options.push(this.customOption);
    return options;
  }

  // buildItemsContext = (currentType, customReportTypeChecker) => {
  //   const defaultItem: SelectItem = {
  //     label: this.typesToDefinitions[this.defaultType].name,
  //     value: this.defaultType
  //   };
  //   const crispItem: SelectItem = {
  //     label: this.typesToDefinitions[this.crispType].name,
  //     value: this.crispType
  //   };
  //   const customItem: SelectItem = {
  //     label: this.typesToDefinitions[this.customType].name,
  //     value: this.customType
  //   };
  //   const items: SelectItem[] = [];
  //   items.push(defaultItem);
  //   items.push(crispItem);
  //   if (customReportTypeChecker) {
  //     customReportTypeChecker(items);
  //   }
  //   items.push(customItem);
  //   const selectItems: SelectItemsGroup[] = [{
  //     items: items
  //   }];
  //   let selectedItem: undefined | SelectItem = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: selectItems,
  //     selectedItem: selectedItem
  //   };
  // };

  getDefaultType = () => {
    return this.defaultType;
  };

  addAtlassianGrowthProfileItems = (options: SelectOption[]) => {
    const atlassianAnnualAssessmentItem: SelectOption = {
      label: this.typesToDefinitions[this.atlassianAnnualAssessmentType].name,
      value: this.atlassianAnnualAssessmentType
    };
    const atlassianPromotionItem: SelectOption = {
      label: this.typesToDefinitions[this.atlassianPromotionType].name,
      value: this.atlassianPromotionType
    };
    options.push(atlassianAnnualAssessmentItem);
    options.push(atlassianPromotionItem);
  };

}

export default new ReportTypeDefinitions();
