import { dmUserId } from './AuthConstants';
import session from './Session';
import util from '../../util/Util';

class AuthUtil {

  isAtlassianUser = (): boolean => {
    const user = session.getCurrentUser();
    if (user) {
      const email = user.getEmail();
      const emailDomain = util.extractDomainFromEmail(email);
      if (emailDomain === 'atlassian.com') {
        return true;
      } else if (user.getId() === dmUserId) {
        return true;
      }
    }
    return false;
  };

}

export default new AuthUtil();
