import AssessmentContext from './AssessmentContext';
import rubricUtil from '../RubricUtil';
import assessmentUtil from './AssessmentUtil';

class CurrentAssessmentContext {

  assessmentContext: undefined | AssessmentContext = undefined;

  setAssessmentContext = (assessmentContext: AssessmentContext): void => {
    if (this._isCurrentAssessmentContext(assessmentContext)) {
      // no need to do anything
    } else {
      this.assessmentContext = assessmentContext;
    }
  };

  getAssessmentContext = (): undefined | AssessmentContext => {
    return this.assessmentContext;
  }

  _isCurrentAssessmentContext = (assessmentContext: AssessmentContext): boolean => {
    const currentContext = this.assessmentContext;
    if (currentContext === undefined && assessmentContext === undefined) {
      return true;
    } else if (currentContext === undefined || assessmentContext === undefined) {
      return false;
    } else if (currentContext.definition && assessmentContext.definition) {
      return rubricUtil.areDefinitionsEqual(currentContext.definition, assessmentContext.definition) &&
        assessmentUtil.areAssessmentsEqual(currentContext.assessment, assessmentContext.assessment)
    } else {
      // This should be impossible to reach.
      return false;
    }
  }

}

export default new CurrentAssessmentContext();
