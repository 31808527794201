import IUser from './IUser';
import User from "./User";

class SessionStorage {

  key = 'session';

  setUser = (user: IUser) => {
    return new Promise((resolve, reject) => {
      if (window.top) {
        if (user) {
          const userObject = {
            id: user.getId(),
            name: user.getDisplayName(),
            email: user.getEmail()
          };
          const userJson = JSON.stringify(userObject);
          window.top.localStorage.setItem(this.key, userJson);
        } else {
          window.top.localStorage.removeItem(this.key);
        }
      }
      resolve(user);
    });
  };

  getUser = (): Promise<undefined | IUser> => {
    return new Promise((resolve, reject) => {
      if (window.top) {
        const userJson = window.top.localStorage.getItem(this.key);
        if (userJson) {
          const userObject = JSON.parse(userJson);
          const photoURL = null;
          const user = new User(
            userObject.id,
            userObject.name,
            userObject.email,
            photoURL);
          resolve(user);
        } else {
          resolve(undefined);
        }
      } else {
        resolve(undefined);
      }
    });
  };

}

export default new SessionStorage();
