
export interface Range {
  start: number
  end: number
}

class SelectionUtil {

  ie: boolean;
  w3: boolean;

  /**
   * get/set caret position
   * @param {HTMLColletion} target
   */
  constructor() {
    const documentAny = document as any;
    this.ie = (typeof documentAny.selection !== "undefined" && documentAny.selection.type !== "Control") && true;
    this.w3 = (typeof window.getSelection !== "undefined") && true;
  }

  focusWithCaretAtEnd = (inputElement) => {
    // Notice that we are also using setTimeout. The reason for it is that Chrome has some bug which 
    // causes the focus event to get fired before the caret is moved into the input.
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd = inputElement.value.length;
      inputElement.focus();
    }, 0);
  }

  /**
   * get caret position
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range}
   * @returns {number}
   */
  getRange = (element): Range => {
    let selectionStart = 0;
    let selectionEnd = 0;
    if (this.w3) {
      const selection = window.getSelection();
      if (selection) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        selectionStart = preCaretRange.toString().length;
        const selectionLength = range.toString().length;
        selectionEnd = selectionStart + selectionLength;
      }
    } else if (this.ie) {
      const documentAny = document as any;
      const selection = documentAny.selection;
      if (selection && documentAny.body && documentAny.body.createTextRange) {
        const textRange = selection.createRange();
        const preCaretTextRange = documentAny.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        selectionStart = preCaretTextRange.text.length;
        const selectionLength = textRange.text.length;
        selectionEnd = selectionStart + selectionLength;
      }
    }
    return {
      start: selectionStart,
      end: selectionEnd
    };
  }

  movePos(offset, element) {
    // element.focus();
    // if (this.w3) {
    //   const selection = window.getSelection();
    //   // selection.collapse(element, pos);
    //   // selection.extend(element, pos);
    //   selection.collapse(element, offset);
    // }
  }

  /**
   * get caret position
   * @param {number} pos - caret position
   */
  getPos = (element): undefined | number => {
    const range = this.getRange(element);
    if (range) {
      return range.start;
    } else {
      return undefined;
    }
  }

  /**
   * set caret position
   * @param {number} pos - caret position
   */
  setPos(pos, element) {
    // if (this.isContentEditable) {
    //   this.currentTarget.focus();
    //   // const selection = document.getSelection();
    //   const selection = window.getSelection();
    //   selection.collapse(this.currentTarget, pos);
    //   // return;
    // }
    // this.currentTarget.setSelectionRange(pos, pos);

    element.focus();

    const currentPosition = this.getPos(element);
    if (currentPosition === undefined) {
      return;
    }
    // const offset = pos - currentPosition;

    if (this.w3) {
      // const selection = window.getSelection();
      // selection.collapse(element, pos);
      // selection.extend(element, pos);
      // selection.collapse(element, offset);


      // const range = selection.getRangeAt(0);
      // const preCaretRange = range.cloneRange();
      // preCaretRange.selectNodeContents(element);
      // preCaretRange.setEnd(range.endContainer, range.endOffset);
      // caretOffset = preCaretRange.toString().length;
    } else if (this.ie) {
      // const textRange = document.selection.createRange();
      // const preCaretTextRange = document.body.createTextRange();
      // preCaretTextRange.moveToElementText(element);
      // preCaretTextRange.setEndPoint("EndToEnd", textRange);
      // caretOffset = preCaretTextRange.text.length;
    }
  }
}

export default new SelectionUtil();