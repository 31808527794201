import colorRange from '../../../../commonbase/color/redToGreenColorRange';
import ScoreMeta from './ScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';

class PercentageScoreMeta extends ScoreMeta {

  constructor() {
    super(scoreTypes.standard);
    // super('Percentage', markers);
  }

  roundInputScorePercent = (scorePercent) => {
    const rounded = Math.round(scorePercent);
    if (rounded < 0) {
      return 0;
    } else if (rounded > 100) {
      return 100;
    } else {
      return rounded;
    }
  };

  scoreToPercent = (score) => {
    const scorePercent = score;
    return scorePercent;
  };

  percentToScore = (scorePercent) => {
    const score = scorePercent;
    return score;
  };

  shouldRenderEmojiForAssessmentItem = (assessmentItem) => {
    return false;
  };

  getColorRange = () => {
    return colorRange;
  }

}

export default new PercentageScoreMeta();
