import PropTypes from 'prop-types';
import React, { FocusEvent, KeyboardEvent } from 'react';
import actions from '../../../shared/actions/Actions';
import authUtil from '../../../shared/model/auth/AuthUtil';
import appNavInfo from './AppNavInfo';
import constants from '../../../shared/model/Constants';
import currentNotifications from '../../../backend/data/CurrentNotifications';
import currentReport from '../../../shared/model/rubric/CurrentReport';
import currentRubric from '../../../shared/model/rubric/CurrentRubric';
import featureFlags from '../../../shared/model/feature/FeatureFlags';
import navConstants from './NavConstants';
import navState from './NavState';
import navUtil from './NavUtil';
import session  from '../../../shared/model/auth/Session';
import urlUtil from '../../../shared/util/UrlUtil';
import UserAvatar from '../widget/UserAvatar';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IUser from '../../../shared/model/auth/IUser';
import RubricReference from '../../../shared/model/rubric/RubricReference';
import NavItem from './NavItem';
import Button from '@atlaskit/button';
import Select from '@atlaskit/select';
import SelectOption from '../../../shared/model/widget/SelectOption';
import TopNavigationSearchPanel from './TopNavigationSearchPanel';
import CreateDefinitionDialog from './CreateDefinitionDialog';
import DefinitionSearchResult from '../../../shared/model/rubric/definition/DefinitionSearchResult';
import Definition from '../../../shared/model/rubric/definition/Definition';
import FormlessTextField from '../widget/FormlessTextField';
import { CreateRubricIcon, SearchIcon } from '../icon/NamedIcons';
import TopNavigationRubricsDropdownPanel from './TopNavigationRubricsDropdownPanel';
import DefinitionPreferencesUpdateContext from '../../../shared/model/rubric/preference/DefinitionPreferencesUpdateContext';

const dropNavButtonLabelsWidth = 900;
const dropAppTitleFromButtonWidth = 1000;
const dropCreateRubricButtonLabelFullWidth = 1000;

interface Props extends RouteComponentProps {
  history?: any
  onItemClicked: (event: any) => void
}

interface State {
  firestoreReadCount: number
  firestoreWriteCount: number
  currentContainerKey: string
  // user: unknown | IUser
  user?: IUser
  notifications: any[]
  unacknowledgedNotificationCount: number
  navInfo: NavItem[]
  definitionUuidsToNames: any
  definitionUuidsToPreferences: any
  recentAccesses: RubricReference[]
  discoverReferences: RubricReference[]
  redirectTo: any
  openDrawer?: any
  assessmentsVisibleInRecents?: boolean
  searchString: string
  showSearchResults: boolean
  showRubricsDropdown: boolean
  showRubricsDropdownTimestamp: number,
  lastVisitedAppNavItem?: NavItem
  createDialogOpen: boolean
}

class TopNavigation extends React.Component<Props, State> {

  mounted = false;
  uiController: any;
  searchInputElement: any;

  constructor(props) {
    super(props);
    const navInfo = appNavInfo.getNavItems();
    const user: unknown | IUser = session.getCurrentUser();
    const state = {
      firestoreReadCount: 0,
      firestoreWriteCount: 0,
      currentContainerKey: navConstants.productContainerKey,
      user: user,
      notifications: [],
      unacknowledgedNotificationCount: 0,
      navInfo: navInfo,
      definitionUuidsToNames: {},
      recentAccesses: [],
      discoverReferences: [],
      definitionUuidsToPreferences: {},
      redirectTo: undefined,
      searchString: '',
      showSearchResults: false,
      showRubricsDropdown: false,
      showRubricsDropdownTimestamp: 0,
      createDialogOpen: false
    };
    this.state = state as State;
  }

  static contextTypes = {
    navOpenState: PropTypes.object,
    router: PropTypes.object,
  };

  UNSAFE_componentWillMount() {
    // console.log('TopNavigation.componentWillMount...');
    currentNotifications.registerListener(this.onCurrentNotificationsChange);
    currentReport.registerListener(this.onCurrentReportChange);
    currentRubric.registerListener(this.onCurrentRubricChange);
    actions.registerListener(this.onAction);
    session.registerListener(this.onSessionChange);
  }

  componentDidMount() {
    // console.log('TopNavigation.componentDidMount...');
    this.mounted = true;
    const user = session.getCurrentUser();
    this.setState({
      user: user,
      notifications: currentNotifications.getNotifications(),
      unacknowledgedNotificationCount: currentNotifications.countUnacknowledgedNotifications()
    });
  }

  componentWillUnmount() {
    // console.log('TopNavigation.componentWillUnmount...');
    currentNotifications.unregisterListener(this.onCurrentNotificationsChange);
    currentReport.unregisterListener(this.onCurrentReportChange);
    currentRubric.unregisterListener(this.onCurrentRubricChange);
    actions.unregisterListener(this.onAction);
    session.unregisterListener(this.onSessionChange);
    // document.removeEventListener('keydown', this.onKeyDown, false);
    this.mounted = false;
  }

  updateDefinitionPreferences = (recentAccesses: RubricReference[], definitionPreferencesUpdateContext: DefinitionPreferencesUpdateContext) => {
    const oldDefinitionUuidsToPreferences: any = this.state.definitionUuidsToPreferences;
    const newDefinitionUuidsToPreferences: any = {};
    for (let i = 0; i < recentAccesses.length; i++) {
      const recentAccess = recentAccesses[i];
      if (recentAccess.definitionUuid === definitionPreferencesUpdateContext.definitionUuid) {
        newDefinitionUuidsToPreferences[recentAccess.definitionUuid] = definitionPreferencesUpdateContext.definitionPreferences;
      } else {
        newDefinitionUuidsToPreferences[recentAccess.definitionUuid] = oldDefinitionUuidsToPreferences[recentAccess.definitionUuid];
      }
    }
    this.setState({
      definitionUuidsToPreferences: newDefinitionUuidsToPreferences
    });
  };

  setCurrentContainer = (containerKey) => {
    this.setState({
      currentContainerKey: containerKey
    });
  };

  setCurrentDrawer = (openDrawer) => {
    this.setState({
      openDrawer: openDrawer
    });
    if (openDrawer && this.uiController) {
      this.uiController.expand();
      navState.setSidebarExpanded(true);
      actions.onSidebarVisibilityChange();
    }
  };

  // isDrawerVisible = (drawerKey) => {
  //   if (this.state.openDrawer === drawerKey) {
  //     if (this.uiController && this.uiController.state && !this.uiController.state.isCollapsed) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  isCurrentPageHash = (pageHash) => {
    const currentPathname = this.props.history.location.pathname;
    if (currentPathname === pageHash) {
      return true;
    }
    return false;
  };

  onHomeClick = () => {
    this.props.history.push('/');
  };

  onNavItemClicked = (navItem: NavItem) => {
    this.setState({
      currentContainerKey: navItem.containerKey
    });
    if (navItem.containerKey === navConstants.appContainerKey) {
      this.setState({
        lastVisitedAppNavItem: navItem
      });
    }
    if (navItem.url) {
      // this.props.history.push(navItem.url);
      window.location.href = navItem.url;
    } else {
      this.redirect(navItem.path, navItem.containerKey);
    }
  };

  onItemClicked = (event: any, handler) => {
    if (handler) {
      event.preventDefault();
      handler();
    } else {
      this.props.onItemClicked(event);
    }
  };

  onSignInClick = () => {
    this.productPageRedirect(navConstants.signInPageHash);
  };

  onSignOut = () => {
    session.signOut();
  };

  onProfilePageClick = () => {
    this.productPageRedirect(navConstants.profilePageHash);
  };

  onNotificationsPageClick = () => {
    this.setCurrentContainer(navConstants.appContainerKey);
    this.appPageRedirect(navConstants.notificationsPageHash);
  };

  onProductToAppLinkClick = () => {
    this.setCurrentContainer(navConstants.appContainerKey);
    this.redirect(navConstants.defaultAppPageHash);
  };

  onCurrentNotificationsChange = (notifications, unacknowledgedNotificationCount) => {
    if (!this.mounted) {
      return null;
    }
    if (this.mounted) {
      this.setState({
        notifications: notifications,
        unacknowledgedNotificationCount: unacknowledgedNotificationCount
      });
      // this.forceUpdate();
    }
  };

  onCurrentReportChange = (state) => {
    if (!this.mounted) {
      return null;
    }
    const scoreUuid = state.assessmentUuid;
    const scoreName = state.assessmentName;
    const encodedName = encodeURIComponent(scoreName);
    const path = navConstants.reportPageHash + '/:' + scoreUuid + '/:' + encodedName;
    this.props.history.push(path);
  };

  onCurrentRubricChange = (state) => {
    if (!this.mounted) {
      return null;
    }
    if (this.mounted) {
      const definition = state.definition;
      let newPathname: undefined | string = undefined;
      if (definition) {
        if (currentRubric.isInEditMode()) {
          newPathname = navUtil.makePath(navConstants.editPageHash, state.definitionUuid);
        } else {
          const encodedName = encodeURIComponent(state.definitionName);
          newPathname = navUtil.makePath(navConstants.viewPageHash, state.definitionUuid, encodedName);
        }
      } else {
        newPathname = navUtil.makePath(navConstants.homePageHash);
      }
      const currentPathname = this.props.history.location.pathname;
      if (newPathname === currentPathname) {
        // do nothing
      } else {
        newPathname = urlUtil.preserveAnchorId(newPathname);
        if (newPathname) {
          this.props.history.push(newPathname);
        }
      }
      if (this.state.user && definition) {
        this.setState({
          currentContainerKey: navConstants.appContainerKey
        });
        // const element = document.getElementById('main-content-panel');
        // new ScrollController(element).scrollToElement();
      }
    }
  };

  appPageRedirect = (path) => {
    this.redirect(path, navConstants.appContainerKey);
  };

  onGlobalNavItemClick = (navItem: NavItem) => {
    if (navItem.url) {
      this.props.history.push(navItem.url);
    } else {
      this.redirect(navItem.path, navItem.containerKey);
    }
  };

  productPageRedirect = (path) => {
    this.redirect(path, navConstants.productContainerKey);
  };

  redirect = (path: string, containerKey?: string) => {
    if (containerKey) {
      this.setCurrentContainer(containerKey);
    }
    this.props.history.push(path);
  };

  onAction = (actionId: string, context: any) => {
    if (!this.mounted) {
      return null;
    }
    if (actionId === actions.onMainBodyClickActionId) {
      if (this.state.showRubricsDropdown) {
        this.setState({
          showRubricsDropdown: false
        });
      }
    } else if (actionId === actions.onNavBarClickActionId) {
      if (this.state.showRubricsDropdown) {
        const now = new Date().getTime();
        if (now - this.state.showRubricsDropdownTimestamp > 1000) {
          this.setState({
            showRubricsDropdown: false
          });
        }
      }
    } else if (actionId === actions.collapseDrawerActionId) {
      if (this.uiController) {
        this.uiController.collapse();
        navState.setSidebarExpanded(false);
        actions.onSidebarVisibilityChange();
      }
    } else if (actionId === actions.newRubricActionId) {
      this.setCurrentDrawer(navConstants.createDrawerKey);
      if (this.uiController) {
        this.uiController.expand();
        navState.setSidebarExpanded(true);
        actions.onSidebarVisibilityChange();
      }
    } else if (actionId === actions.visitBoardActionId) {
      this.appPageRedirect(navConstants.boardPageHash);
    } else if (actionId === actions.visitDashboardActionId) {
      this.appPageRedirect(navConstants.dashboardPageHash);
    } else if (actionId === actions.visitDriveActionId) {
      this.appPageRedirect(navConstants.drivePageHash);
    } else if (actionId === actions.definitionPreferencesUpdateContextId) {
      const definitionPreferencesUpdateContext = context as DefinitionPreferencesUpdateContext;
      this.updateDefinitionPreferences(this.state.recentAccesses, definitionPreferencesUpdateContext);
    }
  };

  onSessionChange = (user: undefined | IUser) => {
    if (!this.mounted) {
      return null;
    }
    if (this.mounted) {
      this.setState({
        user: user
      });
      if (user) {
        const currentPathname = this.props.history.location.pathname;
        if (currentPathname === navConstants.signInPageHash) {
          this.props.history.push(navConstants.homePageHash);
        }

        const currentNavItem = this.getCurrentNavInfo();
        if (currentNavItem) {
          this.setState({
            currentContainerKey: currentNavItem.containerKey
          });
          if (currentNavItem.containerKey === navConstants.appContainerKey) {
            this.setState({
              lastVisitedAppNavItem: currentNavItem
            });
          }
        }
      } else {
        this.setState({
          currentContainerKey: navConstants.productContainerKey
        });
        // this.props.history.push(navConstants.homePageHash);
      }
    }
  };

  onDefinitionClicked = (definition: Definition): void => {
    this.setState({
      showRubricsDropdown: false
    });
    this.setCurrentDrawer(null);
    const inEditMode = false;
    // const definition: undefined | Definition = await result.resolveDefinition();
    if (definition) {
      currentRubric.setDefinition(definition, inEditMode);
    } else {
      // currentRubric.setDefinitionByUuid(result.definitionUuid, inEditMode);
    }
    if (this.searchInputElement) {
      this.searchInputElement.blur();
    }
  }

  onSearchResultClicked = async (result: DefinitionSearchResult) => {
    this.setCurrentDrawer(null);
    const inEditMode = false;
    const definition: undefined | Definition = await result.resolveDefinition();
    if (definition) {
      currentRubric.setDefinition(definition, inEditMode);
    } else {
      // currentRubric.setDefinitionByUuid(result.definitionUuid, inEditMode);
    }
    if (this.searchInputElement) {
      this.searchInputElement.blur();
    }
  };

  onSearchTextKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event) {
      this.searchInputElement = event.target;
      if (event.keyCode === 27) {
        this.searchInputElement.blur();
      } else {
        const searchString = event.currentTarget.value;
        this.setState({
          showSearchResults: true,
          searchString: searchString
        });
      }
    }
  };

  onSearchInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({
      showSearchResults: true
    });
  };

  onSearchInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({
      showSearchResults: false
    });
  };

  onRubricsButtonClick = () => {
    if (this.state.showRubricsDropdown) {
      this.setState({
        showRubricsDropdown: false
      });
    } else {
      this.setState({
        showRubricsDropdown: true,
        showRubricsDropdownTimestamp: new Date().getTime()
      });
    }
  }

  onMenuItemSelection = (selectionValue) => {
    // args should be: value: ValueType<OptionType, IsMulti>, action: ActionMeta<OptionType>
    const selection = selectionValue as SelectOption;
    for (const navItem of this.state.navInfo) {
      if (navItem.uuid === selection.value) {
        this.onNavItemClicked(navItem);
      }
    }
  };

  getCurrentNavInfo = () => {
    const hash = urlUtil.getHash();
    if (hash) {
      for (const navInfo of this.state.navInfo) {
        if (navInfo.path && navInfo.path.indexOf('/') === 0) {
          const navInfoHash = '#' + navInfo.path;
          if (navInfoHash === hash) {
            return navInfo;
          }
        }
      }
    }
    return undefined;
  };

  _shouldLinkBeVisible = (navItem: NavItem, containerKey: String) => {
    const title = navItem.title;
    const Icon = navItem.icon;
    const user = session.getCurrentUser();
    const showNonAnonymousItems = !!user;
    let visible = navItem.navItemVisible && title && Icon && navItem.containerKey === containerKey && (navItem.allowAnonymous || showNonAnonymousItems);
    if (visible) {
      if (navItem.path === navConstants.pricingPageHash) {
        if (featureFlags.requirePaymentsForAdvancedFeatures()) {
          visible = false;
        } else {
          const user = session.getCurrentUser();
          if (user) {
            if (authUtil.isAtlassianUser()) {
              visible = false;
            }
          } else {
            visible = false;
          }
        }
      }
    }
    return visible;
  };

  onVisitAppClick = () => {
    this.onNavItemClicked(this.state.lastVisitedAppNavItem ? this.state.lastVisitedAppNavItem : appNavInfo.defaultAppNavItem);
  }

  onCreateDefinitionButtonClick = () => {
    this.setState({
      createDialogOpen: true
    });
  }

  shouldNavItemBeVisible = (navItem: NavItem, containerKey: string, topNavPosition: string): boolean => {
    let visible = navItem.topNavPosition === topNavPosition && this._shouldLinkBeVisible(navItem, containerKey);
    if (visible) {
      if (navItem === appNavInfo.notificationsItem) {
        const notificationsCount = this.state.notifications ? this.state.notifications.length : 0;
        visible = notificationsCount > 0;
      }
    }
    return visible;
  }

  renderNavItems = (containerKey: string, topNavPosition: string) => {
    const items: any[] = [];
    for (const navItem of this.state.navInfo) {
      const visible = this.shouldNavItemBeVisible(navItem, containerKey, topNavPosition);
      if (visible) {
        const isSelected = this.isCurrentPageHash(navItem.path);
        const Icon = navItem.icon;
        const item = (
          <Button
            key={`${navItem.uuid}`}
            iconBefore={<Icon/>}
            appearance={isSelected ? 'subtle-link' : 'link'}
            onClick={() => this.onNavItemClicked(navItem)}
          >
            <span className={`show-when-wider-${dropNavButtonLabelsWidth}`}>{navItem.title}</span>
          </Button>
        );
        items.push(item);
      }
    }
    return items;
  };

  renderWebsiteNavItems = () => {
    const options: SelectOption[] = [];
    for (const navItem of this.state.navInfo) {
      if (navItem.containerKey === navConstants.productContainerKey && navItem.title) {
        const option: SelectOption = {
          label: navItem.title,
          value: navItem.uuid
        };
        options.push(option);
      }
    }
    const selectedOption: SelectOption = {
      label: 'About',
      value: 'this-item-is-not-selectable'
    }
    const customStyles = {
      control: base => ({
        ...base,
        height: 32,
        minHeight: 32,
        backgroundColor: '#fff'
      })
    };
    return (
      <div className="nav-item-left nav-dropdown-menu">
        <Select
          styles={customStyles}
          label={undefined}
          className="single-select"
          classNamePrefix="react-select"
          isSearchable={false}
          placeholder={undefined}
          value={selectedOption}
          options={options}
          onChange={this.onMenuItemSelection}
        />
      </div>
    );
  };

  renderAppNavItem = () => {
    return (
      <div className="nav-item-left">
        <Button
          appearance="primary"
          onClick={this.onVisitAppClick}
        >
          <span className={`show-when-wider-${dropAppTitleFromButtonWidth}`}>{constants.appTitle} </span>app
        </Button>
      </div>
    );
  };

  renderCreateDefinitionButton = () => {
    return (
      <div className="nav-item-left">
        <Button
          iconBefore={<CreateRubricIcon label="Create rubric"/>}
          appearance="default"
          onClick={this.onCreateDefinitionButtonClick}
        >
          <span className={`show-when-wider-${dropNavButtonLabelsWidth}`}>Create</span><span className={`show-when-wider-${dropCreateRubricButtonLabelFullWidth}`}> a rubric</span>
        </Button>
      </div>
    );
  };

  renderSearch = () => {
    const user = this.state.user;
    if (user) {
      return (
        <div
          key={user ? user.getId() : ''}
          className="nav-item-right nav-search-input-container"
        >
          <FormlessTextField
            name="search"
            label={undefined}
            placeholder="Search..."
            value={this.state.searchString}
            onKeyUp={this.onSearchTextKeyUp}
            onFocus={this.onSearchInputFocus}
            onBlur={this.onSearchInputBlur}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderRubricsButton = () => {
    return (
      <div style={{marginLeft: '6px'}}>
        <Button
          appearance="default"
          iconBefore={<SearchIcon label="Search rubrics" />}
          onClick={this.onRubricsButtonClick}
        >
          <span className={`show-when-wider-${dropNavButtonLabelsWidth}`}>Rubrics</span>
        </Button>
      </div>
    );
  }

  render() {
    if (!this.mounted) {
      return null;
    }
    const user = this.state.user;
    let profileAvatar: any = undefined;
    if (user) {
      profileAvatar = (
        <UserAvatar
          size="small"
          onClick={this.onProfilePageClick}
        />
      );
    }
    const dropDownPanel = featureFlags.showRubricsNavigationButton() ?
      this.state.user ? <TopNavigationRubricsDropdownPanel
        user={this.state.user}
        visible={this.state.showRubricsDropdown}
        onDefinitionClicked={this.onDefinitionClicked}
      /> : null :
      <TopNavigationSearchPanel
        searchString={this.state.searchString}
        showSearchResults={this.state.showSearchResults}
        onResultClicked={this.onSearchResultClicked}
      />;
    return (
      <div>
        <div
          className="top-nav-container"
          onClick={actions.onNavBarClick}
        >
          <div className="top-nav-container-side top-nav-container-left">
            <div
              className="top-nav-icon clickable"
              onClick={this.onHomeClick}
            >
            </div>
            {this.state.currentContainerKey === navConstants.appContainerKey ? this.renderRubricsButton() : null}
            {this.renderNavItems(this.state.currentContainerKey, navConstants.topNavPositionLeft)}
            {this.state.currentContainerKey === navConstants.appContainerKey ? this.renderCreateDefinitionButton() : null}
            {this.state.currentContainerKey === navConstants.appContainerKey ? this.renderWebsiteNavItems() : this.renderAppNavItem()}
          </div>
          <div className="top-nav-container-side top-nav-container-right">
            {this.renderNavItems(this.state.currentContainerKey, navConstants.topNavPositionRight)}
            <div className="xxx">
              {profileAvatar}
            </div>
          </div>
        </div>
        <CreateDefinitionDialog
          visible={this.state.createDialogOpen}
          onItemClicked={() => { this.setState({ createDialogOpen: false})}}
          onClose={() => { this.setState({ createDialogOpen: false }) }}
        />
        {dropDownPanel}
      </div>
    );
  }

}

export default withRouter(TopNavigation);
