import adg from '../../../commonbase/adg';
import AssessmentItem from './score/AssessmentItem';
import backgroundRetriever from '../../../commonbase/media/backgroundRetriever';
import csvUtil from '../../../commonbase/csvUtil';
import firebaseApp from '../../../backend/firebase/FirebaseApp';
import roleUtil from '../../model/auth/RoleUtil';
import scoreMetaDefinitions from './score/ScoreMetaDefinitions';
import statusDefinitions, { StatusConfig } from './status/StatusDefinitions';
import { RowBuilder } from './RubricDefinitionBuilder';
import permissionUtil from "../auth/PermissionUtil";
import CustomLevelsScoreMeta from './score/CustomLevelsScoreMeta';
import CustomLevelsScoreMetaInfoBuilder from '../../../commonbase/score/CustomLevelsScoreMetaInfoBuilder';
import { StatementBuilder } from './RubricDefinitionBuilder';
import util from '../../util/Util';
import constants from '../Constants';
import scoreUtil from './score/ScoreUtil';
import Uuid from '../../util/Uuid';
import {ColumnTotal} from "./ColumnTotal";
import Definition, { Discoverability, Editability } from './definition/Definition';
import Assessment from './score/Assessment';
import GuidanceMetadata from './definition/GuidanceMetadata';
import Statement from './definition/Statement';
import GuidanceItem from './definition/GuidanceItem';
import I18N from '../i18n/I18N';
import AssessmentSnapshot from './score/AssessmentSnapshot';
import AssessmentSnapshotData from './score/AssessmentSnapshotData';
import AssessmentColumnScoreInfo from './score/AssessmentColumnScoreInfo';

export class RubricUtil {

  isAssessmentReference = (fileReference) => {
    return fileReference && fileReference.assessmentUuid;
  };

  areDefinitionsEqual = (definitionA: Definition, definitionB: Definition): boolean => {
    if (definitionA === undefined && definitionB === undefined) {
      return true;
    } else if (definitionA === undefined) {
      return false;
    } else {
      return definitionA.uuid === definitionB.uuid;
    }
  }

  equalReferences = (refA, refB) => {
    if (refA.definitionUuid === refB.definitionUuid) {
      if (refA.assessmentUuid === refB.assessmeStatusntUuid) {
        return true;
      }
    }
    return false;
  };

  getAspectHeader = (definition: Definition): string => {
    return definition.aspectHeaderName ? definition.aspectHeaderName : I18N.Area;
  }

  getStatementLabel = (definition: Definition): string => {
    const columnCount = this.countColumns(definition);
    if (columnCount === 1) {
      const column = definition.columns[0];
      if (column.name) {
        return column.name;
      } else {
        return I18N.Consideration;
      }
    } else {
      return I18N.Aspect;
    }
  }

  getScoreMeta = (definition) => {
    const scoreMetaType = this.getScoreMetaType(definition);
    if (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType) {
      return this.buildCustomLevelsScoreMeta(definition);
    } else {
      return scoreMetaDefinitions.scoreMetaFromType(scoreMetaType);
    }
  };

  getScoreMetaFromTypeAndInfo = (scoreMetaType, customLevelsScoreInfo) => {
    if (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType) {
      return this.buildCustomLevelsScoreMetaFromInfo(customLevelsScoreInfo);
    } else {
      return scoreMetaDefinitions.scoreMetaFromType(scoreMetaType);
    }
  };

  getAssessmentStatus = (assessment: Assessment): string => {
    return assessment.statusId ? assessment.statusId : statusDefinitions.getDefaultStatusId();
  };

  setAssessmentStatus = (assessment, statusId) => {
    assessment.statusId = statusId;
  };

  setStatus = (definition, assessment, statusId) => {
    assessment.statusId = statusId;
  };

  getStatusIdsToConfigs = (definition, assessment) => {
    return statusDefinitions.getStatusIdsToConfigs();
  };

  getStatusConfigById = (statusId): StatusConfig => {
    let currentStatusConfig;
    const definition = null; // TODO
    const assessment = null; // TODO
    const statusIdsToConfigs = this.getStatusIdsToConfigs(definition, assessment);
    if (statusId) {
      currentStatusConfig = statusIdsToConfigs[statusId];
    }
    if (!currentStatusConfig) {
      currentStatusConfig = statusDefinitions.getDefaultStatusConfig();
    }
    return currentStatusConfig;
  };

  // TODO: get rid of def param
  getCurrentStatusConfig = (definition, assessment): StatusConfig => {
    return this.getStatusConfigById(assessment.statusId);
  };

  buildCustomLevelsScoreMeta = (definition) => {
    return this.buildCustomLevelsScoreMetaFromInfo(definition.customLevelsScoreInfo);
  };

  buildCustomLevelsScoreMetaFromInfo = (customLevelsScoreInfo) => {
    if (customLevelsScoreInfo) {
      const customLevelsScoreMetaBuilder = new CustomLevelsScoreMetaInfoBuilder()
        .setLabel(customLevelsScoreInfo.label);
      for (const level of customLevelsScoreInfo.levels) {
        customLevelsScoreMetaBuilder.addLevel(level);
      }
      // return customLevelsScoreMetaBuilder.build();
      const metaInfo = customLevelsScoreMetaBuilder.build();
      return new CustomLevelsScoreMeta(metaInfo.label, metaInfo.markers);
      // const markers = customLevelsScoreMetaBuilder.build();
      // return new CustomLevelsScoreMeta(customLevelsScoreInfo.label, markers);
    } else {
      return scoreMetaDefinitions.scoreMetaFromType(scoreMetaDefinitions.standardScoreMetaType);
    }
  };

  getScoreMetaType = (definition) => {
    if (definition) {
      if (definition.options && definition.options.scoreMetaType) {
        return definition.options.scoreMetaType;
      } else {
        // This else is for backward compatibility since the scoreMetaType was introduced
        // after some rubrics were created.
        return scoreMetaDefinitions.standardScoreMetaType;
      }
    } else {
      return scoreMetaDefinitions.standardScoreMetaType;
    }
  };

  getCustomLevelsScoreInfo = (definition) => {
    return definition ? definition.customLevelsScoreInfo : undefined;
  };

  setScoreMetaType = (definition, scoreMetaType) => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.scoreMetaType = scoreMetaType;
  };

  setDefinitionBackgroundImageId = (definition: Definition, backgroundImageId: string) => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.backgroundImageId = backgroundImageId;
  };

  getDefinitionBackgroundImageId = (definition: Definition): undefined | string => {
    if (definition.options) {
      return definition.options.backgroundImageId;
    } else {
      return undefined;
    }
  };

  getDefinitionBackgroundImage = (definition: Definition, defaultBackgrounImageId: string): undefined | any => {
    const backgroundImageId = this.getDefinitionBackgroundImageId(definition);
    if (backgroundImageId) {
      const image = backgroundRetriever(backgroundImageId);
      if (image) {
        return image;
      }
    }
    if (defaultBackgrounImageId) {
      return backgroundRetriever(defaultBackgrounImageId);
    }
    return undefined;
  }

  // getDefinitionColorRangeType = (definition: Definition): string => {
  //   let colorRangeType: undefined | string = undefined;
  //   if (definition.options) {
  //     colorRangeType = definition.options.colorRangeType;
  //   }
  //   if (colorRangeType === undefined) {
  //     const scoreMeta = this.getScoreMeta(definition);
  //     return scoreMeta.getDefaultColorRange();
  //     // return colorRangeDefinitions.getDefaultType();
  //   } else {
  //     return colorRangeType;
  //   }
  // }

  setDefinitionColorRangeType = (definition: Definition, colorRangeType: string): void => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.colorRangeType = colorRangeType;
  }

  setDefinitionScoreMetaType = (definition, scoreMetaType) => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.scoreMetaType = scoreMetaType;
  };

  getDefinitionLikeCount = (definition) => {
    if (definition.metadata && definition.metadata.likeCount) {
      return definition.metadata.likeCount;
    } else {
      return 0;
    }
  };

  adjustDefinitionLikeCount = (definition, likeCountIncrease) => {
    const payload = {
      callType: 'adjustDefinitionLikeCount',
      definitionUuid: definition.uuid,
      likeCountIncrease: likeCountIncrease,
    };
    const functions = firebaseApp.getFunctions();
    return functions.httpsCallable('consolidatedCallHandler')(payload)
      .then((result: any) => {
        if (result && result.data && result.data.ok && result.data.newLikeCount !== undefined) {
          const newLikeCount = result.data.newLikeCount;
          if (!definition.metadata) {
            definition.metadata = {};
          }
          definition.metadata.likeCount = newLikeCount;
        }
        return result;
      })
      .catch((error) => {
        console.warn(error);
        return false;
      });

  };

  setCustomLevelsScoreInfo = (definition, customLevelsScoreInfo) => {
    definition.customLevelsScoreInfo = customLevelsScoreInfo;
  };

  addColumn = (definition, possibleOptions) => {
    if (!permissionUtil.canAddItemsToDefinition(definition)) {
      new Error('No permission to add to definition ' + definition.name);
    }
    const options = possibleOptions === undefined ? {} : possibleOptions;
    if (options.columnName === undefined) {
      options.columnName = '';
    }
    const colCount = definition.columns.length;
    let columnInsertionIndex = 0;
    if (options.after) {
      for (let columnIndex = 0; columnIndex < colCount; columnIndex++) {
        const column = definition.columns[columnIndex];
        if (options.relativeToColumn && options.relativeToColumn.uuid === column.uuid) {
          columnInsertionIndex = columnIndex + 1;
          break;
        }
      }
    } else {
      columnInsertionIndex = 0;
    }

    const newColumn = {
      uuid: util.uuid(),
      name: options.columnName
    };
    const newColumns = this._addColumnItem(definition.columns, newColumn, columnInsertionIndex);

    const callback = (row, group, groupIndex, rowIndex) => {
      const rowCells = row.cells;
      const newCell = {
        uuid: util.uuid(),
        statements: [{
          uuid: util.uuid(),
          text: ''
        }]
      };
      const newCells = this._addColumnItem(rowCells, newCell, columnInsertionIndex);
      row.cells = newCells;
    };
    this.iterateRows(definition, callback);

    definition.columns = newColumns;
  };

  removeColumn = (definition, columnToRemove) => {
    if (!permissionUtil.canRemoveItemsFromDefinition(definition)) {
      new Error('No permission to remove from definition ' + definition.name);
    }
    const colCount = definition.columns.length;
    let columnRemovalIndex = 0;
    for (let columnIndex = 0; columnIndex < colCount; columnIndex++) {
      const column = definition.columns[columnIndex];
      if (columnToRemove.uuid === column.uuid) {
        columnRemovalIndex = columnIndex;
        break;
      }
    }

    const newColumns = this._removeColumnItem(definition.columns, columnRemovalIndex);

    const callback = (row, group, groupIndex, rowIndex) => {
      const rowCells = row.cells;
      const newCells = this._removeColumnItem(rowCells, columnRemovalIndex);
      row.cells = newCells;
    };
    this.iterateRows(definition, callback);

    definition.columns = newColumns;
  };

  _addColumnItem = (items, newItem, columnInsertionIndex) => {
    const newColumns : any[] = [];
    let columnAdded = false;
    for (let columnIndex = 0; columnIndex < items.length; columnIndex++) {
      if (columnIndex === columnInsertionIndex) {
        const newColumn = newItem;
        newColumns.push(newColumn);
        columnAdded = true;
      }
      const item = items[columnIndex];
      newColumns.push(item);
    }
    if (!columnAdded) {
      const newColumn = newItem;
      newColumns.push(newColumn);
    }
    return newColumns;
  };

  _removeColumnItem = (items, columnRemovalIndex) => {
    const newColumns : any[] = [];
    for (let columnIndex = 0; columnIndex < items.length; columnIndex++) {
      if (columnIndex !== columnRemovalIndex) {
        const item = items[columnIndex];
        newColumns.push(item);
      }
    }
    return newColumns;
  };

  addRow = (definition, group, possibleOptions) => {
    if (!permissionUtil.canAddItemsToDefinition(definition)) {
      new Error('No permission to add to definition ' + definition.name);
    }
    const options = possibleOptions === undefined ? {} : possibleOptions;
    if (options.rowName === undefined) {
      options.rowName = '';
    }
    const afterRowUuid = options.afterRowUuid ? options.afterRowUuid : undefined;
    const rows : any[] = [];
    const colCount = definition.columns.length;
    const cells : any[] = [];
    const rowName = options.rowName;
    const addedRow = new RowBuilder()
        .setName(rowName)
        .setThemeName('Theme A')
        .setCells(cells)
        .build();
    for (let i = 0; i < colCount; i++) {
      // let statementText = 'Requirement ' + (i + 1);
      // if (rowName) {
      //   statementText = rowName + ': ' + statementText;
      // }
      const statementText = '';
      const statement = new StatementBuilder()
          .setText(statementText)
          .build();
      const cell = {
        uuid: util.uuid(),
        statements: [statement]
      };
      cells.push(cell);
    }
    let added = false;
    if (afterRowUuid === undefined) {
      rows.push(addedRow);
      added = true;
    }
    for (let i = 0; i < group.rows.length; i++) {
      const groupRow = group.rows[i];
      rows.push(groupRow);
      if (!added && groupRow.uuid === afterRowUuid) {
        rows.push(addedRow);
      }
    }
    group.rows = rows;
    return addedRow;
  };

  removeRow = (definition, group, rowUuid) => {
    if (!permissionUtil.canRemoveItemsFromDefinition(definition)) {
      new Error('No permission to remove from definition ' + definition.name);
    }
    const newRows : any[] = [];
    for (let rowIndex = 0; rowIndex < group.rows.length; rowIndex++) {
      const row = group.rows[rowIndex];
      if (row.uuid !== rowUuid) {
        newRows.push(row);
      }
    }
    group.rows = newRows;
  };

  hasOneOrMoreRows = (definition) => {
    let rowFound = false;
    if (definition && definition.groups) {
      for (let groupIndex = 0; groupIndex < definition.groups.length; groupIndex++) {
        const group = definition.groups[groupIndex];
        if (group.rows && group.rows.length) {
          rowFound = true;
          break;
        }
      }
    }
    return rowFound;
  };

  countColumns = (definition) => {
    if (definition) {
      return definition.columns.length;
    } else {
      return undefined;
    }
  }

  countRows = (definition) => {
    const count = {value: 0};
    this.iterateRows(definition, (row) => {
      count.value = count.value + 1;
    });
    return count.value;
  };

  computeColumnScores = (definition, assessment) => {
    const snapshotData = this.computeAssessmentSnapshotData(definition, assessment);
    return snapshotData.columnScoreInfos;
  };

  buildDefaultColumnVisibilities = (definition) => {
    const visibilities : any[] = [];
    for (let columnIndex = 0; columnIndex < definition.columns.length; columnIndex++) {
      visibilities.push(true);
    }
    return visibilities;
  };

  iterateVisibleAssessments = (definition, assessments, visibleLabels, columnVisibilities, callback, context) => {
    const definitionLabels = this.getDefinitionLabels(definition);
    const internalContext = {
      definitionLabels: definitionLabels,
      visibleLabels: visibleLabels,
      labelsToScoreInfos: {}
    };
    const internalCallback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, internalContext) => {
      const visibleLabels = internalContext.visibleLabels;
      const columnIsVisible = columnVisibilities[columnIndex] !== false;
      if (columnIsVisible) {
        for (let assessmentIndex = 0; assessmentIndex < assessments.length; assessmentIndex++) {
          const assessment = assessments[assessmentIndex];
          // const assessmentItem = this.getUserFacingAssessmentItem(assessment, statement.uuid);
          const assessmentItem = this.getInternalAssessmentItem(assessment, statement.uuid);
          if (assessmentItem) {
            const statementLabels = this.getStatementLabels(definition, statement.uuid);
            if (statementLabels.length > 0) {
              const intersectingLabels = this.filterLabels(statementLabels, visibleLabels);
              for (let labelIndex = 0; labelIndex < intersectingLabels.length; labelIndex++) {
                const label = intersectingLabels[labelIndex];
                const stopIterating = callback(row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, label, labelIndex, assessmentItem, context);
                if (stopIterating) {
                  return;
                }
              }
            } else {
              const label = undefined;
              const labelIndex = -1;
              const stopIterating = callback(row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, label, labelIndex, assessmentItem, context);
              if (stopIterating) {
                return;
              }
            }
          }
        }
      }
    };
    this.iterateStatements(definition, internalCallback, internalContext);
  };

  iterateStatements = (definition, callback, context) => {
    this.iterateRows(definition, (row, group, groupIndex, rowIndex) => {
      for (let columnIndex = 0; columnIndex < row.cells.length; columnIndex++) {
        const cell = row.cells[columnIndex];
        for (let statementIndex = 0; statementIndex < cell.statements.length; statementIndex++) {
          const statement = cell.statements[statementIndex];
          const stopIterating = callback(row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context);
          if (stopIterating) {
            return;
          }
        }
      }
    });
  };

  iterateRows = (definition, callback) => {
    if (definition && definition.groups) {
      for (let groupIndex = 0; groupIndex < definition.groups.length; groupIndex++) {
        const group = definition.groups[groupIndex];
        if (group.rows && group.rows.length) {
          for (let rowIndex = 0; rowIndex < group.rows.length; rowIndex++) {
            const row = group.rows[rowIndex];
            const stopIterating = callback(row, group, groupIndex, rowIndex);
            if (stopIterating) {
              return;
            }
          }
        }
      }
    }
  };

  countStatementsInRow = (row: any): number => {
    for (let columnIndex = 0; columnIndex < row.cells.length; columnIndex++) {
      const cell = row.cells[columnIndex];
      return cell.statements.length;
    }
    return 0;
  }

  iterateRow = (row: any, callback: any, context?: any) => {
    for (let columnIndex = 0; columnIndex < row.cells.length; columnIndex++) {
      const cell = row.cells[columnIndex];
      for (let statementIndex = 0; statementIndex < cell.statements.length; statementIndex++) {
        const statement = cell.statements[statementIndex];
        const stopIterating = callback(columnIndex, statement, statementIndex, context);
        if (stopIterating) {
          return;
        }
      }
    }
  };

  addStatementToCell = (definition, cell) => {
    if (!permissionUtil.canAddItemsToDefinition(definition)) {
      new Error('No permission to add to definition ' + definition.name);
    }
    const statement = new StatementBuilder()
      .setText('')
      .build();
    cell.statements.push(statement);
    return statement;
  };

  deleteCellStatementByUuid = (definition, cell, statementUuid) => {
    if (!permissionUtil.canRemoveItemsFromDefinition(definition)) {
      new Error('No permission to remove from definition ' + definition.name);
    }
    const newStatements : any[] = [];
    for (let statementIndex = 0; statementIndex < cell.statements.length; statementIndex++) {
      const statement = cell.statements[statementIndex];
      if (statement.uuid !== statementUuid) {
        newStatements.push(statement);
      }
    }
    cell.statements = newStatements;
  };

  getGuidance = (statement: Statement): undefined | GuidanceItem[] => {
    return statement.guidance;
  }

  setStatementGuidance = (definition: Definition, statement: Statement, guidance) => {
    statement.guidance = guidance;
  };

  getStatementGuidanceMetadata = (statement: Statement): undefined | GuidanceMetadata => {
    return statement.guidanceMetadata;
  }

  setStatementGuidanceMetadata = (definition: Definition, statement: Statement, guidanceMetadata: GuidanceMetadata) => {
    statement.guidanceMetadata = guidanceMetadata;
  };

  setEditability = (definition: Definition, editability: Editability) => {
    definition.editability = editability;
  };

  setDefinitionDiscoverability = (definition: Definition, discoverability: Discoverability) => {
    definition.discoverability = discoverability;
  };

  setAssessmentDiscoverability = (assessment: Assessment, discoverability: Discoverability) => {
    assessment.discoverability = discoverability;
  };

  shouldViewAssessmentsSideBySide = (definition, assessments) => {
    if (!definition || !assessments || assessments.length === 0) {
      return false;
    }
    const definitionColumnCount = this.countColumns(definition);
    let viewAssessmentsSideBySide = definitionColumnCount === 1 && assessments && assessments.length;
    if (definition && definition.options && definition.options.viewAssessmentsSideBySide === false) {
      viewAssessmentsSideBySide = false;
    }
    return viewAssessmentsSideBySide;
  };

  getAssessmentRole = (assessment, email, emailDomain): string => {
    const roles = assessment.roles ? assessment.roles : [];
    assessment.roles = roles;
    return roleUtil.getRoleByEmailAndDomain(roles, email, emailDomain);
  };

  getAssessmentEmailsToRole = (assessment) => {
    const roles = assessment.roles ? assessment.roles : [];
    const emailsToRoles = roleUtil.unflattenRolesArray(roles);
    return emailsToRoles;
  };

  setAssessmentRole = (assessment, email, role) => {
    const roles = assessment.roles ? assessment.roles : [];
    assessment.roles = roles;
    roleUtil.setRoleByEmail(roles, email, role);
  };

  removeAssessmentRole = (assessment, email) => {
    const roles = assessment.roles ? assessment.roles : [];
    assessment.roles = roles;
    roleUtil.removeRolesByEmail(roles, email);
  };

  setCsvLabels = (definition: Definition, csvLabels: string) => {
    const labels = csvUtil.csvToArray(csvLabels, true);
    definition.labels = labels;
  };

  getDefinitionStatements = (definition: Definition): Statement[] => {
    const statements: Statement[] = [];
    const context = undefined;
    this.iterateStatements(definition, (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context) => {
      statements.push(statement);
    }, context);
    return statements;
  };

  getDefinitionLabels = (definition: Definition): string[] => {
    if (definition.labels) {
      return definition.labels;
    } else {
      return [];
    }
  };

  getDefinitionPillars = (definition) => {
    const pillars : any[] = [];
    const pillarsMap = {};
    this.iterateRows(definition, (row) => {
      const pillar = this.extractPillarFromRowName(row.name);
      if (pillar && !pillarsMap[pillar]) {
        pillarsMap[pillar] = true;
        pillars.push(pillar);
      }
    });
    return pillars;
  };

  extractPillarFromRowName = (rowName) => {
    if (rowName) {
      const parts = rowName.split(':');
      if (parts.length > 1) {
        return parts[0].trim();
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  filterLabels = (labels, allowedLabels) => {
    const filteredLabels : any[] = [];
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      if (util.isInArray(label, allowedLabels, undefined)) {
        filteredLabels.push(label);
      }
    }
    return filteredLabels;
  };

  labelArraysInterset = (labelsA, labelsB) => {
    if (labelsA.length === 0 || labelsB.length === 0) {
      return false;
    }
    for (let i = 0; i < labelsA.length; i++) {
      const label = labelsA[i];
      if (util.isInArray(label, labelsB, undefined)) {
        return true;
      }
    }
    return false;
  };

  countVisibleLabelsInStatement = (definition, statement, visibleLabels) => {
    const statementLabels = this.getStatementLabels(definition, statement.uuid);
    const visibleLabelsInStatement = this.filterLabels(statementLabels, visibleLabels);
    return visibleLabelsInStatement.length;
  };

  countVisibleLabelsInCell = (definition, cell, visibleLabels) => {
    let count = 0;
    for (let i = 0; i < cell.statements.length; i++) {
      const statement = cell.statements[i];
      count = count + this.countVisibleLabelsInStatement(definition, statement, visibleLabels);
    }
    return count;
  };

  getStatementLabels = (definition, statementUuid) => {
    const statementUuidsToLabels = definition.statementUuidsToLabels ? definition.statementUuidsToLabels : {};
    const statementLabels = statementUuidsToLabels[statementUuid];
    if (statementLabels) {
      return statementLabels;
    } else {
      return [];
    }
  };

  setStatementLabels = (definition, statementUuid, labels) => {
    if (!definition.statementUuidsToLabels) {
      definition.statementUuidsToLabels = {};
    }
    definition.statementUuidsToLabels[statementUuid] = labels
  };

  getOptionEnableAspectWeights = (definition) => {
    if (!definition.options) {
      definition.options = {};
    }
    return definition.options.showAspectWeights === true;
  };

  setOptionEnableAspectWeights = (definition, showAspectWeights) => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.showAspectWeights = showAspectWeights;
    return this;
  };

  setOptionHideCompletedAspects = (definition, hideCompletedAspects) => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.hideCompletedAspects = hideCompletedAspects;
    return this;
  };

  setOptionHideZeroScores = (definition, hideZeroScores) => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.hideZeroScores = hideZeroScores;
    return this;
  };

  setOptionHideColumnTotals = (definition, hideColumnTotals) => {
    if (!definition.options) {
      definition.options = {};
    }
    definition.options.hideColumnTotals = hideColumnTotals;
    return this;
  };

  getRowWeight = (row) => {
    if (row.weight === undefined) {
      // backward compatibility since row weight was introduced.
      return 1;
    } else {
      return row.weight;
    }
  };

  setRowWeight = (row, weight) => {
    if (weight > 1) {
      weight = 1;
    } else if (weight < 0) {
      weight = 0;
    }
    row.weight = weight;
  };

  getColumn = (definition, columnIndex) => {
    if (columnIndex >= 0 && columnIndex < definition.columns.length) {
      return definition.columns[columnIndex];
    }
    return undefined;
  };

  setStatementText = (definition, statementUuid, statementText) => {
    if (!permissionUtil.canWriteDefinition(definition)) {
      new Error('No permission to set statement text ' + definition.name);
    }
    const context = undefined;
    this.iterateStatements(definition, (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex) => {
      if (statement.uuid === statementUuid) {
        statement.text = statementText;
        return true;
      }
    }, context);
  };

  setGeneralNotes = (assessment, generalNotes) => {
    assessment.generalNotes = generalNotes;
  };

  appendAssessmentGeneralNotes = (assessment, textToAdd, newSection) => {
    let newGeneralNotes = '';
    if (assessment.generalNotes) {
      const separator = newSection ? ' \n' : ' ';
      newGeneralNotes = assessment.generalNotes + separator + textToAdd;
    } else {
      newGeneralNotes = textToAdd;
    }
    if (newGeneralNotes.length > constants.maxAssessmentGeneralNotesLength) {
      throw new Error('The notes section of the assessment is too long');
    } else {
      assessment.generalNotes = newGeneralNotes;
    }
  };
  
  // setStatementScore = (assessment, statementUuid, value) => {
  //   scoreUtil.setStatementScore(assessment, statementUuid, value);
  // };
  //
  // setStatementAnnotation = (assessment, statementUuid, annotation) => {
  //   scoreUtil.setStatementAnnotation(assessment, statementUuid, annotation);
  // };

  computeMaxColumnTotal = (definition, assessments, viewAssessmentsSideBySide) => {
    let maxColumnTotal: undefined | number = undefined;
    const visibleLabelsFilter = undefined;
    const columnTotals = this.computeColumnTotals(definition, assessments, visibleLabelsFilter, viewAssessmentsSideBySide);
    for (const columnTotal of columnTotals) {
      if (columnTotal.value !== undefined) {
        if (maxColumnTotal === undefined || columnTotal.value > maxColumnTotal) {
          maxColumnTotal = columnTotal.value;
        }
      }
    }
    return maxColumnTotal;
  };

  computeOverallAssessmentProgressFraction = (definition, assessment, optionalVisibleLabelsFilter) => {
    const overallAssessmentInfo = this.computeOverallAssessmentInfo(definition, assessment, optionalVisibleLabelsFilter);
    // console.log('Roobrick computeOverallAssessmentProgressFraction: overallAssessmentInfo', overallAssessmentInfo);
    const progressFraction = overallAssessmentInfo.totalScoreCount ? 
      overallAssessmentInfo.providedScoreCount / overallAssessmentInfo.totalScoreCount : 0;
    // console.log('Roobrick computeOverallAssessmentProgressFraction: progressFraction', progressFraction);
    return progressFraction;
  }

  computeOverallAssessmentScore = (definition: Definition, assessment: Assessment, optionalVisibleLabelsFilter?: any) => {
    const overallAssessmentInfo = this.computeOverallAssessmentInfo(definition, assessment, optionalVisibleLabelsFilter);
    if (overallAssessmentInfo.providedScoreCount) {
      return overallAssessmentInfo.averageScore;
    } else {
      // Backward compatibly - could review this to see if usages handle undefined being returned instead of 0.
      return 0;
    }
  };

  buildSummaryScore = (definition: Definition, assessment: Assessment, optionalVisibleLabelsFilter?: any) => {
    const scoreMeta = this.getScoreMeta(definition);
    const summaryAssessmentInfo = this.computeOverallAssessmentInfo(definition, assessment, optionalVisibleLabelsFilter);
    let summaryScoreTextLabel = '';
    let summaryAssessmentColor = adg.adgGray;
    if (summaryAssessmentInfo.providedScoreCount) {
      const summaryScore = summaryAssessmentInfo.averageScore;
      const summaryScoreTextInfo = scoreMeta.computeScoreTextInfo(summaryScore);
      summaryScoreTextLabel = summaryScoreTextInfo.tooltip ?
        summaryScoreTextInfo.tooltip : summaryScoreTextInfo.label ? summaryScoreTextInfo.label : '(no score)';
      const summaryAssessmentItem = {
        score: summaryScore,
        annotation: ''
      }  
      summaryAssessmentColor = scoreMeta.assessmentItemToColour(summaryAssessmentItem);
    } else {
      summaryScoreTextLabel = '[no assessment provided]';
      summaryAssessmentColor = adg.adgGray;
    }
    return {
      averageScore: summaryAssessmentInfo.averageScore,
      providedScoreCount: summaryAssessmentInfo.providedScoreCount,
      label: summaryScoreTextLabel,
      color: summaryAssessmentColor
    }
  };

  computeOverallAssessmentInfo = (definition, assessment, optionalVisibleLabelsFilter) => {
    const overallAssessmentInfo = {
      averageScore: 0,
      providedScoreCount: 0,
      totalScoreCount: 0
    };
    const visibleLabelsFilter = optionalVisibleLabelsFilter;
    const viewAssessmentsSideBySide = false;
    const columnTotals = this.computeColumnTotals(definition, [assessment], visibleLabelsFilter, viewAssessmentsSideBySide);
    if (columnTotals && columnTotals.length) {
      let totalScore = 0;
      for (const columnTotal of columnTotals) {
        if (columnTotal.providedScoreCount) {
          if (columnTotal.value !== undefined) {
            totalScore += columnTotal.value;
          }
          // console.log('columnTotal:', columnTotal);
          overallAssessmentInfo.providedScoreCount = overallAssessmentInfo.providedScoreCount + columnTotal.providedScoreCount;
          overallAssessmentInfo.totalScoreCount = overallAssessmentInfo.totalScoreCount + columnTotal.totalScoreCount;
        }
      }
      overallAssessmentInfo.averageScore = totalScore / columnTotals.length;
    }
    return overallAssessmentInfo;
  };

  computeColumnTotals = (definition, assessments, visibleLabelsFilter, viewAssessmentsSideBySide): ColumnTotal[] => {
    const enableAspectWeights = this.getOptionEnableAspectWeights(definition);
    const columnTotals : ColumnTotal[] = [];
    for (let definitionColumnIndex = 0; definitionColumnIndex < definition.columns.length; definitionColumnIndex++) {
      if (viewAssessmentsSideBySide) {
        for (const assessment of assessments) {
          const columnTotal = this._computeColumnTotal(definitionColumnIndex, enableAspectWeights, definition, [assessment], visibleLabelsFilter);
          columnTotals.push(columnTotal);
        }
      } else {
        const columnTotal = this._computeColumnTotal(definitionColumnIndex, enableAspectWeights, definition, assessments, visibleLabelsFilter);
        columnTotals.push(columnTotal);
      }
    }
    return columnTotals;
  };

  buildAssessmentSnapshot = (definition: Definition, assessment: Assessment, currentTime: number): AssessmentSnapshot => {
    const assessmentSnapshot: AssessmentSnapshot = util.deepCloneObject(assessment);
    assessmentSnapshot.updateTimestamp = currentTime;
    assessmentSnapshot.snapshotData = this.computeAssessmentSnapshotData(
      definition, assessmentSnapshot);
    return assessmentSnapshot;
  };

  computeAssessmentSnapshotData = (definition: Definition, assessment: Assessment): AssessmentSnapshotData => {
    const columnInfos : any[] = [];
    const context = {
      definition: definition,
      assessment: assessment,
      columnInfos: columnInfos,
      totalScore: 0,
      scoreCount: 0
    };
    for (let columnIndex = 0; columnIndex < definition.columns.length; columnIndex++) {
      const column = definition.columns[columnIndex];
      context.columnInfos.push({
        columnName: column.name,
        totalScore: 0,
        scoreCount: 0
      });
    }
    const callback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context) => {
      const assessmentItem = this.getInternalAssessmentItem(context.assessment, statement.uuid);
      const score = scoreUtil.assessmentItemToScore(assessmentItem);
      if (score !== undefined) {
        const columnInfo = context.columnInfos[columnIndex];
        context.totalScore += score;
        context.scoreCount++;
        columnInfo.totalScore += score;
        columnInfo.scoreCount++;
      }  
    };
    this.iterateStatements(definition, callback, context);
    const averageScore = util.computeAverageByTotalAndCount(context.totalScore, context.scoreCount);
    const columnTotals : any[] = [];
    const columnScoreInfos : any[] = [];
    const snapshotData: AssessmentSnapshotData = {
      averageScore: averageScore,
      columnTotals: columnTotals,
      columnScoreInfos: columnScoreInfos
    };
    for (let columnIndex = 0; columnIndex < definition.columns.length; columnIndex++) {
      const column = definition.columns[columnIndex];
      const columnInfo = context.columnInfos[columnIndex];
      snapshotData.columnTotals.push(columnInfo.totalScore);
      const averageScore = util.computeAverageByTotalAndCount(columnInfo.totalScore, columnInfo.scoreCount);
      const columnScoreInfo: AssessmentColumnScoreInfo = {
        columnName: column.name,
        scoreCount: columnInfo.scoreCount,
        averageScore: averageScore
      };
      snapshotData.columnScoreInfos.push(columnScoreInfo);
    }
    return snapshotData;
  };

  _computeColumnTotal = (columnIndex, enableAspectWeights, definition, assessments, visibleLabelsFilter): ColumnTotal => {
    const column = definition.columns[columnIndex];
    const columnTotal: ColumnTotal = {
      column: column,
      value: 0,
      providedScoreCount: 0,
      totalScoreCount: 0
    };
    const statementScores : any[] = [];
    const rowSummary = {
      rowCount: 0,
      totalWeight: 0
    };
    if (this.hasOneOrMoreRows(definition)) {
      this.iterateRows(definition, (row) => {
        let rowWeight = 1;
        if (enableAspectWeights) {
          rowWeight = this.getRowWeight(row);
        }
        const cell = row.cells[columnIndex];
        if (cell.statements && cell.statements.length) {
          for (let statementIndex = 0; statementIndex < cell.statements.length; statementIndex++) {
            const statement = cell.statements[statementIndex];
            let statementIsVisible = true;
            if (visibleLabelsFilter && visibleLabelsFilter.length > 0) {
              const count = this.countVisibleLabelsInStatement(definition, statement, visibleLabelsFilter);
              statementIsVisible = count > 0;
            }
            if (statementIsVisible) {
              for (const assessment of assessments) {
                columnTotal.totalScoreCount++;
                const assessmentItem = this.getInternalAssessmentItem(assessment, statement.uuid);
                if (scoreUtil.hasScore(assessmentItem)) {
                  const score = scoreUtil.assessmentItemToScore(assessmentItem);
                  if (score !== undefined) {
                    const weightedScore = rowWeight * score;
                    statementScores.push(weightedScore);
                    rowSummary.totalWeight = rowSummary.totalWeight + rowWeight;
                    rowSummary.rowCount++;
                    columnTotal.providedScoreCount++;
                  }
                }
              }
            }
          }
        }
      });
    }
    if (rowSummary.rowCount) {
      const averageWeight = rowSummary.totalWeight / rowSummary.rowCount;
      if (averageWeight > 0) {
        const averageScore = this._averageScores(statementScores);
        const weightedScore = averageScore / averageWeight;
        columnTotal.value = Math.round(weightedScore);
      }
    } else {
      columnTotal.value = undefined;
    }
    return columnTotal;
  };

  computeAspectScore = (definition, assessments, groupIndex, rowIndex, visibleLabels, columnVisibilities) => {
    const filterByLabels = visibleLabels && visibleLabels.length > 0;
    const context = {
      totalScore: 0,
      count: 0
    };
    const group = definition.groups[groupIndex];
    const row = group.rows[rowIndex];
    for (let columnIndex = 0; columnIndex < row.cells.length; columnIndex++) {
      if (columnVisibilities[columnIndex]) {
        const cell = row.cells[columnIndex];
        for (let statementIndex = 0; statementIndex < cell.statements.length; statementIndex++) {
          const statement = cell.statements[statementIndex];
          let qualifyingStatement = true;
          if (filterByLabels) {
            const statementLabels = this.getStatementLabels(definition, statement.uuid);
            qualifyingStatement = this.labelArraysInterset(statementLabels, visibleLabels);
          }
          if (qualifyingStatement) {
            for (let assessmentIndex = 0; assessmentIndex < assessments.length; assessmentIndex++) {
              const assessment = assessments[assessmentIndex];
              // const assessmentItem = this.getUserFacingAssessmentItem(assessment, statement.uuid);
              const assessmentItem = this.getInternalAssessmentItem(assessment, statement.uuid);
              if (scoreUtil.hasScore(assessmentItem)) {
                // context.totalScore += assessmentItem.score;
                const score = scoreUtil.assessmentItemToScore(assessmentItem);
                if (score !== undefined) {
                  context.totalScore += score;
                  context.count++;
                }
              }
            }
          }
        }
      }
    }
    if (context.count === 0) {
      return undefined;
    } else if (context.totalScore) {
      const score = context.totalScore / context.count;
      return score;
    } else {
      return 0;
    }
  };

  _averageScores = (scores) => {
    if (scores.length) {
      let total = 0;
      for (let i = 0; i < scores.length; i++) {
        total = total + scores[i];
      }
      const average = total / scores.length;
      return average;
    } else {
      return 0;
    }
  };

  updateAssessmentSchema = (assessment) => {
    if (!assessment.statementUuidsToInfos) {
      assessment.statementUuidsToInfos = {};
    }
    // Copy scores...
    if (assessment.statementUuidsToScores) {
      const uuids = Object.keys(assessment.statementUuidsToScores);
      for (let i = 0; i < uuids.length; i++) {
        const uuid = uuids[i];
        let info = assessment.statementUuidsToInfos[uuid];
        if (!info) {
          info = this._buildDefaultAssessmentItem();
          assessment.statementUuidsToInfos[uuid] = info;
        }
        if (!info.score) {
          info.score = assessment.statementUuidsToScores[uuid];
        }
      }
      delete assessment.statementUuidsToScores;
    }
    // Copy annotations...
    if (assessment.statementUuidsToAnnotations) {
      const uuids = Object.keys(assessment.statementUuidsToAnnotations);
      for (let i = 0; i < uuids.length; i++) {
        const uuid = uuids[i];
        let info = assessment.statementUuidsToInfos[uuid];
        if (!info) {
          info = this._buildDefaultAssessmentItem();
          assessment.statementUuidsToInfos[uuid] = info;
        }
        if (!info.annotation) {
          info.annotation = assessment.statementUuidsToAnnotations[uuid];
        }
      }
      delete assessment.statementUuidsToAnnotations;
    }
  };

  // getUserFacingAssessmentItem = (assessment, statementUuid) => {
  //   return scoreUtil.getUserFacingAssessmentItem(assessment, statementUuid);
  // };

  getInternalAssessmentItem = (assessment, statementUuid : Uuid) : AssessmentItem => {
    return scoreUtil.getInternalAssessmentItem(assessment, statementUuid);
  };

  assessmentItemToScore = (assessmentItem) => {
    return scoreUtil.assessmentItemToScore(assessmentItem);
  };

  _buildDefaultAssessmentItem = (): AssessmentItem => {
    return scoreUtil._buildDefaultAssessmentItem();
  };

  getLastAssessmentUpdateTime = (assessment, statementUuid) => {
    const info = this.getInternalAssessmentItem(assessment, statementUuid);
    return info.lastUpdateTime;
  };

  cloneDefinition = (sourceDefinition: Definition, newOwnerId: string): Definition => {
    const sourceStatementUuidsToLabels = sourceDefinition.statementUuidsToLabels;
    const clonedDefinition = util.deepCloneObject(sourceDefinition) as Definition;
    clonedDefinition.name = sourceDefinition.name + ' copy';
    clonedDefinition.ownerId = newOwnerId;
    const forceNewUuids = true;
    this.denormaliseDefinition(clonedDefinition, forceNewUuids);
    const iterationContext = {
      clonedDefinition: clonedDefinition,
      sourceStatementUuidsToLabels: sourceStatementUuidsToLabels,
      clonedStatementUuidsToLabels: {}
    };
    const callback = (row, group, groupIndex, rowIndex, columnIndex, sourceStatement, statementIndex, context) => {
      const sourceStatementLabels = context.sourceStatementUuidsToLabels[sourceStatement.uuid];
      const clonedStatementLabels = util.shallowCloneArray(sourceStatementLabels);
      if (clonedStatementLabels) {
        const clonedStatement = this._findStatementByIndexes(clonedDefinition, groupIndex, rowIndex, columnIndex, statementIndex);
        context.clonedStatementUuidsToLabels[clonedStatement.uuid] = clonedStatementLabels;
      }
    };
    this.iterateStatements(sourceDefinition, callback, iterationContext);
    clonedDefinition.statementUuidsToLabels = iterationContext.clonedStatementUuidsToLabels;
    clonedDefinition.clonedFromUuid = sourceDefinition.uuid;
    return clonedDefinition;
  };

  _findStatementByIndexes = (definition, groupIndex, rowIndex, columnIndex, statementIndex) => {
    const group = definition.groups[groupIndex];
    const row = group.rows[rowIndex];
    const cell = row.cells[columnIndex];
    const statement = cell.statements[statementIndex];
    return statement;
  };

  denormaliseDefinition = (definition, forceNewUuids) => {
    if (forceNewUuids || !definition.uuid) {
      definition.uuid = util.uuid();
    }
    for (let columnIndex = 0; columnIndex < definition.columns.length; columnIndex++) {
      const column = definition.columns[columnIndex];
      if (forceNewUuids || !column.uuid) {
        column.uuid = 'col-' + columnIndex + '-' + util.uuid();
      }
    }
    this.iterateRows(definition, (row, group, groupIndex, rowIndex) => {
      if (forceNewUuids || !group.uuid) {
        group.uuid = 'group-' + groupIndex + '-' + util.uuid();
      }
      if (forceNewUuids || !row.uuid) {
        row.uuid = 'row-' + rowIndex + '-' + util.uuid();
      }
      for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
        const cell = row.cells[cellIndex];
        if (forceNewUuids || !cell.uuid) {
          cell.uuid = 'cell-' + cellIndex + '-' + groupIndex + '-' + rowIndex + '-' + util.uuid();
        }
        for (let statementIndex = 0; statementIndex < cell.statements.length; statementIndex++) {
          const statement = cell.statements[statementIndex];
          if (forceNewUuids || !statement.uuid) {
            statement.uuid = 'statement-' + cellIndex + '-' + groupIndex + '-' + rowIndex + '-' + statementIndex + '-' + util.uuid();
          }
        }
      }
    });
  };

  validateDefinition = (definition) => {
    const visitedUuids = {};
    const logUuidVisit = (uuid) => {
      if (!uuid) {
        // debugger;
        throw Error('Undefined UUID detected!');
      }
      if (visitedUuids[uuid]) {
        throw Error('Duplicate UUID detected!');
      }
    };
    for (let columnIndex = 0; columnIndex < definition.columns.length; columnIndex++) {
      const column = definition.columns[columnIndex];
      logUuidVisit(column.uuid);
    }
    this.iterateRows(definition, (row, group, groupIndex, rowIndex) => {
      logUuidVisit(group.uuid);
      logUuidVisit(row.uuid);
      for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
        const cell = row.cells[cellIndex];
        logUuidVisit(cell.uuid);
        for (let statementIndex = 0; statementIndex < cell.statements.length; statementIndex++) {
          const statement = cell.statements[statementIndex];
          logUuidVisit(statement.uuid);
        }
      }
    });
  };

  convertLevelsDefinitionToGuidanceDefinition = (oldDefinition, newDefinition, columnToLevelMatcher) => {
    // Create the score meta...
    const levels: any[] = [];
    const customLevelsScoreInfo = {
      // label: 'Levels',
      levels: levels
    };
    for (let columnIndex = 0; columnIndex < oldDefinition.columns.length; columnIndex++) {
      const oldColumn = oldDefinition.columns[columnIndex];
      const label = columnToLevelMatcher(columnIndex, oldColumn.name);
      const level = {
        label: label,
        tooltip: oldColumn.name
      };
      customLevelsScoreInfo.levels.push(level);
    }
    newDefinition.customLevelsScoreInfo = customLevelsScoreInfo;
    newDefinition.options.scoreMetaType = scoreMetaDefinitions.customLevelsScoreMetaType;

    // Create the rows...
    const newGroups : any[] = [];
    newDefinition.groups = newGroups;
    for (const oldGroup of oldDefinition.groups) {
      const rows: any[] = [];
      const newGroup = {
        rows: rows,
        uuid: util.uuid()
      };
      newDefinition.groups.push(newGroup);
      let lastRow: any | undefined = undefined;
      for (const oldRow of oldGroup.rows) {
        const rowNameParts = oldRow.name.split(':');
        let rowName = '';
        let statementText = '';
        let nextSeparator = '';
        if (rowNameParts.length > 1) {
          for (let i = 0; i < rowNameParts.length - 1; i++) {
            rowName += nextSeparator + rowNameParts[i];
            nextSeparator = ': ';
          }
          statementText = rowNameParts[rowNameParts.length - 1];
        } else {
          rowName = oldRow.name;
          statementText = '????';
        }
        let newRow: any | undefined = undefined;
        let expectationsCell: any | undefined = undefined;
        if (lastRow && lastRow.name === rowName && lastRow.weight === oldRow.weight) {
          newRow = lastRow;
          expectationsCell = newRow.cells[0];
        } else {
          const cells: any[] = [];
          newRow = {
            name: rowName,
            themeName: oldRow.themeName,
            cells: cells,
            uuid: util.uuid()
          };
          if (oldRow.weight) {
            newRow.weight = oldRow.weight;
          }
          newGroup.rows.push(newRow);

          const statements: any[] = [];
          const uuid: Uuid = util.uuid()
          expectationsCell = {
            statements: statements,
            uuid: uuid
          }
          newRow.cells.push(expectationsCell);
        }
        
        const guidance : any[] = [];
        for (let columnIndex = 0; columnIndex < oldDefinition.columns.length; columnIndex++) {
          const oldColumn = oldDefinition.columns[columnIndex];
          const oldCell = oldRow.cells[columnIndex];
          const levelText = oldColumn.name;
          const scoreGuide = levelText;

          if (oldCell.statements && oldCell.statements.length) {
            const requirements: any[] = [];
            const guide = {
              scoreGuide: scoreGuide,
              requirements: requirements,
              uuid: util.uuid()
            };
            guidance.push(guide);

            for (const oldStatement of oldCell.statements) {
              const requirement = {
                text: oldStatement.text,
                uuid: util.uuid()
              };
              guide.requirements.push(requirement);
            }
          }
        }
        const expectationsStatement = {
          text: statementText,
          guidance: guidance,
          uuid: util.uuid()
        };
        expectationsCell.statements.push(expectationsStatement);
        lastRow = newRow;
      }
    }

    newDefinition.columns = [{
      name: 'Expectations',
      uuid: util.uuid()
    }];

    const forceNewUuids = false;
    this.denormaliseDefinition(newDefinition, forceNewUuids);
    // rubricDAO.saveDefinition(newDefinition);
    // this.setState({
    //   definition: newDefinition
    // });
    // setTimeout(this.forceUpdate, 500);
    // this.forceUpdate();
  };

}

export default new RubricUtil();
