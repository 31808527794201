import React, { PureComponent } from "react";
import ModalDialog from '@atlaskit/modal-dialog';
import Button, { ButtonGroup } from '@atlaskit/button';

export default class SafeWidget extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildSafeWidgetStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildSafeWidgetStateFromProps(props));
  }

  buildSafeWidgetStateFromProps = (props) => {
    return {
      inNormalState: true,
      stateRenderingIsExclusive: props.stateRenderingIsExclusive,
      confirmationTitle: props.confirmationTitle ? props.confirmationTitle : 'Are you sure?',
      confirmationMessage: props.confirmationMessage ? props.confirmationMessage : 'Are you sure you want to do this?',
      dangerStateLabel: props.dangerStateLabel ? props.dangerStateLabel : 'Confirm',
      dangerIconBefore: props.dangerIconBefore ? props.dangerIconBefore : 'danger',
      dangerStateAppearance: props.dangerStateAppearance ? props.dangerStateAppearance : 'danger',
      cancelLabel: props.cancelLabel ? props.cancelLabel : 'Cancel',
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      inNormalState: true
    });
  };

  onConfirmation = (event) => {
    const returnToNormalState = this.props.onConfirmation(event);
    if (returnToNormalState) {
      this.setState({
        inNormalState: true
      });
    }
  };

  onEnterDangerState = (event) => {
    // event.preventDefault();
    this.setState({
      inNormalState: false
    });
  };

  onHideModal = () => {
    this.setState({
      inNormalState: true
    });
  };

  renderDangerState = () => {
    return (
      <ModalDialog
        heading={this.state.confirmationTitle}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        onClose={this.onHideModal}
      >
        <div>
          <p>
            {this.state.confirmationMessage}
          </p>
        </div>
        <div
          className="toolbar-right"
          style={{
            marginBottom: '20px'
          }}
        >
          <div style={{marginTop: '20px'}}>
            <ButtonGroup>
              <Button
                appearance={this.state.dangerStateAppearance}
                iconBefore={this.state.dangerIconBefore}
                onClick={this.onConfirmation}
              >
                {this.state.dangerStateLabel ? this.state.dangerStateLabel : this.state.normalStateLabel}
              </Button>
              <Button
                appearance="subtle-link"
                onClick={this.onCancelButtonClick}
              >
                {this.state.cancelLabel}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </ModalDialog>
    );
  };

  render() {
    if (this.state.inNormalState) {
      return this.renderNormalState();
    } else {
      if (this.state.stateRenderingIsExclusive) {
        return this.renderDangerState();
      } else {
        return (
          <React.Fragment>
            {this.renderNormalState()}
            {this.renderDangerState()}
          </React.Fragment>
        );
      }
    }
  }

}
