import analytics from '../../shared/model/analytics/Analytics';
import storageDAO from './StorageDAO';

export default class DriveBrowserPersistence {

  analyticsEntityName = 'drive-state';

  constructor() {
    this.drivePath = 'drive';
  }

  saveFolder = (user, folder) => {
    analytics.databaseWrite(this.analyticsEntityName);
    const key = this._buildFolderKey(user, folder.path);
    return storageDAO.setData(key, folder);
  };

  getFolder = (user, folderPath) => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildFolderKey(user, folderPath);
    return storageDAO.getData(key);
  };

  _buildFolderKey = (user, folderPath) => {
    const userId = user.getId();
    return this.drivePath + '/' + userId + folderPath;
  };

}
