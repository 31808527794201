import actions from '../../../actions/Actions';
import Assessment from './Assessment';
import permissionUtil from '../../auth/PermissionUtil';
import discoverabilityDefinitions from '../DiscoverabilityDefinitions';
import AssessmentDAI from '../../../../backend/data/AssessmentDAI';
import persistenceFactory from '../../../../backend/data/PersistenceFactory';
import rubricDAO from '../../../../backend/data/RubricDAO';
// import rubricPersistence from '../../../../backend/data/RubricPersistence';
// import actions from '../../../actions/Actions';
import constants from '../../Constants';
import currentAssessmentContext from './CurrentAssessmentContext';
// import DefinitionReference from '../definition/DefinitionReference';
import Definition from '../definition/Definition';
import promiseUtil from '../../../../commonbase/util/promiseUtil';
import AssessmentContext from './AssessmentContext';
import rubricUtil from '../RubricUtil';
import util from '../../../util/Util';
import RubricAssessmentBuilder from '../RubricAssessmentBuilder';
import session from '../../auth/Session';

const minMillisecondsBetweenSnapshots = constants.millisPerHour;

class AssessmentUtil {

  rubricPersistence = persistenceFactory.rubricPersistence;
  debouncedSaveAssessment: (assessmentDAO: AssessmentDAI, assessment: Assessment) => Promise<Assessment>;

  constructor() {
    this.debouncedSaveAssessment = util.debouncePromise(this._saveAssessmentWithinDebounce, 1000, false) as (assessmentDAO: AssessmentDAI, assessment: Assessment) => Promise<Assessment>;
  }

  areAllAssessmenetsEqual = (assessmentsA: Assessment[], assessmentsB: Assessment[]): boolean => {
    if (assessmentsA === undefined && assessmentsB === undefined) {
      return true;
    } else if (assessmentsA === undefined) {
      return false;
    } else {
      for (let i = 0; i < assessmentsA.length; i++) {
        const assessmentA = assessmentsA[i];
        const assessmentB = assessmentsB[i];
        if (!this.areAssessmentsEqual(assessmentA, assessmentB)) {
          return false;
        }
      }
      return true;
    }
  }

  areAssessmentsEqual = (assessmentA: Assessment, assessmentB: Assessment): boolean => {
    if (assessmentA === undefined && assessmentB === undefined) {
      return true;
    } else if (assessmentA === undefined) {
      return false;
    } else {
      return assessmentA.uuid === assessmentB.uuid;
    }
  }

  setAssessmentName = (assessment: Assessment, name: string): void => {
    assessment.name = name;
  }

  createAndStoreAssessment = async (
      assessmentDAO: AssessmentDAI,
      definition: Definition,
      builderActions?: (assessmentBuilder: RubricAssessmentBuilder) => RubricAssessmentBuilder): Promise<Assessment> => {
    const currentUser = session.getCurrentUser();
    if (!currentUser) {
      throw new Error('Can nly build assessments when logged in.');
    }
    let assessmentBuilder = new RubricAssessmentBuilder()
      .setDefinitionUuid(definition.uuid)
      .setOwnership(currentUser)
      .setDiscoverability(discoverabilityDefinitions.privateDiscoverabilityType);
    assessmentBuilder = assessmentDAO.applyDefaultAssessmentPermissions(assessmentBuilder);
    if (builderActions) {
      builderActions(assessmentBuilder);
    }
    const assessment = assessmentBuilder.build();
    // const assessment = assessmentDAO.buildAssessment(definition);
    return await this._saveAssessment(assessmentDAO, assessment, true);
  }

  saveAssessment = async (assessmentDAO: AssessmentDAI, assessment: Assessment): Promise<Assessment> => {
    return await this._saveAssessment(assessmentDAO, assessment, false);
  }

  _saveAssessmentWithinDebounce = async (assessmentDAO: AssessmentDAI, assessment: Assessment): Promise<Assessment> => {
    if (permissionUtil.canWriteAssessment(assessment)) {
      return await this._saveAssessment(assessmentDAO, assessment, false);
    } else {
      // Session changed - ignore
      return promiseUtil.promiseReturning(assessment);
    }
  }

  _saveAssessment = async (assessmentDAO: AssessmentDAI, assessment: Assessment, isCreate: boolean): Promise<Assessment> => {
    if (!permissionUtil.canWriteAssessment(assessment)) {
      throw new Error('No permission to save assessment ' + assessment.name);
    }
    if (!assessment.discoverability) {
      assessment.discoverability = discoverabilityDefinitions.privateDiscoverabilityType;
    }
    if (!assessment.roles) {
      assessment.roles = [];
    }
    if (!assessment.generalNotes) {
      assessment.generalNotes = '';
    }
    const currentTime = new Date().getTime();
    const definition = await this._getDefinitionByUuid(assessment.definitionUuid);
    if (!isCreate && assessmentDAO.isAssessmentSnapshottingEnababled()) {
      let lastSnapshotTimestamp = assessment.lastSnapshotTimestamp;
      if (!lastSnapshotTimestamp) {
        lastSnapshotTimestamp = 0;
      }
      const millisSinceLastAssessmentSave = currentTime - lastSnapshotTimestamp;
      if (millisSinceLastAssessmentSave > minMillisecondsBetweenSnapshots) {
        assessment.lastSnapshotTimestamp = currentTime;
        setTimeout(() => {
          this._saveAssessmentSnapshot(definition, assessment, currentTime);
        }, 1);
      }
    }
    assessment.updateTimestamp = currentTime;
    assessment = await assessmentDAO.storeSanitisedAssessment(assessment);
    const assessmentContext: AssessmentContext = {
      definition: definition,
      assessment: assessment
    };
    actions.onAssessmentChange(assessmentContext);
    return assessment;
  };

  _getDefinitionByUuid = async (definitionUuid: string): Promise<undefined | Definition> => {
    const assessmentContext = currentAssessmentContext.getAssessmentContext();
    if (assessmentContext && assessmentContext.definition) {
      if (assessmentContext.definition.uuid === definitionUuid) {
        return promiseUtil.promiseReturning(assessmentContext.definition);
      }
    }
    return rubricDAO.getDefinitionByUuid(definitionUuid);
  }

  _saveAssessmentSnapshot = (definition, assessment, currentTime): Promise<Assessment> => {
    const assessmentSnapshot = rubricUtil.buildAssessmentSnapshot(definition, assessment, currentTime);
    return this.rubricPersistence.saveAssessmentSnapshot(assessmentSnapshot);
  };

}

export default new AssessmentUtil();
