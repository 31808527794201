import SelectItem from '../widget/SelectItem';
import SelectItemsGroup from '../widget/SelectItemsGroup';

class EntityTypeDefinitions {

  definitionEntityType = 'definition';
  assessmentEntityType = 'assessment';
  typesToNames = [];

  constructor() {
    this.typesToNames[this.definitionEntityType] = 'Definition';
    this.typesToNames[this.assessmentEntityType] = 'Assessment';
  }

  buildEntityTypeEnumItemsContext = (currentType) => {
    const definitionEntityTypeItem: SelectItem = {
      label: this.typesToNames[this.definitionEntityType],
      value: this.definitionEntityType
    };
    const assessmentItem: SelectItem = {
      label: this.typesToNames[this.assessmentEntityType],
      value: this.assessmentEntityType
    };
    const items: SelectItem[] = [
      definitionEntityTypeItem,
      assessmentItem];
    const selectItems: SelectItemsGroup[] = [{
      items: items
    }];
    let selectedItem: undefined | SelectItem = undefined;
    for (let i = 0; i < selectItems[0].items.length; i++) {
      const item = selectItems[0].items[i];
      if (item.value === currentType) {
        selectedItem = item;
      }
    }
    return {
      items: selectItems,
      selectedItem: selectedItem
    };
  };

  getDefaultType = () => {
    return this.definitionEntityType;
  };

}

export default new EntityTypeDefinitions();
