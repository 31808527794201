import util from '../../util/Util';
import IUser from './IUser';

export default class User implements IUser {

  id: string;
  displayName: string;
  email: string;
  _emailDomain?: string;
  photoURL: string;

  constructor(id, displayName, email, photoURL) {
    this.id = id;
    this.displayName = displayName;
    this.email = email;
    this.photoURL = photoURL;
  }

  getId = () => {
    return this.id;
  };

  getDisplayName = () => {
    return this.displayName;
  };

  // guessFirstName = () => {
  //  if (this.displayName) {
  //    const nameParts = this.displayName.split(' ');
  //    if (nameParts && nameParts.length > 1) {
  //      const firstName = nameParts[0].trim();
  //      if (firstName) {
  //        return firstName;
  //      } else {
  //       return this.displayName;
  //      }
  //    } else {
  //      return this.displayName;
  //    }
  //  } else {
  //   return '';
  //  }
  // };

  getEmail = () => {
    return this.email;
  };

  getEmailDomain = (): string => {
    if (this._emailDomain) {
      return this._emailDomain;
    } else {
      const emailDomain = util.extractDomainFromEmail(this.email);
      this._emailDomain = emailDomain;
      return emailDomain;
    }
  };

  getPhotoURL = () => {
    return this.photoURL;
  };

}
