import ListenerGroup from '../util/ListenerGroup';

export class GlobalDirtyStateController {

  spinIdsToStartTimes = {};

  listenerGroup = new ListenerGroup('GlobalDirtyStateController');

  isDirty = () => {
    return Object.keys(this.spinIdsToStartTimes).length > 0;
  };

  setDirty = (uuid) => {
    const previouslyDirty = this.isDirty();
    const startTime = new Date().getTime();
    this.spinIdsToStartTimes[uuid] = startTime;
    const currentlyDirty = this.isDirty();
    if (currentlyDirty !== previouslyDirty) {
      this.notifyListeners(currentlyDirty);
    }
  };

  setClean = (uuid) => {
    const previouslyDirty = this.isDirty();
    delete this.spinIdsToStartTimes[uuid];
    const currentlyDirty = this.isDirty();
    if (currentlyDirty !== previouslyDirty) {
      this.notifyListeners(currentlyDirty);
    }
  };

  notifyListeners = (spinning) => {
    this.listenerGroup.notifyListeners(spinning);
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

}

export default new GlobalDirtyStateController();
