import React, { PureComponent } from 'react';
import AspectBarChart from '../chart/AspectBarChart';
import aspectGroupingDefinitions from '../../../../shared/model/rubric/AspectGroupingDefinitions';
import AspectPieChart from '../chart/AspectPieChart';
import ColumnVisibilitySelector from '../ColumnVisibilitySelector';
import ContainerDimensions from 'react-container-dimensions';
import StatementAnnotation from '../StatementAnnotation';
import StatementScore from '../score/StatementScore';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import ChartModel from "../chart/ChartModel";
import WidgetUtil from '../../widget/WidgetUtil';
import ToolbarLeft from '../../../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

export default class ReportView extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    const scoreMeta = rubricUtil.getScoreMeta(props.definition);
    let aspectGrouping = aspectGroupingDefinitions.noneAspectGroupingType;
    if (props.showOnlyAssessedStatements) {
      const pillars = rubricUtil.getDefinitionPillars(props.definition);
      if (pillars && pillars.length > 1) {
        aspectGrouping = aspectGroupingDefinitions.pillarAspectGroupingType;
      }
    }
    const state = {
      definition: props.definition,
      assessment: props.assessment,
      scoreMeta: scoreMeta,
      includeCriteriaHeaders: props.includeCriteriaHeaders,
      includeColumnHeaders: props.includeColumnHeaders,
      includeStatements: props.includeStatements,
      includeAssessmentScores: props.includeAssessmentScores,
      showOnlyAssessedStatements: props.showOnlyAssessedStatements,
      showOnlyColumnsWithScores: props.showOnlyColumnsWithScores,
      includeTotalScore: props.includeTotalScore,
      formatStatementsBold: props.formatStatementsBold,
      showOnlyScoredStatements: false,
      showOnlyAnnotatedStatements: false,
      aspectGrouping: aspectGrouping
    };
    if (state.includeTotalScore || state.showOnlyColumnsWithScores) {
      state.columnScores = state.definition ? rubricUtil.computeColumnScores(state.definition, state.assessment) : [];
    }
    if (state.definition) {
      const columnVisibilities = [];
      for (let columnIndex = 0; columnIndex < state.definition.columns.length; columnIndex++) {
        let visible = true;
        if (state.showOnlyColumnsWithScores) {
          const columnScore = state.columnScores[columnIndex];
          visible = columnScore.scoreCount;
        }
        columnVisibilities.push(visible);
      }
      state.columnVisibilities = columnVisibilities;
    }
    return state;
  };

  onVisibilityToggle = (column, columnIndex) => {
    const visibilities = [];
    for (let v = 0; v < this.state.columnVisibilities.length; v++) {
      let columnVisibility = this.state.columnVisibilities[v];
      if (v === columnIndex) {
        columnVisibility = !columnVisibility;
      }
      visibilities.push(columnVisibility);
    }
    this.setState({
      columnVisibilities: visibilities
    });
  };

  renderColumnScores = () => {
    const renderedScoreListItems = this.state.columnScores.map((columnScore, columnIndex) => {
      const assessmentItem = scoreUtil.buildAssessmentItemFromScore(columnScore.averageScore);
      const renderedScore = this.state.columnVisibilities[columnIndex] ? (
        <li key={'column-' + columnIndex}>
          <ToolbarLeft style={{alignItems: 'center'}}>
            <div>
              <b>{columnScore.columnName}</b>
            </div>
            <div style={{marginLeft: '10px'}}>
              <StatementScore
                style={{
                  display: 'inline'
                }}
                scoreMeta={this.state.scoreMeta}
                assessmentItem={assessmentItem}
                radius={12}
              />
            </div>
          </ToolbarLeft>
        </li>
      ) : null;
      return renderedScore;
    });
    return (
      <div
        style={{
          marginTop: '20px'
        }}
      >
        <h2>Scores by level</h2>
        <ul>
          {renderedScoreListItems}
        </ul>
      </div>
    );
  };

  renderCharts = () => {
    const visibleLabels = rubricUtil.getDefinitionLabels(this.state.definition);
    const chartModel = new ChartModel(
      this.state.definition,
      [this.state.assessment],
      visibleLabels,
      this.state.columnVisibilities);
    return (
      <div style={{float: 'right', marginLeft: '10px', width: '600px'}}>
        {this.state.showOnlyAssessedStatements ? null: this.renderAspectPieChart(chartModel)}
        {this.renderAspectBarChart(chartModel)}
       </div>
    );
  };

  renderAspectBarChart = (chartModel) => {
    if (!this.state.scoreMeta) {
      throw new Error('this.state.scoreMeta not defined')
    }
    return (
      <div>
        <ContainerDimensions>
          { ({ width, height }) =>
            <AspectBarChart
              scoreMeta={this.state.scoreMeta}
              width={width}
              chartModel={chartModel}
              showOnlyAssessedStatements={this.state.showOnlyAssessedStatements}
              aspectGrouping={this.state.aspectGrouping}
            />
          }
        </ContainerDimensions>
      </div>
    );
  };

  renderAspectPieChart = (chartModel) => {
    return (
      <div>
        <AspectPieChart
          scoreMeta={this.state.scoreMeta}
          chartModel={chartModel}
          aspectGrouping={this.state.aspectGrouping}
        />
      </div>
    );
  };

  renderGeneralNotesForAssessment = (assessment) => {
    const generalNotesHtml = WidgetUtil.markdownToHtml(assessment.generalNotes);
    return (
      <div>
        <h3>General notes</h3>
        <p dangerouslySetInnerHTML={{__html: generalNotesHtml}} />
      </div>
    );
  };

  renderGeneralNotes = () => {
    if (this.state.assessment.generalNotes) {
      return this.renderGeneralNotesForAssessment(this.state.assessment);
    } else {
      return null;
    }
  };

  renderAssessment = () => {
    const renderedReportItems = [];
    let context = {
      lastRowName: '',
      lastColumnName: '',
      lastOutputRowName: '',
      lastOutputColumnName: ''
    };
    const onStatement = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context) => {
      const column = rubricUtil.getColumn(this.state.definition, columnIndex);
      if (!this.state.showOnlyColumnsWithScores || this.state.columnVisibilities[columnIndex]) {
        const assessmentItem = scoreUtil.getInternalAssessmentItem(this.state.assessment, statement.uuid);
        const score = scoreUtil.assessmentItemToScore(assessmentItem);
        const annotation = assessmentItem ? assessmentItem.annotation : undefined;
        let showStatement = true;
        if (this.state.showOnlyAssessedStatements) {
          if (!score && !annotation) {
            showStatement = false;
          }
        }
        if (this.state.showOnlyScoredStatements && score === undefined) {
          showStatement = false;
        }
        if (showStatement) {
          if (this.state.showOnlyAnnotatedStatements && !annotation) {
            showStatement = false;
          }
        }
        if (showStatement) {
          let criteriaHeader = null;
          if (this.state.includeCriteriaHeaders && row.name !== context.lastOutputRowName) {
            criteriaHeader = (
              <div className="newMinorSection">
                <h3>
                  {row.name}
                </h3>
              </div>
            );
            context.lastOutputRowName = row.name;
          }

          const columnName = column.name;
          let columnHeader = null;
          if (this.state.includeColumnHeaders && columnName !== context.lastOutputColumnName) {
            columnHeader = (
              <h4>
                {columnName}
              </h4>
            );
            context.lastOutputColumnName = columnName;
          }

          const assessmentItem = scoreUtil.buildAssessmentItemFromScore(score);
          const renderedScore = this.state.includeAssessmentScores ? (
            <ToolbarItem>
              <StatementScore
                scoreMeta={this.state.scoreMeta}
                assessmentItem={assessmentItem}
                radius={12}
              />
            </ToolbarItem>
          ) : null;
          const renderedStatementText = this.state.includeStatements ?
            this.state.formatStatementsBold ? (<b>{statement.text}</b>) : (<span>{statement.text}</span>) : '';
          const renderedStatement = (
            <div className="newMinorSection">
              {renderedStatementText}
            </div>
          );
          const renderedAnnotation = (
            <StatementAnnotation
              marginLeft="0px"
              assessmentItem={assessmentItem}
              text={annotation}
              scoreMeta={this.state.scoreMeta}
            />
          );
          const scoreAndAnnotation = (
            <ToolbarLeft>
              {renderedScore}
              <ToolbarItem>
                {renderedAnnotation}
              </ToolbarItem>
            </ToolbarLeft>
          );
          const reportItem = (
            <div
              key={statement.uuid}
            >
              {criteriaHeader}
              {columnHeader}
              {renderedStatement}
              {scoreAndAnnotation}
            </div>
          );
          renderedReportItems.push(reportItem);
        }
      }
      context.lastColumnName = column.name;
      context.lastRowName = row.name;
    };
    rubricUtil.iterateStatements(this.state.definition, onStatement, context);
    return (
      <div
        style={{
          marginTop: '20px'
        }}
      >
        {this.renderCharts()}
        <h2>Report details</h2>
        {this.renderGeneralNotes()}
        {renderedReportItems}
      </div>
    );
  };

  renderColumnVisibilitiesSelector = () => {
    const columnCount = rubricUtil.countColumns();
    if (columnCount > 1) {
      return (
        <ColumnVisibilitySelector
          definition={this.state.definition}
          columnVisibilities={this.state.columnVisibilities}
          onVisibilityToggle={this.onVisibilityToggle}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.renderColumnVisibilitiesSelector}
        {this.state.includeTotalScore ? this.renderColumnScores() : null}
        {this.state.definition && this.state.assessment && this.state.scoreMeta ? this.renderAssessment() : null}
      </React.Fragment>
    );
  }
}
