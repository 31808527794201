import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import IconButton from '../../components/widget/IconButton';
import ModalDialog from '@atlaskit/modal-dialog';
import momentGraph from './MomentGraph';
import {
  AddMomentTextIcon, 
  AddMomentTextCircleIcon
} from '../../components/icon/NamedIcons';
import Tooltip from "@atlaskit/tooltip";
import util from '../../../shared/util/Util';
import ToolbarCenter from '../../components/widget/toolbar/ToolbarCenter';
import ToolbarLeft from '../../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../components/widget/toolbar/ToolbarItem';

export default class SelectMomentDialog extends PureComponent {

  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    // driveGraph.registerListener(this.onDriveGraphChange);
  }

  componentWillUnmount() {
    this.mounted = false;
    // driveGraph.unregisterListener(this.onDriveGraphChange);
  }

  buildStateFromProps = (props) => {
    return {
      momentImportContext: props.momentImportContext
    };
  };

  // onImportClick = () => {
  //   this.props.onDone();
  // };

  onCancelClick = () => {
    this.props.onCancel();
  };

  renderAddMomentButton = (moment) => {
    return (
      <Tooltip content="Select moment">
        <IconButton
          normalIcon={<AddMomentTextIcon/>}
          hoverIcon={<AddMomentTextCircleIcon/>}
          onClick={() => this.props.onSelectMoment(moment)}
        />
      </Tooltip>
    );
  };

  xxxrenderMoment = (moment) => {
    return (
      <ToolbarLeft className="hover-row" key={moment.uuid}>
        <ToolbarItem style={{width: '200px'}}>
          {util.formatDate(moment.timestamp)}
        </ToolbarItem>
        <ToolbarItem>
          {moment.text}
        </ToolbarItem>
        <ToolbarItem style={{width: '64px'}}>
          {this.renderAddMomentButton(moment)}
        </ToolbarItem>
      </ToolbarLeft>
    );
  };

  renderMoment = (moment) => {
    const cellPadding = '5px';
    return (
      <tr className="hover-row" key={moment.uuid}>
        <td style={{width: '80px', padding: cellPadding}}>
          {util.formatDate(moment.timestamp)}
        </td>
        <td style={{padding: cellPadding}}>
          {moment.text}
        </td>
        <td style={{width: '34px', padding: cellPadding, align: 'center'}}>
          {this.renderAddMomentButton(moment)}
        </td>
      </tr>
    );
  };

  renderMoments = () => {
    const moments = momentGraph.getMoments();
    return moments.map((moment) => {
      return this.renderMoment(moment);
    });
  };

  onModalClosed = () => {
    // ignore
  };

  render() {
    const errorMessage = this.state.newFolderNameErrorMessage;
    return (
      <ModalDialog
        key="select-moment"
        heading="Select moment"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        onClose={this.onModalClosed}
      >
        {errorMessage ? this.renderErrorBanner(this.state.newFolderNameErrorMessage) : null}
        <div className="centredContent" style={{marginTop: '10px', marginBottom: '10px'}}>
          <div style={{marginBottom: '20px'}}>
            <table>
              <tbody style={{borderBottomWidth: '0px'}}>
                {this.renderMoments()}
              </tbody>
            </table>
          </div>
          <ToolbarCenter>
            <Button
              appearance="subtle"
              isDisabled={false}
              onClick={this.onCancelClick}
            >
              Cancel
            </Button>
          </ToolbarCenter>
        </div>
      </ModalDialog>
    );
  }

}
