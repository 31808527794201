
class CannedData {

  testRubricLabelsReference = {
    definitionUuid: '08d0a94f-b461-8610-0efd-d697a69549c1',
    definitionName: 'Test Rubric - labels'
  }
  
  exampleRubricThreeLittlePigsDecisionReference = {
    definitionUuid: 'f309ae0b-1609-c05c-ad3b-3b2e4c24f39c',
    definitionName: 'What should we make our house out of?'
  }
  
  uluStartupEvaluationReference = {
    definitionUuid: '3cc183d9-8d4f-a865-25a7-32682f0630f3',
    definitionName: 'Ulu Startup Evaluation Rubric'
  }

  // startupEvaluationReference = {
  //   definitionUuid: 'xxx',
  //   definitionName: 'Startup Evaluation Rubric'
  // }

  genericLearningReference = {
    definitionUuid: '5a68d9e1-fa96-a327-de36-2fa36a11e60c',
    definitionName: 'A Generic Learning Rubric'
  }

  waterScarcityInTheWorldReference = {
    definitionUuid: '0d7e7d3e-0137-8537-dc20-d2494c368298',
    definitionName: 'Water in the world - Water Scarcity'
  }

  atlassianProjectTeamHealthMonitorReference = {
    definitionUuid: '22945dfe-6cb0-c979-151c-1af1cfbb5a86',
    definitionName: 'Atlassian Project Team Health Monitor'
  }

  atlassianServiceTeamHealthMonitorReference = {
    definitionUuid: '98323e10-f70e-582b-5be9-4009dd07ece3',
    definitionName: 'Atlassian Service Team Health Monitor'
  }

  atlassianLeadershipTeamHealthMonitorReference = {
    definitionUuid: 'ce707f3a-211c-91b0-f7fd-0030a1f01f2d',
    definitionName: 'Atlassian Leadership Team Health Monitor'
  }

  employeeDevelopmentReference = {
    definitionUuid: '5993ec29-3e4f-d56e-b66f-65de1c94f105',
    definitionName: 'Continuous Professional Development'
  }

  decisonMakingTemplateReference = {
    definitionUuid: '186c00ab-417a-03c6-398a-90e06da05de4',
    definitionName: 'Decison Making Template'
  }

  competitiveAnalysisTemplateReference = {
    definitionUuid: '16142d98-24f0-fe4d-e006-1d8d20374ed3',
    definitionName: 'Competitive Analysis Template'
  }

  multipliersVsDiminishersReference = {
    definitionUuid: 'c9bfb66a-9ad2-1984-ad0b-00fc96217caf',
    definitionName: 'Multipliers vs Diminishers'
  }

  appPlatformDeveloperExperienceReference = {
    definitionUuid: 'b294478c-0056-c68c-f033-d35aa97d2159',
    definitionName: 'App Platform Developer Experience'
  }

  okrWritingGuideReference = {
    definitionUuid: 'f3b129ac-ff2e-505c-cc90-ad4aa035e81c',
    definitionName: 'OKR Writing Guide'
  }

  missionStatementWritingGuideReference = {
    definitionUuid: '05985db7-3a87-e128-0128-dc6fd1dca4c9',
    definitionName: 'Mission Statement Writing Guide'
  }

  jobDescriptionWritingGuideReference = {
    definitionUuid: '04e0b86e-b59b-5a73-5dbc-89bee78c4bcc',
    definitionName: 'Job Description Writing Guide'
  }

  googlesManagerFeedbackSurveyReference = {
    definitionUuid: 'a284ac6b-59c8-9735-6968-a643979bf4e4',
    definitionName: 'Google’s Manager Feedback Survey'
  }

  atlassianEngineeringICGrowthProfileReference = {
    definitionUuid: '3032a153-eb62-b442-85ac-39c878a2b3b2',
    definitionName: 'Pascal Growth Profile - P levels'
  }

  atlassianEngineeringManagerGrowthProfileReference = {
    definitionUuid: '8d8d92bc-38ae-dc61-f117-e017022e11a9',
    definitionName: 'Pascal Growth Profile - M-Levels'
  }

  atlassianProductManagementManagerGrowthProfileReference = {
    definitionUuid: '2c4a6686-4bde-d8e7-d775-c2cef38da244',
    definitionName: 'PM Management Growth Profile - M-Levels'
  }

  atlassianProductManagementICGrowthProfileReference = {
    definitionUuid: 'a2f72b4b-e27e-673d-5346-77d19dea1f19',
    definitionName: 'PM IC Growth Profile - P-Levels'
  }

  atlassianValuesInterviewReference = {
    definitionUuid: '388ccca5-e392-732d-8535-f2b74d3b65a8',
    definitionName: 'Atlassian Values Interview'
  }

  // Reference = {
  //   definitionUuid: '',
  //   definitionName: ''
  // }

}

export default new CannedData();
