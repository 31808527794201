import React, { PureComponent } from "react";
import analytics, { analyticsDefinitions } from '../../shared/model/analytics/Analytics';
import Button from '@atlaskit/button';
import currentUserPlan from '../../backend/payment/CurrentUserPlan';
import planDefinitions from '../../shared/model/payment/PlanDefinitions';
import {Elements, StripeProvider} from 'react-stripe-elements';
import firebaseApp from '../../backend/firebase/FirebaseApp';
import session from '../../shared/model/auth/Session';
import SignUpForm from './SignUpForm';
import stripeClientConfig from '../../shared/model/payment/StripeClientConfig';
import ModalDialog from '@atlaskit/modal-dialog';

export default class SignUpButton extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.stripeKey = stripeClientConfig.getStripeKey();
    this.state = this.buildStateFromProps(props);
    this.state.stripe = null;
    this.state.processing = false;
    this.state.paymentErrorMessage = '';
    this.state.paymentMadeSuccessfully = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      appearance: props.appearance ? props.appearance : 'primary'
    };
  };

  componentDidMount() {
    if (window.Stripe) {
      this.setState(
        {stripe: window.Stripe(this.stripeKey)}
      );
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({
          stripe: window.Stripe(this.stripeKey)
        });
      });
    }
  }

  onShowFormClick = () => {
    analytics.event({
      category: analyticsDefinitions.categories.payment,
      action: analyticsDefinitions.actions.signUpButtonClick
    });
    this.setState({
      showModal: true
    });
  };

  onCloseModal = () => {
    if (this.state.paymentMadeSuccessfully) {
      currentUserPlan.refresh();
    }
    this.setState({
      showModal: false,
      paymentErrorMessage: '',
      paymentMadeSuccessfully: false
    });
  };

  async onSubmitForm(stripe) {
    /*
        Reference info:
        *  https://stripe.com/docs/recipes/subscription-signup
        *  https://stripe.com/docs/stripe-js/reference#the-stripe-object
     */
    try {
      // const user = session.getCurrentUser();
      // const userId = user.getId();
      this.setState({
        processing: true,
        paymentErrorMessage: ''
      });

      const user = session.getCurrentUser();
      const userDisplayName = user.getDisplayName();

      // Tokenize the submitted card info...
      // https://stripe.com/docs/api/tokens?lang=node
      const createTokenResult = await stripe.createToken({name: userDisplayName});
      if (createTokenResult.error) {
        this.setState({
          processing: false,
          paymentErrorMessage: createTokenResult.error.message
        });
        return;
      } if (!createTokenResult.token || !createTokenResult.token.id) {
        this.setState({
          processing: false,
          paymentErrorMessage: 'Internal error: token information not found!'
        });
        return;
      }


      // Doco on calling Firebase functions: https://firebase.google.com/docs/functions/callable
      const functions = firebaseApp.getFunctions();
      const signUpFunction = functions.httpsCallable('consolidatedCallHandler');
      const payload = {
        callType: 'sign-up',
        planType: planDefinitions.proType,
        planName: planDefinitions.proPlanName,
        stripeProductId: planDefinitions.proStripeProductId,
        token: createTokenResult.token.id
      };
      signUpFunction(payload)
        .then((result) => {
          // Read result of the Cloud Function.
          const data = result.data;
          if (data && data.ok) {
            this.setState({
              paymentErrorMessage: '',
              paymentMadeSuccessfully: true
            });
          } else if (data && data.errorMessage) {
            this.setState({
              paymentErrorMessage: data.errorMessage
            });
          } else {
            this.setState({
              paymentErrorMessage: 'An unexpected error occurred.'
            });
          }
        }).catch((error) => {
          // const code = error.code;
          const message = error.message;
          const details = error.details;
          this.setState({
           paymentErrorMessage: message + ': ' + details
          });
        }).finally(() => {
          this.setState({
            processing: false
          });
        });
    } catch (error) {
      this.setState({
        processing: false,
        paymentErrorMessage: error ? 'Unexpected error: ' + error : 'unknown error'
      });
    }
  };

  renderPaymentForm() {
    return (
      <ModalDialog
        key="payment-modal"
        heading={'Sign up'}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEscapePress={true}
        onClose={this.hideModal}
      >
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <SignUpForm
              label={this.props.label}
              processing={this.state.processing}
              paymentErrorMessage={this.state.paymentErrorMessage}
              paymentMadeSuccessfully={this.state.paymentMadeSuccessfully}
              onSubmitForm={this.onSubmitForm}
              onCloseForm={this.onCloseModal}
            />
          </Elements>
        </StripeProvider>
      </ModalDialog>
    );
  }

  renderButton() {
    return (
      <Button
        appearance={this.state.appearance}
        onClick={this.onShowFormClick}
      >
        {this.props.label}
      </Button>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderButton()}
        {this.state.showModal ? this.renderPaymentForm() : null}
      </React.Fragment>
    );
  }

}
