import React, { CSSProperties, PureComponent } from 'react';
import actions from '../../../shared/actions/Actions';
import adg from '../../../commonbase/adg';
import analytics, {analyticsDefinitions} from "../../../shared/model/analytics/Analytics";
import AssessmentStatus from './assessment/AssessmentStatus';
import assessmentUtil from '../../../shared/model/rubric/score/AssessmentUtil';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import { LinkIcon, SuccessIcon, WarningIcon } from '../icon/NamedIcons';
import ComparisonToolbar from './score/ComparisonToolbar';
import ContainerDimensions from 'react-container-dimensions';
import editabilityDefinitions from '../../../shared/model/rubric/EditabilityDefinitions';
import featureFlags from '../../../shared/model/feature/FeatureFlags';
import firestoreAssessmentDAO from '../../../backend/data/FirestoreAssessmentDAO';
import I18N from '../../../shared/model/i18n/I18N';
import planDefinitions from '../../../shared/model/payment/PlanDefinitions';
import GeneralNotesField from './GeneralNotesField';
import permissionUtil from '../../../shared/model/auth/PermissionUtil';
import Range from '@atlaskit/range';
import RowWeight from './RowWeight';
import RubricCell from './RubricCell';
import rubricUtil from '../../../shared/model/rubric/RubricUtil';
import scoreUtil from '../../../shared/model/rubric/score/ScoreUtil';
import scrollToComponent from 'react-scroll-to-component';
import SignUpButton from '../../payment/SignUpButton';
import StatementScore from './score/StatementScore';
import Tooltip from "@atlaskit/tooltip";
import util from '../../../shared/util/Util';
import navConstants from '../nav/NavConstants';
import navUtil from '../nav/NavUtil';
import urlUtil from '../../../shared/util/UrlUtil';
import widgetUtil from '../widget/WidgetUtil';
import Definition from '../../../shared/model/rubric/definition/Definition';
import ScoreMeta from '../../../shared/model/rubric/score/ScoreMeta';
import Assessment from '../../../shared/model/rubric/score/Assessment';
import AssessmentDAI from '../../../backend/data/AssessmentDAI';
import Statement from '../../../shared/model/rubric/definition/Statement';
import { ColumnTotal } from '../../../shared/model/rubric/ColumnTotal';
import ToolbarLeft from '../widget/toolbar/ToolbarLeft';
import ToolbarCenter from '../widget/toolbar/ToolbarCenter';
import ToolbarJustify from '../widget/toolbar/ToolbarJustify';
import ToolbarItem from '../widget/toolbar/ToolbarItem';

// assessments = { assessments }
// readableAssessments = { this.state.readableAssessments }
// editableAssessments = { this.state.editableAssessments }
// canViewOrCreateAssessments = { canViewOrCreateAssessments }
interface Common {
  definition: Definition
  scoreMeta: ScoreMeta
  viewAssessmentsSideBySide?: boolean
  renderAssessmentStatusWithinTable?: boolean
  renderGeneralNotesWithinTable?: boolean
  hideCompletedAspects?: boolean
  hideZeroScores?: boolean
  hideColumnTotals?: boolean
  hideGeneralNotes?: boolean
  columnVisibilities: any
  visibleLabels?: string[]
  // assessments: Assessment[]
  readableAssessments?: Assessment[]
  editableAssessments?: Assessment[]
  canViewOrCreateAssessments?: boolean
  viewRowLinks?: boolean
  viewDefinitionHeader?: boolean
  viewStatus?: boolean
  viewNotes?: boolean
}

interface ParentProps extends Common {
  assessments?: Assessment[]
  // readableAssessments?: Assessment[]
  // editableAssessments?: Assessment[]
}

interface AssessmentProps {
  assessments: Assessment[]
  // readableAssessments?: Assessment[]
  // editableAssessments?: Assessment[]
}

interface Props extends Common, AssessmentProps {
  assessmentDAO?: AssessmentDAI
  embedded?: boolean
  onAssessmentChange: () => void
  onColumnIndexToVisibilityChange: (columnVisibilities: any[]) => void
  getGuidanceVisibility: (assessment: Assessment) => boolean
  onGuidanceExpanded: (statement: Statement) => void
  onGuidanceCollapsed: (statement: Statement) => void
}

interface State extends Common, AssessmentProps {
  totalColumnCount: number
  assessmentColumnCount: number
  assessmentComparisonEnabled?: boolean
  assessmentComparisonActive?: boolean
  assessmentComparisonToolbarVisible?: boolean
  assessmentHighlightThresholdPercent?: number
  lastScoreInputTimestamp?: number
}

export default class RubricViewer extends PureComponent<Props, State> {

  mounted = false;
  hideCompletedRowDelayMillis = 0;
  debouncedUpdater: Function;
  debounceAssessmentHighlightThresholdPercent: Function
  uuidsToRefs: any = {};
  definitionRendered = false;
  anchorVisited = false;
  tableLayout = undefined;
  columnStyles: any = undefined;
  tableElement: any = undefined;

  constructor(props: Props) {
    super(props);
    this.debouncedUpdater = util.debounce(this._scheduleUpdate, 1000, false);
    this.debounceAssessmentHighlightThresholdPercent = util.debounce(this.onDebouncedAssessmentHighlightThresholdPercent, 50, false);
    this.state = this.buildStateFromProps(props, true);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.columnStyles = undefined;
    this.setState(this.buildStateFromProps(props, false));
  }

  buildStateFromProps = (props: Props, initial: boolean): State => {
    const viewAssessmentsSideBySide = props.viewAssessmentsSideBySide;
    const assessmentColumnCount = viewAssessmentsSideBySide ? props.assessments.length : 1;
    const totalColumnCount = viewAssessmentsSideBySide ? props.assessments.length : props.assessments.length;
    const renderAssessmentStatusWithinTable = viewAssessmentsSideBySide;
    const renderGeneralNotesWithinTable = viewAssessmentsSideBySide;
    const state: State = {
      definition: props.definition,
      scoreMeta: props.scoreMeta,
      totalColumnCount: totalColumnCount,
      assessmentColumnCount: assessmentColumnCount,
      viewAssessmentsSideBySide: viewAssessmentsSideBySide,
      renderAssessmentStatusWithinTable: renderAssessmentStatusWithinTable,
      renderGeneralNotesWithinTable: renderGeneralNotesWithinTable,
      hideCompletedAspects: props.hideCompletedAspects,
      hideZeroScores: props.hideZeroScores,
      hideColumnTotals: props.hideColumnTotals,
      hideGeneralNotes: props.hideGeneralNotes,
      columnVisibilities: props.columnVisibilities,
      visibleLabels: props.visibleLabels,
      assessments: props.assessments,
      readableAssessments: props.readableAssessments,
      editableAssessments: props.editableAssessments,
      canViewOrCreateAssessments: props.canViewOrCreateAssessments,
      viewRowLinks: props.viewRowLinks === undefined ? true : props.viewRowLinks,
      viewDefinitionHeader: props.viewDefinitionHeader === undefined ? true : props.viewDefinitionHeader,
      viewStatus: props.viewStatus === undefined ? true : props.viewStatus,
      viewNotes: props.viewNotes === undefined ? true : props.viewNotes
    };
    if (initial) {
      state.assessmentComparisonEnabled = featureFlags.assessmentComparisonEnabled();
      state.assessmentComparisonActive = false;
      state.assessmentComparisonToolbarVisible = false;
      state.assessmentHighlightThresholdPercent = 50;
    }
    return state;
  };

  UNSAFE_componentWillMount() {
    this.setState({
      lastScoreInputTimestamp: 0
    });
    this.uuidsToRefs = {};
  }

  componentDidMount() {
    this.mounted = true;
    actions.registerListener(this.onAction);
  };

  componentWillUnmount() {
    this.mounted = false;
    actions.unregisterListener(this.onAction);
    this.uuidsToRefs = {};
  };

  componentDidUpdate() {
    if (this.definitionRendered) {
      if (!this.anchorVisited) {
        urlUtil.scrollToAnchor();
        this.anchorVisited = true;
      }
    }
    widgetUtil.scheduleTooltipRebuild();
  }

  // getGuidanceVisibility = (statement) => {
  //   return this.state.statementUuidsToGuidanceVisibilities[statement.uuid];
  // };

  onAction = (actionId, context) => {
    if (!this.mounted) {
      return null;
    }
    if (actionId === actions.scrollToElementActionId) {
      this.onScrollToElement(context);
    }
  };

  onScrollToElement = (elementUuid) => {
    const element = this.uuidsToRefs[elementUuid];
    if (element) {
      scrollToComponent(element, { offset: 0, align: 'middle', duration: 500, ease:'inCirc'});
    }
  };

  onAssessmentStatusChange = (assessment, newStatusId) => {
    rubricUtil.setStatus(this.state.definition, assessment, newStatusId);
    const assessmentDAO = this.props.assessmentDAO ? this.props.assessmentDAO : firestoreAssessmentDAO;
    assessmentUtil.saveAssessment(assessmentDAO, assessment)
      .then((assessment) => {
        this.forceUpdate();
      });
  };

  getAssessmentByColumnIndex = (columnIndex) => {
    return this.state.assessments[columnIndex];
  };

  isColumnVisible = (columnIndex) => {
    if (this.state.viewAssessmentsSideBySide) {
      return true;
    } else {
      const visibility = this.state.columnVisibilities[columnIndex];
      return visibility !== false;
    }
  };

  onColumnVisibilityMouseDown = (event) => {
    if (event.nativeEvent && event.nativeEvent.stopImmediatePropagation) {
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  onChangeColumnVisibility = (event, column, columnIndexToToggle) => {
    const currentlyVisible = this.isColumnVisible(columnIndexToToggle);
    if (currentlyVisible) {
      let allOthersHidden = true;
      for (let i = 0; i < this.state.columnVisibilities.length; i++) {
        const columnVisibility = this.state.columnVisibilities[i];
        if (i !== columnIndexToToggle && columnVisibility) {
          allOthersHidden = false;
          break;
        }
      }
      if (allOthersHidden) {
        this.setColumnVisibility(columnIndexToToggle, true, true);
      } else {
        this.setColumnVisibility(columnIndexToToggle, true, false);
      }
    } else {
      this.setColumnVisibility(columnIndexToToggle, true, undefined);
    }
  };

  setColumnVisibility = (columnIndexToChange, newVisibility, otherColumnVisibilities) => {
    const columnVisibilities: any[] = [];
    const columnIndexes = Object.keys(this.state.columnVisibilities);
    let changed = false;
    for (let i = 0; i < columnIndexes.length; i++) {
      const columnIndex = parseInt(columnIndexes[i], 10);
      let visibility = this.state.columnVisibilities[columnIndex];
      if (columnIndexToChange === columnIndex) {
        visibility = newVisibility;
        changed = true;
      } else if (otherColumnVisibilities !== undefined) {
        visibility = otherColumnVisibilities;
      }
      columnVisibilities[columnIndex] = visibility;
    }
    if (!changed) {
      columnVisibilities[columnIndexToChange] = newVisibility;
    }
    this.setState({
      columnVisibilities: columnVisibilities
    });
    if (this.props.onColumnIndexToVisibilityChange) {
      this.props.onColumnIndexToVisibilityChange(columnVisibilities);
    }
  };

  onAssessmentChange = () => {
    this.debouncedUpdater();
    if (this.props.onAssessmentChange) {
      return this.props.onAssessmentChange();
    } else {
      return undefined;
    }
  };

  onGeneralNotesChange = (assessment, generalNotes) => {
    rubricUtil.setGeneralNotes(assessment, generalNotes);
    const assessmentDAO = this.props.assessmentDAO ? this.props.assessmentDAO : firestoreAssessmentDAO;
    assessmentUtil.debouncedSaveAssessment(assessmentDAO, assessment).then(() => {
      this.onAssessmentChange();
      analytics.event({
        category: analyticsDefinitions.categories.assessment,
        action: analyticsDefinitions.actions.change,
        label: 'general-notes'
      });
    });
  };

  onToggleAssessmentComparisonActive = () => {
    this.setState({
      assessmentComparisonActive: !this.state.assessmentComparisonActive
    });
  };

  onToggleAssessmentComparisonToolbarVisibility = () => {
    this.setState({
      assessmentComparisonToolbarVisible: !this.state.assessmentComparisonToolbarVisible
    });
  };

  onCloseAssessmentComparisonToolbar = () => {
    this.setState({
      assessmentComparisonActive: false,
      assessmentComparisonToolbarVisible: false
    });
  };

  onDebouncedAssessmentHighlightThresholdPercent = (value) => {
    this.setState({
      assessmentHighlightThresholdPercent: value
    });
  };


  onAssessmentHighlightThresholdPercentChange = (value) => {
    // this.setState({
    //   assessmentHighlightThresholdPercent: value
    // });
    // this.assessmentHighlightThresholdPercent = value;
    this.debounceAssessmentHighlightThresholdPercent(value);
  };

  _scheduleUpdate = () => {
    setTimeout(() => {
      this.forceUpdate();
    }, this.hideCompletedRowDelayMillis);
  };

  shouldHideRow = (row) => {
    if (this.state.hideCompletedAspects) {
      if (this.state.assessments.length) {
        const rowCompletionStatus = this.getRowCompletionStatus(row);
        if (!rowCompletionStatus.incompleteStatementFound) {
          const currentTime = new Date().getTime();
          if (currentTime - rowCompletionStatus.lastAssessmentUpdateTime > this.hideCompletedRowDelayMillis) {
            return true;
          }
        }
      } else {
        // debugger;
      }
    }
    return false;
  };

  getRowCompletionStatus = (row) => {
    const rowCompletionStatus = {
      incompleteStatementFound: false,
      lastAssessmentUpdateTime: 0
    };
    const callback = (columnIndex, statement, statementIndex, rowCompletionStatus) => {
      for (let i = 0; i < this.state.assessments.length; i++) {
        const assessment = this.state.assessments[i];
        // const info = scoreUtil.getUserFacingAssessmentItem(assessment, statement.uuid);
        const info = scoreUtil.getInternalAssessmentItem(assessment, statement.uuid);
        if (info.score === undefined || info.score < 100) {
          rowCompletionStatus.incompleteStatementFound = true;
        }
        const lastAssessmentUpdateTime = rubricUtil.getLastAssessmentUpdateTime(assessment, statement.uuid);
        if (lastAssessmentUpdateTime > rowCompletionStatus.lastAssessmentUpdateTime) {
          rowCompletionStatus.lastAssessmentUpdateTime = lastAssessmentUpdateTime;
        }
      }
      const stopIterating = rowCompletionStatus.incompleteStatementFound;
      return stopIterating;
    };
    rubricUtil.iterateRow(row, callback, rowCompletionStatus);
    return rowCompletionStatus;
  };

  determineHeaderCellRowSpan = (options) => {
    if (options && options.headerCellRowSpan !== undefined) {
      return options.headerCellRowSpan;
    }
    return 1;
  }

  determineRowNameCellRowSpan = (options) => {
    if (options && options.rowNameCellRowSpan !== undefined) {
      return options.rowNameCellRowSpan;
    }
    return 1;
  }

  renderEmptyCell = (options) => {
    return (
      <td rowSpan={this.determineHeaderCellRowSpan(options)}>
      </td>
    );
  };

  renderCategoryHeaderCell = (options) => {
    const aspectHeaderName = this.state.definition.aspectHeaderName ? this.state.definition.aspectHeaderName : I18N.Area;
    return (
      <th
        key="category-header"
        style={{paddingLeft: '6px'}}
        className="rubricCell rubricHeaderCell borderedCell"
        rowSpan={this.determineHeaderCellRowSpan(options)}
      >
        {aspectHeaderName}
      </th>
    );
  };

  renderRowWeight = (row) => {
    if (rubricUtil.getOptionEnableAspectWeights(this.state.definition)) {
      const rowWeight = rubricUtil.getRowWeight(row);
      return (
        <RowWeight weight={rowWeight}/>
      );
    } else {
      return null;
    }
  };

  onRowHeaderLinkClick = (row) => {
    // const url = urlUtil.appendAnchorReference(document.location.href, row.uuid);
    const definition = this.state.definition;
    const url = navUtil.buildDefinitionUrl(definition.uuid, definition.name, row.uuid);
    widgetUtil.copyTextToClipboard(url, () => {
      const flag = {
        id: 'text-copy-' + new Date().getTime(),
        icon: <SuccessIcon label="success" primaryColor={adg.adgGreen} />,
        title: 'Moment saved',
        description: 'Link copied to clipboard',
        flagMessageTimeoutSeconds: 5,
        actions: []
      };
      actions.addFlag(flag);
    });
  };

  renderRowNameCell = (options) => {
    const renderedWeight = options.row.fakeRow ? null : this.renderRowWeight(options.row);
    let renderedLinkIcon: any = null;
    if (this.state.viewRowLinks && options.addLinkIcon) {
      renderedLinkIcon = (
        <React.Fragment>
          {' '}
          <div
            style={{display: 'inline'}}
            className="hover-item clickable"
            data-tip="Link to this row"
            onClick={() => {
              if (options.row.fakeRow) {
                // ignore
              } else {
                this.onRowHeaderLinkClick(options.row)
              }
            }}
          >
            <LinkIcon size="small" label="Small"/>
          </div>
        </React.Fragment>
      );
    }
    const thStyle: any = {paddingLeft: '6px'};
    if (options.valign) {
      thStyle.verticalAlign = 'middle';
    }
    const rowSpan = this.determineRowNameCellRowSpan(options);
    return (
      <th
        key="row-header"
        className="rubricCell rubricHeaderCell borderedCell editorDerivedContent has-hoverables"
        style={thStyle}
        ref={(aspectRowElement) => {this.uuidsToRefs[options.row.uuid] = aspectRowElement}}
        rowSpan={rowSpan}
      >
        <div id={options.row.uuid}/>
        {options.row.name}
        {renderedLinkIcon}
        {renderedWeight}
      </th>
    );
  };

  renderRowFromCells = (renderedCells, options) => {
    const cells: any[] = [];
    if (!options.skipRowHeaderCell) {
      // let rowSpanAttribute = undefined;
      const isHeaderRow = options.row.isHeaderRow;
      let rowNameCell: any;
      if (isHeaderRow) {
        if (options.isRowToolbar) {
          rowNameCell = this.renderEmptyCell(options);
        } else {
          rowNameCell = this.renderCategoryHeaderCell(options);
        }
      } else {
        rowNameCell = this.renderRowNameCell(options);
      }
      cells.push(rowNameCell);
    }
    for (let i = 0; i < renderedCells.length; i++) {
      const renderedCell = renderedCells[i];
      cells.push(renderedCell);
    }
    const valign = options.valign ? options.valign : undefined;
    // const rowStyle = {};
    // if (options.rowBorderBottomStyle) {
    //   rowStyle.borderBottom = options.rowBorderBottomStyle;
    // }
    // if (options.rowBorderTopStyle) {
    //   rowStyle.borderBottom = options.rowBorderTopStyle;
    // }
    let className = 'rubricRow';
    if (options.additionalRowClassNames) {
      className += ` ${options.additionalRowClassNames}`
    }
    const rowKey = `row-${options.statementIndex}-${options.row.uuid}`;
    return (
      <tr
        key={rowKey}
        className={className}
        style={{verticalAlign: valign}}
      >
        {cells}
      </tr>
    );
  };

  renderCell = (key: string, cell: any, assessments: Assessment[],
    columnIndex: number, statementUuidsToAssessmentStats: any,
    multipleAssessmentsVisible: boolean, onlyShowStatementWithUuid: boolean) => {
    let parentProps: ParentProps = util.shallowCloneObject(this.props) as ParentProps;
    if (parentProps.assessments) {
      delete parentProps.assessments;
    }
    if (parentProps.readableAssessments) {
      delete parentProps.readableAssessments;
    }
    if (parentProps.editableAssessments) {
      delete parentProps.editableAssessments;
    }
    const readableAssessments = permissionUtil.filterAssessmentsWithViewPermission(assessments);
    const editableAssessments = permissionUtil.filterAssessmentsWithWritePermission(assessments);
    const assessmentHighlightThresholdPercent = this.state.assessmentHighlightThresholdPercent ? this.state.assessmentHighlightThresholdPercent : 0;
    return (
      <RubricCell
        key={key}
        cell={cell}
        columnIndex={columnIndex}
        {...parentProps}
        definition={this.state.definition}
        scoreMeta={this.state.scoreMeta}
        hideCompletedAspects={this.state.hideCompletedAspects}
        hideZeroScores={this.state.hideZeroScores}
        hideColumnTotals={this.state.hideColumnTotals}
        onlyShowStatementWithUuid={onlyShowStatementWithUuid}
        columnVisibilities={this.state.columnVisibilities}
        visibleLabels={this.state.visibleLabels}
        assessments={assessments}
        multipleAssessmentsVisible={multipleAssessmentsVisible}
        assessmentDAO={this.props.assessmentDAO ? this.props.assessmentDAO : firestoreAssessmentDAO}
        readableAssessments={readableAssessments}
        editableAssessments={editableAssessments}
        assessmentComparisonActive={this.state.assessmentComparisonActive === true}
        assessmentHighlightThresholdPercent={assessmentHighlightThresholdPercent}
        statementUuidsToAssessmentStats={statementUuidsToAssessmentStats}
        canViewOrCreateAssessments={this.state.canViewOrCreateAssessments}
        isColumnVisible={this.isColumnVisible}
        onCellAssessmentChange={this.onAssessmentChange}
        getGuidanceVisibility={this.props.getGuidanceVisibility}
        onGuidanceExpanded={this.props.onGuidanceExpanded}
        onGuidanceCollapsed={this.props.onGuidanceCollapsed}
      />
    );
  };

  renderRowCells = (cell, definitionColumnIndex, renderedCells, statementUuidsToAssessmentStats, onlyShowStatementWithUuid) => {
    const multipleAssessmentsVisible = this.state.assessments.length > 1;
    if (this.state.viewAssessmentsSideBySide) {
      for (let assessmentIndex = 0; assessmentIndex < this.state.assessments.length; assessmentIndex++) {
        const assessment = this.state.assessments[assessmentIndex];
        const assessmentsForCell = [assessment];
        const key = 'cell-' + cell.uuid + '-assessment-' + assessmentIndex;
        const renderedCell = this.renderCell(key, cell, assessmentsForCell, definitionColumnIndex, statementUuidsToAssessmentStats, multipleAssessmentsVisible, onlyShowStatementWithUuid);
        renderedCells.push(renderedCell);
      }
    } else {
      const key = 'cell-' + cell.uuid;
      const renderedCell = this.renderCell(key, cell, this.state.assessments, definitionColumnIndex, statementUuidsToAssessmentStats, multipleAssessmentsVisible, onlyShowStatementWithUuid);
      renderedCells.push(renderedCell);
    }
  };

  renderEditabilityWarning = () => {
    let warningMessage: undefined | string = undefined;
    let warningColor: undefined | string = undefined;
    const editability = this.state.definition.editability;
    if (editability === editabilityDefinitions.fullEditabilityType) {
      warningMessage = `This rubric is fully editable which means the owner can add and remove parts of it.`;
      warningColor = adg.adgRed;
    } else if (editability === editabilityDefinitions.partialEditabilityType) {
      warningMessage = `This rubric is partially editable which means the owner can add to it.`;
      warningColor = adg.adgCheetoOrange;
    }
    if (warningMessage && warningColor) {

    } else {
      return null;
    }
    return (
      <div
        style={{
          color: warningColor,
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '1.3em'
        }}
        className="toolbar-center"
      >
        <ToolbarItem>
          <WarningIcon label="Warning"/>
        </ToolbarItem>
        <ToolbarItem>
          {warningMessage}
        </ToolbarItem>
      </div>
    );
  };

  renderJoinPlanPanelViaLink = () => {
    const signUpUrl = urlUtil.buildAbsoluteLink(navConstants.pricingPageHash);
    return (
      <React.Fragment>
        <ToolbarItem>
          Assessment capabilities are not available until you 
          {' '} <a href={signUpUrl} target="_blank" rel="noopener noreferrer">sign up</a> {' '}
          to the {planDefinitions.typesToDefinitions[planDefinitions.proType].name} plan.
        </ToolbarItem>
      </React.Fragment>
    );
  };

  renderJoinPlanPanelViaButton = () => {
    return (
      <React.Fragment>
        <ToolbarItem>
          Assessment capabilities are not available until you sign up to
          the {planDefinitions.typesToDefinitions[planDefinitions.proType].name} plan.
        </ToolbarItem>
        <ToolbarItem style={{marginLeft: '0.5em'}}>
          <SignUpButton
            label="Sign up now"
          />
        </ToolbarItem>
      </React.Fragment>
    );
  };

  renderJoinPlanPanel = () => {
    return (
      <ToolbarCenter>
        <ToolbarItem>
          <WarningIcon label="Warning" primaryColor={adg.adgRed}/>
        </ToolbarItem>
        {this.props.embedded ? this.renderJoinPlanPanelViaLink() : this.renderJoinPlanPanelViaButton()}
      </ToolbarCenter>
    );
  };

  renderColumnHeaderVisibilityToggle = (column, columnIndex) => {
    // The computation of columns widths when one or more are not visible is currently
    // broken so don't show the icon allowing the column to be hidden.
    return null;

    // if (this.state.definition.columns.length === 1) {
    //   return null;
    // }
    // const visible = this.state.columnVisibilities[columnIndex] !== false;
    // const normalIcon = visible ? <ViewColumnIcon/> : <ViewColumnFilledIcon/>;
    // const hoverIcon = visible ? <ViewColumnFilledIcon/> : <ViewColumnIcon/>;
    // const normalColor = visible ? undefined : adg.adgRed;
    // const hoverColor = visible ? undefined : adg.adgBlue;
    // const tooltip = visible ? 'Hide this column' : 'Show column "' + column.name + '"';
    // return (
    //   <div style={{minWidth: '24px'}}>
    //     <Tooltip content={tooltip}>
    //       <IconButton
    //         normalIcon={normalIcon}
    //         hoverIcon={hoverIcon}
    //         normalColor={normalColor}
    //         hoverColor={hoverColor}
    //         onMouseDown={this.onColumnVisibilityMouseDown}
    //         onClick={(event) => this.onChangeColumnVisibility(event, column, columnIndex)}
    //       />
    //     </Tooltip>
    //   </div>
    // );
  };

  renderStatusOfAssessment = (assessment, showLabel) => {
    let label: undefined | string = undefined;
    if (showLabel) {
      const defaultLabel = 'Assessment status';
      label = showLabel && this.state.assessments.length > 1 ? `${defaultLabel} for ${assessment.name}` : defaultLabel;  
    }
    if (featureFlags.assessmentStatusesEnabled() && assessment) {
      const statusIdsToConfigs = rubricUtil.getStatusIdsToConfigs(this.state.definition, assessment);
      return (
        <div key={`assessment-status-${assessment.uuid}`}>
          <AssessmentStatus 
            statusIdsToConfigs={statusIdsToConfigs}
            definition={this.state.definition}
            assessment={assessment}
            label={label}
            onAssessmentStatusChange={this.onAssessmentStatusChange}
          />
        </div>
      );  
    } else {
      return null;
    }
  };

  renderGeneralNotesForAssessment = (assessment, showLabel) => {
    if (this.state.hideGeneralNotes === true) {
      return null;
    }
    let label: undefined | string = undefined;
    if (showLabel) {
      const defaultLabel = 'General assessment notes';
      label = showLabel && this.state.assessments.length > 1 ? `${defaultLabel} for ${assessment.name}` : defaultLabel;  
    }
    return (
      <GeneralNotesField
        key={'general-notes-' + assessment.uuid}
        label={label}
        definition={this.state.definition}
        assessment={assessment}
        onChange={this.onGeneralNotesChange}
      />
    );
  };

  renderAssessmentStatus = () => {
    if (!this.state.viewStatus) {
      return null;
    }
    if (this.state.renderAssessmentStatusWithinTable) {
      return null;
    } else {
      if (this.state.assessments.length) {
        return this.state.assessments.map((assessment) => {
          return this.renderStatusOfAssessment(assessment, true);
        });
      } else {
        return null;
      }  
    }
  };

  renderGeneralNotes = () => {
    if (!this.state.viewNotes) {
      return null;
    }
    if (this.state.renderGeneralNotesWithinTable) {
      return null;
    } else {
      if (this.state.assessments.length) {
        return this.state.assessments.map((assessment) => {
          return this.renderGeneralNotesForAssessment(assessment, true);
        });
      } else {
        return null;
      }  
    }
  };

  computeTableLayout = (definition, assessments, viewAssessmentsSideBySide, containerWidth) => {
    const tableLayout: any = {
      debug: false,
      tableWidth: undefined,
      isFixed: false
    };
    const minHeaderColumnWidth = 100;
    const maxHeaderColumnWidth = 300;
    const minOtherColumnWidth = 220;
    const definitionColumnCount = rubricUtil.countColumns(definition);
    const visibleNonHeaderColumnCount = viewAssessmentsSideBySide ?
      assessments.length : definitionColumnCount;
    tableLayout.isFixed = true;
    const minTableWidth = minHeaderColumnWidth + visibleNonHeaderColumnCount * minOtherColumnWidth;
    const borderWidth = 1;
    const tableWidth = minTableWidth > containerWidth ? minTableWidth : containerWidth - 2 * borderWidth;
    let firstColumnWidth: undefined | number = undefined;
    if (minTableWidth > containerWidth) {
      firstColumnWidth = minHeaderColumnWidth;
    } else {
      const minToContainerWidthFactor = minTableWidth / containerWidth;
      firstColumnWidth = minHeaderColumnWidth + (1 - minToContainerWidthFactor) * (maxHeaderColumnWidth - minHeaderColumnWidth);
      if (firstColumnWidth > maxHeaderColumnWidth) {
        // this should be impossible to reach
        // debugger;
        firstColumnWidth = maxHeaderColumnWidth;
      }
      if (tableLayout.debug) {
        tableLayout.minToContainerWidthFactor = minToContainerWidthFactor;
      }
    }
    // Only specify the name column width as this will cause the other columns to
    // have equal widths to eachother.
    tableLayout.firstColumnWidth = firstColumnWidth;
    tableLayout.tableWidth = tableWidth;
    if (tableLayout.debug) {
      tableLayout.otherColumnWidth = (tableWidth - tableLayout.firstColumnWidth) / visibleNonHeaderColumnCount;
    }
    return tableLayout;
  };

  renderHeaderRow = (definition: Definition, showColumnTotals: boolean, columnTotals: null | ColumnTotal[]) => {
    const renderedHeaderCells: any[] = [];
    // let assessmentItemIndex = 0;
    for (let definitionColumnIndex = 0; definitionColumnIndex < definition.columns.length; definitionColumnIndex++) {
      const definitionColumn = definition.columns[definitionColumnIndex];
      for (let columnIndex = 0; columnIndex < this.state.assessmentColumnCount; columnIndex++) {
        const visible = this.isColumnVisible(definitionColumnIndex);
        const hidden = visible === false;
        const headerCellStyle = {
          paddingLeft: '6px'
        };
        const assessment = this.getAssessmentByColumnIndex(columnIndex);
        let renderedEditabilityNote: any = null;
        if (this.state.viewAssessmentsSideBySide && featureFlags.showAssessmentEditabilityNote()) {
          const canEditAssessment = permissionUtil.canWriteAssessment(assessment);
          if (canEditAssessment) {
            // renderedEditabilityNote = (
            //   <div className="success toolbar-center">
            //     <SuccessIcon label="success" />
            //     <div style={{ marginLeft: '0px' }}>
            //       You can change this assessment.
            //     </div>
            //   </div>
            // );
            renderedEditabilityNote = null;
          } else {
            renderedEditabilityNote = (
              <div className="warning toolbar-center">
                <WarningIcon label="warning" />
                <div style={{ marginLeft: '0px' }}>
                  You can not change this assessment.
                </div>
              </div>
            );
          }
        }
        const columnTitle = this.state.viewAssessmentsSideBySide ? assessment.name : definitionColumn.name;
        const renderedTitle = hidden ? null : (
          <ToolbarItem>
            {columnTitle}
          </ToolbarItem>
        );
        let renderedSuffix: any = null;
        if (!hidden && showColumnTotals) {
          const assessmentItemIndex = this.state.viewAssessmentsSideBySide ? columnIndex : definitionColumnIndex;
          const score = columnTotals === null ? undefined : columnTotals[assessmentItemIndex].value;
          const assessmentItem = scoreUtil.buildAssessmentItemFromScore(score);
          renderedSuffix = (
            <ToolbarItem>
              <StatementScore
                scoreMeta={this.state.scoreMeta}
                assessmentItem={assessmentItem}
                radius={12}
                dragDown={2}
                hideIfZero={this.state.hideZeroScores}
              />
            </ToolbarItem>
          );
        }
        const renderedColumnHeaderVisibilityToggle = this.renderColumnHeaderVisibilityToggle(
          definitionColumn, definitionColumnIndex);
        const renderedColumnHeaderContent = hidden ? renderedColumnHeaderVisibilityToggle : (
          <>
            <ToolbarJustify>
              <ToolbarLeft>
                <ToolbarItem>
                  {renderedTitle}
                </ToolbarItem>
                <ToolbarItem>
                  {renderedSuffix}
                </ToolbarItem>
              </ToolbarLeft>
              <ToolbarItem>
                {renderedColumnHeaderVisibilityToggle}
              </ToolbarItem>
            </ToolbarJustify>
            {renderedEditabilityNote}
          </>
        );
        const renderedHeaderCell = (
          <th
            key={'header-column-' + definitionColumn.uuid + '-column-index-' + columnIndex}
            className="rubricCell rubricHeaderCell rubricHeaderCell borderedCell"
            style={headerCellStyle}
          >
            <span className="editorDerivedContent">{renderedColumnHeaderContent}</span>
          </th>
        );
        renderedHeaderCells.push(renderedHeaderCell);
      }
      // assessmentItemIndex++;
    }
    return renderedHeaderCells;
  };

  renderSpecialRow = (definition, key, contentRenderer) => {
    const renderedCells: any[] = [];
    for (let definitionColumnIndex = 0; definitionColumnIndex < definition.columns.length; definitionColumnIndex++) {
      const definitionColumn = definition.columns[definitionColumnIndex];
      if (this.state.viewAssessmentsSideBySide && definitionColumnIndex > 0) {
        // this should not occur
        // debugger;
      }
      for (let columnIndex = 0; columnIndex < this.state.assessmentColumnCount; columnIndex++) {
        const assessment = this.getAssessmentByColumnIndex(columnIndex);
        const headerCellStyle = {
          paddingLeft: '6px'
        };
        const renderedContent = contentRenderer(assessment);
        const renderedCell = (
          <td
            key={`${key}-column-${definitionColumn.uuid}-column-index-${columnIndex}`}
            className="rubricCell rubricHeaderCell rubricHeaderCell borderedCell"
            style={headerCellStyle}
          >
            <span className="editorDerivedContent">{renderedContent}</span>
          </td>
        );
        renderedCells.push(renderedCell);
      }
    }
    return renderedCells;
  };

  renderStatusesRow = (definition) => {
    const contentRenderer = (assessment) => {
      return (
        <div>
          {assessment ? this.renderStatusOfAssessment(assessment, false) : null}
        </div>
      );
    };
    return this.renderSpecialRow(definition, 'status', contentRenderer);
  };

  renderNotesRow = (definition) => {
    const contentRenderer = (assessment) => {
      return (
        <div>
          {assessment ? this.renderGeneralNotesForAssessment(assessment, false) : null}
        </div>
      );
    };
    return this.renderSpecialRow(definition, 'notes', contentRenderer);
  };

  computeStatementUuidsToAssessmentStats = () => {
    const columnCount = rubricUtil.countColumns(this.state.definition);
    if (columnCount > 1) {
      return;
    }
    if (this.state.assessments.length <= 1) {
      return;
    }
    const statementUuidsToAssessmentStats = {};
    const definition = this.state.definition;
    const assessments = this.state.assessments;
    const visibleLabels = rubricUtil.getDefinitionLabels(this.state.definition);
    const columnVisibilities = this.state.columnVisibilities;
    const callback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, label, labelIndex, assessmentItem) => {
      let assessmentDiffs = statementUuidsToAssessmentStats[statement.uuid];
      if (!assessmentDiffs) {
        assessmentDiffs = {
          rowUuid: row.uuid,
          averageScorePercent: 0,
          minScorePercent: undefined,
          maxScorePercent: undefined,
          totalScorePercent: 0,
          scoreCount: 0,
        };
        statementUuidsToAssessmentStats[statement.uuid] = assessmentDiffs;
      }
      const denormalisedAssessmentItem = scoreUtil.denormaliseAssessmentItem(assessmentItem);
      if (denormalisedAssessmentItem.hasScore) {
        if (denormalisedAssessmentItem.scorePercent > 0) {
          assessmentDiffs.totalScorePercent = assessmentDiffs.totalScorePercent + denormalisedAssessmentItem.scorePercent;
        }
        if (assessmentDiffs.minScorePercent === undefined || denormalisedAssessmentItem.scorePercent < assessmentDiffs.minScorePercent) {
          assessmentDiffs.minScorePercent = denormalisedAssessmentItem.scorePercent;
        }
        if (assessmentDiffs.maxScorePercent === undefined || denormalisedAssessmentItem.scorePercent > assessmentDiffs.maxScorePercent) {
          assessmentDiffs.maxScorePercent = denormalisedAssessmentItem.scorePercent;
        }
        assessmentDiffs.scoreCount++;
        assessmentDiffs.averageScorePercent = assessmentDiffs.totalScorePercent / assessmentDiffs.scoreCount;
      }
    };
    const context = undefined;
    rubricUtil.iterateVisibleAssessments(definition, assessments, visibleLabels, columnVisibilities, callback, context);
    return statementUuidsToAssessmentStats;
  };


  renderRowGroups = (definition) => {
    const columnCount = rubricUtil.countColumns(this.state.definition);
    const showStatementsInSeparateRows = columnCount === 1 && featureFlags.enableShowStatementsInSeparateRows();
    const statementUuidsToAssessmentStats = this.state.assessmentComparisonActive ?
      this.computeStatementUuidsToAssessmentStats() : undefined;
    // let lastGroupUuid = undefined;
    const renderedRowGroups = definition.groups.map((group, groupIndex) => {
      // lastGroupUuid = group.uuid;
      const renderedRows = group.rows.map((row) => {
        if (this.shouldHideRow(row)) {
          return null;
        }
        if (showStatementsInSeparateRows) {
          const renderedRows: any[] = [];
          rubricUtil.iterateRow(row, (columnIndex, statement, statementIndex, context) => {
            const renderedCells = [];
            for (let definitionColumnIndex = 0; definitionColumnIndex < row.cells.length; definitionColumnIndex++) {
              const cell = row.cells[definitionColumnIndex];
              const onlyShowStatementWithUuid = statement.uuid;
              this.renderRowCells(cell, definitionColumnIndex, renderedCells, statementUuidsToAssessmentStats, onlyShowStatementWithUuid);
            }
            const options: any = {
              row: row,
              statementIndex: statementIndex,
              addLinkIcon: true
            };
            if (statementIndex === 0) {
              const statementCount = rubricUtil.countStatementsInRow(row);
              // options.headerCellRowSpan = statementCount;
              options.rowNameCellRowSpan = statementCount;
              options.skipRowHeaderCell = false;
              // options.additionalRowClassNames = 'rubricRowNoBottomBorder';
            } else {
              options.skipRowHeaderCell = true;
              // const rowStatementCount = rubricUtil.countStatementsInRow(row);
              // if (statementIndex === rowStatementCount - 1) {
              //   options.additionalRowClassNames = 'rubricRowNoTopBorder';
              // }
            }
            const renderedRow = this.renderRowFromCells(renderedCells, options);
            renderedRows.push(renderedRow)
          });
          return renderedRows;
        } else {
          const renderedCells = [];
          for (let definitionColumnIndex = 0; definitionColumnIndex < row.cells.length; definitionColumnIndex++) {
            const cell = row.cells[definitionColumnIndex];
            const onlyShowStatementWithUuid = undefined;
            this.renderRowCells(cell, definitionColumnIndex, renderedCells, statementUuidsToAssessmentStats, onlyShowStatementWithUuid);
          }
          const options = {
            row: row,
            addLinkIcon: true
          };
          const renderedRow = this.renderRowFromCells(renderedCells, options);
          return renderedRow;
        }
      });
      return (
        <tbody
          key={`row-group-${group.uuid}`}
          style={{border: '0px'}}
        >
          {renderedRows}
        </tbody>
      );
    });
    return renderedRowGroups;
  };

  renderAssessmentComparisonButtonToggle = (inFloatingToolbar) => {
    return (
      <ToolbarItem>
        <Checkbox
          label="Compare assessments"
          isChecked={this.state.assessmentComparisonActive}
          crossOrigin={undefined}
          onChange={() => {
            this.onToggleAssessmentComparisonActive();
            if (!inFloatingToolbar) {
              this.onToggleAssessmentComparisonToolbarVisibility();
            }
          }}
        />
      </ToolbarItem>
    );
  };

  renderAssessmentComparisonThresholdSlider = () => {
    return (
      <React.Fragment>
        <ToolbarItem>
          <Tooltip content="Slide to adjust sensitivity">
            <Range
              step={1}
              value={this.state.assessmentHighlightThresholdPercent}
              min={0}
              max={100}
              isDisabled={!this.state.assessmentComparisonActive}
              crossOrigin={undefined}
              onChange={this.onAssessmentHighlightThresholdPercentChange}
            />
          </Tooltip>
        </ToolbarItem>
        <ToolbarItem style={{marginLeft: '5px'}}>
          sensitivity
        </ToolbarItem>
      </React.Fragment>
    );
  };

  renderCloseAssessmentVisibilityToolbar = () => {
    return (
      <ToolbarItem>
        <Button
          appearance="primary"
          onClick={this.onCloseAssessmentComparisonToolbar}
        >
          Close
        </Button>
      </ToolbarItem>
    );
  };

  renderAssessmentComparisonControls = (inFloatingToolbar) => {
    return (
      <ToolbarCenter>
        {this.renderAssessmentComparisonButtonToggle(inFloatingToolbar)}
        {inFloatingToolbar ? this.renderAssessmentComparisonThresholdSlider() : null}
        {inFloatingToolbar ? this.renderCloseAssessmentVisibilityToolbar() : null}
      </ToolbarCenter>
    );
  };

  renderFloatingComparisonToolbar = (containerWidth) => {
    return (
      <ComparisonToolbar
        containerWidth={containerWidth}
        assessmentComparisonActive={this.state.assessmentComparisonActive}
        onToggleAssessmentComparisonActive={this.onToggleAssessmentComparisonActive}
        onAssessmentHighlightThresholdPercentChange={this.onAssessmentHighlightThresholdPercentChange}
      >
        {this.renderAssessmentComparisonControls(true)}
      </ComparisonToolbar>
    );
  };

  renderRubric = (definition) => {
    // const style = definition.style ? definition.style : {};
    // const borderColor = style.borderColor ? style.borderColor : '#ccc';
    // const hoverColor = style.hoverColor ? style.hoverColor : '#F9F9F9';
    // const emptyCellColor = style.hoverColor ? style.hoverColor : '#F8F8F8';
    // const showColumnTotals =
    //   this.state.assessments && this.state.assessments.length && 
    //   this.state.canViewOrCreateAssessments && !this.state.hideColumnTotals;
    // const columnTotals = showColumnTotals ?
    //   rubricUtil.computeColumnTotals(definition, this.state.assessments, this.state.visibleLabels, this.state.viewAssessmentsSideBySide) : null;
  };

  renderDefinitionSideBySideAssessmentsHeaderRow = (definition) => {
    let renderedSideBySideAssessmentsHeaderRow: any = null;
    if (this.state.viewDefinitionHeader && this.state.viewAssessmentsSideBySide && this.state.assessments.length > 0) {
      const definitionColumn = definition.columns[0];
      const headerText = definitionColumn.name;
      let sideBySideAssessmentsHeaderCells = [(
        <th
          key="sideBySideAssessmentsHeaderCell"
          style={{textAlign: 'center', width: '100%'}}
          className="rubricCell rubricHeaderCell rubricHeaderCell borderedCell"
          colSpan={this.state.totalColumnCount}
        >
          {this.state.assessments.length > 1 && this.state.assessmentComparisonEnabled ? this.renderAssessmentComparisonControls(false) : headerText}
        </th>
      )];
      const headerRowOptions = {
        row: {
          uuid: 'mult-column-header-row',
          fakeRow: true,
          isHeaderRow: true
        },
        headerCellRowSpan: 2
      };
      renderedSideBySideAssessmentsHeaderRow = this.renderRowFromCells(sideBySideAssessmentsHeaderCells, headerRowOptions);
    }
    return renderedSideBySideAssessmentsHeaderRow;
  }

  renderDefinitionHeaderRow = (definition, renderedSideBySideAssessmentsHeaderRow) => {
    const showColumnTotals: boolean =
      this.state.assessments && this.state.assessments.length > 0 && 
      this.state.canViewOrCreateAssessments === true && this.state.hideColumnTotals !== true;
    const columnTotals = showColumnTotals ? rubricUtil.computeColumnTotals(
        definition, this.state.assessments, this.state.visibleLabels, this.state.viewAssessmentsSideBySide) : null;
    const headerRowOptions = {
      row: {
        uuid: 'header-row',
        fakeRow: true,
        isHeaderRow: true
      },
      skipRowHeaderCell: renderedSideBySideAssessmentsHeaderRow ? true : false
    };
    const renderedHeaderCells = this.renderHeaderRow(definition, showColumnTotals, columnTotals);
    return this.renderRowFromCells(renderedHeaderCells, headerRowOptions);
  };

  renderDefinitionStatusesRow = (definition) => {
    let renderedStatusesRow: any = null;
    if (featureFlags.assessmentStatusesEnabled()) {
      if (this.state.renderAssessmentStatusWithinTable) {
        const renderedStatusCells = this.renderStatusesRow(definition);
        const rowOptions = {
          row: {
            uuid: 'statuses-row',
            fakeRow: true,
            name: 'Status'
          },
          valign: 'center'
        };
        renderedStatusesRow = this.renderRowFromCells(renderedStatusCells, rowOptions);
      }  
    }
    return renderedStatusesRow;
  };

  renderDefinitionNotesRow = (definition) => {
    let renderedNotesRow: any = null;
    if (this.state.renderGeneralNotesWithinTable) {
      const renderedNotesCells = this.renderNotesRow(definition);
      const rowOptions = {
        row: {
          uuid: 'notes-row',
          fakeRow: true,
          name: 'Notes'
        },
        valign: 'center'
      };
      renderedNotesRow = this.renderRowFromCells(renderedNotesCells, rowOptions);
    }
    return renderedNotesRow;
  };

  renderDefinition = (definition) => {
    const style = definition.style ? definition.style : {};
    const borderColor = style.borderColor ? style.borderColor : '#ccc';
    const hoverColor = style.hoverColor ? style.hoverColor : '#F9F9F9';
    const emptyCellColor = style.hoverColor ? style.hoverColor : '#F8F8F8';
    return (
      <div style={{overflowY: 'hidden'}}>
        {this.state.canViewOrCreateAssessments ? this.renderEditabilityWarning() : this.renderJoinPlanPanel()}
        <style dangerouslySetInnerHTML={{__html: `
          tbody {
            vertical-align: top;
          }
          .borderedCell {
            border: 1px solid ${borderColor};
          }
          .hoverCell:hover {
            background-color: ${hoverColor};
          }
          .emptyCell {
            background-color: ${emptyCellColor};
          }
          table.rubric-viewer {
            table-layout: auto;
            margin: 0px;
          }
        `}} />
        {this.renderAssessmentStatus()}
        {this.renderGeneralNotes()}
        <div className="mainContentSection">&nbsp;</div>
        <ContainerDimensions>
          {
            ({ width, height }) => {
              const tableWidth = width === undefined || isNaN(width) ? 0 : width;
              const tableLayout = this.computeTableLayout(
                definition, this.state.assessments, this.state.viewAssessmentsSideBySide, tableWidth);
              this.tableLayout = tableLayout;
              // const tableStyle = tableLayout.isFixed ? {tableLayout: 'fixed'} : undefined;
              const tableStyle: CSSProperties = {
                tableLayout: 'fixed'
              };
              const renderedColgroup = tableLayout.isFixed && tableLayout.firstColumnWidth ? (
                <colgroup><col width={tableLayout.firstColumnWidth}></col></colgroup>
              ) : null;
              if (tableLayout.tableWidth) {
                tableStyle.width = tableLayout.tableWidth;
              }
              const dummyRowForFirefoxFixedLayout = tableLayout.isFixed ? (<tr><td style={{width: `${tableLayout.firstColumnWidth}px`}}></td></tr>) : null;
              const renderedLayoutDebug = tableLayout.debug ? (<div>{JSON.stringify(tableLayout)}</div>) : null;
              const renderedSideBySideAssessmentsHeaderRow = this.renderDefinitionSideBySideAssessmentsHeaderRow(definition);
              return (
                <React.Fragment>
                  {renderedLayoutDebug}
                  {this.state.assessmentComparisonToolbarVisible ? this.renderFloatingComparisonToolbar(width) : null}
                  <table
                    ref={(c) => this.tableElement = c}
                    className="rubric-viewer"
                    style={tableStyle}
                  >
                    {renderedColgroup}
                    <thead style={{border: '0px'}}>
                      {dummyRowForFirefoxFixedLayout}
                      
                      {renderedSideBySideAssessmentsHeaderRow}
                      {this.state.viewDefinitionHeader ? this.renderDefinitionHeaderRow(definition, renderedSideBySideAssessmentsHeaderRow) : null}
                      {this.state.viewStatus ? this.renderDefinitionStatusesRow(definition) : null}
                      {this.state.viewNotes ? this.renderDefinitionNotesRow(definition) : null}
                    </thead>
                    {this.renderRowGroups(definition)}
                  </table>
                </React.Fragment>
              );
            }
          }
        </ContainerDimensions>
      </div>
    );
  };

  render() {
    if (!this.definitionRendered && this.state.definition) {
      this.definitionRendered = true;
    }
    return this.state.definition ? this.renderDefinition(this.state.definition) : null;
  }
}
