import React, { PureComponent } from 'react';
import TextArea from '@atlaskit/textarea';
import Label from './Label';
import util from '../../../shared/util/Util';
import widgetUtil from '../../../commonbase/util/widgetUtil';
import './ResizingTextArea.css';
import adg from '../../../commonbase/adg';
import selectionUtil from './SelectionUtil';

const DEFAULT_HEIGHT = 20;

interface Props {
  value: string
  label?: string
  placeholder?: string
  compact?: boolean
  minHeight?: number
  maxHeight?: number
  maxLength?: number
  minimumRows?: number
  shouldFitContainer?: boolean
  enableResize?: boolean
  autoFocus?: boolean
  // startInEditMode?: boolean
  onChange?: Function
  debounceChangeCallback?: boolean
  onKeyUp?: Function
  onFocus?: Function
  onBlur?: Function
  onTextAreaRef?: (input: any) => void
}

interface State {
  value: string
  label?: string
  isLabelHidden: boolean
  placeholder: string
  compact: boolean
  minHeight: number
  maxHeight: number
  maxLength?: number
  minimumRows: number
  shouldFitContainer: boolean
  enableResize: boolean
  autoFocus: boolean
  forceFocus?: boolean
  editMode: boolean
  height: number
  resizedMinRows?: number
  onChangeHandler?: Function
}

export default class ResizingTextArea extends PureComponent<Props, State> {

  mounted: boolean = false;
  onDebouncedKeyUp: any;
  resizeAfterNextRender: boolean = true;
  ghost: any;
  moveCaretToEndAllowed = true;
  // textAreaElement: any;

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
    this.onDebouncedKeyUp = util.debounce(this.onKeyUp, 200, false);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    // this.setState(this.buildStateFromProps(props));
    // this.resizeAfterNextRender = true;

    // const oldState = this.state;
    // const newState = this.buildStateFromProps(props);
    // const same = util.shallowCompareObjects(oldState, newState);
    // if (!same) {
    //   // console.log(`oldState: `, JSON.stringify(oldState));
    //   // console.log(`newState: `, JSON.stringify(newState));
    //   // debugger;
    //   this.setState(newState);
    //   this.resizeAfterNextRender = true;
    // }
  }

  componentDidMount() {
    // debugger;
    this.mounted = true;
    this.resize();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  buildStateFromProps = (props: Props): State => {
    // let onChangeHandler: undefined | Function = undefined;;
    // if (props.onChange && props.debounceChangeCallback) {
    //   onChangeHandler = util.debounce(props.onChange, 200, false);
    // }

    let onChangeHandler: undefined | Function = undefined;
    if (this.state && this.state.onChangeHandler) {
      // For efficiency, assume the onChange handler doesn't change
      onChangeHandler = this.state.onChangeHandler;
    } else {
      if (props.onChange && props.debounceChangeCallback) {
        onChangeHandler = util.debounce(props.onChange, 200, false);
      } else {
        onChangeHandler = props.onChange;
      }
    }

    const state: State = {
      value: props.value,
      label: props.label,
      isLabelHidden: props.label ? false : true,
      placeholder: props.placeholder ? props.placeholder : '',
      compact: props.compact === true,
      minHeight: props.minHeight ? props.minHeight : 30,
      maxHeight: props.maxHeight ? props.maxHeight : 1000,
      maxLength: props.maxLength,
      minimumRows: props.minimumRows ? props.minimumRows : 1,
      shouldFitContainer: props.shouldFitContainer === true,
      enableResize: props.enableResize === true,
      autoFocus: props.autoFocus === true,
      editMode: this.state ? this.state.editMode : props.autoFocus ? true : false,
      // editMode: true,
      height: this.state ? this.state.height : DEFAULT_HEIGHT,
      resizedMinRows: props.minimumRows ? props.minimumRows : this.state && this.state.resizedMinRows ? this.state.resizedMinRows : undefined,
      onChangeHandler: onChangeHandler
    };
    return state;
  };

  onKeyDown = (event) => {
    this.moveCaretToEndAllowed = false;
  }

  onKeyUp = (event) => {
    if (this.mounted) {
      this.resize();
      if (this.props.onKeyUp) {
        this.props.onKeyUp(event);
      }
    }
  };

  resize = () => {
    const element = this.ghost;
    if (element) {
      const ghostHeight = element.clientHeight;
      const height = Math.max(this.state.minHeight, Math.min(ghostHeight, this.state.maxHeight));
      const padding = 4;
      const lineHeight = 18;
      const textHeight = height - 2 * padding;
      const resizedMinRows = Math.max(this.state.minimumRows, Math.ceil(textHeight / lineHeight));
      this.setState({
        height: height,
        resizedMinRows: resizedMinRows,
      });
    }
  };

  onChange = (event) => {
    if (this.mounted) {
      const target = event.target || event.currentTarget();
      if (target && target.value !== undefined) {
        const value = target.value;
        this.setState({
          value: value,
        });
        if (this.state.onChangeHandler) {
          if (this.props.debounceChangeCallback) {
            this.state.onChangeHandler(value);
          } else {
            this.state.onChangeHandler(event);
          }
        }
      }
    }
  };

  onViewContainerClick = () => {
    this.setState({
      editMode: true,
      forceFocus: true
    });
  }

  onFocus = (event) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  onBlur = (event) => {
    this.setState({
      editMode: false
    });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  onTextAreaRef = (textAreaElement) => {
    const autoFocus = this.props.autoFocus || this.state.forceFocus;
    if (this.state.forceFocus) {
      this.setState({
        forceFocus: false
      });
    }
    if (autoFocus && textAreaElement && textAreaElement.focus) {
      if (this.moveCaretToEndAllowed) {
        selectionUtil.focusWithCaretAtEnd(textAreaElement);
      } else {
        textAreaElement.focus();
      }
    }
    if (this.props.onTextAreaRef && textAreaElement) {
      this.props.onTextAreaRef(textAreaElement);
    }
  };

  renderLabel = () => {
    return (
      <Label text={this.state.label ? this.state.label : ''}/>
    )
  };

  renderExpandableFieldTextArea() {
    return (
      <div
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      >
        {this.state.isLabelHidden ? null : this.renderLabel()}
        <TextArea
          isCompact={this.state.shouldFitContainer === false}
          minimumRows={this.state.resizedMinRows}
          resize={this.state.enableResize ? 'auto' : 'none'}
          placeholder={this.state.placeholder}
          value={this.state.value}
          maxHeight={`${this.state.height}px`}
          ref={this.onTextAreaRef}
          onChange={this.onChange}
        />
      </div>
    );
  }

  renderViewMode = () => {
    // const lineHeight = 20;
    const color = this.state.value ? undefined : adg.placeholderColor;
    const style: any = {
      // minHeight: this.state.resizedMinRows * lineHeight,
      minHeight: this.state.minHeight,
      maxHeight: this.state.maxHeight,
      overflowX: 'hidden',
      overflowY: 'auto',
      color: color
    }
    const html = this.state.value ? widgetUtil.markdownToHtml(this.state.value) : this.state.placeholder;
    // const text = this.state.value ? this.state.value : this.state.placeholder;
    return (
      <div
        className="resizing-text-area-container-view-mode focussableInputItem"
        style={style}
        onClick={this.onViewContainerClick}
        dangerouslySetInnerHTML={{ __html: html }}
      >
      </div>
    );
  }

  renderExpandableField() {
    const isOneLine = this.state.height <= DEFAULT_HEIGHT;
    const style: any = {
      height: this.state.height + 60,
      resize: isOneLine ? "none" : null
    };
    return (
      <div style={style}>
        {this.renderLabel()}
        <textarea
          className="resizing-textarea"
          style={{
            height: this.state.height,
            resize: isOneLine ? 'none' : undefined,
            width: 'calc(100% - 8px)'
          }}
          name="textarea"
          id="textarea"
          autoFocus={true}
          defaultValue={this.state.value}
          onChange={this.onChange}
          onKeyUp={this.onDebouncedKeyUp}
        />
      </div>
    );
  }

  renderGhostField() {
    return (
      <div
        style={{
          width: 'calc(100% - 8px)'
        }}
        className="resizing-textarea resizing-textarea--ghost"
        ref={(c) => this.ghost = c}
        aria-hidden="true"
      >
        {this.state.value}
      </div>
    );
  }

  render() {
    if (this.resizeAfterNextRender) {
      this.resizeAfterNextRender = false;
      setTimeout(() => {
        this.resize();
      }, 0);
    }
    return (
      <div className="resizing-textarea-container">
        {this.state.editMode ? this.renderExpandableFieldTextArea() : this.renderViewMode()}
        {this.renderGhostField()}
      </div>
    );
  }

}