import React, { PureComponent } from "react";
// import UIContext, { standardUIContext } from '../../../../shared/model/widget/UIContext';

interface Props {
  children?: React.ReactNode;
  className?: string
  opacity?: number
  toolbarClassName?: string
  valign?: string
  // uiContext?: UIContext
}

interface State {
  // bgColor: string
  // padding: string
  borderRadius: string
}

export default class Toolbar extends PureComponent<Props, State> {

  UNSAFE_componentWillMount() {
    // const uiContext: UIContext = this.props.uiContext ? this.props.uiContext : standardUIContext;
    // const bgColor = uiContext.isBackgroundLight() ? 'red' : '#000';
    this.setState({
      // bgColor: bgColor,
      // padding: '2px',
      borderRadius: '2px'
    });
  }

  render() {
    const style: any = {
      opacity: this.props.opacity ? this.props.opacity : 1,
      borderRadius: this.state.borderRadius
    }
    if (this.props.valign === 'top') {
      style.alignItems = 'flex-start';
    }
    const toolbarClassName = this.props.toolbarClassName ? this.props.toolbarClassName : 'toolbar-left';
    return (
      <div
        className={`${toolbarClassName} ${this.props.className ? this.props.className : ''}`}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }

}
