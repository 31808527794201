
const util = {};

util.htmlToText = function(text) {
  text = text.replace(/<style([\s\S]*?)<\/style>/gi, '');
  text = text.replace(/<script([\s\S]*?)<\/script>/gi, '');
  text = text.replace(/<\/div>/ig, '\n');
  text = text.replace(/<\/li>/ig, '\n');
  text = text.replace(/<li>/ig, '  *  ');
  text = text.replace(/<\/ul>/ig, '\n');
  text = text.replace(/<\/p>/ig, '\n');
  // text = text.replace(/<br\s*[\/]?>/gi, "\n");
  text = text.replace(/<br\s*[/]?>/gi, "\n");
  text = text.replace(/<[^>]+>/ig, '');
  return text;
};

util.fileExtension = function(filename) {
  const parts = filename.split('.');
  if (parts.length >= 2) {
    return parts.pop();
  } else {
    return undefined;
  }
};

util.countLines = function(text) {
  const lineCount = text.split(/\r\n|\r|\n/).length;
  return lineCount;
}

module.exports = util;
