import storageDAO from './StorageDAO';

export default class PreferencesBrowserPersistence {

  setLastSelectedAssessmentUuid = (definitionUuid, user, assessmentUuid) => {
    const defaultDefinitionPreferences = {};
    return this.getDefinitionPreferences(definitionUuid, user, defaultDefinitionPreferences)
      .then((definitionPreferences) => {
        if (assessmentUuid) {
          definitionPreferences.lastSelectedAssessmentUuid = assessmentUuid;
        } else if (definitionPreferences.lastSelectedAssessmentUuid) {
          delete definitionPreferences.lastSelectedAssessmentUuid;
        }
        return this.setDefinitionPreferences(definitionUuid, user, definitionPreferences);
      });
  };

  setVisibleAssessmentSummaries = (definitionUuid, user, visibleAssessmentSummaries) => {
    const defaultDefinitionPreferences = {};
    return this.getDefinitionPreferences(definitionUuid, user, defaultDefinitionPreferences)
      .then((definitionPreferences) => {
        if (visibleAssessmentSummaries) {
          definitionPreferences.visibleAssessmentSummaries = visibleAssessmentSummaries;
        } else if (definitionPreferences.visibleAssessmentSummaries) {
          delete definitionPreferences.visibleAssessmentSummaries;
        }
        return this.setDefinitionPreferences(definitionUuid, user, definitionPreferences);
      });
  };

  removeDefinitionPreference = (definitionUuid, user) => {
    const key = 'definition-' + definitionUuid;
    return this.setPreference(user, key, undefined);
  };

  setDefinitionPreferences = (definitionUuid, user, definitionPreferences) => {
    const key = 'definition-' + definitionUuid;
    return this.setPreference(user, key, definitionPreferences);
  };

  getDefinitionPreferences = (definitionUuid, user, defaultDefinitionPreferences) => {
    const key = 'definition-' + definitionUuid;
    return this.getPreference(user, key, defaultDefinitionPreferences);
  };

  setReportPreferences = (user, reportPreferences) => {
    return this.setPreference(user, 'report', reportPreferences);
  };

  getReportPreferences = (user, defaultReportPreferences) => {
    return this.getPreference(user, 'report', defaultReportPreferences);
  };

  setEmailPreference = (user, emailPreferences) => {
    return this.setPreference(user, 'email', emailPreferences);
  };

  getEmailPreference = (user, defaultEmailPreference) => {
    return this.getPreference(user, 'email', defaultEmailPreference);
  };

  setPreference = (user, key, preference) => {
    const storageKey = this._buildPreferenceKey(user, key);
    return storageDAO.setData(storageKey, preference);
  };

  getPreference = (user, key, defaultPreference) => {
    const storageKey = this._buildPreferenceKey(user, key);
    return storageDAO.getData(storageKey, defaultPreference);
  };

  _buildPreferenceKey = (user, key) => {
    return 'preferences-' + user.getId() + '-' + key;
  };

};
