import mockConfluenceModule from './MockConfluenceModule';
import mockContextModule from './MockContextModule';
import mockDialogModule from './MockDialogModule';
import mockEventsModule from './MockEventsModule';
import mockRequestModule from './MockRequestModule';
import mockUserModule from './MockUserModule';

class MockJavascriptAPI {

  buildMockAP = () => {
    const mockAP = {
      confluence: mockConfluenceModule,
      context: mockContextModule,
      dialog: mockDialogModule,
      events: mockEventsModule,
      request: mockRequestModule,
      user: mockUserModule
    }
    return mockAP;
  }

}

export default new MockJavascriptAPI();
