// import actions from '../../../shared/actions/Actions';
import util from '../../../shared/util/Util';

class MomentGraph {

  constructor() {
    this.moments = [];
    this.moments.push({
      uuid: util.uuid(),
      timestamp: new Date().getTime(),
      text: 'Foo k jhkg kjhg kj hgkj h kjh jkh jh jhb jb jhb jhb '
    });
    this.moments.push({
      uuid: util.uuid(),
      timestamp: new Date().getTime(),
      text: 'Bar k jhkg kjhg kj hgkj h kjh jkh jh jhb jb jhb jhb k jhkg kjhg kj hgkj h kjh jkh jh jhb jb jhb jhb k jhkg kjhg kj hgkj h kjh jkh jh jhb jb jhb jhb k jhkg kjhg kj hgkj h kjh jkh jh jhb jb jhb jhb '
    });
    this.moments.push({
      uuid: util.uuid(),
      timestamp: new Date().getTime(),
      text: 'Har k jhkg kjhg kj hgkj h kjh jkh jh jhb jb jhb jhb '
    });
  }

  getMoments = () => {
    return this.moments;
  }

  // addMoment = () => {

  // }

}

export default new MomentGraph();
