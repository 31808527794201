import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import driveGraph from './DriveGraph';
import {
  FolderFilledIcon,
} from '../../components/icon/NamedIcons';

export default class FolderSelector extends PureComponent {

  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = this.buildStateFromProps(props);
    const driveGraphState = driveGraph.getState();
    this.onDriveGraphChange(driveGraphState);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    driveGraph.registerListener(this.onDriveGraphChange);
  }

  componentWillUnmount() {
    this.mounted = false;
    driveGraph.unregisterListener(this.onDriveGraphChange);
  }

  buildStateFromProps = (props) => {
    return {
      allowFolderSelection: props.allowFolderSelection,
      allowItemSelection: props.allowItemSelection
    };
  };

  onDriveGraphChange = (newState) => {
    if (this.mounted) {
      this.setState({
        rootFolder: newState.rootFolder
      });
    } else {
      const state = this.state;
      state.rootFolder = newState.rootFolder;
    }
  };

  onSelectFolderClick = (folder) => {
    if (this.state.allowFolderSelection && this.props.onFolderSelection) {
      this.props.onFolderSelection(folder);
    }
  };

  onSelectItemClick = (item) => {
    if (this.state.allowItemSelection && this.props.onItemSelection) {
      this.props.onItemSelection(item);
    }
  };

  renderFolderLink = (folder, indent) => {
    const marginLeft = indent * 20;
    return (
      <div
        key={folder.uuid}
        style={{
          display: 'block',
          marginLeft: marginLeft + 'px'
        }}
      >
        <Button
          appearance="subtle"
          autoFocus={false}
          iconBefore={<FolderFilledIcon/>}
          onClick={() => this.onSelectFolderClick(folder)}
        >
          {folder.name}
        </Button>
      </div>
    );
  };

  renderFolderLinks = (folder, indent, renderedFolderLinks) => {
    if (folder) {
      const renderedFolderLink = this.renderFolderLink(folder, indent);
      renderedFolderLinks.push(renderedFolderLink);
      if (folder.childFolders) {
        for (let childFolder of folder.childFolders) {
          this.renderFolderLinks(childFolder, indent + 1, renderedFolderLinks);
        }
      }
    }
  };

  render() {
    if (this.state.allowItemSelection) {
      return (
        <div>
          <p>Item selection is not yet supported - only folder selection.</p>
        </div>
      );
    }


    const renderedFolderLinks = [];
    const indent = 0;
    this.renderFolderLinks(this.state.rootFolder, indent, renderedFolderLinks);
    let selectorTypeName = '';
    if (this.state.allowFolderSelection) {
      if (this.state.allowItemSelection) {
        selectorTypeName = 'folder or item';
      } else {
        selectorTypeName = 'folder';
      }
    } else if (this.state.allowItemSelection) {
      selectorTypeName = 'item';
    }
    return (
      <div>
        <div style={{margin: '10px 0px'}}>
          Click on a {selectorTypeName} to select it:
        </div>
        {renderedFolderLinks}
      </div>
    );
  }

}
