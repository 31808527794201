import React from 'react';

const separator = ' ';

class RoobrickGlyphBuilder {

  // _translationX = 0;
  // _translationY = 0;
  // _scaleX = 1;
  // _scaleY = 1;
  viewBoxLeftX = 0;
  viewBoxTopY = 0;
  vewBoxWidth = 1000;
  vewBoxHeight = 1000;
  iconTopLeftPathData = 'M 115.91156720166295 0 H 484.5451243731116 V 368.6335571714487 A 115.91156720166295 115.91156720166295 0 0 1 368.6335571714486 484.54512437311166 H 0 V 115.91156720166289 A 115.91156720166295 115.91156720166295 0 0 1 115.91156720166295 0 Z';
  iconTopRightPathData = 'M 515.4548756268883 0 H 884.0884327983371 A 115.91156720166295 115.91156720166295 0 0 1 1000 115.91156720166289 V 484.54512437311166 H 631.3664428285513 A 115.91156720166295 115.91156720166295 0 0 1 515.4548756268883 368.6335571714487 V 0 Z';
  iconBottomLeftPathData = 'M 0 515.4548756268883 H 368.6335571714486 A 115.91156720166295 115.91156720166295 0 0 1 484.5451243731116 631.3664428285513 V 1000 H 115.91156720166295 A 115.91156720166295 115.91156720166295 0 0 1 0 884.0884327983371 V 515.4548756268883 Z';
  iconBottomRightPathData = 'M 631.3664428285513 515.4548756268883 H 1000 V 884.0884327983371 A 115.91156720166295 115.91156720166295 0 0 1 884.0884327983371 1000 H 515.4548756268883 V 631.3664428285513 A 115.91156720166295 115.91156720166295 0 0 1 631.3664428285513 515.4548756268883 Z';
  centreTickPathData = 'M 353.0241327882914 476.65154664667836 C 320.2443415836611 442.7126397700315 263.00720969948 456.9852174114629 250.00965929726678 502.34914109528705 C 243.76588954400393 524.1327882913862 250.05216020524082 547.5816983362827 266.35319027270134 563.3263528811751 L 388.94126374518 685.9221537914674 C 412.88086609123013 709.8540286997041 451.67646763362677 709.8540286997041 475.6160699796769 685.9221537914674 L 720.796080643541 440.7344156897898 C 754.746578676908 407.9507607662527 740.4624098787565 350.7252200387917 695.1100773516524 337.7199421987652 C 673.3264301555533 331.4761724455022 649.8697926728435 337.75857938783236 634.1212744090443 354.06733689310636 L 432.2805987218818 555.9080125802689 L 353.0241327882914 476.65154664667836 Z';
  centreCircleRadius = 316.82495035121207;
  topRightPlusPathData = `M 951.719 219.903 L 951.719 233.067 C 951.719 260.682 929.333 283.067 901.719 283.067 L 817.113 283.067 L 817.113 366.608 C 817.113 394.222 794.727 416.608 767.113 416.608 L 753.949 416.608 C 726.335 416.608 703.949 394.222 703.949 366.608 L 703.949 283.067 L 619.47 283.067 C 591.855 283.067 569.47 260.682 569.47 233.067 L 569.47 219.903 C 569.47 192.289 591.855 169.903 619.47 169.903 L 703.949 169.903 L 703.949 84.359 C 703.949 56.745 726.335 34.359 753.949 34.359 L 767.113 34.359 C 794.727 34.359 817.113 56.745 817.113 84.359 L 817.113 169.903 L 901.719 169.903 C 929.333 169.903 951.719 192.289 951.719 219.903 Z`;

  viewBoxMiddleX: number;
  viewBoxMiddleY: number;
  viewBox: string;

  iconTopLeftOpacity = 0.85;
  iconTopRightOpacity = 1;
  iconBottomLeftOpacity = 0.7;
  iconBottomRightOpacity = 0.55;

  mainBlue = `rgb(7, 109, 212)`;
  iconTopLeftColor = `rgb(13, 123, 223)`;
  iconTopRightColor = `rgb(7, 109, 212)`;
  iconBottomLeftColor = `rgb(40, 148, 244)`;
  iconBottomRightColor = `rgb(28, 137, 235)`;

  rubricTopLeftPath: React.SVGProps<SVGPathElement>;
  rubricTopRightPath: React.SVGProps<SVGPathElement>;
  rubricBottomLeftPath: React.SVGProps<SVGPathElement>;
  rubricBottomRightPath: React.SVGProps<SVGPathElement>;
  rubricIconPaths: React.SVGProps<SVGPathElement>[];
  topRightPlusPath: React.SVGProps<SVGPathElement>;

  centreTickPath: React.SVGProps<SVGPathElement>;
  assessmentCirclePath: React.SVGProps<SVGPathElement>;

  constructor() {
    // console.log(`=============`);
    // this.translateAndLogX(this.viewBoxLeftX, 'viewBoxLeftX');
    // this.translateAndLogY(this.viewBoxTopY, 'viewBoxTopY');
    // this.scaleAndLogX(this.vewBoxWidth, 'vewBoxWidth');
    // this.scaleAndLogX(this.vewBoxHeight, 'vewBoxHeight');
    // this.translateAndLogPathData(this.iconTopLeftPathData, 'iconTopLeftPathData');
    // this.translateAndLogPathData(this.iconTopRightPathData, 'iconTopRightPathData');
    // this.translateAndLogPathData(this.iconBottomLeftPathData, 'iconBottomLeftPathData');
    // this.translateAndLogPathData(this.iconBottomRightPathData, 'iconBottomRightPathData');
    // this.translateAndLogPathData(this.centreTickPathData, 'centreTickPathData');
    // this.scaleAndLogX(this.centreCircleRadius, 'centreCircleRadius');
    // console.log(`=============`);

    this.viewBoxMiddleX = this.viewBoxLeftX + this.vewBoxWidth / 2;
    this.viewBoxMiddleY = this.viewBoxTopY + this.vewBoxHeight / 2;
    this.viewBox = `${this.viewBoxLeftX} ${this.viewBoxTopY} ${this.vewBoxWidth} ${this.vewBoxHeight}`;

    this.rubricTopLeftPath = <path style={{ fillOpacity: this.iconTopLeftOpacity, fill: this.iconTopLeftColor }} d={this.iconTopLeftPathData} />;
    this.rubricTopRightPath = <path style={{ fillOpacity: this.iconTopRightOpacity, fill: this.iconTopRightColor }} d={this.iconTopRightPathData} />;
    this.rubricBottomLeftPath = <path style={{ fillOpacity: this.iconBottomLeftOpacity, fill: this.iconBottomLeftColor }} d={this.iconBottomLeftPathData} />;
    this.rubricBottomRightPath = <path style={{ fillOpacity: this.iconBottomRightOpacity, fill: this.iconBottomRightColor }} d={this.iconBottomRightPathData} />;
    this.rubricIconPaths = [this.rubricTopLeftPath, this.rubricTopRightPath, this.rubricBottomLeftPath, this.rubricBottomRightPath];
    this.centreTickPath = <path style={{ fill: this.mainBlue }} strokeWidth="0" d={this.centreTickPathData} />;
    this.assessmentCirclePath = <circle fill="#ffffff" strokeWidth="0" cx={this.viewBoxMiddleX} cy={this.viewBoxMiddleY} r={this.centreCircleRadius} />
    this.topRightPlusPath = <path style={{ fillOpacity: 1, fill: '#fff' }} d={this.topRightPlusPathData} />;
  }

  buildDefinitionGlyph = (color?: string): React.SVGProps<SVGPathElement> => {
    return (
      <svg viewBox={this.viewBox} xmlns="http://www.w3.org/2000/svg">
        {this.buildRubricPaths(color)}
      </svg>
    );
  }

  buildAddDefinitionGlyph = (color?: string): React.SVGProps<SVGPathElement> => {
    return (
      <svg viewBox={this.viewBox} xmlns="http://www.w3.org/2000/svg">
        {this.buildAddRubricPaths(color)}
      </svg>
    );
  }

  buildAssessmentGlyph = (color?: string): React.SVGProps<SVGPathElement> => {
    return (
      <svg viewBox={this.viewBox} xmlns="http://www.w3.org/2000/svg">
        {this.buildAssessmentPaths(color)}
      </svg>
    );
  }

  buildAddAssessmentGlyph = (color?: string): React.SVGProps<SVGPathElement> => {
    return (
      <svg viewBox={this.viewBox} xmlns="http://www.w3.org/2000/svg">
        {this.buildAssessmentPaths(color, 0.6)}
        {this.buildTopRightPlusPath()}
      </svg>
    );
  }

  buildRubricPaths = (color?: string): any => {
    return (
      <g>
        <path style={{ fillOpacity: this.iconTopLeftOpacity, fill: color ? color : this.iconTopLeftColor }} d={this.iconTopLeftPathData} />;
        <path style={{ fillOpacity: this.iconTopRightOpacity, fill: color ? color : this.iconTopRightColor }} d={this.iconTopRightPathData} />;
        <path style={{ fillOpacity: this.iconBottomLeftOpacity, fill: color ? color : this.iconBottomLeftColor }} d={this.iconBottomLeftPathData} />;
        <path style={{ fillOpacity: this.iconBottomRightOpacity, fill: color ? color : this.iconBottomRightColor }} d={this.iconBottomRightPathData} />
      </g>
    );
  }

  buildAddRubricPaths = (color?: string): any => {
    return (
      <g>
        {this.buildRubricPaths(color)}
        {this.buildTopRightPlusPath()}
      </g>
    );
  }

  buildAssessmentPaths = (color?: string, circleScale?: number): any => {
    return (
      <g>
        {this.buildRubricPaths(color)}
        {this.buildCircleAndTick(color, circleScale)}
      </g>
    );
  }

  buildCircleAndTick = (color?: string, optionalScale?: number): React.SVGProps<SVGPathElement> => {
    const scale: number = optionalScale ? optionalScale : 1;
    const radius = scale * this.centreCircleRadius;
    const tickPathData: string = scale === 1 ? this.centreTickPathData : this.scalePathData(this.centreTickPathData, scale, this.viewBoxMiddleX, this.viewBoxMiddleY);
    return (
      <g>
        <circle fill="#fff" strokeWidth="0" cx={this.viewBoxMiddleX} cy={this.viewBoxMiddleY} r={radius} />
        <path style={{ fill: color ? color : this.mainBlue }} strokeWidth="0" d={tickPathData} />
      </g>
    );
  }

  buildTopRightPlusPath = (): React.SVGProps<SVGPathElement> => {
    return <path style={{ fillOpacity: 1, fill: '#fff' }} d={this.topRightPlusPathData} />;
  }

  scalePathData = (data: string, scale: number, offsetX: number, offsetY: number, optionalFieldName?: string): string => {
    const fieldName: string = optionalFieldName ? optionalFieldName : '[unknown field]';
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
    const translatedItems: string[] = [];
    const items = data.split(separator);
    let itemIndex = 0;
    while (itemIndex < items.length) {
      const instruction = items[itemIndex];
      translatedItems.push(instruction);
      if (instruction === 'M' || instruction === 'L') {
        const x = this.scale(parseFloat(items[++itemIndex]), scale, offsetX);
        const y = this.scale(parseFloat(items[++itemIndex]), scale, offsetY);
        translatedItems.push(x.toString());
        translatedItems.push(y.toString());
      } else if (instruction === 'L') {
        const x = this.scale(parseFloat(items[++itemIndex]), scale, offsetX);
        const y = this.scale(parseFloat(items[++itemIndex]), scale, offsetY);
        translatedItems.push(x.toString());
        translatedItems.push(y.toString());
      } else if (instruction === 'C') {
        const x1 = this.scale(parseFloat(items[++itemIndex]), scale, offsetX);
        const y1 = this.scale(parseFloat(items[++itemIndex]), scale, offsetY);
        const x2 = this.scale(parseFloat(items[++itemIndex]), scale, offsetX);
        const y2 = this.scale(parseFloat(items[++itemIndex]), scale, offsetY);
        const x = this.scale(parseFloat(items[++itemIndex]), scale, offsetX);
        const y = this.scale(parseFloat(items[++itemIndex]), scale, offsetY);
        translatedItems.push(x1.toString());
        translatedItems.push(y1.toString());
        translatedItems.push(x2.toString());
        translatedItems.push(y2.toString());
        translatedItems.push(x.toString());
        translatedItems.push(y.toString());
      } else if (instruction === 'A') {
        const rx = this.scale(parseFloat(items[++itemIndex]), scale, 0);
        const ry = this.scale(parseFloat(items[++itemIndex]), scale, 0);
        const xAxisRotation = parseFloat(items[++itemIndex]);
        const largeArcFlag = items[++itemIndex];
        const sweepFlag = items[++itemIndex];
        const x = this.scale(parseFloat(items[++itemIndex]), scale, offsetX);
        const y = this.scale(parseFloat(items[++itemIndex]), scale, offsetY);
        translatedItems.push(rx.toString());
        translatedItems.push(ry.toString());
        translatedItems.push(xAxisRotation.toString());
        translatedItems.push(largeArcFlag);
        translatedItems.push(sweepFlag);
        translatedItems.push(x.toString());
        translatedItems.push(y.toString());
      } else if (instruction === 'H') {
        const x = this.scale(parseFloat(items[++itemIndex]), scale, offsetX);
        translatedItems.push(x.toString());
      } else if (instruction === 'V') {
        const y = this.scale(parseFloat(items[++itemIndex]), scale, offsetY);
        translatedItems.push(y.toString());
      } else if (instruction === 'Z') {
        // End
      } else {
        throw new Error(`Unrecognised instruction ${instruction} at item ${itemIndex} in field ${fieldName}`);
      }
      itemIndex++;
    }
    return translatedItems.join(separator);
  }

  scale = (value: number, scale: number, offset?: number): number => {
    let scaledValue = value;
    if (offset) {
      scaledValue = value - offset;
    }
    scaledValue = scale * scaledValue;
    if (offset) {
      scaledValue = scaledValue + offset;
    }
    return scaledValue;
  }

  // scaleAndLogX = (value: number, fieldName: string): void => {
  //   const translatedValue = this.scaleX(value);
  //   // console.log(`${fieldName} = ${translatedValue};`);
  //   this[fieldName] = translatedValue;
  // }

  // scaleAndLogY = (value: number, fieldName: string): void => {
  //   const translatedValue = this.scaleY(value);
  //   // console.log(`${fieldName} = ${translatedValue};`);
  //   this[fieldName] = translatedValue;
  // }

  // translateAndLogX = (value: number, fieldName: string): void => {
  //   const translatedValue = this.translateX(value);
  //   // console.log(`${fieldName} = ${translatedValue};`);
  //   this[fieldName] = translatedValue;
  // }

  // translateAndLogY = (value: number, fieldName: string): void => {
  //   const translatedValue = this.translateY(value);
  //   // console.log(`${fieldName} = ${translatedValue};`);
  //   this[fieldName] = translatedValue;
  // }

  // translateAndLogPathData = (data: string, fieldName: string): void => {
  //   const translatedItems: string[] = this.translatePathData(data, fieldName);
  //   const translatedData = translatedItems.join(separator).trim();
  //   // console.log(`${fieldName} = '${translatedData}';`);
  //   this[fieldName] = translatedData;
  // }

  // translatePathData = (data: string, fieldName: string): string[] => {
  //   // https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
  //   const translatedItems: string[] = [];
  //   const items = data.split(separator);
  //   let itemIndex = 0;
  //   while (itemIndex < items.length) {
  //     const instruction = items[itemIndex];
  //     translatedItems.push(instruction);
  //     if (instruction === 'M' || instruction === 'L') {
  //       const x = this.translateX(parseFloat(items[++itemIndex]));
  //       const y = this.translateY(parseFloat(items[++itemIndex]));
  //       translatedItems.push(x.toString());
  //       translatedItems.push(y.toString());
  //     // } else if (instruction === 'L') {
  //     //   const x = this.translateX(parseFloat(items[++itemIndex]));
  //     //   const y = this.translateY(parseFloat(items[++itemIndex]));
  //     //   translatedItems.push(x.toString());
  //     //   translatedItems.push(y.toString());
  //     } else if (instruction === 'C') {
  //       const x1 = this.translateX(parseFloat(items[++itemIndex]));
  //       const y1 = this.translateY(parseFloat(items[++itemIndex]));
  //       const x2 = this.translateX(parseFloat(items[++itemIndex]));
  //       const y2 = this.translateY(parseFloat(items[++itemIndex]));
  //       const x = this.translateX(parseFloat(items[++itemIndex]));
  //       const y = this.translateY(parseFloat(items[++itemIndex]));
  //       translatedItems.push(x1.toString());
  //       translatedItems.push(y1.toString());
  //       translatedItems.push(x2.toString());
  //       translatedItems.push(y2.toString());
  //       translatedItems.push(x.toString());
  //       translatedItems.push(y.toString());
  //     } else if (instruction === 'A') {
  //       const rx = this.scaleX(parseFloat(items[++itemIndex]));
  //       const ry = this.scaleY(parseFloat(items[++itemIndex]));
  //       const xAxisRotation = this.rotateX(parseFloat(items[++itemIndex]));
  //       const largeArcFlag = items[++itemIndex];
  //       const sweepFlag = items[++itemIndex];
  //       const x = this.translateX(parseFloat(items[++itemIndex]));
  //       const y = this.translateY(parseFloat(items[++itemIndex]));
  //       translatedItems.push(rx.toString());
  //       translatedItems.push(ry.toString());
  //       translatedItems.push(xAxisRotation.toString());
  //       translatedItems.push(largeArcFlag);
  //       translatedItems.push(sweepFlag);
  //       translatedItems.push(x.toString());
  //       translatedItems.push(y.toString());
  //     } else if (instruction === 'H') {
  //       const x = this.translateX(parseFloat(items[++itemIndex]));
  //       translatedItems.push(x.toString());
  //     } else if (instruction === 'V') {
  //       const y = this.translateY(parseFloat(items[++itemIndex]));
  //       translatedItems.push(y.toString());
  //     } else if (instruction === 'Z') {
  //       // End
  //     } else {
  //       throw new Error(`Unrecognised instruction ${instruction} at item ${itemIndex} in field ${fieldName}`);
  //     }
  //     itemIndex++;
  //   }
  //   return translatedItems;
  // }

  // translateX = (x: number): number => {
  //   // return x + this._translationX;
  //   return this.scaleX(x + this._translationX);
  // }

  // translateY = (y: number): number => {
  //   return this.scaleY(y + this._translationY);
  // }

  // scaleX = (x: number): number => {
  //   return x * this._scaleX;
  // }

  // scaleY = (y: number): number => {
  //   return y * this._scaleY;
  // }

  // rotateX = (x: number): number => {
  //   return x;
  // }

  // rotateY = (y: number): number => {
  //   return y;
  // }

}

export default new RoobrickGlyphBuilder();

