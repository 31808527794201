import React, { PureComponent } from "react";
import Button, { ButtonGroup } from '@atlaskit/button';

export default class SafeInlineButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      inNormalState: true,
      cancelLabel: props.cancelLabel !== undefined ? props.cancelLabel : 'Cancel',
      normalStateLabel: props.normalStateLabel,
      dangerStateLabel: props.dangerStateLabel,
      iconBefore: props.iconBefore,
      isDisabled: props.isDisabled === undefined ? false : props.isDisabled,
      normalStateAppearance: props.normalStateAppearance ? props.normalStateAppearance : 'default',
      warningStateAppearance: props.warningStateAppearance ? props.warningStateAppearance : 'danger'
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      inNormalState: true
    });
  };

  onDangerStateButtonClick = (event) => {
    this.props.onConfirmation(event);
  };

  onNormalStateButtonClick = () => {
    this.setState({
      inNormalState: false
    });
  };

  renderDangetStateConfirmButton = () => {
    return (
      <Button
        appearance={this.state.warningStateAppearance}
        iconBefore={this.state.iconBefore}
        onClick={this.onDangerStateButtonClick}
      >
        {this.state.dangerStateLabel ? this.state.dangerStateLabel : this.state.normalStateLabel}
      </Button>
    );
  };

  renderDangetStateCancelButton = () => {
    return (
      <Button
        appearance="subtle-link"
        onClick={this.onCancelButtonClick}
      >
        {this.state.cancelLabel}
      </Button>
    );
  };

  renderDangerState = () => {
    if (this.state.cancelLabel) {
      return (
        <ButtonGroup>
          {this.renderDangetStateConfirmButton()}
          {this.renderDangetStateCancelButton()}
        </ButtonGroup>
      );  
    } else {
      return this.renderDangetStateConfirmButton();
    }
  };

  renderNormalState = () => {
    return (
      <Button
        appearance={this.state.normalStateAppearance}
        iconBefore={this.state.iconBefore}
        isDisabled={this.state.isDisabled}
        onClick={this.onNormalStateButtonClick}
      >
        {this.state.normalStateLabel}
      </Button>
    );
  };

  render() {
    return this.state.inNormalState ? this.renderNormalState() : this.renderDangerState();
  }

}
