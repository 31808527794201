import React, { FocusEvent, KeyboardEvent, PureComponent } from 'react';
import actions from '../../../shared/actions/Actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import definitionSearch from '../../../shared/model/rubric/search/DefinitionSearch';
import session from '../../../shared/model/auth/Session';
import { SearchOptions } from '../../../shared/model/rubric/search/SearchOptions';
import Button from '@atlaskit/button';
import DefinitionSearchResult from '../../../shared/model/rubric/definition/DefinitionSearchResult';
import FormlessTextField from '../widget/FormlessTextField';
import IUser from '../../../shared/model/auth/IUser';
import Definition from '../../../shared/model/rubric/definition/Definition';
import currentRubric from '../../../shared/model/rubric/CurrentRubric';
import DefinitionReference from '../../../shared/model/rubric/definition/DefinitionReference';
import DashboardInfo, { DefinitionInfo } from '../../../shared/model/rubric/dashboard/DashboardInfo';
import dashboardState from '../rubric/dashboard/DashboardState';
import Label from '../widget/Label';
import rubricDAO from '../../../backend/data/RubricDAO';
import rubricViewerPreferences from '../rubric/RubricViewerPreferences';
import DefinitionPreferences from '../../../shared/model/rubric/preference/DefinitionPreferences';
import { StarFilledIcon, StarIcon } from '../icon/NamedIcons';
import adg from '../../../commonbase/adg';
import driveGraph from '../drive/DriveGraph';
import DriveFileReference from '../../../shared/model/drive/DriveFileReference';
import DriveFolder from '../../../shared/model/drive/DriveFolder';

interface Props {
  user: IUser
  visible: boolean
  onDefinitionClicked: (definition: Definition) => void
}

interface State {
  searchString: string
  visible: boolean
  showSearchResults: boolean
  definitionUuidsToPreferences: Map<string, undefined | DefinitionPreferences>
  results: DefinitionSearchResult[]
  definitionReferences: DefinitionReference[]
}

export default class TopNavigationRubricsDropdownPanel extends PureComponent<Props, State> {

  mounted: boolean = false;
  searchDefinitionsDebounced: Function;
  searchInputElement: any
  searchInputRef: any

  state: State = {
    searchString: '',
    visible: false,
    showSearchResults: false,
    definitionUuidsToPreferences: new Map<string, DefinitionPreferences>(),
    results: [],
    definitionReferences: []
  };

  constructor(props: Props) {
    super(props);
    this.searchDefinitionsDebounced = AwesomeDebouncePromise(
      definitionSearch.searchDefinitionsNew, 250, { key: (fieldId, text) => fieldId });
    this.searchInputRef = React.createRef();
    this.retrieveDashboardInfoAsync();
    session.registerListener(this._onSessionChange);
  }

  componentDidMount() {
    this.mounted = true;
    if (this.searchInputRef && this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
    dashboardState.registerListener(this.onDashboardInfoChange);
    driveGraph.registerListener(this.onDriveGraphChange);
    actions.registerListener(this.onAction);
  }

  componentWillUnmount() {
    this.mounted = false;
    dashboardState.unregisterListener(this.onDashboardInfoChange);
    driveGraph.unregisterListener(this.onDriveGraphChange);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState({
      visible: props.visible
    });
  }

  onAction = (actionId: string, context?: any) => {
    if (actionId === actions.removeDefinitionReferencesActionId) {
      const defintionUuid: string = context as string;
      this.removeDefinitionByUuid(defintionUuid);
    } else if (actionId === actions.definitionChangedActionId || actionId === actions.definitionRenamedActionId) {
      const definition: Definition = context as Definition;
      this.renameDefinitionByUuid(definition);
    }
  }

  removeDefinitionByUuid = (defintionUuid: string) => {
    if (this.mounted) {
      const newDefinitionReferences: DefinitionReference[] = [];
      for (const definitionReference of this.state.definitionReferences) {
        if (definitionReference.definitionUuid !== defintionUuid) {
          newDefinitionReferences.push(definitionReference);
        }
      }
      this.setState({
        definitionReferences: newDefinitionReferences
      });
    }
  }

  renameDefinitionByUuid = (definition: Definition) => {
    if (this.mounted) {
      const newDefinitionReferences: DefinitionReference[] = [];
      for (const definitionReference of this.state.definitionReferences) {
        if (definitionReference.definitionUuid !== definition.uuid) {
          const newDefinitionReference: DefinitionReference = {
            definitionUuid: definition.uuid,
            definitionName: definition.name
          }
          newDefinitionReferences.push(newDefinitionReference);
        } else {
          newDefinitionReferences.push(definitionReference);
        }
      }
      this.setState({
        definitionReferences: newDefinitionReferences
      });
    }
  }

  onDashboardInfoChange = (dashboardInfo: DashboardInfo) => {
    if (this.mounted) {
      this.retrieveDashboardInfoAsync();
    }
  }

  onDriveGraphChange = () => {
    if (this.mounted) {
      this.retrieveDriveInfoAsync();
    }
  }

  _onSessionChange = (user: IUser): void => {
    if (this.mounted) {
      this.retrieveDashboardInfoAsync();
    }
  }

  retrieveDashboardInfoAsync = () => {
    setTimeout(async () => {
      await this.retrieveDashboardInfo();
    }, 0);
  }

  retrieveDriveInfoAsync = () => {
    setTimeout(async () => {
      await this.retrieveDriveInfo();
    }, 0);
  }

  cloneDefinitionUuidsToPreferences = (): Map<string, undefined | DefinitionPreferences> => {
    const definitionUuidsToPreferences: Map<string, undefined | DefinitionPreferences> = new Map<string, DefinitionPreferences>();
    if (this.state.definitionUuidsToPreferences) {
      this.state.definitionUuidsToPreferences.forEach((value: undefined | DefinitionPreferences, key: string) => {
        definitionUuidsToPreferences.set(key, value);
      });
    }
    return definitionUuidsToPreferences;
  }

  cloneDefinitionReferences = (): DefinitionReference[] => {
    const clone: DefinitionReference[] = [];
    for (const definitionReference of this.state.definitionReferences) {
      clone.push(definitionReference);
    }
    return clone;
  }

  retrieveDriveInfo = async (): Promise<void> => {
    if (this.mounted) {
      const definitionReferences: DefinitionReference[] = [];
      driveGraph.iterateFileReferences((fileReference: DriveFileReference, folder: DriveFolder) => {
        const definitionReference: DefinitionReference = {
          definitionUuid: fileReference.definitionUuid,
          definitionName: fileReference.definitionName
        }
        this.addDefinitionReference(definitionReference, definitionReferences);
        return false;
      });
      this.addDefinitions(definitionReferences);
    }
  }

  retrieveDashboardInfo = async (): Promise<void> => {
    if (this.mounted) {
      const definitionReferences: DefinitionReference[] = [];
      const dashboardInfo: DashboardInfo = dashboardState.getState();
      const definitionInfoLog: DefinitionInfo[] = dashboardInfo.definitionInfoLog;
      for (const definitionInfo of definitionInfoLog) {
        const definitionReference: DefinitionReference = {
          definitionUuid: definitionInfo.definitionUuid,
          definitionName: definitionInfo.definitionName
        }
        this.addDefinitionReference(definitionReference, definitionReferences);
      }
      this.addDefinitions(definitionReferences);
    }
  }

  addDefinitions = (definitionReferences: DefinitionReference[]) => {
    const newDefinitionReferences = this.cloneDefinitionReferences();
    const definitionPreferencePromises: Promise<DefinitionPreferences>[] = [];
    for (const definitionReference of definitionReferences) {
      const definitionPreferencePromise = rubricViewerPreferences.getDefinitionPreferences(definitionReference.definitionUuid);
      definitionPreferencePromises.push(definitionPreferencePromise);
    }
    Promise.all(definitionPreferencePromises)
      .then((definitionPreferences: DefinitionPreferences[]) => {
        const definitionUuidsToPreferences = this.cloneDefinitionUuidsToPreferences();
        for (let i = 0; i < definitionPreferences.length; i++) {
          const definitionReference = definitionReferences[i];
          const definitionPreference = definitionPreferences[i];
          definitionUuidsToPreferences.set(definitionReference.definitionUuid, definitionPreference);
          this.addDefinitionReference(definitionReference, newDefinitionReferences);
        }
        this.setState({
          definitionUuidsToPreferences: definitionUuidsToPreferences,
          definitionReferences: newDefinitionReferences
        });
      });
  }

  addDefinitionReference = (definitionReference: DefinitionReference, definitionReferences: DefinitionReference[]): void => {
    if (!this.hasDefinitionReference(definitionReference, definitionReferences)) {
      definitionReferences.push(definitionReference);
    }
  }

  hasDefinitionReference = (definitionReference: DefinitionReference, definitionReferences: DefinitionReference[]): boolean => {
    for (const reference of definitionReferences) {
      if (reference.definitionUuid === definitionReference.definitionUuid) {
        return true;
      }
    }
    return false;
  }

  setSearchResults = (results: DefinitionSearchResult[]) => {
    const definitionPreferencePromises: Promise<DefinitionPreferences>[] = [];
    for (const result of results) {
      const definitionReference: DefinitionReference = result.getDefinitionReference();
      const definitionPreferencePromise = rubricViewerPreferences.getDefinitionPreferences(definitionReference.definitionUuid);
      definitionPreferencePromises.push(definitionPreferencePromise);
    }
    Promise.all(definitionPreferencePromises)
      .then((definitionPreferences: DefinitionPreferences[]) => {
        const definitionUuidsToPreferences = this.cloneDefinitionUuidsToPreferences();
        for (let i = 0; i < definitionPreferences.length; i++) {
          const result = results[i];
          const definitionReference: DefinitionReference = result.getDefinitionReference();
          const definitionPreference = definitionPreferences[i];
          definitionUuidsToPreferences.set(definitionReference.definitionUuid, definitionPreference);
        }
        this.setState({
          definitionUuidsToPreferences: definitionUuidsToPreferences,
          results: results
        });
      });
  }

  setFilterText = async (rawSearchString: string) => {
    const searchString = rawSearchString ? rawSearchString.trim() : '';
    this.setState({
      searchString: searchString
    });
    if (searchString) {
      const options: SearchOptions = {
        searchString: searchString,
        maxResults: 20
      };
      const user = session.getCurrentUser();
      if (user) {
        const userId = user.getId();
        const matchingDefinitionSearchResults = await this.searchDefinitionsDebounced(userId, options);
        this.setSearchResults(matchingDefinitionSearchResults);
      }
    } else {
      this.setState({
        results: []
      });
    }
  };

  onSearchResultClicked = async (result: DefinitionSearchResult) => {
    const definition: undefined | Definition = await result.resolveDefinition();
    if (definition) {
      this.props.onDefinitionClicked(definition);
    }
  };

  onSearchTextKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event) {
      this.searchInputElement = event.target;
      if (event.keyCode === 27) {
        this.searchInputElement.blur();
      } else {
        const searchString = event.currentTarget.value;
        this.setState({
          showSearchResults: true,
          searchString: searchString
        });
        this.setFilterText(searchString);
      }
    }
  };

  onSearchInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({
      showSearchResults: true
    });
  };

  onSearchInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({
      showSearchResults: false
    });
  };

  onSearhResultClicked = async (result: DefinitionSearchResult) => {
    this.setFilterText('');
    const definition: undefined | Definition = await result.resolveDefinition();
    if (definition) {
      this.props.onDefinitionClicked(definition);
    }
  };

  onDefinitionReferenceClicked = async (definitionReference: DefinitionReference) => {
    this.setFilterText('');
    const definition: undefined | Definition = await rubricDAO.getDefinitionByUuid(definitionReference.definitionUuid);
    if (definition) {
      this.props.onDefinitionClicked(definition);
    }
  };

  renderSearch = () => {
    return (
      <div
        className="nav-item-right nav-search-input-container"
      >
        <FormlessTextField
          name="search"
          label={undefined}
          placeholder="Search rubrics by name..."
          value={this.state.searchString}
          autoFocus={true}
          onKeyUp={this.onSearchTextKeyUp}
          onFocus={this.onSearchInputFocus}
          onBlur={this.onSearchInputBlur}
        />
      </div>
    );
  }

  renderStar = (isStarred: boolean) => {
    return isStarred ? <StarFilledIcon primaryColor={adg.adgYellow} label="star" /> : <StarIcon primaryColor={adg.adgYellow} label="star" />;
  }

  renderSearchResults = () => {
    if (this.state.searchString) {
      const { results } = this.state;
      if (results && results.length) {
        return results.map((result) => {
          const definitionReference = result.getDefinitionReference();
          const definitionPreferences: undefined | DefinitionPreferences = this.state.definitionUuidsToPreferences.get(definitionReference.definitionUuid);
          const isStarred = definitionPreferences && definitionPreferences.starred ? true : false;
          return (
            <div className="top-nav-search-result-container">
              <Button
                key={definitionReference.definitionUuid}
                iconBefore={this.renderStar(isStarred)}
                appearance="link"
                onClick={async () => await this.onSearhResultClicked(result)}
              >
                {definitionReference.definitionName}
              </Button>
            </div>
          );
        });
      } else {
        return (
          <p>
            No matching rubrics
          </p>
        );
      }
    } else {
      return null;
    }
  };

  renderDefinitiondReferences = () => {
    if (this.state.definitionReferences.length) {
      const currentDefinitionUuid = currentRubric.getDefinitionUuid();
      const renderedDefinitionInfos = this.state.definitionReferences.map((definitionReference: DefinitionReference) => {
        if (definitionReference.definitionUuid === currentDefinitionUuid) {
          return null;
        } else {
          const definitionPreferences: undefined | DefinitionPreferences = this.state.definitionUuidsToPreferences.get(definitionReference.definitionUuid);
          const isStarred = definitionPreferences && definitionPreferences.starred ? true : false;
          return (
            <div
              key={`${definitionReference.definitionUuid}`}
              className="top-nav-search-result-container">
              <Button
                iconBefore={this.renderStar(isStarred)}
                appearance="link"
                onClick={async () => await this.onDefinitionReferenceClicked(definitionReference)}
              >
                {definitionReference.definitionName}
              </Button>
            </div>
          );
        }
      });
      return (
        <div>
          <Label text="Rubrics" />
          {renderedDefinitionInfos}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.state.visible) {
      return (
        <div className="top-nav-search-results-container overlay-container">
          {this.renderSearch()}
          {this.renderSearchResults()}
          {this.renderDefinitiondReferences()}
        </div>
      );
    } else {
      return null;
    }
  }
}
