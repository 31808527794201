const backgroundImages = require('./backgroundImages');

const backgroundRetriever = function(backgroundImageId) {
  for (const backgroundImage of backgroundImages) {
    if (backgroundImage.id === backgroundImageId) {
      return backgroundImage;
    }
  }
  return undefined;
}

module.exports = backgroundRetriever;
