import constants from '../../../../shared/model/Constants';
import currentNotifications from '../../../../backend/data/CurrentNotifications';
import session from '../../../../shared/model/auth/Session';
import NotificationBuilder from "../../../../shared/model/rubric/NotificationBuilder";
import permissionUtil from '../../../../shared/model/auth/PermissionUtil';
import shareStateDefinitions from "../../../../shared/model/rubric/ShareStateDefinitions";
import entityTypeDefinitions from "../../../../shared/model/rubric/EntityTypeDefinitions";
import PermissionCheckResult from '../../../../shared/model/auth/PermissionCheckResult';
import ShareFormBase from './ShareFormBase';
import notificationDAO from "../../../../backend/data/NotificationDAO";
import userUtil from '../../../../shared/model/auth/UserUtil';

export default class ShareAssessmentForm extends ShareFormBase {

  allowSharingToGoogleClassroom = () => {
    return false;
  };

  buildContactPickerContext = () => {
    // const emailsToRoles = rubricUtil.getAssessmentEmailsToRole(this.props.assessment);
    // return emailsToRoles;
    return undefined;
  };

  allowContactForPicker = (contact) => {
    // const role = emailsToRoles[contact.email];
    // return role !== undefined;
    return true;
  };

  buildGoogleClassroomTitle = (props) => {
    return `${props.assessment.name}`;
  };

  buildEmailSubject = (props) => {
    return `${constants.appTitle} assessment: ${props.assessment.name}`;
  };

  buildDefaultInAppSharingMessage = (props) => {
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      const firstName = userUtil.guessFirstName(currentUser);
      const message =
        `Hi,
I've created assessment "${props.assessment.name}" of "${props.definition.name}" and am sharing it with you. 
To view it, click the view button in the toolbar above. 
${firstName}.`;
      return message;
    } else {
      return '';
    }
  };

  buildDefaultEmailSharingMessage = (props) => {
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      const firstName = userUtil.guessFirstName(currentUser);
      const url = this.buildDefaultEmailSharingUrl(props);
      const message =
        `Hi,
I've created assessment "${props.assessment.name}" of "${props.definition.name}" and am sharing it with you.
To view this, click on the following link: ${url} 
${firstName}.
`;
      return message;
    } else {
      return '';
    }
  };

  checkSharingPermission = (): Promise<PermissionCheckResult> => {
    return new Promise((resolve, reject) => {
      if (this.props.assessment) {
        const canAdmin = permissionUtil.canReadAssessment(this.props.assessment);
        if (canAdmin) {
          const result = new PermissionCheckResult(true, '');
          resolve(result);
        } else {
          const result = new PermissionCheckResult(
            false, 'This assessment can not be shared because you do not have sufficient privileges.');
          resolve(result);
        }
      } else {
        return '';
      }
    });
  };

  onSumbitShareInAppForm = (message) => {
    const targetUserContact = this.state.targetUserContact;
    const currentUser = session.getCurrentUser();
    if (currentUser && targetUserContact && this.props.assessment) {
      const targetUserEmail = targetUserContact.email;
      const currentUserId = currentUser.getId();
      const definition = this.props.definition;
      const assessment = this.props.assessment;
      const entityUuid = assessment.uuid;
      const entityTitle = `${assessment.name} of ${definition.name}`;
      const sharedAssessment = new NotificationBuilder()
        .setFromUserId(currentUserId)
        .setTargetUserEmail(targetUserEmail)
        .setShareState(shareStateDefinitions.outstandingShareState)
        .setTitle(entityTitle)
        .setMessage(message)
        .setDefinitionUuid(definition.uuid)
        .setDefinitionName(definition.name)
        .setAssessmentUuid(assessment.uuid)
        .setAssessmentName(assessment.name)
        .setEntityUuid(entityUuid)
        .setEntityType(entityTypeDefinitions.assessmentEntityType)
        .build();
      const fromUserId = currentUserId;
      notificationDAO.doesNotificationExist(
        fromUserId, targetUserEmail, entityTypeDefinitions.assessmentEntityType, entityUuid
      ).then((notificationExists) => {
        if (notificationExists) {
          const alreadySharedUserEmails = this.state.alreadySharedUserEmails.concat(targetUserEmail);
          this.setState({
            alreadySharedUserEmails: alreadySharedUserEmails
          });
          this.popupDuplicateNotificationWarning(targetUserEmail);
        } else {
          notificationDAO.saveNotification(sharedAssessment).then(() => {
            currentNotifications.rebuildCurrentNotifications();
            this.onSharingComplete();
          });
        }
      });
    } else {
      // do nothing
    }
  };

  getShareButtonLabel = () => {
    return 'Share assessment';
  }

}