import React, { PureComponent } from "react";
import adg from '../../../commonbase/adg';
import globalDirtyStateController from '../../../shared/model/GlobalDirtyStateController';

export default class GlobalDirtyState extends PureComponent {

  mounted = false;
  state = {
    dirty: false
  };

  UNSAFE_componentWillMount() {
    globalDirtyStateController.registerListener(this.onDirtyStateChange);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    globalDirtyStateController.unregisterListener(this.onDirtyStateChange);
    this.mounted = false;
  }

  onDirtyStateChange = (dirty) => {
    if (this.mounted) {
      this.setState({
        dirty: dirty
      });
    } else {
      const state = this.state;
      state.dirty = dirty;
    }
  };

  renderDirtyState = () => {
    return (
      <div
        style={{
          zIndex: 1000,
          position: 'fixed',
          right: '10px',
          bottom: '10px',
          padding: '10px',
          borderRadius: '6px',
          backgroundColor: adg.adgRed,
          color: adg.white
        }}
      >
        <div>
          ccccc
        </div>
      </div>
    );
  };

  render() {
    if (this.state.dirty) {
      return this.renderDirtyState();
    } else {
      return null;
    }
  }

}

