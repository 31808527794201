import actions from '../../actions/Actions';
import constants from '../Constants';
import firebaseApp from '../../../backend/firebase/FirebaseApp';
import util from '../../util/Util';
import { Discoverability } from '../rubric/definition/Definition';
import collectionsUtil from '../../../commonbase/util/collectionsUtil';

const onDefinitionOrAssessmentChangeCallType = 'onDefinitionOrAssessmentChange'

interface ChangeData {
  timestamp: number
  notificationId: number
  definitionUuid: string,
  definitionName: string,
  definitionDiscoverability: Discoverability,
  assessmentUuid?: string,
  assessmentName?: string,
  assessmentDiscoverability?: Discoverability
  assessmentRoles?: any[]
  callType: string
}

export class SiteMapChangeNotifier {

  enabled = true;
  _currentChangeNotificationId = 0;
  _lastSubmissionTime = 0;
  _minMillisBetweenSubmissions = constants.millisPerDay;
  _uuidsToLastChangeInfos: Map<string, ChangeData> = new Map<string, ChangeData>();

  enable = () => {
    actions.registerListener(this._onAction);
  };

  disable = () => {
    actions.unregisterListener(this._onAction);
  };
  
  _onAction = (actionId, context) => {
    if (this.enabled) {
      if (actionId === actions.assessmentChangeActionId) {
        this._onAssessmentChanged(context.definition, context.assessment);
      } else if (actionId === actions.definitionChangedActionId) {
        this._onDefinitionChanged(context);        
      } else if (actionId === actions.definitionRenamedActionId) {
        this._onDefinitionChanged(context);        
      }
    }
  };

  _onDefinitionChanged = (definition) => {
    this._currentChangeNotificationId++;
    const notificationId = this._currentChangeNotificationId;
    const changeData: ChangeData = {
      timestamp: new Date().getTime(),
      notificationId: notificationId,
      definitionUuid: definition.uuid,
      definitionName: definition.name,
      definitionDiscoverability: definition.discoverability,
      callType: onDefinitionOrAssessmentChangeCallType
    }
    return this._onDefinitionOrAssessmentChange(changeData);
  };

  _onAssessmentChanged = (definition, assessment) => {
    this._currentChangeNotificationId++;
    const notificationId = this._currentChangeNotificationId;
    const changeData: ChangeData = {
      timestamp: new Date().getTime(),
      notificationId: notificationId,
      definitionUuid: definition.uuid,
      definitionName: definition.name,
      definitionDiscoverability: definition.discoverability,
      assessmentUuid: assessment.uuid,
      assessmentName: assessment.name,
      assessmentDiscoverability: assessment.discoverability,
      assessmentRoles: util.shallowCloneArray(assessment.roles),
      callType: onDefinitionOrAssessmentChangeCallType
    }
    return this._onDefinitionOrAssessmentChange(changeData);
  };

  _onDefinitionOrAssessmentChange = (data: ChangeData) => {
    const dataIsFresh = data.notificationId === this._currentChangeNotificationId;
    if (!dataIsFresh) {
      return;
    }
    let sendNotification = false;
    const uuid = data.assessmentUuid ? data.assessmentUuid : data.definitionUuid;
    const discoverability = data.assessmentDiscoverability ?
      data.assessmentDiscoverability : data.definitionDiscoverability;
    const now = new Date().getTime();
    const lastChangeInfo = this._uuidsToLastChangeInfos.get(uuid);
    if (lastChangeInfo) {
      let _minMillisBetweenSubmissions = this._minMillisBetweenSubmissions;
      if (lastChangeInfo.definitionName !== data.definitionName) {
        _minMillisBetweenSubmissions = 5000;
      } else if (lastChangeInfo.assessmentName && lastChangeInfo.assessmentName !== data.assessmentName) {
          _minMillisBetweenSubmissions = 5000;
      } else if (lastChangeInfo.definitionDiscoverability !== data.definitionDiscoverability) {
        _minMillisBetweenSubmissions = 0;
      } else if (lastChangeInfo.assessmentDiscoverability !== data.assessmentDiscoverability) {
        _minMillisBetweenSubmissions = 0;
      } else {
        if (data.assessmentRoles && lastChangeInfo.assessmentRoles) {
          const itemComparator = (roleA: string, roleB: string): number => {
            return roleA.localeCompare(roleB);
          }
          const rolesAreSame = collectionsUtil.equivalentArrays(data.assessmentRoles, lastChangeInfo.assessmentRoles, itemComparator);
          if (!rolesAreSame) {
            _minMillisBetweenSubmissions = 0;
          }
        } else if (data.assessmentRoles === undefined && lastChangeInfo.assessmentRoles === undefined) {
          _minMillisBetweenSubmissions = 0;
        } else if (data.assessmentRoles || lastChangeInfo.assessmentRoles) {
          _minMillisBetweenSubmissions = 0;
        }
      }
      const millisecondsSinceLastNotification = now - lastChangeInfo.timestamp;
      sendNotification = millisecondsSinceLastNotification >= _minMillisBetweenSubmissions;
      if (!sendNotification) {
        // This is to ensure the last change in a succession of quick changes will be evaluated
        // after a period where it won't be filtered due to the short time between changes.
        const delay = _minMillisBetweenSubmissions - millisecondsSinceLastNotification;
        setTimeout(() => {
          this._onDefinitionOrAssessmentChange(data);
        }, delay);
      }
    } else {
      sendNotification = true;
    }
    const entry = {
      timestamp: now,
      discoverability: discoverability
    };
    this._uuidsToLastChangeInfos.set(uuid, data);
    if (sendNotification) {
      this._scheduleEntryRemoval(entry);
      const functions = firebaseApp.getFunctions();
      const onDefinitionOrAssessmentChangeFunction = functions.httpsCallable('consolidatedCallHandler');
      return onDefinitionOrAssessmentChangeFunction(data)
        .then((result) => {
          return true;
        })
        .catch((error) => {
          // debugger;
          console.warn(error);
          return false;
        });  
    } else {
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    }
  };

  _scheduleEntryRemoval = (uuid) => {
    setTimeout(() => {
      const now = new Date().getTime();
      const entry = this._uuidsToLastChangeInfos.get(uuid);
      if (entry) {
        if (now - entry.timestamp > this._minMillisBetweenSubmissions) {
          this._uuidsToLastChangeInfos.delete(uuid);
        }
      }
    }, this._minMillisBetweenSubmissions + 60000);
  }

}

export default new SiteMapChangeNotifier();
