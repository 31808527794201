
import React, { FormEvent, KeyboardEvent, FocusEvent, PureComponent } from "react";
import Textfield from '@atlaskit/textfield';
import Label from './Label';

type Props = {
  name: string
  label?: string
  placeholder?: string
  minLength?: number
  maxLength?: number
  value?: string
  disabled?: boolean
  autoFocus?: boolean
  shouldFitContainer?: boolean
  isInvalid?: boolean
  invalidMessage?: string
  isSpellCheckEnabled?: boolean
  onChange?: (event: FormEvent<HTMLInputElement>) => void
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

type State = {
  name: string
  label?: string
  placeholder?: string
  minLength?: number
  maxLength?: number
  value?: string
  disabled?: boolean
  autoFocus?: boolean
}

export default class FormlessTextField extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      name: props.name,
      label: props.label,
      placeholder: props.placeholder,
      minLength: props.minLength,
      maxLength: props.maxLength,
      value: props.value,
      disabled: props.disabled,
      autoFocus: props.autoFocus
    };
  };

  onChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      value: event.currentTarget.value
    });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  renderLabel = (label: string) => {
    return (
      <Label text={label}/>
    );
  }

  render() {
    return (
      <>
        {this.state.label ? this.renderLabel(this.state.label) : null}
        <Textfield
          name={this.state.name}
          placeholder={this.state.placeholder}
          maxLength={this.state.maxLength}
          value={this.state.value}
          disabled={this.state.disabled}
          autoFocus={this.state.autoFocus}
          onChange={this.onChange}
          onKeyUp={this.props.onKeyUp}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
      </>
    );
  }

}
