import environment from '../Environment';
import BrowserAuth from './BrowserAuth';
import FirebaseAuth from './FirebaseAuth';
import ListenerGroup from '../../util/ListenerGroup';
import IUser from './IUser';
import { LoginState } from './LoginState';

class Session {

  auth: any;
  currentUser: undefined | IUser;
  listenerGroup = new ListenerGroup('Session');
  firstStateChangeTime: undefined | number = undefined;

  constructor() {
    if (environment.mockAuth()) {
      this.auth = new BrowserAuth(this.onAuthStateChanged);
    } else {
      this.auth = new FirebaseAuth(this.onAuthStateChanged);
    }
    setTimeout(() => {
      if (this.isAfterFirstStateChangeTime()) {
        // All good - normal case
      } else {
        // There was no initial callback from the auth mechanism. Restarting the browser has fixed this.
      }
    }, 5000);
  }

  getMockUsers = () => {
    return this.auth.getMockUsers();
  };

  onMockUserLogin = (user) => {
    return this.auth.onMockUserLogin(user);
  };

  onLoginWithGoogleButtonClick = () => {
    return this.auth.onLoginWithGoogleButtonClick();
  };

  signOut = () => {
    return this.auth.signOut();
  };

  _setCurrentUser = (currentUser) => {
    this.currentUser = currentUser;
    this._notifyListeners();
  };

  // setCurrentUser = (currentUser) => {
  //   this.currentUser = currentUser;
  //   return this.auth.setCurrentUser(currentUser).then(() => {
  //     this._notifyListeners();
  //   });
  // };

  getLoginState = (): LoginState => {
    return this.auth.getLoginState();
  }

  isLoggedIn = () => {
    // return this.currentUser;
    return this.auth.getLoginState() === LoginState.loggedIn;
  };

  isAfterFirstStateChangeTime = () => {
    return this.firstStateChangeTime !== undefined;
  };

  getCurrentUser = (): undefined | IUser => {
    return this.currentUser;
  };

  isCurrentUser = (user: undefined | IUser): boolean => {
    if (user === undefined) {
      return this.currentUser === undefined;
    } else if (this.currentUser === undefined) {
      return user.getId() === undefined;
    } else {
      return this.currentUser.getId() === user.getId();
    }
  }

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  onAuthStateChanged = (user) => {
    if (this.firstStateChangeTime === undefined) {
      this.firstStateChangeTime = new Date().getTime();
    }
    // console.log('Session.onAuthStateChanged:', user);
    this.currentUser = user;
    this._notifyListeners();
  };

  _notifyListeners = () => {
    const context = undefined;
    const synchronously = false;
    this.listenerGroup.notifyListeners(this.currentUser, context, synchronously);
  };

}

export default new Session();

