
class DemoUtil {

  findFirstImageSrc = (definition) => {
    const instructions = definition.instructions;
    for (let i = 0; i < instructions.length; i++) {
      const instruction = instructions[i];
      if (instruction.type === 'displayImage') {
        if (instruction.data && instruction.data.imageSrc) {
          return instruction.data.imageSrc;
        }
      }
    }
    return null;
  };

  getImageLinks = (definition) => {
    const links = [];
    const instructions = definition.instructions;
    for (let i = 0; i < instructions.length; i++) {
      const instruction = instructions[i];
      if (instruction.type === 'displayImage') {
        if (instruction.data && instruction.data.imageSrc) {
          links.push(instruction.data.imageSrc);
        }
      }
    }
    return links;
  };

}

export default new DemoUtil()
