import React, { PureComponent } from 'react';
import SelectOption from '../../../../shared/model/widget/SelectOption';
import SingleSelect from '../../widget/SingleSelect';

interface Props {
  label: string
  statusId: string
  statusIdsToConfigs: any
  onStatusChange: (statusId: string) => void
}

interface State {
  label: string
  statusId: string
  statusIdsToConfigs: any
}

export default class AssessmentStatusSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    const state = {
      statusId: props.statusId,
      label: props.label,
      statusIdsToConfigs: props.statusIdsToConfigs
    };
    return state;
  };

  onAssessmentStatusChange = (selection: SelectOption) => {
    const statusId = selection.value;
    this.props.onStatusChange(statusId);
  };

  renderAssessmentSelector = () => {
    if (!this.state.statusIdsToConfigs) {
      return null;
    }
    const options: SelectOption[] = [];
    let selectedOption: undefined | SelectOption = undefined;
    const ids = Object.keys(this.state.statusIdsToConfigs);
    for (const id of ids) {
      const config = this.state.statusIdsToConfigs[id];
      const status = config.text;
      const option: SelectOption = {
        label: status,
        value: id
      }
      options.push(option);
      if (id === this.state.statusId) {
        selectedOption = option;
      // } else if (!selectedOption && !this.state.statusId) {
      //   selectedOption = option;
      }
    }
    return (
      <SingleSelect
        label={this.state.label}
        options={options}
        defaultSelected={selectedOption}
        placeholder={undefined}
        noMatchesFound={undefined}
        hasAutocomplete
        appearance="default"
        onSelected={this.onAssessmentStatusChange}
      />
    );
  };

  render() {
    return this.renderAssessmentSelector();
  };

}
