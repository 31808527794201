import React, { PureComponent } from "react";
import adg from '../../../../commonbase/adg';
import Button from '@atlaskit/button';
import Expand from '../../widget/Expand';
import guidanceRenderUtil from './GuidanceRenderUtil';
import InlineEdit from '@atlaskit/inline-edit';
import Label from '../../widget/Label';
import SafeInlineButton from '../../widget/SafeInlineButton';
import ResizingTextArea from '../../widget/ResizingTextArea';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import scoreMetaDefinitions from '../../../../shared/model/rubric/score/ScoreMetaDefinitions';
// import scoreTypes from '../../../../shared/model/rubric/score/ScoreTypes';
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import { AddStatementIcon, ArrowDownIcon, ArrowUpIcon, TrashIcon } from '../../icon/NamedIcons';
import util from '../../../../shared/util/Util';
import measurementTypes from '../../../../shared/model/rubric/score/MeasurementTypes';
import GuidanceItem from '../../../../shared/model/rubric/definition/GuidanceItem';
import GuidanceMetadata from '../../../../shared/model/rubric/definition/GuidanceMetadata';
import GuidanceRequirement from '../../../../shared/model/rubric/definition/GuidanceRequirement';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import ToolbarLeft from '../../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

interface Props {
  initiallyExpanded?: boolean
  definition?: Definition
  guidance?: any
  guidanceMetadata?: any
  onGuidanceMetadataChange: (guidanceMetadata: GuidanceMetadata) => void
  onGuidanceChange: (guidance: GuidanceItem[]) => void
}

interface State {
  definition: Definition
  guidanceMetadata: GuidanceMetadata
  guidance: GuidanceItem[]
  expanded: boolean
}

export default class GuidanceEditor extends PureComponent<Props, State> {

  iconButtonColumnWidth = '36px';
  scoreGuideColumnWidth = '36px';

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    const guidance = this.state && this.state.guidance !== undefined ? this.state.guidance :
      props.guidance === undefined ? this.buildInitialGuidance(props.definition) : props.guidance;
    const guidanceMetadata = this.state && this.state.guidanceMetadata !== undefined ? this.state.guidanceMetadata :
      props.guidanceMetadata === undefined ? this.buildInitialGuidance(props.definition) : props.guidanceMetadata;
    const expanded = this.state && this.state.expanded !== undefined ? this.state.expanded :
      props.initiallyExpanded === undefined ? false : props.initiallyExpanded;
    return {
      definition: props.definition,
      guidance: guidance,
      guidanceMetadata: guidanceMetadata,
      expanded: expanded
    };
  };

  buildInitialGuidance = (definition) => {
    const guidance = [];
    return guidance;
  };

  buildGuidanceFromCustomLevelsScoreInfo = (definition) => {
    const guidance: GuidanceItem[] = [];
    const scoreMetaType = rubricUtil.getScoreMetaType(this.state.definition);
    if (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType) {
      if (definition.customLevelsScoreInfo && definition.customLevelsScoreInfo.levels) {
        for (const level of definition.customLevelsScoreInfo.levels) {
          const requirements: GuidanceRequirement[] = [];
          const guide: GuidanceItem = {
            uuid: util.uuid(),
            scoreGuide: level.label,
            requirements: requirements
          };
          guidance.push(guide);
        }
      }
    } else {
      const scoreMeta = scoreMetaDefinitions.scoreMetaFromType(scoreMetaType);
      const markers = scoreMeta.getMarkers();
      for (const marker of markers) {
        if (marker.label) {
          const guide = {
            uuid: util.uuid(),
            scoreGuide: marker.label,
            requirements: []
          };
          guidance.push(guide);
        } else {
          const guide = {
            uuid: util.uuid(),
            scoreGuide: marker.score + '',
            requirements: []
          };
          guidance.push(guide);
        }
      }
    }
    return guidance;
  };

  hasScoreGuide = (scoreGuide) => {
    const scoreMetaType = rubricUtil.getScoreMetaType(this.state.definition);
    if (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType) {
      if (this.state.definition.customLevelsScoreInfo) {
        if (this.state.definition.customLevelsScoreInfo.levels) {
          for (const level of this.state.definition.customLevelsScoreInfo.levels) {
            if (level.label === scoreGuide || level.tooltip === scoreGuide) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      const scoreMeta = scoreMetaDefinitions.scoreMetaFromType(scoreMetaType);
      const measurementType = scoreMeta.getMeasurementType();
      const markers = scoreMeta.getMarkers();
      for (const marker of markers) {
        if (scoreMeta.displayLabelsInsteadOfScores || measurementType === measurementTypes.discreteType) {
          if (marker.label === scoreGuide || marker.label === scoreGuide) {
            return true;
          }
        } else {
          if (marker.score + '' === scoreGuide || marker.label === scoreGuide) {
            return true;
          }
        }
      }
      return false;
    }
  };

  getScoreMeta = () => {
    const scoreMetaType = rubricUtil.getScoreMetaType(this.state.definition);
    if (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType) {
      return rubricUtil.buildCustomLevelsScoreMeta(this.state.definition);
    } else {
      return scoreMetaDefinitions.scoreMetaFromType(scoreMetaType);
    }
  };

  setGuidance = (guidance) => {
    this.setState({
      guidance: guidance
    });
    // this.forceUpdate();
    this.props.onGuidanceChange(guidance);
  };

  onGuidanceNotesChange = (notesMarkdown) => {
    const guidanceMetadata = {
      notesMarkdown: notesMarkdown
    }
    this.setState({
      guidanceMetadata: guidanceMetadata
    });
    this.props.onGuidanceMetadataChange(guidanceMetadata);
  }

  onDeleteGuideByUuid = (guideUuid) => {
    const newGuidance: GuidanceItem[] = [];
    for (const guide of this.state.guidance) {
      if (guide.uuid !== guideUuid) {
        newGuidance.push(guide);
      }
    }
    this.setGuidance(newGuidance);
  };

  onDeleteRequirementByUuid = (requirementUuid) => {
    const newGuidance: GuidanceItem[] = [];
    for (const guide of this.state.guidance) {
      const newGuide = util.deepCloneObject(guide);
      const newRequirements: GuidanceRequirement[] = [];
      for (const requirement of newGuide.requirements) {
        if (requirement.uuid !== requirementUuid) {
          newRequirements.push(requirement);
        }
      }
      newGuide.requirements = newRequirements;
      newGuidance.push(newGuide);
    }
    this.setGuidance(newGuidance);
  };

  moveGuideByUuid = (guideUuid, moveUp) => {
    const newGuidance = util.deepCloneObject(this.state.guidance);
    util.moveArrayItem(newGuidance, guideUuid, 'uuid', moveUp);
    this.setGuidance(newGuidance);
  };

  onBuildGuidanceFromCustomLevelsScoreInfoButtonClick = () => {
    const newGuidance = this.buildGuidanceFromCustomLevelsScoreInfo(this.state.definition);
    this.setGuidance(newGuidance);
  };

  onMoveGuideUpByUuid = (guideUuid) => {
    this.moveGuideByUuid(guideUuid, true);
  };

  onMoveGuideDownByUuid = (guideUuid) => {
    this.moveGuideByUuid(guideUuid, false);
  };

  moveRequirementByUuid = (requirementUuid, moveUp) => {
    const newGuidance = util.deepCloneObject(this.state.guidance);
    for (const guide of newGuidance) {
      util.moveArrayItem(guide.requirements, requirementUuid, 'uuid', moveUp);
    }
    this.setGuidance(newGuidance);
  };

  onMoveRequirementUpByUuid = (requirementUuid) => {
    this.moveRequirementByUuid(requirementUuid, true);
  };

  onMoveRequirementDownByUuid = (requirementUuid) => {
    this.moveRequirementByUuid(requirementUuid, false);
  };

  buildRequirement = () => {
    return {
      uuid: util.uuid(),
      text: ''
    }
  };

  onAddScoreGuidanceButtonClick = () => {
    const guide = {
      uuid: util.uuid(),
      scoreGuide: '',
      requirements: []
    };
    const newGuidance = util.deepCloneObject(this.state.guidance);
    newGuidance.push(guide);
    this.setGuidance(newGuidance);
  };

  onAddRequirementButtonClick = (guideUuid) => {
    const newGuidance: GuidanceItem[] = [];
    for (const guide of this.state.guidance) {
      const newGuide = util.deepCloneObject(guide);
      const newRequirements: GuidanceRequirement[] = [];
      for (const requirement of newGuide.requirements) {
        newRequirements.push(requirement);
      }
      if (guide.uuid === guideUuid) {
        newRequirements.push(this.buildRequirement());
      }
      newGuide.requirements = newRequirements;
      newGuidance.push(newGuide);
    }
    this.setGuidance(newGuidance);
  };

  onGuidanceExpanded = (event) => {
    // event.stopPropagation();
    this.setState({
      expanded: true
    });
  };

  onGuidanceCollapsed = (event) => {
    // event.stopPropagation();
    this.setState({
      expanded: false
    });
  };

  onScoreGuideChange = (newScoreGuide, score) => {
    score.scoreGuide = newScoreGuide ? newScoreGuide.trim() : '';
    this.setGuidance(this.state.guidance);
    // The following is necessary to reflect the deep changhe of the object into the inline edit field. 
    this.forceUpdate();
  };

  onRequirementTextChange = (newRequirementText, requirement) => {
    requirement.text = newRequirementText ? newRequirementText.trim() : '';
    this.setGuidance(this.state.guidance);
    // The following is necessary to reflect the deep changhe of the object into the inline edit field. 
    this.forceUpdate();
  };

  renderHeaderRow = () => {
    return (
      <tr>
        <td style={{width: this.iconButtonColumnWidth}}>
        </td>
        <td style={{width: this.iconButtonColumnWidth}}>
        </td>
        <td style={{width: this.iconButtonColumnWidth}}>
        </td>
        <td>
          <Label text="Abbreviation" paddingTop="0px"/>
        </td>
        <td>
          <Label text="Requirements and behaviors" paddingTop="0px"/>
        </td>
      </tr>
    );
  };

  renderGuideScoreViewMode = (guide, guideIndex) => {
    const hasScoreGuide = this.hasScoreGuide(guide.scoreGuide);
    if (hasScoreGuide) {
      const scoreMeta = this.getScoreMeta();
      return (
        <div>
          {guidanceRenderUtil.renderGuideLabelOrEmoji(guide, guideIndex, scoreMeta)}
        </div>
      );
    } else {
      const style = {
        backgroundColor: adg.adgRed,
        color: adg.white,
        borderRadius: '4px',
        padding: '2px'
      };
      let errorMessage = '';
      const scoreMetaType = rubricUtil.getScoreMetaType(this.state.definition);
      if (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType) {
        errorMessage = 'There is no custom level with this abbreviation.';
      } else {
        const scoreMeta = scoreMetaDefinitions.scoreMetaFromType(scoreMetaType);
        errorMessage = `The scoring system "${scoreMeta.label}" does not have a marker corresponding to this abbreviation.`;
      }
      return (
        <Tooltip content={errorMessage}>
          <div style={style}>
            {guide.scoreGuide || '[none]'}
          </div>
        </Tooltip>
      );
    }
  };

  renderScoreGuideRow = (guide, guideIndex, guideCount) => {
    return (
      <tr>
        <td style={{width: this.iconButtonColumnWidth}}>
          <Tooltip content="Delete score guidance group">
            <SafeInlineButton
              normalStateLabel=""
              cancelLabel=""
              iconBefore={<TrashIcon label="delete"/>}
              onConfirmation={() => this.onDeleteGuideByUuid(guide.uuid)}
            />
          </Tooltip>
        </td>
        <td style={{width: this.iconButtonColumnWidth}}>
          {guideIndex > 0 ? this.renderMoveButton(<ArrowUpIcon label="move up"/>, this.onMoveGuideUpByUuid, guide.uuid) : null}
        </td>
        <td style={{width: this.iconButtonColumnWidth}}>
          {guideIndex < (guideCount - 1) ? this.renderMoveButton(<ArrowDownIcon label="move down"/>, this.onMoveGuideDownByUuid, guide.uuid) : null}
        </td>
        <td colSpan={2}>
          <InlineEdit
            defaultValue={guide.scoreGuide || ''}
            label={undefined}
            editView={fieldProps => <Textfield {...fieldProps} autoFocus />}
            readView={() => this.renderGuideScoreViewMode(guide, guideIndex)}
            onConfirm={value => this.onScoreGuideChange(value, guide)}
          />
        </td>
      </tr>
    );
  };

  renderRequirementRow = (requirement) => {
    return (
      <tr>
        <td colSpan={2}>
          {requirement.text ? requirement.text : '-'}
        </td>
      </tr>
    );
  };

  renderMoveButton = (icon, handler, handlerArgument) => {
    return (
      <Button
        iconBefore={icon}
        onClick={() => handler(handlerArgument)}
      >
      </Button>
    );
  };

  renderGuideRequirements = (guide) => {
    const renderedRequirements = guide.requirements.map((requirement, requirementIndex) => {
      return (
        <tr key={requirement.uuid}>
          <td style={{width: this.iconButtonColumnWidth}}>
            <Tooltip content="Delete requirement/behavior">
              <SafeInlineButton
                normalStateLabel=""
                cancelLabel=""
                iconBefore={<TrashIcon label="delete"/>}
                onConfirmation={() => this.onDeleteRequirementByUuid(requirement.uuid)}
              />
            </Tooltip>
          </td>
          <td style={{width: this.iconButtonColumnWidth}}>
            {requirementIndex > 0 ? this.renderMoveButton(<ArrowUpIcon label="move up"/>, this.onMoveRequirementUpByUuid, requirement.uuid) : null}
          </td>
          <td style={{width: this.iconButtonColumnWidth}}>
            {requirementIndex < (guide.requirements.length - 1) ? this.renderMoveButton(<ArrowDownIcon label="move down"/>, this.onMoveRequirementDownByUuid, requirement.uuid) : null}
          </td>
          <td style={{width: this.scoreGuideColumnWidth}}>
            
          </td>
          <td>
            <InlineEdit
              defaultValue={requirement.text}
              label={undefined}
              editView={fieldProps => (
                  <ResizingTextArea
                    shouldFitContainer={true}
                    {...fieldProps}
                    autoFocus 
                    onChange={(event) => {
                      const value = event.currentTarget.value;
                      this.onRequirementTextChange(value, requirement)
                    }}
                  />
                )}
              readView={() => (
                <div>
                  {requirement.text || '[none]'}
                </div>
              )}
              onConfirm={value => this.onRequirementTextChange(value, requirement)}
            />
          </td>
        </tr>
      );
    });
    return (
      <React.Fragment>
        {renderedRequirements}
        <tr>
          <td style={{width: this.iconButtonColumnWidth}}>
          </td>
          <td style={{width: this.iconButtonColumnWidth}}>
          </td>
          <td style={{width: this.iconButtonColumnWidth}}>
          </td>
          <td style={{width: this.scoreGuideColumnWidth}}>
          </td>
          <td colSpan={3}>
            <Tooltip content="Add a requirement/behavior">
              <Button
                iconBefore={<AddStatementIcon label="add"/>}
                onClick={() => this.onAddRequirementButtonClick(guide.uuid)}
              >
              </Button>
            </Tooltip>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  renderBuildGuidanceFromCustomLevelsScoreInfoButton = () => {
    const hasGuidance = this.state.guidance && this.state.guidance.length;
    const hasCustomLevelsScoreInfo =
      this.state.definition.customLevelsScoreInfo &&
      this.state.definition.customLevelsScoreInfo.levels &&
      this.state.definition.customLevelsScoreInfo.levels.length;
    const scoreMetaType = rubricUtil.getScoreMetaType(this.state.definition);
    const showButtons = !hasGuidance && 
      (scoreMetaType !== scoreMetaDefinitions.customLevelsScoreMetaType ||
        (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType && hasCustomLevelsScoreInfo));
    if (showButtons) {
      return (
        <ToolbarItem>
          <Button
            appearance="primary"
            onClick={this.onBuildGuidanceFromCustomLevelsScoreInfoButtonClick}
          >
            Build guidance from custom levels
          </Button>
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderGuidanceMetadata = () => {
    // if (this.state.guidanceMetadata) {

    // }
    const notesMarkdown = this.state.guidanceMetadata && this.state.guidanceMetadata.notesMarkdown ?
      this.state.guidanceMetadata.notesMarkdown : '';
    return (
      <ResizingTextArea
        label="Guidance notes"
        compact={false}
        autoFocus={true}
        shouldFitContainer={true}
        maxLength={2000}
        minimumRows={2}
        enableResize={true}
        placeholder="Guidance notes..."
        value={notesMarkdown}
        debounceChangeCallback={true}
        onChange={this.onGuidanceNotesChange}
      />      
    );
  }

  renderGuidanceContent = () => {
    const renderedGuides = this.state.guidance.map((guide, guideIndex) => {
      return (
        <React.Fragment key={guide.uuid}>
          {this.renderScoreGuideRow(guide, guideIndex, this.state.guidance.length)}
          {this.renderGuideRequirements(guide)}
        </React.Fragment>
      );
    });
    return (
      <div style={{ marginLeft: '26px' }}>
        {this.renderGuidanceMetadata()}
        <table>
          <tbody style={{borderBottom: '0px'}}>
            {this.renderHeaderRow()}
            {renderedGuides}
            <tr>
              <td style={{width: this.iconButtonColumnWidth}}>
              </td>
              <td style={{width: this.iconButtonColumnWidth}}>
              </td>
              <td style={{width: this.iconButtonColumnWidth}}>
              </td>
              <td colSpan={3}>
                <ToolbarLeft>
                  {this.renderBuildGuidanceFromCustomLevelsScoreInfoButton()}
                  <ToolbarItem>
                    <Tooltip content="Add a new score guidance group">
                      <Button
                        iconBefore={<AddStatementIcon label="add"/>}
                        onClick={this.onAddScoreGuidanceButtonClick}
                      >
                      </Button>
                    </Tooltip>
                  </ToolbarItem>
                </ToolbarLeft>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <Expand
        contentForeignKey={undefined}
        context={this.state.guidance}
        text="Guidance"
        tooltip="Click to edit the scoring guidance for this statement"
        initiallyExpanded={this.state.expanded}
        contentRenderer={this.renderGuidanceContent}
        stopPropagation={true}
        onExpanded={(event) => this.onGuidanceExpanded(event)}
        onCollapsed={(event) => this.onGuidanceCollapsed(event)}
      />
    );
  }

}
