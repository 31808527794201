import environment from '../../shared/model/Environment';
import DashboardBrowserPersistence from './DashboardBrowserPersistence';
import DashboardDatabasePersistence from './DashboardDatabasePersistence';
import DriveBrowserPersistence from './DriveBrowserPersistence';
import DriveDatabasePersistence from './DriveDatabasePersistence';
import MomentFirestorePersistence from './MomentFirestorePersistence';
import NetworkBrowserPersistence from './NetworkBrowserPersistence';
import NetworkDatabasePersistence from './NetworkDatabasePersistence';
import NotificationBrowserPersistence from './NotificationBrowserPersistence';
import NotificationFirestorePersistence from './NotificationFirestorePersistence';
import RubricBrowserPersistence from './RubricBrowserPersistence';
import RubricFirestorePersistence from './RubricFirestorePersistence';
import PreferencesBrowserPersistence from './PreferencesBrowserPersistence';
import PreferencesDatabasePersistence from './PreferencesDatabasePersistence';
// import RecentsBrowserPersistence from './RecentsBrowserPersistence';
// import RecentsDatabasePersistence from './RecentsDatabasePersistence';
import SubscriptionsBrowserPersistence from './SubscriptionsBrowserPersistence';
import SubscriptionsDatabasePersistence from './SubscriptionsDatabasePersistence';
import RubricPersistence from './RubricPersistence';
import firestoreAssessmentDAO from './FirestoreAssessmentDAO';
import AssessmentDAI from './AssessmentDAI';

class PersistenceFactory {

  assessmentPersistence: AssessmentDAI;
  dashboardPersistence: any;
  drivePersistence: any;
  momentPersistence: any;
  networkPersistence: any;
  preferencesPersistence: any;
  rubricPersistence: RubricPersistence;
  subscriptionsPersistence: any;
  notificationsPersistence: any;

  constructor() {
    this.assessmentPersistence = firestoreAssessmentDAO;
    if (environment.mockDatabase()) {
      this.dashboardPersistence = new DashboardBrowserPersistence();
      this.drivePersistence = new DriveBrowserPersistence();
      this.momentPersistence = null;
      this.networkPersistence = new NetworkBrowserPersistence();
      this.rubricPersistence = new RubricBrowserPersistence();
      this.preferencesPersistence = new PreferencesBrowserPersistence();
      // this.recentsPersistence = new RecentsBrowserPersistence();
      this.subscriptionsPersistence = new SubscriptionsBrowserPersistence();
      this.notificationsPersistence = new NotificationBrowserPersistence();
    } else {
      this.dashboardPersistence = new DashboardDatabasePersistence();
      this.drivePersistence = new DriveDatabasePersistence();
      this.momentPersistence = new MomentFirestorePersistence();
      this.networkPersistence = new NetworkDatabasePersistence();
      this.rubricPersistence = new RubricFirestorePersistence();
      this.preferencesPersistence = new PreferencesDatabasePersistence();
      // this.recentsPersistence = new RecentsDatabasePersistence();
      this.subscriptionsPersistence = new SubscriptionsDatabasePersistence();
      this.notificationsPersistence = new NotificationFirestorePersistence();
    }
  }

};

export default new PersistenceFactory();