import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import LoginView from '../components/auth/LoginView';
import PageTitle from '../components/widget/PageTitle';
import rubricPageHelper from './RubricPageHelper';

export default class SignInPage extends PureComponent {

  componentDidMount() {
    rubricPageHelper.updateTitle('Sign In');
  }

  render() {
    return (
      <ContentWrapper>
        <PageTitle>Sign in</PageTitle>
        <p>
          Sign in to start creating and assessing rubrics.
          Using Google to sign in, there's no need to give us your email address or create a new password.
        </p>
        <LoginView/>
      </ContentWrapper>
    );
  }

}
