import ListenerGroup from '../../util/ListenerGroup';

class OnlineStatus {

  listenerGroup = new ListenerGroup('OnlineStatus');

  constructor() {
    window.addEventListener('online',  this._onStatusChange);
    window.addEventListener('offline', this._onStatusChange);
  }

  isOnline = () => {
    return true;
    // return navigator.onLine;
  };

  _onStatusChange = () => {
    this._notifyListeners();
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = () => {
    this.listenerGroup.notifyListeners(true);
    // this.listenerGroup.notifyListeners(navigator.onLine);
  };

}

export default new OnlineStatus();
