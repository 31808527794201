import analytics from '../../shared/model/analytics/Analytics';
import firebaseApp from '../firebase/FirebaseApp';

export default class SubscriptionsDatabasePersistence {

  analyticsEntityName = 'subscriptions';

  constructor() {
    this.subscriptionsPath = 'subscriptions';
    this.trialsPath = 'trials';
  }

  addSubscription = (planType, userId, paymentProviderToken, paymentProviderType) => {
    analytics.databaseWrite(this.analyticsEntityName);
    const key = this._buildSubscriptionKey(userId);
    const subscriptionData = {
      planType: planType,
      paymentProviderToken: paymentProviderToken,
      paymentProviderType: paymentProviderType
    };
    return firebaseApp
      .getDatabase()
      .ref(key)
      .set({
        subscriptionData: subscriptionData
      });
  };

  getSubscription = (userId) => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildSubscriptionKey(userId);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const snapShotVal = snapshot.val();
        const planDefinition = (snapShotVal && snapShotVal.planDefinition) || null;
        return planDefinition;
      });
  };

  _buildSubscriptionKey = (userId) => {
    return this.subscriptionsPath + '/' + userId;
  };

  addTrialInfo = (userId, expiryTimestamp) => {
    analytics.databaseWrite(this.analyticsEntityName);
    const key = this._buildTrialsKey(userId);
    const trialInfo = {
      expiryTimestamp: expiryTimestamp
    };
    return firebaseApp
      .getDatabase()
      .ref(key)
      .set(trialInfo);
  };

  getTrialInfo = (userId) => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildTrialsKey(userId);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const snapShotVal = snapshot.val();
        const trialInfo = snapShotVal || null;
        return trialInfo;
      });
  };

  _buildTrialsKey = (userId) => {
    return this.trialsPath + '/' + userId;
  };

};
