
const callbackDelay = 100;

export default {
  getCurrentUser: (callback) => {
    setTimeout(() => {
      callback({
        atlassianAccountId: '123456'
      });  
    }, callbackDelay);
  }
}