
class StatusDefinitions {

  statuses = {
    nil: {},
    allOK: {
      message: 'All is OK at the moment',
      imageName: 'day.svg'
    }, workInProgress: {
      message: 'All is OK with work in progress',
      imageName: 'cloudy-day-1.svg'
    }, mayNeedWork: {
      message: 'Work may be necessary',
      imageName: 'cloudy.svg'
    }, workToBeDone: {
      message: 'There is work to be done',
      imageName: 'rainy-4.svg'
    }, problemsExist: {
      message: 'There are problems that need to be fixed',
      imageName: 'rainy-7.svg'
    }, pending: {
      message: 'Status is pending',
      imageName: 'cloudy.svg'
    }
  }

}

export default new StatusDefinitions();
