import React, { PureComponent } from 'react';
import AddContactModal from '../network/AddContactModal';
import adg from '../../../commonbase/adg';
import Button from '@atlaskit/button';
import ContactBuilder from '../../../shared/model/network/ContactBuilder';
import ContactPicker from '../network/ContactPicker';
import Label from '../../components/widget/Label';
import navConstants from '../../components/nav/NavConstants';
import networkGraph from '../../components/network/NetworkGraph';
import networkUtil from '../../../shared/model/network/NetworkUtil';
import roleDefinitions from '../../../shared/model/auth/RoleDefinitions';
import RoleSelector from './RoleSelector';
import roleUtil from '../../../shared/model/auth/RoleUtil';
import Tooltip from '@atlaskit/tooltip';
import {AddCollaboratorIcon, DeleteContactIcon, SubmitNameIcon} from '../icon/NamedIcons';
import IconButton from "../widget/IconButton";
import ToolbarLeft from '../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../widget/toolbar/ToolbarItem';

export default class CollaboratorsView extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
    this.state.collaboratorBeingCreated = undefined;
    this.state.selectedContactEmail = undefined;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    const emailsToRoles = roleUtil.unflattenRolesArray(props.flattenedRolesArray);
    return {
      owner: props.owner,
      flattenedRolesArray: props.flattenedRolesArray,
      emailsToRoles: emailsToRoles
    };
  };

  allowContactForPicker = (contact) => {
    const existingRole = this.state.emailsToRoles[contact.email];
    return existingRole === undefined
  };

  onVisitNetworkLinkClick = () => {
    window.location.href = '/#' + navConstants.networkPageHash;
  };

  onContactPicked = (contact) => {
    const collaboratorBeingCreated = Object.assign({}, this.state.collaboratorBeingCreated, {
      contact: contact
    });
    this.setState({
      collaboratorBeingCreated: collaboratorBeingCreated
    });
  };

  setCollaboratorBeingCreatedError = (message) => {
    const collaboratorBeingCreated = Object.assign({}, this.state.collaboratorBeingCreated, {
      errorMessage: message
    });
    this.setState({
      collaboratorBeingCreated: collaboratorBeingCreated
    });
  };

  onCreateContactButtonClick = () => {
    this.setState({
      showCreateContactModal: true
    });
  };

  onCancelContactCreationClick = () => {
    this.setState({
      showCreateContactModal: false
    });
  };

  onContactCreated = (contact) => {
    this.setState({
      selectedContactEmail: contact.email,
      showCreateContactModal: false
    });
  };

  onRemoveCollaboratorClick = (email) => {
    this.props.onRemoveCollaboratorByEmail(email);
  };

  onAddCollaboratorClick = () => {
    const contact = new ContactBuilder()
      .build();
    this.setState({
      collaboratorBeingCreated: {
        contact: contact,
        role: roleDefinitions.readerRoleType,
        errorMessage: undefined
      }
    });
  };

  onCancelCollaboratorCreationClick = () => {
    this.setState({
      collaboratorBeingCreated: undefined
    });
  };

  onCollaboratorBeingCreatedRoleChange = (role) => {
    const collaboratorBeingCreated = this.state.collaboratorBeingCreated;
    collaboratorBeingCreated.role = role;
  };

  onAddCollaboratorBeingCreatedClick = () => {
    const errorMessage = networkUtil.validateContact(this.state.collaboratorBeingCreated.contact);
    if (errorMessage) {
      this.setCollaboratorBeingCreatedError(errorMessage);
    } else {
      this.props.onCollaboratorAddition(
        this.state.collaboratorBeingCreated.contact.email, this.state.collaboratorBeingCreated.role);
      this.setState({
        collaboratorBeingCreated: undefined
      });
      this.forceUpdate();
    }
  };

  renderRemoveCollaboratorButton = (email) => {
    return (
      <Tooltip content="Remove this collaborator">
        <IconButton
          normalIcon={<DeleteContactIcon/>}
          hoverIcon={<DeleteContactIcon/>}
          hoverColor={adg.adgRed}
          onClick={() => this.onRemoveCollaboratorClick(email)}
        />
      </Tooltip>
    );
  };

  onRoleChange = (contact, role) => {
    const newEmailsToRoles = {};
    const emails = Object.keys(this.state.emailsToRoles);
    for (const email of emails) {
      newEmailsToRoles[email] = this.state.emailsToRoles[email];
      if (email === contact.email) {
        newEmailsToRoles[email] = role;
      }
    }
    this.setState({
      emailsToRoles: newEmailsToRoles
    });
    this.props.onCollaboratorUpdate(contact.email, role);
  };

  renderCollaborators = () => {
    // const currentUser = session.getCurrentUser();
    const renderedRoleRows = [];
    const ownerEmail = this.state.owner.getEmail();
    const emails = Object.keys(this.state.emailsToRoles);
    for (const email of emails) {
      const contact = networkGraph.getContactByEmail(email);
      const isOwner = networkUtil.equalEmails(email, ownerEmail);
      const role = this.state.emailsToRoles[email];

      const renderedRoleRow = (
        <tr key={email} valign="middle">
          <td>
            {contact ? contact.name : email}
          </td>
          <td>
            <RoleSelector
              hideLabel={true}
              roleType={role}
              onChange={(role) => this.onRoleChange(contact, role)}
            />
          </td>
          <td>
            {isOwner ? null : this.renderRemoveCollaboratorButton(email)}
          </td>
        </tr>
      );
      renderedRoleRows.push(renderedRoleRow);
    }
    return renderedRoleRows;
  };

  renderAddCollaboratorButton = () => {
    return (
      <ToolbarItem>
        <Button
          appearance="default"
          onClick={this.onAddCollaboratorClick}
        >
          Add collaborator
        </Button>
      </ToolbarItem>
    );
  };

  renderFooterOperations = () => {
    return (
      <tr>
        <td colSpan="3">
          <div className="centredContent" style={{display: 'block', width: '100%'}}>
            <ToolbarLeft>
              {this.state.collaboratorBeingCreated ? null : this.renderAddCollaboratorButton()}
              <ToolbarItem>
                <Button
                  appearance="subtle-link"
                  isDisabled={false}
                  onClick={this.onVisitNetworkLinkClick}
                >
                  Visit network
                </Button>
              </ToolbarItem>
            </ToolbarLeft>
          </div>
        </td>
      </tr>
    );
  };

  renderAddCollaboratorRow = () => {
    const rowStyle = {
      border: `2px solid ${adg.B75}`
    };
    return (
      <tr style={rowStyle}>
        <td>

          <ToolbarLeft>
            <ToolbarItem>
              <ContactPicker
                hideLabel={true}
                selectedContactEmail={this.state.selectedContactEmail}
                allowContact={this.allowContactForPicker}
                onChange={this.onContactPicked}
              />
            </ToolbarItem>
            <ToolbarItem>
              <Tooltip content="Create a new contact">
                <IconButton
                  normalIcon={<AddCollaboratorIcon/>}
                  hoverIcon={<AddCollaboratorIcon/>}
                  onClick={this.onCreateContactButtonClick}
                />
              </Tooltip>
            </ToolbarItem>
          </ToolbarLeft>
        </td>
        <td style={{paddingTop: '4px'}}>
          <RoleSelector
            hideLabel={true}
            roleType={roleDefinitions.readerRoleType}
            onChange={this.onCollaboratorBeingCreatedRoleChange}
          />
        </td>
        <td>
          <ToolbarLeft>
            <ToolbarItem>
              <Button
                appearance="primary"
                iconBefore={<SubmitNameIcon/>}
                isDisabled={false}
                onClick={this.onAddCollaboratorBeingCreatedClick}
              >
                Add collaborator
              </Button>
            </ToolbarItem>
            <ToolbarItem>
              <Button
                appearance="subtle"
                isDisabled={false}
                onClick={this.onCancelCollaboratorCreationClick}
              >
                Cancel
              </Button>
            </ToolbarItem>
          </ToolbarLeft>
        </td>
      </tr>
    );
  };

  renderErrorBanner = (message) => {
    return (
      <div className="message-banner error-message-banner">
        {message}
      </div>
    );
  };

  showCreateContactModal = () => {
    return (
      <AddContactModal
        onCancelClick={this.onCancelContactCreationClick}
        onContactCreated={this.onContactCreated}
      />
    );
  };

  renderHeaderRow = () => {
    const theadStyle = {borderBottomWidth: '0px'};
    const thStyle = {margin: '0px', paddingBottom: '0px'};
    return (
      <thead style={theadStyle}>
        <tr>
          <th style={thStyle}>
            <Label text="Contact" paddingTop="0px"/>
          </th>
          <th style={thStyle}>
            <Label text="Role" paddingTop="0px"/>
          </th>
          <th style={thStyle}>
            <Label text="Actions" paddingTop="0px"/>
          </th>
        </tr>
      </thead>
    );
  };

  render() {
    const errorMessage = this.state.collaboratorBeingCreated ?
      this.state.collaboratorBeingCreated.errorMessage : '';
    return (
      <div>
        {this.state.showCreateContactModal ? this.showCreateContactModal() : null}
        {errorMessage ? this.renderErrorBanner(errorMessage) : null}
        <Label text={this.props.title}/>
        <table style={{width: 'auto', margin: '0px'}}>
          {this.state.showHeaderRow ? this.renderHeaderRow() : null}
          <tbody style={{borderBottomWidth: '0px'}}>
            {this.renderCollaborators()}
            {this.state.collaboratorBeingCreated ? this.renderAddCollaboratorRow() : null}
            {this.renderFooterOperations()}
          </tbody>
        </table>
      </div>
    );
  }

}
