import colorRange from '../../../../commonbase/color/customLevelsColorRange';
import ScoreMeta from './ScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';

class LevelsScoreMeta extends ScoreMeta {

  constructor() {
    super(scoreTypes.levels);
  }

  getColorRange = () => {
    return colorRange;
  }

}

export default new LevelsScoreMeta();
