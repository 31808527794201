import currentUserPlan from '../../../backend/payment/CurrentUserPlan';
import discoverabilityDefinitions from "../rubric/DiscoverabilityDefinitions";
import editabilityDefinitions from "../rubric/EditabilityDefinitions";
import roleDefinitions from './RoleDefinitions'
import rubricUtil from '../rubric/RubricUtil';
import session from './Session';
import shareStateDefinitions from "../rubric/ShareStateDefinitions";
import Definition from '../rubric/definition/Definition';
import Assessment from '../rubric/score/Assessment';
import IUser from './IUser';

class PermissionUtil {

  // Definition

  filterByReadableDefinitions = (definitions: Definition[]): Definition[] => {
    const readableDefinitions: Definition[] = [];
    for (let i = 0; i < definitions.length; i++) {
      const definition = definitions[i];
      if (this.canReadDefinition(definition)) {
        readableDefinitions.push(definition);
      }
    }
    return readableDefinitions;
  };

  canReadDefinition = (definition: Definition): boolean => {
    let authorized = false;
    if (definition) {
      const user = session.getCurrentUser();
      if (user) {
        const userId = user.getId();
        authorized = this._canReadDefinition(definition, userId);
      }
    }
    return authorized;
  };

  canDeleteDefinition = (definition: Definition): boolean => {
    return this.canWriteDefinition(definition);
  };

  canWriteDefinition = (definition: Definition): boolean => {
    let authorized = false;
    if (definition) {
      const user = session.getCurrentUser();
      if (user) {
        const userId = user.getId();
        if (this._canReadDefinition(definition, userId)) {
          if (userId === definition.ownerId) {
            return true;
          }
        }
      }
    }
    return authorized;
  };

  _canReadDefinition = (definition: Definition, userId: string): boolean => {
    let authorized = false;
    if (userId) {
      if (definition.ownerId === userId) {
        authorized = true;
      } else {
        const discoverability = definition.discoverability;
        if (discoverability === discoverabilityDefinitions.publicDiscoverabilityType || discoverability === discoverabilityDefinitions.linkDiscoverabilityType) {
          authorized = true;
        }
      }
    }
    return authorized;
  };

  canForkDefinition = (definition: Definition): boolean => {
    const user = session.getCurrentUser();
    if (user) {
      if (definition) {
        if (definition.allowForking) {
          return true;
        }
        const userId = user.getId();
        if (userId && userId === definition.ownerId) {
          return true;
        }
      }
    }
    return false;
  };

  canRemoveItemsFromDefinition = (definition: Definition): boolean => {
    if (definition) {
      const allow = definition.editability === editabilityDefinitions.fullEditabilityType;
      if (allow) {
        return true;
      }
    }
    return false;
  };

  canAddItemsToDefinition = (definition: Definition): boolean => {
    if (definition) {
      const allow = definition.editability === editabilityDefinitions.fullEditabilityType ||
        definition.editability === editabilityDefinitions.partialEditabilityType;
      if (allow) {
        return true;
      }
    }
    return false;
  };

  // Assessment

  // canViewOrCreateAssessments = (): boolean => {
  //   const user = session.getCurrentUser();
  //   if (user) {
  //     const planDefinition = currentUserPlan.getCurrentPlanDefinition();
  //     if (planDefinition.allowAssessments) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  canViewOrCreateAssessments = (): boolean => {
    return this.canViewAssessments();
  };

  canViewAssessments = (): boolean => {
    const user = session.getCurrentUser();
    if (user) {
      const planDefinition = currentUserPlan.getCurrentPlanDefinition();
      if (planDefinition.allowViewAssessments) {
        return true;
      }
    }
    return false;
  };

  // canCreateAssessments = (): boolean => {
  //   const user = session.getCurrentUser();
  //   if (user) {
  //     const planDefinition = currentUserPlan.getCurrentPlanDefinition();
  //     if (planDefinition.createAssessments) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  canCreateAssessment = (): boolean => {
    const user = session.getCurrentUser();
    if (user) {
      const planDefinition = currentUserPlan.getCurrentPlanDefinition();
      if (planDefinition.allowCreateAssessments) {
        return true;
      }
    }
    return false;
  };

  canReadAssessment = (assessment: Assessment): boolean => {
    return this.canUserReadAssessment(assessment, session.getCurrentUser());
  };

  canUserReadAssessment = (assessment: Assessment, user: undefined | IUser): boolean => {
    let authorized = false;
    if (assessment) {
      if (user) {
        const planDefinition = currentUserPlan.getCurrentPlanDefinition();
        if (planDefinition.allowViewAssessments) {
          // debugger;
          if (assessment.discoverability === discoverabilityDefinitions.publicDiscoverabilityType) {
            authorized = true;
          } else {
            const userId = user.getId();
            if (userId) {
              const userEmail = user.getEmail();
              const emailDomain = user.getEmailDomain();
              const role = rubricUtil.getAssessmentRole(assessment, userEmail, emailDomain);
              if (roleDefinitions.isReader(role)) {
                authorized = true;
              }
            }
          }
        }
      }
    }
    return authorized;
  };

  canAdministerAssessment = (assessment: Assessment): boolean => {
    let authorized = false;
    if (assessment) {
      const user = session.getCurrentUser();
      if (user) {
        if (assessment.ownerId === user.getId()) {
          authorized = true;
        } else {
          const userEmail = user.getEmail();
          const emailDomain = user.getEmailDomain();
          const role = rubricUtil.getAssessmentRole(assessment, userEmail, emailDomain);
          if (roleDefinitions.isAdmin(role)) {
            authorized = true;
          }
        }
      }
    }
    return authorized;
  };

  canDeleteAssessment = (assessment: Assessment): boolean => {
    return this.canWriteAssessment(assessment);
  };

  canShareAssessment = (assessment: Assessment): boolean => {
    return this.canWriteAssessment(assessment);
  };

  canWriteAssessment = (assessment: Assessment): boolean => {
    let authorized = false;
    if (assessment) {
      const user = session.getCurrentUser();
      if (user) {
        const planDefinition = currentUserPlan.getCurrentPlanDefinition();
        if (planDefinition.allowCreateAssessments) {
          const userEmail = user.getEmail();
          const emailDomain = user.getEmailDomain();
          const role = rubricUtil.getAssessmentRole(assessment, userEmail, emailDomain);
          if (roleDefinitions.isWriter(role)) {
            authorized = true;
          }
        }
      }
    }
    return authorized;
  };

  filterAssessmentsWithViewPermission = (assessments: Assessment[]): Assessment[] => {
    // if (assessments.length) {
    //   debugger;
    // }
    const filteredAssessments: Assessment[] = [];
    for (let i = 0; i < assessments.length; i++) {
      const assessment = assessments[i];
      if (this.canReadAssessment(assessment)) {
        filteredAssessments.push(assessment);
      }
    }
    return filteredAssessments;
  };

  filterAssessmentsWithWritePermission = (assessments: Assessment[]): Assessment[] => {
    const filteredAssessments: Assessment[] = [];
    for (let i = 0; i < assessments.length; i++) {
      const assessment = assessments[i];
      if (this.canWriteAssessment(assessment)) {
        filteredAssessments.push(assessment);
      }
    }
    return filteredAssessments;
  };

  // Shared entities

  canReadNotification = (notification): boolean => {
    // TBD
    return true;
  };

  canRejectNotification = (notification): boolean => {
    let permission = false;
    if (notification.shareState !== shareStateDefinitions.rejectedShareState) {
      const currentUser = session.getCurrentUser();
      if (currentUser) {
        const currentUserEmail = currentUser.getEmail();
        if (currentUserEmail === notification.targetUserEmail) {
          permission = true;
        }
      }
    }
    return permission;
  };

  canAcknowledgeNotification = (notification): boolean => {
    let permission = false;
    if (notification.shareState !== shareStateDefinitions.acceptedShareState) {
      const currentUser = session.getCurrentUser();
      if (currentUser) {
        const currentUserEmail = currentUser.getEmail();
        if (currentUserEmail === notification.targetUserEmail) {
          permission = true;
        }
      }
    }
    return permission;
  };

  canWriteNotification = (notification): boolean => {
    // TBD
    return true;
  };

}

export default new PermissionUtil();
