import analytics from '../../shared/model/analytics/Analytics';
import firebaseApp from '../firebase/FirebaseApp';
import util from '../../shared/util/Util';
import environment from '../../shared/model/Environment';
import DashboardPersistence from './DashboardPersistence';
import DashboardInfo from '../../shared/model/rubric/dashboard/DashboardInfo';

export default class DashboardDatabasePersistence implements DashboardPersistence {

  dashboardPath = 'dashboard';
  analyticsEntityName = 'dashboard-state';

  saveState = (userId: string, state: DashboardInfo) => {
    if (environment.isLocalHost) {
      util.checkObjectHasNoUndefinedValues(state);
    }
    analytics.databaseWrite(this.analyticsEntityName);
    const key = this._buildKey(userId);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .set(state);
  };

  getState = (userId: string): Promise<undefined | DashboardInfo> => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildKey(userId);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const state = snapshot.val() as undefined | DashboardInfo;
        return state;
      });
  };

  _buildKey = (userId: string) => {
    return this.dashboardPath + '/' + userId;
  };

};
