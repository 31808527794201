
export class I18N {

  About = 'About';
  //Add_signal = 'Add signal';
  An_unknown_error_occurred = 'An unknown error occurred';
  Area = 'Area';
  Area_header = 'Area header';
  Aspect = 'Aspect';
  Assessment = 'Assessment';
  Assessments = 'Assessments';
  assessment = 'assessment';
  assessments = 'assessments';
  Benefits = 'Benefits';
  Consideration = 'Consideration';
  by = 'by';
  cancel = 'cancel';
  Cant_login = 'Can\'t login?';
  Configuration = 'Configuration';
  consolidated = 'consolidated';
  Current_time = 'Current time';
  Dashboard = 'Dashboard';
  Description = 'Description';
  //Dimensions = 'Dimensions';
  Getting_started = 'Getting started';
  Height = 'Height';
  Home = 'Home';
  Label = 'Label';
  Login = 'Sign in';
  Login_with_Google = 'Sign in with Google';
  Login_with_Password = 'Sign in with Password';
  Logout = 'Logout';
  menu = 'menu';
  NA = 'N/A';
  Name = 'Name';
  None = 'None';
  Note = 'Note';
  Observations = 'Observations';
  Operations = 'Operations';
  Overview = 'Overview';
  Pain_points = 'Pain points';
  Password = 'Password';
  Recommendations = 'Recommendations';
  RotationPeriodSeconds = 'Rotation period (seconds)';
  Rubric = 'Rubric';
  rubric = 'rubric';
  Set_discriminator = 'Set discriminator';
  Session = 'Session';
  Settings = 'Settings';
  Support = 'Support';
  Username = 'Username';
  Weight = 'Weight';
  Width = 'Width';


  January = 'January';
  February = 'February';
  March = 'March';
  April = 'April';
  May = 'May';
  June = 'June';
  July = 'July';
  August = 'August';
  September = 'September';
  October = 'October';
  November = 'November';
  December = 'December';

  Jan = 'Jan';
  Feb = 'Feb';
  Mar = 'Mar';
  Apr = 'Apr';
  //May = 'May';
  Jun = 'Jun';
  Jul = 'Jul';
  Aug = 'Aug';
  Sep = 'Sep';
  Oct = 'Oct';
  Nov = 'Nov';
  Dec = 'Dec';

}

export default new I18N();
