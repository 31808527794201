import React from 'react';
import PathBuilder from './PathBuilder';
import util from '../../../shared/util/Util';

export default class EmojiBuilder {

  constructor() {
    this.attributes = {
      radius: 16,
      cx: 16,
      cy: 16,
      strokeWidth: 1,
      foregroundColor: '#000000',
      backgroundColor: '#ffffff'
    };
  };

  setCentre = (cx, cy) => {
    this.attributes.cx = cx;
    this.attributes.cy = cy;
    return this;
  };

  setRadius = (radius) => {
    this.attributes.radius = radius;
    this.attributes.strokeWidth = radius / 6;
    return this;
  };

  setForegroundColor = (foregroundColor) => {
    this.attributes.foregroundColor = foregroundColor;
    return this;
  };

  setBackgroundColor = (backgroundColor) => {
    this.attributes.backgroundColor = backgroundColor;
    return this;
  };

  showHeadeOutline = () => {
    this.attributes.showHeadOutline = true;
    return this;
  };

  setHappinessFactor = (happinessFactor) => {
    this.attributes.happinessFactor = happinessFactor;
    this.attributes.showOpenEyes = true;
    return this;
  };

  renderOpenEyes = (attributes) => {
    const eyeCentreY = attributes.cy - 0.25 * attributes.radius;
    const width = 0.8 * attributes.radius;
    const leftX = attributes.cx - width / 2;
    const rightX = attributes.cx + width / 2;
    const r = attributes.radius / 8;
    return (
      <g>
        <circle
          fill={attributes.backgroundColor}
          stroke={attributes.foregroundColor}
          strokeWidth={0.8 * attributes.strokeWidth}
          cx={leftX}
          cy={eyeCentreY}
          r={r}
        />
        <circle
          fill={attributes.backgroundColor}
          stroke={attributes.foregroundColor}
          strokeWidth={0.8 * attributes.strokeWidth}
          cx={rightX}
          cy={eyeCentreY}
          r={r}
        />
      </g>
    );
  };

  renderHeadOutline = (attributes) => {
    return (
      <circle
        fill={attributes.backgroundColor}
        stroke={attributes.foregroundColor}
        strokeWidth={attributes.strokeWidth / 2}
        cx={attributes.cx}
        cy={attributes.cy}
        r={attributes.radius}
      />
    );
  };

  renderMouth = (attributes) => {
    const happinessFactor = attributes.happinessFactor;
    const sadTopY = attributes.cy + 0.6 * attributes.radius;
    const happyTopY = attributes.cy + 0.2 * attributes.radius;
    const topY = util.interpolate(sadTopY, happyTopY, happinessFactor);
    const width = 1.3 * attributes.radius;
    const leftX = attributes.cx - width / 2;
    const rightX = attributes.cx + width / 2;
    const rx = 0.5 * attributes.radius;
    const ry = happinessFactor < 0.5 ?
      util.interpolate(0.75 * rx, 0, 2 * happinessFactor) :
      util.interpolate(0, 0.75 * rx, 2 * (happinessFactor - 0.5));
    const sweepFlag = happinessFactor < 0.5 ? 1 : 0;
    let path = new PathBuilder()
      .setPathColor(attributes.foregroundColor)
      .setFill(attributes.backgroundColor)
      .setPathWidth(attributes.strokeWidth)
      .moveTo(leftX, topY)
      .arcTo (rx, ry, 0, 0, sweepFlag, rightX, topY)
    ;
    if (happinessFactor < 0.25 || happinessFactor > 0.75) {
      path = path.close();
    }
    return path.render();
  };

  renderPuzzledMouth = (attributes) => {
    const happinessFactor = 0.5;
    const sadTopY = attributes.cy + 0.6 * attributes.radius;
    const happyTopY = attributes.cy + 0.2 * attributes.radius;
    const topY = util.interpolate(sadTopY, happyTopY, happinessFactor);
    const width = 1.3 * attributes.radius;
    const leftX = attributes.cx - width / 2;
    const rightX = attributes.cx + width / 2;
    const rx = 0.5 * attributes.radius;
    const ry = 0.4 * attributes.radius;
    const leftSweepFlag = 0;
    const rightSweepFlag = 1;
    return new PathBuilder()
      .setPathColor(attributes.foregroundColor)
      .setFill(attributes.backgroundColor)
      .setPathWidth(attributes.strokeWidth)
      .moveTo(leftX, topY)
      .arcTo (rx, ry, 0, 0, leftSweepFlag, attributes.cx, topY)
      .arcTo (rx, ry, 0, 0, rightSweepFlag, rightX, topY)
      .render();
  };

  renderEyebrow = (attributes, cx, cy, leftHeightFactor, rightHeightFactor) => {
    const height = 0.1 * attributes.radius;
    const width = 0.3 * attributes.radius;
    return <line
      stroke={attributes.foregroundColor}
      fill={attributes.backgroundColor}
      strokeWidth={0.8 * attributes.strokeWidth}
      x1={cx - width / 2}
      y1={cy + leftHeightFactor * height}
      x2={cx + width / 2}
      y2={cy + rightHeightFactor * height}
      strokeLinecap="round"
    />
  };

  renderEyebrows = (attributes) => {
    const happinessFactor = attributes.happinessFactor;
    const eyeCentreY = attributes.cy - 0.35 * attributes.radius;
    const heightOffset = happinessFactor < 0.5 ?
      util.interpolate(0, 0.08 * attributes.radius, 2 * happinessFactor) :
      util.interpolate(0.08 * attributes.radius, 0, 2 * (happinessFactor - 0.5));
    const middleY = eyeCentreY - 0.2 * attributes.radius - heightOffset;
    const innerWidth =  0.5 * attributes.radius;
    const outerWidth =  1.1 * attributes.radius;
    const width = util.interpolate(innerWidth, outerWidth, happinessFactor);
    const leftCentreX = attributes.cx - width / 2;
    const rightCentreX = attributes.cx + width / 2;
    const leftEyeHeightFactor = util.interpolate(-1, 1, happinessFactor);
    const rightEyeHeightFactor = util.interpolate(1, -1, happinessFactor);
    return (
      <g>
        {this.renderEyebrow(attributes, leftCentreX, middleY, leftEyeHeightFactor, rightEyeHeightFactor)}
        {this.renderEyebrow(attributes, rightCentreX, middleY, -1 * leftEyeHeightFactor, -1 * rightEyeHeightFactor)}
      </g>
    );
  };

  renderDebug = (attributes) => {
    return (
      <g>
        <line
          stroke={attributes.foregroundColor}
          fill={attributes.backgroundColor}
          strokeWidth={attributes.strokeWidth}
          x1={attributes.cx - attributes.radius}
          y1={attributes.cy - attributes.radius}
          x2={attributes.cx + attributes.radius}
          y2={attributes.cy + attributes.radius}
        />
      </g>
    );
  };

  renderHappiness = (attributes) => {
    return (
      <g>
        {attributes.showHeadOutline ? this.renderHeadOutline(attributes) : null}
        {this.renderOpenEyes(attributes)}
        {this.renderMouth(attributes)}
        {this.renderEyebrows(attributes)}
      </g>
    );
  };

  renderPuzzled = (attributes) => {
    return (
      <g>
        {attributes.showHeadOutline ? this.renderHeadOutline(attributes) : null}
        {this.renderOpenEyes(attributes)}
        {this.renderPuzzledMouth(attributes)}
      </g>
    );
  };

  render = () => {
    const attributes = this.attributes;
    if (attributes.happinessFactor === undefined) {
      return this.renderPuzzled(attributes);
    } else {
      return this.renderHappiness(attributes);
    }
  }
  
}