
const util = {};

util.isUuid = function(text) {
  // Example: 96423e6a-3a8f-67f8-f97d-7762f94d35cc
  const zeroCharCode = 48;
  const nineCharCode = 57;
  const aCharCode = 65;
  const zCharCode = 90;
  if (text) {
    if (text.length === 36) {
      for (let index = 0; index < text.length; index++) {
        const currentChar = text.charAt(index);
        if (index === 8 || index === 13 || index === 18 || index === 23) {
          if (currentChar !== '-') {
            // console.log(`* ${text} is not a UUID: character at index ${index} is not a dash (${currentChar})`);
            return false;
          }
        } else {
          var charCode = currentChar.charCodeAt(0);
          const alphanumeric = (charCode >= zeroCharCode && charCode <= nineCharCode) ||
            (charCode >= aCharCode && charCode <= zCharCode);
          if (!alphanumeric) {
            // console.log(`* ${text} is not a UUID: character at index ${index} is not alphanumeric (${currentChar})`);
            return false;
          }
        }
      }
      return true;
    } else {
      // console.log(`* ${text} is not a UUID: incorrect length (${text.length})`);
      return false;
    }
  } else {
    // console.log(`* ${text} is not a UUID`);
    return false;
  }
}

util.uuid4 = function() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

util.uuid = function() {
  // WARNING: don't change this scheme  since URL parsing depends on the format and there are existing 
  //          UUIDs based on it.
  const s4 = util.uuid4;
  // Example: 96423e6a-3a8f-67f8-f97d-7762f94d35cc
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
};

util.equalByUuid = function(a, b) {
  if (a && b) {
    return a.uuid === b.uuid;
  } else if (!a && !b) {
    return true;
  } else {
    return false;
  }
}

util.allEqualByUuid = function(as, bs) {
  if (as && bs) {
    if (as.length === bs.length) {
      for (let i = 0; i < as.length; i++) {
        if (!util.equalByUuid(as[i], bs[i])) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  } else if (!as && !bs) {
    return true;
  } else {
    return false;
  }
}

module.exports = util;
