import util from '../../util/Util';

export default class NotificationBuilder {

  constructor() {
    this.notificationObject = {
      uuid: util.uuid(),
      fromUserId: undefined,
      targetUserEmail: undefined,
      entityType: '',
      title: undefined,
      message: undefined,
      //definitionUuid: undefined,
      //definitionName: undefined,
      //assessmentUuid: undefined,
      //assessmentName: undefined,
      // acknowledged: false,
      expiryTimestamp: undefined
    };
  }

  setUuid = (uuid) => {
    this.notificationObject.uuid = uuid;
    return this;
  };

  setFromUserId = (fromUserId) => {
    this.notificationObject.fromUserId = fromUserId;
    return this;
  };

  setTargetUserEmail = (targetUserEmail) => {
    this.notificationObject.targetUserEmail = targetUserEmail;
    return this;
  };

  setShareState = (shareState) => {
    this.notificationObject.shareState = shareState;
    return this;
  };

  setTitle = (title) => {
    this.notificationObject.title = title;
    return this;
  };

  setMessage = (message) => {
    this.notificationObject.message = message;
    return this;
  };

  setDefinitionUuid = (definitionUuid) => {
    this.notificationObject.definitionUuid = definitionUuid;
    return this;
  };

  setDefinitionName = (definitionName) => {
    this.notificationObject.definitionName = definitionName;
    return this;
  };

  setAssessmentUuid = (assessmentUuid) => {
    this.notificationObject.assessmentUuid = assessmentUuid;
    return this;
  };

  setAssessmentName = (assessmentName) => {
    this.notificationObject.assessmentName = assessmentName;
    return this;
  };

  setEntityUuid = (entityUuid) => {
    this.notificationObject.entityUuid = entityUuid;
    return this;
  };

  setEntityType = (entityType) => {
    this.notificationObject.entityType = entityType;
    return this;
  };

  setExpiryTimestamp = (expiryTimestamp) => {
    this.notificationObject.expiryTimestamp = expiryTimestamp;
    return this;
  };

  build = () => {
    if (this.notificationObject.targetUserEmail === undefined) {
      throw new Error('targetUserEmail must be set.');
    }
    if (this.notificationObject.title === undefined) {
      throw new Error('title must be set.');
    }
    if (this.notificationObject.message === undefined) {
      throw new Error('message must be set.');
    }
    return this.notificationObject;
  };

}

