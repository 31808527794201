import React, { PureComponent } from "react";
import adg from '../../../commonbase/adg';
import actions from '../../../shared/actions/Actions';
import assessmentUtil from '../../../shared/model/rubric/score/AssessmentUtil';
import DefinitionAssessmentSelect from '../rubric/assessment/DefinitionAssessmentSelect';
import { ButtonGroup } from '@atlaskit/button';
import constants from "../../../shared/model/Constants";
import { DatePicker } from '@atlaskit/datetime-picker';
import DefinitionSelect from '../rubric/definition/DefinitionSelect';
import discoverabilityDefinitions from "../../../shared/model/rubric/DiscoverabilityDefinitions";
import firestoreAssessmentDAO from '../../../backend/data/FirestoreAssessmentDAO';
import Label from '../widget/Label';
import navUtil from '../nav/NavUtil';
import SafeInlineButton from '../widget/SafeInlineButton';
import SubmitButton from '../widget/SubmitButton';
import {
  ClearFormIcon, ErrorIcon, SuccessIcon
} from '../icon/NamedIcons';
import ResizingTextArea from '../widget/ResizingTextArea';
import session from '../../../shared/model/auth/Session';
import util from '../../../shared/util/Util';
import dateUtil from '../../../commonbase/util/dateUtil';
import rubricUtil from "../../../shared/model/rubric/RubricUtil";
import Assessment from '../../../shared/model/rubric/score/Assessment';
import Definition from '../../../shared/model/rubric/definition/Definition';
import rubricDAO from '../../../backend/data/RubricDAO';
import ToolbarLeft from '../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../widget/toolbar/ToolbarItem';

type Props = {

}

type State = {
  uuid?: string
  date: Date
  description: string
  dirty?: boolean
  disabled?: boolean
  definitionReset: boolean
  definition: undefined | Definition
  assessment: Assessment
}

export default class CreateMomentView extends PureComponent<Props, State> {

  mounted = false;
  assessmentDAO = firestoreAssessmentDAO;
  state: any;

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  buildStateFromProps = (props: Props) => {
    return {
    };
  };

  getInitialState = () => {
    return {
      uuid: util.uuid(),
      date: new Date(),
      description: '',
      dirty: false,
      disabled: true,
      definition: undefined,
      assessment: undefined
    };
  };

  getResetState = () => {
    return {
      uuid: util.uuid(),
      date: new Date(),
      description: '',
      dirty: false,
      disabled: true
    };
  };

  stateToMoment = () => {
    const currentUser = session.getCurrentUser();
    if (!currentUser) {
      throw new Error('No current user');
    }
    return {
      uuid: this.state.uuid,
      ownerId: currentUser.getId(),
      discoverability: discoverabilityDefinitions.privateDiscoverabilityType,
      date: this.state.date.getTime(),
      description: this.state.description
    }
  };

  _createMomentAddedFlag = (definition, assessment, moment) => {
    const flagId = util.uuid();
    const flagActions: any[] = [];
    // debugger;
    if (assessment) {
      // const assessmentContext = {
      //   definition: definition,
      //   assessment: assessment
      // }
      flagActions.push({
        content: 'Visit assessment',
        onClick: () => {
          actions.removeFlag(flagId);
          // actions.onViewAssessment(assessmentContext);
          // window.location.href = constants.appBaseUrl + '/#' + navConstants.viewPageHash + '/:' + definition.uuid + '/' + definition.name
          window.location.href = navUtil.buildDefinitionUrl(definition.uuid , definition.name, undefined);
        }
      });
    }
    const flag = {
      id: flagId,
      // appearance: "success",
      icon: <SuccessIcon label="success" primaryColor={adg.adgGreen} />,
      title: 'Moment saved',
      description: 'Your moment has been saved.',
      flagMessageTimeoutSeconds: 20,
      actions: flagActions
    };
    actions.addFlag(flag);
  };

  _createErrorFlag = (moment, description) => {
    const flag = {
      id: util.uuid(),
      xappearance: "error",
      icon: <ErrorIcon label="error" primaryColor={adg.adgRed} />,
      title: 'Error',
      description: description,
      flagMessageTimeoutSeconds: 10
    };
    actions.addFlag(flag);
  };

  saveMomentToAssessmentNotes = (assessment, moment) => {
    return new Promise((resolve, reject) => {
      const momentNote = '**' + util.formatDate(new Date()) + '**: ' + moment.description;
      rubricUtil.appendAssessmentGeneralNotes(assessment, momentNote, true);
      resolve(undefined);
    }).then(() => {
      return assessmentUtil.saveAssessment(this.assessmentDAO, assessment)
    });
  };

  onDateChange = (newDateText) => {
    // alert(newDateText);
    const newDate = new Date(newDateText);
    this.setState({
      date: newDate,
      dirty: true
    });
  };

  onDescriptionChange = (event) => {
    let disabled = true;
    const description = event.currentTarget.value;
    if (description) {
      disabled = false;
    }
    this.setState({
      description: description,
      dirty: true,
      disabled: disabled
    });
  };

  onSubmitMomentClick = () => {
    const moment = this.stateToMoment();
    this.saveMomentToAssessmentNotes(this.state.assessment, moment).then(() => {
      const resetState = this.getResetState();
      this.setState(resetState);
      this._createMomentAddedFlag(this.state.definition, this.state.assessment, moment);
    }).catch((error) => {
      console.error("Error writing moment: ", error);
      this._createErrorFlag(moment, 'An error occurred whilst saving the moment: ' + error);
    });
  };

  onResetButtonClick = () => {
    //alert(this.state.description);
    const resetState = this.getResetState();
    this.setState(resetState);
  };

  onDefinitionSelect = (selectedDefinition) => {
    // console.log(selectedDefinition);
    this.setState({
      definitionReset: true,
      definition: undefined
    });
    setTimeout(() => {
      this.setState({
        definitionReset: false,
        definition: selectedDefinition
      });
    }, 0);
  };

  onAssessmentsSelect = (selectedAssessments) => {
    this.setState({
      assessment: selectedAssessments && selectedAssessments.length ? selectedAssessments[0] : undefined
    });
  };

  renderDefinitionAssessmentSelect = (definition: Definition) => {
    return (
      <DefinitionAssessmentSelect
        lastSelectedAssessmentCacheKey="last-moment-assessment"
        assessmentUuids={[]}
        multiple={false}
        allowReadableAssessments={false}
        definitionReset={this.state.definitionReset}
        definition={definition}
        assessmentDAO={this.assessmentDAO}
        requireAssessmentWritePermission={true}
        autoFocus={false}
        onSelect={this.onAssessmentsSelect}
      />
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="centredContent" style={{marginTop: '0px'}}>
          <h2>Capture a moment</h2>
        </div>

        <Label text="When was the moment?" />
        <DatePicker
          id="moment-date"
          value={this.state.date}
          dateFormat={dateUtil.dateFormat}
          formatDisplayLabel={dateUtil.parseAndFormatDate}
          parseInputValue={dateUtil.parseDate}
          onChange={this.onDateChange}
        />

        <div className="xxxxxnewMainSection">
          <DefinitionSelect
            label="Rubric"
            cacheKey="last-moment-definition"
            definitionResolver={rubricDAO}
            initialDefinitionUuid={undefined}
            autoFocus={true}
            onSelect={this.onDefinitionSelect}
          />
        </div>
        
        <div className="xxxxxnewMainSection">
          {this.state.definition ? this.renderDefinitionAssessmentSelect(this.state.definition) : null}
        </div>

        <div style={{
          marginTop: '10px',
          marginBottom: '10px'
        }}>
          <ResizingTextArea
            label="Description"
            compact={false}
            autoFocus={false}
            shouldFitContainer={true}
            minHeight={60}
            maxHeight={900}
            maxLength={constants.maxMomentLength}
            minimumRows={1}
            enableResize={true}
            placeholder=""
            value={this.state.description}
            onChange={(event) => this.onDescriptionChange(event)}
          />
        </div>

        <div className="newMinorSection centredContent">
          <ButtonGroup>
            <ToolbarLeft>
              <ToolbarItem>
                <SubmitButton
                  normalStateLabel="Submit"
                  dirty={this.state.dirty}
                  isDisabled={this.state.disabled}
                  onClick={this.onSubmitMomentClick}
                />
              </ToolbarItem>
              <ToolbarItem>
                <SafeInlineButton
                  normalStateLabel="Clear"
                  iconBefore={<ClearFormIcon label="clear"/>}
                  isDisabled={!this.state.dirty}
                  onConfirmation={this.onResetButtonClick}
                />
              </ToolbarItem>
            </ToolbarLeft>
          </ButtonGroup>
        </div>
      </React.Fragment>
    );
  };

}
