import React from 'react';
import './Card.css';
import adg from '../../../../commonbase/adg';
import IconButton from '../IconButton';
import { NoPermissionIcon, VisitIcon, VisitFilledIcon } from '../../icon/NamedIcons';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import StatementScore from '../../rubric/score/StatementScore';
import Tooltip from '@atlaskit/tooltip';
import widgetUtil from '../WidgetUtil';
import { CardDragInfo, ICard } from './BoardState';
import I18N from '../../../../shared/model/i18n/I18N';

interface Props extends ICard {
  onCardLinkClick: (card: ICard) => void
  onDragStart: (dragInfo: CardDragInfo) => void
}

interface State {
}

export default class Card extends React.Component<Props, State> {

  element: any = undefined;
  dragImageElementsToRemove: string[] = [];

  onDragStart = (event) => {
    const cardElement = event.currentTarget;
    if (cardElement) {
      const clonedCard = cardElement.cloneNode(true);
      clonedCard.style.width = `${cardElement.clientWidth}px`;
      clonedCard.style.height = `${cardElement.clientHeight}px`;
      clonedCard.style.transform = `rotate(4deg)`;
      const dragImage = document.createElement('div');
      dragImage.appendChild(clonedCard);
      dragImage.id = 'drag-image-' + new Date().getTime();
      dragImage.style.position = "absolute";
      dragImage.style.bottom = "-2000px";
      dragImage.style.right = "-2000px";
      document.body.appendChild(dragImage);
      if (event.nativeEvent) {
        event.dataTransfer.setDragImage(dragImage, event.nativeEvent.offsetX, event.nativeEvent.offsetY);
      }
      // Firefox breaks if event.dataTransfer.setData("text/plain", dragImage.id) is called.
      event.dataTransfer.effectAllowed = 'move';
      this.dragImageElementsToRemove.push(dragImage.id);
    }
    if (this.props.onDragStart) {
      const dragInfo: CardDragInfo = {
        cardAssessmentUuid: this.props.assessmentUuid
      }
      this.props.onDragStart(dragInfo);
    }
  }

  onDragEnd = (event) => {
    event.preventDefault();
  }

  onDrop = (event) => {
    this.dragImageElementsToRemove.map((dragImageId) => {
      if (dragImageId) {
        const dragImage = document.getElementById(dragImageId);
        if (dragImage) {
          dragImage.remove();
        }
      }
      return undefined;
    });
    this.dragImageElementsToRemove = [];
  }

  onCardLinkClick = (event: any) => {
    const card: ICard = this.props;
    this.props.onCardLinkClick(card);
  }

  renderLockIconButton = () => {
    return (
      <div className="card-button-container">
        <Tooltip content={`You do not have permission to change the status of this ${I18N.assessment}`}>
          <IconButton
            normalIcon={<NoPermissionIcon label="no permission"/>}
            hoverIcon={<NoPermissionIcon label="no permission"/>}
            normalColor={adg.adgRed}
            hoverColor={adg.adgRed}
            onClick={(event) => {
            }}
          />
        </Tooltip>
      </div>
    );
  };

  renderViewIconButton = () => {
    return (
      <div className="card-button-container card-view-button-container">
        <Tooltip content={`View this ${I18N.assessment}`}>
          <IconButton
            normalIcon={<VisitIcon label="visit"/>}
            hoverIcon={<VisitFilledIcon label="visit"/>}
            normalColor={adg.adgBlue}
            hoverColor={adg.adgBlue}
            onClick={this.onCardLinkClick}
          />
        </Tooltip>
      </div>
    );
  };

  renderAssessmentScoreDetails = () => {
    if (this.props.scoreMeta && this.props.assessmentScore) {
      const assessmentItem = scoreUtil.buildAssessmentItemFromScore(this.props.assessmentScore);
      return (
        <StatementScore
          scoreMeta={this.props.scoreMeta}
          assessmentItem={assessmentItem}
          radius={20}
        />
      );
    } else {
      return (
        <div>

        </div>
      );
    }
  };

  renderDescription = () => {
    if (this.props.description) {
      const descriptionHtml = widgetUtil.markdownToHtml(this.props.description);
      return (
        <Tooltip content={`Drag to another column to change the status of this ${I18N.assessment}`}>
          <div
            className="card-description"
            dangerouslySetInnerHTML={{__html: descriptionHtml}}
          >
          </div>
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  renderLink = () => {
    const props = this.props;
    const renderedLink = (
      <a
        className="card-link"
        href="/#"
        title={`View this ${I18N.assessment}`}
        onClick={this.onCardLinkClick}
      >
        <span className="card-assessment-name">{props.cardSubtitle}</span>
        {': '}
        <span className="card-definition-name">{props.cardTitle}</span>
      </a>
    );
    return renderedLink;
  }

  render() {
    const props = this.props;
    const className = props.allowDrag ? `card card-draggable` : `card card-not-draggable`;
    return (
      <div
        ref={(element => {this.element = element})}
        className={className}
        draggable={props.allowDrag}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDrop={this.onDrop}
      >
        <div className="card-floating-score">
          {this.renderAssessmentScoreDetails()}
        </div>
        <div
          className="card-title"
        >
          {this.props.allowDrag ? null : this.renderLockIconButton()}
          {this.renderViewIconButton()}
          {this.renderLink()}
        </div>
        <div className="card-description">
          {this.renderDescription()}
        </div>
      </div>
    )
  };

}