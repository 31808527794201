import confluenceConnectUtil from './ConfluenceConnectUtil';

class ConfluenceMacroUtil {

  getStringMacroParameter = (macroParams, parameterName, defaultValue) => {
    return confluenceConnectUtil.getStringMacroParameter(macroParams, parameterName, defaultValue);
  };

  getBooleanMacroParameter = (macroParams, parameterName, defaultValue) => {
    return confluenceConnectUtil.getBooleanMacroParameter(macroParams, parameterName, defaultValue);
  };

}

export default new ConfluenceMacroUtil();
