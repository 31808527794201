import authUtil from '../auth/AuthUtil';
import session from '../auth/Session';

class FeatureFlagsUtil {

  isInDevMode = () => {
    return window.location.host === 'localhost:3000';
  }

  isUserIn = (userIds) => {
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      const currentUserId = currentUser.getId();
      for (const userId of userIds) {
        if (userId === currentUserId) {
          return true;
        }
      }
    }
    return false;
  }

  isAtlassianUser = () => {
    return authUtil.isAtlassianUser();
  };

}

export default new FeatureFlagsUtil();
