const scoreTypes = {
  // The string values must map to values in definition.options.scoreMetaType
  standard: 'standard',
  levels: 'levels',
  yesOrNo: 'yesOrNo',
  passOrFail: 'passOrFail',
  unsatisfactoryToStrong: 'unsatisfactoryToStrong',
  badOkGood: 'badOkGood',
  poorToStrong: 'poorToStrong',
  limitedToExcellent: 'limitedToExcellent',
  zeroToTen: 'zeroToTen',
  customLevels: 'customlevels'
}

module.exports = scoreTypes;
