import util from '../../util/Util';
import IUser from '../auth/IUser';
import Contact from './Contact';

export default class ContactBuilder {

  contact: Contact;

  constructor() {
    this.contact = {
      uuid: util.uuid(),
      name: '',
      email: ''
    };
  }

  setContact = (contact: Contact): ContactBuilder => {
    this.contact = contact;
    return this;
  };

  setUser = (user: IUser): ContactBuilder => {
    this.contact.name = user.getDisplayName();
    this.contact.email = user.getEmail();
    return this;
  };

  setUuid = (uuid: string): ContactBuilder => {
    this.contact.uuid = uuid;
    return this;
  };

  setName = (name: string): ContactBuilder => {
    this.contact.name = name;
    return this;
  };

  setEmail = (email: string): ContactBuilder => {
    this.contact.email = email;
    return this;
  };

  build = (): Contact => {
    return this.contact;
  }

}
