
class AppViewState {

  appContainerLeftX = 0;
  appContainerWidth = -1;

  setAppContainerLeftX = (appContainerLeftX) => {
    this.appContainerLeftX = appContainerLeftX;
  };

  setAppContainerWidth = (appContainerWidth) => {
    this.appContainerWidth = appContainerWidth;
  };

  getAppContainerLeftX = () => {
    return this.appContainerLeftX;
  };

  getAppContainerWidth = () => {
    return this.appContainerWidth;
  };

}

export default new AppViewState();
