import constants from '../../../../shared/model/Constants';
import currentNotifications from '../../../../backend/data/CurrentNotifications';
import discoverabilityDefinitions from '../../../../shared/model/rubric/DiscoverabilityDefinitions';
import session from '../../../../shared/model/auth/Session';
import NotificationBuilder from "../../../../shared/model/rubric/NotificationBuilder";
import shareStateDefinitions from "../../../../shared/model/rubric/ShareStateDefinitions";
import entityTypeDefinitions from "../../../../shared/model/rubric/EntityTypeDefinitions";
import PermissionCheckResult from '../../../../shared/model/auth/PermissionCheckResult';
import ShareFormBase from './ShareFormBase';
import notificationDAO from "../../../../backend/data/NotificationDAO";
import { ShareFormProps } from './ShareFormBase';
import userUtil from '../../../../shared/model/auth/UserUtil';

export default class ShareRubricForm extends ShareFormBase {

  allowSharingToGoogleClassroom = () => {
    return true;
  };

  buildContactPickerContext = () => {
    return undefined;
  };

  allowContactForPicker = (contact) => {
    return true;
  };

  buildGoogleClassroomTitle = (props: ShareFormProps) => {
    return `${props.definition.name}`;
  };

  buildEmailSubject = (props) => {
    return `${constants.appTitle} rubric: ${props.definition.name}`;
  };

  buildDefaultInAppSharingMessage = (props) => {
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      const firstName = userUtil.guessFirstName(currentUser);
      // const link = constants.appBaseUrl + '/#' + navConstants.viewPageHash + '/:' + props.definition.uuid + '/:' + props.definition.name;
      const message =
        `Hi,
Please take a look at this "${props.definition.name}" rubric.
To view it, click the view button in the toolbar above. 
${firstName}.`;
      return message;
    } else {
      return '';
    }
  };

  buildDefaultEmailSharingMessage = (props) => {
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      const firstName = userUtil.guessFirstName(currentUser);
      const url = this.buildDefaultEmailSharingUrl(props);
      const message =
        `Hi,
Please take a look at this "${props.definition.name}" rubric.
To view this, click on the following link:
${url} 
${firstName}.`;
      return message;
    } else {
      return '';
    }
  };

  checkSharingPermission = (): Promise<PermissionCheckResult> => {
    return new Promise((resolve, reject) => {
      // TODO: Could make this a setting.
      // const allowAnyoneToShareRubrics = true;
      // const canAdmin = permissionUtil.canWriteDefinition(this.props.definition);
      // if (allowAnyoneToShareRubrics || canAdmin) {
        const isPrivate = discoverabilityDefinitions.isPrivate(this.props.definition.discoverability);
        if (isPrivate) {
          const result = new PermissionCheckResult(
            false, 'This rubric can not be shared because it is private.');
          resolve(result);
        } else {
          const result = new PermissionCheckResult(true, '');
          resolve(result);
        }
      // } else {
      //   const result = new PermissionCheckResult(
      //     false, 'This rubric can not be shared because you do not have sufficient privileges.');
      //   resolve(result);
      // }
    });
  };

  onSumbitShareInAppForm = (message: string) => {
    const targetUserContact = this.state.targetUserContact;
    const currentUser = session.getCurrentUser();
    if (targetUserContact && targetUserContact && currentUser) {
      const targetUserEmail = targetUserContact.email;
      const currentUserId = currentUser.getId();
      const definition = this.props.definition;
      const entityUuid = definition.uuid;
      const entityTitle = `${definition.name}`;
      const sharedDefinition = new NotificationBuilder()
        .setFromUserId(currentUserId)
        .setTargetUserEmail(targetUserEmail)
        .setShareState(shareStateDefinitions.outstandingShareState)
        .setTitle(entityTitle)
        .setMessage(message)
        .setDefinitionUuid(definition.uuid)
        .setDefinitionName(definition.name)
        .setEntityUuid(entityUuid)
        .setEntityType(entityTypeDefinitions.definitionEntityType)
        .build();
      const fromUserId = currentUserId;
      notificationDAO.doesNotificationExist(
        fromUserId, targetUserEmail, entityTypeDefinitions.definitionEntityType, entityUuid
      ).then((notificationExists) => {
        if (notificationExists) {
          const alreadySharedUserEmails = this.state.alreadySharedUserEmails.concat(targetUserEmail);
          this.setState({
            alreadySharedUserEmails: alreadySharedUserEmails
          });
          this.popupDuplicateNotificationWarning(targetUserEmail);
        } else {
          notificationDAO.saveNotification(sharedDefinition).then(() => {
            currentNotifications.rebuildCurrentNotifications();
            this.onSharingComplete();
          });
        }
      });
    }
  };

  getShareButtonLabel = () => {
    return 'Share rubric';
  }

}