
export default interface ConfluenceExportScoreMeta {

  // getMarkerNames: () => string[];
  
  // scoreToEmojiStorage: (scorePercent: number) => string;
  appendScore: (builder, scorePercent: number) => void;
  appendScoreGuideLabel: (builder, scoreGuide: string) => void;

}

export class StandardConfluenceExportScoreMeta implements ConfluenceExportScoreMeta {

  // getMarkerNames = (): string[] => {
  //   return ['Inadequate or painful', 'Just OK', 'Pleasant / works well', 'Awesome / delightful'];
  // }

  // scoreToEmojiStorage = (scorePercent: number): string => {
  //   let emojiHtml = '';
  //   if (scorePercent === undefined) {
  //     emojiHtml = `<ac:emoticon ac:name="blue-star" ac:emoji-shortname=":ghost:" ac:emoji-id="1f47b" ac:emoji-fallback="👻" />`;
  //   } else if (scorePercent >= 80) {
  //     emojiHtml = `<ac:emoticon ac:name="laugh" ac:emoji-shortname=":grinning:" ac:emoji-id="1f600" ac:emoji-fallback="😀" />`;
  //   } else if (scorePercent >= 60) {
  //     emojiHtml = `<ac:emoticon ac:name="smile" ac:emoji-shortname=":slight_smile:" ac:emoji-id="1f642" ac:emoji-fallback="🙂" />`;
  //   } else if (scorePercent >= 40) {
  //     emojiHtml = `<ac:emoticon ac:name="blue-star" ac:emoji-shortname=":expressionless:" ac:emoji-id="1f611" ac:emoji-fallback="😑" />`;
  //   } else if (scorePercent >= 20) {
  //     emojiHtml = `<ac:emoticon ac:name="blue-star" ac:emoji-shortname=":disappointed:" ac:emoji-id="1f61e" ac:emoji-fallback="😞" />`;
  //   } else {
  //     emojiHtml = `<ac:emoticon ac:name="blue-star" ac:emoji-shortname=":frowning2:" ac:emoji-id="2639" ac:emoji-fallback="☹" />`;
  //   }
  //   return emojiHtml;
  // }

  appendScore = (builder, scorePercent: number): void => {
    /*
    0:   Poor    0 - 20
    33:  Weak   20 - 50
    67:  Good   50 - 80
    100: Strong 80 - 100
    */
    // const prefix = includeValue ? `${}` : ``;
    if (scorePercent === undefined) {
      this.appendScoreGuideLabel(builder, undefined);
    } else if (scorePercent >= 80) {
      this.appendScoreGuideLabel(builder, 'Strong');
    } else if (scorePercent >= 50) {
      this.appendScoreGuideLabel(builder, 'Good');
    } else if (scorePercent >= 20) {
      this.appendScoreGuideLabel(builder, 'Weak');
    } else {
      this.appendScoreGuideLabel(builder, 'Poor');
    }
  }

  appendScoreGuideLabel = (builder, scoreGuide: unknown | string) => {
    if (!scoreGuide) {
      builder.status('?', 'Grey');
    } else if (scoreGuide === 'Poor') {
      builder.status(scoreGuide, 'Red');
    } else if (scoreGuide === 'Weak') {
      builder.status(scoreGuide, 'Yellow');
    } else if (scoreGuide === 'Good') {
      builder.status(scoreGuide, 'Blue');
    } else if (scoreGuide === 'Strong') {
      builder.status(scoreGuide, 'Green');
    } else {
      builder.status(scoreGuide, 'Red');
    }
  }

}