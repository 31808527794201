import roleDefinitions from './RoleDefinitions';
import networkConstants from '../network/NetworkConstants';

class RoleUtil {

  separator: string;

  constructor() {
    this.separator = '>>>>';
  }

  /*
       Example roles array:
       [
          *>>>>writer,
          *>>>>reader,
          *@bar.com>>>>writer,
          *@bar.com>>>>reader,
          foo@gmail.com>>>>admin,
          foo@gmail.com>>>>writer,
          foo@gmail.com>>>>reader
       ]
   */

  getRoleByEmailAndDomain = (flattenedRolesArray, email, emailDomain) => {
    const emailsToRoles = this.unflattenRolesArray(flattenedRolesArray);
    const allUsersRole = emailsToRoles[networkConstants.everyoneEmailAddress];
    const emailDomainRole = emailsToRoles['*@' + emailDomain];
    const emailRole = emailsToRoles[email];
    const mostPrivilegedRole = this._getMostPrivilegedRole(allUsersRole, this._getMostPrivilegedRole(emailDomainRole, emailRole));
    return mostPrivilegedRole;
  };

  /**
   * Sets a role, but also adds implied roles. e.g. admin implies write which
   * implies reader.
   */
  setRoleByEmail = (flattenedRolesArray, email, role) => {
    this.removeRolesByEmail(flattenedRolesArray, email);
    this._addRoleAndImpliedRoles(flattenedRolesArray, email, role);
  };

  removeRolesByEmail = (flattenedRolesArray, email) => {
    for (let i = flattenedRolesArray.length - 1; i >=0; i--) {
      const flattenedRole = flattenedRolesArray[i];
      const roleInfo = this.parseFlattenedRole(flattenedRole);
      if (roleInfo.email.trim() === email.trim()) {
        flattenedRolesArray.splice(i, 1);
      }
    }
  };

  unflattenRolesArray = (flattenedRolesArray) => {
    const emailsToRoles = {};
    for (let i = flattenedRolesArray.length - 1; i >=0; i--) {
      const flattenedRole = flattenedRolesArray[i];
      const roleInfo = this.parseFlattenedRole(flattenedRole);
      const existingRole = emailsToRoles[roleInfo.email];
      if (existingRole) {
        emailsToRoles[roleInfo.email] = this._getMostPrivilegedRole(existingRole, roleInfo.role);
      } else {
        emailsToRoles[roleInfo.email] = roleInfo.role;
      }
    }
    return emailsToRoles;
  };

  _getMostPrivilegedRole = (roleA, roleB) => {
    if (roleA && roleB) {
      if (roleDefinitions.typesToSuperiorityIndex[roleA] <= roleDefinitions.typesToSuperiorityIndex[roleB]) {
        return roleA;
      } else {
        return roleB;
      }
    } else if (roleA) {
      return roleA;
    } else {
      return roleB;
    }
  };

  parseFlattenedRole = (flattenedRole) => {
    const parts = flattenedRole.split(this.separator);
    return {
      email: parts[0],
      role: parts[1]
    }
  };

  _addRoleAndImpliedRoles = (flattenedRolesArray, email, role) => {
    const flattenedRole = email.trim() + this.separator + role.trim();
    flattenedRolesArray.push(flattenedRole);
    const impliedRole = roleDefinitions.getNextImpliedRoleType(role);
    if (impliedRole) {
      this._addRoleAndImpliedRoles(flattenedRolesArray, email, impliedRole);
    }
  };

}

export default new RoleUtil();
