import actions from '../../../../shared/actions/Actions';
import assessmentUtil from '../../../../shared/model/rubric/score/AssessmentUtil';
import constants from '../../../../shared/model/Constants';
import dashboardDAO from '../../../../backend/data/DashboardDAO';
import definitionSearch from '../../../../shared/model/rubric/search/DefinitionSearch';
import firestoreAssessmentDAO from '../../../../backend/data/FirestoreAssessmentDAO';
import ListenerGroup from '../../../../shared/util/ListenerGroup';
import rubricDAO from "../../../../backend/data/RubricDAO";
import session from '../../../../shared/model/auth/Session';
import util from '../../../../shared/util/Util';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import statusDefinitions from '../../../../shared/model/rubric/status/StatusDefinitions';
import permissionUtil from '../../../../shared/model/auth/PermissionUtil';
import IUser from '../../../../shared/model/auth/IUser';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
// import ExtendedRubricReference from '../../../../shared/model/rubric/ExtendedRubricReference';
import { SearchOptions } from '../../../../shared/model/rubric/search/SearchOptions';
import DefinitionSearchResult from '../../../../shared/model/rubric/definition/DefinitionSearchResult';
import ResolvedDefinitionSearchResult from '../../../../shared/model/rubric/definition/ResolvedDefinitionSearchResult';
import DashboardInfo, { AssessmentInfo, DefinitionInfo } from '../../../../shared/model/rubric/dashboard/DashboardInfo';

const defaultState: DashboardInfo = {
  definitionInfoLog: [],
  assessmentInfoLog: [],
  recommendedDefinitionInfos: [],
  lastRecommendedDefinitionsSearchTime: 0,
  ownerIsMe: false
};

const maxDefinitionItems = 24;
const maxAssessmentItems = 24;
const recommendedDefinitionsSearchIntervalMillis = constants.millisPerDay;

class DashboardState {

  listenerGroup = new ListenerGroup('DashboardState');
  _debouncedSaveState: any;
  _debouncedNotifyListeners: any;
  enabled = false;
  assessmentDAO = firestoreAssessmentDAO;
  receivedData = false;
  state: DashboardInfo = util.deepCloneObject(defaultState);
  user: undefined | IUser = session.getCurrentUser();
  recommendedDefinitionsSearchIntervalHandle: undefined | any = undefined;
  _stateLoading: boolean = false;

  constructor() {
    this._debouncedSaveState = util.debounce(this._saveState, 5000, false);
    this._debouncedNotifyListeners = util.debounce(this._notifyListeners, 5000, false);
  }

  enable = () => {
    if (this.enabled) {
      return;
    }
    this.enabled = true;
    session.registerListener(this._onSessionChange);
    actions.registerListener(this._onAction);
    const user = session.getCurrentUser();
    this._updatePeriodicDefinitionSearching();
    this._loadState(user);
    definitionSearch.addSearcher(this._searchDefinitionsNew);
  };

  disable = () => {
    session.unregisterListener(this._onSessionChange);
    actions.unregisterListener(this._onAction);
    this._cancelPeriodicDefinitionSearching();
    this.enabled = false;
  };
  
  _searchDefinitionsNew = async (currentUserId: string, searchOptions: SearchOptions): Promise<DefinitionSearchResult[]> => {
    const definitionSearchResults: DefinitionSearchResult[] = [];
    this.iterateDefinitionInfos((info: DefinitionInfo) => {
      if (info.definitionName) {
        if (definitionSearch.doesMatch(info.definitionName, searchOptions)) {
          const definitionResolver: () => Promise <undefined | Definition> = function() {
            return rubricDAO.getDefinitionByUuid(info.definitionUuid);
          }
          const definitionSearchResult = new ResolvedDefinitionSearchResult(
            info.definitionUuid, info.definitionName, definitionResolver
          );
          definitionSearchResults.push(definitionSearchResult);
        }
      }
      const stopIterating: boolean = searchOptions && searchOptions.maxResults > 0 && searchOptions.maxResults < definitionSearchResults.length;
      return stopIterating;
    });
    return definitionSearchResults;
  };

  getState = (): DashboardInfo => {
    return this.state;
  };

  setAssessmentStatus = (definitionUuid, assessmentUuid, statusId) => {
    return rubricDAO.getAssessmentByUuid(assessmentUuid)
      .then((assessment: undefined | Assessment) => {
        if (this.user && this.state && assessment) {
          rubricUtil.setAssessmentStatus(assessment, statusId);
          this._updateAssessmentStatusByUuid(assessmentUuid, statusId);
          return this._saveState()
            .then(() => {
              const assessmentItem = this._findAssessmentItemByAssessmentUuid(assessmentUuid);
              if (assessmentItem) {
                return assessmentUtil.saveAssessment(this.assessmentDAO, assessment)
              }
              return undefined;
            });
        }
        return undefined;
      });
  };

  iterateDefinitionInfos = (callback: (info: DefinitionInfo) => boolean) => {
    if (this.state && this.state.definitionInfoLog) {
      for (const definitionInfo of this.state.definitionInfoLog) {

        // if (definitionInfo.definitionName && definitionInfo.definitionName.indexOf('empty') >= 0) {
        //   console.log(definitionInfo);
        //   debugger;
        // }

        const stopIterating = callback(definitionInfo);
        if (stopIterating) {
          break;
        }
      }
    }
  };

  hasReceivedData = () => {
    return this.receivedData;
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  // Implementation...

  _onSessionChange = (user: undefined | IUser) => {
    this.user = user;
    this._loadState(user)
      .then(() => {
        this._updatePeriodicDefinitionSearching();
      });
  };

  _updatePeriodicDefinitionSearching = () => {
    const user = session.getCurrentUser();
    if (user) {
      if (this.recommendedDefinitionsSearchIntervalHandle) {
        this._cancelPeriodicDefinitionSearching();
      }
      this.recommendedDefinitionsSearchIntervalHandle = setInterval(
        this._searchRecommendedDefinitions, recommendedDefinitionsSearchIntervalMillis);
      this._searchRecommendedDefinitions();
    } else {
      this._cancelPeriodicDefinitionSearching();
    }
  };

  _cancelPeriodicDefinitionSearching = () => {
    if (this.recommendedDefinitionsSearchIntervalHandle) {
      clearInterval(this.recommendedDefinitionsSearchIntervalHandle);
      this.recommendedDefinitionsSearchIntervalHandle = undefined;
    }
  };

  _searchRecommendedDefinitions = () => {
    const user = session.getCurrentUser();
    if (user) {
      const currentUserId = user.getId();
      const maxResults = 24;
      const currentTime = new Date().getTime();
      const lastRecommendedDefinitionsSearchTime = this.state.lastRecommendedDefinitionsSearchTime;
      const isDueToRun =
        this.receivedData &&
        (!lastRecommendedDefinitionsSearchTime ||
        (lastRecommendedDefinitionsSearchTime + recommendedDefinitionsSearchIntervalMillis) < currentTime);
      if (isDueToRun) {
        this.state.lastRecommendedDefinitionsSearchTime = currentTime;
        rubricDAO.searchRecommendedDefinitions(currentUserId, maxResults)
          .then((definitions) => {
            if (this.enabled && this.state) {
              const recommendedDefinitionInfos: any[] = [];
              for (const definition of definitions) {
                if (!this._isInRecentAccesses) {
                  const info = this._definitionToInfo(definition);
                  recommendedDefinitionInfos.push(info);  
                }
              }
              this.state.recommendedDefinitionInfos = recommendedDefinitionInfos;
              this._debouncedSaveState();
            }
          });
      }
    }
  };

  _isInRecentAccesses = (definition, recentAccesses) => {
    for (const recentAccess of recentAccesses) {
      if (recentAccess.definitionUuid === definition.uuid) {
        return true;
      }
    }
    return false;
  };

  _loadState = (user: undefined | IUser) => {
    // if (this._stateLoading) {
    //   return
    // }
    if (!this._stateLoading && user) {
      this._stateLoading = true;
      const currentTime = new Date().getTime();
      const oneDayInMilliseconds = constants.millisPerDay;
      const minLastUpdateTimeBeforeRefreshing = currentTime - oneDayInMilliseconds
      const userId = user.getId();
      return dashboardDAO.getState(userId)
        .then((state) => {
          this._stateLoading = false;
          this.receivedData = true;
          setTimeout(() => {
            this._updatePeriodicDefinitionSearching();
          }, 10000);
          if (state) {
            state = this._initialiseState(state);
            const doSchemaMigration =
              (state.lastUpdateTimestamp && state.lastUpdateTimestamp < minLastUpdateTimeBeforeRefreshing);
            if (doSchemaMigration) {
              this._updateSchema(user, state)
                .then(() => {
                  state.lastUpdateTimestamp = currentTime;
                  this._saveState();
                });
            } else {
              this._debouncedNotifyListeners();
            }
          } else {
            // this._initialiseState({});
            this._initialiseState(defaultState);
          }
          this.user = user;
        })
        .catch(() => {
          this._stateLoading = false;
          // this._initialiseState({});
          this._initialiseState(defaultState);
        });
    } else {
      this.user = undefined;
      this.state = this.state ? this.state : util.deepCloneObject(defaultState);
      return new Promise((resolve, reject) => {
        resolve(undefined);
      });
    }
  };

  _initialiseState = (state: DashboardInfo): DashboardInfo => {
    const fullEmptyState = util.deepCloneObject(defaultState);
    const fullState = Object.assign(fullEmptyState, state);
    this.state = fullState;
    return this.state;
  };

  _saveState = () => {
    return new Promise((resolve, reject) => {
      if (this.user) {
        const userId = this.user.getId();
        dashboardDAO.saveState(userId, this.state)
          .then(() => {
            this._debouncedNotifyListeners();
            resolve(undefined);
          });
      } else {
        resolve(undefined);
      }
    });
  };

  _onAction = (actionId, context) => {
    if (this.enabled) {
      if (actionId === actions.visitDefinitionActionId) {
        this._logDefinitionVisit(context);
      } else if (actionId === actions.viewAssessmentActionId) {
        this._logAssessmentView(context);
      } else if (actionId === actions.assessmentChangeActionId) {
        // Ignore - don't save too often
      } else if (actionId === actions.assessmentLeaveActionId) {
        this._logAssessmentView(context);
      } else if (actionId === actions.assessmentRenamedActionId) {
        this._logAssessmentView(context);
      } else if (actionId === actions.removeDefinitionReferencesActionId) {
        this._removeDefinition(context);
        this._removeAssessmentsByDefinitionUuid(context);
        this._debouncedSaveState();
      } else if (actionId === actions.removeAssessmentReferencesActionId) {
        this._removeAssessment(context);
      } else if (actionId === actions.definitionRenamedActionId) {
        this._logDefinitionVisit(context);
      } else if (actionId === actions.definitionRenamedActionId) {
        this._logAssessmentView(context);
      }
    }
  };

  _logDefinitionVisit = (definition: Definition) => {
    if (this.user && this.state) {
      const info = this._definitionToInfo(definition);
      info.lastVisitTime = new Date().getTime();
      this._removeDefinition(info.definitionUuid);
      this.state.definitionInfoLog.unshift(info);
      if (this.state.definitionInfoLog.length > maxDefinitionItems) {
        this.state.definitionInfoLog.splice(-1, 1);
      }
      this._debouncedSaveState();
    }
  };

  _definitionToInfo = (definition: Definition): DefinitionInfo => {
    const currentTimestamp = new Date().getTime();
    const scoreMetaType = rubricUtil.getScoreMetaType(definition);
    const customLevelsScoreInfo = rubricUtil.getCustomLevelsScoreInfo(definition);

    // const scoreMetaType = assessmentContext.scoreMetaType;
    // const customLevelsScoreInfo = rubricUtil.getCustomLevelsScoreInfo(definition);
    const info: DefinitionInfo = {
      definitionUuid: definition.uuid,
      definitionName: definition.name,
      definitionDescription: definition.description,
      definitionOwnerId: definition.ownerId,
      scoreMetaType: scoreMetaType,
      lastSyncTimestamp: currentTimestamp
    };
    if (customLevelsScoreInfo) {
      info.customLevelsScoreInfo = customLevelsScoreInfo;
    }
    return info;
  };

  _logAssessmentView = (assessmentContext) => {
    if (this.user && this.state) {
      const info = this._getAssessmentItem(assessmentContext);
      info.lastVisitTime = new Date().getTime();
      this._removeAssessment(info.assessmentUuid);
      this.state.assessmentInfoLog.unshift(info);
      if (this.state.assessmentInfoLog.length > maxAssessmentItems) {
        this.state.assessmentInfoLog.splice(-1, 1);
      }
      this._debouncedSaveState();
    }
  };

  _updateAssessmentStatusByUuid = (assessmentUuid, statusId) => {
    for (const assessmentItem of this.state.assessmentInfoLog) {
      if (assessmentItem.assessmentUuid === assessmentUuid) {
        assessmentItem.assessmentStatusId = statusId;
      }
    }
  };

  _removeDefinition = (definitionUuid) => {
    for (let index = this.state.definitionInfoLog.length - 1; index >= 0; index--) {
      const info = this.state.definitionInfoLog[index];
      if (info.definitionUuid === definitionUuid) {
        this.state.definitionInfoLog.splice(index, 1);
      }
    }
  };

  _removeAssessment = (assessmentUuid) => {
    for (let index = this.state.assessmentInfoLog.length - 1; index >= 0; index--) {
      const info = this.state.assessmentInfoLog[index];
      if (info.assessmentUuid === assessmentUuid) {
        this.state.assessmentInfoLog.splice(index, 1);
      }
    }
  };

  _removeAssessmentsByDefinitionUuid = (definitionUuid) => {
    for (let index = this.state.assessmentInfoLog.length - 1; index >= 0; index--) {
      const info = this.state.assessmentInfoLog[index];
      if (info.definitionUuid === definitionUuid) {
        this.state.assessmentInfoLog.splice(index, 1);
      }
    }
  };

  _findDefinitionInfoByDefinitionUuid = (definitionUuid) => {
    for (const definitionInfo of this.state.definitionInfoLog) {
      if (definitionInfo.definitionUuid === definitionUuid) {
        return definitionInfo;
      }
    }
  };

  _findAssessmentItemByAssessmentUuid = (assessmentUuid) => {
    for (const assessmentItem of this.state.assessmentInfoLog) {
      if (assessmentItem.assessmentUuid === assessmentUuid) {
        return assessmentItem;
      }
    }
  };

  getAssessmentScoreDetails = (definitionUuid, assessmentUuid) => {
    const definitionInfo = this._findDefinitionInfoByDefinitionUuid(definitionUuid);
    const assessmentItem = this._findAssessmentItemByAssessmentUuid(assessmentUuid);
    if (definitionInfo && assessmentItem) {
      const details: any = {
        scoreMetaType: definitionInfo.scoreMetaType,
        customLevelsScoreInfo: definitionInfo.customLevelsScoreInfo
      }
      const assessmentScore = assessmentItem.assessmentScore;
      if (assessmentScore !== undefined) {
        details.assessmentScore = assessmentScore;
      }
      return details;
    }
    return undefined;
  };

  _getAssessmentItem = (assessmentContext): AssessmentInfo => {
    const definition = assessmentContext.definition;
    const assessment = assessmentContext.assessment;
    const assessmentScore = assessmentContext.assessmentScore;
    const currentTimestamp = new Date().getTime();
    const hasWritePermission = permissionUtil.canWriteAssessment(assessment);
    const statusId = assessment.statusId ? assessment.statusId : statusDefinitions.getDefaultStatusId();
    const info: AssessmentInfo = {
      definitionUuid: definition.uuid,
      definitionName: definition.name,
      definitionDescription: definition.description,
      definitionOwnerId: definition.ownerId,
      assessmentUuid: assessment.uuid,
      assessmentName: assessment.name,
      assessmentStatusId: statusId,
      hasWriteAssessmentPermission: hasWritePermission,
      lastSyncTimestamp: currentTimestamp
    };
    if (assessmentScore !== undefined) {
      info.assessmentScore = assessmentScore;
    }
    return info;
  };

  _updateSchema = (user, state) => {
    const definitionPromises: Promise<undefined | Definition>[] = [];
    const definitionUuidsToDefinitions = {};
    for (const definitionInfo of state.definitionInfoLog) {
      const definitionPromise = rubricDAO.getDefinitionByUuid(definitionInfo.definitionUuid)
        .then((definition: undefined | Definition) => {
          if (definition) {
            definitionUuidsToDefinitions[definition.uuid] = definition;
            this._updateDefinitionInfoSchema(definitionInfo, definition);
          }
          return definition;
        });
      definitionPromises.push(definitionPromise);
    }
    return Promise.all(definitionPromises)
      .then(() => {
        const assessmentPromises: Promise<undefined | Assessment>[] = [];
        for (const assessmentItem of state.assessmentInfoLog) {
          const assessmentPromise = rubricDAO.getAssessmentByUuid(assessmentItem.assessmentUuid)
            .then((assessment: undefined | Assessment) => {
              if (assessment) {
                this._updateAssessmentItemSchema(assessmentItem, assessment, definitionUuidsToDefinitions);
              }
              return assessment;
            });
            assessmentPromises.push(assessmentPromise);
        }
        return Promise.all(assessmentPromises);    
      });
  };

  _updateDefinitionInfoSchema = (info, definition) => {
    const customLevelsScoreInfo = rubricUtil.getCustomLevelsScoreInfo(definition);
    info.scoreMetaType = rubricUtil.getScoreMetaType(definition);
    if (customLevelsScoreInfo) {
      info.customLevelsScoreInfo = customLevelsScoreInfo;
    }
  };

  _updateAssessmentItemSchema = (info, assessment, definitionUuidsToDefinitions) => {
    const currentTimestamp = new Date().getTime();
    const hasWritePermission = permissionUtil.canWriteAssessment(assessment);
    const statusId = assessment.statusId ? assessment.statusId : statusDefinitions.getDefaultStatusId();
    info.assessmentStatusId = statusId;
    info.assessmentName = assessment.name;
    info.hasWriteAssessmentPermission = hasWritePermission;
    info.lastSyncTimestamp = currentTimestamp;
    const definition = definitionUuidsToDefinitions[info.definitionUuid];
    if (definition) {
      const assessmentScore = rubricUtil.computeOverallAssessmentScore(definition, assessment);
      if (assessmentScore) {
        info.assessmentScore = assessmentScore;
      }
    }
  };

  _notifyListeners = () => {
    this.listenerGroup.notifyListeners(this.state);
  };

}

export default new DashboardState();
