import React, { PureComponent } from "react";
import adg from '../../../commonbase/adg';
import Button from '@atlaskit/button';
import networkGraph from './NetworkGraph';
import AddContactModal from './AddContactModal';
import {
  AddContactIcon,
  DeleteContactIcon,
} from '../../components/icon/NamedIcons';
import LiftedPageTitle from '../../components/widget/LiftedPageTitle';
import LiftedPanel from '../../components/widget/LiftedPanel';
import SafePopupIconButton from "../widget/SafePopupIconButton";
import session from '../../../shared/model/auth/Session';

export default class NetworkView extends PureComponent {

  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = this.buildStateFromProps(props);
    const networkGraphState = networkGraph.getState();
    this._onNetworkGraphChange(networkGraphState);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    session.registerListener(this.onSessionChange);
  }

  componentDidMount() {
    this.mounted = true;
    networkGraph.registerListener(this._onNetworkGraphChange);
  }

  componentWillUnmount() {
    this.mounted = false;
    session.unregisterListener(this.onSessionChange);
    networkGraph.unregisterListener(this._onNetworkGraphChange);
  }

  onSessionChange = (user) => {
  };

  buildStateFromProps = (props) => {
    return {
    };
  };

  _onNetworkGraphChange = (networkGraphState) => {
    if (this.mounted) {
      this.setState({
        network: networkGraphState.network
      });
      this.forceUpdate();
    } else {
      const state = this.state;
      state.network = networkGraphState.network;
    }
  };

  onDeleteContactClick = (contact) => {
    networkGraph.deleteContactByUuid(contact.uuid);
    this.forceUpdate();
  };

  onCancelContactCreationClick = () => {
    this.setState({
      showCreateContactModal: false
    });
  };

  onContactCreated = () => {
    this.setState({
      showCreateContactModal: false
    });
  };

  onCreateContactClick = () => {
    this.setState({
      showCreateContactModal: true
    });
  };

  renderNetwork = () => {
    const contacts = [];
    networkGraph.iterateContacts((contact) => {
      contacts.push(contact);
    });
    contacts.sort((contactA, contactB) => {
      if (contactA.email.startsWith('*') || contactB.email.startsWith('*')) {
        return contactA.email.localeCompare(contactB.email);
      } else {
        return contactA.name.localeCompare(contactB.name);
      }
    });
    const renderedContacts = contacts.map((contact) => {
      return (
        <tr key={contact.uuid}>
          <td>
            {contact.name}
          </td>
          <td>
            {contact.email}
          </td>
          <td>
            <SafePopupIconButton
              confirmationMessage={`Are you sure you want to delete your contact "${contact.name}"?`}
              normalIcon={<DeleteContactIcon />}
              hoverIcon={<DeleteContactIcon />}
              tooltip={`Delete contact "${contact.name}"`}
              onConfirmation={() => this.onDeleteContactClick(contact)}
            />
          </td>
        </tr>
      );
    });
    return (
      <div className="newMinorSection">
        <table>
          <thead>
            <tr>
              <th>
                Name
              </th>
              <th>
                Email
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{borderBottomWidth: '0px'}}>
            {renderedContacts}
          </tbody>
        </table>
      </div>
    );
  };

  renderHeading = () => {
    return (
      <LiftedPageTitle>
        Network
      </LiftedPageTitle>
    );
  };

  showCreateContactModal = () => {
    return (
      <AddContactModal
        onCancelClick={this.onCancelContactCreationClick}
        onContactCreated={this.onContactCreated}
      />
    );
  };

  renderCreateContactButton = () => {
    return (
      <div className="newMinorSection">
        <Button
          appearance="primary"
          iconBefore={<AddContactIcon primaryColor={adg.white} secondaryColor={adg.adgPrimaryBlue}/>}
          isDisabled={false}
          onClick={() => this.onCreateContactClick()}
        >
            Create contact
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.showCreateContactModal ? this.showCreateContactModal() : null}
        {this.props.showHeading ? this.renderHeading() : null}
        <div className="newMinorSection">
        </div>
        <LiftedPanel>
          {this.renderCreateContactButton()}
          {this.state.network ? this.renderNetwork() : null}
        </LiftedPanel>
      </div>
    );
  }

}
