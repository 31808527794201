import RubricReference from './RubricReference';

export default class RubricReferenceBuilder {

  definitionUuid: string
  definitionName: string
  assessmentUuid?: string
  assessmentName?: string

  constructor() {
    this.definitionUuid = '';
    this.definitionName = '';
    this.assessmentUuid = undefined;
    this.assessmentName = undefined;
  }

  setDefinitionUuid = (definitionUuid: string) => {
    if (!definitionUuid) {
      throw new Error('definitionUuid must be set.');
    }
    this.definitionUuid = definitionUuid;
    return this;
  };

  setDefinitionName = (definitionName: string) => {
    if (!definitionName) {
      throw new Error('definitionName must be set.');
    }
    this.definitionName = definitionName;
    return this;
  };

  setAssessmentUuid = (assessmentUuid: string) => {
    this.assessmentUuid = assessmentUuid;
    return this;
  };

  setAssessmentName = (assessmentName: string) => {
    this.assessmentName = assessmentName;
    return this;
  };

  build = (): RubricReference => {
    if (!this.definitionUuid) {
      throw new Error('definitionUuid must be set.');
    }
    if (!this.definitionName) {
      throw new Error('definitionName must be set.');
    }
    const fileReference: RubricReference = {
      definitionUuid: this.definitionUuid,
      definitionName: this.definitionName
    }
    if (this.assessmentUuid && this.assessmentName) {
      fileReference.assessmentUuid = this.assessmentUuid;
      fileReference.assessmentName = this.assessmentName;
    }
    return fileReference;
  };

}

