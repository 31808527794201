const colorRangeUtil = require('./colorRangeUtil');

const thisColorRange = {
  startColorRgb: colorRangeUtil._colorToRgb('#4C9AFF'), // Arvo Breeze
  endColorRgb: colorRangeUtil._colorToRgb('#0747A6') // Chore Coat
};

thisColorRange.computeColor = function(factorFromStart) {
  return colorRangeUtil._computeColorInRange(thisColorRange.startColorRgb, thisColorRange.endColorRgb, factorFromStart);
};

module.exports = thisColorRange;
