import React, { PureComponent } from 'react';
import AssessmentView from '../components/rubric/report/AssessmentView';
import authUtil from '../../shared/model/auth/AuthUtil';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import ContentWrapper from '../components/widget/ContentWrapper';
import currentReport from '../../shared/model/rubric/CurrentReport';
import currentRubric from "../../shared/model/rubric/CurrentRubric";
import EditorBoldIcon from '@atlaskit/icon/glyph/editor/bold';
import EditorTextStyleIcon from '@atlaskit/icon/glyph/editor/text-style';
import IconButton from "../components/widget/IconButton";
import Label from '../components/widget/Label';
import LabelIcon from '@atlaskit/icon/glyph/label';
import LiftedPanel from '../components/widget/LiftedPanel';
import navUtil from "../../shared/util/UrlUtil";
import PageTitle from '../components/widget/PageTitle';
import permissionUtil from "../../shared/model/auth/PermissionUtil";
import preferencesDAO from '../../backend/data/PreferencesDAO';
import PromotionView from '../components/rubric/report/PromotionView';
import ReportView from '../components/rubric/report/ReportView';
import reportTypeDefinitions from '../../shared/model/rubric/ReportTypeDefinitions';
import ReportTypeSelector from '../components/rubric/report/ReportTypeSelector';
import rubricDAO from "../../backend/data/RubricDAO";
import rubricPageHelper from './RubricPageHelper';
import session from "../../shared/model/auth/Session";
import Tooltip from '@atlaskit/tooltip';
import VidConnectionCircleIcon from '@atlaskit/icon/glyph/vid-connection-circle';
import adg from "../../commonbase/adg";
import { ArrowLeftIcon, ArrowLeftCircleIcon } from '../components/icon/NamedIcons';
import ToolbarLeft from '../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../components/widget/toolbar/ToolbarItem';

const defaultPreferences = {
  includeCriteriaHeaders: true,
  includeColumnHeaders: true,
  includeStatements: true,
  includeAssessmentScores: true,
  showOnlyAssessedStatements: false,
  showOnlyColumnsWithScores: true,
  includeTotalScore: true,
  formatStatementsBold: true
};

export default class ReportPage extends PureComponent {

  constructor(props) {
    super(props);
    this.reportTypeKey = 'reportType';
    this.includeCriteriaHeadersKey = 'includeCriteriaHeaders';
    this.includeColumnHeadersKey = 'includeColumnHeaders';
    this.includeStatementsKey = 'includeStatements';
    this.includeAssessmentScoresKey = 'includeAssessmentScores';
    this.showOnlyAssessedStatementsKey = 'showOnlyAssessedStatements';
    this.includeTotalScoreKey = 'includeTotalScore';
    this.formatStatementsBoldKey = 'formatStatementsBold';
    this.state = {
      definition: undefined,
      assessment: undefined,
      reportType: reportTypeDefinitions.defaultType,
      preferences: {
        includeCriteriaHeaders: defaultPreferences.includeCriteriaHeaders,
        includeColumnHeaders: defaultPreferences.includeColumnHeaders,
        includeStatements: defaultPreferences.includeStatements,
        includeAssessmentScores: defaultPreferences.includeAssessmentScores,
        showOnlyAssessedStatements: defaultPreferences.showOnlyAssessedStatements,
        showOnlyColumnsWithScores: defaultPreferences.showOnlyColumnsWithScores,
        includeTotalScore: defaultPreferences.includeTotalScore,
        formatStatementsBold: defaultPreferences.formatStatementsBold
      }
    };
  }

  applyDefaultPreferences = (preferences) => {
    const keys = Object.keys(defaultPreferences);
    for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      if (preferences[key] === undefined) {
        preferences[key] = defaultPreferences[key];
      }
    }
  };

  loadPreferences = () => {
    const reportTypePreferencePromise = this.loadReportTypePreference();
    const customReportPreferencesPromise = this.loadCustomReportPreferences();
    Promise.all([reportTypePreferencePromise, customReportPreferencesPromise])
      .then((preferencesArray) => {
        this.preferencesLoaded = true;
        let reportType = preferencesArray[0];
        if (!reportType) {
          reportType = reportTypeDefinitions.defaultType;
        }
        const customPreferences = preferencesArray[1];
        if (customPreferences) {
          this.applyDefaultPreferences(customPreferences);
          this.setState({
            preferences: customPreferences
          });
        }
        if (reportType) {
          this.setReportType(reportType);
        }
      });
  };

  loadCustomReportPreferences = () => {
    const user = session.getCurrentUser();
    return preferencesDAO.getReportPreferences(user, this.state.preferences);
  };

  saveCustomReportPreferences = (preferences) => {
    const user = session.getCurrentUser();
    return preferencesDAO.setReportPreferences(user, preferences);
  };

  loadReportTypePreference = () => {
    const user = session.getCurrentUser();
    return preferencesDAO.getPreference(user, this.reportTypeKey);
  };

  saveReportTypePreference = (reportType) => {
    const user = session.getCurrentUser();
    return preferencesDAO.setPreference(user, this.reportTypeKey, reportType);
  };

  UNSAFE_componentWillMount() {
    const assessmentUuid = navUtil.getHashParameter(0);
    this.setAssessmentByUuid(assessmentUuid);
    this.loadPreferences();
  }

  componentDidMount() {
    rubricPageHelper.updateTitle('Report');
    currentReport.registerListener(this.onCurrentReportChange);
    session.registerListener(this.onSessionChange);
  };

  componentWillUnmount() {
    currentReport.unregisterListener(this.onCurrentReportChange);
    session.unregisterListener(this.onSessionChange);
  };

  onSessionChange = (user) => {
    this.loadPreferences();
  };

  onCurrentReportChange = (state) => {
    this.setAssessmentByUuid(state.assessmentUuid);
  };

  onViewRubric = () => {
    currentRubric.setDefinition(this.state.definition, false);
  };

  onReportTypeChange = (reportType) => {
    this.setReportType(reportType);
  };

  setReportType = (reportType) => {
    let preferences = this.state.preferences;
    if (reportType === reportTypeDefinitions.defaultType) {
      preferences = {
        includeCriteriaHeaders: defaultPreferences.includeCriteriaHeaders,
        includeColumnHeaders: defaultPreferences.includeColumnHeaders,
        includeStatements: defaultPreferences.includeStatements,
        includeAssessmentScores: defaultPreferences.includeAssessmentScores,
        showOnlyAssessedStatements: defaultPreferences.showOnlyAssessedStatements,
        showOnlyColumnsWithScores: defaultPreferences.showOnlyColumnsWithScores,
        includeTotalScore: defaultPreferences.includeTotalScore,
        formatStatementsBold: defaultPreferences.formatStatementsBold
      }
    } else if (reportType === reportTypeDefinitions.crispType) {
      preferences = {
        includeCriteriaHeaders: true,
        includeColumnHeaders: true,
        includeStatements: true,
        includeAssessmentScores: true,
        showOnlyAssessedStatements: true,
        showOnlyColumnsWithScores: true,
        includeTotalScore: true,
        formatStatementsBold: true
      }
    } else if (!this.preferencesLoaded && reportType === reportTypeDefinitions.customType) {
      this.loadPreferences();
    }
    this.saveReportTypePreference(reportType);
    this.setState({
      reportType: reportType,
      preferences: preferences
    });
  };

  onIncludeCriteriaHeadersToggle = () => {
    this.togglePreference(this.includeCriteriaHeadersKey);
  };

  onIncludeColumnHeadersToggle = () => {
    this.togglePreference(this.includeColumnHeadersKey);
  };

  onIncludeStatementsToggle = () => {
    this.togglePreference(this.includeStatementsKey);
  };

  onIncludeAssessmentScoresToggle = () => {
    this.togglePreference(this.includeAssessmentScoresKey);
  };

  onShowOnlyAssessedStatementsToggle = () => {
    this.togglePreference(this.showOnlyAssessedStatementsKey);
  };

  onIncludeTotalScoreToggle = () => {
    this.togglePreference(this.includeTotalScoreKey);
  };

  onFormatBoldStatementsToggle = () => {
    this.togglePreference(this.formatStatementsBoldKey);
  };

  togglePreference = (preferenceKey) => {
    const currentValue = this.state.preferences[preferenceKey];
    const newValue = !currentValue;
    const newPreferences = Object.assign({}, this.state.preferences);
    newPreferences[preferenceKey] = newValue;
    this.saveCustomReportPreferences(newPreferences);
    this.setState({preferences: newPreferences});
  };

  setAssessmentByUuid = (assessmentUuid) => {
    rubricDAO.getAssessmentByUuid(assessmentUuid).then((assessment) => {
      if (assessment) {
        const definitionUuid = assessment.definitionUuid;
        let definitionPromise = undefined;
        let currentDefinition = currentRubric.getDefinition();
        if (currentDefinition && currentDefinition.uuid === definitionUuid) {
          definitionPromise = new Promise((resolve, reject) => {
            resolve(currentDefinition);
          });
        } else {
          definitionPromise = rubricDAO.getDefinitionByUuid(definitionUuid);
        }
        definitionPromise.then((definition) => {
          const canReadDefinition = permissionUtil.canReadDefinition(definition);
          if (canReadDefinition) {
            const canReadAssessment = permissionUtil.canReadAssessment(assessment);
            if (canReadAssessment) {
              this.setState({
                definition: definition,
                assessment: assessment
              });
            } else {
              this.setState({
                errorMessage: 'It seems as though you do not have permission to read this assessment.'
              });
            }
            } else {
            this.setState({
              errorMessage: 'It seems as though you do not have permission to read this definition.'
            });
          }
        });
      } else {
        this.setState({
          errorMessage: 'It seems as though this assessment has been deleted.'
        });
      }
    });
  };

  renderViewRubricButton = () => {
    return (
      <Tooltip content="Back to this rubric">
        <ToolbarItem>
          <IconButton
            normalIcon={<ArrowLeftIcon/>}
            hoverIcon={<ArrowLeftCircleIcon/>}
            onClick={this.onViewRubric}
          />
        </ToolbarItem>
      </Tooltip>
    );
  };

  renderReportTypeLabel = () => {
    return (
      <ToolbarItem style={{marginRight: '4px'}}>
        <Label
          text="Report type:"
          paddingTop="4px"
        />
      </ToolbarItem>
    );
  };

  customReportTypeChecker = (options) => {
    if (this.state.definition) {
      if (authUtil.isAtlassianUser()) {
        if (this.isAnAtlassianGrowthProfile()) {
          reportTypeDefinitions.addAtlassianGrowthProfileItems(options);
        }
      }
    }
  };

  isAnAtlassianGrowthProfile = () => {
    const atlassianGrowthProfileUuids = [
      // Old formats
      'pascal-p',
      'ff238265-8057-861c-89b9-c712746c3052',
      'cb21f472-cf6c-1a44-080a-e506ac1107de', // PM IC Growth Profile - P-Levels
      '46fb64b8-52ee-b904-4a39-7771b810967b',  // PM Management Growth Profile - M-Levels

      // new formats
      '3032a153-eb62-b442-85ac-39c878a2b3b2', // Engineering P levels
    ];
    for (const atlassianGrowthProfileUuid of atlassianGrowthProfileUuids) {
      if (atlassianGrowthProfileUuid === this.state.definition.uuid) {
        return true;
      }
    }
    return false;
  };

  renderReportTypeSelector = () => {
    return (
      <ToolbarItem style={{width: '270px'}}>
        <ReportTypeSelector
          shouldFitContainer={true}
          customReportTypeChecker={this.customReportTypeChecker}
          reportType={this.state.reportType}
          onChange={this.onReportTypeChange}
        />
      </ToolbarItem>
    );
  };

  renderIncludeCriteriaHeadersButton = () => {
    const colors = this.computeIconColors(this.includeCriteriaHeadersKey);
    return (
      <Tooltip content="Include criteria headers">
        <ToolbarItem>
          <IconButton
            normalIcon={<EditorTextStyleIcon/>}
            hoverIcon={<EditorTextStyleIcon/>}
            normalColor={colors.normalColor}
            hoverColor={colors.hoverColor}
            onClick={this.onIncludeCriteriaHeadersToggle}
          />
        </ToolbarItem>
      </Tooltip>
    );
  };

  renderIncludeColumnHeadersButton = () => {
    const colors = this.computeIconColors(this.includeColumnHeadersKey);
    return (
      <Tooltip content="Include column headers">
        <ToolbarItem>
          <IconButton
            normalIcon={<LabelIcon/>}
            hoverIcon={<LabelIcon/>}
            normalColor={colors.normalColor}
            hoverColor={colors.hoverColor}
            onClick={this.onIncludeColumnHeadersToggle}
          />
        </ToolbarItem>
      </Tooltip>
    );
  };

  renderIncludeStatementsButton = () => {
    const colors = this.computeIconColors(this.includeStatementsKey);
    return (
      <Tooltip content="Include rubric statements">
        <ToolbarItem>
          <IconButton
            normalIcon={<CommentIcon/>}
            hoverIcon={<CommentIcon/>}
            normalColor={colors.normalColor}
            hoverColor={colors.hoverColor}
            onClick={this.onIncludeStatementsToggle}
          />
        </ToolbarItem>
      </Tooltip>
    );
  };

  renderIncludeAssessmentScoresButton = () => {
    const colors = this.computeIconColors(this.includeAssessmentScoresKey);
    return (
      <Tooltip content="Include assessment scores">
        <ToolbarItem>
          <IconButton
            normalIcon={<VidConnectionCircleIcon/>}
            hoverIcon={<VidConnectionCircleIcon/>}
            normalColor={colors.normalColor}
            hoverColor={colors.hoverColor}
            onClick={this.onIncludeAssessmentScoresToggle}
          />
        </ToolbarItem>
      </Tooltip>
    );
  };

  renderIncludeTotalScoreButton = () => {
    const colors = this.computeIconColors(this.includeTotalScoreKey);
    return (
      <Tooltip content="Include total score">
        <ToolbarItem>
          <IconButton
            normalIcon={<VidConnectionCircleIcon/>}
            hoverIcon={<VidConnectionCircleIcon/>}
            normalColor={colors.normalColor}
            hoverColor={colors.hoverColor}
            onClick={this.onIncludeTotalScoreToggle}
          />
        </ToolbarItem>
      </Tooltip>
    );
  };

  renderFormatBoldStatementsButton = () => {
    const colors = this.computeIconColors(this.formatStatementsBoldKey);
    return (
      <Tooltip content="Format statements as bold">
        <ToolbarItem>
          <IconButton
            normalIcon={<EditorBoldIcon/>}
            hoverIcon={<EditorBoldIcon/>}
            normalColor={colors.normalColor}
            hoverColor={colors.hoverColor}
            onClick={this.onFormatBoldStatementsToggle}
          />
        </ToolbarItem>
      </Tooltip>
    );
  };

  computeIconColors = (key) => {
    const isPreferenceSet = this.state.preferences[key] === true;
    const normalColor = isPreferenceSet ? adg.adgBlue : '#333';
    const hoverColor = isPreferenceSet ? '#333' : adg.adgBlue;
    return {normalColor: normalColor, hoverColor: hoverColor}
  };

  renderCustomReportToolbarItems = () => {
    return (
      <React.Fragment>
        {this.renderIncludeCriteriaHeadersButton()}
        {this.renderIncludeColumnHeadersButton()}
        {this.renderIncludeStatementsButton()}
        {this.renderIncludeAssessmentScoresButton()}
        {this.renderIncludeTotalScoreButton()}
        {this.renderFormatBoldStatementsButton()}
      </React.Fragment>
    );
  };

  renderToolbar = () => {
    const isCustomReport = this.state.reportType === reportTypeDefinitions.customType;
    return (
      <ToolbarLeft>
        {this.renderViewRubricButton()}
        {this.renderReportTypeLabel()}
        {this.renderReportTypeSelector()}
        {isCustomReport ? this.renderCustomReportToolbarItems() : null}
      </ToolbarLeft>
    );
  };

  renderReport = () => {
    if (this.state.reportType === reportTypeDefinitions.atlassianAnnualAssessmentType) {
      return (
        <div>
          <AssessmentView
            definition={this.state.definition}
            assessment={this.state.assessment}
          />
        </div>
      );
    } else if (this.state.reportType === reportTypeDefinitions.atlassianPromotionType) {
      return (
        <div>
          <PromotionView
            definition={this.state.definition}
            assessment={this.state.assessment}
          />
        </div>
      );
    } else {
      return (
        <ReportView
          definition={this.state.definition}
          assessment={this.state.assessment}
          includeCriteriaHeaders={this.state.preferences.includeCriteriaHeaders}
          includeColumnHeaders={this.state.preferences.includeColumnHeaders}
          includeStatements={this.state.preferences.includeStatements}
          includeAssessmentScores={this.state.preferences.includeAssessmentScores}
          showOnlyAssessedStatements={this.state.preferences.showOnlyAssessedStatements}
          showOnlyColumnsWithScores={this.state.preferences.showOnlyColumnsWithScores}
          includeTotalScore={this.state.preferences.includeTotalScore}
          formatStatementsBold={this.state.preferences.formatStatementsBold}
        />
      );
    }
  };

  render() {
    const title = this.state.assessment ?
      this.state.definition.name + ': ' + this.state.assessment.name:
      'Report';
    return (
      <ContentWrapper>
        <LiftedPanel>
          <PageTitle>{title}</PageTitle>
          {this.state.definition && this.state.assessment ? this.renderToolbar() : null}
          {this.renderReport()}
        </LiftedPanel>
      </ContentWrapper>
    );
  }
}
