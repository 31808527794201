import React, { PureComponent } from 'react';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
import currentRubric from '../../../../shared/model/rubric/CurrentRubric';
import Select from '@atlaskit/select';
import SelectOption from '../../../../shared/model/widget/SelectOption';

type State = {
  label?: string,
  options?: SelectOption[],
  selectedOption?: SelectOption
}

type Props = {
  key?: string,
  label: string,
  assessments: Assessment[],
  selectedAssessment: Assessment
  onAssessmentSelected?: (assessmentUuid: string) => Promise<void>
}

export default class AssessmentSelector extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props) => {
    const options: SelectOption[] = [];
    let selectedOption: undefined | SelectOption = undefined;
    for (let i = 0; i < props.assessments.length; i++) {
      const assessment = props.assessments[i];
      const uuid = assessment.uuid;
      const name = assessment.name;
      const option: SelectOption = {
        label: name,
        value: uuid
      };
      options.push(option);
      if (props.selectedAssessment && props.selectedAssessment.uuid === uuid) {
        selectedOption = option;
      }
    }
    if (selectedOption === undefined && options.length) {
      selectedOption = options[0];
    }
    return {
      label: props.label,
      selectedAssessment: props.selectedAssessment,
      assessments: props.assessments,
      selectedOption: selectedOption,
      options: options
    };
  };

  onChange = (selectionValue) => {
    // args should be: value: ValueType<OptionType, IsMulti>, action: ActionMeta<OptionType>
    const selection = selectionValue as SelectOption;
    this.setState({
      selectedOption: selection
    });
    const assessmentUuid = selection.value;
    if (this.props.onAssessmentSelected) {
      this.props.onAssessmentSelected(assessmentUuid);
    }
    currentRubric.setAssessmentUuid(assessmentUuid);
  };

  render() {
    const options = this.state.options;
    if (options && options.length > 0) {
      return (
        <div
          key={this.props.key ? this.props.key : 'assessment-selector'}
        >
          <Select
            label={this.state.label}
            className="single-select"
            classNamePrefix="react-select"
            placeholder={undefined}
            value={this.state.selectedOption}
            options={this.state.options}
            onChange={this.onChange}
          />
        </div>
      );
    } else {
      return null;
    }
  };

}
