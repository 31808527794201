import {
  DemoDefinitionClearAllInstructionBuilder,
  DemoDefinitionDelayInstructionBuilder,
  DemoDefinitionDisplayImageInstructionBuilder,
  DemoDefinitionDisplayPointerInstructionBuilder,
} from '../DemoDefinitionBuilder';
import analyticRubricImage from '../../../../images/screenshot/screenshot-analytic-rubric.png';
import clickCreateScreenshot from "../../../../images/screenshot/screenshot-create-demo-1-click-create.png";
import clickOptionScreenshot from "../../../../images/screenshot/screenshot-create-demo-2-click-option.png";
import perStatementAssessmentsImage from '../../../../images/screenshot/screenshot-per-statement-assessments.png';
import scoresByAspectImage from '../../../../images/screenshot/screenshot-scores-by-aspect.png';
import columnHidingImage from '../../../../images/screenshot/screenshot-column-hiding.png';
import cellEditingImage from '../../../../images/screenshot/screenshot-cell-editing.png';
import viewRubricWithAssessmentImage from '../../../../images/screenshot/screenshot-view-rubric-with-assessment.png';
import viewRubric from "../../../../images/screenshot/screenshot-view-rubric-no-assessments.png";
import editModeScreenshot from "../../../../images/screenshot/screenshot-edit-demo-2-edit-mode.png";
import shareImage from '../../../../images/screenshot/screenshot-share.png';
import clickSearchScreenshot from "../../../../images/screenshot/screenshot-search-demo-1-click-search.png";
import searchResultsScreenshot from "../../../../images/screenshot/screenshot-search-demo-2-results.png";
import webViewScreenshot from "../../../../images/screenshot/screenshot-sidebar-demo-2-web-view.png";
import appViewScreenshot from "../../../../images/screenshot/screenshot-sidebar-demo-3-app-view.png";
import collapsedScreenshot from "../../../../images/screenshot/screenshot-sidebar-demo-1-collapsed.png";

class DemoContent {

  addTypicalRubricInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionClearAllInstructionBuilder()
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(analyticRubricImage)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.3)
          .setImageEndRatioY(0.23)
          .setImageEndRatioX(0.1)
          .addTextLine('A typical')
          .addTextLine('rubric')
          .build()
      );
  };

  addPerStatementAssessmentsInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(perStatementAssessmentsImage)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.15)
          .setImageEndRatioY(0.15)
          .setImageEndRatioX(0.88)
          .addTextLine('Assess each')
          .addTextLine('rubric statement')
          .addTextLine('separately:')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(500)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.35)
          .setImageEndRatioY(0.17)
          .setImageEndRatioX(0.77)
          .addTextLine(' * comments')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(500)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.45)
          .setImageEndRatioY(0.17)
          .setImageEndRatioX(0.49)
          .addTextLine(' * score')
          .build()
      );
  };

  addAspectPieChartInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(scoresByAspectImage)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.2)
          .setImageEndRatioY(0.4)
          .setImageEndRatioX(0.35)
          .addTextLine('Visualize')
          .addTextLine('assessment')
          .addTextLine('scores')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.2)
          .setImageEndRatioY(0.38)
          .setImageEndRatioX(0.82)
          .addTextLine('Individual')
          .addTextLine('aspect')
          .addTextLine('score')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.6)
          .setImageEndRatioY(0.5)
          .setImageEndRatioX(0.5)
          .addTextLine('The overall')
          .addTextLine('assessment')
          .addTextLine('score')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.7)
          .setImageEndRatioY(0.58)
          .setImageEndRatioX(0.46)
          .addTextLine('Easily spot')
          .addTextLine('bad results')
          .build()
      );
  };

  addColumnHidingInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(columnHidingImage)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.4)
          .setImageEndRatioY(0.4)
          .setImageEndRatioX(0.3)
          .addTextLine('Columns can')
          .addTextLine('be hidden')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.18)
          .setImageEndRatioY(0.08)
          .setImageEndRatioX(0.96)
          .addTextLine('Click here to')
          .addTextLine('toggle column')
          .addTextLine('visibility')
          .build()
      )
  };

  addCellEditingInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(cellEditingImage)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.23)
          .setImageEndRatioY(0.23)
          .setImageEndRatioX(0.11)
          .addTextLine('Click a rubric')
          .addTextLine('cell to assess')
          .addTextLine('statements')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.3)
          .setImageEndRatioY(0.46)
          .setImageEndRatioX(0.81)
          .addTextLine('Change the score')
          .addTextLine('using the slider')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.71)
          .setImageEndRatioY(0.71)
          .setImageEndRatioX(0.85)
          .addTextLine('Annotate each')
          .addTextLine('statement score')
          .build()
      )
  };

  addSharingPartAInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(viewRubricWithAssessmentImage)
          .setOffsetX(-1)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.2)
          .setImageEndRatioY(0.074)
          .setImageEndRatioX(0.954)
          .addTextLine('Click here to')
          .addTextLine('share a rubric')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.3)
          .setImageEndRatioY(0.19)
          .setImageEndRatioX(0.42)
          .addTextLine('Click here')
          .addTextLine('to share')
          .addTextLine('an assessment')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.5)
          .setImageEndRatioY(0.19)
          .setImageEndRatioX(0.53)
          .addTextLine('Unlock the')
          .addTextLine('the assessment')
          .addTextLine('to share')
          .build()
      );
  };

  addSharingPartBInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(shareImage)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.35)
          .setImageEndRatioY(0.35)
          .setImageEndRatioX(0.7)
          .addTextLine('Enter the email')
          .addTextLine('address of the')
          .addTextLine('person to share')
          .addTextLine('with')
          .build()
      );
  };

  addFavouritesInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(viewRubricWithAssessmentImage)
          .setOffsetX(-1)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setRightSide()
          .setSideStartRatioY(0.2)
          .setImageEndRatioY(0.074)
          .setImageEndRatioX(0.885)
          .addTextLine('Click here to')
          .addTextLine('make a rubric')
          .addTextLine('a favourite')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.6)
          .setImageEndRatioY(0.41)
          .setImageEndRatioX(0.065)
          .addTextLine('It\'s then marked')
          .addTextLine('here as a')
          .addTextLine('favourite also')
          .build()
      );
  };

  addCreatePartAInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(clickCreateScreenshot)
          .setOffsetX(-1)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(500)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.172)
          .setImageEndRatioY(0.172)
          .setImageEndRatioX(0.008)
          .addTextLine('Click here to')
          .addTextLine('create a rubric')
          .build()
      );

  };

  addCreatePartBInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(clickOptionScreenshot)
          .setOffsetX(-1)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(500)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.3)
          .setImageEndRatioY(0.23)
          .setImageEndRatioX(0.1)
          .addTextLine('Select a template')
          .addTextLine('to start with')
          .build()
      );
  };

  addEditPartAInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(viewRubric)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.2)
          .setImageEndRatioY(0.08)
          .setImageEndRatioX(0.88)
          .addTextLine('Click the edit')
          .addTextLine('icon to edit')
          .addTextLine('the rubric on')
          .addTextLine('the screen')
          .build()
      );

  };

  addEditPartBInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(editModeScreenshot)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.08)
          .setImageEndRatioY(0.08)
          .setImageEndRatioX(0.4)
          .addTextLine('Rename the rubric')
          .addTextLine('by clicking here')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.3)
          .setImageEndRatioY(0.15)
          .setImageEndRatioX(0.24)
          .addTextLine('Enter the rubric')
          .addTextLine('description here')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.4)
          .setImageEndRatioY(0.33)
          .setImageEndRatioX(0.6)
          .addTextLine('Enter the')
          .addTextLine('editability')
          .addTextLine('here')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.5)
          .setImageEndRatioY(0.35)
          .setImageEndRatioX(0.24)
          .addTextLine('Enter the')
          .addTextLine('discoverability')
          .addTextLine('here')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.7)
          .setImageEndRatioY(0.6)
          .setImageEndRatioX(0.5)
          .addTextLine('Options')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.9)
          .setImageEndRatioY(0.8)
          .setImageEndRatioX(0.6)
          .addTextLine('Click rubric table')
          .addTextLine('cells to modify')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setRightSide()
          .setSideStartRatioY(0.2)
          .setImageEndRatioY(0.083)
          .setImageEndRatioX(0.87)
          .addTextLine('Click here to')
          .addTextLine('view the rubric')
          .build()
      );
  };

  addSearchPartAInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(clickSearchScreenshot)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.13)
          .setImageEndRatioY(0.13)
          .setImageEndRatioX(0.008)
          .addTextLine('Click here to start')
          .addTextLine('searching')
          .build()
      );
  };

  addSearchPartBInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(searchResultsScreenshot)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.16)
          .setImageEndRatioY(0.16)
          .setImageEndRatioX(0.06)
          .addTextLine('Enter search')
          .addTextLine('text here')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(2000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.3)
          .setImageEndRatioY(0.23)
          .setImageEndRatioX(0.1)
          .addTextLine('Search results')
          .addTextLine('appear here')
          .build()
      );
  };

  addSidebarPartAInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(collapsedScreenshot)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(1000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.6)
          .setImageEndRatioY(0.6)
          .setImageEndRatioX(0.02)
          .addTextLine('Here\'s how the sidebar')
          .addTextLine('looks when it\'s collapsed')
          .build()
      );
  };

  addSidebarPartBInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionClearAllInstructionBuilder()
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(webViewScreenshot)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.5)
          .setImageEndRatioY(0.5)
          .setImageEndRatioX(0.08)
          .addTextLine('Here\'s how the sidebar')
          .addTextLine('looks when it\'s been')
          .addTextLine('expanded')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(2000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.1)
          .setImageEndRatioY(0.1)
          .setImageEndRatioX(0.06)
          .addTextLine('Click here to show')
          .addTextLine('app items in the')
          .addTextLine('sidebar')
          .build()
      );
  };

  addSidebarPartCInstructions = (definition) => {
    return definition
      .addInstruction(
        new DemoDefinitionDisplayImageInstructionBuilder()
          .setImageSrc(appViewScreenshot)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.5)
          .setImageEndRatioY(0.5)
          .setImageEndRatioX(0.08)
          .addTextLine('This shows the sidebar')
          .addTextLine('when in app mode.')
          .build()
      )
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(2000)
          .build()
      )
      .addInstruction(
        new DemoDefinitionDisplayPointerInstructionBuilder()
          .setLeftSide()
          .setSideStartRatioY(0.1)
          .setImageEndRatioY(0.1)
          .setImageEndRatioX(0.06)
          .addTextLine('Click here to go')
          .addTextLine('back to the')
          .addTextLine('previous view')
          .build()
      );
  };

}

export default new DemoContent()
