import React from 'react';
import adg from '../../../../commonbase/adg';
import { CardDragInfo, ICard, ListDragInfo } from './BoardState';
import Card from './Card';
import './List.css';

interface Props {
  id: string
  title?: string
  height: any
  cards: ICard[]
  onDragStart: (dragInfo: ListDragInfo) => void
  onDragLeave?: (event: any) => void
  onDragOver: (event: any) => void
  onDrop: (event: any) => void
  onCardLinkClick: (card: ICard) => void
}

interface State {
  daggingOver?: boolean
}

export default class List extends React.Component<Props, State> {

  listHeight: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      daggingOver: false
    }
  }

  onDragStart = (dragInfo: CardDragInfo) => {
    const listDragInfo: ListDragInfo = {
      fromListId: this.props.id,
      cardAssessmentUuid: dragInfo.cardAssessmentUuid
    }
    this.props.onDragStart(listDragInfo);
  }

  onDragOver = (event) => {
    this.setState({
      daggingOver: true
    });
    if (this.props.onDragOver) {
      this.props.onDragOver(event);
    }
  };
   
  onDragLeave = (event) => {
    this.setState({
      daggingOver: false
    });
    if (this.props.onDragLeave) {
      this.props.onDragLeave(event);
    }
  };
   
  onDrop = (event) => {
    this.setState({
      daggingOver: false
    });
    if (this.props.onDrop) {
      this.props.onDrop(event);
    }
  };

  renderEmptyList = () => {
    return (
      <div
        className="empty-list-item"
      >
        drag cards here
      </div>
    );
  }
   
  render() {
    const cards = this.props.cards.map((card, index) => {
      return ( 
        <li key={index}>
          <Card
            {...card}
            onDragStart={this.onDragStart}
            onCardLinkClick={this.props.onCardLinkClick}
          />
        </li>
      );
    });
    // const height = this.props.height;
    const listHeight = this.listHeight ? this.listHeight : window.innerHeight;
    const heightExtension = 200;
    const height = listHeight + heightExtension;
    const dropZoneStyle: any = {
      height: height + 'px'
    };
    if (this.state.daggingOver) {
      dropZoneStyle.borderColor = adg.adgRed;
      // dropZoneStyle.backgroundColor = adg.adgGreen;
    }
    return (
      <div
        style={dropZoneStyle}
        ref={(element) => {
          // this.myElement = element;
          if (element && !this.listHeight) {
            this.listHeight = element.clientHeight;
          }
        }}
        className="list-drop-zone"
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      >
        <div
          className="list-container"
        >
          <h2 className={`name-header name-${this.props.id}`}>{this.props.title}</h2>
          <ul
            className="list"
          >
            {cards}
            {cards.length ? null : this.renderEmptyList()}
          </ul>
        </div>
      </div>
    );
  }
  
}