import Assessment from './score/Assessment';
import discoverabilityDefinitions from './DiscoverabilityDefinitions';
import roleDefinitions from '../auth/RoleDefinitions';
import roleUtil from '../auth/RoleUtil';
import util from '../../util/Util';
import statusDefinitions from './status/StatusDefinitions';

export default class RubricAssessmentBuilder {

  assessment: Assessment;

  constructor() {
    this.assessment = {
      uuid: util.uuid(),
      ownerId: '',
      name: '',
      definitionUuid: '',
      discoverability: discoverabilityDefinitions.privateDiscoverabilityType,
      archivedTimestamp: 0,
      roles: [],
      statusId: statusDefinitions.getDefaultStatusId(),
      generalNotes: '',
      statementUuidsToInfos: {},
      lastSnapshotTimestamp: 0,
      updateTimestamp: new Date().getTime()
    };
  }

  setUuid = (uuid) => {
    this.assessment.uuid = uuid;
    return this;
  };

  setOwnership = (owner) => {
    const ownerId = owner.getId();
    const ownerEmail = owner.getEmail();
    this.assessment.ownerId = ownerId;
    roleUtil.setRoleByEmail(this.assessment.roles, ownerEmail, roleDefinitions.adminRoleType);
    return this;
  };

  addReaderRoleForEveryone = () => {
    roleUtil.setRoleByEmail(this.assessment.roles, '*', roleDefinitions.readerRoleType);
    return this;
  }

  addWriterRoleForEveryone = () => {
    roleUtil.setRoleByEmail(this.assessment.roles, '*', roleDefinitions.writerRoleType);
    return this;
  }

  addReaderRoleForUser = (user) => {
    return this.addRoleForUser(user, roleDefinitions.readerRoleType);
  };

  addWriterRoleForUser = (user) => {
    return this.addRoleForUser(user, roleDefinitions.writerRoleType);
  };

  addRoleForUser = (user, roleType) => {
    // const userId = user.getId();
    const userEmail = user.getEmail();
    roleUtil.setRoleByEmail(this.assessment.roles, userEmail, roleType);
    return this;
  };

  setName = (name) => {
    this.assessment.name = name;
    return this;
  };

  setDefinitionUuid = (definitionUuid) => {
    this.assessment.definitionUuid = definitionUuid;
    return this;
  };

  applybuilderActions = (builderActions) => {
    if (builderActions) {
      builderActions(this);
    }
    return this;
  }

  setArchivedTimestamp = (archivedTimestamp) => {
    this.assessment.archivedTimestamp = archivedTimestamp;
    return this;
  };

  setDiscoverability = (discoverability) => {
    this.assessment.discoverability = discoverability;
    return this;
  };

  setGeneralNotes = (generalNotes) => {
    this.assessment.generalNotes = generalNotes;
    return this;
  };

  build = () => {
    if (this.assessment.ownerId === undefined) {
      throw new Error('ownerId must be set.');
    }
    if (this.assessment.definitionUuid === undefined) {
      throw new Error('definitionUuid must be set.');
    }
    if (this.assessment.name === undefined) {
      this.assessment.name = 'New assessment - ' + util.formatDate(new Date());
    }
    return this.assessment;
  };

}

