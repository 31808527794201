import ListenerGroup from '../../util/ListenerGroup';

class RubricDeletionNotifier {

  listenerGroup = new ListenerGroup('RubricDeletionNotifier');

  notifyRubricDeletion = (definitionUuid) => {
    this._notifyListeners(definitionUuid);
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = (definitionUuid) => {
    this.listenerGroup.notifyListeners(definitionUuid);
  };

}

export default new RubricDeletionNotifier();
