import actions from "../../../shared/actions/Actions";
import preferencesDAO from "../../../backend/data/PreferencesDAO";
import session from '../../../shared/model/auth/Session';
import DefinitionPreferences from '../../../shared/model/rubric/preference/DefinitionPreferences';
import promiseUtil from '../../../commonbase/util/promiseUtil';
import IUser from '../../../shared/model/auth/IUser';

class RubricViewerPreferences {

  definitionUuidsToPreferencesCache: Map<string, undefined | DefinitionPreferences> = new Map<string, DefinitionPreferences>();

  constructor() {
    actions.registerListener(this._onAction);
    session.registerListener(this._onSessionChange);
  }

  getDefinitionPreferences = async (definitionUuid: string): Promise<DefinitionPreferences> => {
    const cachedDefinitionPreferences: undefined | DefinitionPreferences = this.definitionUuidsToPreferencesCache.get(definitionUuid);
    if (cachedDefinitionPreferences) {
      return cachedDefinitionPreferences;
    }
    const definitionPreferences: DefinitionPreferences = await this._getDefinitionPreferences(definitionUuid);
    this.definitionUuidsToPreferencesCache.set(definitionUuid, definitionPreferences);
    return definitionPreferences;
  };

  setDefinitionPreferences = async (definitionUuid: string, definitionPreferences: DefinitionPreferences): Promise<void> => {
    this.definitionUuidsToPreferencesCache.set(definitionUuid, definitionPreferences);
    const currentUser = session.getCurrentUser();
    return preferencesDAO.setDefinitionPreferences(
      definitionUuid, currentUser, definitionPreferences);
  };

  _getDefinitionPreferences = async (definitionUuid: string): Promise<DefinitionPreferences> => {
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      const defaultDefinitionPreferences: DefinitionPreferences = {};
      return preferencesDAO.getDefinitionPreferences(
        definitionUuid, currentUser, defaultDefinitionPreferences);
    } else {
      return promiseUtil.promiseReturning({} as DefinitionPreferences);
    }
  };

  _onSessionChange = (user: IUser) => {
    this.definitionUuidsToPreferencesCache = new Map<string, DefinitionPreferences>();
  }

  _onAction = (actionId: string, context: any) => {
    if (actionId === actions.removeDefinitionReferencesActionId) {
      const definitionUuid = context;
      const currentUser = session.getCurrentUser();
      preferencesDAO.removeDefinitionPreference(
        definitionUuid, currentUser)
    }
  };

}

export default new RubricViewerPreferences();
