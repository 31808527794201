
import I18N from '../i18n/I18N';
import SelectOption from '../widget/SelectOption';
import Definition from './definition/Definition';
import rubricUtil from './RubricUtil';

class AspectGroupingDefinitions {

  summaryAspectGroupingType = 'summary';
  noneAspectGroupingType = 'none';
  statementAspectGroupingType = 'statement';
  labelAspectGroupingType = 'label';
  pillarAspectGroupingType = 'pillar';
  summaryAspectGroupingOption: SelectOption = {
    value: this.summaryAspectGroupingType,
    label: 'Summary'
  };
  noneAspectGroupingOption: SelectOption = {
    value: this.noneAspectGroupingType,
    label: I18N.Area
  };
  statementAspectGroupingOption: SelectOption = {
    value: this.statementAspectGroupingType,
    label: 'Statement'
  };
  labelAspectGroupingOption: SelectOption = {
    value: this.labelAspectGroupingType,
    label: 'Label'
  };
  pillarAspectGroupingOption: SelectOption = {
    value: this.pillarAspectGroupingType,
    label: 'Pillar'
  };
  options: SelectOption[] = [
    this.summaryAspectGroupingOption,
    this.noneAspectGroupingOption,
    this.statementAspectGroupingOption,
    this.labelAspectGroupingOption,
    this.pillarAspectGroupingOption
  ];

  typesToNames: any[] = [];

  constructor() {
    for (const option of this.options) {
      this.typesToNames[option.value] = option.label;
    }
    // this.typesToNames[this.summaryAspectGroupingType] = 'Summary';
    // this.typesToNames[this.noneAspectGroupingType] = I18N.Area;
    // this.typesToNames[this.statementAspectGroupingType] = 'Statement';
    // this.typesToNames[this.labelAspectGroupingType] = 'Label';
    // this.typesToNames[this.pillarAspectGroupingType] = 'Pillar';
  }

  getOptions = (): SelectOption[] => {
    return this.options;
  }

  buildOptions = (isGroupingAllowed: (type: string) => boolean, definition: Definition): SelectOption[] => {
    const options: SelectOption[] = [];
    this._addOption(this.summaryAspectGroupingType, isGroupingAllowed, definition, options);
    this._addOption(this.noneAspectGroupingType, isGroupingAllowed, definition, options);
    this._addOption(this.statementAspectGroupingType, isGroupingAllowed, definition, options);
    this._addOption(this.labelAspectGroupingType, isGroupingAllowed, definition, options);
    this._addOption(this.pillarAspectGroupingType, isGroupingAllowed, definition, options);
    return options;
  };

  _addOption = (groupingType: string, isGroupingAllowed: (type: string) => boolean, definition: Definition, options: SelectOption[]) => {
    if (!isGroupingAllowed || isGroupingAllowed(groupingType)) {
      let label;
      if (groupingType === this.noneAspectGroupingType && definition) {
        label = rubricUtil.getAspectHeader(definition);
      } else if (groupingType === this.statementAspectGroupingType && definition) {
        label = rubricUtil.getStatementLabel(definition);
      } else {
        label = this.typesToNames[groupingType];
      }
      const option: SelectOption = {
        // content: this.typesToNames[groupingType],
        label: label,
        value: groupingType
      };
      options.push(option);
    }
  };

  // buildAspectGroupingTypeEnumItemsContext = (currentType, isGroupingAllowed, definition) => {
  //   const items: any[] = [];
  //   this._addItem(this.summaryAspectGroupingType, isGroupingAllowed, definition, items);
  //   this._addItem(this.noneAspectGroupingType, isGroupingAllowed, definition, items);
  //   this._addItem(this.statementAspectGroupingType, isGroupingAllowed, definition, items);
  //   this._addItem(this.labelAspectGroupingType, isGroupingAllowed, definition, items);
  //   this._addItem(this.pillarAspectGroupingType, isGroupingAllowed, definition, items);
  //   const selectItems = [{
  //     items: items
  //   }];
  //   let selectedItem = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: items,
  //     selectedItem: selectedItem
  //   };
  // };

  // _addItem = (groupingType, isGroupingAllowed, definition, items) => {
  //   if (!isGroupingAllowed || isGroupingAllowed(groupingType)) {
  //     let label;
  //     if (groupingType === this.noneAspectGroupingType && definition) {
  //       label = rubricUtil.getAspectHeader(definition);
  //     } else if (groupingType === this.statementAspectGroupingType && definition) {
  //       label = rubricUtil.getStatementLabel(definition);
  //     } else {
  //       label = this.typesToNames[groupingType];
  //     }
  //     const item = {
  //       content: this.typesToNames[groupingType],
  //       label: label,
  //       value: groupingType
  //     };
  //     items.push(item);
  //   }
  // };

}

export default new AspectGroupingDefinitions();
