import storageDAO from './StorageDAO';

export default class SubscriptionsBrowserPersistence {

  addSubscription = (planType, userId, paymentProviderToken, paymentProviderType) => {
    const key = this._buildSubscriptionKey(userId);
    const data = {
      planType: planType,
      paymentProviderToken: paymentProviderToken,
      paymentProviderType: paymentProviderType
    };
    return storageDAO.setData(key, data);
  };

  getSubscription = (userId) => {
    const key = this._buildSubscriptionKey(userId);
    return storageDAO.getData(key, null);
  };

  _buildSubscriptionKey = (userId) => {
    return 'subscription-' + userId;
  };

  addTrialInfo = (userId, expiryTimestamp) => {
    const key = this._buildTrialInfoKey(userId);
    const data = {
      expiryTimestamp: expiryTimestamp
    };
    return storageDAO.setData(key, data);
  };

  getTrialInfo = (userId) => {
    const key = this._buildTrialInfoKey(userId);
    return storageDAO.getData(key, null);
  };

  _buildTrialInfoKey = (userId) => {
    return 'trial-info-' + userId;
  };

};
