import React, { PureComponent } from 'react';
import AspectPieChart from "./AspectPieChart";
import ChartModel from './ChartModel';
import ToolbarLeft from '../../../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../../components/widget/toolbar/ToolbarItem';

export default class MultiPieChart extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      scoreMeta: props.scoreMeta,
      chartModel: props.chartModel,
      aspectGrouping: props.aspectGrouping
    };
  };

  render() {
    const renderedChartDivs = this.state.chartModel.assessments.map((assessment) => {
      const singleAssessmentChartModel = new ChartModel(
        this.state.chartModel.definition,
        [assessment],
        this.state.chartModel.visibleLabels,
        this.state.chartModel.columnVisibilities);
      return (
        <ToolbarItem key={assessment.uuid} style={{textAlign: 'center'}}>
          <AspectPieChart
            scoreMeta={this.state.scoreMeta}
            chartModel={singleAssessmentChartModel}
            aspectGrouping={this.state.aspectGrouping}
          />
          <p className="caption">
            {assessment.name}
          </p>
        </ToolbarItem>
      );
    });
    return (
      <ToolbarLeft>
        {renderedChartDivs}
      </ToolbarLeft>
    );
  };

}
