const adg = require('../adg');
const scoreTypes = require('./scoreTypes');

const scoreTypesToDefinitions = {};
scoreTypesToDefinitions[scoreTypes.standard] = {
  name: 'Percentage',
  markers: [{
    score: 0
  }, {
    score: 25
  }, {
    score: 50
  }, {
    score: 75
  }, {
    score: 100
  }]
};
scoreTypesToDefinitions[scoreTypes.levels] = {
  name: 'Levels',
  markers: [{
    score: 0,
    label: 'Below expectations'
  }, {
    score: 20,
    label: 'Minimum'
  }, {
    score: 35,
    label: 'Progressing'
  }, {
    score: 50,
    label: 'Average'
  }, {
    score: 65,
    label: 'Advanced'
  }, {
    score: 80,
    label: 'Maximum'
  }, {
    score: 100,
    label: 'Exceeds expectations'
  }]
};
scoreTypesToDefinitions[scoreTypes.yesOrNo] = {
  name: 'Yes or No',
  markers: [{
    score: 0,
    label: 'No',
    color: adg.adgRed
  }, {
    score: 1,
    label: 'Yes',
    color: adg.adgGreen
  }]
};
scoreTypesToDefinitions[scoreTypes.passOrFail] = {
  name: 'Pass or Fail',
  markers: [{
    score: 0,
    label: 'Fail',
    color: adg.adgRed
  }, {
    score: 1,
    label: 'Pass',
    color: adg.adgGreen
  }]
};
scoreTypesToDefinitions[scoreTypes.unsatisfactoryToStrong] = {
  name: 'Unsatisfactory, Weak, Good, Strong',
  markers: [{
    score: 0,
    label: 'Unsatisfactory'
  }, {
    score: 1,
    label: 'Weak'
  }, {
    score: 2,
    label: 'Good'
  }, {
    score: 3,
    label: 'Strong'
  }]
};
scoreTypesToDefinitions[scoreTypes.badOkGood] = {
  name: 'Bad, OK, Good',
  markers: [{
    score: 0,
    label: 'Bad'
  }, {
    score: 1,
    label: 'OK'
  }, {
    score: 2,
    label: 'Good'
  }]
};
scoreTypesToDefinitions[scoreTypes.poorToStrong] = {
  name: 'Poor, Weak, Good, Strong',
  markers: [{
    score: 0,
    label: 'Poor'
  }, {
    score: 1,
    label: 'Weak'
  }, {
    score: 2,
    label: 'Good'
  }, {
    score: 3,
    label: 'Strong'
  }]
};
scoreTypesToDefinitions[scoreTypes.limitedToExcellent] = {
  name: 'Limited, Progressing, Competent, Good, Excellent',
  markers: [{
    score: 0,
    label: 'Limited'
  }, {
    score: 1,
    label: 'Progressing'
  }, {
    score: 2,
    label: 'Competent'
  }, {
    score: 3,
    label: 'Good'
  }, {
    score: 4,
    label: 'Excellent'
  }]
};
scoreTypesToDefinitions[scoreTypes.zeroToTen] = {
  name: '1 - 10',
  markers: [{
    score: 0
  }, {
    score: 1
  }, {
    score: 2
  }, {
    score: 3
  }, {
    score: 4
  }, {
    score: 5
  }, {
    score: 6
  }, {
    score: 7
  }, {
    score: 8
  }, {
    score: 9
  }, {
    score: 10
  }]
};
scoreTypesToDefinitions[scoreTypes.customLevels] = {
  name: 'Custom levels',
  markers: undefined
};

module.exports = scoreTypesToDefinitions;

