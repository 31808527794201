import colorRange from '../../../../commonbase/color/redToGreenColorRange';
import ScoreMeta from './ScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';

class ZeroToTenScoreMeta extends ScoreMeta {

  constructor() {
    super(scoreTypes.zeroToTen);
  }

  allowScoresBetweenMarkers = () => {
    return false;
  };

  getColorRange = () => {
    return colorRange;
  }

}

export default new ZeroToTenScoreMeta();

