
/*
      Also see stripe-server-config.js
 */
class StripeClientConfig {

  getStripeKey = () => {
    return 'pk_live_9egOL24FzeuZlgiHXpFdHZWd';
    // return 'pk_test_oZFTZIeb72TPgMldV3J7bDbL';
  };

}

export default new StripeClientConfig();
