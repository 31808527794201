import React, { PureComponent } from "react";
import DefinitionAssessmentSelect from '../../rubric/assessment/DefinitionAssessmentSelect';
import Button from '@atlaskit/button';
import DebugObject from '../../widget/DebugObject';
import DefinitionSelect from '../../rubric/definition/DefinitionSelect';
import firestoreAssessmentDAO from '../../../../backend/data/FirestoreAssessmentDAO';
import Label from '../../widget/Label';
import csvUtil from '../../../../commonbase/csvUtil';
import Toggle from '@atlaskit/toggle';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import rubricDAO from '../../../../backend/data/RubricDAO';
import promiseUtil from '../../../../commonbase/util/promiseUtil';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

type Props = {
  selectedDefinitionUuid: undefined | string
  startCollapsed?: boolean
  viewTitle?: boolean
  viewChart?: boolean
  viewTable?: boolean
  onParamsChange: (params: any) => void
}

type State = {
  showDebug?: boolean
  showAnAssessment?: boolean
  startCollapsed?: boolean
  viewTitle?: boolean
  viewChart?: boolean
  viewTable?: boolean
  definitionIsFixed?: boolean
  pendingDefinitionUuid?: string
  pendingDefinition?: Definition
  forcedDefinitionName?: string
  selectedDefinitionName?: string
  selectedDefinition?: Definition
  selectedAssessmentUuids?: string[]
  definitionReset?: boolean
}

export default class ConfluenceMacroEditorPanel extends PureComponent<Props, State> {

  assessmentDAO = firestoreAssessmentDAO;
  mounted = false;
  driveGraphLoaded = false;
  state: State;

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
    this.state.showDebug = false;
    this.state.showAnAssessment = props.selectedAssessmentUuids && props.selectedAssessmentUuids.length;
    this.state.definitionIsFixed = props.definitionIsFixed;
    this.state.forcedDefinitionName = props.forcedDefinitionName;
    this.state.selectedDefinition = props.selectedDefinition;
    // this.state.selectedDefinitionUuid = props.selectedDefinitionUuid;
    this.state.selectedAssessmentUuids = props.selectedAssessmentUuids;
  }

  // https://roobrick.au.ngrok.io/app/confluence-macro-editor?definition-name=&dialog=1&simpleDialog=1&xdm_e=https%3A%2F%2Fforgery.atlassian.net&xdm_c=channel-local-roobrick-confluence__local-any-rubric&cp=%2Fwiki&xdm_deprecated_addon_key_do_not_use=local-roobrick-confluence&lic=none&cv=1.793.0
  // https://roobrick.au.ngrok.io/app/confluence-macro-editor?definition-uuid=&definition-name=&dialog=1&simpleDialog=1&xdm_e=https%3A%2F%2Fforgery.atlassian.net&xdm_c=channel-local-roobrick-confluence__local-any-rubric&cp=%2Fwiki&xdm_deprecated_addon_key_do_not_use=local-roobrick-confluence&lic=none&cv=1.793.0

  // UNSAFE_componentWillReceiveProps(props: Props) {
  //   // this.setState(this.buildStateFromProps(props));
  // }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    if (this.props.selectedDefinitionUuid) {
      this.resolveSelectedDefinition(this.props.selectedDefinitionUuid);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  };

  componentDidMount() {
  }

  resolveSelectedDefinition = (definitionUuid: string) => {
    rubricDAO.getDefinitionByUuid(definitionUuid).then((definition: undefined | Definition) => {
      if (definition) {
        this.setState({
          selectedDefinition: definition
        });
      }
    });
  }

  getDefinitionByUuid = (definitionUuid: string): Promise<undefined | Definition> => {
    if (this.state.selectedDefinition && this.state.selectedDefinition.uuid === definitionUuid) {
      return promiseUtil.promiseReturning(this.state.selectedDefinition);
    } else {
      return rubricDAO.getDefinitionByUuid(definitionUuid);
    }
  }

  buildStateFromProps = (props: Props) => {
    return {
      startCollapsed: props.startCollapsed === true,
      viewTitle: props.viewTitle,
      viewChart: props.viewChart,
      viewTable: props.viewTable
    };
  };

  buildParams = (object: State): any => {
    const selectedAssessmentUuids = object.selectedAssessmentUuids ? object.selectedAssessmentUuids : this.nilAssessmentUuids();
    return {
      selectedDefinitionUuid: object.selectedDefinition ? object.selectedDefinition.uuid : undefined,
      // selectedDefinitionUuid: object.selectedDefinitionUuid,
      selectedDefinitionName: object.selectedDefinitionName,
      selectedAssessmentUuidsCsv: csvUtil.arrayToCsv(selectedAssessmentUuids),
      startCollapsed: object.startCollapsed,
      viewTitle: object.viewTitle,
      viewChart: object.viewChart,
      viewTable: object.viewTable
    };
  };

  buildParamsFromProps = () => {
    return this.buildParams(this.props);
  };

  buildParamsFromState = (optionalState?: State): any => {
    return this.buildParams(optionalState ? optionalState : this.state);
  };

  nilAssessmentUuids = () => {
    return [];
  };

  onDefinitionSelection = (definition) => {
    // console.log(`Roobrick.ConfluenceMacroEditorPanel: Selecting option:`, option);

    // Phase 1: Set definitionReset to true
    this.setState({
      selectedDefinitionName: definition.name,
      selectedDefinition: undefined,
      // selectedDefinitionUuid: undefined,
      pendingDefinitionUuid: definition.uuid,
      pendingDefinition: definition,
      definitionReset: true
    });

    // Phase 2: Set definitionReset to false
    setTimeout(() => {
      const stateDelta = {
        selectedDefinition: definition,
        // selectedDefinitionUuid: definition.uuid,
        definitionReset: false,
        selectedAssessmentUuids: []
      };
      this.setState(stateDelta);
      // const updatedParams = this.buildParamsFromState();
      const currentState = Object.assign({}, this.state, stateDelta);
      const updatedParams = this.buildParamsFromState(currentState);

      updatedParams.selectedDefinitionUuid = definition.uuid;
      updatedParams.selectedDefinitionName = this.state.forcedDefinitionName ? this.state.forcedDefinitionName : definition.name;
      updatedParams.selectedAssessmentUuidsCsv = csvUtil.arrayToCsv(this.nilAssessmentUuids());
      // console.log('Roobrick: ConfluenceMacroEditorPanel.onDefinitionSelection: updatedParams:', updatedParams);
      this.props.onParamsChange(updatedParams);
    }, 0);

    // const stateDelta = {
    //   selectedDefinitionUuid: option.value,
    //   definitionReset: false,
    //   selectedAssessmentUuids: []
    // };
    // this.setState(stateDelta);
    // const currentState = Object.assign({}, this.state, stateDelta);
    // const updatedParams = this.buildParamsFromState(currentState);
    // updatedParams.selectedDefinitionUuid = option.value;
    // updatedParams.selectedDefinitionName = this.state.forcedDefinitionName ? this.state.forcedDefinitionName : option.label;
    // updatedParams.selectedAssessmentUuidsCsv = csvUtil.arrayToCsv(this.nilAssessmentUuids());
    // console.log('Roobrick: ConfluenceMacroEditorPanel.onDefinitionSelection: updatedParams:', updatedParams);
    // this.props.onParamsChange(updatedParams);
  };

  onShowAssessmentsToggle = () => {
    this.setState({
      showAnAssessment: !this.state.showAnAssessment
    });
  };

  onAssessmentsSelect = (selectedAssessments) => {
    const selectedAssessmentUuids: string[] = [];
    for (const selectedAssessment of selectedAssessments) {
      selectedAssessmentUuids.push(selectedAssessment.uuid);
    }
    this.setState({
      selectedAssessmentUuids: selectedAssessmentUuids
    });
    const updatedParams = this.buildParamsFromState();
    updatedParams.selectedAssessmentUuidsCsv = csvUtil.arrayToCsv(selectedAssessmentUuids);
    // console.log('Roobrick: ConfluenceMacroEditorPanel.onAssessmentsSelect: updatedParams:', updatedParams);
    this.props.onParamsChange(updatedParams);
  };

  onToggleStartCollapsed = () => {
    this.onOptionChanged('startCollapsed', !this.state.startCollapsed);
  };

  onToggleViewTitle = () => {
    this.onOptionChanged('viewTitle', !this.state.viewTitle);
  };

  onToggleViewChart = () => {
    this.onOptionChanged('viewChart', !this.state.viewChart);
  };

  onToggleViewTable = (event) => {
    this.onOptionChanged('viewTable', !this.state.viewTable);
  };

  onOptionChanged = (optionFieldName, newValue) => {
    const stateDelta = {};
    stateDelta[optionFieldName] = newValue;
    this.setState(stateDelta);
    const updatedParams = this.buildParamsFromState();
    updatedParams[optionFieldName] = newValue;
    this.props.onParamsChange(updatedParams);
  };

  onClearFormButtonClick = () => {
    this.setState({
      selectedDefinition: undefined,
      // selectedDefinitionUuid: undefined,
      selectedAssessmentUuids: this.nilAssessmentUuids(),
      startCollapsed: undefined
    });
    const updatedParams = this.buildParamsFromProps();
    this.props.onParamsChange(updatedParams);
    this.forceUpdate();
  };

  renderForcedDefinition = () => {
    return (
      <div className="xxxxxnewMainSection">
        <Label text="Rubric"/>
        {this.state.forcedDefinitionName}
      </div>
    );
  };

  renderDefinitionSelector = () => {
    const initialDefinitionUuid = this.state.selectedDefinition ? this.state.selectedDefinition.uuid : undefined;
    // console.log('initialDefinitionUuid:', initialDefinitionUuid);
    return (
      <div className="xxxxxnewMainSection">
        <DefinitionSelect
          label="Rubric"
          cacheKey={undefined}
          autoFocus={true}
          definitionResolver={rubricDAO}
          initialDefinitionUuid={initialDefinitionUuid}
          onSelect={this.onDefinitionSelection}
        />
      </div>
    );
  };

  renderAssessmentsSelector = () => {
    if (!this.state.selectedDefinition || !this.state.selectedAssessmentUuids) {
      return null;
    }
    return (
      <div className="xxxxxnewMainSection">
        <DefinitionAssessmentSelect
          lastSelectedAssessmentCacheKey={undefined}
          multiple={true}
          allowReadableAssessments={true}
          definitionReset={this.state.definitionReset === true}
          definition={this.state.selectedDefinition}
          assessmentUuids={this.state.selectedAssessmentUuids}
          assessmentDAO={this.assessmentDAO}
          requireAssessmentWritePermission={true}
          autoFocus={false}
          onSelect={this.onAssessmentsSelect}
        />
      </div>
    );
  };

  renderStartCollapsedButton = () => {
    return (
      <div>
        <Toggle
          isChecked={this.state.startCollapsed}
          onChange={this.onToggleStartCollapsed}
        />
        Initially collapsed
      </div>
    );
  };

  renderAssessmentDependentOptions = () => {
    return (
      <React.Fragment>
        <div>
          <Toggle
            isChecked={this.state.viewChart}
            onChange={this.onToggleViewChart}
          />
          {this.state.selectedAssessmentUuids && this.state.selectedAssessmentUuids.length > 1 ? 'View charts' : 'View chart'}
        </div>
      </React.Fragment>
    );
  };

  renderDefinitionDependentOptions = () => {
    return (
      <React.Fragment>
        <div>
          <Toggle
            isChecked={this.state.viewTitle}
            onChange={this.onToggleViewTitle}
          />
          View title
        </div>
        <div>
          <Toggle
            isChecked={this.state.viewTable}
            onChange={this.onToggleViewTable}
          />
          View table
        </div>
      </React.Fragment>
    );
  };

  renderOptions = () => {
    const definitionSelected = this.state.selectedDefinition;
    // const definitionSelected = this.state.selectedDefinitionUuid;
    const assessmentsSelected = this.state.selectedAssessmentUuids && this.state.selectedAssessmentUuids.length;
    return (
      <div className="newMainSection">
        <Label text="Options"/>
        {this.renderStartCollapsedButton()}
        {definitionSelected ? this.renderDefinitionDependentOptions() : null}
        {assessmentsSelected ? this.renderAssessmentDependentOptions() : null}
      </div>
    );
  };

  renderClearFormButton = () => {
    return (
      <ToolbarItem className="newMainSection">
        <Button
          appearance="default"
          onClick={this.onClearFormButtonClick}
        >
          Reset
        </Button>
      </ToolbarItem>
    );
  };

  render() {
    return (
      <div style={{minHeight: '350px'}}>
        <DebugObject
          objectName="ConfluenceMacroEditorPanel"
          visible={this.state.showDebug}
          object={this.state}
        />
        {this.state.definitionIsFixed ? this.renderForcedDefinition() : this.renderDefinitionSelector()}
        {this.state.selectedDefinition ? this.renderAssessmentsSelector() : null}
        {this.renderOptions()}
        {this.renderClearFormButton()}
      </div>
    );
  };

}
