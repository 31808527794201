import React, { FormEvent, PureComponent } from 'react';
import adg from '../../commonbase/adg';
import Button, { ButtonGroup } from '@atlaskit/button';
import ContentWrapper from '../components/widget/ContentWrapper';
import constants from '../../shared/model/Constants';
import ResizingTextArea from '../components/widget/ResizingTextArea';
import LoginView from '../components/auth/LoginView';
import PageTitle from '../components/widget/PageTitle';
import rubricPageHelper from './RubricPageHelper';
import session from '../../shared/model/auth/Session';
import { statusDefinitions, StatusProvider } from '../../shared/model/status/Status';
import firebaseApp from "../../backend/firebase/FirebaseApp";
import Spinner from '../components/widget/loading/Spinner';
import IUser from '../../shared/model/auth/IUser';
import FormlessTextField from '../components/widget/FormlessTextField';

let draftSubject = '';
let draftMessage = '';

interface Props {

}

interface State {
  loggedIn: boolean
  loggedInUser: undefined | IUser
  message: string
  subject: string
  readyToSubmit: boolean
  messageSent: boolean
  errorMessage: undefined | string
  processing: boolean
}

export default class ContactUsPage extends PureComponent<Props, State> {

  statusProvider: StatusProvider;

  constructor(props: Props) {
    super(props);
    const user = session.getCurrentUser();
    this.statusProvider = new StatusProvider('ContactPage');
    this.state = {
      loggedIn: user !== null,
      loggedInUser: user,
      subject: draftSubject,
      message: draftMessage,
      readyToSubmit: false,
      messageSent: false,
      errorMessage: undefined,
      processing: false
    };
    this.statusProvider.setCurrentStatus(statusDefinitions.statuses.nil);
  }

  UNSAFE_componentWillMount() {
    this.statusProvider.setActive(true);
    session.registerListener(this.onSessionChange);
    this.updateStatus(this.state.message, this.state.subject);
  }

  componentDidMount() {
    rubricPageHelper.updateTitle('Contact Us');
  }

  componentWillUnmount() {
    this.statusProvider.setActive(false);
    session.unregisterListener(this.onSessionChange);
  }

  onSessionChange = (user: undefined | IUser) => {
    this.setState({
      loggedIn: user !== null,
      loggedInUser: user
    });
    this.updateStatus(this.state.message, this.state.subject);
  };

  onSubjectChange = (event: FormEvent<HTMLInputElement>) => {
    const subject = event.currentTarget.value;
    draftSubject = subject;
    this.updateStatus(this.state.message, subject);
  };

  onMessageChange = (event: FormEvent<HTMLInputElement>) => {
    const message = event.currentTarget.value;
    draftMessage = message;
    this.updateStatus(message, this.state.subject);
  };

  onSubmitFormClick = () => {
    this.setState({
      processing: true,
      errorMessage: ''
    });
    // Doco on calling Firebase functions: https://firebase.google.com/docs/functions/callable
    const functions = firebaseApp.getFunctions();
    const contactUsFunction = functions.httpsCallable('consolidatedCallHandler');
    const payload = {
      callType: 'contact-us',
      subject: this.state.subject,
      message: this.state.message
    };
    contactUsFunction(payload)
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        if (data && data.ok) {
          draftSubject = '';
          draftMessage = '';
          this.setState({
            subject: '',
            message: '',
            errorMessage: '',
            messageSent: true
          });
        } else if (data && data.errorMessage) {
          this.setState({
            errorMessage: data.errorMessage
          });
        } else {
          this.setState({
            errorMessage: 'An unexpected error occurred.'
          });
        }
      }).catch((error) => {
        // const code = error.code;
        let errorMessage = error.message;
        if (error.details) {
          errorMessage += ': ' + error.details;
        }
        this.setState({
          errorMessage: errorMessage
        });
      }).finally(() => {
        this.setState({
          processing: false
        });
      });
  };

  updateStatus = (message, subject) => {
    let readyToSubmit = false;
    if (this.state.loggedInUser) {
      if (this.state.errorMessage) {
        this.statusProvider.setCurrentStatus(
          statusDefinitions.statuses.problemsExist,
          this.state.errorMessage);
      } else if (subject) {
        if (message) {
          if (message.length > constants.minContactUsMessageLength) {
            readyToSubmit = true;
            this.statusProvider.setCurrentStatus(
              statusDefinitions.statuses.allOK,
              'Ready to submit.');
          } else {
            this.statusProvider.setCurrentStatus(
              statusDefinitions.statuses.mayNeedWork,
              'Your message is a bit too short.');
          }
        } else {
          this.statusProvider.setCurrentStatus(
            statusDefinitions.statuses.workToBeDone,
            'Please enter a message.');
        }
      } else {
        this.statusProvider.setCurrentStatus(
          statusDefinitions.statuses.workToBeDone,
          'Please enter a subject.');
      }
    } else {
      this.statusProvider.setCurrentStatus(
        statusDefinitions.statuses.problemsExist,
        'You need to log in before contacting us.');
    }
    this.setState({
      subject: subject,
      message: message,
      readyToSubmit: readyToSubmit
    });
  };

  buildStyles = () => {
    const padding = '20px';
    return `
    .contact-form {
      display: block;
      padding: 10px;
      min-width: 300px;
      max-width: 650px;
      margin-top: 70px;
      margin-left: auto;
      margin-right: auto;
      background-color: ${adg.globalSidebarColor};
      background-image: linear-gradient(to right, ${adg.globalSidebarColor}, ${adg.adgCyan});
      border-width;
      border-radius: 12px;
    }
    .contact-form-inner {
      background-color: #fff;
      padding: ${padding};
      border-radius: 6px;
    }
    p.contact-form-message {
      text-align: center;
    }
    div.contact-form-button-container {
      margin-top: ${padding};
    }
    `;
  };

  renderMessage = () => {
    let message: undefined | string = undefined;
    let styleName: any = undefined;
    if (this.state.errorMessage) {
      message = this.state.errorMessage;
      styleName = 'error';
    } else if (this.state.messageSent) {
      message = 'Your message has been sent. Thank you :)';
      styleName = 'info';
    }
    if (message) {
      const classNames = `message-banner ${styleName}-message-banner`;
      return (
        <div className={classNames}>
          {message}
        </div>
      );
    } else {
      return null;
    }
  };

  renderForm = () => {
    const user = this.state.loggedInUser;//session.getCurrentUser();
    if (!user) {
      return null;
    }
    const displayName = user.getDisplayName();
    const email = user.getEmail();
    return (
      <div className="contact-form">
        <div className="contact-form-inner">
          <PageTitle align="center">Contact Us</PageTitle>

          <p className="contact-form-message">
            Got a question? We'd love to hear from you.
            Send us a message and we'll respond as soon as possible.
          </p>

          <Spinner visible={this.state.processing}/>

          {this.renderMessage()}

          <FormlessTextField
            name="name"
            label="Name"
            maxLength={constants.maxDisplayNameLength}
            shouldFitContainer={true}
            disabled={true}
            autoFocus={false}
            value={displayName}
          />

          <FormlessTextField
            name="email"
            label="From email"
            maxLength={constants.maxEmailLength}
            shouldFitContainer={true}
            disabled={true}
            autoFocus={false}
            value={email}
          />

          <FormlessTextField
            name="subject"
            label="Subject"
            maxLength={constants.maxSubjectLength}
            shouldFitContainer={true}
            disabled={false}
            autoFocus={true}
            placeholder="Complement, query, complaint..."
            value={this.state.subject}
            onChange={this.onSubjectChange}
          />

          <ResizingTextArea
            label="Message"
            compact={false}
            autoFocus={false}
            shouldFitContainer={true}
            minHeight={40}
            maxHeight={1000}
            maxLength={constants.maxContactUsMessageLength}
            minimumRows={3}
            enableResize={true}
            placeholder="Your message..."
            value={this.state.message}
            onChange={this.onMessageChange}
          />

          <div className="centredContent contact-form-button-container">
            <ButtonGroup>
              <Button
                appearance={this.state.readyToSubmit ? 'primary' : 'default'}
                onClick={this.onSubmitFormClick}
              >
                Submit
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <ContentWrapper>
        <style dangerouslySetInnerHTML={{__html: this.buildStyles()}} />
        {this.state.loggedInUser ?
          this.renderForm() :
          <LoginView message="Please sign in so you can contact us."/>
        }
      </ContentWrapper>
    );
  }
}
