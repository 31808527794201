import React from "react";
import IconButton from './IconButton';
import SafeWidget from './SafeWidget';
import Tooltip from '@atlaskit/tooltip';
import adg from "../../../commonbase/adg";

export default class SafePopupIconButton extends SafeWidget {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    const parentState = this.buildSafeWidgetStateFromProps(props);
    const thisState = {
      normalIcon: props.normalIcon,
      hoverIcon: props.hoverIcon,
      tooltip: props.tooltip
    };
    return Object.assign(parentState, thisState);
  };

  renderNormalState = () => {
    return (
      <Tooltip content={this.state.tooltip}>
        <IconButton
          normalIcon={this.state.normalIcon}
          hoverIcon={this.state.hoverIcon}
          normalColor={undefined}
          hoverColor={adg.adgRed}
          onClick={this.onEnterDangerState}
        />
      </Tooltip>
    );
  };

}
