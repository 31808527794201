import analytics, { analyticsDefinitions } from '../analytics/Analytics';
import commonUtil from '../../../commonbase/util/commonUtil';
import firebaseApp from '../../../backend/firebase/FirebaseApp';
import User from './User';
import IUser from './IUser';
import { LoginState } from './LoginState';

interface FirebaseUserMetadata {
  creationTime: string
  lastSignInTime: string
}

interface FirebaseUser {
  isAnonymous: boolean
  uid: string
  displayName: string
  email: string
  photoURL: string
  metadata: FirebaseUserMetadata
}

export default class FirebaseAuth {

  _firebaseAuthInstanceUuid = commonUtil.uuid();
  _newUserDetectNotificationSent = false;
  _hasNotified = false;
  _lastAuthStateChangeUser: null | IUser = null;
  _lastNotificationUser: null | IUser = null;
  _loginState: LoginState = LoginState.notKnown;
  onAuthStateChanged: Function;
  firebaseAuth: any;

  constructor(onAuthStateChanged: Function) {
    this.onAuthStateChanged = onAuthStateChanged;
    this.firebaseAuth = firebaseApp.getAuth();
    this.firebaseAuth.onAuthStateChanged(this.onFirebaseAuthStateChanged);
  }

  getLoginState = (): LoginState => {
    return this._loginState;
  }

  getMockUsers = () => {
    return [];
  };

  onMockUserLogin = (user) => {
    throw Error('No mock users for Firebase auth.');
  };

  onLoginWithGoogleButtonClick = () => {
    const googleProvider = firebaseApp.getGoogleProvider();
    return this.firebaseAuth.signInWithPopup(googleProvider);
    // signInWithRedirect caused a "accounts.google.com refused to connect" in Connect and monday.com iframes.
    // return this.firebaseAuth.signInWithRedirect(googleProvider);
  };

  signOut = () => {
    return this.firebaseAuth.signOut();
  };

  setCurrentUser = (currentUser) => {
    return sessionStorage.setUser(currentUser);
  };

  getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      const user = this.firebaseAuth.currentUser;
      resolve(user);
    });
  };

  onFirebaseAuthStateChanged = async (firebaseUser: undefined | FirebaseUser) => {
    if (firebaseUser) {
      this._loginState = LoginState.loggedIn;
      if (firebaseUser.isAnonymous) {
        this._possiblyFireAuthStateChanged(null);
      } else {
        const id = firebaseUser.uid;
        const displayName = firebaseUser.displayName;
        const email = firebaseUser.email;
        const photoURL = firebaseUser.photoURL;
        const user = new User(id, displayName, email, photoURL);
        // this._fireAuthStateChanged(user);
        const userPreferenceSync = await this._newUserPreferenceSync();
        await userPreferenceSync.sync(user);
        this._possiblyFireAuthStateChanged(user);
        this._possiblySendNewUserNotification(firebaseUser, user);
      }
    } else {
      this._loginState = LoginState.notLoggedIn;
      this._possiblyFireAuthStateChanged(null);
    }
  };

  _possiblySendNewUserNotification = (firebaseUser: FirebaseUser, user: IUser) => {
    try {
      const metadata = firebaseUser.metadata;
      if (metadata) {
        if (metadata.creationTime && metadata.creationTime === metadata.lastSignInTime) {
          const creationTimestamp = new Date(metadata.creationTime).getTime();
          const now = new Date().getTime();
          const millsSinceCreation = now - creationTimestamp;
          if (millsSinceCreation < 60000) {
            if (!this._newUserDetectNotificationSent) {
              this._newUserDetectNotificationSent = true;
              if (!this._equalUsers(this._lastNotificationUser, user)) {
                this._sendNewUserNotification(firebaseUser, metadata);
                this._lastNotificationUser = user;
                analytics.event({
                  category: analyticsDefinitions.categories.user,
                  action: analyticsDefinitions.actions.newUserAccount,
                  label: undefined,
                  value: undefined
                });
              }
            }
          }
        }
      }
    } catch (error) {
      console.warn(`Unexpected error in _possiblySendNewUserNotification`);
    }
  };

  _sendNewUserNotification = (firebaseUser: FirebaseUser, metadata: FirebaseUserMetadata) => {
    const payload = {
      callType: 'logNewUser',
      id: firebaseUser.uid,
      _firebaseAuthInstanceUuid: this._firebaseAuthInstanceUuid,
      creationTime: metadata.creationTime,
      lastSignInTime: metadata.lastSignInTime
    };
    const functions = firebaseApp.getFunctions();
    return functions.httpsCallable('consolidatedCallHandler')(payload)
      .then((result) => {
        return true;
      })
      .catch((error) => {
        console.warn(error);
        return false;
      });
  };

  _newUserPreferenceSync = async () => {
    // Dynamically import UserPreferenceSync to avoid circular dependencies...
    const UserPreferenceSync = await import('./UserPreferenceSync');
    const userPreferenceSync = new UserPreferenceSync.default();
    return userPreferenceSync;
  }

  _possiblyFireAuthStateChanged = (user: null | IUser) => {
    let fire = false;
    if (this._hasNotified) {
      if (!this._equalUsers(this._lastAuthStateChangeUser, user)) {
        fire = true;
      }
    } else {
      fire = true;
    }
    if (fire) {
      this._hasNotified = true;
      this._lastAuthStateChangeUser = user;
      this.onAuthStateChanged(user);
    }
  }

  _equalUsers = (userA: undefined | null | IUser, userB: undefined | null | IUser): boolean => {
    if (userA && userB) {
      return userA.getId() === userB.getId();
    } else if (userA) {
      return false;
    } else if (userB) {
      return false;
    } else {
      return true;
    }
  }

}

