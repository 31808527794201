import constants from '../../shared/model/Constants';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';

class FirebaseApp {

  constructor() {
    const projectId = "rubricbyluca";
    const apiKey = "AIzaSyCkxYjkB-dODZan9s_FPCHvtq_jUUGANZA";
    const authDomain = constants.appDomain;
    const databaseURL = "https://rubricbyluca.firebaseio.com";
    const messagingSenderId = "560441368888";
    const storageBucket = "rubricbyluca.appspot.com";

    const config = {
      apiKey: apiKey,
      authDomain: authDomain,
      databaseURL: databaseURL,
      projectId: projectId,
      storageBucket: storageBucket,
      messagingSenderId: messagingSenderId
    };
    this.firebaseApp = firebase.initializeApp(config);
  }

  getFirestore = () => {
    if (!this.firestore) {
      this.firestore = firebase.firestore();
      const firestoreSettings = {
        //timestampsInSnapshots: true
      };
      this.firestore.settings(firestoreSettings);
    }
    return this.firestore;
  };

  getDatabase = () => {
    if (!this.database) {
      this.database = firebase.database();
    }
    return this.database;
  };

  getFunctions = () => {
    if (!this.functions) {
      this.functions = firebase.functions();
    }
    return this.functions;
  };

  getAuth = () => {
    this._ensureInitialised();
    return this.auth;
  };

  getGoogleProvider = () => {
    this._ensureInitialised();
    return this.googleAuthProvider;
  }

  _ensureInitialised = () => {
    if (!this.auth) {
      this.googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      // Auth parameter doco: https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters
      this.googleAuthProvider.setCustomParameters({prompt: 'select_account'});
      this.auth = firebase.auth();
    }
  }

}

export default new FirebaseApp();