import React, { PureComponent } from 'react';

export default class PageTitle extends PureComponent {

  render() {
    const style = {
      marginBottom: '0.5em'
    };
    return (
      <h1
        style={style}
        align={this.props.align}
      >
        {this.props.children}
      </h1>
    );
  }

}