import React, { PureComponent } from 'react';
import editabilityDefinitions from '../../../shared/model/rubric/EditabilityDefinitions';
import SelectOption from '../../../shared/model/widget/SelectOption';
import SingleSelect from '../widget/SingleSelect';

interface Props {
  editabilityType: string
  onChange: (editabilityType: string) => void
}

interface State {
  dirty?: boolean
  editabilityType: string
}

export default class EditabilitySelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      editabilityType: props.editabilityType === undefined ?
        this.state.editabilityType : props.editabilityType
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      editabilityType: selection.value
    });
    this.props.onChange(selection.value);
  };

  render() {
    const options: SelectOption[] = editabilityDefinitions.getOptions();
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === this.state.editabilityType) {
        selectedOption = option;
      }
    }
    return (
      <div>
        <SingleSelect
          label="Editability"
          options={options}
          defaultSelected={selectedOption}
          placeholder={undefined}
          noMatchesFound={undefined}
          hasAutocomplete
          appearance="default"
          onSelected={this.onChange}
        />
      </div>
    );
  };

}
