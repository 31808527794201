
import React, { PureComponent } from "react";

type Props = {
  children?: React.ReactNode;
  level: 'error' | 'info',
  containerStyles?: any
}

type State = {
  level: 'error' | 'info',
  containerStyles: any
}

export default class MessageBanner extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props): State => {
    return {
      level: props.level,
      containerStyles: props.containerStyles ? props.containerStyles : {}
    };
  };

  render() {
    return (
      <div className={`message-banner-container ${this.state.level}-message-banner-container`} style={this.state.containerStyles}>
        <div className="message-banner-icon-container">
          <img src={`/img/icon/${this.state.level}.svg`} alt={`${this.state.level} icon`}/>
        </div>
        <div className="message-banner-message-container">
          <div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

}
