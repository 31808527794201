
export interface AnalyticsCategories {
  database: string
  payment: string
  rubric: string
  assessment: string
  user: string,
  mondayApp: string,
  notesApp: string,
  noteView: string
}

export interface AnalyticsActions {
  create: string
  newUserAccount: string
  change: string
  read: string
  write: string
  delete: string
  signInButtonClick: string
  signUpButtonClick: string
  viewApp: string
  viewNotes: string
  upgradePlanButtonClick: string
}

class AnalyticsDefinitions {

  categories: AnalyticsCategories;
  actions: AnalyticsActions;

  constructor() {
    this.categories = {
      database: 'database',
      payment: 'payment',
      rubric: 'rubric',
      assessment: 'assessment',
      user: 'user',
      mondayApp: 'monday-app',
      notesApp: 'notes-app',
      noteView: 'note-view'
    };
    this.actions = {
      create: 'create',
      newUserAccount: 'new-user-account',
      change: 'change',
      read: 'read',
      write: 'write',
      delete: 'delete',
      signInButtonClick: 'sign-in-button-click',
      signUpButtonClick: 'sign-up-button-click',
      viewApp: 'view-app',
      viewNotes: 'view-notes',
      upgradePlanButtonClick: 'upgrade-plan-button-click'
    };
  }

}

export default new AnalyticsDefinitions();
