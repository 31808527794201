import DemoBuilder from '../DemoBuilder';
import demoContent from "./DemoContent";
import { DemoDefinitionBuilder } from '../DemoDefinitionBuilder';

export default class DemoEdit extends DemoBuilder {

  build = () => {
    return new DemoDefinitionBuilder()
      .setDebugOn(false)
      .setRenderComputer(false)
      .setWidth(this.width)
      .setMarginLeft(150)
      .setMarginRight(150)
      .addInstructionGroupings([
        demoContent.addEditPartAInstructions,
        demoContent.addEditPartBInstructions,
      ])
    .build();
  }

}