import React from 'react';
import Button from '@atlaskit/button';
import { CreateRubricIcon } from '../icon/NamedIcons';
import actions from '../../../shared/actions/Actions';
import definitionCreator from '../rubric/definition/DefinitionCreator';
import featureFlags from '../../../shared/model/feature/FeatureFlags';
import ModalDialog from '@atlaskit/modal-dialog';
import ToolbarCenter from '../widget/toolbar/ToolbarCenter';
import ToolbarRight from '../widget/toolbar/ToolbarRight';
import ToolbarItem from '../widget/toolbar/ToolbarItem';

interface Props {
  visible: boolean
  onItemClicked: () => void
  onClose: () => void
}

interface State {
  visible: boolean
}

export default class CreateDefinitionDialog extends React.Component<Props, State> {

  createItems: any[];
  state: State;

  constructor(props: Props) {
    super(props);
    this.createItems = [
      {
        title: 'Quick operations',
        items: [
          ['blank-rubric', undefined, 'Empty rubric', 'New empty rubric', CreateRubricIcon, definitionCreator.onNewBlankRubric],
          ['new-decision-rubric', undefined, 'Decision', 'New decision', CreateRubricIcon, this.onNewDecisionRubric],
          //['new-competitive-analysis-rubric', undefined, 'Competitive analysis', 'New decision', CreateRubricIcon, actions.launchNewCompetitiveAnalysisWizard],

          // ['new-holistic-rubric', undefined, 'Holistic rubric', 'New holistic rubric', CreateRubricIcon, definitionCreator.onNewHolisticRubric],
          // ['new-analytic-rubric', undefined, 'Analytic rubric', 'New analytic rubric', CreateRubricIcon, definitionCreator.onNewAnalyticRubric],
          // ['new-single-point-rubric', undefined, 'Single point rubric', 'New single point rubric', CreateRubricIcon, definitionCreator.onNewSinglePointPointRubric],
          // ['new-levels-rubric', undefined, 'Levels rubric', 'New levels rubric', CreateRubricIcon, definitionCreator.onNewLevelsRubric],
          // ['new-progress-rubric', undefined, 'Progress rubric', 'New progress rubric', CreateRubricIcon, definitionCreator.onNewProgressRubric],

          ['new-example-rubric', undefined, 'Example rubric', 'New example rubric', CreateRubricIcon, definitionCreator.onNewExampleRubric],
        ]
      },
    ];
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    const state = this.buildStateFromProps(props);
    this.setState(state);
  }

  buildStateFromProps = (props: Props): State => {
    const state: State = {
      visible: props.visible
    }
    return state;
  }

  onItemClicked = (event, url, handler) => {
    if (handler) {
      handler();
    }
    this.props.onItemClicked();
  };

  onNewDecisionRubric = () => {
    if (featureFlags.createDecisionWizardEnabled()) {
      actions.launchNewDecisionWizard();
    } else {
      // fallback...
      definitionCreator.onNewDecisionRubric();
    }
  }

  renderDialogBody = () =>  {
    const renderedItems = this.createItems.map(itemGroup => {
      return itemGroup.items.map(item => {
        const [key, url, text, label, Icon, handler] = item;
        return (
          <ToolbarItem key={key}>
            <Button
              appearance="default"
              iconBefore={<Icon label={label} />}
              onClick={(event) => this.onItemClicked(event, url, handler)}
            >
              {text.valueOf()}
            </Button>
          </ToolbarItem>
        );
      });
    });
    return (
      <>
        <ToolbarCenter>
          {renderedItems}
        </ToolbarCenter>
        <ToolbarRight style={{ marginTop: '30px'}}>
          <Button
            appearance="link"
            onClick={(event) => this.props.onClose()}
          >
            Cancel
          </Button>
        </ToolbarRight>
      </>
    );
  }

  renderDialog = () => {
    return (
      <ModalDialog
        key="create-definition"
        heading={'Create a rubric'}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEscapePress={true}
        onClose={this.props.onClose}
      >
        <div style={{ marginTop: '20px', marginBottom: '10px' }}>
          {this.renderDialogBody()}
        </div>
      </ModalDialog>
    )
  }

  render() {
    if (this.state.visible) {
      return this.renderDialog()
    } else {
      return null;
    }
  }

}