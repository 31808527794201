import React, { PureComponent } from 'react';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
import AssessmentDAI from '../../../../backend/data/AssessmentDAI';
import AsyncAssessmentSelect from './AsyncAssessmentSelect';
import session from '../../../../shared/model/auth/Session';
import promiseUtil from '../../../../commonbase/util/promiseUtil';
import DataProvider from '../../../../shared/model/DataProvider';
import Definition from '../../../../shared/model/rubric/definition/Definition';
// import DataSource from '../../../../shared/model/rubric/DataSource';

type Props = {
  assessmentDAO: AssessmentDAI,
  requireAssessmentWritePermission: boolean,
  // label: string,
  disabled?: boolean,
  definition: Definition,
  assessmentUuids: string[],
  lastSelectedAssessmentCacheKey: undefined | string,
  definitionReset: boolean,
  multiple: boolean,
  autoFocus: boolean,
  allowReadableAssessments: boolean,
  onSelect: Function
}

type State = {
  assessmentDAO: AssessmentDAI,
  // label: string,
  disabled: boolean,
  initialising: boolean,
  definition: Definition,
  multiple: boolean,
  // selectedOptions: any,
  assessmentUuids: string[],
  lastSelectedAssessmentCacheKey: undefined | string,
  definitionReset: boolean,
  showDebug?: boolean,
  otherDebug?: any
}

let otherDebug = {
  instanceCount: 0,
  lastProps: undefined
}

export default class DefinitionAssessmentSelect extends PureComponent<Props> implements DataProvider<Assessment[], any> {

  mounted: boolean = false;
  // assessmentDAO: AssessmentDAI;
  select?: any;
  cachedUuidsToAssessments: any = {};
  options: any[] = [];
  state: State;

  constructor(props: Props) {
    super(props);
    if (!props.assessmentDAO) {
      throw new Error('assessmentDAO must be defined');
    }
    // this.assessmentDAO = props.assessmentDAO;
    this.state = this.buildStateFromProps(props);
    otherDebug.instanceCount++;
    this.state.otherDebug = otherDebug;
    this.options = [];
    this.state.showDebug = false;
    // const selectedOptions: any[] = this.state ? this.state.selectedOptions : [];
    // this.state.selectedOptions = selectedOptions;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!props.assessmentDAO) {
      throw new Error(`props.assessmentDAO must not be null`);
    }
    // console.log(`DefinitionAssessmentSelect.componentWillReceiveProps: Setting definition to ${props.definition.name}...`);
    this.setState({
      definition: props.definition,
      assessmentUuids: props.assessmentUuids,
      assessmentDAO: props.assessmentDAO,
      disabled: props.disabled
    });
  }

  UNSAFE_componentWillMount() {
    // console.log(`DefinitionAssessmentSelect.componentWillMount`);
    this.mounted = true;
    // this.updateStateFromInitialAssessmentUuids(this.state.assessmentUuids);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focusOnSelect();
    }
  }

  componentWillUnmount() {
    // console.log(`DefinitionAssessmentSelect.componentWillUnmount`);
    this.mounted = false;
  }

  focusOnSelect = () => {
    if (this.select) {
      this.select.focus();
    }
  };

  buildStateFromProps = (props: Props): any => {
    if (!props.assessmentDAO) {
      throw new Error(`props.assessmentDAO must not be null`);
    }
    this.options = [];
    const initialising = this.state ? this.state.initialising : true;
    const state: any = {
      // label: props.label,
      assessmentDAO: props.assessmentDAO,
      disabled: !!props.disabled,
      initialising: initialising,
      multiple: props.multiple,
      lastSelectedAssessmentCacheKey: props.lastSelectedAssessmentCacheKey,
      definitionReset: props.definitionReset,
      definition: props.definition,
      assessmentUuids: props.assessmentUuids
    };
    return state;
  };

  fetchData = (context: any): Promise<Assessment[]> => {
    // if (!this.mounted) {
    //   console.log(`DefinitionAssessmentSelect.fetchData: Ignoring request to fetch data since unmounted`);
    // }
    if (this.state.definition) {
      const user = session.getCurrentUser();
      if (user === undefined) {
        return promiseUtil.promiseReturning([]);
      }
      const includeArchivedAssessments = false;
      return this.state.assessmentDAO.getAssessmentsByDefinitionUuidAndUser(this.state.definition.uuid, user, includeArchivedAssessments);
    } else {
      return promiseUtil.promiseReturning([]);
    }
  }

  render() {
    // if (!this.mounted) {
    //   console.log(`DefinitionAssessmentSelect.render: Ignoring request to render since unmounted`);
    //   return null;
    // }
    // if (!this.state.definition) {
    //   return null;
    // }
    // if (!this.state.assessmentDAO) {
    //   return null;
    // }
    return (
      <AsyncAssessmentSelect
        key={`async-ass-select-${this.state.definition ? this.state.definition.uuid : 'none'}`}
        dataFetchContext={undefined}
        disabled={this.state.disabled}
        dataProvider={this}
        definition={this.state.definition}
        assessmentDAO={this.state.assessmentDAO}
        requireAssessmentWritePermission={this.props.requireAssessmentWritePermission}
        label={this.state.multiple ? `Assessments` : `Assessment`}
        showPlaceholder={!this.state.definition}
        placeholder="Waiting for rubric selection..."
        assessmentUuids={this.state.assessmentUuids}
        lastSelectedAssessmentCacheKey="last-moment-assessment"
        multiple={this.state.multiple}
        allowReadableAssessments={false}
        definitionReset={this.state.definitionReset}
        autoFocus={false}
        onSelect={this.props.onSelect}
      />
    );
  }

}
