import DemoBuilder from '../DemoBuilder';
import { DemoDefinitionBuilder } from '../DemoDefinitionBuilder';
import demoContent from "./DemoContent";

export default class DemoSearch extends DemoBuilder {

  build = () => {
    return new DemoDefinitionBuilder()
      .setDebugOn(false)
      .setRenderComputer(false)
      .setWidth(this.width)
      .setMarginLeft(150)
      .setMarginRight(150)
      .addInstructionGroupings([
        demoContent.addSearchPartAInstructions,
        demoContent.addSearchPartBInstructions,
      ])
      .build();
  }

}