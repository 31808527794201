import networkConstants from './NetworkConstants';
import util from '../../util/Util';
import Contact from './Contact';

class NetworkUtil {

  equalEmails = (emailA: string, emailB: string): boolean => {
    // if (emailA && emailB) {
      return emailA.trim().toLowerCase() === emailB.trim().toLowerCase();
    // } else {
    //   return false;
    // }
  };

  validateContact = (contact: Contact) => {
    if (contact) {
      if (contact.email === networkConstants.everyoneEmailAddress) {
        // special case
        return undefined;
      }
      if (!contact.name || !contact.name.trim()) {
        return 'All contacts must have a name';
      }
      if (!contact.email || !contact.email.trim()) {
        return 'All contacts must have an email';
      }
      if (!util.isValidEmail(contact.email)) {
        return 'The contact email is invalid.';
      }
      return undefined;
    } else {
      return 'No contact provided';
    }
  };

}

export default new NetworkUtil();