
export default class DemoBuilder {

  width = 500;

  setWidth = (width) => {
    this.width = width;
    return this;
  };

}