import PlanDefinition from './PlanDefinition';

class PlanDefinitions {

  // Only used on the pricing page. Needs to be incorporated into the
  // code before an enterprise plan can be provided.
  enterpriseType = 'enterprise';
  enterprisePlanName = 'Enterprise';
  enterpriseDollarsPerMonth = 4;
  enterpriseStripeProductId = 'TBD';

  liteType = 'lite'; // see also constants.js
  proType = 'pro';
  litePlanName = 'Lite';
  proPlanName = 'Pro';
  liteStripeProductId = '';
  liteDollarsPerMonth = 0;
  proDollarsPerMonth = 2;
  proStripeProductId = 'prod_EOoIVKSGwU4GTn';
  typesToDefinitions = [];
  litePlanDefinition: PlanDefinition = {
    type: this.liteType,
    name: this.litePlanName,
    dollarsPerMonth: this.liteDollarsPerMonth,
    stripeProductId: this.liteStripeProductId,
    // allowAssessments: false,
    allowViewAssessments: true,
    allowCreateAssessments: false
  }
  proPlanDefinition: PlanDefinition = {
    type: this.proType,
    name: this.proPlanName,
    dollarsPerMonth: this.proDollarsPerMonth,
    stripeProductId: this.proStripeProductId,
    // allowAssessments: true,
    allowViewAssessments: true,
    allowCreateAssessments: true
  }
  enterprisePlanDefinition: PlanDefinition = {
    type: this.enterpriseType,
    name: this.enterprisePlanName,
    dollarsPerMonth: this.enterpriseDollarsPerMonth,
    stripeProductId: this.enterpriseStripeProductId,
    // allowAssessments: true,
    allowViewAssessments: true,
    allowCreateAssessments: true
  }

  constructor() {
    this.typesToDefinitions[this.liteType] = this.litePlanDefinition;
    this.typesToDefinitions[this.proType] = this.proPlanDefinition;
    this.typesToDefinitions[this.enterpriseType] = this.enterprisePlanDefinition;
  }

  isProOrGreater = (planType: string) => {
    if (planType) {
      return planType === this.proType || planType === this.enterpriseType;
    } else {
      return false;
    }
  };

  formatDollarsPerMonth = (dollarsPerMonth: number) => {
    if (dollarsPerMonth === 0) {
      return 'FREE';
    } else if (dollarsPerMonth) {
      return '$' + dollarsPerMonth + '.00 / month';
    } else {
      return '?';
    }
  };

  buildItemsContext = (currentType: string) => {
    const liteItem = {
      content: this.typesToDefinitions[this.liteType].name,
      value: this.liteType
    };
    const proItem = {
      content: this.typesToDefinitions[this.proType].name,
      value: this.proType
    };
    const enterpriseItem = {
      content: this.typesToDefinitions[this.enterpriseType].name,
      value: this.enterpriseType
    };
    const selectItems = [{
      items: [liteItem, proItem, enterpriseItem]
    }];
    let selectedItem: undefined | any = undefined;
    for (let i = 0; i < selectItems[0].items.length; i++) {
      const item = selectItems[0].items[i];
      if (item.value === currentType) {
        selectedItem = item;
      }
    }
    return {
      items: selectItems,
      selectedItem: selectedItem
    };
  };

  getDefaultType = () => {
    return this.liteType;
  };

}

export default new PlanDefinitions();
