import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import './ExpandingText.css';
import widgetUtil from '../../../commonbase/util/widgetUtil';

interface Props {
  markdown: string
  introHeight: number
  cutoffTolerance: number
  seeMoreText?: string
  seeLessText?: string
  expanded?: boolean
}

interface State {
  introHeight: number
  cutoffTolerance: number
  minimisedHeight?: number
  textFullHeight?: number
  html: string
  expanded: boolean
  expandable?: boolean
  seeMoreText: string
  seeLessText: string
}

export default class ExpandingText extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    const html = widgetUtil.markdownToHtml(props.markdown);
    const expanded = (this.state && this.state.expanded) || (!this.state && props.expanded);
    return {
      introHeight: props.introHeight,
      cutoffTolerance: props.cutoffTolerance,
      html: html,
      seeLessText: props.seeLessText ? props.seeLessText : 'see less',
      seeMoreText: props.seeMoreText ? props.seeMoreText + '...' : 'see more...',
      expanded: expanded
    };
  };

  onToggleExpandClick = (event) => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  renderExpandCollapseLink = () => {
    const text = this.state.expanded ? this.state.seeLessText : this.state.seeMoreText;
    const className = this.state.expanded ? 'collapse-button-over-text' : 'expand-button-over-text';
    return (
      <div className={className}>
        <Button
          appearance="link"
          spacing="none"
          shouldFitContainer={false}
          iconBefore={this.state.expanded ? <ChevronUpIcon label="collaps" /> : <ChevronDownIcon label="expand" />}
          onClick={this.onToggleExpandClick}
        >
          {text}
        </Button>
      </div>
    );
  };

  renderHeightCalculator = () => {
    return (
      <div
        className="text-height-calculator"
        dangerouslySetInnerHTML={{ __html: this.state.html }}
        ref={(element: any) => {
          if (element) {
            let minimisedHeight = this.state.introHeight;
            if (element.clientHeight < this.state.introHeight + this.state.cutoffTolerance) {
              minimisedHeight = this.state.introHeight + this.state.cutoffTolerance;
            }
            this.setState({
              minimisedHeight: minimisedHeight,
              textFullHeight: element.clientHeight,
              expandable: element.clientHeight > this.state.introHeight + this.state.cutoffTolerance
            });
          }
        }}
      >
      </div>
    );
  }

  renderText = () => {
    const style: any = {};
    if (!this.state.expanded) {
      style.maxHeight = this.state.minimisedHeight;
    }
    return (
      <div
        style={style}
        className="expanding-text-container"
        dangerouslySetInnerHTML={{ __html: this.state.html }}
      >
      </div>
    );
  }

  render() {
    const renderedTextHeightCalculator = this.state.textFullHeight ? null : this.renderHeightCalculator();
    return (
      <>
        {renderedTextHeightCalculator}
        {this.renderText()}
        {this.state.expandable ? this.renderExpandCollapseLink() : null}
      </>
    );
  }

}
