import React, { PureComponent } from "react";
import analytics, {analyticsDefinitions} from "../../../shared/model/analytics/Analytics";
import Button from '@atlaskit/button';
import i18n from "../../../shared/model/i18n/I18N";
import session from "../../../shared/model/auth/Session";
import Spinner from '@atlaskit/spinner';

interface Props {

}

interface State {
  visible: boolean
}

export default class SignInWithGoogleButton extends PureComponent<Props, State> {

  delayToVisibility = 2000;
  mounted = false;

  constructor(props: Props) {
    super(props);
    this.mounted = false;
    // this.state = this.buildStateFromProps(props);
    // this.state.visible = false;
    this.state = {
      visible: false
    }
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    if (!this.state.visible) {
      this.setInitialVisibility();
    }
  };

  componentWillUnmount() {
    // This is never called since it's within WithUser which is within MainRoute.withRouter. 
    // See https://github.com/ReactTraining/react-router/blob/800991cd2044293718fccd3763b9d91c2f811296/docs/guides/ComponentLifecycle.md
    this.mounted = false;
  }

  componentDidUpdate() {
    if (this.mounted && !this.state.visible) {
      this.setInitialVisibility();
    }
  };

  setInitialVisibility = () => {
    if (session.isAfterFirstStateChangeTime() || this.delayToVisibility === 0) {
      if (this.mounted) {
        this.setState({
          visible: true
        });
      } else {
        // const state = this.state;
        // state.visible = true;
      }
    } else {
      setTimeout(this.setInitialVisibility, 500);
    }
  };

  onSignInClick = (event: any) => {
    analytics.event({
      category: analyticsDefinitions.categories.user,
      action: analyticsDefinitions.actions.signInButtonClick
    });
    session.onLoginWithGoogleButtonClick();
  };

  renderPasswordLoginForm = () => {
    return (
      <span
        style={{
          fontSize: '18px'
        }}
      >
        <Button
          appearance="primary"
          onClick={this.onSignInClick}
        >
          {i18n.Login_with_Google}
        </Button>
      </span>
    );
  };

  renderSpinner = () => {
    return (
      <Spinner
        size="medium"
        delay={1000}
      />
    );
  }

  render() {
    return this.state.visible ? this.renderPasswordLoginForm() : this.renderSpinner();
  }

}
