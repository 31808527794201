import React from "react";
import ConnectAppIframe from '../ConnectAppIframe';
// import Button from '@atlaskit/button';
// import commonConstants from '../../../../commonbase/commonConstants';
import confluenceMacroUtil from './ConfluenceMacroUtil';
import DefinitionTitleLink from '../../rubric/definition/DefinitionTitleLink';
import firestoreAssessmentDAO from '../../../../backend/data/FirestoreAssessmentDAO';
import EmbeddedView from '../../rubric/EmbeddedView';
import Expand from '../../widget/Expand';
import InfoMessageBanner from '../../widget/banner/InfoMessageBanner';
// import session from '../../../../shared/model/auth/Session';
import storageDAO from '../../../../backend/data/StorageDAO';
import csvUtil from '../../../../commonbase/csvUtil';
import urlUtil from '../../../../shared/util/UrlUtil';
import { startCollapsedDefault, viewTitleDefault, viewChartDefault, viewTableDefault } from './ConfluenceMacroConstants';

/* global AP */

export default class ConfluenceMacroView extends ConnectAppIframe {

  assessmentDAO = firestoreAssessmentDAO;
  mounted = false;

  // Linking is disabled at this stage because the experience of embedding the entire site in a app
  // iframe is poor.
  shouldRenderTitleAsLink = false;

  constructor(props) {
    super(props);
    this.loadState(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);

    // this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.mounted = true;
    // this.setState(this.buildStateFromProps(this.props));
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    this.mounted = false;
  };

  componentDidMount() {
    super.componentDidMount();
  };

  componentDidUpdate() {
    // this.sizeAppFrameToContent(0, 500, 1000, 3000);
    this.debouncedSizeAppFrameToContent();
  }

  loadState = (props) => {
    const queryADefinitionUuid = urlUtil.getQueryParameter('definition-uuid');
    const queryBDefinitionUuid = urlUtil.getQueryParameter('selectedDefinitionUuid');
    const forcedDefinitionUuid = queryADefinitionUuid ? queryADefinitionUuid : queryBDefinitionUuid;
    const forcedSelectedAssessmentUuidsCsv = urlUtil.getQueryParameter('selectedAssessmentUuidsCsv');
    // console.log('Roobrick: ConfluenceMacroView.loadState: selectedDefinitionName:', selectedDefinitionName);
    // console.log('Roobrick: ConfluenceMacroView.loadState: forcedDefinitionUuid:', forcedDefinitionUuid);
    if (AP) {
      AP.require("confluence", (confluence) => {
        confluence.getMacroData((macroParams) => {
          // console.log('Roobrick: ConfluenceMacroView.loadState: macroParams:', macroParams);
          const selectedDefinitionName = confluenceMacroUtil.getStringMacroParameter(macroParams, 'selectedDefinitionName');
          const selectedAssessmentUuids = forcedSelectedAssessmentUuidsCsv && forcedSelectedAssessmentUuidsCsv.length ?
            csvUtil.csvToArray(forcedSelectedAssessmentUuidsCsv) :
            csvUtil.csvToArray(macroParams.selectedAssessmentUuidsCsv);
          this.setState({
            selectedDefinitionName: selectedDefinitionName,
            forcedDefinitionUuid: forcedDefinitionUuid,
            selectedDefinitionUuid: forcedDefinitionUuid ? forcedDefinitionUuid : macroParams.selectedDefinitionUuid,
            selectedAssessmentUuids: selectedAssessmentUuids,
            collapsed: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'startCollapsed', startCollapsedDefault),
            viewTitle: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'viewTitle', viewTitleDefault),
            viewChart: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'viewChart', viewChartDefault),
            viewTable: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'viewTable', viewTableDefault)
          });
        });
      });
    } else {
      storageDAO.getData('mock-confluence-params', undefined).then((macroParams) => {
        if (!macroParams) {
          macroParams = this.getDefaultMacroParams();
        }
        const selectedDefinitionName = confluenceMacroUtil.getStringMacroParameter(macroParams, 'selectedDefinitionName');
        const selectedDefinitionUuid = forcedDefinitionUuid ? forcedDefinitionUuid : macroParams.selectedDefinitionUuid;
        const selectedAssessmentUuids = forcedSelectedAssessmentUuidsCsv && forcedSelectedAssessmentUuidsCsv.length ?
          csvUtil.csvToArray(forcedSelectedAssessmentUuidsCsv) :
          csvUtil.csvToArray(macroParams.selectedAssessmentUuidsCsv);
        this.setState({
          selectedDefinitionName: selectedDefinitionName,
          forcedDefinitionUuid: forcedDefinitionUuid,
          selectedDefinitionUuid: selectedDefinitionUuid,
          selectedAssessmentUuids: selectedAssessmentUuids,
          collapsed: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'startCollapsed', startCollapsedDefault),
          viewTitle: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'viewTitle', viewTitleDefault),
          viewChart: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'viewChart', viewChartDefault),
          viewTable: confluenceMacroUtil.getBooleanMacroParameter(macroParams, 'viewTable', viewTableDefault)
        });
      });
    }
  };

  getDefaultMacroParams = () => {
    return {
      selectedDefinitionUuid: undefined
    };
  };

  onExpanded = () => {
    this.setState({
      collapsed: false
    });
    // this.sizeAppFrameToContent(50, 100, 200, 500, 2000);
    this.debouncedSizeAppFrameToContent();
  };

  onCollapsed = () => {
    this.setState({
      collapsed: true
    });
    // this.sizeAppFrameToContent(50, 100, 200, 500, 2000);
    this.debouncedSizeAppFrameToContent();
  };

  onDefinitionAndAssessmentsResolved = (definition, visibleAssessments) => {
    let title = definition.name;
    if (visibleAssessments && visibleAssessments.length) {
      title += ': ';
      let nextSeparator = '';
      for (const assessment of visibleAssessments) {
        title += nextSeparator + assessment.name;
        nextSeparator = ' vs ';
      }
    }
    this.setState({
      titleFromResolvedObjects: title
    });
  };

  renderPlainTitle = (title) => {
    return (
      <h3>{title}</h3>
    );
  };

  renderTitleAsLink = (title, definitionUuid, definitionName) => {
    return (
      <DefinitionTitleLink
        title={title}
        definitionUuid={definitionUuid}
        definitionName={definitionName}
      />
    );
  };

  renderTitle = (definitionUuid, definitionName) => {
    const title = this.state.titleFromResolvedObjects ? this.state.titleFromResolvedObjects : definitionName;
    return (
      <div style={{paddingBottom: '10px', textAlign: 'center', fontWeight: 'bold'}}>
        {this.shouldRenderTitleAsLink ?
          this.renderTitleAsLink(title, definitionUuid, definitionName) :
          this.renderPlainTitle(title)}
      </div>
    );
  };

  renderDefinitionView = (definitionUuid, definitionName) => {
    const visibleAssessmentUuids = [];
    if (this.state.selectedAssessmentUuids) {
      for (const assessmentUuid of this.state.selectedAssessmentUuids) {
        visibleAssessmentUuids.push(assessmentUuid);
      }
    }
    return (
      <EmbeddedView
        definitionUuid={this.state.selectedDefinitionUuid}
        definitionName={definitionName}
        visibleAssessmentUuids={visibleAssessmentUuids}
        assessmentDAO={this.assessmentDAO}
        viewChart={this.state.viewChart}
        viewTable={this.state.viewTable}
        onDefinitionAndAssessmentsResolved={this.onDefinitionAndAssessmentsResolved}
      />
    );
  };

  renderDefinitionLinkAndView = () => {
    const definitionName = this.state.selectedDefinitionName;
    const definitionUuid = this.state.selectedDefinitionUuid;
    return (
      <React.Fragment>
        {this.state.viewTitle ? this.renderTitle(definitionUuid, definitionName) : null}
        {this.renderDefinitionView(definitionUuid, definitionName)}
      </React.Fragment>
    )
  };

  renderSelectedDefinition = () => {
    const text = this.state.collapsed ? `Show ${this.state.selectedDefinitionName}` : `Hide ${this.state.selectedDefinitionName}`;
    return (
      <Expand
        contentForeignKey={undefined}
        text={text}
        initiallyExpanded={!this.state.collapsed}
        contentRenderer={this.renderDefinitionLinkAndView}
        stopPropagation={true}
        onExpanded={this.onExpanded}
        onCollapsed={this.onCollapsed}
      />
    );
  };

  renderInstructions = () => {
    return (
      <InfoMessageBanner
        containerStyles={{ padding: '40px 0px' }}
      >
        No rubric has been selected. Edit the page, select the macro panel and then click the pencil button to select a rubric.
      </InfoMessageBanner>
    );
  };

  renderDebug = () => {
    return (
      <div>
        Confluence macro view. selectedDefinitionUuid is {this.state.selectedDefinitionUuid}
      </div>
    );
  };

  renderConnectAppIframe = () => {
    // Should change this to re-use EmbeddedAssessmentView if possile
    return (
      <div>
        {this.state.selectedDefinitionUuid ? this.renderSelectedDefinition() : this.renderInstructions()}
        {this.state.showDebug ? this.renderDebug() : null}
      </div>
    );
  };

}
