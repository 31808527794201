import constants from '../model/Constants';
import collectionsUtil from '../../commonbase/util/collectionsUtil';
import commonUtil from '../../commonbase/util/commonUtil';

export class Util {

  // eslint-disable-next-line no-useless-escape
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  uuid = () => {
    return commonUtil.uuid();
  };

  average = (scores) => {
    let totalScore = 0;
    for (let i = 0; i < scores.length; i++) {
      totalScore += scores[i];
    }
    const averageScore = totalScore / scores.length;
    return averageScore;
  };

  debounce = (func, wait, immediate): Function => {
    let lastTimeout;
    const context = this;
    return function() {
      // const context = this;
      const args = arguments;
      const later = () => {
        lastTimeout = null;
        if (!immediate) {
          func.apply(context, args);
        };
      };
      const callNow = immediate && !lastTimeout;
      clearTimeout(lastTimeout);
      lastTimeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  debouncePromise = (func, wait, immediate) => {
    let lastTimeout;
    const context = this;
    return function() {
      const args = arguments;
      return new Promise((resolve, reject) => {
        // const context = this;
        // const args = arguments;
        const later = () => {
          lastTimeout = null;
          if (!immediate) {
            func.apply(context, args).then((result) => {
              resolve(result);
            });
          };
        };
        const callNow = immediate && !lastTimeout;
        clearTimeout(lastTimeout);
        lastTimeout = setTimeout(later, wait);
        if (callNow) {
          func.apply(context, args).then((result) => {
            resolve(result);
          });
        }
      });
    };
  };

  isValidEmail = (text) => {
    return text && this.emailPattern.test(text.trim().toLowerCase());
  };

  extractDomainFromEmail = (email) => {
    const parts = email.split('@');
    if (parts.length === 2) {
      return parts[1];
    } else {
      return undefined;
    }
  };

  sortObjectsByField = (objects, fieldName, descending?) => {
    if (objects && objects.length) {
      const comparator = (a, b) => {
        if (a[fieldName] < b[fieldName]) {
          return descending ? 1 : -1;
        } else if (a[fieldName] > b[fieldName]) {
          return descending ? -1 : 1;
        } else {
          return 0;
        }
      };
      objects.sort(comparator);  
    } else {
      return objects;
    }
  };

  shallowCompareObjects = (objectA: any, objectB: any): boolean => {
    const keysA = Object.keys(objectA);
    const keysB = Object.keys(objectB);
    if (keysA.length === keysB.length) {
      for (const keyA of keysA) {
        const valueA = objectA[keyA];
        const valueB = objectA[keyA];
        if (valueA !== valueB) {
          return false;
        }
      }
      for (const keyB of keysA) {
        const valueA = objectA[keyB];
        const valueB = objectA[keyB];
        if (valueA !== valueB) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  parseDate = (dateText, optionalTimezone) => {
    const timestamp = this.parseDateAsTimestamp(dateText, optionalTimezone);
    return new Date(timestamp);
  };

  parseDateAsTimestamp = (dateText, optionalTimezone) => {
    const fullDateText = optionalTimezone ? dateText + ' ' + optionalTimezone : dateText;
    return Date.parse(fullDateText);
  };

  // Example result: 'Sep 15, 2017'
  formatDate = (dateOrTimestamp) => {
    const date = typeof dateOrTimestamp === 'number' ? new Date(dateOrTimestamp) : dateOrTimestamp;
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return constants.abbreviatedMonthNames[monthIndex] + ' ' + day + ', ' + year;
  };

  cloneDate = (date) => {
    return new Date(date.getTime());
  };

  rollDays = (date, days) => {
    date.setDate(date.getDate() + days);
  };

  rollWeeks = (date, weeks) => {
    date.setDate(date.getDate() + weeks * 7);
  };

  rollMonths = (date, months) => {
    const preferredDayOfMonth = date.getDate();
    let dayIncrementSize = months > 0 ? 1 : -1;
    let requiredMonthRollCount = months > 0 ? months : -1 * months;
    let prevMonth = date.getMonth();
    let monthRollCount = 0;
    while (monthRollCount < requiredMonthRollCount) {
      date.setDate(date.getDate() + dayIncrementSize);
      let currentMonth = date.getMonth();
      if (currentMonth !== prevMonth) {
        monthRollCount++;
      }
      prevMonth = currentMonth;
    }

    const resultingMonth = date.getMonth();
    date.setDate(preferredDayOfMonth);
    let adjustedMonth = date.getMonth();
    while (adjustedMonth !== resultingMonth) {
      this.rollDays(date, -1);
      adjustedMonth = date.getMonth();
    }
  };

  computeAverageByTotalAndCount = (total, count) => {
    if (count) {
      return total / count;
    } else {
      return 0;
    }
  };

  computeAverage = (values) => {
    if (values && values.length > 0) {
      let total = 0;
      for (let i = 0; i < values.length; i++) {
        total = total + values[i];
      }
      return total / values.length;
    } else {
      // debugger;
      return undefined;
    }
  };

  checkObjectHasNoUndefinedValues = (object) => {
    this._checkObjectHasNoUndefinedValues(object, '');
  };

  _checkObjectHasNoUndefinedValues = (object, path) => {
    const keys = Object.keys(object);
    for (const key of keys) {
      const value = object[key];
      if (value === undefined) {
        console.warn(`Found an undefined value at ${path + '.' + key}.`);
        // debugger;
      } else if (typeof value === 'object') {
        this._checkObjectHasNoUndefinedValues(value, path + '.' + key);
      }
    }
  };

  mergeObjectArrays = (objectsA, objectsB, discriminatorFieldName) => {
    const mergedObjects: any = [];
    const visitedDiscriminators = {};
    for (let i = 0; i < objectsA.length; i++) {
      const object = objectsA[i];
      mergedObjects.push(object);
      visitedDiscriminators[object[discriminatorFieldName]] = true;
    }
    for (let i = 0; i < objectsB.length; i++) {
      const object = objectsB[i];
      if (!visitedDiscriminators[object[discriminatorFieldName]]) {
        mergedObjects.push(object);
        visitedDiscriminators[object[discriminatorFieldName]] = true;
      }
    }
    return mergedObjects;
  };

  replaceItemInArray = (replacedItem, items, discriminatorFieldName) => {
    const replacedItems: any = [];
    for (const item of items) {
      const discriminatedItemValue = item[discriminatorFieldName];
      const discriminatedReplacementItemValue = replacedItem[discriminatorFieldName];
      if (discriminatedItemValue === discriminatedReplacementItemValue) {
        replacedItems.push(replacedItem);
      } else {
        replacedItems.push(item);
      }
    }
    return replacedItems;
  };

  isInArray = (itemToCheck, items, identifierFieldName?: string) => {
    return collectionsUtil.isInArray(itemToCheck, items, identifierFieldName ? identifierFieldName : '');
  };

  /**
   * Example call: util.moveArrayItem(myItems, myItem.uuid, 'uuid', true);
   */
  moveArrayItem = (items, itemId, identifierFieldName, moveTowardsBeginning) => {
    const itemIndex = this.findItemIndex(items, itemId, identifierFieldName);
    if (itemIndex !== undefined) {
      if (itemIndex > 0 && moveTowardsBeginning) {
        const prevItem = items[itemIndex - 1];
        const thisItem = items[itemIndex];
        items[itemIndex] = prevItem;
        items[itemIndex - 1] = thisItem;
      } else if (itemIndex < (items.length - 1) && !moveTowardsBeginning) {
        const thisItem = items[itemIndex];
        const nextItem = items[itemIndex + 1];
        items[itemIndex + 1] = thisItem;
        items[itemIndex] = nextItem;
      }
    }
  };

  /**
   * Example call: const index = util.findItemIndex(myItems, myItem.uuid, 'uuid');
   */
  findItemIndex = (items, itemOrItemId, identifierFieldName) => {
    let foundIndex: undefined | number = undefined;
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      if (identifierFieldName) {
        if (item[identifierFieldName] === itemOrItemId) {
          foundIndex = index;
        }
      } else {
        if (item === itemOrItemId) {
          foundIndex = index;
        }
      }
    }
    return foundIndex;
  };

  filterOut = (items, itemsToFilterOut, identifierFieldName) => {
    const filteredItems: any = [];
    for (const item of items) {
      const foundIndex = this.findItemIndex(itemsToFilterOut, item[identifierFieldName], identifierFieldName);
      if (foundIndex === undefined) {
        filteredItems.push(item);
      } else {
        // don't add
      }
    }
    return filteredItems;
  };

  deepCloneObject = (sourceObject) => {
    const sourceObjectJson = JSON.stringify(sourceObject);
    const clonedObject = JSON.parse(sourceObjectJson);
    return clonedObject;
  };

  shallowCloneObject = (originalObject) => {
    const clonedObject = {};
    const keys = Object.keys(originalObject);
    for (const key of keys) {
      clonedObject[key] = originalObject[key];
    }
    return clonedObject;
  };

  shallowCloneArray = (items) => {
    if (items) {
      const clonedItems: any = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        clonedItems.push(item);
      }
      return clonedItems;
    } else {
      return items; // null or undefined
    }
  };

  interpolate = (valueA, valueB, factor) => {
    const difference = valueB - valueA;
    return valueA + factor * difference;
  };

  /**
   * Returns a random number between min (inclusive) and max (inclusive)
   */
  getRandomValue = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  /**
  * Returns a random integer between min (inclusive) and max (inclusive).
  * The value is no lower than min (or the next integer greater than min
  * if min isn't an integer) and no greater than max (or the next integer
  * lower than max if max isn't an integer).
  * Using Math.round() will give you a non-uniform distribution!
  */
  getRandomInteger = (min, max) => {
    min = Math.ceil(min);
    const maxPlus1 = Math.floor(max);
    return Math.floor(Math.random() * (maxPlus1 - min + 1)) + min;
  }

  randomPick = (items) => {
    if (items && items.length) {
      const randomIndex = this.getRandomInteger(0, items.length - 1);
      return items[randomIndex];
    } else {
      return undefined;
    }
  };

}

export default new Util();
