import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import CustomLevelsEditRow from './CustomLevelsEditRow';
import InlineEdit from '@atlaskit/inline-edit';
import Label from '../../widget/Label';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import Tooltip from "@atlaskit/tooltip";
import { gridSize, fontSize } from '@atlaskit/theme';
import util from '../../../../shared/util/Util';

export default class CustomLevelsEditor extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    let customLevelsScoreInfo = props.definition.customLevelsScoreInfo;
    if (!props.definition.customLevelsScoreInfo) {
      customLevelsScoreInfo = {
        levels: []
      };
    }
    return {
      definition: props.definition,
      customLevelsScoreInfo: customLevelsScoreInfo
    };
  };

  setCustomLevelsScoreInfo = (customLevelsScoreInfo) => {
    this.setState({
      customLevelsScoreInfo: customLevelsScoreInfo
    });
    this.props.onChange(customLevelsScoreInfo);
  };

  onAddLevelButtonClick = () => {
    const levelCount = this.state.customLevelsScoreInfo.levels.length;
    const label = 'L' + (levelCount + 1);
    const newLevel = {
      uuid: util.uuid(),
      label: label,
      tooltip: ''
    };
    const customLevelsScoreInfo = util.deepCloneObject(this.state.customLevelsScoreInfo);
    customLevelsScoreInfo.levels.push(newLevel);
    this.setCustomLevelsScoreInfo(customLevelsScoreInfo);
  };

  onDeleteLevelByUuid = (uuid) => {
    const customLevelsScoreInfo = util.deepCloneObject(this.state.customLevelsScoreInfo);
    const levels = [];
    for (const level of customLevelsScoreInfo.levels) {
      if (level.uuid !== uuid) {
        levels.push(level);
      }
    }
    customLevelsScoreInfo.levels = levels;
    this.setCustomLevelsScoreInfo(customLevelsScoreInfo);
  };


  onLevelChange = (changedLevel) => {
    const customLevelsScoreInfo = util.deepCloneObject(this.state.customLevelsScoreInfo);
    const levels = [];
    for (const level of customLevelsScoreInfo.levels) {
      if (level.uuid === changedLevel.uuid) {
        levels.push(changedLevel);
      } else {
        levels.push(level);
      }
    }
    customLevelsScoreInfo.levels = levels;
    this.setCustomLevelsScoreInfo(customLevelsScoreInfo);
  };

  moveLevelByUuid = (levelUuid, moveUp) => {
    const customLevelsScoreInfo = util.deepCloneObject(this.state.customLevelsScoreInfo);
    const levels = customLevelsScoreInfo.levels;
    util.moveArrayItem(levels, levelUuid, 'uuid', moveUp);
    this.setState({
      customLevelsScoreInfo: customLevelsScoreInfo
    });
    this.props.onChange(customLevelsScoreInfo);
  };

  onMoveLevelUpByUuid = (requirementUuid) => {
    this.moveLevelByUuid(requirementUuid, true);
  };

  onMoveLevelDownByUuid = (requirementUuid) => {
    this.moveLevelByUuid(requirementUuid, false);
  };

  renderAddCustomLevel = () => {
    const ReadViewContainer = styled.div`
      display: flex;
      font-size: ${fontSize()}px;
      line-height: ${(gridSize() * 2.5) / fontSize()};
      max-width: 100%;
      min-height: ${(gridSize() * 2.5) / fontSize()}em;
      padding: ${gridSize()}px ${gridSize() - 2}px;
      word-break: break-word;
    `;
    return (
      <tr>
        <td>
          <InlineEdit
            defaultValue={this.state.editValue}
            label={undefined}
            editView={fieldProps => <Textfield {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {this.state.editValue || 'Click to enter value'}
              </ReadViewContainer>
            )}
            onConfirm={value => this.setState({ editValue: value })}
          />
          <Textfield
            name="event-handlers"
            width={40}
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            onFocus={this.handleOnFocus}
          />
        </td>
      </tr>
    );
  };

  renderScoreMetaLevels = () => {
    // if (!this.state.customLevelsScoreInfo) {
    //   // return null;
    //   this.state.customLevelsScoreInfo = {
    //     levels: []
    //   }
    // }
    const customLevelsScoreInfo = this.state.customLevelsScoreInfo;
    const levels = customLevelsScoreInfo && customLevelsScoreInfo.levels ? customLevelsScoreInfo.levels : [];
    const renderedLevels = levels.map((level, index) => {
      return (
        <tr key={'level-' + index}>
          <CustomLevelsEditRow
            uuid={level.uuid}
            abbreviation={level.label}
            tooltip={level.tooltip}
            levelIndex={index}
            levelCount={levels.length}
            onDeleteLevelByUuid={this.onDeleteLevelByUuid}
            onMoveLevelUpByUuid={this.onMoveLevelUpByUuid}
            onMoveLevelDownByUuid={this.onMoveLevelDownByUuid}
            onChange={this.onLevelChange}
          />
        </tr>
      );
    });
    return (
      <div>
        <table>
          <thead style={{borderBottom: '0px'}}>
            <tr>
              <th>
              </th>
              <th>
              </th>
              <th>
              </th>
              <th>
                <Tooltip content="No longer than 3 letters or numbers">
                  <Label text="Abbreviation" paddingTop="0px"/>
                </Tooltip>
              </th>
              <th>
                <Tooltip content="Extra details about the level" position="right">
                  <Label text="Tooltip" paddingTop="0px"/>
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody style={{borderBottom: '0px'}}>
            {renderedLevels}
            <tr>
              <td colSpan="4">
                <Button
                  onClick={this.onAddLevelButtonClick}
                >
                  Add custom level
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <div style={{marginLeft: '26px'}}>
        {this.renderScoreMetaLevels()}
      </div>
    );
  }

}
