import React, { PureComponent } from 'react';
import Label from '../../components/widget/Label';
import Toggle from '@atlaskit/toggle';

export default class ColumnVisibilitySelector extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      definition: props.definition,
      columnVisibilities: props.columnVisibilities
    };
  };

  renderColumnVisibilityControls() {
    return this.state.definition.columns.map((column, columnIndex) => {
      return (
        <div key={'column-' + columnIndex}>
          <Toggle
            createAnalyticsEvent="false"
            isChecked={this.state.columnVisibilities[columnIndex]}
            onChange={() => {this.props.onVisibilityToggle(column, columnIndex)}}
          />
          {column.name}
        </div>
      );
    });
  };

  render() {
    if (this.state.definition) {
      return (
        <div>
          <Label text="Column visibilities"/>
          {this.renderColumnVisibilityControls()}
        </div>
      );
    } else {
      return null;
    }
  };

}
