import React from "react";
import AppIframe from './AppIframe';
import connectJavascriptApi from './ConnectJavascriptApi';
import util from '../../../shared/util/Util';

export default class ConnectAppIframe extends AppIframe {

  debouncedSizeAppFrameToContent: () => void;
  
  constructor(props) {
    super(props);
    this.debouncedSizeAppFrameToContent = util.debounce(this.sizeAppFrameToContent, 1000, false) as () => void;
  }

  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  // componentDidUpdate() {
  //   // this.sizeAppFrameToContent(0, 500, 1000, 3000);
  //   this.debouncedSizeAppFrameToContent();
  // };

  sizeAppFrameToContent = () => {
    const AP = connectJavascriptApi.getAP();
    if (AP) {
      AP.resize();
    }
  }

  // OLDsizeAppFrameToContent = (delay1, delay2, delay3, delay4) => {
  //   if (AP) {
  //     AP.resize();
  //     if (delay1 !== undefined && delay1 >= 0) {
  //       setTimeout(() => {
  //         AP.resize();
  //         if (delay2 !== undefined && delay2 >= 0) {
  //           setTimeout(() => {
  //             AP.resize();
  //             if (delay3 !== undefined && delay3 >= 0) {
  //               setTimeout(() => {
  //                 AP.resize();
  //                 if (delay4 !== undefined && delay4 >= 0) {
  //                   setTimeout(() => {
  //                     AP.resize();
  //                   }, delay4);
  //                 }
  //               }, delay3);
  //             }
  //           }, delay2);
  //         }
  //       }, delay1);
  //     }
  //   }
  // };

  renderConnectAppIframe = () => {
    return (
      <div>
        Override this method
      </div>
    );
  };

  renderLoggedInContent = () => {
    return this.renderConnectAppIframe();
  };

}
