import constants from '../../../shared/model/Constants';

export class NavConstants {
  
  // hiddenContainerKey = 'nav-items-with-this-key-will-not-be-shown';
  productContainerKey = 'product-container';
  appContainerKey = 'app-container';

  searchDrawerKey = 'search-drawer';
  createDrawerKey = 'create-drawer';

  topNavPositionLeft = 'left';
  topNavPositionRight = 'right';

  // Left nav
  // globalSidebarWidth = 64;
  // collapsedSecondarySidebarWidth = 10;
  // expandedSecondarySidebarWidth = 245;

  globalSidebarWidth = 0;
  collapsedSecondarySidebarWidth = 0;
  expandedSecondarySidebarWidth = 0;

  blogUrl = constants.appBaseUrl + '/blog';

  homePageHash = '/';
  contactUsPageHash = '/contact';
  notificationsPageHash = '/notifications';
  signInPageHash = '/sign-in';
  profilePageHash = '/profile';
  aboutPageHash = '/about';
  privacyPageHash = '/privacy';
  pricingPageHash = '/pricing';
  helpPageHash = '/help';
  rubricCreationuidePageHash = '/rubric-creation-guide';
  termsOfUsePageHash = '/terms-of-use';

  boardPageHash = '/board';
  dashboardPageHash = '/dashboard';
  drivePageHash = '/drive';
  networkPageHash = '/network';
  editPageHash = '/edit';
  viewPageHash = '/view';
  assessmentEntryPointPageHash = '/ass';
  reportPageHash = '/report';

  searchPageHash = '/search';
  createPageHash = '/create';

  defaultAppPageHash = this.boardPageHash;

  notesPrivacyPolicyPageHash = '/notes-privacy';

}

export default new NavConstants();
