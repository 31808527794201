import React, { PureComponent } from 'react';
import demoContent from './content/DemoContent';
import DemoPanel from './DemoPanel';
import { DemoDefinitionBuilder } from './DemoDefinitionBuilder';

export default class HighlightsPanel extends PureComponent {

  constructor(props) {
    super(props);
    const delayBetweenImages = 3000;
    const definition = new DemoDefinitionBuilder()
      .setDebugOn(false)
      .setWidth(1000)
      .setMarginLeft(150)
      .setMarginRight(150)
      .setDefaultDelay(delayBetweenImages)
      .addInstructionGroupings([
        demoContent.addTypicalRubricInstructions,
        demoContent.addPerStatementAssessmentsInstructions,
        demoContent.addAspectPieChartInstructions,
        demoContent.addCellEditingInstructions,
        demoContent.addColumnHidingInstructions,
        demoContent.addFavouritesInstructions,
        demoContent.addCreatePartAInstructions,
        demoContent.addCreatePartBInstructions,
        demoContent.addSharingPartAInstructions,
        demoContent.addSharingPartBInstructions,
      ])
      .build();
    this.state = {
      definition: definition
    };
  }

  renderDemo = () => {
    return (
      <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'fit-content'
      }}
    >
        <DemoPanel definition={this.state.definition}/>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderDemo()}
      </React.Fragment>
    );
  }

}