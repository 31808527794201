import React from "react";
// import App, { AppProps, AppState } from '../../modules/App';
import App from '../../modules/App';
// import Button from '@atlaskit/button';
import appViewState from '../../modules/AppViewState';
import '@atlaskit/css-reset';
import constants from '../../../shared/model/Constants';
import ContainerDimensions from 'react-container-dimensions';
import dashboardState from "../rubric/dashboard/DashboardState";
import EmptyState from '@atlaskit/empty-state';
import emptyStateImages from '../widget/EmptyStateImages';
import navConstants from '../nav/NavConstants';
import navUtil from '../nav/NavUtil';
import session from '../../../shared/model/auth/Session';
import SignInWithGoogleButton from '../widget/SignInWithGoogleButton';
// import { AppNavInfo } from "../nav/AppNavInfo";

// interface Props extends AppProps{

// }

// interface State extends AppState {

// }

export default class AppIframe extends App {

  mounted: boolean = false;

  constructor(props) {
    super(props);
    this.mounted = false;
    dashboardState.enable();
  }

  UNSAFE_componentWillReceiveProps(props) {
    // super.UNSAFE_componentWillReceiveProps(props, undefined);
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.mounted = true;
    const state = this.buildStateFromProps(this.props);
    this.setState(state);
    session.registerListener(this._onSessionChange);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.mounted = false;
    session.unregisterListener(this._onSessionChange);
  }

  getCurrentUser = () => {
    return this.state.user;
  }

  /**
   * This method is intended for overriding.
   */
  onSessionChange = (user) => {
  }
  
  _onSessionChange = (user) => {
    if (this.mounted) {
      this.setState({
        user: user
      });
    }
    this.onSessionChange(user);
  };

  buildStateFromProps = (props) => {
    const state = {
      flags: [],
      user: session.getCurrentUser(),
      title: props.title,
      renderedContent: props.content
    };
    return state;
  };

  allowImageBackgrounds = () => {
    // Image backgrounds look a bit odd in apps
    return false;
  };

  allowCookieBanner = () => {
    // Cookies can't always be saved in app iframes.
    return false;
  };

  renderLoggedInContent = () => {
    return (
      <div>
        Override this method
      </div>
    );
  };

  renderLoggedInAppIframe = () => {
    return (
      <div style={{marginRight: '20px'}}>
        {this.renderLoggedInContent()}
      </div>
    );
  };

  renderLoginButton = () => {
    return (
      <SignInWithGoogleButton />
    );
  };

  renderCantLoginView = () => {
    const signInUrl = navUtil.buildPageUrl(navConstants.signInPageHash);
    return (
      <React.Fragment>
        Can't login? 
        Visit <a href={signInUrl} target="_blank" rel="noopener noreferrer">{constants.appTitle}</a> in
        a new window.
      </React.Fragment>
    );
  };

  renderLoggedOutContent = () => {
    const description = constants.appByline;
    const size = this.props.size ? this.props.size : 'narrow';
    const dimensions = size === 'narrow' ? { width: 450, height: 100 } : { width: 900, height: 200 };
    return (
      <EmptyState
        header={`Sign in to ${constants.appTitle}`}
        description={description}
        imageUrl={emptyStateImages.getAnyEmptyStateImage()}
        size={size}
        maxImageWidth={dimensions.width}
        maxImageHeight={dimensions.height}
        primaryAction={this.renderLoginButton()}
        tertiaryAction={this.renderCantLoginView()}
      />
    );
  }

  renderAppContent = () => {
    return (
      <ContainerDimensions>
        {
          ({ width, height }) => {
            const scrollbarWidth = 25;
            const rightMargin = 20;
            const appContainerLeftX = 0;
            const appContainerWidth = width - scrollbarWidth;
            appViewState.setAppContainerLeftX(appContainerLeftX);
            appViewState.setAppContainerWidth(appContainerWidth - rightMargin);
            return (
              <React.Fragment>
                <div className="ac-content">
                  {this.state.user ? this.renderLoggedInAppIframe() : this.renderLoggedOutContent()}
                </div>
              </React.Fragment>
            );
          }
        }
      </ContainerDimensions>
    );
  }

}
