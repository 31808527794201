import React, { FormEvent, PureComponent } from 'react';
import actions from '../../../../shared/actions/Actions';
import Button from '@atlaskit/button';
import constants from '../../../../shared/model/Constants';
import ContainerDimensions from 'react-container-dimensions';
import { DatePicker } from '@atlaskit/datetime-picker';
import DebugObject from '../../widget/DebugObject';
import DecisionStatusSelector from './DecisionStatusSelector';
import decisionStatusDefinitions from './DecisionStatusDefinitions';
import definitionCreator from '../definition/DefinitionCreator';
import Label from '../../widget/Label';
import ModalDialog from '@atlaskit/modal-dialog';
import util from '../../../../shared/util/Util';
import dateUtil from '../../../../commonbase/util/dateUtil';
import FormlessTextField from '../../widget/FormlessTextField';
import ToolbarCenter from '../../widget/toolbar/ToolbarCenter';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

interface Props {
  title?: string,
  dueDate?: Date,
}

interface State {
  wizardActive: boolean,
  title?: string
  aspects: string[],
  aspectsIndexesToConsiderations: any,
  statusType: string,
  impact: undefined | string,
  driver: undefined | string,
  approver: undefined | string,
  contributors: undefined | string,
  informed: undefined | string,
  outcome: undefined | string,
  optionNames: string[],
  errorMessage?: undefined | string,
  stepIndex?: number,
  dueDate: Date,
  debug: boolean
}

export default class NewDecisionWizard extends PureComponent<Props, State> {

  mounted = false;
  stepIdGetBasicInfo = 'get-basic-info';
  stepIdGetPeopleInfo = 'get-people-info';
  stepIdGetAspects = 'get-aspects';
  stepIdgetOptionNames = 'get-option-names';
  stepIds = [
    this.stepIdGetBasicInfo,
    this.stepIdGetPeopleInfo,
    this.stepIdGetAspects,
    // this.stepIdgetOptionNames
  ];
  placeholderAspects = [
    'Color',
    'Strategy alignment',
    'Customer value',
    'Price',
    'Effort',
    'Strength'
  ];
  axisColor: string;

  constructor(props: Props) {
    super(props);
    this.axisColor = '#777';
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    const newState = this.buildStateFromProps(props);
    this.setState(newState);
  }

  componentDidMount() {
    actions.registerListener(this._onAction);
    this.mounted = true;
  };

  componentWillUnmount() {
    actions.unregisterListener(this._onAction);
    this.mounted = false;    
  };

  buildStateFromProps = (props: Props): State => {
    const aspects = ['', '', '', ''];
    const aspectsIndexesToConsiderations = {};
    const dueDate: Date = props.dueDate ? props.dueDate : this.state ? this.state.dueDate : new Date();
    return {
      wizardActive: false,
      title: props.title,
      aspects: aspects,
      aspectsIndexesToConsiderations: aspectsIndexesToConsiderations,
      // status: 'Not started',
      statusType: decisionStatusDefinitions.getDefaultType(),
      impact: undefined,
      driver: undefined,
      approver: undefined,
      contributors: undefined,
      informed: undefined,
      dueDate: dueDate,
      outcome: undefined,
      optionNames: ['Option A', 'Option B', 'Option C'],
      debug: false
    }
  }

  _onAction = (actionId: string, context: any) => {
    if (this.mounted) {
      if (actionId === actions.launchNewDecisionWizardActionId) {
        this._launchNewDecisionWizard();
      }
    }
  };

  _launchNewDecisionWizard = () => {
    this.setState({
      wizardActive: true,
      errorMessage: undefined,
      stepIndex: 0,
      title: this.state.title ? this.state.title : ''
    });
  };

  removeEmptyAspects = (aspects: string[]) => {
    return aspects && aspects.length ? aspects.filter(aspect => !!aspect) : [];
  };

  onTitleChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      title: event.currentTarget.value.trim()
    });
  };

  onDueDateChange = (newDateText: string) => {
    const newDate = new Date(newDateText);
    this.setState({
      dueDate: newDate
    });
  };

  onStatusChange = (statusType) => {
    this.setState({
      statusType: statusType
    });
  };

  onImpactChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      impact: event.currentTarget.value.trim()
    });
  };

  onDriverChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      driver: event.currentTarget.value.trim()
    });
  };

  onApproverChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      approver: event.currentTarget.value.trim()
    });
  };

  onContributorsChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      contributors: event.currentTarget.value.trim()
    });
  };

  onInformedChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      informed: event.currentTarget.value.trim()
    });
  };

  onOutcomeChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      outcome: event.currentTarget.value.trim()
    });
  };

  onAspectChange = (event: FormEvent<HTMLInputElement>, aspectIndex) => {
    const minSpareAspectCount = 1;
    const minAspectCount = 4;
    const aspects: string[] = [];
    let spareAspectCount = 0;
    for (let i = 0 ; i < this.state.aspects.length; i++) {
      const aspect = i === aspectIndex ? event.currentTarget.value.trim() : this.state.aspects[i];
      aspects.push(aspect);
      if (aspect) {
        
      } else {
        spareAspectCount++;
      }
    }
    const additionAspectCount = minSpareAspectCount - spareAspectCount;
    for (let i = 0 ; i < additionAspectCount; i++) {
      aspects.push('');
    }
    for (let i = 0 ; i < minAspectCount - aspects.length; i++) {
      aspects.push('');
    }
    this.setState({
      aspects: aspects
    });
  };

  onPreviousClick = () => {
    if (this.state.stepIndex !== undefined && this.state.stepIndex > 0) {
      this.setState({
        stepIndex: this.state.stepIndex - 1
      });
    }
  };

  onNextClick = () => {
    if (this.state.stepIndex !== undefined && this.state.stepIndex < this.stepIds.length - 1) {
      this.setState({
        stepIndex: this.state.stepIndex + 1
      });
    }
  };

  onDoneClick = () => {
    const statusText = decisionStatusDefinitions.getStatusText(this.state.statusType);
    const options = {
      title: this.state.title,
      status: statusText,
      impact: this.state.impact,
      driver: this.state.driver,
      approver: this.state.approver,
      contributors: this.state.contributors,
      informed: this.state.informed,
      dueDateTimestamp: this.state.dueDate === undefined ? undefined : this.state.dueDate.getTime(),
      aspects: this.removeEmptyAspects(this.state.aspects),
      aspectsIndexesToConsiderations: this.state.aspectsIndexesToConsiderations,
      outcome: this.state.outcome,
      assessmentNames: this.state.optionNames,
      builtByWizard: true
    };
    definitionCreator.onNewDecisionRubric(options);
    this.setState({
      wizardActive: false,
      errorMessage: undefined,
      stepIndex: 0
    });
  };

  onCancelClick = () => {
    this.setState({
      wizardActive: false
    });
  };

  onModalClosed = () => {
    // ignore
  };

  renderGetBasicInfoStep = () => {
    return (
      <React.Fragment key="basic-info">
        <div>
          <FormlessTextField
            name="title"
            label="Decision title"
            placeholder="Which car should I buy?"
            maxLength={constants.maxDefinitionNameLength}
            value={this.state.title}
            onChange={this.onTitleChange}
          />
        </div>
        <div>
          <DecisionStatusSelector
            statusType={this.state.statusType}
            onChange={this.onStatusChange}
          />
        </div>
        <div>
          <FormlessTextField
            name="impact"
            label="Impact"
            placeholder="Impact of this decision: Low, Medium or High."
            maxLength={30}
            shouldFitContainer={true}
            value={this.state.impact}
            onChange={this.onImpactChange}
          />
        </div>
        <div>
          <Label text="When does the decision need to be made by?" />
          <DatePicker
            id="due-date"
            value={this.state.dueDate.toISOString()}
            dateFormat={dateUtil.dateFormat}
            formatDisplayLabel={dateUtil.parseAndFormatDate}
            parseInputValue={dateUtil.parseDate}
            onChange={this.onDueDateChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderGetPeopleInfoStep = () => {
    return (
      <React.Fragment key="people-info">
        <div>
          <FormlessTextField
            name="driver"
            label="Driver"
            placeholder="Who is the person driving this decision."
            maxLength={constants.maxDisplayNameLength}
            shouldFitContainer={true}
            value={this.state.driver}
            onChange={this.onDriverChange}
          />
        </div>
        <div>
          <FormlessTextField
            name="approver"
            label="Approver"
            placeholder="Who should be the approver of this decision."
            maxLength={3 * constants.maxDisplayNameLength}
            shouldFitContainer={true}
            value={this.state.approver}
            onChange={this.onApproverChange}
          />
        </div>
        <div>
          <FormlessTextField
            name="contributors"
            label="Contributors"
            placeholder="Who should contribute to this decision."
            maxLength={10 * constants.maxDisplayNameLength}
            shouldFitContainer={true}
            value={this.state.contributors}
            onChange={this.onContributorsChange}
          />
        </div>
        <div>
          <FormlessTextField
            name="informed"
            label="Informed"
            placeholder="Who should be informed of this decision."
            maxLength={10 * constants.maxDisplayNameLength}
            shouldFitContainer={true}
            value={this.state.informed}
            onChange={this.onInformedChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderGetAspectsStep = () => {
    const renderedAspects = this.state.aspects.map((aspect, index) => {
      return (
        <React.Fragment key="aspects">
          <div>
          <FormlessTextField
              name="aspects"
              label={undefined}
              placeholder={'e.g. ' + util.randomPick(this.placeholderAspects)}
              maxLength={constants.maxDefinitionRowNameLength}
              shouldFitContainer={true}
              value={aspect}
              onChange={(event: FormEvent<HTMLInputElement>) => this.onAspectChange(event, index)}
            />
          </div>
        </React.Fragment>
      );
    });
    return (
      <div key="get-aspects">
        <Label text="Aspects"/>
        {renderedAspects}
      </div>
    );
  };

  renderWizardStep = () => {
    if (this.state.stepIndex !== undefined) {
      const stepId = this.stepIds[this.state.stepIndex];
      if (stepId === this.stepIdGetBasicInfo) {
        return this.renderGetBasicInfoStep();
      } else if (stepId === this.stepIdGetPeopleInfo) {
        return this.renderGetPeopleInfoStep();
      } else if (stepId === this.stepIdGetAspects) {
        return this.renderGetAspectsStep();
        // } else if (stepId === this.stepIdgetOptionNames) {
        //   return this.rendergetOptionNamesStep();
      }
    }
    return null;
  };

  renderWizardContent = (width, height) => {
    return (
      <div>
        {this.renderWizardStep()}
      </div>
    );
  };

  renderPreviousStepButton = () => {
    if (this.state.stepIndex !== undefined && this.state.stepIndex > 0) {
      return (
        <ToolbarItem>
          <Button
            appearance="default"
            isDisabled={false}
            onClick={this.onPreviousClick}
          >
            Previous
          </Button>
        </ToolbarItem>
      );
    }
    return null;
  };

  renderNextStepButton = () => {
    if (this.state.stepIndex !== undefined && this.state.stepIndex < this.stepIds.length - 1) {
      return (
        <ToolbarItem>
          <Button
            appearance="default"
            isDisabled={false}
            onClick={this.onNextClick}
          >
            Next
          </Button>
        </ToolbarItem>
      );
    }
    return null;
  };

  renderDoneButton = () => {
    if (this.state.stepIndex === this.stepIds.length - 1) {
      return (
        <ToolbarItem>
          <Button
            appearance="primary"
            isDisabled={false}
            onClick={this.onDoneClick}
          >
            Create decision
          </Button>
        </ToolbarItem>
      );
    }
    return null;
  };

  renderErrorBanner = (message) => {
    return (
      <div className="message-banner error-message-banner">
        {message}
      </div>
    );
  };

  renderDialog = () => {
    const heading = this.state.title ? `New decision: ${this.state.title}` : 'Make a new decision';
    return (
      <ModalDialog
        key="new-decision"
        heading={heading}
        width="large"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        onClose={this.onModalClosed}
      >
        {this.state.errorMessage ? this.renderErrorBanner(this.state.errorMessage) : null}
        <div className="centredContent" style={{width: '100%', marginTop: '10px', marginBottom: '10px'}}>
          <div style={{marginBottom: '20px'}}>
            <table>
              <tbody style={{borderBottomWidth: '0px'}}>
                <tr>
                  <td>
                    <ContainerDimensions>{
                      ({ width, height }) => {
                        return this.renderWizardContent(width, height);
                      }
                    }</ContainerDimensions>
                  </td>
                </tr>
                {this.state.debug ? this.renderDebug() : null}
                <DebugObject
                  objectName="NewDecisionWizard"
                  visible={this.state.debug}
                  object={this.state}
                />
              </tbody>
            </table>
          </div>
          <ToolbarCenter>
            <ToolbarItem>
              <Button
                appearance="subtle"
                isDisabled={false}
                onClick={this.onCancelClick}
              >
                Cancel
              </Button>
            </ToolbarItem>
            {this.renderPreviousStepButton()}
            {this.renderNextStepButton()}
            {this.renderDoneButton()}
          </ToolbarCenter>
        </div>
      </ModalDialog>
    );
  };

  renderDebug = () => {
    return null;
    // return (
    //   <div>
    //     NewDecisionWizard state: {this.state}
    //   </div>
    // );
  };

  render() {
    if (this.state.wizardActive) {
      return this.renderDialog();
    }
    return null;
  }

}
