import React, { PureComponent } from 'react';
import adg from '../../../commonbase/adg';
import { DeleteRubricIcon } from '../icon/NamedIcons';
import actions from '../../../shared/actions/Actions';
import rubricDAO from '../../../backend/data/RubricDAO';
import SafePopupButton from '../widget/SafePopupButton';
import util from '../../../shared/util/Util';
import { SuccessIcon, ErrorIcon } from '../../components/icon/NamedIcons';
import SafePopupIconButton from "../widget/SafePopupIconButton";

export default class DeleteRubricButton extends PureComponent {

  _createSuccessFlag = () => {
    const flag = {
      id: util.uuid(),
      icon: <SuccessIcon label="Success" primaryColor={adg.adgGreen} secondaryColor="#fff" />,
      title: 'Rubric deleted',
      description: `The rubric ${this.props.definition.name} has been deleted.`,
      flagMessageTimeoutSeconds: 5
    };
    actions.addFlag(flag);
  };

  _createErrorFlag = (error) => {
    const message = error.toString().replace('Error:', '');
    const flag = {
      id: util.uuid(),
      icon: <ErrorIcon label="Error" primaryColor={adg.adgRed} secondaryColor="#fff" />,
      title: 'Rubric NOT deleted',
      description: message,
      flagMessageTimeoutSeconds: 10
    };
    actions.addFlag(flag);
  };

  onDeleteRubric = () => {
    rubricDAO.deleteDefinition(this.props.definition)
      .then(() => {
        this._createSuccessFlag();
        if (this.props.onRubricDeleted) {
          this.props.onRubricDeleted();
        }
      }).catch((error) => {
        this._createErrorFlag(error);
        if (this.props.onRubricNotDeleted) {
          this.props.onRubricNotDeleted();
        }
      });
    return true;
  };

  render() {
    const confirmationTitle = 'Confirm rubric deletion';
    const confirmationMessage = `Are you sure you want to delete rubric "${this.props.definition.name}"?`;
    if (this.props.renderAsIconButton) {
      return (
        <SafePopupIconButton
          confirmationMessage={confirmationMessage}
          normalIcon={<DeleteRubricIcon/>}
          hoverIcon={<DeleteRubricIcon/>}
          tooltip={`Delete rubric "${this.props.definition.name}"`}
          onConfirmation={this.onDeleteRubric}
        />
      );
    } else {
      return (
        <SafePopupButton
          confirmationTitle={confirmationTitle}
          confirmationMessage={confirmationMessage}
          iconBefore={<DeleteRubricIcon/>}
          onConfirmation={this.onDeleteRubric}
        />
      );
    }
  };

}
