import React, { PureComponent } from 'react';
import adg from '../../../../commonbase/adg';
import DemoPlayer from './DemoPlayer';
import demoRenderUtil from './DemoRenderUtil';
import demoUtil from './DemoUtil';
import PathBuilder from '../../svg/PathBuilder';

export default class DemoPanel extends PureComponent {

  constructor(props) {
    super(props);
    const demoDefinition = props.definition;
    this.state = {
      demoDefinition: demoDefinition,
      instructions: demoDefinition.instructions,
      imageLinks: [],
      visibleComponents: [],
      controlBarClassName: 'non-fadeable-player-control-bar',
      paused: false,
      progressPercent: 0
    };
  }

  // UNSAFE_componentWillMount() {
  // };

  componentDidMount() {
    this.stateful = true;
    const imageLinks = demoUtil.getImageLinks(this.state.demoDefinition);
    this.setState({
      imageLinks: imageLinks
    });
    if (this.demoPlayer) {
      this.demoPlayer.dispose();
      this.demoPlayer = undefined;
    }
    const handlers = {
      onVisibleComponentsChange: this.onVisibleComponentsChange,
      onPlayerStateChange: this.onPlayerStateChange
    };
    this.demoPlayer = new DemoPlayer(
      this.state.demoDefinition,
      this.state.instructions,
      handlers);
    this.demoPlayer.start();
    setTimeout(this.fadeControlBar, 3000);
  };

  fadeControlBar = () => {
    this.setState({
      controlBarClassName: 'fadeable-player-control-bar',
    });
  };

  componentWillUnmount() {
    this.clearVisibleComponents();
    this.stateful = false;
    if (this.demoPlayer) {
      this.demoPlayer.dispose();
      this.demoPlayer = undefined;
    }
  };

  onVisibleComponentsChange = (visibleComponents) => {
    this.setState({
      visibleComponents: visibleComponents
    });
  };

  onPlayerStateChange = (paused, progressPercent) => {
    this.setState({
      paused: paused,
      progressPercent: progressPercent
    });
  };

  onPlayClick = () => {
    if (this.demoPlayer) {
      this.demoPlayer.resume();
    }
  };

  onPauseClick = () => {
    if (this.demoPlayer) {
      this.demoPlayer.pause();
    }
  };

  onFastForwardClick = () => {
    if (this.demoPlayer) {
      this.demoPlayer.advance();
    }
  };

  onReverseClick = () => {
    if (this.demoPlayer) {
      this.demoPlayer.reverse();
    }
  };

  clearVisibleComponents = () => {
    this.setState({
      visibleComponents: []
    });
  };

  renderDebug = () => {
    const def = this.state.demoDefinition;
    return (
      <g>
        <rect
          strokeWidth={1}
          stroke="red"
          fill="none"
          x={0}
          y={0}
          width={def.width}
          height={def.height}
        />
      </g>
    );
  };

  renderPointerComponent = (component) => {
    const def = this.state.demoDefinition;
    const pointer = component.data;
    const pointerEndX = def.frameInsideLeft + def.imageWidth * pointer.imageEndRatioX;
    const pointerEndY = def.frameInsideTop + def.imageHeight * pointer.imageEndRatioY;
    const fontSize = 16;
    let pointerStartX;
    let pointerStartY = def.frameInsideTop + def.imageHeight * pointer.imageStartRatioY;
    let textAnchor;
    let textOffset;
    if (pointer.side === 'left') {
      pointerStartX = def.marginLeft - def.pointerTextPadding;
      textAnchor = 'end';
      textOffset = -4;
    } else {
      pointerStartX = def.width - def.marginRight + def.pointerTextPadding;
      textAnchor = 'start';
      textOffset = 4;
    }
    const renderedTexts = pointer.lines.map((line, lineIndex) => {
      return (
        <text
          key={'text-' + lineIndex}
          stroke={def.color.pointer}
          fill={def.color.pointer}
          x={pointerStartX + textOffset}
          y={pointerStartY + fontSize / 3 + lineIndex * fontSize}
          textAnchor={textAnchor}
          fontSize={fontSize}
        >
          {line}
        </text>
      );
    });
    return (
      <g>
        <circle
          strokeWidth={2}
          stroke={def.color.pointer}
          fill={def.color.pointer}
          cx={pointerEndX}
          cy={pointerEndY}
          r={def.pointerRadius}
        />
        <line
          strokeWidth={3}
          stroke={def.color.pointer}
          x1={pointerStartX}
          y1={pointerStartY}
          x2={pointerEndX}
          y2={pointerEndY}
        />
        {renderedTexts}
      </g>
    );
  };

  renderImageComponent = (component) => {
    const def = this.state.demoDefinition;
    return (
      <image
        xlinkHref={component.data.image.src}
        x={def.frameInsideLeft + component.data.image.offsetX}
        y={def.frameInsideTop}
        width={def.imageWidth}
        height={def.imageHeight}
      />
    );
  };

  renderImageMaskComponent = (component) => {
    const def = this.state.demoDefinition;
    return (
      <rect
        strokeWidth={0}
        fill="#fff"
        x={def.frameInsideLeft}
        y={def.frameInsideTop}
        width={def.imageWidth}
        height={def.imageHeight}
      />
    );
  };

  renderStand = () => {
    const def = this.state.demoDefinition;
    const middleX = def.marginLeft + def.frameBorderWidth + def.imageWidth / 2;
    const standUpperWidth = def.frameWidth / 5;
    const standLowerWidth = def.frameWidth / 4.8;
    const standBottomWidth = def.frameWidth / 3.3;
    const standUpperY = def.frameOutsideBottom;
    const standLowerY = def.height - def.height / 30;
    const standBottomY = def.height;
    const stand = new PathBuilder()
      .setPathColor(def.color.stand)
      .setFill(def.color.stand)
      .moveTo(middleX - standUpperWidth / 2, standUpperY)
      .lineTo(middleX - standLowerWidth / 2, standLowerY)
      .lineTo(middleX - standBottomWidth / 2, standBottomY)
      .lineTo(middleX + standBottomWidth / 2, standBottomY)
      .lineTo(middleX + standLowerWidth / 2, standLowerY)
      .lineTo(middleX + standUpperWidth / 2, standUpperY)
      .close()
      .render();
    return (
      <g>
        {stand}
        <rect />
      </g>
    );
  };

  renderFrame = () => {
    const def = this.state.demoDefinition;
    return (
      <g>
        <rect
          strokeWidth={0}
          stroke={def.color.frame}
          fill={def.color.frame}
          x={def.frameOutsideLeft}
          y={def.frameOutsideTop}
          width={def.frameOutsideRight - def.frameOutsideLeft}
          height={def.frameOutsideBottom - def.frameOutsideTop}
          rx={def.frameOutsideRadius}
          ry={def.frameOutsideRadius}
        />
        <rect
          strokeWidth={0}
          stroke={def.color.imageAreaBackgroundColor}
          fill={def.color.imageAreaBackgroundColor}
          x={def.frameInsideLeft}
          y={def.frameInsideTop}
          width={def.frameInsideRight - def.frameInsideLeft}
          height={def.frameInsideBottom - def.frameInsideTop}
        />
      </g>
    );
  };

  renderComponent = (component, componentIndex) => {
    let renderedComponent = null;
    if (component.type === 'image') {
      renderedComponent = this.renderImageComponent(component);
    } else if (component.type === 'imageMask') {
      renderedComponent = this.renderImageMaskComponent(component);
    } else if (component.type === 'pointer') {
      renderedComponent = this.renderPointerComponent(component);
    } else {
      // Unknown component
      // debugger;
    }
    return (
      <g key={'component-' + componentIndex}>
        {renderedComponent}
      </g>
    );
  };

  renderControls = ()=> {
    const def = this.state.demoDefinition;
    const controlBarHeight = 60;
    const centreX = def.frameCentreX;
    const centreY = def.frameInsideBottom - 0.6 * controlBarHeight;
    const bar = demoRenderUtil.renderControlBar(
      this.state.controlBarClassName,
      centreX, centreY, controlBarHeight, this.state.paused, this.state.progressPercent,
      this.onPlayClick, this.onPauseClick, this.onFastForwardClick, this.onReverseClick);
    return bar;
  };

  renderImagePrefetchLinks = () => {
    const imageLinks = this.state.imageLinks;
    if (imageLinks) {
      return imageLinks.map((imageLink, index) => {
        return (
          <link key={'prefetch-image-' + index} rel="prefetch" href={imageLink}/>
        );
      })
    } else {
      return null;
    }
  };

  render() {
    const def = this.state.demoDefinition;
    const renderedComponents = this.state.visibleComponents.map((component, componentIndex) => {
      return this.renderComponent(component, componentIndex);
    });
    return (
      <React.Fragment>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={def.width}
          height={def.height}
          className="demo-area"
        >
          {def.renderComputer ? this.renderStand() : null}
          {def.renderComputer ? this.renderFrame() : null}
          {renderedComponents}
          {this.renderControls()}
          {def.debugOn ? this.renderDebug() : null}
        </svg>
        {this.renderImagePrefetchLinks()}
      </React.Fragment>
    );
  }

  renderFrameOld = () => {
    const def = this.state.demoDefinition;
    const frameOutside = new PathBuilder()
      .setPathColor(adg.adgGray)
      .setFill(adg.adgGray)
      .moveTo(def.frameOutsideLeft, def.frameOutsideTop + def.frameOutsideRadius)
      .lineTo(def.frameOutsideLeft, def.frameOutsideBottom - def.frameOutsideRadius)
      .arcTo(
        def.frameOutsideRadius, def.frameOutsideRadius,
        1, 0, 0,
        def.frameOutsideLeft + def.frameOutsideRadius, def.frameOutsideBottom)
      .lineTo(def.frameOutsideRight - def.frameOutsideRadius, def.frameOutsideBottom)
      .arcTo(
        def.frameOutsideRadius, def.frameOutsideRadius,
        1, 0, 0,
        def.frameOutsideRight, def.frameOutsideBottom - def.frameOutsideRadius)
      .lineTo(def.frameOutsideRight, def.frameOutsideTop + def.frameOutsideRadius)
      .arcTo(
        def.frameOutsideRadius, def.frameOutsideRadius,
        1, 0, 0,
        def.frameOutsideRight - def.frameOutsideRadius, def.frameOutsideTop)
      .lineTo(def.frameOutsideLeft + def.frameOutsideRadius, def.frameOutsideTop)
      .arcTo(
        def.frameOutsideRadius, def.frameOutsideRadius,
        1, 0, 0,
        def.frameOutsideLeft, def.frameOutsideTop + def.frameOutsideRadius)
      // .close()
      .render();
    return (
      <g>
        {frameOutside}
        <rect />
      </g>
    );
  };

}