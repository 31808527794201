import ListenerGroup from '../../shared/util/ListenerGroup';
import featureFlags from '../../shared/model/feature/FeatureFlags';
import planDefinitions from '../../shared/model/payment/PlanDefinitions';
import session from '../../shared/model/auth/Session';
import subscriptionsDAO from '../data/SubscriptionsDAO';

export class CurrentUserPlan {

  listenerGroup = new ListenerGroup('CurrentUserPlan');

  constructor() {
    const oneHourInMillis = 60 * 60 * 1000;
    this.trialExpiryLeniencyMillis = oneHourInMillis;
    this.trialPeriodMillis = 30 * 24 * oneHourInMillis;
    this.planExpiryTimer = undefined;
    this.planDefinition = planDefinitions.typesToDefinitions[planDefinitions.liteType];
    session.registerListener(this.onSessionChange);
    const user = session.getCurrentUser();
    this._setUser(user);
  }

  refresh = () => {
    const user = session.getCurrentUser();
    this._setUser(user);
  };

  onSessionChange = (user) => {
    this._setUser(user);
  };

  _setUser = (user) => {
    this.inTrialPeriod = false;
    this.inSecondHalfOfTrialPeriod = false;
    if (user) {
      const emailDomain = user.getEmailDomain();
      if (this._isInEnterpriseWhitelistedEmailDomains(emailDomain)) {
        this._setPlan(user, planDefinitions.enterpriseType);
      } else {
        const userEmail = user.getEmail();
        if (this._isInProWhitelistedEmails(userEmail)) {
          this._setPlan(user, planDefinitions.proType);
        } else {
          if (featureFlags.requirePaymentsForAdvancedFeatures()) {
            this._setPlanForUser(user);
          } else {
            this._setPlan(user, planDefinitions.proType);
          }
        }
      }
    } else {
      this._setPlan(user, planDefinitions.liteType);
    }
  };

  _setPlanForUser = (user) => {
    const userId = user.getId();
    subscriptionsDAO.getSubscription(userId).then((subscription) => {
      let planType = planDefinitions.liteType;
      if (subscription && subscription.planType) {
        this._setPlan(user, subscription.planType);
      } else {
        subscriptionsDAO.getTrialInfo(userId).then((trialInfo) => {
          let valid = true;
          if (trialInfo && trialInfo.expiryTimestamp !== undefined) {
            const currentTime = new Date().getTime();
            const cutoffTime = trialInfo.expiryTimestamp + this.trialExpiryLeniencyMillis;
            const millisToCutoff = cutoffTime - currentTime;
            valid = millisToCutoff > 0;
            if (valid) {
              this._subscribeToTrialExpiry(user, trialInfo.expiryTimestamp);
            }
          } else {
            valid = true;
            this._createTrialPlan(user).then(() => {
              const currentTime = new Date().getTime();
              const subscriptionExpiryTimestamp = currentTime + this.trialPeriodMillis;
              this._subscribeToTrialExpiry(user, subscriptionExpiryTimestamp);
            });
          }
          planType = valid ? planDefinitions.proType : planDefinitions.liteType;
          this._setPlan(user, planType);
        });
      }
    });
  };

  _isInEnterpriseWhitelistedEmailDomains = (emailDomain) => {
    return emailDomain === 'atlassian.com' || 'education.nsw.gov.au';
  };

  _isInProWhitelistedEmails = (email) => {
    if (email === 'roobrickorg@gmail.com') {
      return true;
    } else if (email === 'hugh.morrow@gmail.com') {
      return true;
    }
    return false;
  };

  _setPlan = (user, planType) => {
    this.planDefinition = planDefinitions.typesToDefinitions[planType];
    this._notifyListeners();
  };

  _createTrialPlan = (user) => {
    const userId = user.getId();
    const currentTime = new Date().getTime();
    const expiryTimestamp = currentTime + this.trialPeriodMillis;
    return subscriptionsDAO.addTrialInfo(userId, expiryTimestamp);
  };

  _subscribeToTrialExpiry = (user, subscriptionExpiryTimestamp) => {
    if (this.planExpiryTimer) {
      clearTimeout(this.planExpiryTimer);
    }
    this.inTrialPeriod = true;
    const currentTime = new Date().getTime();
    const delayToSubscriptionExpiry = subscriptionExpiryTimestamp - currentTime;
    const halfTrialPeriodMillis = this.trialPeriodMillis / 2;
    let delayToTimer = 0;
    if (delayToSubscriptionExpiry < halfTrialPeriodMillis) {
      delayToTimer = delayToSubscriptionExpiry + this.trialExpiryLeniencyMillis;
    } else {
      // Show a warning for the second half of the trial...
      this.inSecondHalfOfTrialPeriod = true;
      // Only delay to the end of half the trial period so that the warning can be displayed...
      delayToTimer = subscriptionExpiryTimestamp + this.trialExpiryLeniencyMillis - halfTrialPeriodMillis;
    }
    if (delayToTimer > 0) {
      this.planExpiryTimer = setTimeout(() => {
        // Set the user to force re-querying the plan which will result in the detection of the expiry.
        this._setUser(user);
      }, delayToTimer);
    }
  };

  getCurrentPlanDefinition = () => {
    return this.planDefinition;
  };

  isInTrialPeriod = () => {
    return this.inTrialPeriod;
  };

  isInSecondHalfOfTrialPeriod = () => {
    return this.inSecondHalfOfTrialPeriod;
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = () => {
    const context = {
      inTrialPeriod: this.inTrialPeriod,
      inSecondHalfOfTrialPeriod: this.inSecondHalfOfTrialPeriod
    };
    this.listenerGroup.notifyListeners(this.planDefinition, context);
  };

}

export default new CurrentUserPlan();

