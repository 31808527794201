import BinaryScoreMeta from './BinaryScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';

class YesOrNoScoreMeta extends BinaryScoreMeta {

  constructor() {
    super(scoreTypes.yesOrNo);
    // super('Yes or No', markers);
  }

}

export default new YesOrNoScoreMeta();

