
const callbackDelay = 100;

export default {
  getContext: (callback) => {
    setTimeout(() => {
      callback({
        jira: {
          issue: {
            key: 'TEST-7'
          }
        }
      });
    }, callbackDelay);
  }
}