import React, { PureComponent } from "react";
import constants from '../../../../commonbase/commonConstants';
import PrivacyPolicyPage from '../../../pages/PrivacyPolicyPage';

interface Props {}

interface State {}

export default class NotesPrivacyPolicyPage extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <PrivacyPolicyPage
        pageTitle="Notes Privacy Policy"
        appTitle="Notes"
        hostingExplanation={` hosted within ${constants.appTitle} and`}
      />
    );
  }

}
