import React, { PureComponent } from 'react';
import DemoPanel from './DemoPanel';
import demoUtil from './DemoUtil';
import Modal from '@atlaskit/modal-dialog';
import demoRenderUtil from './DemoRenderUtil';

export default class PopupDemoPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = this.buildStateFromProps(props);
    this.setState(newState);
  }

  buildStateFromProps = (props) => {
    const firstImageSrc = demoUtil.findFirstImageSrc(props.definition);
    return {
      title: props.title,
      previewWidth: props.previewWidth,
      previewHeight: props.previewHeight,
      firstImageSrc: firstImageSrc,
      definition: props.definition
    }
  };

  onStartDemo = () => {
    this.setState({
      inDemoMode: true
    });
  };

  onStopDemo = () => {
    this.setState({
      inDemoMode: false
    });
  };

  renderDemoInDialog = () => {
    const actions = [{
      text: 'Close',
      onClick: this.onStopDemo
    }];
    return (
      <Modal
        heading={this.state.title ? this.state.title : ''}
        width="x-large"
        actions={actions}
        onClose={this.onStopDemo}
      >
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'fit-content'
          }}
        >
          <DemoPanel definition={this.state.definition}/>
        </div>
      </Modal>
    );
  };

  render() {
    const centreX = this.state.previewWidth / 2;
    const centreY = this.state.previewHeight / 2;
    const circleRadius = 30;
    const renderedImage = (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={this.state.previewWidth}
        height={this.state.previewHeight}
      >
        <image
          xlinkHref={this.state.firstImageSrc}
          x={0}
          y={0}
          width={this.state.previewWidth}
          height={this.state.previewHeight}
        />
        {demoRenderUtil.renderPlaySymbol(centreX, centreY, circleRadius, this.onStartDemo)}
      </svg>
    );
    return (
      <React.Fragment>
        {renderedImage}
        {this.state.inDemoMode ? this.renderDemoInDialog() : null}
      </React.Fragment>
    );
  }

}