import Definition from '../../shared/model/rubric/definition/Definition';
import Assessment from '../../shared/model/rubric/score/Assessment';
import AssessmentSnapshot from '../../shared/model/rubric/score/AssessmentSnapshot';
import { SearchOptions } from '../../shared/model/rubric/search/SearchOptions';
import DeletionResult from './DeletionResult';
import RubricPersistence from './RubricPersistence';
import storageDAO from './StorageDAO';

export default class RubricBrowserPersistence implements RubricPersistence {

  definitionPrefix = 'definition-';
  assessmentPrefix = 'assessment-';

  saveDefinition = (definition: Definition): Promise<Definition> => {
    return storageDAO
      .setData(this.definitionPrefix + definition.uuid, definition)
      .then(() => {
        return definition;
      });
  };

  deleteDefinition = (definition) => {
    return storageDAO.removeItem(this.definitionPrefix + definition.uuid);
  };

  getDefinitionByUuid = (uuid) => {
    return storageDAO.getData(this.definitionPrefix + uuid);
  };

  saveAssessment = (assessment) => {
    return storageDAO.setData(this.assessmentPrefix + assessment.uuid, assessment);
  };

  saveAssessmentSnapshot = (assessmentSnapshot: AssessmentSnapshot): Promise<AssessmentSnapshot> => {
    // Not yet implemented properly
    return new Promise((resolve, reject) => {
      resolve(assessmentSnapshot);
    });
  };

  getAssessmentSnapshots = (assessmentUuid: string): Promise<AssessmentSnapshot[]> => {
    // Not yet implemented properly
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  };

  deleteAssessment = (assessment: Assessment): Promise<DeletionResult> => {
    return storageDAO.removeItem(this.assessmentPrefix + assessment.uuid)
      .then(() => {
        return {deleted: true}
      });
  };

  getAssessmentByUuid = (assessmentUuid: string, trapError?: boolean): Promise<undefined | Assessment> => {
    const requiredPrefix = this.assessmentPrefix + assessmentUuid;
    const options = {
      isKeyOfInterest: (itemKey) => {
        return itemKey.indexOf(requiredPrefix) === 0;
      }, transformItem: (item) => {
        return JSON.parse(item);
      }
    };
    return storageDAO.getItems(options).then((scores) => {
      if (scores.length) {
        if (scores.length > 1) {
          console.error('Multiple scores found:', scores);
          // debugger;
        }
        return scores[0] as Assessment;
      } else {
        return undefined;
      }
    });
  };

  getAssessmentsByUuids = (assessmentUuids, querySemantics): Promise<undefined | Assessment[]> => {
    throw new Error('Not implemented');
  }

  getAssessmentsByDefinitionUuidAndUser = (definitionUuid, user, includeArchivedAssessments) => {
    const requiredPrefix = this.assessmentPrefix;
    const options = {
      isKeyOfInterest: (itemKey) => {
        return itemKey.indexOf(requiredPrefix) === 0;
      }, transformItem: (item) => {
        return JSON.parse(item);
      }, isItemOfInterest: (assessment) => {
        let match = assessment.definitionUuid === definitionUuid;
        if (match) {
          match = assessment.ownerId === user.getId();
        }
        if (match && !includeArchivedAssessments) {
          match = assessment.archivedTimestamp;
        }
        return match;
      }
    };
    return storageDAO.getItems(options);
  };

  searchDefinitionsNew = (currentUserId: string, canReadDefinition: (definition: Definition) => boolean, searchOptions: SearchOptions) => {
    throw new Error('No implemented');
  };

  searchDefinitions = (currentUserId: string, canReadDefinition: (definition: Definition) => boolean, searchOptions: SearchOptions) => {
    const requiredPrefix = this.definitionPrefix;
    const options = {
      maxResults: searchOptions.maxResults,
      searchStringLower: searchOptions.searchString ? searchOptions.searchString.toLowerCase() : '',
      isKeyOfInterest: (itemKey) => {
        return itemKey.indexOf(requiredPrefix) === 0;
      }, transformItem: (item) => {
        return JSON.parse(item);
      }, isItemOfInterest: (item) => {
        let matches = canReadDefinition(item);
        if (matches) {
          if (options.searchStringLower) {
            matches = item.name.toLowerCase().indexOf(options.searchStringLower) >= 0;
          } else {
            matches = true;
          }
        }
        return matches;
      }
    };
    return storageDAO.getItems(options);
  };

};
