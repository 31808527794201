import featureFlagsUtil from './FeatureFlagsUtil';
import { dmUserId } from '../auth/AuthConstants';
// import environment from '../../../shared/model/Environment';

class FeatureFlags {

  // FF-1
  networkEnabled = true;

  // FF-2
  //roleBasedAssessmentAuth = true;

  // FF-3
  // Need to solve how to cleanup assessments when a rubric is deleted. The
  // following feature flag is false because the true variant will not work
  // due to permissions problems
  // deleteOtherUsersAssessmentsOnRubricDeletion = false;

  // FF-4
  // interpretDefinitionStatementsAsMarkdown = true;//featureFlagsUtil.isInDevMode();

  // FF-5
  // interpretAssessmentAnnotationsAsMarkdown = true;//featureFlagsUtil.isInDevMode();

  // FF-6
  // annualAssessmentReportingEnabled = true;//featureFlagsUtil.isInDevMode();

  // FF-7
  updateDetectionEnabledForAllUsers = () => {
    return featureFlagsUtil.isUserIn([dmUserId]);
  };

  // FF-8
  migrateToFileReferenceSchemaCompleted = false;

  // FF-9
  // recentAssessmentViewLoggingEnabled = featureFlagsUtil.isInDevMode();
  recentAssessmentViewLoggingEnabled = false;

  // FF-10
  // newDashboardEnabled = true;

  // FF-11
  // assessmentSnapshotsEnabled = true;

  // FF-12
  momentsEnabled = () => {
    return featureFlagsUtil.isUserIn([dmUserId]);
  };

  // FF-13
  // emailDomainSharingEnabled = () => {
  //   // return featureFlagsUtil.isUserIn([dmUserId]);
  //   return true;
  // };

  // FF-14
  requirePaymentsForAdvancedFeatures = () => {
    return false;
  };

  // FF-15
  scoreGuidanceEnabled = () => {
    // return featureFlagsUtil.isUserIn([dmUserId]);
    return true;
  };

  // FF-16
  growthProfileRebuildingEnabled = () => {
    return featureFlagsUtil.isUserIn([dmUserId]);
  };

  // FF-17
  // sideBySideAssessmentsEnabled = () => {
  //   return true;
  // };

  // FF-18
  createDecisionWizardEnabled = () => {
    return true;
  };

  // FF-19: Also replicated in backend featureFlags.js
  emailToUserIdEnabled = (userId) => {
    return userId === dmUserId;
  };

  // FF-20:
  syncUserDetailsEnabled = () => {
    return true;
  };

  // FF-21:
  rubricLikingEnabled = () => {
    return true;
    // return false;
  };

  // FF-22: Reserved ID. See backend featureFlags.js

  // FF-23:
  assessmentStatusesEnabled = () => {
    return true;
  };

  // FF-24:
  toggleAssessmentItemApplicabilityEnabled = () => {
    return true;
  };

  // FF-25:
  assessmentComparisonEnabled = () => {
    // return featureFlagsUtil.isUserIn([dmUserId]);
    return true;
  };

  // FF-26:
  alwaysViewCellsInInputMode = () => {
    // return false;
    return true;
  };

  // FF-27:
  enableShowStatementsInSeparateRows = () => {
    return true;
  };

  // FF-28:
  presentScoresInBarForm = () => {
    return true;
  };

  // FF-29:
  enableBackgroundImages = () => {
    // return environment.isLocalHost;
    // return true;
    return false;
  };

  // FF-30:
  showAssessmentEditabilityNote = () => {
    // return environment.isLocalHost;
    return true;
  };

  // FF-31:
  showHorizontalNav = () => {
    // return environment.isLocalHost;
    return true;
  };

  // FF-32:
  showRubricsNavigationButton = () => {
    // return environment.isLocalHost;
    return true;
  };

  // FF-33:
  isDashboardEnabled = () => {
    return false;
  };

}

export default new FeatureFlags();
