import React, { PureComponent } from 'react';
import adg from '../../../../commonbase/adg';
import ColumnSelector from '../ColumnSelector'
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import ScoreSvg from "../score/ScoreSvg";
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import Tag from '@atlaskit/tag';
import util from '../../../../shared/util/Util';
import widgetUtil from '../../../components/widget/WidgetUtil';
import ToolbarLeft from '../../../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

export default class AssessmentView extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {    
    const selectedColumnUuids = [];
    let assessmentItem = undefined;
    if (props.definition && props.assessment) {
      for (let columnIndex = 0; columnIndex < props.definition.columns.length; columnIndex++) {
        const column = props.definition.columns[columnIndex];
        selectedColumnUuids.push(column.uuid);
      }
      assessmentItem = this.buildAssessmentItem(props.definition, props.assessment, selectedColumnUuids, true);
    }
    const scoreMeta = rubricUtil.getScoreMeta(props.definition);
    return {
      showScores: false,
      definition: props.definition,
      assessment: props.assessment,
      selectedColumnUuids: selectedColumnUuids,
      assessmentItem: assessmentItem,
      scoreMeta: scoreMeta
    };
  };

  onSelectedColumnChange = (newSelectedColumnUuids) => {
    const assessmentItem = this.buildAssessmentItem(this.state.definition, this.state.assessment, newSelectedColumnUuids, false);
    this.setState({
      selectedColumnUuids: newSelectedColumnUuids,
      assessmentItem: assessmentItem
    });
  };

  isInOptions = (uuid, options) => {
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.value === uuid) {
        return true;
      }
    }
    return false;
  };

  isColumnSelected = (columnUuid, uuids) => {
    const selectedColumnUuids = uuids ? uuids : this.state.selectedColumnUuids;
    for (let i = 0; i < selectedColumnUuids.length; i++) {
      if (selectedColumnUuids[i] === columnUuid) {
        return true;
      }
    }
    return false;
  };

  buildAssessmentItem = (definition, assessment, selectedColumnUuids, buildColumnIndexesContainingAssessments) => {
    let selectedColumnIndexes = [];
    const columnIndexesContainingAssessments = [];
    if (definition) {
      for (let columnIndex = 0; columnIndex < definition.columns.length; columnIndex++) {
        columnIndexesContainingAssessments.push(false);
        const column = definition.columns[columnIndex];
        const selected = this.isColumnSelected(column.uuid, selectedColumnUuids);
        selectedColumnIndexes.push(selected);
      }
    }
    const context = {
      definition: definition,
      assessment: assessment,
      assessmentItem: {
        columnIndexesContainingAssessments: columnIndexesContainingAssessments,
        values: [],
        roleExpectations: [],
        teamContributions: []
      }
    };
    if (!buildColumnIndexesContainingAssessments) {
      context.assessmentItem.columnIndexesContainingAssessments = this.state.assessmentItem.columnIndexesContainingAssessments;
    }
    const onStatement = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context) => {
      const includeColumn = selectedColumnIndexes[columnIndex];
      if (includeColumn) {
        // const assessmentItem = scoreUtil.getUserFacingAssessmentItem(assessment, statement.uuid);
        const assessmentItem = scoreUtil.getInternalAssessmentItem(assessment, statement.uuid);
        if (scoreUtil.hasScore(assessmentItem)) {
          const score = scoreUtil.assessmentItemToScore(assessmentItem);
          columnIndexesContainingAssessments[columnIndex] = true;
          const info = {
            key: statement.uuid,
            statementText: statement.text,
            score: score,
            annotation: assessmentItem.annotation
          };
          const isValuesRow =
            row.name.indexOf('Lives Atlassian values') > 0 ||
            row.name.indexOf('Culture and values') > 0 ||
            statement.text.indexOf('Lives Atlassian values') > 0 ||
            statement.text.indexOf('Culture and values') > 0;
          if (isValuesRow) {
            context.assessmentItem.values.push(info);
          } else {
            const labels = rubricUtil.getStatementLabels(context.definition, statement.uuid);
            if (this.hasLabel('impact', labels)) {
              context.assessmentItem.roleExpectations.push(info);
            } else {
              context.assessmentItem.teamContributions.push(info);
            }
          }
        }
      }
    };
    rubricUtil.iterateStatements(definition, onStatement, context);
    this.sortAssessmentItem(context.assessmentItem.values);
    this.sortAssessmentItem(context.assessmentItem.roleExpectations);
    this.sortAssessmentItem(context.assessmentItem.teamContributions);
    context.assessmentItem.valuesScore = this.computeScore(context.assessmentItem.values);
    context.assessmentItem.roleExpectationsScore = this.computeScore(context.assessmentItem.roleExpectations);
    context.assessmentItem.teamContributionsScore = this.computeScore(context.assessmentItem.teamContributions);
    return context.assessmentItem;
  };

  computeScore = (infos) => {
    let totalScore = 0;
    let scoreCount = 0;
    for (const info of infos) {
      totalScore = totalScore + info.score;
      scoreCount++;
    }
    return scoreCount ? totalScore / scoreCount : 0;
  };

  sortAssessmentItem = (assessmentItems) => {
    util.sortObjectsByField(assessmentItems, 'score', true);
  };

  hasLabel = (labelToFind, labels) => {
    for (const label of labels) {
      if (label === labelToFind) {
        return true;
      }
    }
    return false;
  };

  extractAspectFromRow = (row) => {
    const components = row.name.split(':');
    return components[2].trim();
  };

  scoreToRating = (score) => {
    if (!score || score < 30) {
      return 'Off year';
    } else if (score > 70) {
      return 'Exceptional year';
    } else {
      return 'Great year';
    }
  };

  renderSectionTitle = (text, overallScore) => {
    const width = 24;
    const height = width;
    const radius = width / 2;
    const ratingClassification = this.scoreToRating(overallScore);
    const assessmentItem = scoreUtil.buildAssessmentItemFromScore(overallScore);
    return (
      <ToolbarLeft>
        <ToolbarItem>
          <h3>
            {text}
          </h3>
        </ToolbarItem>
        <ToolbarItem>
          <svg width={width} height={height}>
            <ScoreSvg
              scoreMeta={this.state.scoreMeta}
              assessmentItem={assessmentItem}
              radius={radius}
            />
          </svg>
        </ToolbarItem>
        <ToolbarItem>
          <Tag
            text={ratingClassification}
            color="red"
            appearance="rounded"
          />
        </ToolbarItem>
      </ToolbarLeft>
    );
  };

  renderScore = (score) => {
    if (this.state.showScores) {
      return (
        <span style={{color: adg.adgRed}}>
          {Math.round(score)}
        </span>
      );
    } else {
      return null;
    }
  };

  renderSection = (title, assessmentItems, overallScore) => {
    const renderedAssessmentItems = assessmentItems.map((assessmentItem) => {
      const annotationHtml = widgetUtil.markdownToHtml(assessmentItem.annotation);
      const renderedScore = this.renderScore(assessmentItem.score);
      const scoreSeparator = this.state.showScores ? ': ' : '';
      const renderedAnnotationHtml = (
        <span
          dangerouslySetInnerHTML={{__html: annotationHtml}}>
        </span>
      );
      return (
        <div key={assessmentItem.key}>
          <b>{assessmentItem.statementText}</b> {renderedScore}{scoreSeparator} {renderedAnnotationHtml} <br/>
        </div>
      );
    });
    return (
      <div
        style={{marginTop: '20px'}}
      >
        {this.renderSectionTitle(title, overallScore)}
        {renderedAssessmentItems}
      </div>
    );
  };

  renderValuesSection = () => {
    return this.renderSection('Demonstration of the values', this.state.assessmentItem.values, this.state.assessmentItem.valuesScore);
  };

  renderRoleExpectationsSection = () => {
    return this.renderSection('Delivery of role expectations', this.state.assessmentItem.roleExpectations, this.state.assessmentItem.roleExpectationsScore);
  };

  renderTeamContributionSection = () => {
    return this.renderSection('Contribution to team(s)', this.state.assessmentItem.teamContributions, this.state.assessmentItem.teamContributionsScore);
  };
  
  renderColumnSelector = () => {
    const columnInfos = [];
    for (let columnIndex = 0; columnIndex < this.state.definition.columns.length; columnIndex++) {
      if (this.state.assessmentItem.columnIndexesContainingAssessments[columnIndex]) {
        const column = this.state.definition.columns[columnIndex];
        columnInfos.push({
          uuid: column.uuid,
          index: columnIndex,
          name: column.name,
          selected: this.isColumnSelected(column.uuid)
        });
      }
    }
    if (columnInfos.length > 1) {
      return (
        <ColumnSelector
          columnInfos={columnInfos}
          onChange={this.onSelectedColumnChange}
        />
      );
    } else {
      return null;
    }
  };

  renderAssessment = () => {
    return (
      <div style={{marginTop: '20px'}}>
        <h2>Assessment: {this.state.definition.name}: {this.state.assessment.name}</h2>
        {this.renderColumnSelector()}
        {this.renderValuesSection()}
        {this.renderRoleExpectationsSection()}
        {this.renderTeamContributionSection()}
      </div>
    );
  };

  render() {
    if (this.state.definition && this.state.assessment) {
      return this.renderAssessment();
    } else {
      return null;
    }
  };

}
