import React from 'react';
import adg from '../../../commonbase/adg';
import PathBuilder from '../svg/PathBuilder';
import OpacityHelper from './OpacityHelper';
import SafeWidget from "../widget/SafeWidget";

export default class RemoveColumnIcon extends SafeWidget {

  constructor(props) {
    super(props);
    this.opacityHelper = new OpacityHelper(this);
  }

  renderNormalState = () => {
    const width = this.props.width;
    const height = this.props.height;
    const triangleTop = height / 2;
    const crossLeft = width / 4;
    const crossRight = 3 * width / 4;
    const crossTop = height / 5;
    const crossBottom = 3 * height / 5;
    const mainFill = new PathBuilder('main-fill')
      .setPathColor(adg.adgRed)
      .setFill(adg.adgRed)
      .setPathWidth(2)
      .moveTo(0, 0)
      .lineTo(0, triangleTop)
      .lineTo(width / 2, height)
      .lineTo(width, triangleTop)
      .lineTo(width, 0)
      .lineTo(0, 0)
      .close()
      .render();
    const lineA = new PathBuilder('line-a')
      .setPathColor('#fff')
      .setPathWidth(2)
      .moveTo(crossLeft, crossBottom)
      .lineTo(crossRight, crossTop)
      .render();
    const lineB = new PathBuilder('line-b')
      .setPathColor('#fff')
      .setPathWidth(2)
      .moveTo(crossLeft, crossTop)
      .lineTo(crossRight, crossBottom)
      .render();
    return (
      <svg
        style={this.opacityHelper.buildStyle()}
        width={width}
        height={height}
        onMouseEnter={this.opacityHelper.onMouseEnter}
        onMouseLeave={this.opacityHelper.onMouseLeave}
        onClick={this.onEnterDangerState}
      >
        {mainFill}
        {lineA}
        {lineB}
      </svg>
    );
  }

}