import React, { FormEvent, FocusEvent, PureComponent } from 'react';
import adg from '../../../../commonbase/adg';
import Button, { ButtonGroup } from '@atlaskit/button';
import commonNavUtil from '../../../../commonbase/util/commonNavUtil';
import ContactPicker from '../../network/ContactPicker';
import constants from '../../../../shared/model/Constants';
import actions from '../../../../shared/actions/Actions';
import ResizingTextArea from '../../widget/ResizingTextArea';
import PermissionCheckResult, { falsePermissionCheckResult } from '../../../../shared/model/auth/PermissionCheckResult';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import scriptLoader from '../../../../shared/util/ScriptLoader';
import util from '../../../../shared/util/Util';
import navConstants from '../../nav/NavConstants';
import widgetUtil from '../../widget/WidgetUtil';
import {NotificationsIcon, EmailIcon, SuccessIcon, WarningIcon} from '../../icon/NamedIcons';
import FormlessTextField from '../../widget/FormlessTextField';
import { SelectedType } from '@atlaskit/tabs/types';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import discoverabilityDefinitions from '../../../../shared/model/rubric/DiscoverabilityDefinitions';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
import Contact from '../../../../shared/model/network/Contact';
import ToolbarCenter from '../../widget/toolbar/ToolbarCenter';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';
import navUtil from '../../nav/NavUtil';
import MessageBanner from '../../widget/banner/MessageBanner';

export interface ShareFormProps {
  definition: Definition
  assessment?: Assessment
  onDone: (targetUserContact: undefined | Contact) => void
  onCancel: () => void;
}

interface State {
  selectedTabType: number
  sharingAllowedCheckResult: any
  alreadySharedUserEmails: any[]
  classroomSharingMessage: string
  inAppSharingMessage: string
  emailSharingMessage: string
  targetUserContact: undefined | Contact
  targetUserEmailBlurred: boolean
  targetUserEmailValid: boolean
  targetUserEmailDirty: boolean
  formDirty: boolean
  blurHandlingEnabled?: boolean
  targetUserEmail?: string
  onClassroomMessageChange?: Function
}

export default abstract class ShareFormBase extends PureComponent<ShareFormProps, State> {


  textAreaElement: any;
  classroomShareDiv: any;

  constructor(props: ShareFormProps) {
    super(props);
    this.state = {
      selectedTabType: 0,
      sharingAllowedCheckResult: falsePermissionCheckResult,
      alreadySharedUserEmails: [],
      classroomSharingMessage: '',
      inAppSharingMessage: '',
      emailSharingMessage: '',
      targetUserContact: undefined,
      targetUserEmailBlurred: false,
      targetUserEmailValid: false,
      targetUserEmailDirty: false,
      formDirty: false
    };
  }

  abstract checkSharingPermission: () => Promise<PermissionCheckResult>;
  abstract buildEmailSubject: (props: ShareFormProps) => string;
  abstract buildDefaultInAppSharingMessage: (props: ShareFormProps) => string;
  abstract buildDefaultEmailSharingMessage: (props: ShareFormProps) => string;
  abstract buildContactPickerContext: () => void;
  abstract buildGoogleClassroomTitle: (props: ShareFormProps) => string;
  abstract getShareButtonLabel: () => string;
  abstract onSumbitShareInAppForm: (inAppSharingMessage: string) => void;
  abstract allowSharingToGoogleClassroom: () => boolean;

  componentDidMount() {
    this.setState({
      blurHandlingEnabled: true
    });
    this.checkSharingPermission().then((sharingAllowedCheckResult) => {
      this.setState({
        sharingAllowedCheckResult: sharingAllowedCheckResult,
        inAppSharingMessage: this.buildDefaultInAppSharingMessage(this.props),
        emailSharingMessage: this.buildDefaultEmailSharingMessage(this.props)
      });
    });
    this.loadGoogleClassroomScriptAndRenderShareButton();
  };

  buildDefaultEmailSharingUrl = (props) => {
    let url = '';
    if (props.assessment && props.assessment.discoverability === discoverabilityDefinitions.publicDiscoverabilityType) {
      url = navUtil.buildSeoAssessmentEntryPointUrl(
        props.definition.uuid,
        props.definition.name,
        props.assessment.uuid,
        props.assessment.name);
    } else if (props.definition && props.definition.discoverability === discoverabilityDefinitions.publicDiscoverabilityType) {
      url = navUtil.buildSeoDefinitionEntryPointUrl(
        props.definition.uuid,
        props.definition.name);
    } else if (props.assessment) {
      url = navUtil.buildAssessmentUrl(props.assessment.uuid, props.assessment.name);
    } else {
      url = navUtil.buildDefinitionUrl(props.definition.uuid, props.definition.name);
    }
    return url;
  }

  // checkSharingPermission = (): Promise<void> => {
  //   return new Promise((resolve, reject) => {
  //     resolve();
  //   });
  // }

  onContactPicked = (contact: Contact, context: any) => {
    this.setState({
      targetUserContact: contact,
      // targetUserEmail: contact.email,
      targetUserEmailValid: true,
      targetUserEmailDirty: true,
      formDirty: true
    });
  };

  onVisitNetworkLinkClick = () => {
    window.location.href = '/#' + navConstants.networkPageHash;
    this.onCancel();
  };

  onTabSelect = (selectedTabType: SelectedType) => {
    this.setState({
      selectedTabType: selectedTabType
    });
  };

  onSelectShareToGoogleClassroomTab = () => {
    this.setState({
      selectedTabType: 1
    });
  };

  onSelectShareInAppTab = () => {
    this.setState({
      selectedTabType: 2
    });
  };

  onSelectShareViaEmailTab = () => {
    this.setState({
      selectedTabType: 3
    });
  };

  onClassroomMessageChange = (event) => {
    const message = event.currentTarget.value;
    this.setState({
      classroomSharingMessage: message
    });
  };

  onAppMessageChange = (event) => {
    const message = event.currentTarget.value;
    this.setState({
      inAppSharingMessage: message
    });
  };

  onEmailMessageChange = (event) => {
    const message = event.currentTarget.value;
    this.setState({
      emailSharingMessage: message
    });
  };

  onEmailChange = (event: FormEvent<HTMLInputElement>) => {
    const targetUserEmail = event.currentTarget.value;
    const isValidEmail = util.isValidEmail(targetUserEmail);
    if (targetUserEmail) {
      this.setState({
        targetUserEmail: targetUserEmail,
        targetUserEmailValid: isValidEmail,
        targetUserEmailDirty: true,
        formDirty: true
      });
    }
  };

  onEmailBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (this.state.blurHandlingEnabled) {
      this.setState({
        targetUserEmailBlurred: true
      });
    }
  };

  onTextAreaRef = (textAreaElement: any) => {
    this.textAreaElement = textAreaElement;
  };

  onSumbitOpenEmailClient = (event) => {

  };

  onSharingComplete = () => {
    this.popupFlag();
    if (this.props.onDone && this.state.targetUserContact) {
      this.props.onDone(this.state.targetUserContact);
    }
  };

  onSumbitCopyEmailText = (event) => {
    if (this.textAreaElement) {
      widgetUtil.textAreaSelectAndCopyToClipboard(this.textAreaElement, () => {
        const flag = {
          id: 'text-copy-' + new Date().getTime(),
          icon: <SuccessIcon label="success" primaryColor={adg.adgGreen} />,
          title: 'Email text copied',
          description: 'The email text was copied to clipboard',
          flagMessageTimeoutSeconds: 5,
          actions: []
        };
        actions.addFlag(flag);
      });
    }
  };

  onCancel = () => {
    this.props.onCancel();
  };

  popupFlag = () => {
    const flag = {
      id: util.uuid(),
      appearance: "success",
      icon: <SuccessIcon label="success" secondaryColor={adg.adgGreen} />,
      title: 'Notification sent',
      description: 'The notification has been sent.',
      flagMessageTimeoutSeconds: 5
    };
    actions.addFlag(flag);
  }

  // popupTextCopied = (title: string, description: string) => {
  //   const flag = {
  //     id: util.uuid(),
  //     appearance: "warning",
  //     icon: <InfoIcon label="info" secondaryColor={adg.adgBlue} />,
  //     title: title,
  //     description: description,
  //     flagMessageTimeoutSeconds: 5
  //   };
  //   actions.addFlag(flag);
  // };

  popupDuplicateNotificationWarning = (email: string) => {
    const flag = {
      id: util.uuid(),
      appearance: "warning",
      icon: <WarningIcon label="warning" secondaryColor={adg.adgWarning} />,
      title: 'Notification previously sent',
      description: `The notification was previously sent to ${email}.`,
      flagMessageTimeoutSeconds: 5
    };
    actions.addFlag(flag);
  };

  isAlreadySharedWithEmail = (email: string) => {
    for (let i = 0; i < this.state.alreadySharedUserEmails.length; i++) {
      const alreadySharedUserEmail = this.state.alreadySharedUserEmails[i];
      if (alreadySharedUserEmail === email) {
        return true;
      }
    }
    return false;
  };

  renderSharingAllowed = () => {
    const blogUrl = commonNavUtil.buildBlogURL('b537', 'Sharing rubrics and assessments');
    const assessmentOrRubric = this.props.assessment ? 'assessment' : 'rubric';
    const message = this.state.sharingAllowedCheckResult.isAllowed() ?
      (
        <div>
          Before sharing this {assessmentOrRubric}, check it's permissions to ensure recipient(s) have the correct access. 
          You may like to read the <a href={blogUrl} target="_blank" rel="noopener noreferrer">sharing rubrics and assessments</a> article.
        </div>
      ) :
      this.state.sharingAllowedCheckResult.getNotAllowedMessage();
    return (
      <MessageBanner level="info">
        {message}
      </MessageBanner>
    );
  };

  renderMessage = (placeholder, message, maxLength, onMessageChange) => {
    return (
      <ResizingTextArea
        label="Message"
        compact={false}
        autoFocus={true}
        shouldFitContainer={true}
        debounceChangeCallback={true}
        maxLength={maxLength}
        minimumRows={4}
        enableResize={true}
        placeholder={placeholder}
        value={message}
        onTextAreaRef={this.onTextAreaRef}
        onChange={onMessageChange}
      />
    );
  };

  renderTargetEmail = () => {
    let isInvalid: undefined | boolean = undefined;
    let invalidMessage: undefined | string = undefined;
    if (this.state.targetUserEmailBlurred && !this.state.targetUserEmailValid) {
      isInvalid = true;
      invalidMessage = 'The email address is invalid.';
    } else if (this.state.targetUserEmail && this.isAlreadySharedWithEmail(this.state.targetUserEmail)) {
      isInvalid = true;
      invalidMessage = 'This assessment has already been shared with that email address.';
    }
    return (
      <FormlessTextField
        name="email-address"
        label="Email address"
        placeholder="myexaminer@gmail.com - the email of person to share with"
        isInvalid={isInvalid}
        invalidMessage={invalidMessage}
        maxLength={constants.maxEmailLength}
        shouldFitContainer={true}
        isSpellCheckEnabled={false}
        autoFocus={true}
        value={this.state.targetUserEmail}
        onChange={this.onEmailChange}
        onBlur={this.onEmailBlur}
      />
    );
  };

  renderTargetContact = () => {
    const contactPickerContext = this.buildContactPickerContext();
    return (
      <React.Fragment>
        <div>
          <ContactPicker
            hideLabel={false}
            label="Collaborator"
            selectedContactEmail={this.state.targetUserEmail}
            context={contactPickerContext}
            onChange={this.onContactPicked}
          />
        </div>
        <div>
          <Button
            appearance="subtle-link"
            isDisabled={false}
            onClick={this.onVisitNetworkLinkClick}
          >
            Visit network
          </Button>
        </div>
      </React.Fragment>
    );
  };

  loadGoogleClassroomScriptAndRenderShareButton = () => {
    // See https://developers.google.com/classroom/guides/sharebutton
    if (this.classroomShareDiv) {
      const w: any = window;
      if (!w.___gcfg) {
        w.___gcfg = {
          parsetags: 'explicit'
        };
      }
      scriptLoader.loadScript('https://apis.google.com/js/platform.js').then(() => {
        const divId = this.classroomShareDiv;
        const body = this.state.classroomSharingMessage;
        const options = {
          url: document.location.href,
          title: this.buildGoogleClassroomTitle(this.props),
          body: body,//this.buildGoogleClassroomBody(this.props)
          onsharecomplete: this.onSharingComplete
        };
        const w: any = window;
        w.gapi.sharetoclassroom.render(divId, options)
      });
    }
  }

  renderGoogleClassroomSharing = () => {
    const messageContainerStyle = {
      width: '400px'
    };
    return (
      <div className="centredContent newMainSection">
        <div style={messageContainerStyle}>
          {this.renderMessage(
            'Message for the classroom...',
            this.state.onClassroomMessageChange,
            constants.maxGoogleClassroomSharingMessageLength,
            this.onClassroomMessageChange)}
        </div>
        <div className="centredContent newMinorSection">
          <ToolbarCenter>
            <ToolbarItem>
              <div
                ref={input => {
                  this.classroomShareDiv = input;
                }}
              >
                Share to Google classroom...
              </div>
            </ToolbarItem>
            <ToolbarItem style={{marginLeft: '16px'}}>
              <b>Google Classroom</b>
            </ToolbarItem>
          </ToolbarCenter>
        </div>
        <p className="newMinorSection centered">
          Click the image above to share <br/>
          to your Google classroom.
        </p>
        <div className="centredContent newMinorSection">
          <Button
            appearance="subtle"
            iconBefore={undefined}
            onClick={this.onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  renderInAppSharing = () => {
    const formDirtyAndValid =
      this.state.formDirty && this.state.targetUserEmailValid;
    const formEnabled = formDirtyAndValid && this.state.sharingAllowedCheckResult.isAllowed();
    return (
      <div style={{width: '100%'}}>
        {this.renderMessage(
          'Hey ..., take a look at this assessment...',
          this.state.inAppSharingMessage,
          constants.maxInAppSharingMessageLength,
          this.onAppMessageChange)}
        {this.renderTargetContact()}
        <div className="centredContent newMainSection">
          <ButtonGroup>
            <Button
              appearance={formEnabled ? 'primary' : 'default'}
              isDisabled={!formEnabled}
              iconBefore={undefined}
              onClick={() => this.onSumbitShareInAppForm(this.state.inAppSharingMessage)}
            >
              {this.getShareButtonLabel()}
            </Button>
            <Button
              appearance="subtle"
              iconBefore={undefined}
              onClick={this.onCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  };

  renderEmailSharing = () => {
    const formEnabled = this.state.sharingAllowedCheckResult.isAllowed();
    const subject = this.buildEmailSubject(this.props);
    const body = encodeURIComponent(this.state.emailSharingMessage);
    return (
      <div style={{width: '100%'}}>
        {this.renderMessage(
          'Hey ..., take a look at this assessment...',
          this.state.emailSharingMessage,
          constants.maxEmailSharingMessageLength,
          this.onEmailMessageChange)}
        <div className="centredContent newMainSection">
          <ButtonGroup>
            <Button
              appearance={formEnabled ? 'primary' : 'default'}
              isDisabled={!formEnabled}
              iconBefore={undefined}
              onClick={this.onSumbitCopyEmailText}
            >
              Copy email text
            </Button>
            <Button
              href={`mailto:?subject=${subject}&body=${body}`}
              target="_blank" rel="noopener noreferrer"
              appearance={formEnabled ? 'primary' : 'default'}
              isDisabled={!formEnabled}
              iconBefore={undefined}
            >
              Open email client
            </Button>
            <Button
              appearance="subtle"
              iconBefore={undefined}
              onClick={this.onCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  };

  renderSelectionTab = () => {
    const divStyle: any = {
      textAlign: 'center',
      margin: '20px'
    };
    const paragraphStyle = {
      fontSize: '0.8em',
      color: adg.adgCharcoal
    };
    return (
      <div style={{
        width: '100%',
        margin: '50px auto'
      }}>
        <ToolbarCenter>
          <div style={divStyle}>
            <Button
              iconBefore={<NotificationsIcon label="Notification" />}
              appearance="primary"
              onClick={this.onSelectShareToGoogleClassroomTab}
            >
              Share to Google Classroom
            </Button>
            <p style={paragraphStyle}>
              Select this option to share directly<br/>
              into your Google Classroom.
            </p>
          </div>
          <div style={divStyle}>
            <Button
              iconBefore={<NotificationsIcon label="Notification" />}
              appearance="primary"
              onClick={this.onSelectShareInAppTab}
            >
              Share in app
            </Button>
            <p style={paragraphStyle}>
              Select this option if you know<br/>
              they already use {constants.appTitle}.
            </p>
          </div>
          <div style={divStyle}>
            <Button
              iconBefore={<EmailIcon label="Emai"/>}
              appearance="primary"
              onClick={this.onSelectShareViaEmailTab}
            >
              Share via email
            </Button>
            <p style={paragraphStyle}>
              Select this option if they may<br/>
              not be using {constants.appTitle} yet.
            </p>
          </div>
        </ToolbarCenter>
      </div>
    );
  };

  render() {
    // console.log('this.state.selectedTabType:', this.state.selectedTabType);
    // const selectionTabPanel = (
    //   <Tab>{this.renderSelectionTab()}</Tab>
    // );
    const shareToGoogleClassroomTabPanel = (
      <TabPanel key="tabpanel-share-google-classroom">{this.renderGoogleClassroomSharing()}</TabPanel>
    );
    const shareViaEmailTabPanel = (
      <TabPanel key="tabpanel-share-email">{this.renderEmailSharing()}</TabPanel>
    );
    const shareInAppTabPanel = (
      <TabPanel key="tabpanel-share-app">{this.renderInAppSharing()}</TabPanel>
    );
    const renderedTabs = [
      <Tab key="tab-share-google-classroom">Share to Google Classroom</Tab>,
      <Tab key="tab-share-email">Share via email</Tab>,
      <Tab key="tab-share-app">Share in app</Tab>];
    const renderedTabPanels = [
      shareToGoogleClassroomTabPanel,
      shareViaEmailTabPanel,
      shareInAppTabPanel];

    return (
      <React.Fragment>
        {this.renderSharingAllowed()}
        <Tabs
          id="controlled"
          onChange={this.onTabSelect}
          selected={this.state.selectedTabType}
        >
          <TabList>
            {renderedTabs}
          </TabList>
          {renderedTabPanels}
        </Tabs>
      </React.Fragment>
    );
  }

}