import React, { PureComponent } from "react";
import MessageBanner from './MessageBanner';

type Props = {
  children?: React.ReactNode;
  containerStyles?: any
}

export default class InfoMessageBanner extends PureComponent<Props> {

  render() {
    return (
      <MessageBanner level="info" containerStyles={this.props.containerStyles}>
        {this.props.children}
      </MessageBanner>
    );
  }
  
}
