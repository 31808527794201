
export default class DemoPlayer {

  alive = true;
  paused = false;

  constructor(demoDefinition, instructions, handlers) {
    this.demoDefinition = demoDefinition;
    this.instructions = instructions;
    this.handlers = handlers;
    this.visibleComponents = [];
    this._buildVisibleStates();
    this.currentInstructionIndex = 0;
    this.currentTimeoutHandle = undefined;
  }

  start = () => {
    this.visibleComponents = [];
    this.currentInstructionIndex = 0;
    this.executeCurrentInstructionIndex();
  };

  pause = () => {
    this.paused = true;
    this._notifyStateChange();
  };

  resume = () => {
    this.paused = false;
    this.executeCurrentInstructionIndex();
    this._notifyStateChange();
  };

  advance = () => {
    const visibleState = this._findNextVisibilityState();
    if (visibleState.instructionIndex !== this.currentInstructionIndex) {
      this.currentInstructionIndex = visibleState.instructionIndex;
      this.visibleComponents = visibleState.visibleComponents;
      this.handlers.onVisibleComponentsChange(this.visibleComponents);
      this._notifyStateChangeIfOnVisibleComponentsChange();
    }
  };

  reverse = () => {
    const visibleState = this._findPrevVisibilityState();
    if (visibleState.instructionIndex !== this.currentInstructionIndex) {
      this.currentInstructionIndex = visibleState.instructionIndex;
      this.visibleComponents = visibleState.visibleComponents;
      this.handlers.onVisibleComponentsChange(this.visibleComponents);
      this._notifyStateChangeIfOnVisibleComponentsChange();
    }
  };

  _notifyStateChangeIfOnVisibleComponentsChange = () => {
    const instruction = this.instructions[this.currentInstructionIndex];
    if (this._isVisibleInstruction(instruction)) {
      this._notifyStateChange();
    }
  };

  _notifyStateChange = () => {
    const progressPercent = this._computeProgressPercent();
    this.handlers.onPlayerStateChange(this.paused, progressPercent);
  };

  _computeProgressPercent = () => {
    const visibleStateIndex = this.instructionIndexesToVisibleStateIndexes[this.currentInstructionIndex];
    if (visibleStateIndex === this.visibleStates.length - 1) {
      return 100;
    } else {
      return 100 * (visibleStateIndex) / (this.visibleStates.length);
    }
  };

  _buildVisibleStates = () => {
    const visibleStates = [];
    const instructionIndexesToVisibleStateIndexes = {};
    const allowExecuteNext = false;
    const suppressNotifications = true;
    this.lastVisitbleStateInstructionIndex = 0;
    let visibleStateIndex = 0;
    for (let instructionIndex = 0; instructionIndex < this.instructions.length; instructionIndex++) {
      const instruction = this.instructions[instructionIndex];
      this._executeInstruction(instructionIndex, instruction, allowExecuteNext, suppressNotifications);
      if (this._isVisibleInstruction(instruction)) {
        if (visibleStates.length > 0) {
          visibleStateIndex++;
        }
        this.lastVisitbleStateInstructionIndex = instructionIndex;
        const visibleComponents = this._cloneVisibleComponents();
        const visibleState = {
          instructionIndex: instructionIndex,
          visibleComponents: visibleComponents
        };
        visibleStates.push(visibleState);
      }
      instructionIndexesToVisibleStateIndexes[instructionIndex] = visibleStateIndex;
    }
    this.visibleStates = visibleStates;
    this.instructionIndexesToVisibleStateIndexes = instructionIndexesToVisibleStateIndexes;
  };

  _findPrevVisibilityState = () => {
    for (let i = this.visibleStates.length - 1; i >=0; i--) {
      const visibleState = this.visibleStates[i];
      if (visibleState.instructionIndex < this.currentInstructionIndex) {
        return visibleState;
      }
    }
    return this.visibleStates[0];
  };

  _findNextVisibilityState = () => {
    for (let i = 0; i < this.visibleStates.length; i++) {
      const visibleState = this.visibleStates[i];
      if (visibleState.instructionIndex > this.currentInstructionIndex) {
        return visibleState;
      }
    }
    return this.visibleStates[this.visibleStates.length - 1];
  };

  _isVisibleInstruction = (instruction) => {
    const instructionType = instruction.type;
    if (instructionType === 'displayImage') {
      return true;
    } else if (instructionType === 'displayPointer') {
      return true;
    } else {
      return false;
    }
  };

  dispose = () => {
    this.alive = false;
  };

  executeCurrentInstructionIndex = () => {
    this.executeInstructionIndex(this.currentInstructionIndex);
  };

  executeInstructionIndex = (instructionIndex) => {
    if (this.alive) {
      this.currentInstructionIndex = instructionIndex;
      if (this.instructions) {
        if (instructionIndex < this.instructions.length) {
          const instruction = this.instructions[instructionIndex];
          const allowExecuteNext = true;
          const suppressNotifications = false;
          this._executeInstruction(instructionIndex, instruction, allowExecuteNext, suppressNotifications);
        }
      }
    }
  };

  _executeInstructionAfterDelay = (nextInstructionIndex, delayMilliseconds) => {
    const currentTimeoutHandle = setTimeout(() => {
      if (this.alive && !this.paused && this.currentTimeoutHandle === currentTimeoutHandle) {
        this.currentTimeoutHandle = undefined;
        this.executeInstructionIndex(nextInstructionIndex);
      }
    }, delayMilliseconds);
    this.currentTimeoutHandle = currentTimeoutHandle;
  };

  _executeInstruction = (instructionIndex, instruction, allowExecuteNext, suppressNotifications) => {
    const data = instruction.data;
    let executeNext = true;
    let delayToNext = 0;
    let nextInstructionIndex = instructionIndex + 1;
    if (instruction.type === 'delay') {
      delayToNext = data.milliseconds ? data.milliseconds : this.demoDefinition.defaultDelay;
    } else if (instruction.type === 'displayImage') {
      const imageComponent = {
        type: 'image',
        data: {
          image: {
            src: data.imageSrc,
            offsetX: data.offsetX
          }
        }
      };
      this.addVisibleComponent(imageComponent, suppressNotifications);
    } else if (instruction.type === 'clearVisibleComponents') {
      this.clearVisibleComponents(suppressNotifications);
    } else if (instruction.type === 'blankImageArea') {
      const blankImageAreaComponent = {
        type: 'imageMask',
        data: data
      };
      this.addVisibleComponent(blankImageAreaComponent, suppressNotifications);
    } else if (instruction.type === 'displayPointer') {
      const pointerComponent = {
        type: 'pointer',
        data: data
      };
      this.addVisibleComponent(pointerComponent, suppressNotifications);
    } else if (instruction.type === 'restart') {
      this.clearVisibleComponents(suppressNotifications);
      nextInstructionIndex = 0;
    } else if (instruction.type === 'stop') {
      executeNext = false;
    } else {
      // Unknown instruction
      // debugger;
    }
    if (allowExecuteNext && executeNext) {
      this._executeInstructionAfterDelay(nextInstructionIndex, delayToNext);
    }
  };

  addVisibleComponent = (component, suppressNotifications) => {
    const visibleComponents = this._cloneVisibleComponents();
    visibleComponents.push(component);
    this.visibleComponents = visibleComponents;
    if (!suppressNotifications) {
      this.handlers.onVisibleComponentsChange(this.visibleComponents);
      this._notifyStateChangeIfOnVisibleComponentsChange();
    }
  };

  clearVisibleComponents = (suppressNotifications) => {
    this.visibleComponents = [];
    if (!suppressNotifications) {
      this.handlers.onVisibleComponentsChange(this.visibleComponents);
      this._notifyStateChangeIfOnVisibleComponentsChange();
    }
  };

  _cloneVisibleComponents = () => {
    const visibleComponents = [];
    for (let i = 0; i < this.visibleComponents.length; i++) {
      visibleComponents.push(this.visibleComponents[i]);
    }
    return visibleComponents;
  }

}