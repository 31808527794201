
export default interface UIContext {
  isBackgroundLight: () => boolean;
}

class StandardUIContext implements UIContext {

  isBackgroundLight = (): boolean => {
    return true;
  };

}

export const standardUIContext = new StandardUIContext();
