
const util = {};

util.promiseReturning = (value) => {
  return new Promise((resolve, reject) => {
    resolve(value);
  });
}

util.promiseReturningAsync = (value) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(value);
    }, 1);
  });
}

// util.getHashParameter = (index) => {
//   const hash = window.location.hash;
//   let paramIndex = 0;
//   const hashParts = hash.split('/');
//   for (let i = 0; i < hashParts.length; i++) {
//     const hashPart = hashParts[i];
//     if (hashPart.indexOf(':') === 0) {
//       if (paramIndex === index) {
//         return hashPart.substring(1);
//       }
//       paramIndex++;
//     }
//   }
//   return undefined;
// };

/**
 * Execute a number of promises in series.
 * @param promiseFactories an array of functions that each return a single promise.
 * @returns {*} the result of the last promise.
 */
util.executePromisesInSeries = (promiseFactories) => {
  const firstPromise = Promise.resolve();
  return promiseFactories.reduce(function(lastPromise, promiseFactory) {
    return lastPromise.then(promiseFactory);
  }, firstPromise);
}

module.exports = util;
