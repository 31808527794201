import { KeyboardEvent } from 'react';
// import { Remarkable } from 'remarkable';
import basedWidgetUtil from '../../../commonbase/util/widgetUtil';
import ReactTooltip from 'react-tooltip';
import util from '../../../shared/util/Util';

class WidgetUtil {

  debouncedTooltipRebuild: Function;

  constructor() {
    this.debouncedTooltipRebuild = util.debounce(ReactTooltip.rebuild, 1000, false) as Function;
  }

  bindTextFieldEnterEvent = (event: KeyboardEvent<HTMLInputElement>, handler) => {
    if (event && event.key === 'Enter') {
      handler(event);
    }
  };

  copyTextToClipboard = (text, callback) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (callback) {
      callback();
    };
  };

  textAreaSelectAndCopyToClipboard = (textAreaElement, callback) => {
    textAreaElement.select();
    textAreaElement.focus();
    document.execCommand('copy');
    if (callback) {
      callback();
    };
  };

  markdownToHtml = (markdown) => {
    return basedWidgetUtil.markdownToHtml(markdown);
  }

  markdownToInlineHtml = (markdown) => {
    const markdownWithoutLineBreaks = markdown.replace(/(?:\r\n|\r|\n)/g, ' ');
    const htmlParar = basedWidgetUtil.markdownToHtml(markdownWithoutLineBreaks);
    const html = htmlParar.replace('<p>', '').replace('</p>', '');
    return html;
  }

  scheduleTooltipRebuild = () => {
    this.debouncedTooltipRebuild();
  }

}

export default new WidgetUtil();
