import React, { PureComponent } from "react";
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import ToolbarLeft from '../../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

class GuidanceRenderUtil extends PureComponent {

  renderGuideLabelOrEmoji = (guide, guideIndex, scoreMeta) => {
    if (guide.scoreGuide) {
      return <span>{guide.scoreGuide}</span>;
    } else {
      const marker = scoreMeta.getMarkerByIndex(guideIndex);
      if (marker === undefined) {
        return null;
      } else {
        const scorePercent = scoreMeta.scoreToPercent(marker.score);
        const assessmentItem = scoreUtil.buildAssessmentItemFromScore(scorePercent);
        const radius = 8;
        const padding = 1;
        const size = radius * 2 + padding * 2;
        const foregroundColor = '#000';
        const backgroundColor = '#fff';
        const showHead = true;
        return (
          <ToolbarLeft>
            <ToolbarItem style={{lineHeight: radius + padding * 2 + 'px'}}>
              <svg width={size} height={size}>
                {scoreMeta.renderEmoji(
                  assessmentItem, radius, radius + padding, radius + padding, foregroundColor, backgroundColor, showHead)}
              </svg>
            </ToolbarItem>
            <ToolbarItem>
              {marker.tooltip}
            </ToolbarItem>
          </ToolbarLeft>
        );
      }
    }
  }

}

export default new GuidanceRenderUtil();
