import React from 'react';
import adg from '../../../commonbase/adg';
import actions from '../../actions/Actions';
import ListenerGroup from '../../util/ListenerGroup';
import rubricDAO from '../../../backend/data/RubricDAO';
import rubricUtil from './RubricUtil';
import { ErrorIcon } from '../../../frontend/components/icon/NamedIcons';
import util from '../../util/Util';
import Definition from './definition/Definition';
import Assessment from './score/Assessment';

interface State {
  definition: undefined | Definition,
  definitionUuid: undefined | string,
  definitionName: undefined | string,
  assessment: undefined | Assessment,
  assessmentUuid: undefined | string,
  scoreUuid: undefined | string,
  inEditMode: boolean
}

class CurrentRubric {

  listenerGroup = new ListenerGroup('CurrentRubric');
  state: State = {
    definition: undefined,
    definitionUuid: undefined,
    definitionName: undefined,
    assessment: undefined,
    assessmentUuid: undefined,
    scoreUuid: undefined,
    inEditMode: false
  };

  setRubricReference = (rubricReference, inEditMode) => {
    if (rubricUtil.isAssessmentReference(rubricReference)) {
      this.setDefinitionAndAssessmentUuids(rubricReference.definitionUuid, rubricReference.assessmentUuid, inEditMode);
    } else {
      this.setDefinitionByUuid(rubricReference.definitionUuid, inEditMode);
    }
  };

  setDefinitionAndAssessmentUuids = (definitionUuid: string, assessmentUuid: string, inEditMode: boolean) => {
    const definitionPromise = rubricDAO.getDefinitionByUuid(definitionUuid);
    const assessmentPromise = rubricDAO.getAssessmentByUuid(assessmentUuid);
    Promise.all([definitionPromise, assessmentPromise])
      .then((results) => {
        const definition = results[0];
        const assessment = results[1];
        if (definition) {
          if (assessment) {
            this._setDefinitionAndAssessmentUuids(definition, definition.uuid, definition.name, assessment, assessment.uuid, inEditMode);
          } else {
            this.popupFailureFlag(
              'Assessment not found',
              'Oh my! The assessment is no longer accessible. :(',
              'Remove references',
              () => {actions.removeAssessmentReferences(assessmentUuid)});
          }
        } else {
          this.popupFailureFlag(
            'Rubric not found',
            'Oh my! The rubric is no longer accessible. The owner could have changed permissions or deleted it.',
            'Remove references',
            () => {actions.removeDefinitionReferences(definitionUuid)});
        }
      });
  };

  popupFailureFlag = (title, description, cleanupLabel, cleanupHandler) => {
    const flagId = util.uuid();
    const flagActions = [{
      content: cleanupLabel,
      onClick: () => {
        actions.removeFlag(flagId);
        cleanupHandler();
      }
    }, {
      content: 'Ignore',
      onClick: () => {
        actions.removeFlag(flagId);
      }
    }];
    const flag = {
      id: flagId,
      appearance: "error",
      icon: <ErrorIcon label="error" secondaryColor={adg.adgRed} />,
      title: title,
      description: description,
      flagMessageTimeoutSeconds: 20,
      actions: flagActions
    };
    actions.addFlag(flag); 
  };

  setDefinitionByUuid = (definitionUuid, inEditMode) => {
    rubricDAO.getDefinitionByUuid(definitionUuid).then((definition) => {
      if (definition) {
        this.setDefinition(definition, inEditMode);
      } else {
        const title = 'Rubric not found';
        const description = 'Oh my! The rubric is no longer accessible. The owner could have changed permissions or deleted it.';
        const cleanupLabel = '';
        const cleanupHandler = undefined;
        this.popupFailureFlag(title, description, cleanupLabel, cleanupHandler);
      }
    });
  };

  setDefinitionAndAssessment = (definition, assessment, inEditMode) => {
    if (definition) {
      if (assessment) {
        this._setDefinitionAndAssessmentUuids(definition, definition.uuid, definition.name, assessment, assessment.uuid, inEditMode);
      } else {
        this._setDefinitionAndAssessmentUuids(definition, definition.uuid, definition.name, undefined, undefined, inEditMode);
      }
    } else {
      this._setDefinitionAndAssessmentUuids(undefined, undefined, undefined, undefined, undefined, inEditMode);
    }
  };

  setDefinition = (definition: undefined | Definition, inEditMode: boolean) => {
    if (definition) {
      this._setDefinitionAndAssessmentUuids(definition, definition.uuid, definition.name, undefined, undefined, inEditMode);
    } else {
      this._setDefinitionAndAssessmentUuids(undefined, undefined, undefined, undefined, undefined, inEditMode);
    }
  };

  _setDefinitionAndAssessmentUuids = (definition, definitionUuid, definitionName, assessment, assessmentUuid, inEditMode) => {
    this.state.definition = definition;
    this.state.definitionUuid = definitionUuid;
    this.state.definitionName = definitionName;
    this.state.assessment = assessment;
    this.state.assessmentUuid = assessmentUuid;
    this.state.inEditMode = inEditMode === true;
    this._notifyListeners();
  };

  setAssessmentUuid = (assessmentUuid) => {
    this.state.assessmentUuid = assessmentUuid;
    this._notifyListeners();
  };

  getDefinition = () => {
    return this.state.definition;
  };

  getDefinitionUuid = () => {
    return this.state.definitionUuid;
  };

  getAssessmentUuid = () => {
    return this.state.assessmentUuid;
  };

  isInEditMode = () => {
    return this.state.inEditMode;
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = () => {
    this.listenerGroup.notifyListeners(this.state);
  };

}

export default new CurrentRubric();
