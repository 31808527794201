
import React, { PureComponent } from "react";
import Expand from './Expand';
import environment from '../../../shared/model/Environment';

export type DebugObjectState = {
  objectName: string,
  visible?: boolean,
  object: any,
  expanded: boolean
};

export type DebugObjectProps = {
  objectName: string,
  visible?: boolean,
  object: any
};

export default class DebugObject extends PureComponent<DebugObjectProps> {

  state: DebugObjectState;

  constructor(props: DebugObjectProps) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: DebugObjectProps) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: DebugObjectProps): DebugObjectState => {
    const visible = props.visible && environment.isRunningLocally;
    return {
      objectName: props.objectName,
      visible: visible,
      object: props.object,
      expanded: false
    };
  };

  renderObject = () => {
    return (
      <code><pre>{JSON.stringify(this.state.object, null, 2)}</pre></code>
    );
  };

  render() {
    if (this.state.visible) {
      return (
        <Expand
          contentForeignKey={undefined}
          text={`Debug ${this.state.objectName}`}
          initiallyExpanded={this.state.expanded}
          contentRenderer={this.renderObject}
          stopPropagation={true}
        />
      );
    } else {
      return null;
    }
  }

}
