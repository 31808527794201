import util from './Util';

class SelectUtil {

  findOption = (rubricOptionGroups, definitionUuid) => {
    for (const group of rubricOptionGroups) {
      for (const option of group.options) {
        if (option.value === definitionUuid) {
          return option;
        }
      }
    }
    return undefined;
  };

  findGroup = (rubricOptionGroups, groupLabel) => {
    for (const group of rubricOptionGroups) {
      if (group.label === groupLabel) {
        return group;
      }
    }
    return undefined;
  };

  sortOptions = (optionGroups) => {
    if (optionGroups && optionGroups.length) {
      util.sortObjectsByField(optionGroups, 'label');
      for (const group of optionGroups) {
        if (group && group.options && group.options.length) {
          util.sortObjectsByField(group.options, 'label');
        }
      }
    }
    return optionGroups;
  };

  isOptionInArray = (value, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return true;
      }
    }
    return false;
  };

};

export default new SelectUtil();
