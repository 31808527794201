import React, { PureComponent } from 'react';
import AddColumnIcon from '../../icon/AddColumnIcon';
import AddRowIcon from '../../icon/AddRowIcon';
import constants from '../../../../shared/model/Constants';
import discoverabilityDefinitions from '../../../../shared/model/rubric/DiscoverabilityDefinitions';
import DemoCreateBuilder from './content/DemoCreateBuilder';
import DemoEditBuilder from './content/DemoEditBuilder';
import DemoSearchBuilder from './content/DemoSearchBuilder';
import DemoSharingBuilder from './content/DemoSharingBuilder';
import DemoSidebarBuilder from './content/DemoSidebarBuilder';
import InfoMessageBanner from '../../widget/banner/InfoMessageBanner';
import PopupDemoPanel from './PopupDemoPanel';
import planDefinitions from '../../../../shared/model/payment/PlanDefinitions';
import RemoveRowIcon from '../../icon/RemoveRowIcon';
import RemoveColumnIcon from '../../icon/RemoveColumnIcon';
import HelpViewItem from './HelpViewItem';
import util from '../../../../shared/util/Util';
import {
  AddAssessmentIcon, AcceptNotificationIcon,
  CollaboratorsIcon,
  GlobalCreateIcon, EditNameIcon, EditRubricIcon,
  CreateRubricIcon, CopyRubricIcon, IdeaIcon, NotificationsIcon,
  RejectNotificationIcon,
  ShareRubricIcon,
  ShareAssessmentIcon, WarningIcon, ViewRubricIcon
} from '../../icon/NamedIcons';

export default class HelpView extends PureComponent {

  constructor(props) {
    super(props);
    this.demoPreviewWidth = 300;
    this.demoPopupWidth = 800;
    this.previewHeight = 200;
    this.state = {
      items: []
    };
    this.addItem('', this.buildGettingAroundDemoStartItem());
    this.addItem('Getting around', this.buildGettingAroundItem());
    this.addItem('', this.buildDemoEndItem());

    this.addItem('', this.buildFindingARubricDemoStartItem());
    this.addItem('Searching for rubrics', this.buildFindingARubricItem());
    this.addItem('', this.buildDemoEndItem());

    this.addItem('', this.buildCreatingARubricDemoStartItem());
    this.addItem('Creating a rubric', this.buildCreatingARubricItem());
    this.addItem('', this.buildDemoEndItem());

    this.addItem('', this.buildEditDemoStartItem());
    this.addItem('Renaming a rubric', this.buildRenamingARubricItem());
    this.addItem('Editing a rubric', this.buildEditingARubricItem());
    this.addItem('', this.buildDemoEndItem());

    this.addItem('Copying a rubric', this.buildCopyingARubricItem());

    this.addItem('Assessing a rubric', this.buildAssessingItem());
    this.addItem('Multiple assessments', this.buildMultipleAssessmentsItem());


    this.addItem('', this.buildSharingARubricDemoStartItem());
    this.addItem('Sharing a rubric', this.buildSharingARubricItem());
    this.addItem('Sharing an assessment', this.buildSharingAssessmentsItem());
    this.addItem('', this.buildDemoEndItem());

    this.addItem('Notifications', this.buildNotificationsItem());
    this.addItem('Acknowledging notifications', this.buildAcknowledgingNotificationsItem());
    this.addItem('Rejecting notifications', this.buildRejectingNotificationsItem());
  }

  buildDemoEndItem = () => {
    return (
      <div style={{clear: 'both'}}>
      </div>
    );
  };

  buildGettingAroundDemoStartItem = () => {
    return (
      <div style={{float: 'right', marginLeft: '20px'}}>
        <PopupDemoPanel
          title="Getting around"
          previewWidth={this.demoPreviewWidth}
          previewHeight={this.previewHeight}
          definition={
            new DemoSidebarBuilder()
            .setWidth(this.demoPopupWidth)
            .build()
          }
        />
      </div>
    );
  };

  buildGettingAroundItem = () => {
    return (
      <div>
        <p>
          First up, {constants.appTitle} is optimized for desktop and laptop computers rather than
          mobile devices.
        </p>
        <p>
          The blue sidebar at the left contains buttons allowing you to navigate {constants.appTitle}.
          The sidebar can be expanded to provide more details and collapse so the main content area is wider.
          You can toggle the state of the sidebar by pressing <span className="attention">[</span>.
          You can also drag the sidebar panel open and closed.
        </p>
        {this.buildDemoEndItem()}
      </div>
    );
  };

  buildFindingARubricDemoStartItem = () => {
    return (
      <div style={{float: 'left', marginRight: '20px'}}>
        <PopupDemoPanel
          title="Searching for rubrics"
          previewWidth={this.demoPreviewWidth}
          previewHeight={this.previewHeight}
          definition={
            new DemoSearchBuilder()
            .setWidth(this.demoPopupWidth)
            .build()
          }
        />
      </div>
    );
  };

  buildFindingARubricItem = () => {
    return (
      <div>
        <p>
          To find a rubric you click on the dashboard button and select a rubric from the given templates. Above the template options,
          it shows your recent rubrics.
        </p>
      </div>
    );
  };

  buildCreatingARubricDemoStartItem = () => {
    return (
      <div style={{float: 'right', marginLeft: '20px'}}>
        <PopupDemoPanel
          title="Creating a rubric"
          previewWidth={this.demoPreviewWidth}
          previewHeight={this.previewHeight}
          definition={
            new DemoCreateBuilder()
            .setWidth(this.demoPopupWidth)
            .build()
          }
        />
      </div>
    );
  };

  buildCreatingARubricItem = () => {
    return (
      <div>
        <p>
          To create a rubric, click the <GlobalCreateIcon/> icon in the left side navigation menu.
          This will open the create menu from which you can click the
          "<CreateRubricIcon/> New rubric" menu option.
        </p>
        <p>
          Once this is done, you'll be in the rubric editor page with the newly created rubric.
          See the section on section on modifying a rubric for more details.
        </p>
      </div>
    );
  };

  buildEditDemoStartItem = () => {
    return (
      <div style={{float: 'left', marginRight: '20px'}}>
        <PopupDemoPanel
          title="Editing a rubric"
          previewWidth={this.demoPreviewWidth}
          previewHeight={this.previewHeight}
          definition={
            new DemoEditBuilder()
            .setWidth(this.demoPopupWidth)
            .build()
          }
        />
      </div>
    );
  };

  buildRenamingARubricItem = () => {
    return (
      <div>
        <p>
          You can rename a rubric that you own at any time.
          Renaming a rubric won't affect any assessments against the rubric.
        </p>
        <p>
          To rename a rubric, you will need to be in edit mode.
          Open the rubric to rename and click the <EditRubricIcon width={14} height={14}/> to
          enter edit mode.
          Once in edit mode, a text field will allow the rubric to be renamed.
        </p>
      </div>
    );
  };

  buildEditingARubricItem = () => {
    return (
      <div>
        <p>
          You can only edit the rubrics you own.
          When viewing a rubric that you own, you'll see a <EditRubricIcon/> icon which, when pressed,
          will take you to the rubric editor.
        </p>
        <p>
          The rubric editor allows you to edit the contents of a rubric.
        </p>
        <p>
          <AddColumnIcon width={14} height={14}/> This icon appears between columns.
          When clicked, it inserts a new column at its position.
        </p>
        <p>
          <AddRowIcon width={14} height={14}/> This icon appears between rows.
          When clicked, it inserts a new row at its position.
        </p>
        <p>
          <RemoveColumnIcon width={14} height={14}/> This icon appears above columns.
          When clicked, it deletes the column in its position.
        </p>
        <p>
          <RemoveRowIcon width={14} height={14}/> This icon appears to the left of rows.
          When clicked, it deletes the row in its position.
        </p>
      </div>
    );
  };

  buildCopyingARubricItem = () => {
    return (
      <div>
        <p>
          <CopyRubricIcon/>
          Copying a rubric is making a duplicate for you to work on.
          You may want to do this if assessments already exist for a rubric that you want to change
          to avoid breaking the assessments.
          Another reason why you may want to fork a rubric is when you don't own the original rubric,
          and want to create a similar rubric.
        </p>
      </div>
    );
  };

  buildSharingARubricDemoStartItem = () => {
    return (
      <div style={{float: 'right', marginLeft: '20px'}}>
        <PopupDemoPanel
          title="Sharing rubrics and assessments"
          previewWidth={this.demoPreviewWidth}
          previewHeight={this.previewHeight}
          definition={
            new DemoSharingBuilder()
              .setWidth(this.demoPopupWidth)
              .build()
          }
        />
      </div>
    );
  };

  buildSharingARubricItem = () => {
    const defs = discoverabilityDefinitions;
    return (
      <div>
        <p>
          To share a rubric, view it and click the <ShareRubricIcon/> symbol (if available).
        </p>
        <p>
          A rubric can have one of three levels of <i>discoverability</i> which determines who is able to access it and how:
        </p>
        <ul>
          <li>
            <b>{defs.typesToNames[defs.privateDiscoverabilityType]}</b>: Only the owner of the rubric will be able to see it.
          </li>
          <li>
            <b>{defs.typesToNames[defs.linkDiscoverabilityType]}</b>: Anyone with the link to the rubric will be able to see it.
          </li>
          <li>
            <b>{defs.typesToNames[defs.publicDiscoverabilityType]}</b>: Anyone will be able to see it and it will appear in search results.
          </li>
        </ul>
        <p>
          Before sharing a rubric, you need to make sure its discoverability is <b>not {defs.typesToNames[defs.privateDiscoverabilityType]}</b>.
        </p>
      </div>
    );
  };

  buildAssessingItem = () => {
    const litePlan = planDefinitions.typesToDefinitions[planDefinitions.liteType];
    const proPlan = planDefinitions.typesToDefinitions[planDefinitions.proType];
    const enterprisePlan = planDefinitions.typesToDefinitions[planDefinitions.enterpriseType];
    const renderedWarning =
      litePlan && !litePlan.allowCreateAssessments && proPlan.allowCreateAssessments ?
        (
          <p>
            <WarningIcon width={14} height={14}/> You need to sign up to either the {proPlan.name} or
            {enterprisePlan.name} plan in order to be able to create assessments.
          </p>
        ) : null;
    return (
      <div>
        {renderedWarning}
        <p>
          Once a rubric is defined, assessments can be made against it. 
          To create a new assessment, visit the rubric and click the <AddAssessmentIcon/> symbol.
        </p>
        <p>
          Each statement of a rubric can be assessed individually. 
          Simply click on a cell and it will change to allow a <em>score</em> and <em>annotation</em> to
          be entered.
        </p>
        <p>
          Note that a score of zero is only registered for a statement if an annotation has 
          been provided. 
        </p>
      </div>
    );
  };

  buildMultipleAssessmentsItem = () => {
    return (
      <div>
        <p>
          A rubric can be assessed any number of times.
          For example, a teacher will want to create a separate assessment for each of their students.
          A student may also want to create their own assessment and update it as they complete
          their assignment.
        </p>
        <p>
          Each assessment has a name.
          You can change the name of your assessments at any time by
          clicking <EditNameIcon width={14} height={14}/> when the assessment is selected.
        </p>
      </div>
    );
  };

  buildSharingAssessmentsItem = () => {
    return (
      <div>
        <p>
          An assessment owner may share the assessment with others.
          For example, perhaps you have finished an assignment which you have self assessed.
          Sharing this assessment with your teacher or supervisor will help explain your thoughts.
        </p>
        <p>
          To share an assessment, select the assessment and click the <ShareAssessmentIcon/> symbol.
        </p>
        <p>
          Anyone that the assessment has been shared with is called a <b>collaborator</b>.
          You can see the assessment collaborators on the rubric page by clicking on the
          <CollaboratorsIcon width={14} height={14}/> icon when the assessment is selected.
        </p>
        <p>
          <IdeaIcon width={14} height={14}/> Before sharing an assessment, it's a good idea to
          make sure it's name clearly identifies who or what the assessment is of.
        </p>
      </div>
    );
  };

  buildNotificationsItem = () => {
    return (
      <div>
        <p>
          The notifications page allows you to see the rubrics and assessments that have
          been shared with you.
          To open the notifications page, click on the menu item marked by
          the <NotificationsIcon width={14} height={14}/> symbol.
        </p>
        <p>
          To visit the rubric or assessment identified by a notification, click on
          the <ViewRubricIcon width={14} height={14}/> symbol.
        </p>
        <p>
          New notifications will be highlighted by adorning the notifications icon with a
          red badge identifying the number of notifications to acknowledge.
        </p>
      </div>
    );
  };

  buildAcknowledgingNotificationsItem = () => {
    return (
      <div>
        <p>
          Notifications need to be acknowledged before the related rubric or assessment can be viewed.
        </p>
        <p>
          Notifications can be acknowledged in the notifications page by clicking on
          the <AcceptNotificationIcon width={14} height={14}/> symbol that appears at the top right corner of
          the notification panel.
        </p>
      </div>
    );
  };

  buildRejectingNotificationsItem = () => {
    return (
      <div>
        <p>
          Notifications can also be rejected.
          If a notification is rejected, the related rubric or assessment can not be viewed.
          You can acknowledge a rejected notification in case you make a mistake or change
          your mind.
        </p>
        <p>
          Notifications can be rejected in the notifications page by clicking on
          the <RejectNotificationIcon width={14} height={14}/> symbol that appears at the top right
          corner of the notification panel.
        </p>
      </div>
    );
  };

  addItem = (title, content) => {
    const item = (
      <HelpViewItem
        key={util.uuid()}
        title={title}
      >
        {content}
      </HelpViewItem>
    );
    this.state.items.push(item);
  };

  renderTableOfContents = () => {
    return null;
  };

  render() {
    const renderedItems = this.state.items.map((item) => {
      return item;
    });
    return (
      <div>
        <InfoMessageBanner>
          Need to ask a question? Visit
          our <a
            href="https://groups.google.com/forum/#!forum/roobrick"
            rel="noopener noreferrer"
            target="_blank">Google group</a>.
        </InfoMessageBanner>
        {this.renderTableOfContents()}
        {renderedItems}
      </div>
    );
  }

}