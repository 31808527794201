import constants from '../../shared/model/Constants';
import currentRubric from '../../shared/model/rubric/CurrentRubric';
import urlUtil from '../../shared/util/UrlUtil';
import rubricDAO from "../../backend/data/RubricDAO";
import Definition from '../../shared/model/rubric/definition/Definition';

class RubricPageHelper {

  updateTitle = (suffix?: string) => {
    if (suffix) {
      document.title = constants.appTitle + ' - ' + suffix;
    } else {
      document.title = constants.appTitle;
    }
  }

  resolveDefinitionContext(uuidHashParameterIndex: number, permissionCheck: (definition: Definition) => boolean): Promise<undefined | Definition> {
    let currentDefinition: undefined | Definition = currentRubric.getDefinition();
    const uuid = urlUtil.getHashParameter(uuidHashParameterIndex);
    if (uuid) {
      if (currentDefinition && currentDefinition.uuid === uuid) {
        return this._resolveDefinition(currentDefinition, permissionCheck);
      } else {
        return rubricDAO.getDefinitionByUuid(uuid).then((definition) => {
          if (definition && permissionCheck(definition)) {
            return definition;
          } else {
            return undefined;
          }
        });
      }
    } else if (currentDefinition) {
      return this._resolveDefinition(currentDefinition, permissionCheck);
    } else {
      return new Promise((resolve, reject) => {
        resolve(undefined);
      });
    }
  }

  _resolveDefinition = (definition: Definition, permissionCheck: (definition: Definition) => boolean): Promise<undefined | Definition> => {
    return new Promise((resolve, reject) => {
      if (permissionCheck(definition)) {
        resolve(definition);
      } else {
        resolve(undefined);
      }
    });
  };

}

export default new RubricPageHelper();
