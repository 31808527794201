import React, { PureComponent } from "react";
import ScoreTag from './ScoreTag';
import ToolbarLeft from '../../../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../../components/widget/toolbar/ToolbarItem';

export default class DiscreteScoreInput extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      label: props.label,
      editable: props.editable,
      scoreMeta: props.scoreMeta,
      scorePercent: props.scorePercent
    };
  };

  onScoreChange = (marker) => {
    if (!this.state.editable) {
      return;
    }
    const existingScore = this.state.scoreMeta.percentToScore(this.state.scorePercent); 
    if (existingScore === marker.score) {
      // Clear the score
      const scorePercent = undefined;
      this.setState({
        scorePercent: scorePercent
      });
      // debugger;
      this.props.onScoreChange(scorePercent);
    } else {
      const scorePercent = this.state.scoreMeta.scoreToPercent(marker.score);
      this.setState({
        scorePercent: scorePercent
      });
      this.props.onScoreChange(scorePercent);
    }
  };

  render() {
    const score = this.state.scoreMeta.percentToScore(this.state.scorePercent);
    const renderedMarkers = this.state.scoreMeta.markers.map((marker) => {
      const selected = marker.score === score;
      const className = this.state.editable ? 'clickable' : undefined;
      return (
        <ToolbarItem
          key={'marker-' + marker.score}
          className={className}
          onClick={() => {this.onScoreChange(marker)}}
        >
          <ScoreTag
            inputMode={true}
            selected={selected}
            text={marker.label}
            color={marker.color}
            appearance="rounded"
          />
        </ToolbarItem>
      );
    });
    return (
      <ToolbarLeft>
        {renderedMarkers}
      </ToolbarLeft>
    );
  }

}
