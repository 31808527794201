
export class ScriptLoader {

  scriptUrlsToLoadingStates = {};

  loadScript = (scriptUrl: string, options?: any) => {
    return new Promise((resolve, reject) => {
      if (this.scriptUrlsToLoadingStates[scriptUrl] === 'loaded') {
        resolve(undefined);
      } else {
        const onScriptLoaded = () => {
          this.scriptUrlsToLoadingStates[scriptUrl] = 'loaded';
          resolve(undefined);
        };
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptUrl;
        script.onreadystatechange = onScriptLoaded;
        script.onload = onScriptLoaded;
        if (options) {
          for (const [key, value] of Object.entries(options)) {
            script[key] = value;
          }
        }
        document.head.appendChild(script);
      }
    });
  };

}

export default new ScriptLoader();
