
class EmailNotificationDefinitions {

  definitionLikeEmailsAllowedType = 'definitionLikeEmailsAllowed';
  newBlogEmailsAllowedType = 'newBlogEmailsAllowed';
  definitionLikeEmailsAllowed = 'definitionLikeEmailsAllowed';

}

export default new EmailNotificationDefinitions();
