import React, { PureComponent } from 'react';
import adg from '../../../../commonbase/adg';
import AspectBarChart from '../chart/AspectBarChart';
import aspectGroupingDefinitions from '../../../../shared/model/rubric/AspectGroupingDefinitions';
import ChartLabel from '../chart/ChartLabel';
import ChartModel from '../chart/ChartModel';
import ContainerDimensions from 'react-container-dimensions';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import ScoreSvg from "../score/ScoreSvg";
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import Tag from '@atlaskit/tag';
import util from '../../../../shared/util/Util';
import widgetUtil from '../../../components/widget/WidgetUtil';
import ToolbarLeft from '../../../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

export default class PromotionView extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    let assessmentItem = undefined;
    let chartModel;
    if (props.definition && props.assessment) {
      const columnVisibilities = rubricUtil.buildDefaultColumnVisibilities(props.definition);
      assessmentItem = this.buildAssessmentItem(props.definition, props.assessment);
      const visibleLabels = props.definition.labels;
      chartModel = new ChartModel(
        props.definition,
        [props.assessment],
        visibleLabels,
        columnVisibilities);
    }
    return {
      showScores: false,
      definition: props.definition,
      scoreMeta: rubricUtil.getScoreMeta(props.definition),
      assessment: props.assessment,
      assessmentItem: assessmentItem,
      chartModel: chartModel
    };
  };

  buildAssessmentItem = (definition, assessment) => {
    const pillars = rubricUtil.getDefinitionPillars(definition);
    const pillarsToAssessmentItems = {};
    for (const pillar of pillars) {
      pillarsToAssessmentItems[pillar] = [];
    }
    const context = {
      definition: definition,
      assessment: assessment,
      assessmentItem: {
        pillarsToAssessmentItems: pillarsToAssessmentItems
      }
    };
    const onStatement = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context) => {
      // const assessmentItem = scoreUtil.getUserFacingAssessmentItem(assessment, statement.uuid);
      const assessmentItem = scoreUtil.getInternalAssessmentItem(assessment, statement.uuid);
      if (scoreUtil.hasScore(assessmentItem)) {
        const score = scoreUtil.assessmentItemToScore(assessmentItem)
        const info = {
          key: statement.uuid,
          statementText: statement.text,
          score: score,
          annotation: assessmentItem.annotation
        };
        const pillarsToAssessmentItems = context.assessmentItem.pillarsToAssessmentItems;
        const pillar = rubricUtil.extractPillarFromRowName(row.name);
        if (pillar) {
          const infos = pillarsToAssessmentItems[pillar];
          infos.push(info);
          }
      }
    };
    rubricUtil.iterateStatements(definition, onStatement, context);
    for (const pillar of pillars) {
      const infos = pillarsToAssessmentItems[pillar];
      this.sortAssessmentItem(infos);
    }
    return context.assessmentItem;
  };

  sortAssessmentItem = (assessmentItems) => {
    util.sortObjectsByField(assessmentItems, 'score', true);
  };

  hasLabel = (labelToFind, labels) => {
    for (const label of labels) {
      if (label === labelToFind) {
        return true;
      }
    }
    return false;
  };

  extractAspectFromRow = (row) => {
    const components = row.name.split(':');
    return components[2].trim();
  };

  scoreToRating = (score) => {
    if (!score || score < 30) {
      return 'Promotion warning';
    } else {
      return 'Promotion thumbs up';
    }
  };

  renderSectionTitle = (text, overallScore) => {
    const width = 24;
    const height = width;
    const radius = width / 2;
    const ratingClassification = this.scoreToRating(overallScore);
    const assessmentItem = scoreUtil.buildAssessmentItemFromScore(overallScore);
    return (
      <ToolbarLeft>
        <ToolbarItem>
          <h3>
            {text}
          </h3>
        </ToolbarItem>
        <ToolbarItem>
          <svg width={width} height={height}>
            <ScoreSvg
              scoreMeta={this.state.scoreMeta}
              assessmentItem={assessmentItem}
              radius={radius}
            />
          </svg>
        </ToolbarItem>
        <ToolbarItem>
          <Tag
            text={ratingClassification}
            color="red"
            appearance="rounded"
          />
        </ToolbarItem>
      </ToolbarLeft>
    );
  };

  renderScore = (assessmentItem) => {
    const score = scoreUtil.assessmentItemToScore(assessmentItem);
    if (score === undefined) {
      return null;
    } else {
      if (this.state.showScores) {
        return (
          <span style={{color: adg.adgRed}}>
            {Math.round(score)}
          </span>
        );
      } else {
        return null;
      }  
    }
  };

  renderSection = (title, assessmentItems) => {
    const individualScores = [];
    const renderedAssessmentItems = assessmentItems.map((assessmentItem) => {
      const annotationHtml = widgetUtil.markdownToHtml(assessmentItem.annotation);
      const renderedScore = this.renderScore(assessmentItem);
      const score = scoreUtil.assessmentItemToScore(assessmentItem);
      if (score !== undefined) {
        individualScores.push(score);
      }
      const scoreSeparator = this.state.showScores ? ': ' : '';
      const renderedAnnotationHtml = (
        <span
          dangerouslySetInnerHTML={{__html: annotationHtml}}>
        </span>
      );
      return (
        <div key={assessmentItem.key}>
          <b>{assessmentItem.statementText}</b> {renderedScore}{scoreSeparator} {renderedAnnotationHtml}
        </div>
      );
    });
    const overallScore = util.computeAverage(individualScores);
    return (
      <div
        style={{marginTop: '20px'}}
      >
        {this.renderSectionTitle(title, overallScore)}
        {renderedAssessmentItems}
      </div>
    );
  };

  renderAspectBarChart = (aspectGrouping) => {
    if (!this.state.chartModel) {
      return null;
    }
    if (!this.state.scoreMeta) {
      throw new Error('this.state.scoreMeta not defined')
    }
    return (
      <React.Fragment>
        <ChartLabel definition={this.state.definition} aspectGrouping={aspectGrouping}/>
        <ContainerDimensions>
          { ({ width, height }) =>
            <AspectBarChart
              scoreMeta={this.state.scoreMeta}
              width={width}
              chartModel={this.state.chartModel}
              aspectGrouping={aspectGrouping}
            />
          }
        </ContainerDimensions>
      </React.Fragment>
    );
  };

  renderAssessment = () => {
    const pillarSections = [];
    const pillarsToAssessmentItems = this.state.assessmentItem.pillarsToAssessmentItems;
    const pillars = Object.keys(pillarsToAssessmentItems);
    for (const pillar of pillars) {
      const infos = pillarsToAssessmentItems[pillar];
      const section = this.renderSection(pillar, infos);
      pillarSections.push(section);
    }
    return (
      <div style={{marginTop: '20px'}}>
        <h2>Promotion: {this.state.assessment.name}</h2>
        {pillarSections}
        {this.renderAspectBarChart(aspectGroupingDefinitions.pillarAspectGroupingType)}
        {this.renderAspectBarChart(aspectGroupingDefinitions.labelAspectGroupingType)}
      </div>
    );
  };

  render() {
    if (this.state.definition && this.state.assessment) {
      return this.renderAssessment();
    } else {
      return null;
    }
  };

}
