import analytics, { analyticsDefinitions } from '../../../../shared/model/analytics/Analytics';
import currentRubric from '../../../../shared/model/rubric/CurrentRubric';
import firestoreAssessmentDAO from '../../../../backend/data/FirestoreAssessmentDAO';
import I18N from '../../../../shared/model/i18n/I18N';
import rubricDAO from '../../../../backend/data/RubricDAO';
import RubricDefinitionBuilder from "../../../../shared/model/rubric/RubricDefinitionBuilder";
import discoverabilityDefinitions from "../../../../shared/model/rubric/DiscoverabilityDefinitions";
import constants from "../../../../shared/model/Constants";
import scoreMetaDefinitions from '../../../../shared/model/rubric/score/ScoreMetaDefinitions';
import session from "../../../../shared/model/auth/Session";
import util from '../../../../shared/util/Util';
import assessmentUtil from '../../../../shared/model/rubric/score/AssessmentUtil';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
import RubricAssessmentBuilder from '../../../../shared/model/rubric/RubricAssessmentBuilder';
// import { poorToStrong } from '../../../../commonbase/score/scoreTypes';

export interface DecisionOptions {

}

class DefinitionCreator {

  _createDefinitionBuilder = () => {
    const user = session.getCurrentUser();
    return new RubricDefinitionBuilder()
      .setOwner(user)
      .setDiscoverability(discoverabilityDefinitions.publicDiscoverabilityType)
      .setBorderColor(constants.defaultRubricBorderColor)
      .setHoverColor(constants.defaultRubricHoverColor);
  };

  _completeDefinitionCreation = (definition: Definition, onDefinitionCreated?: (definition: Definition) => Promise<void>) => {
    rubricDAO.denormaliseDefinition(definition);
    rubricDAO.saveDefinition(definition).then((definition) => {
      if (onDefinitionCreated) {
        onDefinitionCreated(definition)
        .then(() => {
          currentRubric.setDefinition(definition, true);
        });
      } else {
        currentRubric.setDefinition(definition, true);
      }
    });
  };

  onNewOneByThreeRubric = () => {
    this.newBlankRubric(1, 3);
  };

  onNewTwoByThreeRubric = () => {
    this.newBlankRubric(2, 3);
  };

  onNewThreeByThreeRubric = () => {
    this.newBlankRubric(3, 3);
  };

  onNewFourByThreeRubric = () => {
    this.newBlankRubric(4, 3);
  };

  onNewFiveByThreeRubric = () => {
    this.newBlankRubric(5, 3);
  };

  newBlankRubric = (optionalColumnCount?: number, optionalRowCount?: number, optionalName?: string) => {
    const columnCount = optionalColumnCount ? optionalColumnCount : 1;
    const rowCount = optionalRowCount ? optionalRowCount : 4;
    const name = optionalName ? optionalName : columnCount === 1 ? `My rubric` : `My ${columnCount} x ${rowCount} rubric`;
    analytics.event({
      category: analyticsDefinitions.categories.rubric,
      action: analyticsDefinitions.actions.create,
      label: 'holistic'
    });
    const buildBlankStringArray = (count) => {
      const items: string[] = [];
      for (let i = 0; i < count; i++) {
        items.push('');
      }
      return items;
    };
    const columnNames = buildBlankStringArray(columnCount);
    const rowNames = buildBlankStringArray(rowCount);
    const statementBuilder = (columnIndex, rowIndex) => {
      return '';
    };
    const definition = this._createDefinitionBuilder()
      .setName(name)
      .setDescription(``)
      .setStatementBuilder(statementBuilder)
      .setColumnNames(columnNames)
      .setRowNames(rowNames)
      .setOptionHideCompletedAspects(false)
      .setOptionHideZeroScores(false)
      .setOptionHideColumnTotals(false)
      .build();
    this._completeDefinitionCreation(definition);
  };

  onNewBlankRubric = (event) => {
    return this.newBlankRubric();
  };

  onNewHolisticRubric = (event) => {
    analytics.event({
      category: analyticsDefinitions.categories.rubric,
      action: analyticsDefinitions.actions.create,
      label: 'holistic'
    });
    const columnNames = ['25%', '50%', '75%', '100%'];
    const rowNames = ['Criteria'];
    const statementBuilder = (columnIndex, rowIndex) => {
      if (rowIndex === 0) {
        return 'To be completed. (e.g. "Patches of the lawn not mown...")';
      } else if (rowIndex === 1) {
        return 'To be completed. (e.g. "Most of the lawn mown...")';
      } else if (rowIndex === 2) {
        return 'To be completed. (e.g. "Most of the lawn mown ...")';
      } else if (rowIndex === 3) {
        return 'To be completed. (e.g. "All of the lawn mown...")';
      } else {
        return 'To be completed when defining rubric.'
      }
    };
    const definition = this._createDefinitionBuilder()
      .setName('My holistic rubric')
      .setDescription(``)
      .addDescriptionText(`This is a holistic rubric which means it comprise a series of statements corresponding to increasing levels of achievement.`)
      .addDescriptionText(` Typically holistic rubrics are arranged with rows represent the various levels of achievement,`)
      .addDescriptionText(`  but in ${constants.appTitle}, the various levels of achievement must be represented in columns.`)
      .setStatementBuilder(statementBuilder)
      .setColumnNames(columnNames)
      .setRowNames(rowNames)
      .setOptionHideCompletedAspects(false)
      .setOptionHideZeroScores(false)
      .setOptionHideColumnTotals(false)
      .build();
    this._completeDefinitionCreation(definition);
  };

  onNewAnalyticRubric = (event) => {
    analytics.event({
      category: analyticsDefinitions.categories.rubric,
      action: analyticsDefinitions.actions.create,
      label: 'analytic'
    });
    const columnNames = ['25%', '50%', '75%', '100%'];
    const rowNames = [
      'Criteria A', 'Criteria B', 'Criteria C', 'Criteria D'];
    const statementBuilder = (columnIndex, rowIndex) => {
      if (rowIndex === 0) {
        return 'Description for this option'
      } else if (rowIndex === 1) {
        if (columnIndex === 0) {
          return 'To be completed. (e.g. "Within 10 feet")';
        } else if (columnIndex === 1) {
          return 'To be completed. (e.g. "Within 5 feet")';
        } else if (columnIndex === 2) {
          return 'To be completed. (e.g. "Within 2 feet")';
        } else {
          return 'To be completed. (e.g. "Within 1 foot")';
        }
      } else if (rowIndex === 2) {
        if (columnIndex === 0) {
          return 'To be completed. (e.g. "30 feet")';
        } else if (columnIndex === 1) {
          return 'To be completed. (e.g. "50 feet")';
        } else if (columnIndex === 2) {
          return 'To be completed. (e.g. "70 feet")';
        } else {
          return 'To be completed. (e.g. "80 feet")';
        }
      } else if (rowIndex === 3) {
        if (columnIndex === 0) {
          return 'To be completed. (e.g. "3/5")';
        } else if (columnIndex === 1) {
          return 'To be completed. (e.g. "2/5")';
        } else if (columnIndex === 2) {
          return 'To be completed. (e.g. "4/5")';
        } else {
          return 'To be completed. (e.g. "5/5")';
        }
      } else {
        return 'To be completed when defining rubric.'
      }
    };
    const definition = this._createDefinitionBuilder()
      .setName('My analytic rubric')
      .setDescription(``)
      .addDescriptionText(`This is an analytic rubric so it splits out requirements into separate aspects where each aspect is defined in a separate row. `)
      .setStatementBuilder(statementBuilder)
      .setColumnNames(columnNames)
      .setRowNames(rowNames)
      .setOptionHideCompletedAspects(false)
      .setOptionHideZeroScores(false)
      .setOptionHideColumnTotals(false)
      .build();
    this._completeDefinitionCreation(definition);
  };

  onNewExampleRubric = (event) => {
    return this._onNewSinglePointPointRubric('My rubric (e.g. Cappuccino quality)');
  };

  onNewSinglePointPointRubric = (event) => {
    return this._onNewSinglePointPointRubric('My single point rubric');
  };

  _onNewSinglePointPointRubric = (name) => {
    analytics.event({
      category: analyticsDefinitions.categories.rubric,
      action: analyticsDefinitions.actions.create,
      label: 'single-point'
    });
    const columnNames = [I18N.Area];
    const rowNames = [
      'Area A (e.g. "Form")',
      'Area B (e.g. "Taste")'
    ];
    const statementBuilder = (columnIndex, rowIndex) => {
      if (rowIndex === 0) {
        return [
          `Statement A (e.g. Foam, milk, coffee ratio)`,
          `Statement B (e.g. Foam thickness)`
        ];
      } else if (rowIndex === 1) {
        return [
          `Statement A (e.g. Heat)`,
          `Statement B (e.g. Acidity`,
          `Statement C (e.g. Richness)`
        ];
      } else {
        return []
      }
    };
    const guidanceBuilder = (columnIndex, rowIndex, statement, statementIndex) => {
      // 'Poor, Weak, Good, Strong'
      if (rowIndex === 0) {
        if (statementIndex === 0) {
          return [{
            uuid: util.uuid(),
            scoreGuide: 'Strong',
            requirements: [{
              uuid: util.uuid(),
              text: 'The cappuccino comprises 1/3 foam, 1/3 milk and 1/3 coffee.'
            }]            
          }];
        } else if (statementIndex === 1) {

        }
      } else if (rowIndex === 1) {
        if (statementIndex === 0) {

        } else if (statementIndex === 1) {

        } else if (statementIndex === 2) {

        }
      } else {
        return []
      }
    }
    const definition = this._createDefinitionBuilder()
      .setName(name)
      .setDescription(``)
      .addDescriptionText(`This is your rubric to modify as you see fit.
      
      This rubric defines criteria by breaking down the subject into various 
      areas and statements. For soe statements, 
      `)
      // .addDescriptionText(`This is a single-point rubric and as such, it only has a single column defining`)
      // .addDescriptionText(` what it means to be proficient. `)
      // .addDescriptionText(` Sub-optimal and outstanding results are not specified.`)
      .setStatementBuilder(statementBuilder)
      .setGuidanceBuilder(guidanceBuilder)
      .setAspectHeaderName(I18N.Area)
      .setColumnNames(columnNames)
      .setRowNames(rowNames)
      .setOptionHideCompletedAspects(false)
      .setOptionHideZeroScores(false)
      .setOptionHideColumnTotals(false)
      .setOptionScoreMetaType(scoreMetaDefinitions.poorToStrongScoreMetaType)
      .build();
    this._completeDefinitionCreation(definition);
  };

  onNewLevelsRubric = (event) => {
    analytics.event({
      category: analyticsDefinitions.categories.rubric,
      action: analyticsDefinitions.actions.create,
      label: 'levels'
    });
    const columnNames = ['Learning', 'Competent', 'Advanced', 'Expert'];
    const rowNames = ['Competency A', 'Competency B', 'Competency C', 'Competency D'];
    const statementBuilder = (columnIndex, rowIndex) => {
      const columnName = columnNames[columnIndex];
      const rowName = rowNames[rowIndex];
      const statement = `"${columnName}" for "${rowName}" is defined as...`;
      return statement;
    };
    const definition = this._createDefinitionBuilder()
      .setName('My levels rubric')
      .addDescriptionText(`This rubric is structured to allow the assessment of a person against one or more competency levels. `)
      .addDescriptionText(` One example could be a sport such as martial arts where each level might be a different colored belt. `)
      .addDescriptionText(` Another example could be a corporation's job levels.`)
      .setStatementBuilder(statementBuilder)
      .setColumnNames(columnNames)
      .setRowNames(rowNames)
      .setOptionHideCompletedAspects(false)
      .setOptionHideZeroScores(false)
      .setOptionHideColumnTotals(false)
      .setOptionScoreMetaType(scoreMetaDefinitions.levelsScoreMetaType)
      .build();
    this._completeDefinitionCreation(definition);
  };

  _getOption = (options, field, defaultValue) => {
    if (options) {
      const value = options[field];
      if (value) {
        return value;
      }
    }
    return defaultValue;
  };

  onNewCompetitiveAnalysis = (options) => {
  };

  onNewDecisionRubric = (options?: DecisionOptions) => {
    const title = this._getOption(options, 'title', 'My decision rubric');
    const aspects = this._getOption(options, 'aspects', undefined);
    const aspectsIndexesToConsiderations = this._getOption(options, 'aspectsIndexesToConsiderations', undefined);
    const status = this._getOption(options, 'status', '');
    const impact = this._getOption(options, 'impact', '');
    const driver = this._getOption(options, 'driver', '');
    const approver = this._getOption(options, 'approver', '');
    const contributors = this._getOption(options, 'contributors', '');
    const informed = this._getOption(options, 'informed', '');
    const dueDateTimestamp = this._getOption(options, 'dueDateTimestamp', undefined);
    const outcome = this._getOption(options, 'outcome', '');
    const builtByWizard = this._getOption(options, 'builtByWizard', false);
    const assessmentNames = this._getOption(options, 'assessmentNames', ['Option A', 'Option B', 'Option C']);
    analytics.event({
      category: analyticsDefinitions.categories.rubric,
      action: analyticsDefinitions.actions.create,
      label: 'decision'
    });
    const columnNames = ['Considerations'];
    let rowNames;
    let statementBuilder;
    if (aspects && aspects.length) {
      rowNames = aspects;
      statementBuilder = (columnIndex, rowIndex) => {
        let considerations;
        if (aspectsIndexesToConsiderations) {
          considerations = aspectsIndexesToConsiderations[rowIndex];
        }
        if (!considerations || !considerations.length) {
          const aspect = aspects[rowIndex]
          considerations = [`${aspect} consideration A`.trim(), `${aspect} consideration B`.trim()];
        }
        return considerations;
      };
    } else {
      rowNames = [
        'Area A (e.g. "Cost per month")',
        'Area B (e.g. "Quality")',
        'Area C (e.g. "External rating")'
      ];
      statementBuilder = (columnIndex, rowIndex) => {
        if (rowIndex === 0) {
          return ['Initial cost', 'Ongoing cost'];
        } else if (rowIndex === 1) {
          return ['Warranty period'];
        } else if (rowIndex === 2) {
          return ['Magazine ratings', 'Friend ratings'];
        }
      };
    }
    const renderedStatus = status ? status : '';
    const renderedImpact = impact ? impact : '';
    const renderedDriver = driver ? driver : '';
    const renderedApprover = approver ? approver : '';
    const renderedContributors = contributors ? contributors : '';
    const renderedInformed = informed ? informed : '';
    const renderedDueDate = dueDateTimestamp ? util.formatDate(dueDateTimestamp) : 'when does this decision need to be made by?';
    const renderedOutcome = outcome ? outcome : '';
    const genericAspectsNotes = `The left column of the options table identifies important aspects relating to the decision. `;
    const carNotes = `To illustrate how this rubric is intended to be used, it identifies aspects relating to choosing a new car. The aspects are based on four pillars;- economics, reliability, comfort and cosmetics.`;
    const aspectAndConsiderationsNotes = builtByWizard ? genericAspectsNotes : `${genericAspectsNotes} ${carNotes}`;
    const aspectWeightsNote = `Note that different aspects can be assigned different weights to ensure the overall score for each choice factors in the relevant importance of each aspect.`;
    const instructionsIntro = `## Instructions
  
These instructions and the format of the decision is based on the [Atlassian DACI](https://www.atlassian.com/team-playbook/plays/daci) model.

This rubric will help a robust decision to be made in a timely manner. It will need to be customised for the decision you need to make. 

${aspectAndConsiderationsNotes}
    
${aspectWeightsNote} 
      
The possible options are identified as separate assessments against the rubric. The average score of each option should then indicate which is the overall best option.`;
    const mainInstructions = `### Creating the decision rubric

1. Edit the description with the information relevant to your decision.
1. Edit the description to remove these instructions (when you no longer need them).
1. Add the criteria relevant to your decision.
1. Remove the example template criteria.
1. Change the scoring scheme of the rubric if you wish.
1. Change the "editability" of the rubric to "immutable" when it is ready to be assessed.
1. Change the "discoverability" of the rubric from "public" to "link" or "private".`;
    const optionsInstructions = `### Assessing options

1. Create a new assessment corresponding to each possible choice for the decision. 
1. Complete the assessment for each choice.
1. Compare the assessments to make the best choice for the decision.`;
    const infoSection = `## Summary

 |    |    |
| ------: | ----------- |
| **Status**: | ${renderedStatus} |
| **Impact**: | ${renderedImpact} |
| **Driver**: | ${renderedDriver} |
| **Approver**: | ${renderedApprover} |
| **Contributors**: | ${renderedContributors} |
| **Informed**: | ${renderedInformed} |
| **Due Date**: | ${renderedDueDate} |
| **Outcome**: | ${renderedOutcome} |
    
## Background Information
    
It's usually helpful to provide a background to help set out the context of the decision. Replace this with information relevant to this decision.
    
## Supporting Data
    
If relevant data is available, it should be included in the decision making process. You should also [link to the data](https://www.locationofdata.com) if it is accessible elsewhere.

## Options Considered

Create an assessment for each option to be considered. Each option will appear as a column in the table when it is selected as being visible. `;
    const description = `${instructionsIntro}

${mainInstructions}

${optionsInstructions}

${infoSection}`;
    const definition = this._createDefinitionBuilder()
      .setName(title)
      .setAspectHeaderName(`Aspect`)
      .addDescriptionText(description)
      .setDiscoverability(discoverabilityDefinitions.linkDiscoverabilityType)
      .setStatementBuilder(statementBuilder)
      .setColumnNames(columnNames)
      .setRowNames(rowNames)
      .setOptionScoreMetaType(scoreMetaDefinitions.poorToStrongScoreMetaType)
      .setOptionHideCompletedAspects(false)
      .setOptionHideZeroScores(false)
      .setOptionHideColumnTotals(false)
      .build();
    let onDefinitionCreated: undefined | ((definition: Definition) => Promise<void>) = undefined;
    if (assessmentNames && assessmentNames.length) {
      onDefinitionCreated = (definition: Definition): Promise<void> => {
        const assessmentPromises: Promise<Assessment>[] = [];
        for (const assessmentName of assessmentNames) {
          // const assessmentPromise = rubricDAO.createAssessment(definition, assessmentName);
          const builderActions = (assessmentBuilder: RubricAssessmentBuilder): RubricAssessmentBuilder => {
            assessmentBuilder.setName(assessmentName)
            return assessmentBuilder;
          }
          const assessmentPromise = assessmentUtil.createAndStoreAssessment(firestoreAssessmentDAO, definition, builderActions);
          assessmentPromises.push(assessmentPromise);
        }
        return Promise.all(assessmentPromises)
          .then((assessments: Assessment[]) => {
            return undefined;
          });
      };
    }
    this._completeDefinitionCreation(definition, onDefinitionCreated);
  };

  onNewProgressRubric = (event) => {
    analytics.event({
      category: analyticsDefinitions.categories.rubric,
      action: analyticsDefinitions.actions.create,
      label: 'progress'
    });
    const columnNames = ['Progress'];
    const rowNames = [
      'Task A (e.g. "Mow lawn")',
      'Task B (e.g. "Rake leaves")',
      'Task C (e.g. "Clean windows")'
    ];
    const statementBuilder = (columnIndex, rowIndex) => {
      return ' ';
    };
    const definition = this._createDefinitionBuilder()
      .setName('My progress rubric')
      .addDescriptionText(`A progress rubric identifies a list of tasks to be done. `)
      .addDescriptionText(` An example might be a list of jobs to be done. `)
      .addDescriptionText(` Each row of the rubric represents an item that needs doing.`)
      .addDescriptionTextBreak()
      .addDescriptionText(`Note the option selected to hide rows (tasks) when they are complete.`)
      .setStatementBuilder(statementBuilder)
      .setAspectHeaderName('Task')
      .setColumnNames(columnNames)
      .setRowNames(rowNames)
      .setOptionHideCompletedAspects(true)
      .setOptionHideZeroScores(false)
      .setOptionHideColumnTotals(true)
      .build();
    this._completeDefinitionCreation(definition);
  };

}

export default new DefinitionCreator();
