import React, { PureComponent } from 'react';
import adg from '../../../../commonbase/adg';
import rubricDAO from '../../../../backend/data/RubricDAO';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import util from '../../../../shared/util/Util';
import widgetUtil from '../../widget/WidgetUtil';
import ChartModel from './ChartModel';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
import AssessmentSnapshot from '../../../../shared/model/rubric/score/AssessmentSnapshot';
import ScoreMeta from '../../../../shared/model/rubric/score/ScoreMeta';
import AssessmentSnapshotsSeries from '../../../../shared/model/rubric/score/AssessmentSnapshotsSeries';
import promiseUtil from '../../../../commonbase/util/promiseUtil';

interface Base {
  chartModel: ChartModel
  width: number
  height: number
  scoreMeta: ScoreMeta
}

interface Props extends Base {
  aspectGrouping: any
}

interface State extends Base {
  definition: Definition
  assessments: Assessment[]
  showAxis: boolean
  chartWidth: number
  chartAreaHeight: number
  chartBottomY: number
  xLeftIndent: number
  xRightIndent: number
  yTopIndent: number
  yBottomIndent: number
  renderContext: any
  minTime: number
  maxTime: number
  cachedAssessmentSnapshotsSeries: undefined | AssessmentSnapshotsSeries[]
}

export default class HistoryChart extends PureComponent<Props, State> {

  axisColor = '#777';

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    const newState = this.buildStateFromProps(props);
    this.setState(newState);
  }

  componentDidUpdate() {
    widgetUtil.scheduleTooltipRebuild();
  }

  buildStateFromProps = (props: Props): State => {
    const xLeftIndent = 20;
    const xRightIndent = 10;
    const yTopIndent = 10;
    const yBottomIndent = 40;
    const chartAreaHeight = props.height - yTopIndent - yBottomIndent;
    const chartWidth = props.width - xLeftIndent - xRightIndent;
    const chartBottomY = yTopIndent + chartAreaHeight;
    const state: State = {
      chartModel: props.chartModel,
      definition: props.chartModel.definition,
      assessments: props.chartModel.assessments,
      showAxis: true,
      width: props.width,
      height: props.height,
      scoreMeta: props.scoreMeta,
      chartWidth: chartWidth,
      chartAreaHeight: chartAreaHeight,
      chartBottomY: chartBottomY,
      xLeftIndent: xLeftIndent,
      xRightIndent: xRightIndent,
      yTopIndent: yTopIndent,
      yBottomIndent: yBottomIndent,
      renderContext: undefined,
      minTime: 0,
      maxTime: 0,
      cachedAssessmentSnapshotsSeries: undefined
    }
    const renderContext = this.buildRenderContext(state);
    state.renderContext = renderContext;
    this.retrieveSnapshots(state.definition, state.assessments);
    return state;
  }

  buildRenderContext = (state) => {
    const xLeftIndent = state.xLeftIndent;
    const chartBottomY = state.chartBottomY;
    const maxPossibleScorePercent = 100;
    const maxPossibleScore = state.scoreMeta.percentToScore(maxPossibleScorePercent);
    if (!maxPossibleScore) {
      // debugger;
    }
    const factorToX = (factor) => {
      return xLeftIndent + Math.round(factor * state.chartWidth);
    };
    const factorToY = (factor) => {
      return chartBottomY - Math.round(factor * state.chartAreaHeight);
    };
    const scoreToY = (score) => {
      const factor = score / maxPossibleScore;
      return chartBottomY - Math.round(factor * state.chartAreaHeight);
    };
    const timeToX = (time, state) => {
      const minTime = state.minTime;
      const maxTime = state.maxTime;
      const factor = minTime === maxTime ? 0.5 : (time - minTime) / (maxTime - minTime);
      return xLeftIndent + Math.round(factor * state.chartWidth);
    };
    const xLeft = factorToX(0);
    const xMiddle = factorToX(0.5);
    const xRight = factorToX(1);
    const yBottom = factorToY(0);
    const yMiddle = factorToY(0.5);
    const yTop = factorToY(1);
    const context = {
      xLeft: xLeft,
      xMiddle: xMiddle,
      xRight: xRight,
      yTop: yTop,
      yMiddle: yMiddle,
      yBottom: yBottom,
      valueLabelFontSize: 12,
      valueAxisLabelFontSize: 14,
      valueTickFontSize: 14,
      timeTickFontSize: 14,
      aspectBarFontSize: 14,
      fontFamily: 'Verdana',
      startXsToEffortPersonYOffsets: {},
      scoreToY: scoreToY,
      timeToX: timeToX
    };
    return context;
  };

  retrieveSnapshots = (definition: Definition, assessments: Assessment[]) => {
    const currentTime = new Date().getTime();
    const promises: Promise<AssessmentSnapshot[]>[] = [];
    for (const assessment of assessments) {
      const promise = this.retrieveAssessmentSnapshots(definition, assessment, currentTime);
      promises.push(promise);
    }
    Promise.all(promises).then((results: AssessmentSnapshot[][]) => {
      const cachedAssessmentSnapshotsSeries: AssessmentSnapshotsSeries[] = [];
      let minTime = currentTime;
      const maxTime = currentTime;
      for (let i = 0; i < results.length; i++) {
        const assessmentSnapshots = results[i];
        const firstSnapshot = assessmentSnapshots[0];
        if (firstSnapshot.updateTimestamp && firstSnapshot.updateTimestamp < minTime) {
          minTime = firstSnapshot.updateTimestamp;
        }
        const assessmentSnapshotsSeries: AssessmentSnapshotsSeries = {
          assessmentUuid: firstSnapshot.uuid,
          assessmentSnapshots: assessmentSnapshots
        }
        // assessmentUuidsToSnapshots[firstSnapshot.uuid] = assessmentSnapshots;
        cachedAssessmentSnapshotsSeries.push(assessmentSnapshotsSeries);
      }
      this.setState({
        minTime: minTime,
        maxTime: maxTime,
        cachedAssessmentSnapshotsSeries: cachedAssessmentSnapshotsSeries
      });
    });
  };

  retrieveAssessmentSnapshots = (definition: Definition, assessment: Assessment, currentTime: number): Promise<AssessmentSnapshot[]> => {
    // First try to retrieve the cached assessment snapshots...
    if (this.state && this.state.cachedAssessmentSnapshotsSeries) {
      for (const cachedAssessmentSnapshots of this.state.cachedAssessmentSnapshotsSeries) {
        if (cachedAssessmentSnapshots.assessmentUuid === assessment.uuid) {
          return promiseUtil.promiseReturning(cachedAssessmentSnapshots.assessmentSnapshots);
        }
      }
    }

    const assessmentUuid = assessment.uuid;
    return rubricDAO.getAssessmentSnapshots(assessmentUuid).then((assessmentSnapshots: AssessmentSnapshot[]) => {
      const currentAssessmentSnapshot = rubricUtil.buildAssessmentSnapshot(
        definition, assessment, currentTime);
      if (assessmentSnapshots && assessmentSnapshots.length) {
        assessmentSnapshots.push(currentAssessmentSnapshot);
        return assessmentSnapshots;
      } else {
        return [currentAssessmentSnapshot];
      }
    });
  }

  renderHistoryForSnapshots = (seriesCount: number, assessmentSnapshots: AssessmentSnapshot[], seriesColor: string) => {
    let lastCoords: undefined | any = undefined;
    const renderedGraphics = assessmentSnapshots.map((assessmentSnapshot, index) => {
      let graphic: undefined | any = undefined;
      const averageScorePercent = assessmentSnapshot.snapshotData.averageScore;
      if (isNaN(averageScorePercent)) {
        // Corrupt data so skip.
        return null;
      }
      const averageScore = this.state.scoreMeta.percentToScore(averageScorePercent);
      const snapshotTime = assessmentSnapshot.updateTimestamp;
      const snapshotDate = new Date(snapshotTime);
      const x = this.state.renderContext.timeToX(snapshotTime, this.state);
      const y = this.state.renderContext.scoreToY(averageScore);
      const tooltipSeriesNamePrefix = seriesCount  > 1 ? assessmentSnapshot.name + ': ' : '';
      const tooltip = 
        tooltipSeriesNamePrefix +
        util.formatDate(snapshotDate) + ': ' + Math.round(averageScore);
      const point = (
        <circle
          key={'t-' + snapshotTime}
          r={5}
          stroke="#fff"
          fill={seriesColor}
          strokeWidth="0"
          cx={x}
          cy={y}
          data-tip={tooltip}
        />
      );
      if (lastCoords) {
        const line = (
          <line
            x1={lastCoords.x}
            y1={lastCoords.y}
            x2={x}
            y2={y}
            stroke={seriesColor}
            strokeWidth={2}
          />
        );
        graphic = (
          <g key={'t-' + snapshotTime}>
            {line}
            {point}
          </g>
        );
      } else {
        graphic = point;
      }
      lastCoords = {x: x, y: y};
      return graphic;
    });
    return (
      <g>
        {renderedGraphics}
      </g>
    );
  };

  renderHistory = () => {
    const cachedAssessmentSnapshotsSeries = this.state.cachedAssessmentSnapshotsSeries;
    if (cachedAssessmentSnapshotsSeries) {
      return cachedAssessmentSnapshotsSeries.map((series: AssessmentSnapshotsSeries, index) => {
        const seriesColor: string = adg.getAnyPrimaryColorByIndex(index);
        const seriesCount = cachedAssessmentSnapshotsSeries.length;
        return (
          <g key={'series-' + index}>
            {this.renderHistoryForSnapshots(seriesCount, series.assessmentSnapshots, seriesColor)}
          </g>
        );
      });
    } else {
      return null;
    }
  }

  renderAxis = (isXAxis: boolean) => {
    if (this.state.showAxis) {
      const renderContext = this.state.renderContext;
      const coords = isXAxis ?
        {
          x1: renderContext.xLeft,
          y1: renderContext.yBottom,
          x2: renderContext.xRight,
          y2: renderContext.yBottom
        } :
        {
          x1: renderContext.xLeft,
          y1: renderContext.yTop,
          x2: renderContext.xLeft,
          y2: renderContext.yBottom
        };
      return (
        <line {...coords} stroke={this.axisColor} strokeWidth={1} />
      );
    } else {
      return null;
    }
  };

  getSeriesFirstDateTime = (series: AssessmentSnapshotsSeries): number => {
    if (series.assessmentSnapshots && series.assessmentSnapshots.length) {
      const assessmentSnapshot: AssessmentSnapshot = series.assessmentSnapshots[0];
      return assessmentSnapshot.updateTimestamp;
    }
    return 0;
  }

  getSeriesLastDateTime = (series: AssessmentSnapshotsSeries): number => {
    if (series.assessmentSnapshots && series.assessmentSnapshots.length) {
      const assessmentSnapshot: AssessmentSnapshot = series.assessmentSnapshots[series.assessmentSnapshots.length - 1];
      return assessmentSnapshot.updateTimestamp;
    }
    return 0;
  }

  getFirstDateTime = (): number => {
    let firstDateTime = new Date().getTime();
    if (this.state.cachedAssessmentSnapshotsSeries) {
      for (const series of this.state.cachedAssessmentSnapshotsSeries) {
        const seriesDateTime = this.getSeriesFirstDateTime(series);
        firstDateTime = Math.min(firstDateTime, seriesDateTime);
      }
    }
    return firstDateTime;
  }

  getLastDateTime = (): number => {
    let lastDateTime = 0;
    if (this.state.cachedAssessmentSnapshotsSeries) {
      for (const series of this.state.cachedAssessmentSnapshotsSeries) {
        const seriesDateTime = this.getSeriesLastDateTime(series);
        lastDateTime = Math.max(lastDateTime, seriesDateTime);
      }
    }
    return lastDateTime;
  }

  renderDateMarker = (x: number, timestamp: number, textAnchor: string, renderContext: any) => {
    const markerY = renderContext.yBottom + 20;
    const formattedTime = new Date(timestamp).toLocaleDateString();
    return (
      <text
        stroke={this.axisColor}
        fill={this.axisColor}
        strokeWidth="1"
        alignmentBaseline="middle"
        x={x}
        y={markerY}
        textAnchor={textAnchor}
        fontFamily={renderContext.fontFamily}
        fontSize={renderContext.aspectBarFontSize}
      >
        {formattedTime}
      </text>
    );
  }

  renderDateMarkers = () => {
    const cachedAssessmentSnapshotsSeries = this.state.cachedAssessmentSnapshotsSeries;
    if (cachedAssessmentSnapshotsSeries) {
      const renderContext = this.state.renderContext;
      const firstDateTime = this.getFirstDateTime();
      const lastDateTime = this.getLastDateTime();
      if (firstDateTime < lastDateTime) {
        const renderedFirstDateMarker = this.renderDateMarker(renderContext.xLeft, firstDateTime, 'start', renderContext);
        const renderedLastDateMarker = this.renderDateMarker(renderContext.xRight, lastDateTime, 'end', renderContext);
        const renderedDateMarkers: any[] = [renderedFirstDateMarker, renderedLastDateMarker];
        return (
          <g>
            {renderedDateMarkers}
          </g>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  render() {
    return (
      <svg width={this.state.width} height={this.state.height}>
        {this.renderAxis(true)}
        {this.renderAxis(false)}
        {this.renderDateMarkers()}
        {this.renderHistory()}
      </svg>
    )
  }

}
