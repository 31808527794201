
import React, { PureComponent } from "react";

type Props = {
  text: string,
  additionalClassNames?: string,
  marginLeft?: string,
  marginRight?: string,
  paddingTop?: string,
  paddingBottom?: string,
  display?: string
  color?: string
}

type State = {
  text: string,
  display?: string
}

export default class Label extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props): State => {
    return {
      text: props.text,
      display: props.display
    };
  };

  render() {
    const labelStyle: any = {};
    if (this.props.paddingTop) {
      labelStyle.paddingTop = this.props.paddingTop;
    }
    if (this.props.paddingBottom) {
      labelStyle.paddingBottom = this.props.paddingBottom;
    }
    if (this.state.display) {
      labelStyle.display = this.state.display;
    }
    if (this.props.marginLeft) {
      labelStyle.marginLeft = this.props.marginLeft;
    }
    if (this.props.marginRight) {
      labelStyle.marginRight = this.props.marginRight;
    }
    if (this.props.color) {
      labelStyle.color = this.props.color;
    }
    let className = 'label';
    if (this.props.additionalClassNames) {
      className += ` ${this.props.additionalClassNames}`;
    } else {
      className += ' normal-label';
    }
    return (
      <div className={className} style={labelStyle}>
        <span>
          {this.state.text}
        </span>
      </div>
    );
  }

}
