
const constants = {};

constants.company = 'Roobrick';
constants.appDomain = 'www.roobrick.org';
constants.appBaseUrl = 'https://' + constants.appDomain;
constants.appTitle = 'Roobrick';
constants.appByline = 'Assess all the things';
constants.appBriefDescription = `${constants.appTitle} improves your effectiveness at analyzing complex problems such as decision making and evaluating qualitative data.`;

constants.logoPath256x256 = `/img/roobrick-logo-v4-256x256.png`;

constants.confluenceAppKey = 'roobrick-confluence';
constants.confluenceAppTitle = `${constants.appTitle} assessments for Confluence`;
constants.confluenceAppDescription = `This app integrates Confluence with ${constants.appTitle}, allowing rubrics to appear within confluence pages.`;
constants.confluenceDescriptorUrl = constants.appBaseUrl + '/confluence-connect-descriptor';
constants.anyRubricMacroModuleKey = `any-rubric`;
constants.okrWritingGuideMacroModuleKey = `OKR-Writing-Guide`;
constants.confluenceMacroViewParam = `confluence-macro-view`;
constants.confluenceMacroViewUrlBase = `/app/${constants.confluenceMacroViewParam}`;
constants.confluenceMacroEditorParam = `confluence-macro-editor`;
constants.confluenceMacroEditorUrlBase = `/app/${constants.confluenceMacroEditorParam}`;

constants.popupAssessmentMacroModuleKey = `popup-assessment`;
constants.popupAssessmentScoreMacroWidth = 20;
constants.popupAssessmentScoreMacroHeight = 20;
constants.confluencePopupAssessmentViewParam = `confluence-popup-assessment-view`;
constants.confluencePopupAssessmentMacroViewUrlBase = `/app/${constants.confluencePopupAssessmentViewParam}`;
constants.confluencePopupAssessmentEditorParam = `confluence-popup-assessment-editor`;
constants.confluencePopupAssessmentMacroEditorUrlBase = `/app/${constants.confluencePopupAssessmentEditorParam}`;

constants.confluenceAssessmentDialogModuleKey = `confluence-assessment-dialog`;
constants.confluenceAssessmentDialogPanelParam = `confluence-assessment-dialog-panel`;
constants.confluenceAssessmentDialogPanelUrlBase = `/app/${constants.confluenceAssessmentDialogPanelParam}`;

constants.jiraAppKey = 'roobrick-jira';
constants.jiraAppTitle = `${constants.appTitle} assessments for Jira`;
constants.jiraAppDescription = `This app integrates Jira with ${constants.appTitle}.`;
constants.jiraDescriptorUrl = constants.appBaseUrl + '/jira-connect-descriptor';
constants.jiraContentModuleKey = `content-module`;
constants.jiraContentModuleLabel = `${constants.appTitle} issue report assessment`;
constants.jiraContentModuleParam = `jira-content-panel`;
constants.jiraContentModulePanelUrl = `/app/${constants.jiraContentModuleParam}`;
constants.jiraGlanceModuleKey = `issue-glance`;
constants.jiraGlanceModuleLabel = `Issue report assessment`;
constants.jiraGlanceParam = `jira-glance-panel`;
constants.jiraGlancePanelUrl = `/app/${constants.jiraGlanceParam}`;

constants.dmUserId = '46WxPElWQuRJAXUqcXWYiy7bODj1';
constants.dmEamilAddress = 'dugald@iconic.com.au';

constants.jsonFormatType = 'json';
constants.htmlFormatType = 'html';

constants.planDefinitions = {
  liteType: 'lite' // see also PlanDefinitions.js
};

constants.definitionsCollectionKey = 'definitions';

constants.jiraIssueViewRightSidebarWidth = 250;

module.exports = constants;
