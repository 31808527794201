import React, { PureComponent } from 'react';
// import { akGridSizeUnitless } from '@atlaskit/util-shared-styles';

export default class ContentWrapper extends PureComponent {

  render() {
    // This wrapper is now replaced by the div.main-content-panel style in shared.css
    return (
      <>
        {this.props.children}
      </>
    );
    // const style = {
    //   margin: `${akGridSizeUnitless * 4}px ${akGridSizeUnitless * 8}px`,
    //   paddingBottom: `${akGridSizeUnitless * 3}px`
    // };
    // return (
    //   <div
    //     style={style}
    //   >
    //     {this.props.children}
    //   </div>
    // );
  }

}