import analytics from '../../shared/model/analytics/Analytics';
import firebaseApp from '../firebase/FirebaseApp';
import dataStats from './DataStats';
import entityTypeDefinitions from "../../shared/model/rubric/EntityTypeDefinitions";
import rubricDAO from './RubricDAO';
import util from '../../shared/util/Util';

export default class NotificationFirestorePersistence {

  analyticsEntityName = 'notification';

  constructor() {
    this.notificationsCollectionKey = 'notifications';
  }

  saveNotification = (notification) => {
    analytics.databaseWrite(this.analyticsEntityName);
    const objectToSave = {
      uuid: notification.uuid,
      fromUserId: notification.fromUserId,
      targetUserEmail: notification.targetUserEmail,
      entityUuid: notification.entityUuid,
      entityType: notification.entityType,
      shareState: notification.shareState,
      title: notification.title,
      message: notification.message,
      //expiryTimestamp: notification.expiryTimestamp
    };
    if (notification.definitionUuid) {
      objectToSave.definitionUuid = notification.definitionUuid;
    }
    if (notification.definitionName) {
      objectToSave.definitionName = notification.definitionName;
    }
    if (notification.assessmentUuid) {
      objectToSave.assessmentUuid = notification.assessmentUuid;
    }
    if (notification.assessmentName) {
      objectToSave.assessmentName = notification.assessmentName;
    }
    if (notification.expiryTimestamp) {
      objectToSave.expiryTimestamp = notification.expiryTimestamp;
    }

    dataStats.logFirestoreReadWrite();
    return firebaseApp
      .getFirestore()
      .collection(this.notificationsCollectionKey)
      .doc(notification.uuid)
      .set(objectToSave)
      .then(() => {
        // console.log("Notification successfully written!");
      })
      .catch((error) => {
        console.error("Error writing notification: ", error);
        // debugger;
      });
  };

  getSharedAssessments = (user, definitionUuid, shareState) => {
    const userEmail = user.getEmail();
    let query = firebaseApp
      .getFirestore()
      .collection(this.notificationsCollectionKey)
      .where('targetUserEmail', '==', userEmail)
      .where('entityType', '==', entityTypeDefinitions.assessmentEntityType)
      .where('definitionUuid', '==', definitionUuid);
    if (shareState) {
      query = query.where('shareState', '==', shareState);
    }
    return query
      .get()
      .then((querySnapshot) => {
        const assessmentPromises = [];
        querySnapshot.forEach(function(doc) {
          const notification = doc.data();
          const promise = rubricDAO.getAssessmentByUuid(notification.assessmentUuid);
          assessmentPromises.push(promise);
        });
        dataStats.logFirestoreReads(assessmentPromises.length);
        analytics.databaseRead(this.analyticsEntityName, assessmentPromises.length);
        return Promise.all(assessmentPromises);
      });
  };

  getCurrentUserNotifications = (user) => {
    // TODO: Should add a property 'involvesUserId' which is the union of 'fromUserId' and 'targetUserEmail'.
    return Promise.all([
      this.getNotificationsFromCurrentUser(user),
      this.getNotificationsToCurrentUser(user)
    ]).then((results) => {
      const notificationsA = results[0];
      const notificationsB = results[1];
      const notifications = util.mergeObjectArrays(notificationsA, notificationsB, 'uuid');
      analytics.databaseRead(this.analyticsEntityName, notifications.length);
      return notifications;
    });
  };

  getNotificationsFromCurrentUser = (user) => {
    const userId = user.getId();
    return firebaseApp
      .getFirestore()
      .collection(this.notificationsCollectionKey)
      .where('fromUserId', '==', userId)
      .get()
      .then((querySnapshot) => {
        return this._querySnapshotToNotifications(querySnapshot);
      });
  };

  getNotificationsToCurrentUser = (user) => {
    const userEmail = user.getEmail();
    return firebaseApp
      .getFirestore()
      .collection(this.notificationsCollectionKey)
      .where('targetUserEmail', '==', userEmail)
      .get()
      .then((querySnapshot) => {
        return this._querySnapshotToNotifications(querySnapshot);
      });
  };

  deleteAssessmentNotifications = (assessmentUuid, definitionUuid) => {
    dataStats.logFirestoreDelete();
    analytics.databaseDelete(this.analyticsEntityName);
    return firebaseApp
      .getFirestore()
      .collection(this.notificationsCollectionKey)
      .where('entityType', '==', entityTypeDefinitions.assessmentEntityType)
      .where('definitionUuid', '==', definitionUuid)
      .where('assessmentUuid', '==', assessmentUuid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  };

  searchNotificationsByTypeAndTitle = (entityType, title) => {
    return firebaseApp
      .getFirestore()
      .collection(this.notificationsCollectionKey)
      .where('entityType', '==', entityType)
      .where('title', '==', title)
      .get()
      .then((querySnapshot) => {
        return this._querySnapshotToNotifications(querySnapshot);
      });
  };

  doesNotificationExist = (fromUserId, targetUserEmail, entityType, entityUuid) => {
    return firebaseApp
      .getFirestore()
      .collection(this.notificationsCollectionKey)
      .where('fromUserId', '==', fromUserId)
      .where('targetUserEmail', '==', targetUserEmail)
      .where('entityType', '==', entityType)
      .where('entityUuid', '==', entityUuid)
      .get()
      .then((querySnapshot) => {
        const notifications = this._querySnapshotToNotifications(querySnapshot);
        return notifications && notifications.length;
      });
  };

  _querySnapshotToNotifications = (querySnapshot) => {
    const notifications = [];
    querySnapshot.forEach(function(doc) {
      const notification = doc.data();
      notifications.push(notification);
    });
    dataStats.logFirestoreReads(notifications.length);
    analytics.databaseRead(this.analyticsEntityName, notifications.length);
    return notifications;
  };

}
