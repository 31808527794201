import React, { PureComponent } from 'react';
import actions from '../../../shared/actions/Actions';
import assessmentUtil from '../../../shared/model/rubric/score/AssessmentUtil';
import adg from '../../../commonbase/adg';
import analytics, {analyticsDefinitions} from "../../../shared/model/analytics/Analytics";
import browserType from '../../../shared/model/browser/BrowserType';
import { AddMomentTextIcon, AddMomentTextCircleIcon, CloseCellIcon, InnapplicableIcon } from '../icon/NamedIcons';
import SelectMomentDialog from '../moment/SelectMomentDialog';
import constants from '../../../shared/model/Constants';
import Expand from '../widget/Expand';
import featureFlags from '../../../shared/model/feature/FeatureFlags';
import firestoreAssessmentDAO from '../../../backend/data/FirestoreAssessmentDAO';
import guidanceRenderUtil from './guidance/GuidanceRenderUtil';
import IconButton from '../widget/IconButton';
import Label from '../widget/Label';
import momentGraph from '../moment/MomentGraph';
import permissionUtil from '../../../shared/model/auth/PermissionUtil';
import ResizingTextArea from '../widget/ResizingTextArea';
import rubricRenderingUtil from './RubricRenderingUtil';
import rubricUtil from '../../../shared/model/rubric/RubricUtil';
import ScoreInput from './score/ScoreInput';
import StatementAnnotation from './StatementAnnotation';
import StatementScore from './score/StatementScore';
import scoreUtil from '../../../shared/model/rubric/score/ScoreUtil';
import scrollToComponent from "react-scroll-to-component";
import Tooltip from "@atlaskit/tooltip";
import widgetUtil from '../widget/WidgetUtil';
import Definition from '../../../shared/model/rubric/definition/Definition';
import ScoreMeta from '../../../shared/model/rubric/score/ScoreMeta';
import Assessment from '../../../shared/model/rubric/score/Assessment';
import AssessmentDAI from '../../../backend/data/AssessmentDAI';
import Statement from '../../../shared/model/rubric/definition/Statement';
import ToolbarLeft from '../widget/toolbar/ToolbarLeft';
import ToolbarJustify from '../widget/toolbar/ToolbarJustify';
import ToolbarItem from '../widget/toolbar/ToolbarItem';

const doubleCellLRPadding = 20;

interface Common {
  cell: any
  columnIndex: number
  definition: Definition
  scoreMeta: ScoreMeta
  hideCompletedAspects?: boolean
  hideZeroScores?: boolean
  hideColumnTotals?: boolean
  onlyShowStatementWithUuid: boolean
  columnVisibilities: any
  visibleLabels?: string[]
  assessments: Assessment[]
  readableAssessments: Assessment[]
  editableAssessments: Assessment[]
  assessmentComparisonActive: boolean
  assessmentHighlightThresholdPercent: number
  statementUuidsToAssessmentStats: any
  canViewOrCreateAssessments?: boolean
}

interface Props extends Common {
  assessmentDAO: AssessmentDAI
  multipleAssessmentsVisible: boolean
  isColumnVisible: (columnIndex: number) => boolean
  getGuidanceVisibility: (assessment: Assessment) => boolean
  onCellAssessmentChange: () => void
  onGuidanceExpanded: (statement: Statement) => void
  onGuidanceCollapsed: (statement: Statement) => void
}

interface State extends Common {
  lastScoreInputTimestamp?: number
  enteredCellUuid?: string
  focusedCellUuid?: string
  lastClickedStatementUuid?: string
  momentImportContext?: any
}

export default class RubricCell extends PureComponent<Props, State> {

  statementUuidsToGuidanceVisibilities = {};
  mounted = false;
  hideCompletedRowDelayMillis = 3000;
  uuidsToRefs: any = {};
  columnStyles: any = undefined;
  cellWidthSizerElement?: any = undefined;

  constructor(props) {
    super(props);
    // this.debouncedUpdater = util.debounce(this._scheduleUpdate, 1000, false);
    this.state = this.buildStateFromProps(props);
    // this.state.statementUuidsToGuidanceVisibilities = {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.columnStyles = undefined;
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      cell: props.cell,
      columnIndex: props.columnIndex,
      definition: props.definition,
      scoreMeta: props.scoreMeta,
      hideCompletedAspects: props.hideCompletedAspects,
      hideZeroScores: props.hideZeroScores,
      hideColumnTotals: props.hideColumnTotals,
      onlyShowStatementWithUuid: props.onlyShowStatementWithUuid,
      columnVisibilities: props.columnVisibilities,
      visibleLabels: props.visibleLabels,
      // columnWidth: props.columnWidth,
      assessments: props.assessments,
      readableAssessments: props.readableAssessments,
      editableAssessments: props.editableAssessments,
      assessmentComparisonActive: props.assessmentComparisonActive,
      assessmentHighlightThresholdPercent: props.assessmentHighlightThresholdPercent,
      statementUuidsToAssessmentStats: props.statementUuidsToAssessmentStats,
      canViewOrCreateAssessments: props.canViewOrCreateAssessments
    };
  };

  UNSAFE_componentWillMount() {
    this.setState({
      lastScoreInputTimestamp: 0
    });
    this.uuidsToRefs = {};
  }

  componentDidMount() {
    this.mounted = true;
    document.addEventListener('keyup', this.onKeyUp, false);
    document.addEventListener('mouseup', this.onMouseUp, false);
    actions.registerListener(this.onAction);
  };

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('keyup', this.onKeyUp);
    document.removeEventListener('mouseup', this.onMouseUp);
    actions.unregisterListener(this.onAction);
    this.uuidsToRefs = {};
  };

  onMouseUp = (event) => {
    if (!browserType.isMobile()) {
      if (this.state.enteredCellUuid) {
        // ignore
      } else {
        this.deselectCell();
      }
    }
  };

  onKeyUp = (event) => {
    if (!browserType.isMobile()) {
      const keyCode = event.keyCode;
      if (keyCode === 27) {
        this.deselectCell();
      }
    }
  };

  onCellEnter = (cell) => {
    // console.log('Changing active cell from', this.state.enteredCellUuid, 'to', cell.uuid);
    this.setState({
      enteredCellUuid: cell.uuid
    });
  };

  onCellLeave = (cell) => {
    this.setState({
      enteredCellUuid: undefined
    });
  };

  onCloseCellButtonClick = () => {
    this.deselectCell();
  };

  deselectCell = () => {
    this.setState({
      focusedCellUuid: undefined
    });
  };

  onStatementClick = (statement) => {
    this.setState({
      lastClickedStatementUuid: statement.uuid
    });
  };

  canEditTheseAssessments = () => {
    return this.state.editableAssessments && this.state.editableAssessments.length && this.state.canViewOrCreateAssessments;
  }

  onCellClick = (event, cell, isFocused) => {
    if (this.canEditTheseAssessments()) {
      const alreadyFocused = this.state.focusedCellUuid === cell.uuid;
      if (alreadyFocused) {
        // ignore
      } else {
        this.setState({
          focusedCellUuid: cell.uuid
        });
      }
    }
  };

  onAddMomentTextButtonClick = (statement, assessment) => {
    this.setState({
      momentImportContext: {
        definition: this.state.definition,
        statement: statement,
        assessment: assessment
      }
    });
  };

  onSelectMoment = (moment) => {
    const momentImportContext = this.state.momentImportContext;
    if (momentImportContext) {
      const assessmentItem = scoreUtil.getInternalAssessmentItem(momentImportContext.assessment, momentImportContext.statement.uuid);
      let annotation = scoreUtil.assessmentItemToAnnotation(assessmentItem);
      annotation += moment.text;
      assessmentItem.annotation = annotation;
      const assessmentDAO = this.props.assessmentDAO ? this.props.assessmentDAO : firestoreAssessmentDAO;
      assessmentUtil.debouncedSaveAssessment(assessmentDAO, momentImportContext.assessment).then(() => {
        // this.debouncedUpdater();
        this.setState({
          momentImportContext: undefined
        });
      });
    }
  };

  onAddMomentDone = () => {
    this.setState({
      momentImportContext: undefined
    });
  };

  onAction = (actionId, context) => {
    if (!this.mounted) {
      return null;
    }
    if (actionId === actions.scrollToElementActionId) {
      this.onScrollToElement(context);
    }
  };

  onScrollToElement = (elementUuid) => {
    const element = this.uuidsToRefs[elementUuid];
    if (element) {
      scrollToComponent(element, { offset: 0, align: 'middle', duration: 500, ease:'inCirc'});
    }
  };

  onAssessmentChange = () => {
    // this.debouncedUpdater();
    if (this.props.onCellAssessmentChange) {
      return this.props.onCellAssessmentChange();
    } else {
      return undefined;
    }
  };

  onToggleStatementApplicabilityClick = (statement, assessment) => {
    scoreUtil.toggleStatementApplicability(assessment, statement.uuid);
    const assessmentDAO = this.props.assessmentDAO ? this.props.assessmentDAO : firestoreAssessmentDAO;
    return assessmentUtil.debouncedSaveAssessment(assessmentDAO, assessment).then(() => {
      analytics.event({
        category: analyticsDefinitions.categories.assessment,
        action: analyticsDefinitions.actions.change,
        label: 'innapplicable',
        value: undefined
      });
      return this.onAssessmentChange();
    });
  };

  onStatementScoreChange = (statementUuid, assessment, value) => {
    scoreUtil.setStatementScore(assessment, statementUuid, value);
    const assessmentDAO = this.props.assessmentDAO ? this.props.assessmentDAO : firestoreAssessmentDAO;
    assessmentUtil.debouncedSaveAssessment(assessmentDAO, assessment).then(() => {
      this.onAssessmentChange();
      analytics.event({
        category: analyticsDefinitions.categories.assessment,
        action: analyticsDefinitions.actions.change,
        label: 'score',
        value: value
      });
    });
  };

  onStatementAnnotationChange = (event, cell, assessment, statement) => {
    scoreUtil.setStatementAnnotation(assessment, statement.uuid, event.currentTarget.value);
    const assessmentDAO = this.props.assessmentDAO ? this.props.assessmentDAO : firestoreAssessmentDAO;
    assessmentUtil.debouncedSaveAssessment(assessmentDAO, assessment).then(() => {
      this.onAssessmentChange();
      analytics.event({
        category: analyticsDefinitions.categories.assessment,
        action: analyticsDefinitions.actions.change,
        label: 'annotation'
      });
    });
  };

  getGuidanceVisibility = (statement) => {
    return this.statementUuidsToGuidanceVisibilities[statement.uuid] &&
      this.props.getGuidanceVisibility(statement);
  };

  onGuidanceExpanded = (event, statement) => {
    this.statementUuidsToGuidanceVisibilities[statement.uuid] = true;
    this.props.onGuidanceExpanded(statement);
    this.forceUpdate();
  };

  onGuidanceCollapsed = (event, statement) => {
    this.statementUuidsToGuidanceVisibilities[statement.uuid] = false;
    this.props.onGuidanceCollapsed(statement);
    this.forceUpdate();
  };

  _scheduleUpdate = () => {
    // setTimeout(() => {
    //   this.forceUpdate();
    // }, this.hideCompletedRowDelayMillis);
  };

  renderAddMomentButton = (statement, assessment) => {
    return (
      <Tooltip content="Add text from moment">
        <IconButton
          normalIcon={<AddMomentTextIcon label="Add moment"/>}
          hoverIcon={<AddMomentTextCircleIcon label="Add moment"/>}
          onClick={() => this.onAddMomentTextButtonClick(statement, assessment)}
        />
      </Tooltip>
    );
  };

  renderToggleStatementApplicabilityButton = (statement, assessment, assessmentItem) => {
    const innapplicable = assessmentItem.innapplicable;
    const invertColors = innapplicable === true;
    const tooltip = innapplicable ? 'Mark this as applicable' : 'Mark this as innapplicable';
    return (
      <Tooltip content={tooltip}>
        <IconButton
          invertColors={invertColors}
          normalIcon={<InnapplicableIcon label="Innapplicable"/>}
          hoverIcon={<InnapplicableIcon label="Innapplicable"/>}
          normalColor={adg.adgBlue}
          hoverColor={adg.adgRed}
          onClick={() => this.onToggleStatementApplicabilityClick(statement, assessment)}
        />
      </Tooltip>
    );
  };

  renderStatementInputsToolbar = (cell, columnIndex, statement, statementIndex, annotation, assessment, assessmentItem, allowDisplayOfAssesmentName) => {
    const canWriteAssessment = permissionUtil.canWriteAssessment(assessment);
    const toolbarContent: any[] = [];
    const showAssessmentName = allowDisplayOfAssesmentName && this.props.multipleAssessmentsVisible;
    const assessmentNameLabelText = showAssessmentName && assessment.name ? assessment.name : '';
    if (canWriteAssessment && featureFlags.momentsEnabled()) {
      const moments = momentGraph.getMoments();
      const renderedAddMomentButton = this.renderAddMomentButton(statement, assessment);
      if (moments && moments.length) {
        toolbarContent.push({key: 'a', renderedOutput: renderedAddMomentButton});
      }
    }
    if (canWriteAssessment && featureFlags.toggleAssessmentItemApplicabilityEnabled()) {
      const renderedButton = this.renderToggleStatementApplicabilityButton(statement, assessment, assessmentItem);
      toolbarContent.push({ key: 'b', renderedOutput: renderedButton});
    }
    const renderedToolbarContent = toolbarContent.length ? toolbarContent.map((content) => {
      return (
        <ToolbarItem key={content.key}>
          {content.renderedOutput}
        </ToolbarItem>
      );
    }) : null;
    return (
      <ToolbarJustify>
        <ToolbarItem>
          <Label text={assessmentNameLabelText}/>
        </ToolbarItem>
        <ToolbarItem>
          <ToolbarLeft>
            {renderedToolbarContent}
          </ToolbarLeft>
        </ToolbarItem>
      </ToolbarJustify>
    );
  }

  renderScore = (statement, assessment, assessmentScore, assessmentItem, editable) => {
    // const columnWidth = this.state.columnWidth;
    const columnWidth = this.cellWidthSizerElement ? this.cellWidthSizerElement.clientWidth - doubleCellLRPadding : 100;
    const scoreLabel = undefined; // `Score for ${assessment.name}:`
    return (
      <ScoreInput
        label={scoreLabel}
        editable={editable}
        scoreMeta={this.state.scoreMeta}
        containerWidth={columnWidth}
        score={assessmentScore}
        assessmentItem={assessmentItem}
        onScoreChange={(value) => this.onStatementScoreChange(statement.uuid, assessment, value)}
      />
    );
  }

  renderStatementInputs = (cell, columnIndex, statement, statementIndex, annotation, assessment, assessmentIndex, assessmentScore, assessmentItem, showStatementInputsToolbar) => {
    const annotationLabel = undefined; // `Annotation for ${assessment.name}:`
    const autoFocus = featureFlags.alwaysViewCellsInInputMode() ? false : this.state.lastClickedStatementUuid ?
      statement.uuid === this.state.lastClickedStatementUuid :
      statementIndex === 0 && assessmentIndex === 0;
    
    // const autoFocus = this.state.lastClickedStatementUuid ?
    //   statement.uuid === this.state.lastClickedStatementUuid :
    //   statementIndex === 0 && assessmentIndex === 0;
    
    // const autoFocus = false;
    // const autoFocus = true;
    // const startInEditMode = statementIndex === 0;
    return (
      <div style={{
        marginTop: assessmentIndex === 0 ? '0px' : '10px',
        marginBottom: '0px'
      }}>
        {showStatementInputsToolbar ? this.renderStatementInputsToolbar(cell, columnIndex, statement, statementIndex, annotation, assessment, assessmentItem, true) : null}
        <div>
          {this.renderScore(statement, assessment, assessmentScore, assessmentItem, true)}
        </div>
        <ResizingTextArea
          label={annotationLabel}
          compact={false}
          autoFocus={autoFocus}
          shouldFitContainer={true}
          minHeight={40}
          maxHeight={500}
          maxLength={constants.maxAssessmentAnnotationLength}
          minimumRows={1}
          enableResize={true}
          placeholder={this.state.focusedCellUuid === cell.uuid ? "Annotation (notes explaining the score)" : undefined}
          value={annotation}
          onChange={(event) => this.onStatementAnnotationChange(event, cell, assessment, statement)}
        />
      </div>
    );
  };

  renderStatementAnnotation = (cell, statement, annotation, assessment, assessmentItem) => {
    if (annotation) {
      return (
        <React.Fragment>
          <StatementAnnotation
            assessmentItem={assessmentItem}
            text={annotation}
            scoreMeta={this.state.scoreMeta}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  renderGuidanceContent = (guidance) => {
    const renderedGuidance = guidance.map((guide, guideIndex) => {
      const renderedRequirements = guide.requirements.map((requirement) => {
        return (
          <li key={requirement.uuid}>{requirement.text}</li>
        );
      });
      const renderedRequirementsList = (
        <ul>
          {renderedRequirements}
        </ul>
      );
      return (
        <ul key={guide.uuid}>
          <li>{guidanceRenderUtil.renderGuideLabelOrEmoji(guide, guideIndex, this.state.scoreMeta)}</li>
          {renderedRequirementsList}
        </ul>
      );
    });
    return renderedGuidance;
  };

  renderGuidanceMetadata = (statement) => {
    if (statement.guidanceMetadata && statement.guidanceMetadata.notesMarkdown) {
      const notesHtml = widgetUtil.markdownToHtml(statement.guidanceMetadata.notesMarkdown);
      return (
        <div
          style={{ marginLeft: '20px' }}
          dangerouslySetInnerHTML={{ __html: notesHtml }}
        />
      );
    } else {
      return null;
    }
  };

  renderGuidanceMetadataAndContent = (statement) => {
    return (
      <React.Fragment>
        {this.renderGuidanceMetadata(statement)}
        {this.renderGuidanceContent(statement.guidance)}
      </React.Fragment>
    );
  };

  renderGuidanceLink = (cell, statement) => {
    if (statement.guidance) {
      const expanded = this.getGuidanceVisibility(statement);
      return (
        <Expand
          display="inline"
          contentForeignKey={undefined}
          context={statement}
          text="Guidance"
          initiallyExpanded={expanded}
          contentRenderer={() => { return null }}
          stopPropagation={true}
          onExpanded={(event) => {
            this.onGuidanceExpanded(event, statement);
          }}
          onCollapsed={(event) => {
            this.onGuidanceCollapsed(event, statement);
          }}
        />
      );
    } else {
      return null;
    }
  }

  renderGuidanceMetadataAndContentIfExpanded = (statement) => {
    if (statement.guidance) {
      const expanded = this.getGuidanceVisibility(statement);
      if (expanded) {
        return this.renderGuidanceMetadataAndContent(statement);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  renderStatementGuidance = (cell, statement) => {
    if (statement.guidance) {
      const expanded = this.getGuidanceVisibility(statement);
      return (
        <Expand
          contentForeignKey={undefined}
          context={statement}
          text="Guidance"
          initiallyExpanded={expanded}
          contentRenderer={this.renderGuidanceMetadataAndContent}
          stopPropagation={true}
          onExpanded={(event) => this.onGuidanceExpanded(event, statement)}
          onCollapsed={(event) => this.onGuidanceCollapsed(event, statement)}
        />
      );
    } else {
      return null;
    }
  };

  renderStatementNotApplicableText = () => {
    return (
      <p className="error">
        This statement has been marked as innapplicable for this assessment which means it does not have a score.
      </p>
    );
  };

  renderStatementOutputs = (cell, statement, annotation, assessment, assessmentItem, assessmentScore) => {
    const enderedStatementInnapplicabilityText = assessmentItem && assessmentItem.innapplicable && !featureFlags.presentScoresInBarForm() ?
      this.renderStatementNotApplicableText() : null;
    const renderedAssessmentScore = featureFlags.presentScoresInBarForm() ?
      this.renderScore(statement, assessment, assessmentScore, assessmentItem, false) :
      this.renderStatementAssessmentScore(assessment, statement, assessmentItem);
    const renderedAssessmentAnnotation = this.renderStatementAnnotation(cell, statement, annotation, assessment, assessmentItem);
    return (
      <React.Fragment>
        {enderedStatementInnapplicabilityText}
        {renderedAssessmentAnnotation}
        {renderedAssessmentScore}
      </React.Fragment>
    );
  };

  renderStatementAssessmentScore = (assessment, statement, assessmentItem) => {
    return (
      <React.Fragment>
        <div
          style={{
            //display: 'inline-block',
            marginLeft: '0px',
            // float: 'left'
          }}
        >
          <StatementScore
            scoreMeta={this.state.scoreMeta}
            assessmentItem={assessmentItem}
            radius={10}
            dragDown={4}
            marginLeft="4px"
            hideIfZero={this.state.hideZeroScores}
          />
        </div>
      </React.Fragment>
    );
  };

  renderAssessmentLabel = (assessment: Assessment, inline: boolean) => {
    if (inline) {
      return (
        <Label text={assessment.name + ':'} paddingTop="6px" display="inline-block" marginLeft="10px" marginRight="5px"/>
      );
    } else {
      return (
        <Label text={assessment.name + ':'} paddingTop="6px"/>
      );
    }
  };

  renderSpace = () => {
    return (
      <React.Fragment>
        &nbsp;
      </React.Fragment>
    );
  };

  renderStatementAssessment = (cell, columnIndex, statement, statementIndex, inputMode, assessment, assessmentIndex, showStatementInputsToolbar) => {
    const viewInEditMode = (featureFlags.alwaysViewCellsInInputMode() || inputMode) && permissionUtil.canWriteAssessment(assessment);
    // const viewInEditMode = true;
    const assessmentItem = scoreUtil.getInternalAssessmentItem(assessment, statement.uuid);
    const assessmentScore = scoreUtil.assessmentItemToScore(assessmentItem);
    const existingAnnotation = scoreUtil.assessmentItemToAnnotation(assessmentItem);
    const annotation = existingAnnotation ? existingAnnotation : '';
    // const showLabel = featureFlags.presentScoresInBarForm() ?
    //   this.state.assessments.length > 1 :
    //   this.state.assessments.length > 1 && (annotation || assessmentScore);
    const showLabel = false;
    const showLabelInline = showLabel;
    const renderedStatements = viewInEditMode ?
      this.renderStatementInputs(cell, columnIndex, statement, statementIndex, annotation, assessment, assessmentIndex, assessmentScore, assessmentItem, showStatementInputsToolbar) :
      this.renderStatementOutputs(cell, statement, annotation, assessment, assessmentItem, assessmentScore);
    return (
      <React.Fragment>
        {showLabel ? this.renderAssessmentLabel(assessment, showLabelInline) : assessment.annotation ? this.renderSpace() : null}
        {renderedStatements}
      </React.Fragment>
    );
  };

  computeAssessmentItemStyles = (statement, assessmentItem, assessment): any => {
    const statementUuid = statement.uuid;
    const denormalisedAssessmentItem = scoreUtil.denormaliseAssessmentItem(assessmentItem);
    let itemOpacity = 1;
    if (this.state.assessmentComparisonActive && this.state.statementUuidsToAssessmentStats) {
      const stats = this.state.statementUuidsToAssessmentStats[statementUuid];
      if (stats) {
        // const scorePercent = denormalisedAssessmentItem.scorePercent;

        let scorePercent: number;
        if (denormalisedAssessmentItem.hasScore) {
          scorePercent = denormalisedAssessmentItem.scorePercent;
        } else {
          scorePercent = (stats.minScorePercent + stats.maxScorePercent) / 2;
        }

        const diffToMin = Math.abs(stats.minScorePercent - scorePercent);
        const diffToMax = Math.abs(stats.maxScorePercent - scorePercent);
        if (diffToMin < this.state.assessmentHighlightThresholdPercent && diffToMax < this.state.assessmentHighlightThresholdPercent) {
          itemOpacity = 0.1;
        }
      } else {
        itemOpacity = 0.1;
        // backgroundColor = 'red';
      }
    }
    return {
      opacity: itemOpacity,
      // backgroundColor: backgroundColor
    }
  };

  renderStatement = (cell, columnIndex, inputMode, definitionLabels, definitionHasLabels, statement, statementIndex) => {
    if (statement.text === undefined) {
      return null;
    }
    if (this.state.onlyShowStatementWithUuid && this.state.onlyShowStatementWithUuid !== statement.uuid) {
      return null;
    }
    const statementLabels = rubricUtil.getStatementLabels(this.state.definition, statement.uuid);
    const statementHasLabels = statementLabels.length > 0;
    const filteredStatementLabels = rubricUtil.filterLabels(statementLabels, this.state.visibleLabels);
    if (definitionHasLabels && statementHasLabels) {
      if (filteredStatementLabels.length === 0) {
        return null;
      }
    }

    const containerStyle: any = {
      display: 'block',
      opacity: 1
    };

    const assessmentUuidsToStyles = {};
    if (this.state.assessmentComparisonActive && this.state.statementUuidsToAssessmentStats) {
      this.state.assessments.map((assessment, assessmentIndex) => {
        const assessmentItem = scoreUtil.getInternalAssessmentItem(assessment, statement.uuid);
        const style = this.computeAssessmentItemStyles(statement, assessmentItem, assessment);
        assessmentUuidsToStyles[assessment.uuid] = style;
        if (style.opacity < containerStyle.opacity) {
          containerStyle.opacity = style.opacity;
          if (style.backgroundColor) {
            containerStyle.backgroundColor = style.backgroundColor;
          }
        }
        return undefined;
      });
    }

    let showStatementInputsToolbar = true;
    const mainClasses = 'rubricStatementGroup';
    let renderedStatementToolbar: any = null;
    if (this.state.assessments.length === 1) {
      const assessment = this.state.assessments[0];
      const assessmentItem = scoreUtil.getInternalAssessmentItem(assessment, statement.uuid);
      const existingAnnotation = scoreUtil.assessmentItemToAnnotation(assessmentItem);
      const annotation = existingAnnotation ? existingAnnotation : '';
      renderedStatementToolbar = (
        <ToolbarItem style={{ flexGrow: 0, flexShrink: 0 }}>
          {this.renderStatementInputsToolbar(cell, columnIndex, statement, statementIndex, annotation, assessment, assessmentItem, false)}
        </ToolbarItem>
      );
      showStatementInputsToolbar = false;
    } else {
      renderedStatementToolbar = null;
      showStatementInputsToolbar = true;
   }

    const renderedAssessments = this.state.assessments.map((assessment, assessmentIndex) => {
      return (
        <React.Fragment key={assessment.uuid}>
          {this.renderStatementAssessment(cell, columnIndex, statement, statementIndex, inputMode, assessment, assessmentIndex, showStatementInputsToolbar)}
        </React.Fragment>
      );
    });
    const renderedGuidance = null;
    const statementHtml = widgetUtil.markdownToInlineHtml(statement.text);
    const renderedStatement = (
      <span
        className="statement-text"
        dangerouslySetInnerHTML={{__html: statementHtml}}>
      </span>
    );
    const minToolbarHeight = '32px';
    return (
      <div
        className={inputMode ? `${mainClasses} rubricStatementGroupInput` : mainClasses}
        style={containerStyle}
        key={statement.uuid}
        ref={(statementElement) => { this.uuidsToRefs[statement.uuid] = statementElement }}
        onClick={(event) => this.onStatementClick(statement)}
      >
        <ToolbarJustify style={{ alignItems: 'flex-start', minHeight: minToolbarHeight}}>
          <ToolbarItem style={{ flexGrow: 2 }}>
            <span className="editorDerivedContent" style={{ fontWeight: 400 }}>
              {renderedStatement}
              {definitionHasLabels ? rubricRenderingUtil.renderFilteredStatementLabels(
                this.state.definition, filteredStatementLabels) : null}
              {this.renderGuidanceLink(cell, statement)}
            </span>
          </ToolbarItem>
          {renderedStatementToolbar}
        </ToolbarJustify>
        {this.renderGuidanceMetadataAndContentIfExpanded(statement)}
        {renderedAssessments}
        {renderedGuidance}
      </div>
    );
  };

  renderStatements = (cell, columnIndex, inputMode, definitionLabels, definitionHasLabels) => {
    return cell.statements.map((statement, statementIndex) => {
      return this.renderStatement(cell, columnIndex, inputMode, definitionLabels, definitionHasLabels, statement, statementIndex);
    });
  };

  renderCloseCellIcon = () => {
    return (
      <Tooltip content="Close">
        <div
          className="icon-button"
          style={{
            float: 'right',
            marginRight: '2px'
          }}
          onClick={this.onCloseCellButtonClick}
        >
          <CloseCellIcon label="Close"/>
        </div>
      </Tooltip>
    );
  };

  renderCell = (cell, columnIndex) => {
    const definitionLabels = rubricUtil.getDefinitionLabels(this.state.definition);
    const definitionHasLabels = definitionLabels.length > 0;
    const cellStatementCount = cell.statements.length;
    let hidden = cellStatementCount === 0;
    if (!hidden) {
      const visible = this.props.isColumnVisible(columnIndex);
      hidden = visible === false;
    }
    if (hidden) {
      return (
        <td
          key={cell.uuid}
          className="rubricCell rubricTextCell borderedCell emptyCell"
        >
          &nbsp;
        </td>
      );
    }
    const hasFocussedCell = this.state.focusedCellUuid !== undefined;
    const isFocused = hasFocussedCell && this.state.focusedCellUuid === cell.uuid;
    let classNames = 'rubricCell rubricTextCell borderedCell hoverCell';
    if (isFocused) {
      classNames += ' emphasized';
    } else if (hasFocussedCell) {
      classNames += ' deemphasized';
    }
    const cellStyle = {};
    // if (!hidden) {
    //   if (this.state.definition && this.tableElement) {
    //     const headerColumnCount = 1;
    //     const columnCount = headerColumnCount + this.state.definition.columns.length;
    //     const tableWidth = this.tableElement.clientWidth;
    //     const cellWidth = tableWidth / (columnCount + 1);
    //     cellStyle.minWidth = cellWidth + 'px';
    //   }
    // }
    const renderedCellInnerContent = (
      <React.Fragment>
        {false && isFocused ? this.renderCloseCellIcon() : null}
        {this.renderStatements(cell, columnIndex, isFocused, definitionLabels, definitionHasLabels)}
      </React.Fragment>
    );
    // const renderedCellContent = this.canEditTheseAssessments() ? (
    //   <Tooltip content="Click cell to assess" position="mouse" mousePosition="top">
    //     {renderedCellInnerContent}
    //   </Tooltip>
    // ) : renderedCellInnerContent;
    return (
      <td
        key={cell.uuid}
        style={cellStyle}
        className={classNames}
        onMouseEnter={browserType.isMobile() ? undefined : () => this.onCellEnter(cell)}
        onMouseLeave={browserType.isMobile() ? undefined : () => this.onCellLeave(cell)}
        onClick={(event) => this.onCellClick(event, cell, isFocused)}
      >
        {this.renderCellWidthSizer()}
        {renderedCellInnerContent}
      </td>
    );
  };

  renderMomentImport = () => {
    return (
      <SelectMomentDialog
        onSelectMoment={this.onSelectMoment}
        onCancel={this.onAddMomentDone}
      />
    );
  };

  renderCellWidthSizer = () => {
    return (
      <div
        ref={(element) => { this.cellWidthSizerElement = element }}
        style={{
          width: '100%',
          height: '0px',
          textAlign: 'center',
          color: 'red'
        }}
      >
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.momentImportContext ? this.renderMomentImport() : null}
        {this.renderCell(this.state.cell, this.state.columnIndex)}
      </React.Fragment>
    );
  }

}
