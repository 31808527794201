import React, { PureComponent } from 'react';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
import AssessmentDAI from '../../../../backend/data/AssessmentDAI';
import { AsyncSelect } from '@atlaskit/select';
import collectionsUtil from '../../../../commonbase/util/collectionsUtil';
import DebugObject from '../../widget/DebugObject';
import IconButton from '../../widget/IconButton';
import Label from '../../widget/Label';
import session from '../../../../shared/model/auth/Session';
import preferencesDAO from '../../../../backend/data/PreferencesDAO';
import { SwitcherIcon } from '../../icon/NamedIcons';
import Tooltip from '@atlaskit/tooltip';
import permissionUtil from '../../../../shared/model/auth/PermissionUtil';
import promiseUtil from '../../../../commonbase/util/promiseUtil';
import SelectOption from '../../../../shared/model/widget/SelectOption';
import DataProvider from '../../../../shared/model/DataProvider';
import DataSource from '../../../../shared/model/rubric/DataSource';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import ToolbarJustify from '../../widget/toolbar/ToolbarJustify';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

type Props = {
  disabled?: boolean,
  dataFetchContext: any,
  dataProvider: DataProvider<Assessment[], any>,
  definition: Definition,
  assessmentDAO: AssessmentDAI,
  requireAssessmentWritePermission: boolean,
  label: string,
  showPlaceholder: boolean,
  placeholder: string,
  assessmentUuids: string[],
  lastSelectedAssessmentCacheKey: undefined | string,
  definitionReset: boolean,
  multiple: boolean,
  autoFocus: boolean,
  allowReadableAssessments: boolean,
  onSelect: Function
}

type State = {
  assessmentDAO: AssessmentDAI,
  label: string,
  disabled: boolean,
  showPlaceholder: boolean,
  placeholder: string,
  initialising: boolean,
  definition: Definition,
  initialAssessments: any[],
  multiple: boolean,
  selectedOptions: SelectOption[],
  showInitialAssessments: boolean,
  initialAssessmentsShown: boolean,
  lastSelectedAssessmentCacheKey: undefined | string,
  showDebug?: boolean,
  otherDebug?: any
}

let otherDebug = {
  instanceCount: 0,
  lastProps: undefined
}

export default class AsyncAssessmentSelect extends PureComponent<Props> {

  simpleMode = true;
  select?: any;
  options: any[] = [];
  state: State;

  // Simple cache
  cachedUuidsToAssessments: Map<string, Assessment> = new Map<string, Assessment>();

  constructor(props: Props) {
    super(props);
    if (!props.assessmentDAO) {
      throw new Error('assessmentDAO must be defined');
    }
    this.state = this.buildStateFromProps(props);
    otherDebug.instanceCount++;
    this.state.otherDebug = otherDebug;
    this.options = [];
    this.state.showDebug = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    // console.log(`DefinitionAssessmentSelect.componentWillReceiveProps: Setting definition to ${props.definition.name}...`);
    // if (props.assessmentDAO.getDataSourceId() !== this.state.assessmentDAO.getDataSourceId()) {
      this.setState({
        definition: props.definition,
        assessmentDAO: props.assessmentDAO
      });
      // this.forceUpdate();
    // }
    this.rebuildSelectedOptions(props.assessmentUuids, props.assessmentDAO);
  }

  rebuildSelectedOptions = (selectedAssessmentUuids: string[], assessmentDAO: AssessmentDAI) => {
    const assessmentUuids: string[] = [];
    // debugger;
    for (const assessmentUuid of selectedAssessmentUuids) {
      assessmentUuids.push(assessmentUuid);
    }
    assessmentDAO.getAssessmentsByUuids(assessmentUuids).then(assessments => {
      // debugger;
      const selectedOptions: SelectOption[] = [];
      for (const assessment of assessments) {
        if (assessment) {
          selectedOptions.push({
            value: assessment.uuid,
            label: assessment.name
          });
          this.cacheAssessment(assessment);
        }
      }
      this.setState({
        selectedOptions: selectedOptions
      });
    }).catch((error) => {
      console.warn(error);
      const selectedOptions: SelectOption[] = [];
      this.setState({
        selectedOptions: selectedOptions
      });
    });
  }

  UNSAFE_componentWillMount() {
    this.updateStateFromInitialAssessmentUuids(this.props.assessmentUuids);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focusOnSelect();
    }
  }

  focusOnSelect = () => {
    if (this.select) {
      this.select.focus();
    }
  };

  buildStateFromProps = (props: Props): any => {
    this.options = [];
    const initialising = this.state ? this.state.initialising : true;
    const selectedOptions: any[] = this.state ? this.state.selectedOptions : [];
    const initialAssessments: any[] = this.state ? this.state.initialAssessments : [];
    const state: any = {
      initialising: initialising,
      assessmentDAO: props.assessmentDAO,
      disabled: !!props.disabled,
      multiple: props.multiple,
      definition: props.definition,
      lastSelectedAssessmentCacheKey: props.lastSelectedAssessmentCacheKey,
      initialAssessments: initialAssessments,
      selectedOptions: selectedOptions,
      label: props.label,
      showPlaceholder: props.showPlaceholder,
      placeholder: props.placeholder,
      showInitialAssessments: false,
      initialAssessmentsShown: false
    };
    if (props.definitionReset) {
      state.showInitialAssessments = false;
      state.initialAssessmentsShown = false;
    }
    return state;
  };

  cacheAssessment = (assessment: Assessment) => {
    this.cachedUuidsToAssessments.set(assessment.uuid, assessment);
  }

  getCachedAssessment = (assessmentUuid: string) => {
    return this.cachedUuidsToAssessments.get(assessmentUuid);
  }

  getAssessmentByUuid = (assessmentUuid: string): Promise<undefined | Assessment> => {
    const assessment = this.getCachedAssessment(assessmentUuid);
    if (assessment) {
      return promiseUtil.promiseReturning(assessment);
    } else {
      return this.state.assessmentDAO.getAssessmentByUuid(assessmentUuid)
        .catch((error) => {
          debugger;
          return undefined;
        });
    }
  }

  updateStateFromInitialAssessmentUuids = (assessmentUuids): Promise<void> => {
    const sanitisedAssessmentUuids = collectionsUtil.filterNilsFromArray(assessmentUuids);
    if (sanitisedAssessmentUuids && sanitisedAssessmentUuids.length) {
      return this.getInitialAssessmentUuids(sanitisedAssessmentUuids).then((lastAssessmentUuids: string[]) => {
        if (lastAssessmentUuids && lastAssessmentUuids.length) {
          const assessmentPromises: Promise<undefined | Assessment>[] = [];
          for (const lastAssessmentUuid of lastAssessmentUuids) {
            if (lastAssessmentUuid) {
              const assessmentPromise = this.getAssessmentByUuid(lastAssessmentUuid);
              assessmentPromises.push(assessmentPromise);
            }
          }
          Promise.all(assessmentPromises)
            .then((initialAssessments) => {
              const assessments: Assessment[] = [];
              let index = 0;
              for (const assessment of initialAssessments) {
                if (assessment) {
                  assessments.push(assessment);
                } else {
                  console.warn(`Unable to find assessment with UUID '${sanitisedAssessmentUuids[index]}'`);
                }
                index++;
              }
              const selectedOptions = this.buildCurrentOptions(assessments);
              this.setState({
                initialising: false,
                initialAssessments: assessments,
                selectedOptions: selectedOptions
              });
              // this.checkToShowInitialAssessments(this.state.initialAssessments);
            });
        } else {
          this.setState({
            initialising: false
          });
        }
        return promiseUtil.promiseReturning(undefined);
      });
    } else {
      return promiseUtil.promiseReturning(undefined);
    }
  }

  getInitialAssessmentUuids = (assessmentUuids): Promise<string[]> => {
    if (assessmentUuids && assessmentUuids.length) {
      return new Promise((resolve, reject) => {
        resolve(assessmentUuids);
      });
    } else if (this.state.lastSelectedAssessmentCacheKey) {
      const user = session.getCurrentUser();
      return preferencesDAO.getPreference(user, this.state.lastSelectedAssessmentCacheKey, undefined)
        .then((lastSelectedAssessmentUuid) => {
          return lastSelectedAssessmentUuid ? [lastSelectedAssessmentUuid] : [];
        });
    } else {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  };

  buildCurrentOptions = (assessments: Assessment[]): SelectOption[] => {
    const options: SelectOption[] = [];
    if (assessments && assessments.length) {
      for (const assessment of assessments) {
        options.push({
          value: assessment.uuid,
          label: assessment.name
        });
      }
    }
    return options;
  };

  loadOptions = (): Promise<SelectOption[]> => {
    return this.props.dataProvider.fetchData(this.props.dataFetchContext)
      .then((matchingAssessments: Assessment[]) => {
        const options: SelectOption[] = [];
        for (const possibleAssessment of matchingAssessments) {
          let assessment: undefined | Assessment = undefined;
          if (this.props.allowReadableAssessments) {
            assessment = possibleAssessment;
          } else if (this.props.requireAssessmentWritePermission) {
            assessment = permissionUtil.canWriteAssessment(possibleAssessment) ? possibleAssessment : undefined;
          } else {
            assessment = permissionUtil.canReadAssessment(possibleAssessment) ? possibleAssessment : undefined;
          }
          if (assessment) {
            this.cacheAssessment(assessment);
            const name = assessment.name ? assessment.name : '(no name)';
            const option: SelectOption = {
              // label: name,
              label: name + ' {' + assessment.uuid + '}',
              value: assessment.uuid
            };
            options.push(option);
          }
        }
        this.checkToShowInitialAssessments(this.state.initialAssessments);
        return options;
      })
      .catch((error) => {
        return [];
      });
  }

  checkToShowInitialAssessments = (initialAssessments: Assessment[]) => {
    const initialised =
      this.state &&
      !this.state.initialAssessmentsShown &&
      initialAssessments &&
      initialAssessments.length;
    if (initialised) {
      this.setState({
        showInitialAssessments: true,
        initialAssessmentsShown: true
      });
    }
  }

  onSwitchModesButtonClick = () => {
    if (this.state.disabled) {
      return;
    }
    const hasInitialAssessments = this.state.initialAssessments && this.state.initialAssessments.length;
    if (this.state.multiple || hasInitialAssessments) {
      this.setState({
        showInitialAssessments: !this.state.showInitialAssessments
      });
    } else {
      this.setState({
        showInitialAssessments: false
      });
    }
  };

  onChange = (optionOrOptions) => {
    // console.log('Roobrick: AssessmentSelect.onChange: optionOrOptions:', optionOrOptions);
    let assessments: any[] = [];
    if (Array.isArray(optionOrOptions)) {
      const options = optionOrOptions;
      for (const option of options) {
        const assessmentUuid = option.value;
        const assessment = this.getCachedAssessment(assessmentUuid);
        if (assessment) {
          assessments.push(assessment);
        }
      }
    } else {
      const option = optionOrOptions;
      const assessmentUuid = option.value;
      if (this.state.lastSelectedAssessmentCacheKey) {
        const currentUser = session.getCurrentUser();
        preferencesDAO.setPreference(currentUser, this.state.lastSelectedAssessmentCacheKey, assessmentUuid);
      }
      const assessment = this.getCachedAssessment(assessmentUuid);
      if (assessment) {
        assessments.push(assessment);
      }
    }
    const selectedOptions = this.buildCurrentOptions(assessments);
    this.setState({
      selectedOptions: selectedOptions
    });
    this.props.onSelect(assessments);
  };

  renderSelect = () => {
    const dataSource: DataSource = this.state.assessmentDAO;
    /*
      Provide a key to AsyncSelect that is based on the ID of the data source so that the AsyncSelect is
      re-mounted each time the data source changes so that the options are re-retrieved.
     */
    const asyncSelectKey = `select-${this.state.definition ? this.state.definition.uuid: ''}-${dataSource.getDataSourceId()}`;
    // console.log(`AsyncAssessmentSelect.renderSelect: Rendering for definition: ${this.state.definition.name}`);
    return (
      <div>
        <AsyncSelect
          key={asyncSelectKey}
          ref={ref => {
            this.select = ref;
          }}
          label={this.state.label}
          isDisabled={this.state.disabled}
          placeholder={`Select ${this.state.multiple ? 'one or more assessments' : 'an assessment'}`}
          className="async-select-with-callback"
          classNamePrefix="react-select"
          cacheOptions
          defaultOptions
          value={this.state.selectedOptions}
          isMulti={this.state.multiple}
          isSearchable={false}
          loadOptions={this.loadOptions}
          options={this.options}
          onChange={this.onChange}
        />
      </div>
    );
  };

  renderToggleModesIcon = () => {
    const hasInitialAssessment = this.state.initialAssessments && this.state.initialAssessments.length;
    if (hasInitialAssessment && !this.state.disabled) {
      let tooltip = '';
      if (this.state.showInitialAssessments) {
        if (this.state.multiple) {
          tooltip = 'Select assessments';
        } else {
          tooltip = 'Select an assessment';
        }
      } else {
        if (this.state.multiple) {
          tooltip = 'Stay with last selected assessments';
        } else {
          tooltip = 'Stay with last selected assessment';
        }
      }
      return (
        <Tooltip content={tooltip}>
          <IconButton
            disabled={this.state.disabled}
            normalIcon={<SwitcherIcon label=""/>}
            hoverIcon={<SwitcherIcon label=""/>}
            onClick={this.onSwitchModesButtonClick}
          />
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  renderText = (text: string, opacity: number) => {
    return (
      <div style={{display: 'inline-block', height: '40px', position: 'relative', width: '100%'}}>
        <div style={{position: 'absolute', bottom: '10px', opacity: opacity}}>
          {text}
        </div>
      </div>
    );
  };

  renderInitialAssessmentNames = () => {
    const opacity = this.props.disabled ? 0.6 : 1;
    let assessmentNames = '';
    let nextSeparator = '';
    if (this.state.selectedOptions && this.state.selectedOptions.length) {
      for (const option of this.state.selectedOptions) {
        assessmentNames += nextSeparator + option.label;
        nextSeparator = ', ';
      }
    }
    //         <div style={{ position: 'absolute', left: '12px', top: '12px', bottom: '10px', opacity: opacity }}>
    return this.renderText(assessmentNames, opacity);
  };

  renderToolbar = () => {
    const hasInitialAssessments = this.state.initialAssessments && this.state.initialAssessments.length;
    const clickabilityClassName = this.state.disabled ? '' : ' clickable'
    const classes = this.state.showInitialAssessments ? clickabilityClassName : undefined;
    const onClick = this.state.showInitialAssessments ? this.onSwitchModesButtonClick : undefined;
    if (hasInitialAssessments) {
      return (
        <ToolbarJustify className="widget-container">
          <ToolbarItem
            className={classes}
            style={{flexGrow: 1, marginRight: '5px'}}
            onClick={onClick}
          >
            {this.state.showInitialAssessments ? this.renderInitialAssessmentNames() : this.renderSelect()}
          </ToolbarItem>
          <ToolbarItem>
            {this.renderToggleModesIcon()}
          </ToolbarItem>
        </ToolbarJustify>
      );
    } else {
      return this.renderSelect();
    }
  };

  renderSimpleSelect = () => {
    return this.renderSelect();
  }

  render() {
    // const content = this.state.showPlaceholder ? this.renderText(this.state.placeholder, 1) : this.renderToolbar();
    const content = this.simpleMode ? this.renderSimpleSelect() : this.renderToolbar();
    return (
      <div>
        <DebugObject
          objectName="AssessmentSelect"
          visible={this.state.showDebug}
          object={this.state}
        />
        <Label text={this.props.label} />
        {content}
      </div>
    );
  };

}
