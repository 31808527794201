
class PaymentProviderDefinitions {

  stripePaymentProviderType = 'stripe';
  typesToNames = [];

  constructor() {
    this.typesToNames[this.stripePaymentProviderType] = 'Stripe';
  }

  buildPaymentProviderTypeEnumItemsContext = (currentType) => {
    const stripePaymentProviderItem = {
      content: this.typesToNames[this.stripePaymentProviderType],
      value: this.stripePaymentProviderType
    };
    const selectItems = [{
      items: [stripePaymentProviderItem]
    }];
    let selectedItem: undefined | any = undefined;
    for (let i = 0; i < selectItems[0].items.length; i++) {
      const item = selectItems[0].items[i];
      if (item.value === currentType) {
        selectedItem = item;
      }
    }
    return {
      items: selectItems,
      selectedItem: selectedItem
    };
  };

  getDefaultType = () => {
    return this.stripePaymentProviderType;
  };

}

export default new PaymentProviderDefinitions();
