
const util = {};

util.equalArrays = function(itemsA, itemsB) {
  if (itemsA.length === itemsB.length) {
    for (let i = 0; i < itemsA.length; i++) {
      if (itemsA[i] !== itemsB[i]) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

util.uuidDiscriminatorItemComparator = function(itemA, itemB) {
  return itemA['uuid'] === itemB['uuid'];
}

util.equivalentArrays = function(itemsA, itemsB, itemComparator) {
  if (itemsA && itemsB) {
    return this._equivalentArraysAToB(itemsA, itemsB, itemComparator) && this._equivalentArraysAToB(itemsB, itemsA, itemComparator);
  } else {
    return false;
  }
}

util._equivalentArraysAToB = function(itemsA, itemsB, itemComparator) {
  if (itemsA.length === itemsB.length) {
    for (let i = 0; i < itemsA.length; i++) {
      const index = util.indexOfItemInArray(itemsA[i], itemsB, itemComparator);
      const found = index >= 0;
      if (!found) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

util.filterNilsFromArray = (items) => {
  if (items) {
    const filteredItems = [];
    for (const item of items) {
      if (item) {
        filteredItems.push(item);
      }
    }
    return filteredItems;
  } else {
    return [];
  }
}

util.computeCombinations = function(items, maxCombinations) {
  // console.log('--------');
  // console.log(`Computing combinations of:`, items);
  const combinations = [];
  // const items = util.sortObjectsByField(unsortedItems, fieldName, false);
  for (let combinationLength = 2; combinationLength <= items.length; combinationLength++) {
    util._addCombinationsOfLength(items, combinationLength, combinations, maxCombinations);
    if (maxCombinations && combinations.length >= maxCombinations) {
      break;
    }
  }
  // console.log(`Returning combinations:`, combinations);
  return combinations;
}

util._addCombinationsOfLength = function(items, combinationLength, combinations, maxCombinations) {
  // console.log(` - looking for combinations of length ${combinationLength}:`);
  for (let skipCount = 0; skipCount < items.length - 1; skipCount++) {
    // console.log(`   - skipCount:`, skipCount);
    let combination = [];
    for (let index = 0; index < items.length; index = index + skipCount + 1) {
      // console.log(`     - pushing:`, items[index]);
      combination.push(items[index]);
      if (combination.length === combinationLength) {
        // console.log(`   * Adding combination within loop:`, combination);
        combinations.push(combination);
        combination = [];
        index = index + 1 - combinationLength;
      }
      if (maxCombinations && combinations.length >= maxCombinations) {
        break;
      }
    }
    if (combination.length === combinationLength) {
      // console.log(`   * Adding combination at end of loop:`, combination);
      combinations.push(combination);
    } else {
      // console.log(`   < Aborting combination:`, combination);
    }
    if (maxCombinations && combinations.length >= maxCombinations) {
      break;
    }
  }
}

util.sortObjectsByField = function(objects, fieldName, descending) {
  if (objects && objects.length) {
    const comparator = (a, b) => {
      if (a[fieldName] < b[fieldName]) {
        return descending ? 1 : -1;
      } else if (a[fieldName] > b[fieldName]) {
        return descending ? -1 : 1;
      } else {
        return 0;
      }
    };
    objects.sort(comparator);  
  }
  return objects;
};

util.isInArray = function(itemToCheck, items, identifierFieldName) {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (identifierFieldName) {
      if (item[identifierFieldName] === itemToCheck[identifierFieldName]) {
        return true;
      }
    } else {
      if (item === itemToCheck) {
        return true;
      }
    }
  }
  return false;
};

util.indexOfItemInArray = function(itemToCheck, items, itemComparator) {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (itemComparator) {
      if (itemComparator(item, itemToCheck) === 0) {
        return i;
      }
    } else {
      if (item === itemToCheck) {
        return i;
      }
    }
  }
  return -1;
};

util.addItemToArray = function(itemToAdd, items) {
  if (items) {
    for (const item of items) {
      if (item === itemToAdd) {
        return items;
      }
    }
    items.push(itemToAdd);
    return items;
  } else {
    return [itemToAdd];
  }
}

util.removeItemFromArray = function(itemToRemove, items) {
  // console.log(`Removing ${itemToRemove} from ${items}`);
  if (items) {
    for (let index = items.length - 1; index >= 0; index--) {
      const item = items[index];
      if (item === itemToRemove) {
        // console.log(`Found item to remove.`);
        items.splice(index, 1);
      }
    }
    // console.log(`Returning items ${items}...`);
    return items;
  } else {
    return [];
  }
}

util.mergeArrays = function(itemsA, itemsB, identifierFieldName) {
  const mergedItems = [];
  for (const itemA of itemsA) {
    if (!util.isInArray(itemA, mergedItems, identifierFieldName)) {
      mergedItems.push(itemA);
    }
  }
  for (const itemB of itemsB) {
    if (!util.isInArray(itemB, mergedItems, identifierFieldName)) {
      mergedItems.push(itemB);
    }
  }
  return mergedItems;
};

// util.mergeArrays = function(itemsA, itemsB, itemComparator) {
//   const mergedItems = [];
//   debugger;
//   for (const itemA of itemsA) {
//     if (!util.isInArray(itemA, mergedItems, itemComparator)) {
//       mergedItems.push(itemA);
//     }
//   }
//   for (const itemB of itemsB) {
//     if (!util.isInArray(itemB, mergedItems, itemComparator)) {
//       mergedItems.push(itemB);
//     }
//   }
//   return mergedItems;
// };

module.exports = util;
