
import React, { PureComponent } from "react";
import Label from './Label';
import Select from '@atlaskit/select';
import SelectOption from '../../../shared/model/widget/SelectOption';

type Props = {
  label: undefined | string
  options: SelectOption[]
  defaultSelected?: SelectOption
  placeholder: undefined | string
  noMatchesFound: any
  hasAutocomplete: boolean
  appearance: string
  onSelected: (selection: SelectOption) => void
}

type State = {
  label: string
  options: SelectOption[]
  selectedItem: undefined | SelectOption
  placeholder: string
  noMatchesFound: any
  hasAutocomplete: boolean
  appearance: string
}

export default class SingleSelect extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props): State => {
    return {
      label: props.label,
      options: props.options,
      selectedItem: props.defaultSelected ? props.defaultSelected : undefined,
      placeholder: props.placeholder,
      noMatchesFound: props.noMatchesFound,
      hasAutocomplete: props.hasAutocomplete,
      appearance: props.appearance
    };
  };

  // onSelected = (option: null | ActionMeta<SelectOption>) => {
  //   console.log(option);
  //   debugger;
  //   if (option) {
  //     // const selection = {
  //     //   label: option.content,
  //     //   value: option.value
  //     // }
  //     // this.props.onSelected(selection);
  //   }
  // }

  onSelected = (option: any) => {
    // console.log(option);
    if (option) {
      const selection = {
        label: option.label,
        value: option.value
      }
      this.props.onSelected(selection);
    }
  }

  renderLabel = () => {
    return (
      <Label text={this.state.label} />
    );
  }

  render() {
    return (
      <>
        {this.state.label ? this.renderLabel() : null}
        <Select
          className="single-select"
          classNamePrefix="react-select"
          value={this.state.selectedItem}
          options={this.state.options}
          placeholder={this.state.placeholder}
          onChange={this.onSelected}
        />
      </>
    );
  }

}
