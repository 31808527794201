import React, { PureComponent } from 'react';
import actions from '../../../../shared/actions/Actions';
import adg from '../../../../commonbase/adg';
import ScoreSvg from '../score/ScoreSvg';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import util from '../../../../shared/util/Util';

export default class AspectBarChart extends PureComponent {

  constructor(props) {
    super(props);
    this.barCornerRadius = 6;
    this.barBackgroundColor = adg.adgGray;
    this.barTextColor = adg.white;
    this.barAxisColor = '#333';
    this.barStrokeWidth = 0;
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = this.buildStateFromProps(props);
    this.setState(newState);
  }

  buildStateFromProps = (props) => {
    if (!props.scoreMeta) {
      throw new Error('props.scoreMeta not defined')
    }
    const chartModel = props.chartModel;
    const separatedAssessmentData = chartModel.getSeparatedAssessmentData(props.aspectGrouping);
    const barCount = separatedAssessmentData.segmentCount;
    const assessmentCount = chartModel.assessments.length;
    const padding = 2;
    const barHeight = 32;
    const barGroupHeight = 32 * assessmentCount;
    const barPadding = 6;
    const chartHeight = barCount * (barGroupHeight + 2 * barPadding);
    const xLeftIndent = padding;
    const xRightIndent = padding;
    const yTopIndent = padding;
    const yBottomIndent = padding;
    const svgWidth = props.width;
    const chartWidth = svgWidth - xLeftIndent - xRightIndent;
    const chartBottomY = yTopIndent + chartHeight;
    const svgHeight = chartHeight + yTopIndent + yBottomIndent;
    const state = {
      separatedAssessmentData: separatedAssessmentData,
      scoreMeta: props.scoreMeta,
      barCount: barCount,
      barHeight: barHeight,
      barGroupHeight: barGroupHeight,
      barPadding: barPadding,
      width: svgWidth,
      height: svgHeight,
      chartWidth: chartWidth,
      chartHeight: chartHeight,
      chartBottomY: chartBottomY,
      xLeftIndent: xLeftIndent,
      xRightIndent: xRightIndent,
      yTopIndent: yTopIndent,
      yBottomIndent: yBottomIndent,
      chartModel: chartModel
    };
    const renderContext = this.buildRenderContext(state);
    state.renderContext = renderContext;
    return state;
  };

  buildRenderContext = (state) => {
    const xLeftIndent = state.xLeftIndent;
    const chartBottomY = state.chartBottomY;
    const maxPossibleScore = 100;
    const rowIndexToY = (rowIndex, renderContext) => {
      const chartAreaTopY = renderContext.factorToY(1);
      const topBarTopY = chartAreaTopY + state.barPadding;
      const barTopY = topBarTopY + rowIndex * (state.barGroupHeight + 2 * state.barPadding);
      return barTopY + state.barGroupHeight / 2;
    };
    const scoreToX = (score) => {
      const factor = score / maxPossibleScore;
      return xLeftIndent + Math.round(factor * state.chartWidth);
    };
    const factorToX = (factor) => {
      return xLeftIndent + Math.round(factor * state.chartWidth);
    };
    const factorToY = (factor) => {
      return chartBottomY - Math.round(factor * state.chartHeight);
    };
    const xLeft = factorToX(0);
    const xMiddle = factorToX(0.5);
    const xRight = factorToX(1);
    const yBottom = factorToY(0);
    const yMiddle = factorToY(0.5);
    const yTop = factorToY(1);
    const context = {
      xLeft: xLeft,
      xMiddle: xMiddle,
      xRight: xRight,
      yTop: yTop,
      yMiddle: yMiddle,
      yBottom: yBottom,
      valueLabelFontSize: 12,
      valueAxisLabelFontSize: 14,
      valueTickFontSize: 14,
      timeTickFontSize: 14,
      aspectBarFontSize: 14,
      fontFamily: 'Verdana',
      startXsToEffortPersonYOffsets: {},
      rowIndexToY: rowIndexToY,
      factorToX: factorToX,
      formatDate: util.formatDate,
      scoreToX: scoreToX,
      factorToY: factorToY
    };
    return context;
  };

  onSegmentClick = (segmentInfo) => {
    if (segmentInfo && segmentInfo.targetUuid) {
      actions.onScrollToElement(segmentInfo.targetUuid);
    }
  };

  getAssessmentName = (assessmentUuid) => {
    const chartModel = this.state.chartModel;
    const assessments = chartModel.assessments;
    for (const assessment of assessments) {
      if (assessment.uuid === assessmentUuid) {
        return assessment.name;
      }
    }
    return undefined;
  };

  renderBarAssessment = (segmentInfo, barLabel, renderContext, scorePercent, barGroupTopY, barTopY) => {
    const assessmentItem = scoreUtil.buildAssessmentItemFromScore(scorePercent);
    const key = segmentInfo.uuid;
    const barHeight = this.state.barHeight;
    const barMiddleY = barTopY + barHeight / 2;
    // const barForegroundColor = this.state.scoreMeta.scorePercentToColour(scorePercent);
    const barForegroundColor = this.state.scoreMeta.assessmentItemToColour(assessmentItem);
    const paddingLeft = barHeight + 4;
    // const scoreIsValid = scorePercent !== undefined;
    const scoreIsValid = scoreUtil.hasScore(assessmentItem);
    let barRightX = 0;
    if (scoreIsValid) {
      barRightX = renderContext.scoreToX(scorePercent);
    } else {
      barRightX = renderContext.scoreToX(0);
    }
    const width = barRightX - renderContext.xLeft;
    if (width <= 0) {
      return null;
    }
    return (
      <g
        key={key}
        className="clickable"
        onClick={() => {this.onSegmentClick(segmentInfo)}}
      >
        <rect
          x={renderContext.xLeft}
          y={barTopY}
          rx={this.barCornerRadius}
          ry={this.barCornerRadius}
          width={width}
          height={barHeight}
          fill={barForegroundColor}
          stroke={this.barBackgroundColor}
          strokeWidth={this.barStrokeWidth}
        />
        <circle
          r={barHeight / 3} stroke="#fff" strokeWidth="4"
          cx={renderContext.xLeft + barHeight / 2}
          cy={barMiddleY}
        />
        <ScoreSvg
          scoreMeta={this.state.scoreMeta}
          assessmentItem={assessmentItem}
          radius={barHeight / 3}
          centreX={renderContext.xLeft + barHeight / 2}
          centreY={barMiddleY}
        />
        <text
          stroke={this.barTextColor}
          fill={this.barTextColor}
          strokeWidth="1"
          alignmentBaseline="middle"
          x={renderContext.xLeft + paddingLeft}
          y={barMiddleY}
          textAnchor="start"
          fontFamily={renderContext.fontFamily}
          fontSize={renderContext.aspectBarFontSize}
        >
          {barLabel}
        </text>
      </g>
    );
  };

  renderAssessmentBars = (renderContext) => {
    const separatedAssessmentData = this.state.separatedAssessmentData;
    const assessmentUuidsToData = separatedAssessmentData.assessmentUuidsToData;
    const assessmentUuids = Object.keys(assessmentUuidsToData);
    const renderedBars = [];
    let barIndexWithinGroup = 0;
    for (const assessmentUuid of assessmentUuids) {
      const segmentLabelPrefix = assessmentUuids.length > 1 ? this.getAssessmentName(assessmentUuid) : '';
      const data = assessmentUuidsToData[assessmentUuid];
      const segmentInfos = data.segmentInfos;
      const renderedBarsForAssessment = this.renderBarsForAssessment(
        segmentInfos, renderContext, barIndexWithinGroup, segmentLabelPrefix);
      renderedBars.push(renderedBarsForAssessment);
      barIndexWithinGroup++;
    }
    return renderedBars;
  };

  renderBarsForAssessment = (segmentInfos, renderContext, barIndexWithinGroup, segmentLabelPrefix) => {
    const renderedBars = [];
    const chartRightX = renderContext.scoreToX(100);
    let barIndex = 0;
    for (const segmentInfo of segmentInfos) {
      const barGroupHeight = this.state.barGroupHeight;
      const barMiddleY = renderContext.rowIndexToY(barIndex, renderContext);
      const scorePercent = segmentInfo.score;
      const barGroupTopY = barMiddleY - barGroupHeight / 2;
      const barTopY = barGroupTopY + barIndexWithinGroup * this.state.barHeight;
      const separator = segmentLabelPrefix && segmentInfo.name ? ': ' : '';
      const barLabel = segmentLabelPrefix + separator + segmentInfo.name;
      const width = chartRightX - renderContext.xLeft;
      const barGroupBackground = barIndexWithinGroup === 0 && width > 0 ?
       (
        <rect
          x={renderContext.xLeft}
          y={barGroupTopY}
          rx={this.barCornerRadius}
          ry={this.barCornerRadius}
          width={width}
          height={barGroupHeight}
          stroke={this.barBackgroundColor}
          fill={this.barBackgroundColor}
          strokeWidth={this.barStrokeWidth}
        />
       ) : null;
      const renderedBar = (
        <g
          key={'bar-' + segmentInfo.uuid}
          className="clickable"
          onClick={() => {this.onSegmentClick(segmentInfo)}}
        >
          {barGroupBackground}
          {this.renderBarAssessment(segmentInfo, barLabel, renderContext, scorePercent,
            barGroupTopY, barTopY)}
        </g>
      );
      renderedBars.push(renderedBar);
      barIndex++;
    }
    return renderedBars;
  };

  renderXTicks = (renderContext) => {
    const renderedTicks = [];
    const tickValues = [0, 25, 50, 75, 100];
    const tickHeight = renderContext.timeTickFontSize / 2;
    tickValues.map((tickValue) => {
      const x = renderContext.scoreToX(tickValue);
      const renderedTick = (
        <g key={'y-tick-' + tickValue}>
          <line
            x1={x}
            y1={renderContext.yBottom}
            x2={x}
            y2={renderContext.yBottom + tickHeight}
            stroke={this.barAxisColor}
            strokeWidth={1}
          />
          <text
            stroke={this.barAxisColor}
            fill={this.barAxisColor}
            textAnchor={tickValue === 0 ? "start" : tickValue === 100 ? "end" : "middle"}
            alignmentBaseline="middle"
            x={x}
            y={renderContext.yBottom + tickHeight + 2 * renderContext.timeTickFontSize / 3}
            fontFamily={renderContext.fontFamily}
            fontSize={renderContext.timeTickFontSize}
          >
            {tickValue}
          </text>
        </g>

      );
      renderedTicks.push(renderedTick);
      return null;
    });
    return renderedTicks;
  };

  renderAxis = (x) => {
    if (this.props.showAxis) {
      const renderContext = this.state.renderContext;
      const coords = x ?
        {
          x1: renderContext.xLeft,
          y1: renderContext.yBottom,
          x2: renderContext.xRight,
          y2: renderContext.yBottom
        } :
        {
          x1: renderContext.xLeft,
          y1: renderContext.yTop,
          x2: renderContext.xLeft,
          y2: renderContext.yBottom
        };
      return (
        <line {...coords} stroke="#777" strokeWidth={1} />
      );
    } else {
      return null;
    }
  };

  render() {
    const renderContext = this.state.renderContext;
    renderContext.legendContext = {
      x: this.state.xLeftIndent + 10,
      lastY: this.state.yTopIndent + 10
    };
    return (
      <svg width={this.state.width} height={this.state.height}>
        {this.renderAxis(true)}
        {this.renderAxis(false)}
        {this.props.showAxis ? this.renderXTicks(renderContext) : null}
        {this.renderAssessmentBars(renderContext)}
      </svg>
    );
  };

}