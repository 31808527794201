import { dmUserId } from '../auth/AuthConstants';
import emailNotificationDefinitions from './EmailNotificationDefinitions';
import firebaseApp from '../../../backend/firebase/FirebaseApp';
import Definition from '../rubric/definition/Definition';

export class NotificationUtil {

  sendBlogNotification = (blogId: string, email: string) => {
    const payload = {
      callType: 'sendNotification',
      preferencePropertyName: emailNotificationDefinitions.newBlogEmailsAllowedType,
      blogId: blogId,
      toUserEmails: [email],
      // toUserIds: [dmUserId]
    };
    this._sendNotification(payload);
  };
  
  notifyDefinitionLike = (definition: Definition) => {
    const payload = {
      callType: 'sendNotification',
      preferencePropertyName: emailNotificationDefinitions.definitionLikeEmailsAllowedType,
      definitionUuid: definition.uuid,
      definitionName: definition.name,
      ownerId: definition.ownerId
    };
    this._sendNotification(payload);
  };

  _sendNotification = (payload: any) => {
    const functions = firebaseApp.getFunctions();
    return functions.httpsCallable('consolidatedCallHandler')(payload)
      .then((result) => {
        // debugger;
        return true;
      })
      .catch((error) => {
        // debugger;
        console.warn(error);
        return false;
      });
  };

  sendTestNotification = () => {
    const functions = firebaseApp.getFunctions();
    const contentTypeName = 'Rubric';
    const contentTypeNameLower = contentTypeName.toLowerCase();
    const messageSubject = `There's 1 new like on your ${contentTypeNameLower}`;
    const contentSubject = `There's <b>1 new like</b> on your ${contentTypeNameLower}`;
    const contentUrl = 'https://www.roobrick.org';
    const contentBody = `<b>Fred</b> liked this rubric`;
    const contentTitle = 'My rubric';
    const payload = {
      callType: 'testNotification',
      toUserIds: [dmUserId],
      contentTypeName: contentTypeName,
      contentTypeNameLower: contentTypeNameLower,
      messageSubject: messageSubject,
      contentSubject: contentSubject,
      contentUrl: contentUrl,
      contentBody: contentBody,
      contentTitle: contentTitle
    };
    return functions.httpsCallable('consolidatedCallHandler')(payload)
      .then((result) => {
        // debugger;
        return true;
      })
      .catch((error) => {
        // debugger;
        console.warn(error);
        return false;
      });
  };

}

export default new NotificationUtil();
