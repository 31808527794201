import React, { PureComponent } from "react";
import Avatar from '@atlaskit/avatar';
import { ProfileIcon } from '../icon/NamedIcons';
import session from '../../../shared/model/auth/Session';
import IUser from '../../../shared/model/auth/IUser';

interface Props {
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  onClick?: () => any
}

interface State {
  user: undefined | IUser
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
}

export default class UserAvatar extends PureComponent<Props, State> {

  mounted = false;

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    session.registerListener(this.onSessionChange);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  buildStateFromProps = (props) => {
    const user = session.getCurrentUser();
    return {
      user: user,
      size: props.size
    };
  };

  onSessionChange = (user: undefined | IUser) => {
    if (!this.mounted) {
      return null;
    }
    this.setState({
      user: user
    });
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const user = this.state.user;
    if (user && user.getPhotoURL()) {
      return (
        <div>
          <Avatar
            size={this.state.size}
            src={user.getPhotoURL()}
            onClick={this.onClick}
          />
        </div>
      );
    } else {
      return (
        <div>
          <ProfileIcon label="" />
        </div>
      );
    }
  }

}
