import analytics from '../../shared/model/analytics/Analytics';
import firebaseApp from '../firebase/FirebaseApp';

export default class NetworkDatabasePersistence {

  analyticsEntityName = 'network';

  constructor() {
    this.networkPath = 'network';
  }

  saveNetwork = (user, network) => {
    analytics.databaseWrite(this.analyticsEntityName);
    const key = this._buildNetworkKey(user);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .set(network);
  };

  getNetwork = (user) => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildNetworkKey(user);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const network = snapshot.val();
        this._ensureNetworkStructureIsWellDefined(network);
        return network;
      });
  };

  _ensureNetworkStructureIsWellDefined = (network) => {
    if (network && !network.contacts) {
      network.contacts = [];
    }
  };

  _buildNetworkKey = (user) => {
    return this.networkPath + '/' + user.getId();
  };

}
