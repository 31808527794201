import actions from '../../shared/actions/Actions';
import assessmentDeletionNotifier from '../../shared/model/rubric/AssessmentDeletionNotifier';
import constants from '../../shared/model/Constants';
import definitionSearch from '../../shared/model/rubric/search/DefinitionSearch';
import discoverabilityDefinitions from '../../shared/model/rubric/DiscoverabilityDefinitions';
// import featureFlags from '../../shared/model/feature/FeatureFlags';
import persistenceFactory from './PersistenceFactory';
import permissionUtil from '../../shared/model/auth/PermissionUtil';
import session from '../../shared/model/auth/Session';
import rubricUtil from '../../shared/model/rubric/RubricUtil';
import RubricAssessmentBuilder from '../../shared/model/rubric/RubricAssessmentBuilder';
import RubricDefinitionBuilder from '../../shared/model/rubric/RubricDefinitionBuilder';
// import rubricDeletionNotifier from '../../shared/model/rubric/RubricDeletionNotifier';
import util from '../../shared/util/Util';
import Definition from '../../shared/model/rubric/definition/Definition';
import Assessment from '../../shared/model/rubric/score/Assessment';
import IUser from '../../shared/model/auth/IUser';
import promiseUtil from '../../commonbase/util/promiseUtil';
import RubricPersistence from './RubricPersistence';
import { SearchOptions } from '../../shared/model/rubric/search/SearchOptions';
import AssessmentSnapshot from '../../shared/model/rubric/score/AssessmentSnapshot';
import DefinitionSearchResult from '../../shared/model/rubric/definition/DefinitionSearchResult';
import AssessmentLoader from './AssessmentLoader';
import AssessmentDAI from './AssessmentDAI';

class RubricDAO implements AssessmentLoader {

  rubricPersistence: RubricPersistence;
  assessmentPersistence: AssessmentDAI;
  debouncedSaveDefinition: any

  constructor() {
    this.rubricPersistence = persistenceFactory.rubricPersistence;
    this.assessmentPersistence = persistenceFactory.assessmentPersistence;
    definitionSearch.addSearcher(this.searchDefinitionsNew);
    this.debouncedSaveDefinition = util.debouncePromise(this.saveDefinition, 1000, false);
    // this.debouncedSaveAssessment = util.debouncePromise(this.saveAssessment, 1000, false);
  }

  createDefinition = (definitionName: string, columnNames: string[], rowNames: string[], statementBuilder: any) => {
    return new Promise((resolve, reject) => {
      const user = session.getCurrentUser();
      const definition = new RubricDefinitionBuilder()
        .setOwner(user)
        .setName(definitionName)
        .setDiscoverability(discoverabilityDefinitions.privateDiscoverabilityType)
        .setBorderColor(constants.defaultRubricBorderColor)
        .setHoverColor(constants.defaultRubricHoverColor)
        .setStatementBuilder(statementBuilder)
        .setColumnNames(columnNames)
        .setRowNames(rowNames)
        .build();
      this.denormaliseDefinition(definition);
      resolve(definition);
    });
  };

  forkDefinition = (definition: Definition): Promise<Definition> => {
    if (!permissionUtil.canForkDefinition(definition)) {
      throw new Error('No permission to fork definition ' + definition.name);
    }
    const user = session.getCurrentUser();
    if (user) {
      const forkedDefinition = rubricUtil.cloneDefinition(definition, user.getId());
      return this.saveDefinition(forkedDefinition);
    } else {
      throw new Error('User session not found');
    }
  };

  saveDefinition = (definition: Definition): Promise<Definition> => {
    if (!permissionUtil.canWriteDefinition(definition)) {
      throw new Error('No permission to write to definition ' + definition.name);
    }
    rubricUtil.validateDefinition(definition);
    definition.updateTimestamp = new Date().getTime();
    if (!definition.ownerEmailDomain) {
      const user = session.getCurrentUser();
      if (user) {
        const emailDomain = user.getEmailDomain();
        definition.ownerEmailDomain = emailDomain;
      } else {
        throw new Error('User session not found');
      }
    }
    return this._saveDefinition(definition)
      .then((definition) => {
        actions.onDefinitionChanged(definition);
        return definition;
      });
  };

  _saveDefinition = (definition: Definition): Promise<Definition> => {
    return this.rubricPersistence
      .saveDefinition(definition);
  };

  getAssessmentSnapshots = (assessmentUuid: string): Promise<AssessmentSnapshot[]> => {
    return this.rubricPersistence.getAssessmentSnapshots(assessmentUuid);
  };

  deleteDefinition = (definition: Definition): Promise<void> => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not delete definition until logged in.');
    }
    if (!permissionUtil.canDeleteDefinition(definition)) {
      throw new Error('No permission to delete definition ' + definition.name);
    }
    const user = session.getCurrentUser();
    if (user) {
      const definitionUuid = definition.uuid;
      const includeArchivedAssessments = false;
      return this.assessmentPersistence.getAssessmentsByDefinitionUuidAndUser(definitionUuid, user, includeArchivedAssessments)
        .then((assessments) => {
          if (assessments && assessments.length) {
            throw new Error('Can not delete definition ' + definition.name + ' because there are assessments against it.');
          } else {
            return this.rubricPersistence.deleteDefinition(definition).then(() => {
              actions.removeDefinitionReferences(definition.uuid);
              return undefined;
            });
          }
        });
    } else {
      throw new Error('User session not found');
    }
  };

  deleteAssessment = (assessment: Assessment) => {
    if (!permissionUtil.canDeleteAssessment(assessment)) {
      throw new Error('No permission to delete assessment ' + assessment.name);
    }
    const assessmentRemovalPromise = this.rubricPersistence.deleteAssessment(assessment)
      .then(() => {
        return new Promise((resolve, reject) => {
            try {
              assessmentDeletionNotifier.notifyAssessmentDeletion(assessment.uuid, assessment.definitionUuid);
            } catch (error) {
              console.error('Assessment deletion notification failed:', error);
              // debugger;
            }
            try {
              actions.removeAssessmentReferences(assessment.uuid);
            } catch (error) {
              console.error('Assessment removal notification failed:', error);
              // debugger;
            }
            resolve(undefined);
          });
      })
      .catch((error) => {
        console.error('Delete failed:', error);
        // debugger;
        console.warn(error);
        return {deleted: false};
      });
    return assessmentRemovalPromise;
  };

  getDefinitionByUuid = (uuid: string): Promise<undefined | Definition> => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read definitions ued until logged in.');
    }
    return this.rubricPersistence.getDefinitionByUuid(uuid);
  };

  getAssessmentByUuid = (assessmentUuid: string): Promise<undefined | Assessment> => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read assessment until logged in.');
    }
    return this.rubricPersistence.getAssessmentByUuid(assessmentUuid);
  };

  getAssessmentsByUuids = (assessmentUuids: string[], querySemantics: any) => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read assessment until logged in.');
    }
    return this.rubricPersistence.getAssessmentsByUuids(assessmentUuids, querySemantics);
  };

  getCurrentUserAssessmentsByDefinitionUuid = async (definitionUuid: string, includeArchivedAssessments: boolean): Promise<Assessment[]> => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read assessments until logged in.');
    }
    const user = session.getCurrentUser();
    if (user) {
      // debugger;
      return this.getAssessmentsByDefinitionUuidAndUser(definitionUuid, user, includeArchivedAssessments);
    } else {
      return promiseUtil.promiseReturning([]);
    }
  };

  getAssessmentsByDefinitionUuidAndUser = (definitionUuid: string, owner: IUser, includeArchivedAssessments: boolean) => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read assessments until logged in.');
    }
    // return this.rubricPersistence.getAssessmentsByDefinitionUuidAndUser(definitionUuid, owner, includeArchivedAssessments);
    return this.assessmentPersistence.getAssessmentsByDefinitionUuidAndUser(definitionUuid, owner, includeArchivedAssessments);
  };

  searchDefinitions = (currentUserId: string, searchOptions: SearchOptions): Promise<Definition[]> => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read definitions until logged in.');
    }
    return this.rubricPersistence.searchDefinitions(
      currentUserId, permissionUtil.canReadDefinition, searchOptions);
  };

  searchDefinitionsNew = (currentUserId: string, searchOptions: SearchOptions): Promise<DefinitionSearchResult[]> => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read definitions until logged in.');
    }
    return this.rubricPersistence.searchDefinitionsNew(
      currentUserId, permissionUtil.canReadDefinition, searchOptions);
  };

  searchRecommendedDefinitions = (currentUserId: string, maxResults: number) => {
    if (!session.isLoggedIn()) {
      throw new Error('Can not read definitions until logged in.');
    }
    const searchOptions: SearchOptions = {
      searchString: '',
      maxResults: maxResults
    }
    return this.rubricPersistence.searchDefinitions(currentUserId, permissionUtil.canReadDefinition, searchOptions);
  };

  // createAssessment = (definition, assessmentName, builderActions) => {
  //   if (!session.isLoggedIn()) {
  //     throw new Error('Can not read definitions until logged in.');
  //   }
  //   const assessment = this._createAssessmentObject(definition.uuid: string, assessmentName, builderActions);
  //   return this.saveAssessment(assessment, true).then(() => {
  //     return assessment;
  //   });
  // };

  _createAssessmentObject = (definitionUuid: string, assessmentName: string, builderActions: any) => {
    const user = session.getCurrentUser();
    // const assessmentBuilder = new RubricAssessmentBuilder()
    //   .setOwnership(user)
    //   .setName(assessmentName)
    //   .setDefinitionUuid(definitionUuid);
    const assessment = new RubricAssessmentBuilder()
      .setOwnership(user)
      .setName(assessmentName)
      .setDefinitionUuid(definitionUuid)
      .applybuilderActions(builderActions)
      .build();
    return assessment;
  };

  denormaliseDefinition = (definition: Definition, forceNewUuids?: boolean) => {
    rubricUtil.denormaliseDefinition(definition, forceNewUuids);
  };

}

export default new RubricDAO();
