import ListenerGroup from '../../util/ListenerGroup';
import statusDefinitions from './StatusDefinitions';

export class GlobalStatusController {

  providerKeysToProviders = {};
  currentStatusInfo = {
    status: statusDefinitions.statuses.nil
  };

  listenerGroup = new ListenerGroup('GlobalStatusController');

  addProvider = (provider) => {
    this.providerKeysToProviders[provider.key] = provider;
  };

  removeProvider = (provider) => {
    delete this.providerKeysToProviders[provider.key];
  };

  getCurrentStatusInfo = () => {
    this.currentStatusInfo = this._computeCurrentStatusInfo();
    return this.currentStatusInfo;
  };

  recomputeCurrentStatusInfo = () => {
    this.currentStatusInfo = this._computeCurrentStatusInfo();
    this.notifyListeners();
  };

  _computeCurrentStatusInfo = () => {
    const keys = Object.keys(this.providerKeysToProviders);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const provider = this.providerKeysToProviders[key];
      if (provider.isActive()) {
        const statusInfo = provider.getCurrentStatusInfo();
        // const status = provider.getCurrentStatus();
        if (statusInfo && statusInfo.status !== statusDefinitions.statuses.nil) {
          return statusInfo;
        }
      }
    }
    return {
      status: statusDefinitions.statuses.nil
    };
  };

  notifyListeners = () => {
    // console.log(`GlobalStatusController: Notifying listeners...`);
    this.listenerGroup.notifyListeners(this.currentStatusInfo);
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

}

export default new GlobalStatusController();
