
class DataStats {

  constructor() {
    this.enabled = false;
    this.counts = {
      firestore: {
        read: 0,
        write: 0,
        delete: 0
      }
    }
  }

  logFirestoreRead = () => {
    if (this.enabled) {
      this.counts.firestore.read++;
    }
  };

  logFirestoreReads = (count) => {
    if (this.enabled) {
      this.counts.firestore.read += count;
    }
  };

  logFirestoreWrite = () => {
    if (this.enabled) {
      this.counts.firestore.write++;
    }
  };

  logFirestoreDelete = () => {
    if (this.enabled) {
      this.counts.firestore.delete++;
    }
  };

  logFirestoreReadWrite = () => {
    if (this.enabled) {
      this.counts.firestore.read++;
      this.counts.firestore.write++;
    }
  };

  getFirestoreReads = () => {
    return this.counts.firestore.read;
  };

  getFirestoreWrites = () => {
    return this.counts.firestore.write;
  };

  getFirestoreDeletes = () => {
    return this.counts.firestore.delete;
  };

}

export default new DataStats();
