import React, { PureComponent } from "react";
import actions from '../../../shared/actions/Actions';
import globalSpinnerController from '../../../shared/model/status/GlobalStatusController';
import navState from '../nav/NavState';
import statusDefinitions from '../../../shared/model/status/StatusDefinitions';
import Tooltip from "@atlaskit/tooltip";
import navUtil from '../nav/NavUtil';
import navConstants from '../nav/NavConstants';

export default class GlobalStatus extends PureComponent {

  mounted = false;
  state = {
    statusInfo: globalSpinnerController.getCurrentStatusInfo(),
    sidebarExpanded: navState.isSidebarExpanded()
  };

  UNSAFE_componentWillMount() {
    globalSpinnerController.registerListener(this.onStatusChange);
    actions.registerListener(this.onAction);
  }

  componentDidMount() {
    this.mounted = true;
    this.setState({
      statusInfo: globalSpinnerController.getCurrentStatusInfo()
    });
  }

  componentWillUnmount() {
    globalSpinnerController.unregisterListener(this.onStatusChange);
    actions.unregisterListener(this.onAction);
    this.mounted = false;
  }

  onStatusChange = (statusInfo) => {
    if (this.mounted) {
      this.setState({
        statusInfo: statusInfo
      });
    } else {
      // Ignore: fetch status during next mount.
    }
  };

  onAction = (actionId) => {
    if (!this.mounted) {
      return null;
    }
    if (actionId === actions.sidebarVisibilityChangeActionId) {
      const sidebarExpanded = navState.isSidebarExpanded();
      this.setState({
        sidebarExpanded: sidebarExpanded
      });
    }
  };

  renderStatus = () => {
    const statusInfo = this.state.statusInfo;
    const status = statusInfo.status;
    const statusMessage = statusInfo.overridingMessage ? statusInfo.overridingMessage : statusInfo.status.message;
    const globalSidebarWidth = navConstants.globalSidebarWidth;
    const viewDimensions = navUtil.computeViewDimensions(this.state.sidebarExpanded);
    const offsetLeft = `calc(50% + ${viewDimensions.centerX}px)`;
    const height = globalSidebarWidth;
    const quarterHeight = height / 4;
    const offsetTop = `-${quarterHeight}px`;
    return (
      <div
        style={{
            zIndex: 1,
            position: 'absolute',
            left: offsetLeft,
            top: offsetTop
          }}
        >
        <div className="global-status">
          <Tooltip content={statusMessage} position="mouse" mousePosition="bottom">
            <img
              src={'img/weather-status/animated/' + status.imageName}
              width={globalSidebarWidth}
              height={height}
              alt={status.name}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.statusInfo.status === statusDefinitions.statuses.nil) {
      return null;
    } else {
      return this.renderStatus();
    }
  }

}

