import adg from '../../../../commonbase/adg';

export class DemoDefinitionDelayInstructionBuilder {

  constructor() {
    this.instruction = {
      type: 'delay',
      data: {
        milliseconds: undefined
      }
    }
  }

  setMilliseconds = (milliseconds) => {
    this.instruction.data.milliseconds = milliseconds;
    return this;
  };

  build = () => {
    return this.instruction;
  }

}

export class DemoDefinitionRestartInstructionBuilder {

  constructor() {
    this.instruction = {
      type: 'restart'
    }
  }

  build = () => {
    return this.instruction;
  }

}

export class DemoDefinitionClearAllInstructionBuilder {

  constructor() {
    this.instruction = {
      type: 'clearVisibleComponents'
    }
  }

  build = () => {
    return this.instruction;
  }

}

export class DemoDefinitionBlankImageAreaInstructionBuilder {

  constructor() {
    this.instruction = {
      type: 'blankImageArea'
    }
  }

  build = () => {
    return this.instruction;
  }

}

export class DemoDefinitionDisplayImageInstructionBuilder {

  constructor() {
    this.instruction = {
      type: 'displayImage',
      data: {
        offsetX: 0
      }
    }
  }

  setImageSrc = (imageSrc) => {
    this.instruction.data.imageSrc = imageSrc;
    return this;
  };

  setOffsetX = (offsetX) => {
    this.instruction.data.offsetX = offsetX;
    return this;
  };

  build = () => {
    return this.instruction;
  }

}

export class DemoDefinitionDisplayPointerInstructionBuilder {

  constructor() {
    this.instruction = {
      type: 'displayPointer',
      data: {
        side: 'right',
        imageStartRatioY: 0.5,
        imageEndRatioY: 0.5,
        imageEndRatioX: 0.5,
        lines: []
      }
    }
  }

  setLeftSide = () => {
    this.instruction.data.side = 'left';
    return this;
  };

  setRightSide = () => {
    this.instruction.data.side = 'right';
    return this;
  };

  setSideStartRatioY = (sideStartRatioY) => {
    this.instruction.data.imageStartRatioY = sideStartRatioY;
    return this;
  };

  setImageEndRatioY = (imageEndRatioY) => {
    this.instruction.data.imageEndRatioY = imageEndRatioY;
    return this;
  };

  setImageEndRatioX = (imageEndRatioX) => {
    this.instruction.data.imageEndRatioX = imageEndRatioX;
    return this;
  };

  addTextLine = (text) => {
    this.instruction.data.lines.push(text);
    return this;
  };

  build = () => {
    return this.instruction;
  }

}

export class DemoDefinitionBuilder {

  constructor() {
    this.options = {
      renderComputer: true,
      imageAreaBackgroundColor: '#fff',
      width: 900,
      marginLeft: 100,
      marginRight: 100,
      defaultDelay: 3000,
      instructions: []
    };
  }

  setDebugOn = (debugOn) => {
    this.options.debugOn = debugOn;
    return this;
  };

  setRenderComputer = (renderComputer) => {
    this.options.renderComputer = renderComputer;
    return this;
  };

  setImageAreaBackgroundColor = (imageAreaBackgroundColor) => {
    this.options.imageAreaBackgroundColor = imageAreaBackgroundColor;
    return this;
  };

  setWidth = (width) => {
    this.options.width = width;
    return this;
  };

  setMarginLeft = (marginLeft) => {
    this.options.marginLeft = marginLeft;
    return this;
  };

  setMarginRight = (marginRight) => {
    this.options.marginRight = marginRight;
    return this;
  };

  setDefaultDelay = (defaultDelay) => {
    this.options.defaultDelay = defaultDelay;
    return this;
  };

  addInstruction = (instruction) => {
    this.options.instructions.push(instruction);
    return this;
  };

  addInstructions = (instructionsAppender) => {
    instructionsAppender(this);
    return this;
  };

  addInstructionGroupings = (groupings) => {
    for (let i = 0; i < groupings.length; i++) {
      const grouping = groupings[i];
      this.addInstructions(grouping);
      const isLastGrouping = i >= groupings.length - 1;
      if (!isLastGrouping) {
        this.addBetweenImagesInstructions();
      }
    }
    this.addWaitAndRepeatInstructions();
    return this;
  };

  addBetweenImagesInstructions = () => {
    return this
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(this.options.defaultDelay)
          .build()
      )
      .addInstruction(
        new DemoDefinitionClearAllInstructionBuilder()
          .build()
      )
  };

  addWaitAndRepeatInstructions = () => {
    return this
      .addInstruction(
        new DemoDefinitionDelayInstructionBuilder()
          .setMilliseconds(this.options.defaultDelay)
          .build()
      )
      .addInstruction(
        new DemoDefinitionRestartInstructionBuilder()
          .build()
      )
  };

  build = () => {
    const options = this.options;
    const renderComputer = this.options.renderComputer;
    const aspectWidth = 1280;
    const aspectHeight = 900;
    const aspectRatio = aspectWidth / aspectHeight;
    const marginLeft = options.marginLeft;
    const marginRight = options.marginRight;
    let height = options.width;
    let frameBorderWidth = renderComputer ? height / 30 : 0;
    let standHeight = renderComputer ? height / 10 : 0;
    let frameOutsideBottom = height - standHeight;
    let imageHeight = frameOutsideBottom - 2 * frameBorderWidth;
    let imageWidth = imageHeight * aspectRatio;
    let frameOutsideWidth = imageWidth + 2 * frameBorderWidth;
    const allowedWidth = options.width - marginLeft - marginRight;
    if (frameOutsideWidth > allowedWidth) {
      const scaleDownFactor = allowedWidth / frameOutsideWidth;
      imageWidth = imageWidth * scaleDownFactor;
      imageHeight = imageHeight * scaleDownFactor;
      frameBorderWidth = frameBorderWidth * scaleDownFactor;
      standHeight = standHeight * scaleDownFactor;
    }
    const frameOutsideRadius = 2 * frameBorderWidth / 3;
    const middleX = marginLeft + frameBorderWidth + imageWidth / 2;
    const frameOutsideLeft = middleX - imageWidth / 2 - frameBorderWidth;
    const frameOutsideRight = middleX + imageWidth / 2 + frameBorderWidth;
    const frameOutsideTop = 0;
    const frameHeight = 2 * frameBorderWidth + imageHeight;
    frameOutsideBottom = frameOutsideTop + frameHeight;
    height = frameOutsideBottom + standHeight;
    const width = options.width;
    const frameWidth = imageWidth + 2 * frameBorderWidth;
    const def = {
      color: {
        pointer: adg.adgRed,
        frame: '#606060',
        stand: '#e0e0e0',
        imageAreaBackgroundColor: options.imageAreaBackgroundColor
      },
      renderComputer: renderComputer,
      width: width,
      height: height,
      imageWidth: imageWidth,
      imageHeight: imageHeight,
      marginLeft: marginLeft,
      marginRight: marginRight,
      debugOn: options.debugOn,
      pointerTextPadding: 20,
      pointerRadius: 4,
      frameCentreX: (frameOutsideLeft + frameOutsideRight) / 2,
      frameCentreY: (frameOutsideTop + frameOutsideBottom) / 2,
      frameOutsideRadius: frameOutsideRadius,
      frameOutsideLeft: frameOutsideLeft,
      frameOutsideRight: frameOutsideRight,
      frameOutsideTop: frameOutsideTop,
      frameOutsideBottom: frameOutsideBottom,
      frameInsideLeft: frameOutsideLeft + frameBorderWidth,
      frameInsideRight: frameOutsideRight - frameBorderWidth,
      frameInsideTop: frameOutsideTop + frameBorderWidth,
      frameInsideBottom: frameOutsideBottom - frameBorderWidth,
      frameWidth: frameWidth,
      frameHeight: frameHeight,
      frameBorderWidth: frameBorderWidth,
      standHeight: standHeight,
      defaultDelay: options.defaultDelay,
      instructions: options.instructions
    };
    return def;
  }

}