const colorRangeUtil = require('./colorRangeUtil');

const thisColorRange = {
  startColorRgb: colorRangeUtil._colorToRgb('#DE350B'), // Dirt red
  endColorRgb: colorRangeUtil._colorToRgb('#00875A') // Slime
};

thisColorRange.computeColor = function(factorFromStart) {
  return colorRangeUtil._computeColorInRange(thisColorRange.startColorRgb, thisColorRange.endColorRgb, factorFromStart);
};

module.exports = thisColorRange;
