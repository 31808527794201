import AboutPage from '../../pages/AboutPage';
import constants from '../../../shared/model/Constants';
import BoardPage from '../../pages/BoardPage';
import ContactUsPage from '../../pages/ContactUsPage';
import DashboardPage from '../../pages/DashboardPage';
import DrivePage from '../../pages/DrivePage';
import EditRubricPage from '../../pages/EditRubricPage';
// import featureFlags from '../../../shared/model/feature/FeatureFlags';
import HelpPage from '../../pages/HelpPage';
import HomePage from '../../pages/HomePage';
import navConstants from './NavConstants';
import featureFlags from '../../../shared/model/feature/FeatureFlags';
import NetworkPage from '../../pages/NetworkPage';
import NotificationsPage from '../../pages/NotificationsPage';
// import PricingPage from '../../pages/PricingPage';
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage';
import ProfilePage from '../../pages/ProfilePage';
import ReportPage from '../../pages/ReportPage';
// import RubricCreationGuidePage from '../../pages/RubricCreationGuidePage';
import RubricPage from '../../pages/RubricPage';
import SignInPage from '../../pages/SignInPage';
import TermsOfUsePage from '../../pages/TermsOfUsePage';
import {
  AboutIcon,
  BlogIcon,
  BoardIcon,
  CollaboratorsIcon,
  ContactUsIcon,
  DashboardIcon,
  DriveIcon,
  HelpIcon,
  NotificationsIcon,
  // PricingIcon,
  PrivacyIcon,
  TermsOfUseIcon,
  // RubricCreationGuideIcon
} from '../icon/NamedIcons';
import NavItem from './NavItem';
import util from '../../../commonbase/util/commonUtil';
import AssessmentEntryPointPage from '../../pages/AssessmentEntryPointPage';
import NotesPrivacyPolicyPage from '../crm/view/NotesPrivacyPolicyPage';

export class AppNavInfo {

  homeItem: NavItem;
  dashboardItem: NavItem;
  boardItem: NavItem;
  notificationsItem: NavItem;
  navItems: NavItem[];
  defaultAppNavItem: NavItem;

  constructor() {
    this.navItems = [];

    // Product level listed pages
    this.homeItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.homePageHash)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(HomePage)
      .build();

    const signInItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.signInPageHash)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(SignInPage)
      .build();
    const aboutItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.aboutPageHash)
      .setTitle('About ' + constants.appTitle)
      .setIcon(AboutIcon)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(AboutPage)
      .build();
    const blogItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setUrl(constants.appBaseUrl + '/blog')
      .setTitle('Blog')
      .setIcon(BlogIcon)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(AboutPage)
      .build();
    // const pricingItem = new AppNavInfoBuilder()
    //   .setExact(true)
    //   .setAllowAnonymous(true)
    //   .setPath(navConstants.pricingPageHash)
    //   .setTitle('Pricing')
    //   .setIcon(PricingIcon)
    //   .setContainerKey(navConstants.productContainerKey)
    //   .setComponent(PricingPage)
    //   .build();
    // this.navInfo.push(pricingItem);
    const helpItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.helpPageHash)
      .setTitle('Help')
      .setIcon(HelpIcon)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(HelpPage)
      .build();
    const privacyItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.privacyPageHash)
      .setTitle('Privacy')
      .setIcon(PrivacyIcon)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(PrivacyPolicyPage)
      .build();
    const termsOfUseItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.termsOfUsePageHash)
      .setTitle('Terms of use')
      .setIcon(TermsOfUseIcon)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(TermsOfUsePage)
      .build();
    const contactUsItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.contactUsPageHash)
      .setTitle('Contact us')
      .setIcon(ContactUsIcon)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(ContactUsPage)
      .build();

    // Product level non-listed pages
    const profileItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(navConstants.profilePageHash)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(ProfilePage)
      .build();
    const setProfilePreferenceItem = new AppNavInfoBuilder()
      .setExact(false)
      .setAllowAnonymous(false)
      .setPath(navConstants.profilePageHash + '/set/:preferenceKey/:preferenceValue')
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(ProfilePage)
      .build();

    // App level
    this.boardItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(navConstants.boardPageHash)
      .setTitle('Board')
      .setIcon(BoardIcon)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(BoardPage)
      .build();
    this.dashboardItem = new AppNavInfoBuilder()
      .setNavItemVisible(featureFlags.isDashboardEnabled())
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(navConstants.dashboardPageHash)
      .setTitle('Dashboard')
      .setIcon(DashboardIcon)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(DashboardPage)
      .build();
    const driveItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(navConstants.drivePageHash)
      .setTitle('Drive')
      .setIcon(DriveIcon)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(DrivePage)
      .build();
    const networkItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(navConstants.networkPageHash)
      .setTitle('Network')
      .setIcon(CollaboratorsIcon)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(NetworkPage)
      .build();
    this.notificationsItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(navConstants.notificationsPageHash)
      .setTitle('Notifications')
      .setIcon(NotificationsIcon)
      .setTopNavPosition(navConstants.topNavPositionRight)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(NotificationsPage)
      .build();
    const editRubricItem = new AppNavInfoBuilder()
      .setExact(false)
      .setAllowAnonymous(false)
      .setPath(navConstants.editPageHash + '/:uuid')
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(EditRubricPage)
      .build();
    const viewRubricItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(navConstants.viewPageHash)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(RubricPage)
      .build();
    // http://localhost:3000/#/ass/:779ff9cc-031b-6432-d5fb-db46767d5983
    const assessmentWithoutNameEntryPointItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(`${navConstants.assessmentEntryPointPageHash}/:assessmentUuid`)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(AssessmentEntryPointPage)
      .setNavItemVisible(false)
      .build();
    // http://localhost:3000/#/ass/:779ff9cc-031b-6432-d5fb-db46767d5983/:Residential%20solar%20power%20system%20guide
    const assessmentWithNameEntryPointItem = new AppNavInfoBuilder()
      .setExact(true)
      .setAllowAnonymous(false)
      .setPath(`${navConstants.assessmentEntryPointPageHash}/:assessmentUuid/:assessmentName`)
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(AssessmentEntryPointPage)
      .setNavItemVisible(false)
      .build();
    const viewRubricByUuidItem = new AppNavInfoBuilder()
      .setExact(false)
      .setAllowAnonymous(false)
      .setPath(navConstants.viewPageHash + '/:uuid/:name')
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(RubricPage)
      .build();
    const reportItem = new AppNavInfoBuilder()
      .setExact(false)
      .setAllowAnonymous(false)
      .setPath(navConstants.reportPageHash + '/:uuid/:name')
      .setContainerKey(navConstants.appContainerKey)
      .setComponent(ReportPage)
      .build();
    
    const notesPrivacyPolicyItem = new AppNavInfoBuilder()
      .setNavItemVisible(false)
      .setExact(true)
      .setAllowAnonymous(true)
      .setPath(navConstants.notesPrivacyPolicyPageHash)
      .setTitle('Notes Privacy Policy')
      .setIcon(PrivacyIcon)
      .setContainerKey(navConstants.productContainerKey)
      .setComponent(NotesPrivacyPolicyPage)
      .build();

    this.navItems.push(this.homeItem);
    this.navItems.push(signInItem);
    this.navItems.push(aboutItem);
    this.navItems.push(blogItem);
    this.navItems.push(helpItem);
    this.navItems.push(privacyItem);
    this.navItems.push(termsOfUseItem);
    this.navItems.push(contactUsItem);
    this.navItems.push(profileItem);
    this.navItems.push(setProfilePreferenceItem);
    this.navItems.push(this.dashboardItem);
    this.navItems.push(this.boardItem);
    this.navItems.push(driveItem);
    this.navItems.push(networkItem);
    this.navItems.push(this.notificationsItem);
    this.navItems.push(editRubricItem);
    this.navItems.push(viewRubricItem);
    this.navItems.push(assessmentWithoutNameEntryPointItem);
    this.navItems.push(assessmentWithNameEntryPointItem);
    this.navItems.push(viewRubricByUuidItem);
    this.navItems.push(reportItem);
    this.navItems.push(notesPrivacyPolicyItem);
  
    this.defaultAppNavItem = featureFlags.isDashboardEnabled() ? this.dashboardItem : this.boardItem;
  }

  getNavItems = (): NavItem[] => {
    return this.navItems;
  };

}

class AppNavInfoBuilder {

  navItem: NavItem;

  constructor() {
    this.navItem = {
      uuid: util.uuid(),
      navItemVisible: true,
      exact: true,
      allowAnonymous: false,
      containerKey: '',
      path: '',
      title: '',
      topNavPosition: navConstants.topNavPositionLeft
    };
  }

  setNavItemVisible = (navItemVisible: boolean) => {
    this.navItem.navItemVisible = navItemVisible;
    return this;
  };

  setExact = (exact: boolean) => {
    this.navItem.exact = exact;
    return this;
  };

  setAllowAnonymous = (allowAnonymous: boolean) => {
    this.navItem.allowAnonymous = allowAnonymous;
    return this;
  };

  setUrl = (url) => {
    this.navItem.url = url;
    return this;
  };

  setPath = (path) => {
    this.navItem.path = path;
    return this;
  };

  setTitle = (title) => {
    this.navItem.title = title;
    return this;
  };

  setIcon = (icon) => {
    this.navItem.icon = icon;
    return this;
  };

  setTopNavPosition = (topNavPosition) => {
    this.navItem.topNavPosition = topNavPosition;
    return this;
  };

  setContainerKey = (containerKey) => {
    this.navItem.containerKey = containerKey;
    return this;
  };

  setComponent = (component) => {
    this.navItem.component = component;
    return this;
  };

  build = (): NavItem => {
    return this.navItem;
  }

}

export default new AppNavInfo();
