import React, { PureComponent } from 'react';
import LoginView from './LoginView';
import session from "../../../shared/model/auth/Session";
import IUser from '../../../shared/model/auth/IUser';

interface Props {
  children?: React.ReactNode;
  allowAnonymous: boolean
}

interface State {
  instanceId: number
  user: undefined | IUser
}

export default class WithUser extends PureComponent<Props, State> {

  instanceId = 1;
  mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      instanceId: 1,
      user: undefined
    };
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    // this.setState({
    //   instanceId: this.state.instanceId + 1
    // });
  }

  componentDidMount() {
    this.mounted = true;
    session.registerListener(this.onCurrentUserChange);
    const user = session.getCurrentUser();
    this.setState({
      user: user
    });
  }

  componentWillUnmount() {
    // This is never called since it's within WithUser which is within MainRoute.withRouter. 
    // See https://github.com/ReactTraining/react-router/blob/800991cd2044293718fccd3763b9d91c2f811296/docs/guides/ComponentLifecycle.md
    this.mounted = false;
    session.unregisterListener(this.onCurrentUserChange);
  }

  onCurrentUserChange = (user) => {
    if (this.mounted) {
      this.setState({
        user: user
      });
      this.forceUpdate();
    }
  };

  render() {
    if (this.props.allowAnonymous === true || this.state.user) {
      return (
        <React.Fragment key={`instance-${this.state.instanceId}`}>
          {this.props.children}
        </React.Fragment>
      );
    } else {
      return (
        <LoginView />
      );
    }
  }

};
