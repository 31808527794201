
export default class ValidationResult {

  errorMessage: null | string

  constructor(errorMessage: null | string) {
    this.errorMessage = errorMessage;
  }

  isValid = () => {
    return !this.errorMessage;
  };

  getErrorMessage = () => {
    return this.errorMessage;
  }

}
