import React, { PureComponent } from 'react';
import constants from '../../../shared/model/Constants';
import InlineEdit from '@atlaskit/inline-edit';
import Label from '../widget/Label';
import { gridSize, fontSize } from '@atlaskit/theme';
import permissionUtil from '../../../shared/model/auth/PermissionUtil';
import ResizingTextArea from '../widget/ResizingTextArea';
import styled from 'styled-components';
import Tooltip from "@atlaskit/tooltip";
import WidgetUtil from '../widget/WidgetUtil';

const placeholder = 'Click to edit these notes...';

export default class GeneralNotesField extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    const state = {
      definition: props.definition,
      assessment: props.assessment,
      label: props.label
    };
    return state;
  };

  onGeneralNotesChange = (event) => {
    const generalNotes = event.currentTarget.value;
    this.props.onChange(this.state.assessment, generalNotes);
  };

  onConfirm = (newStatus) => {
  };

  renderEditView = (fieldProps) => {
    return (
      <ResizingTextArea
        key={'general-notes-' + this.state.assessment.uuid}
        label={this.state.label}
        compact={false}
        autoFocus={true}
        shouldFitContainer={true}
        minimumRows={1}
        minHeight={20}
        maxHeight={1000}
        maxLength={constants.maxAssessmentGeneralNotesLength}
        enableResize={true}
        placeholder={placeholder}
        value={this.state.assessment.generalNotes || ''}
        onChange={this.onGeneralNotesChange}
        onBlur={undefined}
      />
    );
  };

  renderNotes = () => {
    const trimmedNotes = this.state.assessment.generalNotes ? this.state.assessment.generalNotes.trim() : '';
    const markdown = trimmedNotes ? trimmedNotes : placeholder;
    const html = WidgetUtil.markdownToHtml(markdown);
    const style = trimmedNotes ? {} : {color: '#aaa'};
    return (
      <div style={style}>
        <p dangerouslySetInnerHTML={{__html: html}} />
      </div>
    );
  }

  renderEditableNotes = () => {
    const ReadViewContainer = styled.div`
      display: flex;
      font-size: ${fontSize()}px;
      line-height: ${(gridSize() * 2.5) / fontSize()};
      min-width: 50px;
      max-width: 100%;
      min-height: ${(gridSize() * 2.5) / fontSize()}em;
      padding: ${gridSize()}px ${gridSize() - 2}px;
      word-break: break-word
    `;
    const renderedLabel = this.state.label ? <Label text={this.state.label}/> : null;
    return (
      <React.Fragment>
        {renderedLabel}
        <div style={{marginTope: '-8px'}}>
          <InlineEdit
            defaultValue={this.state.assessment.generalNotes}
            label={undefined}
            editView={fieldProps => this.renderEditView(fieldProps)}
            readView={() => (
              <Tooltip content={`Click to edit general notes of assessment ${this.state.assessment.name}`}>
                <ReadViewContainer>
                  {this.renderNotes()}
                </ReadViewContainer>
              </Tooltip>
            )}
            onConfirm={value => this.onConfirm(value)}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.state.assessment) {
      const editable = permissionUtil.canWriteAssessment(this.state.assessment);
      if (editable) {
        return this.renderEditableNotes();
      } else {
        return this.renderNotes();
      }  
    } else {
      return null;
    }
  }

}
