import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import analytics from './shared/model/analytics/Analytics';
import cannedDataCreator from './backend/data/canned/CannedDataCreator';
import ConfluenceMacroEditor from './frontend/components/integration/confluence/ConfluenceMacroEditor';
import ConfluenceMacroView from './frontend/components/integration/confluence/ConfluenceMacroView';
import connectJavascriptApi from './frontend/components/integration/ConnectJavascriptApi';
import commonConstants from './commonbase/commonConstants';
import constants from './shared/model/Constants';
import EmbeddedView from './frontend/components/rubric/EmbeddedView';
import firestoreAssessmentDAO from './backend/data/FirestoreAssessmentDAO';
import CreateMomentView from './frontend/components/moment/CreateMomentView';
import MainRouter from './frontend/modules/MainRouter';
import MobileApp from './frontend/modules/MobileApp';
import urlUtil from './shared/util/UrlUtil';

document.title = constants.appTitle;

const renderMainApp = () => {
  // eslint-disable-next-line no-undef
  const renderedMainRouter = <MainRouter />;
  const rootElement = document.getElementById('app-root');
  ReactDOM.render(renderedMainRouter, rootElement);
};

const renderEmbeddedView = (definitionUuid: string, visibleAssessmentUuids: string[]) => {
  const renderedEmbeddedView = (
    <EmbeddedView
      definitionUuid={definitionUuid}
      visibleAssessmentUuids={visibleAssessmentUuids}
      assessmentDAO={firestoreAssessmentDAO}
      viewChart={true}
      viewTable={true}
    />
  );
  // eslint-disable-next-line no-undef
  ReactDOM.render(renderedEmbeddedView, document.getElementById('app-root'));
};

const renderMomentForm = () => {
  const viewport = document.querySelector("meta[name=viewport]");
  if (viewport) {
    viewport.setAttribute('content', 'width=device-width');
  }

  const content = <CreateMomentView/>;
  // eslint-disable-next-line no-undef
  ReactDOM.render(<MobileApp title={'Capture a moment'} content={content}/>, document.getElementById('app-root'));
};

analytics.initialize();
cannedDataCreator.create().then(() => {
  //renderMainApp();
});

const renderLazily = (LazyComponent) => {
  const lazilyRenderedComponent = (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent />
    </Suspense>
  );
  ReactDOM.render(lazilyRenderedComponent, document.getElementById('app-root'));
}

// const validateJwt = async () => {
//   // https://www.roobrick.org/app/confluence-macro-editor?dialog=1&simpleDialog=1&xdm_e=https%3A%2F%2Fkingstonmorrow.atlassian.net&xdm_c=channel-roobrick-confluence__any-rubric&cp=%2Fwiki&xdm_deprecated_addon_key_do_not_use=roobrick-confluence&lic=none&cv=1000.0.0-a98607aeacf6&jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI1NTcwNTg6ODUwMzc4YjYtZmI0Yi00NTQ0LWEzMTgtYTQ5YzU0OWJkYTgxIiwicXNoIjoiNjlhNTczYjZiMGNjYzY1YjA2ZWQ0YjA0YzNlY2EzYmFhZmY0ZTNlNzlmMDQ2YzIzMjA0NTc5MGIyN2Y1MTYxNSIsImlzcyI6IkNvbmZsdWVuY2U6MjQ3ODA3NTEzNSIsImNvbnRleHQiOnt9LCJleHAiOjE3MDc1MzkzMTksImlhdCI6MTcwNzUzOTEzOX0.U-_tfd-kvp1YPXoNb8oJ4gmw0AnVNAZbQrz0ZVH47RU
//   const existingInstallation = await connectInstallationDAO.getInstallation(firestore, clientKey);
//   authentication.authenticate(jwtToken, sharedSecret);
// }

// console.log(`index: processing entry point...`);
const pathParameters = urlUtil.getPathParameters();
const pathParameter0 = urlUtil.getPathParameter(0, pathParameters);
const pathParameter1 = urlUtil.getPathParameter(1, pathParameters);
// console.log(`index:  * pathParameter0 = "${pathParameter0}"`);
// console.log(`index:  * pathParameter1 = "${pathParameter1}"`);
if (pathParameter0 === 'app') {
  if (pathParameter1 === 'monday') {
    const pathParameter2 = urlUtil.getPathParameter(2, pathParameters);
    if (pathParameter2 === 'board-view') {
      // https://roobrick.au.ngrok.io/app/monday/board-view
      const LazyComponent = React.lazy(() => import('./frontend/components/integration/monday/MondayBoardView'));
      renderLazily(LazyComponent);
    } else if (pathParameter2 === 'crm') {
      // https://roobrick.au.ngrok.io/app/monday/crm
      // https://roobrick.loca.lt/app/monday/crm
      const LazyComponent = React.lazy(() => import('./frontend/components/integration/monday/crm/MondayCrmView'));
      renderLazily(LazyComponent);
    } else if (pathParameter2 === 'roobrick-how-to-use') {
      // https://roobrick.au.ngrok.io/app/monday/roobrick-how-to-use
      // https://roobrick.org/app/monday/roobrick-how-to-use
      const LazyComponent = React.lazy(() => import('./frontend/components/integration/monday/MondayRoobrickHowToUseFrame'));
      renderLazily(LazyComponent);
    } else if (pathParameter2 === 'crm-how-to-use') {
      // https://roobrick.au.ngrok.io/app/monday/crm-how-to-use
      const LazyComponent = React.lazy(() => import('./frontend/components/integration/monday/crm/MondayCrmHowToUseFrame'));
      renderLazily(LazyComponent);
    } else if (pathParameter2 === 'dashboard-widget') {
      // https://roobrick.au.ngrok.io/app/monday/dashboard-widget
      const LazyComponent = React.lazy(() => import('./frontend/components/integration/monday/MondayDashboardWidget'));
      renderLazily(LazyComponent);
    }
  } else {
    let resize = true;
    let margin = true;
    if (pathParameter1 === commonConstants.confluencePopupAssessmentViewParam) {
      resize = false;
      margin = false;
    }
    const options = {
      "data-options": `resize:${resize};margin:${margin}`
    };
    connectJavascriptApi.loadAllJs(options).then(() => {
      // console.log('Roobrick: Loaded all.js');
      if (pathParameter1 === commonConstants.jiraGlanceParam) {
        const LazyComponent = React.lazy(() => import('./frontend/components/integration/jira/JiraGlancePanel'));
        renderLazily(LazyComponent);
      } else if (pathParameter1 === commonConstants.jiraContentModuleParam) {
        const LazyComponent = React.lazy(() => import('./frontend/components/integration/jira/JiraContentPanel'));
        renderLazily(LazyComponent);
      } else if (pathParameter1 === commonConstants.confluenceMacroViewParam) {
        //  * http://localhost:3000/app/confluence-macro-view?selectedDefinitionUuid=b294478c-0056-c68c-f033-d35aa97d2159&selectedDefinitionName=Foo&selectedAssessmentUuid=08411a96-7451-77e4-6de7-0a982c5028c8&viewTable=true
        ReactDOM.render(<ConfluenceMacroView/>, document.getElementById('app-root'));
      } else if (pathParameter1 === commonConstants.confluenceMacroEditorParam) {
        //  * http://localhost:3000/app/confluence-macro-editor
        //  * http://localhost:3000/app/confluence-macro-editor/b294478c-0056-c68c-f033-d35aa97d2159
        //  * http://localhost:3000/app/confluence-macro-editor?selectedDefinitionUuid=b294478c-0056-c68c-f033-d35aa97d2159&selectedDefinitionName=Foo&selectedAssessmentUuid=08411a96-7451-77e4-6de7-0a982c5028c8
        ReactDOM.render(<ConfluenceMacroEditor/>, document.getElementById('app-root'));
      } else if (pathParameter1 === commonConstants.confluencePopupAssessmentViewParam) {
        const LazyComponent = React.lazy(() => import('./frontend/components/integration/confluence/ConfluencePopupAssessmentMacroView'));
        renderLazily(LazyComponent);
      } else if (pathParameter1 === commonConstants.confluencePopupAssessmentEditorParam) {
        const LazyComponent = React.lazy(() => import('./frontend/components/integration/confluence/ConfluencePopupAssessmentMacroConfigEditor'));
        renderLazily(LazyComponent);
      } else if (pathParameter1 === commonConstants.confluenceAssessmentDialogPanelParam) {
        const LazyComponent = React.lazy(() => import('./frontend/components/integration/confluence/ConfluencePopupAssessmentDialogPanel'));
        renderLazily(LazyComponent);
      } else {
        ReactDOM.render(<ConfluenceMacroView/>, document.getElementById('app-root'));
      }
    });  
  }
} else if (pathParameter0 === 'mock-confluence-page-view') {
  // http://localhost:3000/mock-confluence-page-view
  const LazyComponent = React.lazy(() => import('./frontend/components/integration/confluence/MockConfluencePageView'));
  renderLazily(LazyComponent);
} else if (pathParameter0 === 'mock-confluence-page-editor') {
  // http://localhost:3000/mock-confluence-page-editor
  const LazyComponent = React.lazy(() => import('./frontend/components/integration/confluence/MockConfluencePageEditor'));
  renderLazily(LazyComponent);
} else if (pathParameter0 === 'mock-jira-issue-view') {
  // http://localhost:3000/mock-jira-issue-view
  const LazyComponent = React.lazy(() => import('./frontend/components/integration/jira/MockJiraIssueView'));
  renderLazily(LazyComponent);
} else if (pathParameter0 === 'ui-test') {
  // http://localhost:3000/ui-test
  const LazyComponent = React.lazy(() => import('./frontend/test/UiTestPage'));
  renderLazily(LazyComponent);
} else {
  const hash = urlUtil.getHash();
  if (hash === '#/moment') {
    renderMomentForm();
  } else {
    const embedOption = urlUtil.getQueryParameter('embed');
    const definitionUuid = urlUtil.getHashParameter(0);
    if (embedOption === '1' && definitionUuid) {
      const assessmentUuidsCsv = urlUtil.getQueryParameter('assessments');
      const visibleAssessmentUuids: string[] = assessmentUuidsCsv ? assessmentUuidsCsv.split[','] : [];
      renderEmbeddedView(definitionUuid, visibleAssessmentUuids);
    } else {
      renderMainApp();
    }
  }
}
