import SelectOption from '../../../../shared/model/widget/SelectOption';

class DecisionStatusDefinitions {

  notStartedStatusType = 'not-started';
  inProgressStatusType = 'in-progress';
  decidedStatusType = 'decided';
  notStartedStatusOption: SelectOption = {
    value: this.notStartedStatusType,
    label: 'Not Started'
  };
  inProgressStatusOption: SelectOption = {
    value: this.inProgressStatusType,
    label: 'In Progress'
  };
  decidedStatusOption: SelectOption = {
    value: this.decidedStatusType,
    label: 'Decided'
  };
  options: SelectOption[] = [
    this.notStartedStatusOption,
    this.inProgressStatusOption,
    this.decidedStatusOption
  ];
  typesToNames = [];

  constructor() {
    for (const option of this.options) {
      this.typesToNames[option.value] = option.label;
    }
    // this.typesToNames = [];
    // this.typesToNames[this.notStartedStatusType] = 'Not Started';
    // this.typesToNames[this.inProgressStatusType] = 'In Progress';
    // this.typesToNames[this.decidedStatusType] = 'Decided';
  }

  getOptions = (): SelectOption[] => {
    return this.options;
  }

  // buildEnumItemsContext = (currentType) => {
  //   const fullEditStateItem = {
  //     content: this.typesToNames[this.notStartedStatusType],
  //     value: this.notStartedStatusType
  //   };
  //   const partialEditItem = {
  //     content: this.typesToNames[this.inProgressStatusType],
  //     value: this.inProgressStatusType
  //   };
  //   const immutableItem = {
  //     content: this.typesToNames[this.decidedStatusType],
  //     value: this.decidedStatusType
  //   };
  //   const selectItems = [{
  //     items: [fullEditStateItem, partialEditItem, immutableItem]
  //   }];
  //   let selectedItem = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: selectItems,
  //     selectedItem: selectedItem
  //   };
  // };

  getDefaultType = () => {
    return this.notStartedStatusType;
  };

  getStatusText = (statusType: string) => {
    return this.typesToNames[statusType];
  };

}

export default new DecisionStatusDefinitions();
