import authUtil from '../../../shared/model/auth/AuthUtil';
import session from '../../../shared/model/auth/Session';
import cannedData from '../../../backend/data/canned/CannedData';
import currentRubric from '../../../shared/model/rubric/CurrentRubric';
import definitionSearch from '../../../shared/model/rubric/search/DefinitionSearch';
import driveDAO from '../../../backend/data/DriveDAO';
import driveDefinitions from '../../../shared/model/drive/DriveDefinitions';
import DriveFolderBuilder from '../../../shared/model/drive/DriveFolderBuilder';
import featureFlags from '../../../shared/model/feature/FeatureFlags';
import ListenerGroup from '../../../shared/util/ListenerGroup';
// import rubricPersistenceNotifier from '../../../backend/data/RubricPersistenceNotifier';
import rubricDeletionNotifier from '../../../shared/model/rubric/RubricDeletionNotifier';
// import util from '../../../shared/util/Util';
import rubricDAO from "../../../backend/data/RubricDAO";
import rubricUtil from '../../../shared/model/rubric/RubricUtil';
import IUser from '../../../shared/model/auth/IUser';
import DriveFolder from '../../../shared/model/drive/DriveFolder';
import DriveFileReference from '../../../shared/model/drive/DriveFileReference';
import { SearchOptions } from '../../../shared/model/rubric/search/SearchOptions';
import Definition from '../../../shared/model/rubric/definition/Definition';
import DefinitionSearchResult from '../../../shared/model/rubric/definition/DefinitionSearchResult';
import ResolvedDefinitionSearchResult from '../../../shared/model/rubric/definition/ResolvedDefinitionSearchResult';

class DriveGraph {

  currentUser: unknown | IUser = undefined;
  rootFolder: any | DriveFolder = undefined;
  folderUuidsToDetails: any = {};

  /**
   * Just to overcome bugs. Should remove at some point.
   */
  defenciveProgramming = false;

  listenerGroup = new ListenerGroup('DriveGraph');

  constructor() {
    const currentUser = session.getCurrentUser();
    this.currentUser = currentUser;
    session.registerListener(this._onSessionChange);
    currentRubric.registerListener(this._onCurrentRubricChange);
    // rubricPersistenceNotifier.registerListener(this._onRubricPersistenceChange);
    rubricDeletionNotifier.registerListener(this._onRubricDeleted);
    this._buildState(currentUser);
    definitionSearch.addSearcher(this._searchDefinitionsNew);
  }

  _onSessionChange = (user) => {
    this.currentUser = user;
    this._buildState(user);
  };

  // _onRubricPersistenceChange = (notificationContext) => {
  //   this._buildState(this.currentUser);
  // };

  _onCurrentRubricChange = (currentRubricState) => {
    // Update file references if necessary. Delay the processing so that it doesn't interfere with the
    // processing of the current rubric.
    setTimeout(() => {
      this._updateFileReference(currentRubricState);
    }, 2000);
  };

  _updateFileReference = (currentRubricState) => {
    let changeDetected = false;
    this.iterateFileReferences((fileReference) => {
      if (fileReference.definitionUuid && fileReference.definitionUuid === currentRubricState.definitionUuid) {
        if (fileReference.definitionName !== currentRubricState.definitionName) {
          fileReference.definitionName = currentRubricState.definitionName;
          changeDetected = true;
        }
      }

      if (fileReference.assessmentUuid && fileReference.assessmentUuid === currentRubricState.assessmentUuid) {
        if (currentRubricState && currentRubricState.assessmentName) {
          if (fileReference.assessmentName !== currentRubricState.assessmentName) {
            fileReference.assessmentName = currentRubricState.assessmentName;
            changeDetected = true;
          }
        }
      }
      return false;
    });
    if (changeDetected) {
      this.save();
    }
  };

  _onRubricDeleted = (definitionUuid) => {
    let changesDetected = this._purgeDefinitionFromFolder(this.rootFolder, definitionUuid);
    if (changesDetected) {
      driveDAO.saveFolder(this.rootFolder)
        .then(() => {
          this._notifyListeners();
        });
    }
  };

  _purgeDefinitionFromFolder = (folder, definitionUuid) => {
    let changesDetected = false;
    if (folder) {
      if (folder.fileReferences) {
        const newDefinitionReferences: any[] = [];
        for (let fileReference of folder.fileReferences) {
          if (fileReference.definitionUuid === definitionUuid) {
            changesDetected = true;
          } else {
            newDefinitionReferences.push(fileReference);
          }
        }
        if (changesDetected) {
          folder.fileReferences = newDefinitionReferences;
        }
      }
      if (folder.childFolders) {
        for (let childFolder of folder.childFolders) {
          const changesInChildFolder = this._purgeDefinitionFromFolder(childFolder, definitionUuid);
          changesDetected = changesDetected || changesInChildFolder;
        }
      }
    }
    return changesDetected;
  };

  _buildState = (user: undefined | IUser) => {
    if (user) {
      driveDAO.getRootFolder(user)
        .then((rootFolder) => {
          this.rootFolder = rootFolder;

          // FF-8
          if (!featureFlags.migrateToFileReferenceSchemaCompleted) {
            this._tempMigrateFolderSchema(this.rootFolder);
          }

          this._createIndex();
          if (rootFolder) {
            this._setRootFolder(rootFolder);
          }
          this._notifyListeners();
          if (!this.rootFolder) {
            const sessionUser = session.getCurrentUser();
            const noChangeInSessionUser = sessionUser && sessionUser.getId() === user.getId();
            if (noChangeInSessionUser) {
              this._createRootFolder(user);
            }
          }
          setTimeout(this._conditionallyCreateSeedContents, 3000);
          return this.rootFolder;
        }).catch((error) => {
          console.error(error);
          // debugger;
        });
    } else {
      this.rootFolder = undefined;
      this.folderUuidsToDetails = {};
      this._notifyListeners();
    }
  };

  _createRootFolder = (user) => {
    if (user) {
      driveDAO.saveFolder(driveDefinitions.rootFolder)
        .then(() => {
          this.rootFolder = new DriveFolderBuilder()
            .setIsRoot(true)
            .setUuid(driveDefinitions.rootFolderUuid)
            .setName(driveDefinitions.rootFolderName)
            .build();
          this.addFolder(this.rootFolder, null);
          this._notifyListeners();
        });
    }
  };

  _conditionallyCreateSeedContents = () => {
    if (this.rootFolder !== undefined) {
      if (!this.rootFolder.seededV1) {
        this._addDemoContents();
        this.rootFolder.seededV1 = true;
        this.save();
      }
    }
  };

  _addDemoContents = () => {
    const testFolder = this.conditionallySeedFolder('seed-test', 'Test', this.rootFolder);
    const exampleFolder = this.conditionallySeedFolder('seed-example', 'Example', this.rootFolder);
    const schoolFolder = this.conditionallySeedFolder('seed-school', 'School', this.rootFolder);
    const craftPackFolder = this.conditionallySeedFolder('seed-craft-pack', 'Craft Pack', this.rootFolder);
    const workFolder = this.conditionallySeedFolder('seed-work', 'Work', this.rootFolder);
    this.conditionallySeedDefinitionReference(testFolder, cannedData.testRubricLabelsReference);
    this.conditionallySeedDefinitionReference(exampleFolder, cannedData.exampleRubricThreeLittlePigsDecisionReference);
    this.conditionallySeedDefinitionReference(schoolFolder, cannedData.genericLearningReference);
    this.conditionallySeedDefinitionReference(schoolFolder, cannedData.waterScarcityInTheWorldReference);
    this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.atlassianProjectTeamHealthMonitorReference);
    this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.atlassianServiceTeamHealthMonitorReference);
    this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.atlassianLeadershipTeamHealthMonitorReference);
    this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.employeeDevelopmentReference);
    this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.decisonMakingTemplateReference);
    this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.multipliersVsDiminishersReference);
    this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.okrWritingGuideReference);
    // this.conditionallySeedDefinitionReference(craftPackFolder, cannedData.startupEvaluationReference);
    this.conditionallySeedDefinitionReference(workFolder, cannedData.appPlatformDeveloperExperienceReference);
    this.conditionallySeedDefinitionReference(workFolder, cannedData.googlesManagerFeedbackSurveyReference);
    if (authUtil.isAtlassianUser()) {
      this.conditionallySeedDefinitionReference(workFolder, cannedData.atlassianEngineeringICGrowthProfileReference);
      this.conditionallySeedDefinitionReference(workFolder, cannedData.atlassianEngineeringManagerGrowthProfileReference);
      this.conditionallySeedDefinitionReference(workFolder, cannedData.atlassianProductManagementManagerGrowthProfileReference);
      this.conditionallySeedDefinitionReference(workFolder, cannedData.atlassianProductManagementICGrowthProfileReference);
      this.conditionallySeedDefinitionReference(workFolder, cannedData.atlassianValuesInterviewReference);
    }
    this.save();
  };

  conditionallySeedFolder = (folderUuid, folderName, parentFolder) => {
    let createdFolder = this.getFolderByUuid(folderUuid);
    if (!createdFolder) {
      createdFolder = new DriveFolderBuilder()
        .setUuid(folderUuid)
        .setName(folderName)
        .setParentFolder(parentFolder)
        .build();
      this._addFolder(createdFolder, parentFolder);
    }
    return createdFolder;
  };

  conditionallySeedDefinitionReference = (folder, reference) => {
    if (folder && reference) {
      if (!this._findFileReference(folder, reference.definitionUuid, undefined)) {
        const fileReference: DriveFileReference = {
          definitionUuid: reference.definitionUuid,
          definitionName: reference.definitionName
        };
        this.addFileReferenceToFolder(folder, fileReference);
      }
    }
  };

  _findFileReference = (folder, definitionUuid, assessmentUuid) => {
    if (folder.fileReferences) {
      for (const reference of folder.fileReferences) {
        if (reference.definitionUuid === definitionUuid && reference.assessmentUuid === assessmentUuid) {
          return reference;
        }
      }
    }
    return undefined;
  };

  _findFolderByUuid = (parentFolder, folderUuid, recursive) => {
    if (parentFolder) {
      if (parentFolder.childFolders && parentFolder.childFolders.length) {
        for (const childFolder of parentFolder.childFolders) {
          if (childFolder.uuid === folderUuid) {
            return childFolder;
          } else if (recursive) {
            const foundFolder = this._findFolderByUuid(childFolder, folderUuid, recursive);
            if (foundFolder) {
              return foundFolder;
            }
          }
        }
      }
    }
    return undefined;
  };

  _findParentOfFolderByUuid = (parentFolder, folderUuid, recursive) => {
    if (parentFolder) {
      if (parentFolder.childFolders) {
        for (const childFolder of parentFolder.childFolders) {
          if (childFolder.uuid === folderUuid) {
            return parentFolder;
          } else if (recursive) {
            const foundParentFolder = this._findParentOfFolderByUuid(childFolder, folderUuid, recursive);
            if (foundParentFolder) {
              return foundParentFolder;
            }
          }
        }
      }
    }
    return undefined;
  };

  _setRootFolder = (rootFolder) => {
    this._addFolder(rootFolder, null);
  };

  _createIndex = () => {
    this.folderUuidsToDetails = {};
    this._addFolderToIndex(this.rootFolder, null, true);
  };

  _addFolderToIndex = (folder, parentFolder, recursive) => {
    if (folder) {
      const details = {
        folder: folder,
        parentFolder: parentFolder
      };
      this.folderUuidsToDetails[folder.uuid] = details;
      if (recursive && folder.childFolders) {
        for (const childFolder of folder.childFolders) {
          this._addFolderToIndex(childFolder, folder, recursive);
        }
      }
    }
  };

  _addFolder = (folder, parentFolder) => {
    if (parentFolder) {
      if (!parentFolder.childFolders) {
        parentFolder.childFolders = [];
      }
      parentFolder.childFolders.push(folder);
      this._addFolderToIndex(folder, parentFolder, false);
      this.save();
    }
  };

  _tempMigrateFolderSchema = (folder) => {
    if (folder) {
      if (folder.definitionReferences && !folder.fileReferences) {
        folder.fileReferences = [];
        for (const definitionReference of folder.definitionReferences) {
          folder.fileReferences.push(definitionReference);
        }
        delete folder.definitionReferences;
      }
      if (folder.childFolders) {
        for (let childFolder of folder.childFolders) {
          this._tempMigrateFolderSchema(childFolder);
        }
      }
    }
  };

  _notifyListeners = () => {
    const newState = this.getState();
    this.listenerGroup.notifyListeners(newState);
  };

  // API

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  save = async (): Promise<void> => {
    return driveDAO.saveFolder(this.rootFolder)
      .then(() => {
        this._notifyListeners();
        return undefined;
      });
  };

  addFileReferenceToFolder = (folder: DriveFolder, fileReference: DriveFileReference) => {
    // if (!fileReference.assessmentName) {
    //   // debugger;
    //   return;
    // }
    if (!folder.fileReferences) {
      folder.fileReferences = [];
    }
    let updated = false;
    let alreadyInFolder = false;
    for (let reference of folder.fileReferences) {
      if (rubricUtil.equalReferences(reference, fileReference)) {
        alreadyInFolder = true;
        if (reference.definitionName !== fileReference.definitionName) {
          reference.definitionName = fileReference.definitionName;
        }
        if (fileReference.assessmentName) {
          if (reference.assessmentName !== fileReference.assessmentName) {
            reference.assessmentName = fileReference.assessmentName;
          }
        }
      }
    }
    if (!alreadyInFolder) {
      folder.fileReferences.push(fileReference);
      updated = true;
    }
    if (updated) {
      driveDAO.saveFolder(this.rootFolder)
        .then(() => {
          this._notifyListeners();
        });
    }
  };

  getState = () => {
    return {
      rootFolder: this.rootFolder
    }
  };

  addFolder = (folder, parentFolder) => {
    if (!folder.childFolders) {
      folder.childFolders = [];
    }
    this._addFolder(folder, parentFolder);
  };

  removeFolder = (folder: DriveFolder) => {
    const details = this.folderUuidsToDetails[folder.uuid];
    if (details) {
      if (details.parentFolder) {
        const newPeerFolders: DriveFolder[] = [];
        for (let peerFolder of details.parentFolder.childFolders) {
          if (peerFolder.uuid !== folder.uuid) {
            newPeerFolders.push(peerFolder);
          }
        }
        details.parentFolder.childFolders = newPeerFolders;
      }
      delete this.folderUuidsToDetails[folder.uuid];
    } else if (this.defenciveProgramming) {
      const recursive = true;
      const parentFolder = this._findParentOfFolderByUuid(this.rootFolder, folder.uuid, recursive);
      if (parentFolder) {
        // console.log('Found parentFolder', parentFolder);
        const newPeerFolders: DriveFolder[] = [];
        for (let peerFolder of parentFolder.childFolders) {
          if (peerFolder.uuid !== folder.uuid) {
            newPeerFolders.push(peerFolder);
          }
        }
        parentFolder.childFolders = newPeerFolders;
      }
    }
  };

  removeFileReferenceFromFolder = (folder: DriveFolder, fileReference: DriveFileReference) => {
    let removed = false;
    if (folder && folder.fileReferences) {
      const newDefinitionReferences: DriveFileReference[] = [];
      for (let reference of folder.fileReferences) {
        if (rubricUtil.equalReferences(reference, fileReference)) {
          removed = true;
        } else {
          newDefinitionReferences.push(reference);
        }
      }
      folder.fileReferences = newDefinitionReferences;
    }
    if (removed) {
      driveDAO.saveFolder(this.rootFolder)
        .then(() => {
          this._notifyListeners();
        });
    }
  };

  getFolderByUuid = (uuid) => {
    const details = this.folderUuidsToDetails[uuid];
    if (details && details.folder) {
      return details.folder;
    } else if (this.defenciveProgramming) {
      const recursive = true;
      const foundFolder = this._findFolderByUuid(this.rootFolder, uuid, recursive);
      return foundFolder;
    }
    return undefined;
  };

  findFolderContainingByDefinitionUuid = (definitionUuid, parentFolder) => {
    // const folder = parentFolder ? parentFolder : this.rootFolder;
    // if (folder.fileReferences) {
    //   for (const reference of folder.fileReferences) {
    //     if (reference.definitionUuid === definitionUuid) {
    //       return folder;
    //     }
    //   }
    // }

    let foundFolder: undefined | DriveFolder = undefined;
    this.iterateFileReferences((fileReference: DriveFileReference, folder?: DriveFolder) => {
      if (fileReference.definitionUuid === definitionUuid) {
        foundFolder = folder;
        return true;
      }
      return false;
    });
    return foundFolder;
  };

  iterateFileReferences = (callback: (fileReference: DriveFileReference, folder: DriveFolder) => boolean) => {
    this._iterateFileReferences(this.rootFolder, true, callback);
  };

  _iterateFileReferences = (folder: DriveFolder, recursively: boolean, callback: (fileReference: DriveFileReference, folder: DriveFolder) => boolean) => {
    let stopIterating = false;
    if (folder) {
      if (folder.fileReferences) {
        for (const fileReference of folder.fileReferences) {
          stopIterating = callback(fileReference, folder);
          if (stopIterating) {
            break;
          }
        }
      }
      if (stopIterating) {
        return;
      }
      if (recursively && folder.childFolders) {
        for (const childFolder of folder.childFolders) {
          this._iterateFileReferences(childFolder, recursively, callback);
        }
      }
    }
  };

  _searchDefinitionsNew = async (currentUserId: string, searchOptions: SearchOptions): Promise<DefinitionSearchResult[]> => {
    const definitionSearchResults: DefinitionSearchResult[] = [];
    this.iterateFileReferences((fileReference: DriveFileReference, folder?: DriveFolder) => {
      if (definitionSearch.doesMatch(fileReference.definitionName, searchOptions)) {
        const definitionResolver: () => Promise<undefined | Definition> = function() {
          return rubricDAO.getDefinitionByUuid(fileReference.definitionUuid);
        }
        const definitionSearchResult = new ResolvedDefinitionSearchResult(
          fileReference.definitionUuid, fileReference.definitionName, definitionResolver
        );
        definitionSearchResults.push(definitionSearchResult);
      }
      return false;
    });
    return definitionSearchResults;
  };

}

export default new DriveGraph();
