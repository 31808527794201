
export type QueryOptions = {
  maxResults?: number;
  isKeyOfInterest?: (key: string) => boolean;
  transformItem?: (item: any) => any;
  isItemOfInterest?: (item: any) => boolean;
}

export type QueryResults = {
  keys: string[];
  items: any[];
}

// This is used as a fallback in cases where local storage is unvailable such as
// in iframes when certain policies are applied.
class MemoryStorage {
  _data: any = {};
  length: number = 0;
  getItem = (key: any): any => {
    return this._data[key];
  }
  setItem = (key: any, value: any): void => {
    this._data[key] = value;
    this.length = Object.keys(this._data).length;
  }
  removeItem = (key: any): any => {
    delete this._data[key];
    this.length = Object.keys(this._data).length;
  }
  key = (index: number): any => {
    const keys = Object.keys(this._data);
    if (index < keys.length) {
      return keys[index];
    } else {
      return undefined;
    }
  }
  clear = (): void => {
    this._data = {};
    this.length = 0;
  }
}

export class StorageDAO {

  _memoryStorage: any;

  getItems = (options: QueryOptions): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      try {
        const matches = this._match(options);
        resolve(matches.items);
      } catch (exception) {
        const message = 'Error getting items: ' + exception;
        console.error(message + '. Options:', options);
        reject(message);
      }
    });
  };

  getInt = (key: string, defaultValue?: number): Promise<number> => {
    return this.getString(key).then((stringValue) => {
      try {
        if (stringValue === undefined) {
          if (defaultValue === undefined) {
            throw new Error(`Unable to find value under key ${key}`);
          } else {
            return defaultValue;
          }
        } else {
          const value = parseInt(stringValue, 10);
          return value;
        }
      } catch (exception) {
        if (defaultValue === undefined) {
          throw exception;
        } else {
          return defaultValue;
        }
      }
    });
  };

  setInt = (key: string, value: number): Promise<void> => {
    return this.setString(key, value.toString());
  };

  getString = (key: string, defaultValue?: string): Promise<undefined | string> => {
    return new Promise((resolve, reject) => {
      try {
        const value: null | string = this._getLocalStorage().getItem(key);
        resolve(value ? value : undefined);
      } catch (exception) {
        if (defaultValue === undefined) {
          console.error('Error getting string: ', key, ':', exception);
          reject();
        } else {
          resolve(defaultValue);
        }
      }
    });
  };

  setString = (key: string, value: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        if (value === undefined) {
          this._getLocalStorage().removeItem(key);
        } else {
          this._getLocalStorage().setItem(key, value);
        }
        resolve();
      } catch (exception) {
        console.error('Error setting string: ', key, ':', value, ':', exception);
        reject();
      }
    });
  };

  getData = (key: string, defaultValue?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const serialisedSettings = this._getLocalStorage().getItem(key);
        if (serialisedSettings === null) {
          resolve(defaultValue);
        } else {
          resolve(JSON.parse(serialisedSettings));
        }
      } catch (exception) {
        if (defaultValue === undefined) {
          console.error('Error getting data: ', key, ':', exception);
          reject();
        } else {
          resolve(defaultValue);
        }
      }
    });
  };

  setData = (key: string, data: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        if (data === undefined) {
          this._getLocalStorage().removeItem(key);
        } else {
          const serialisedData = JSON.stringify(data);
          this._getLocalStorage().setItem(key, serialisedData);
        }
        resolve(data);
      } catch (exception) {
        console.error('Error setting data: ', key, ':', data, ':', exception);
        reject('Error setting data under key ' + key + '.');
      }
    });
  };

  removeItems = (options: QueryOptions): Promise<void[]> => {
    const promises: Promise<void>[] = [];
    const matches = this._match(options);
    for (let i = 0; i < matches.keys.length; i++) {
      const key = matches.keys[i];
      const promise = this.removeItem(key);
      promises.push(promise);
    }
    return Promise.all(promises);
  };

  removeItem = (key: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this._getLocalStorage().removeItem(key);
      resolve();
    });
  };

  _match = (options: QueryOptions): QueryResults => {
    const keys: string[] = [];
    const items: any[] = [];
    const maxItems = options.maxResults ? options.maxResults : 1000;
    const storage = this._getLocalStorage();
    const itemCount = storage.length;
    for (let i = 0; i < itemCount; i++) {
      const itemKey: null | string = storage.key(i);
      if (itemKey) {
        let match = true;
        if (options && options.isKeyOfInterest) {
          match = options.isKeyOfInterest(itemKey);
        }
        if (match) {
          let item = storage.getItem(itemKey);
          if (options.transformItem) {
            item = options.transformItem(item);
          }
          if (options.isItemOfInterest) {
            match = options.isItemOfInterest(item);
          }
          if (match) {
            keys.push(itemKey);
            items.push(item);
          }
        }
      }
      if (items.length > maxItems) {
        break;
      }
    }
    return {
      keys: keys,
      items: items
    };
  };

  isLocalStorageAvailable = (): boolean => {
    try {
      if (window.localStorage) {
        return true;
      }
    } catch (error) {
      // No need to report 
    }
    return false;
  }

  _getLocalStorage = () => {
    try {
      return window.localStorage;
    } catch (error) {
      // debugger;
      // console.log('Unable to access local storage. Reverting to memory storage...');
      if (!this._memoryStorage) {
        this._memoryStorage = new MemoryStorage();
      }
      return this._memoryStorage;
    }
  }

}

export default new StorageDAO();
