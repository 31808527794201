import React, { PureComponent } from "react";
import actions from '../../../../shared/actions/Actions';
import appViewState from '../../../modules/AppViewState';
// import adg from '../../../../commonbase/adg';
// import { Checkbox } from '@atlaskit/checkbox';
// import Range from '@atlaskit/range';
// import Tooltip from "@atlaskit/tooltip";
import navState from '../../nav/NavState';
// import navUtil from '../../nav/NavUtil';

export default class ComparisonToolbar extends PureComponent {

  mounted = false;

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    actions.registerListener(this.onAction);
  }

  componentWillUnmount() {
    actions.unregisterListener(this.onAction);
    this.mounted = false;
  }

  buildStateFromProps = (props) => {
    return {
      sidebarExpanded: navState.isSidebarExpanded(),
      containerWidth: props.containerWidth,
      assessmentComparisonActive: props.assessmentComparisonActive
    };
  };

  onAction = (actionId) => {
    if (!this.mounted) {
      return null;
    }
    if (actionId === actions.sidebarVisibilityChangeActionId) {
      const sidebarExpanded = navState.isSidebarExpanded();
      this.setState({
        sidebarExpanded: sidebarExpanded
      });
    }
  };

  render() {
    const appContainerLeftX = appViewState.getAppContainerLeftX();
    const appContainerWidth = appViewState.getAppContainerWidth();
    const topToolbarHeight = 54; // includes border
    const margin = 20;
    const containerStyle = {
      display: 'block',
      position: 'fixed',
      backgroundColor: '#DEEBFF',
      border: '2px solid #0052CC',
      borderRadius: '6px',
      marginLeft: '0px',
      marginRight: '0px',
      padding: '10px',
      top: topToolbarHeight + margin,
      left: appContainerLeftX + margin,
      width: appContainerWidth - margin - margin,
      height: '50px',
      zIndex: 1
    };
    return (
      <div
        style={containerStyle}
        onClick={this.state.onClick}
      >
        {this.props.children}
      </div>
    );
  }

}
