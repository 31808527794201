import React, { PureComponent } from 'react';
import RubricDAO from '../../backend/data/RubricDAO';
import session from '../../shared/model/auth/Session';
import currentRubric from '../../shared/model/rubric/CurrentRubric';
import navConstants from '../components/nav/NavConstants';
import navUtil from '../components/nav/NavUtil';
import ContentWrapper from '../components/widget/ContentWrapper';
import Spinner from '../components/widget/loading/Spinner';
import rubricPageHelper from './RubricPageHelper';

interface Props {
  history: any
}

interface State {

}

/*
 Test URLs:
    Example Assessment - SolarEdge
     - http://localhost:3000/#/ass/9f2587e1-15bf-2827-9788-cdfa9aafdb42
     - http://localhost:3000/#/ass/9f2587e1-15bf-2827-9788-cdfa9aafdb42/foo
     - https://www.roobrick.org/#/ass/9f2587e1-15bf-2827-9788-cdfa9aafdb42

     - http://localhost:3000/#/ass/9f2587e1-15bf-2827-9788-cdfa9aafdb42/Example+Assessment%3A%20SolarEdge
    Non existent assessment:
     - http://localhost:3000/#/ass/foo
*/

export default class AssessmentEntryPointPage extends PureComponent<Props, State> {

  // constructor(props: Props) {
  //   super(props);    
  // }

  // componentDidUpdate() {
  //   debugger;
  //   this.onSessionChange();
  // }

  componentDidMount() {
    session.registerListener(this.onSessionChange);
    this.onSessionChange();
    rubricPageHelper.updateTitle('Loading assessment...');
  }

  componentWillUnmount() {
    session.unregisterListener(this.onSessionChange);
  }

  onSessionChange = () => {
    if (session.getCurrentUser()) {
      this.redirect();
    }
  }

  redirect = async () => {
    let assessmentRedirectDone = false;
    try {
      const hash = window.location.hash;
      const prefix = `#${navConstants.assessmentEntryPointPageHash}/`;
      if (hash && hash.length > prefix.length && hash.indexOf(prefix) === 0) {
        const hashParts = hash.substring(prefix.length).split('/');
        const assessmentUuid = hashParts[0].startsWith(':') ? hashParts[0].substring(1) : hashParts[0];
        const assessment = await RubricDAO.getAssessmentByUuid(assessmentUuid);
        if (assessment) {
          const inEditMode = false;
          currentRubric.setDefinitionAndAssessmentUuids(assessment.definitionUuid, assessment.uuid, inEditMode);
          assessmentRedirectDone = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (!assessmentRedirectDone) {
      if (this.props.history) {
        this.props.history.push(navConstants.boardPageHash);
      } else {
        const url = navUtil.buildPageUrl(navConstants.boardPageHash);
        window.location.href = url;
      }
    }
  }

  render() {
    return (
      <ContentWrapper>
        <Spinner visible={true}/>
      </ContentWrapper>
    );
  }

}
