import React, { PureComponent } from "react";
import UIContext, { standardUIContext } from '../../../shared/model/widget/UIContext';

interface Props {
  children?: React.ReactNode;
  isText?: boolean
  className?: string
  width?: string
  autoScrollX?: boolean
  opacity?: number
  toolbarClassName?: string
  uiContext?: UIContext
}

interface State {
  className: string
  padding: string
  borderRadius: string
}

export default class LiftedPanel extends PureComponent<Props, State> {

  UNSAFE_componentWillMount() {
    const uiContext: UIContext = this.props.uiContext ? this.props.uiContext : standardUIContext;
    const toolbarClassName = this.props.toolbarClassName ? this.props.toolbarClassName : '';
    let className = `lifted-panel ${toolbarClassName}`;
    if (this.props.autoScrollX) {
      className += ' allow-scroll-horizontally';
    }

    if (this.props.isText) {
      if (uiContext.isBackgroundLight()) {
        className += ' lifted-panel-text-on-light-bg';
      } else {
        className += ' lifted-panel-text-on-dark-bg';
      }
    } else {
      if (uiContext.isBackgroundLight()) {
        className += ' lifted-panel-on-light-bg';
      } else {
        className += ' lifted-panel-on-dark-bg';
      }
    }

    if (this.props.className) {
      className += ' ' + this.props.className;
    }
    this.setState({
      className: className
    });
  }

  render() {
    const style: any = {
      padding: this.state.padding,
      borderRadius: this.state.borderRadius
    }
    if (this.props.width) {
      style.width = this.props.width;
    }
    return (
      <div
        className={this.state.className}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }

}
