import React, { PureComponent } from "react";
import Assessment from '../../../../../shared/model/rubric/score/Assessment';
import ConfluenceStorageBuilder from './ConfluenceStorageBuilder';
import ConfluenceExportScoreMeta, { StandardConfluenceExportScoreMeta } from './ConfluenceExportScoreMeta';
import Definition from '../../../../../shared/model/rubric/definition/Definition';
import navUtil from '../../../../components/nav/NavUtil';
import scoreUtil from '../../../../../shared/model/rubric/score/ScoreUtil';
import rubricUtil from '../../../../../shared/model/rubric/RubricUtil';
import widgetUtil from '../../../../../commonbase/util/widgetUtil';
// import GuidanceItem from '../../../../../shared/model/rubric/definition/GuidanceItem';
import Statement from '../../../../../shared/model/rubric/definition/Statement';
import GuidanceRequirement from '../../../../../shared/model/rubric/definition/GuidanceRequirement';
import I18N from '../../../../../shared/model/i18n/I18N';
// import FeatureFlags from "../../../../shared/model/feature/FeatureFlags";

interface Props {
  definition: Definition,
  assessments: Assessment[]
}

interface State {
  definition: Definition,
  storage: string
}

export default class ConfluenceExport extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      definition: props.definition,
      assessments: props.assessments,
      storage: this.buildStorageDoc(props.definition, props.assessments)
    };
  };

  purpleText = (text) => {
    return `<span style="color: rgb(101,84,192);">${text}</span>`;
  }

  sentenceCsv = (items) => {
    let csv = '';
    items.map((item, index) => {
      const separator = index === items.length - 1 ? ' and ' : csv ? ', ' : '';
      csv += separator + this.purpleText(item);
      return undefined;
    });
    return csv;
  };

  appendStorageSummaryForArea = (builder, definition, assessments, structureInfo, areaName, rowIndex) => {
    const rowInfo = structureInfo[rowIndex];
    const statementTexts = rowInfo.statementTexts;
    const statementTextsCsv = this.sentenceCsv(statementTexts);
    builder.p(`The ${this.purpleText(areaName)} section comprises considerations for ${statementTextsCsv}.`);
  }

  appendGuidanceExpand = (
      builder,
      statement: Statement,
      exportScoreMeta: ConfluenceExportScoreMeta) => {
    const guidance = rubricUtil.getGuidance(statement);
    if (guidance && guidance.length) {
      builder.openExpand(`Guidance`);
      const guidanceMetadata = rubricUtil.getStatementGuidanceMetadata(statement);
      if (guidanceMetadata) {
        const notesHtml = widgetUtil.markdownToHtml(guidanceMetadata.notesMarkdown);
        builder.html(`<div>${notesHtml}</div>`);
      }
      for (const guidanceItem of guidance) {
        const scoreGuide = guidanceItem.scoreGuide;
        builder.html(`<p>`);
        exportScoreMeta.appendScoreGuideLabel(builder, scoreGuide);
        builder.html(`</p>`);
        const requirements: GuidanceRequirement[] = guidanceItem.requirements;
        for (const requirement of requirements) {
          builder.html(`<p> * ${requirement.text}</p>`);
        }
      }
      builder.html(``);
      builder.closeExpand();
    }
  }

  appendStorageTableForArea = (builder, definition: Definition, assessments: Assessment[],
      structureInfo, areaName, showAreaColumn,
      exportScoreMeta: ConfluenceExportScoreMeta) => {
    builder.append(`\n<table data-layout="default">`);
    builder.append(`\n  <tbody>`);

    const headerContentItems: string[] = [];
    if (showAreaColumn) {
      headerContentItems.push(I18N.Area);
    }
    headerContentItems.push('Consideration');
    for (const assessment of assessments) {
      headerContentItems.push(assessment.name);
    }
    builder.tr(headerContentItems, {headerRow: true});

    rubricUtil.iterateRows(definition, (row, group, groupIndex, rowIndex) => {
      if (row.name === areaName) {
        rubricUtil.iterateRow(row, (columnIndex, statement, statementIndex, context) => {
          const rowContentItems: string[] = [];
          let headerCellRowSpan = undefined;
          const rowInfo = structureInfo[rowIndex];
          assessments.map((assessment, assessmentIndex) => {
            if (showAreaColumn && statementIndex === 0 && assessmentIndex === 0) {
              rowContentItems.push(`<p><strong>${row.name}</strong></p>`);
              headerCellRowSpan = rowInfo.statementCount;
            } else {

            }
            if (assessmentIndex === 0) {
              // rowContentItems.push(`<p><strong>${statement.text}</strong></p>`);
              const considerationBuilder = new ConfluenceStorageBuilder();
              this.appendGuidanceExpand(considerationBuilder, statement, exportScoreMeta);
              const considerationHtml = considerationBuilder.build();
              rowContentItems.push(`<p><strong>${statement.text}</strong></p>${considerationHtml}`);
            }
            const assessmentItem = rubricUtil.getInternalAssessmentItem(assessment, statement.uuid);
            let assessmentText = '';
            if (assessmentItem.innapplicable) {
              assessmentText = 'N/A'
            } else {
              if (scoreUtil.hasScore(assessmentItem)) {
                const score = scoreUtil.assessmentItemToScore(assessmentItem);
                // const emojiStorageHtml = exportScoreMeta.scoreToEmojiStorage(score);
                const scoreBuilder = new ConfluenceStorageBuilder();
                exportScoreMeta.appendScore(scoreBuilder, score);
                const emojiStorageHtml = scoreBuilder.build();
                assessmentText = emojiStorageHtml;
              }
              const annotation = scoreUtil.assessmentItemToAnnotation(assessmentItem);
              if (annotation) {
                const separator = ' ';
                const annotationHtml = widgetUtil.markdownToHtml(annotation);
                assessmentText += separator + annotationHtml;
              }
            }
            // rowContentItems.push(`<p><strong>${statement.text}</strong></p><p>${assessmentText}</p>`);
            rowContentItems.push(`<p>${assessmentText}</p>`);
            return undefined;
          });
          builder.tr(rowContentItems, {headerCellRowSpan: headerCellRowSpan, headerRow: false});
        });
      }
    });
    builder.append(`\n  </tbody>`);
    builder.append(`\n</table>`);
  };

  buildStorageDoc = (definition: Definition, assessments: Assessment[]) => {
    const structureInfo: any[] = [];
    const areaNames: string[] = [];
    rubricUtil.iterateRows(definition, (row, group, groupIndex, rowIndex) => {
      areaNames.push(row.name);
      const statementTexts: string[] = [];
      const rowInfo = {
        statementCount: 0,
        statementTexts: statementTexts
      };
      rubricUtil.iterateRow(row, (columnIndex, statement, statementIndex, context) => {
        rowInfo.statementCount++;
        statementTexts.push(statement.text);
      });
      structureInfo.push(rowInfo);
    });

    const exportScoreMeta: ConfluenceExportScoreMeta = new StandardConfluenceExportScoreMeta();
    const builder = new ConfluenceStorageBuilder();
    // const assessmentNames = assessments.map(assessment => {return assessment.name});
    // const assementNamesCsv = this.sentenceCsv(assessmentNames);
    const areaNamesCsv = this.sentenceCsv(areaNames);
    const labelNames = rubricUtil.getDefinitionLabels(definition);
    const hasLabels = labelNames && labelNames.length;
    const rowAnchorId = undefined;
    const definitionUrl = navUtil.buildDefinitionUrl(definition.uuid, definition.name, rowAnchorId);
    const definitionLink = `<a href="${definitionUrl}">${definitionUrl}</a>`;
    const panelContent = `This is an exported view of ${definitionLink}. Visit the original form of this rubric for the best experience.`;
    const descriptionHtml = widgetUtil.markdownToHtml(definition.description);
    builder
      .panel(panelContent)
      .html(descriptionHtml);
    builder.image(`chart-0-summary.png`);

    if (hasLabels) {
      const labelNamesCsv = this.sentenceCsv(labelNames);
      builder.h3('Label breakdown')
      builder.p(`Each consideration in the assessment framework is labelled one or more of ${labelNamesCsv} to allow for comparisons ` +
        `from different points of view. The overall scores for each label are detailed in the following sections.`);

      const labelsToImageFileNames = {};
      labelsToImageFileNames['Ease'] = 'label-chart-1-ease.png';
      labelsToImageFileNames['Functionality'] = 'label-chart-2-functionality.png';
      labelsToImageFileNames['Trust'] = 'label-chart-3-trust.png';
      labelsToImageFileNames['Incentive'] = 'label-chart-4-incentive.png';

      labelNames.map(labelName => {
        const imageFileName = labelsToImageFileNames[labelName];
        if (imageFileName) {
          builder.h4(labelName);
          builder.image(imageFileName);
        }
        return undefined;
      });
    }

    if (assessments && assessments.length) {
      builder.h2(`Assessments`);
      const assessmentCountText = assessments.length === 1 ? `one assessment` : `${assessments.length} assessments`;
      const intro = `The rubric also shows ${assessmentCountText}. High level notes for each of these assessments 
      are displayed in subsections below.`;
      builder.p(intro);
      for (const assessment of assessments) {
        builder.h3(assessment.name);
        const assessmentNotesHtml = assessment.generalNotes ?
          widgetUtil.markdownToHtml(assessment.generalNotes) :
          `(no overview is provided for this assessment)`;
        builder.html(assessmentNotesHtml);
      }

      builder.h3(`Assessment scoring scale`);
      builder.p(`The scale used to score each consideration of the rubric is as follows:`);
      const scores = [0, 33, 67, 100];
      builder.html(`<ul>`);
      for (const score of scores) {
        builder.html(`<li>`);
        exportScoreMeta.appendScore(builder, score);
        builder.html(`</li>`);
      }
      builder.html(`</ul>`);
    }

    builder.h2('Area breakdown');
    builder.p(`This section provides a breakdown by area;- ${areaNamesCsv}.`);

    const areaNamesToImageFileNames = {};
    // areaNamesToImageFileNames['Documentation'] = 'chart-1-documentation.png';
    // areaNamesToImageFileNames['Dev loop'] = 'chart-2-dev-loop.png';
    // areaNamesToImageFileNames['User interface'] = 'chart-3-user-interface.png';
    // areaNamesToImageFileNames['Capabilities'] = 'chart-4-capabilities.png';
    // areaNamesToImageFileNames['Non functional features'] = 'chart-5-non-functional-features.png';
    // areaNamesToImageFileNames['Administration and monitoring'] = 'chart-6-admin-and-monitoring.png';
    // areaNamesToImageFileNames['Commerce'] = 'chart-7-commerce.png';
    // areaNamesToImageFileNames['Support'] = 'chart-8-support.png';

    rubricUtil.iterateRows(definition, (row, group, groupIndex, rowIndex) => {
      const areaName = row.name;
      builder.h3(areaName);
      this.appendStorageSummaryForArea(builder, definition, assessments, structureInfo, areaName, rowIndex);
      const imageFileName = areaNamesToImageFileNames[areaName];
      if (imageFileName) {
        builder.image(imageFileName);
      } else {
        builder.p('');
      }
      
      // builder.openExpand(`${areaName} assessment details`);
      this.appendStorageTableForArea(builder,
        definition, assessments,
        structureInfo, areaName, false, exportScoreMeta);
      // builder.closeExpand();
    });
    
    // builder.append(`\n  </tbody>`);
    // builder.append(`\n</table>`);

    return builder.build();
  }

  renderCharts = () => {
    return null;
  }

  render() {
    if (this.state.definition) {
      return (
        <div style={{marginTop: '50px', marginBottom: '50px'}}>
          <p>
            <b>{this.state.definition.name}</b>
          </p>
          {this.renderCharts()}
          <pre style={{width: '2000px', fontSize: '10px'}}>
            {this.state.storage}
          </pre>
        </div>
      );  
    } else {
      return null;
    }
  }

}
