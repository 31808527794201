import analytics from '../../shared/model/analytics/Analytics';
import storageDAO from './StorageDAO';

export default class NetworkDatabasePersistence {

  analyticsEntityName = 'network';

  constructor() {
    this.networkPath = 'network';
  }

  saveNetwork = (user, network) => {
    analytics.databaseWrite(this.analyticsEntityName);
    const key = this._buildNetworkKey(user);
    // return firebaseApp
    //   .getDatabase()
    //   .ref(key)
    //   .set(network);
    return storageDAO.setData(key, network);
  };

  getNetwork = (user) => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildNetworkKey(user);
    // return firebaseApp
    //   .getDatabase()
    //   .ref(key)
    //   .once('value')
    //   .then((snapshot) => {
    //     const network = snapshot.val();
    //     this._ensureNetworkStructureIsWellDefined(network);
    //     return network;
    //   });
    return storageDAO.getData(key);
  };

  _ensureNetworkStructureIsWellDefined = (network) => {
    if (network && !network.contacts) {
      network.contacts = [];
    }
  };

  _buildNetworkKey = (user) => {
    return this.networkPath + '/' + user.getId();
  };

}
