import currentNotifications from './CurrentNotifications';
import ListenerGroup from '../../shared/util/ListenerGroup';

class CurrentNotificationsRefreshState {

  listenerGroup = new ListenerGroup('CurrentNotificationsRefreshState');
  millisecondToRefreshHighlighting = 5 * 60000;
  millisecondsToAutoRefresh = 30 * 60000;

  constructor() {
    currentNotifications.registerListener(this.onCurrentNotificationsChange);
    this._scheduleTimers();
  }

  onCurrentNotificationsChange = () => {
    this._notifyListeners();
    this._scheduleTimers();
  };

  getLastRetrievalTime = () => {
    return currentNotifications.getLastRetrievalTime();
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _scheduleTimers = () => {
    setTimeout(this._notifyListeners, this.millisecondToRefreshHighlighting + 500);
    setTimeout(() => {
      this._checkForRefresh();
    }, this.millisecondsToAutoRefresh);
  };

  _checkForRefresh = () => {
    const lastRetrievalTime = this.getLastRetrievalTime();
    const currentTime = new Date().getTime();
    const millisecondsSinceLastRefresh = currentTime - lastRetrievalTime;
    // debugger;
    if (millisecondsSinceLastRefresh >= this.millisecondsToAutoRefresh) {
      currentNotifications.rebuildCurrentNotifications();
    } else {
      const delayToAutoRefresh = this.millisecondsToAutoRefresh - millisecondsSinceLastRefresh
      setTimeout(() => {
        this._checkForRefresh();
      }, delayToAutoRefresh);
    }
  };

  _notifyListeners = () => {
    this.listenerGroup.notifyListeners();
  };

}

export default new CurrentNotificationsRefreshState();
