import React, { PureComponent } from 'react';
import adg from '../../../commonbase/adg';
import PathBuilder from '../svg/PathBuilder';
import OpacityHelper from './OpacityHelper';

export const directionUpType = 'up';
export const directionRightType = 'right';
export const directionDownType = 'down';
export const directionLeftType = 'left';

export default class ArrowIcon extends PureComponent {

  constructor(props) {
    super(props);
    this.opacityHelper = new OpacityHelper(this);
  }

  render = () => {
    const width = this.props.width;
    const height = this.props.height;
    const paddingLeftRight = height / 4;
    const paddingTopBottom = height / 4;

    const mainFill = new PathBuilder('main-fill')
      .setPathColor(adg.adgPrimaryBlue)
      .setFill(adg.adgPrimaryBlue)
      .setPathWidth(2)
      .moveTo(0, 0)
      .lineTo(0, height)
      .lineTo(width, height)
      .lineTo(width, 0)
      .close()
      .render();
    let middleLine = null;
    let arrowTipLine = null;
    if (this.props.direction === directionUpType || this.props.direction === directionDownType) {
      const arrowTipLeftX = paddingLeftRight;
      const arrowTipRightX = width - paddingLeftRight;
      const arrowLineTopY = paddingTopBottom;
      const arrowLineBottomY = height - paddingTopBottom;
      const centreX = width / 2;
      const arrowTipHeight = height / 4;
      middleLine = new PathBuilder('middle-line')
        .setPathColor('#fff')
        .setPathWidth(2)
        .moveTo(centreX, arrowLineTopY)
        .lineTo(centreX, arrowLineBottomY)
        .render();
      if (this.props.direction === directionUpType) {
        const arrowTipBottomY = arrowLineTopY + arrowTipHeight;
        arrowTipLine =  new PathBuilder('arrow-tip-line')
          .setPathColor('#fff')
          .setPathWidth(2)
          .moveTo(arrowTipLeftX, arrowTipBottomY)
          .lineTo(centreX, arrowLineTopY)
          .lineTo(arrowTipRightX, arrowTipBottomY)
          .render();
      } else {
        const arrowTipTopY = arrowLineBottomY - arrowTipHeight;
        arrowTipLine = new PathBuilder('arrow-tip-line')
          .setPathColor('#fff')
          .setPathWidth(2)
          .moveTo(arrowTipLeftX, arrowTipTopY)
          .lineTo(centreX, arrowLineBottomY)
          .lineTo(arrowTipRightX, arrowTipTopY)
          .render();
      }
    } else if (this.props.direction === directionRightType || this.props.direction === directionLeftType) {
      const arrowTipTopY = paddingTopBottom;
      const arrowTipBottomY = height - paddingTopBottom;
      const arrowLineLeftX = paddingLeftRight;
      const arrowLineRightX = width - paddingLeftRight;
      const centreY = height / 2;
      const arrowTipWidth = width / 4;
      middleLine = new PathBuilder('middle-line')
        .setPathColor('#fff')
        .setPathWidth(2)
        .moveTo(arrowLineLeftX, centreY)
        .lineTo(arrowLineRightX, centreY)
        .render();
      if (this.props.direction === directionLeftType) {
        const arrowTipRightX = arrowLineLeftX + arrowTipWidth;
        arrowTipLine =  new PathBuilder('arrow-tip-line')
          .setPathColor('#fff')
          .setPathWidth(2)
          .moveTo(arrowTipRightX, arrowTipTopY)
          .lineTo(arrowLineLeftX, centreY)
          .lineTo(arrowTipRightX, arrowTipBottomY)
          .render();
      } else {
        const arrowTipLeftX = arrowLineRightX - arrowTipWidth;
        arrowTipLine = new PathBuilder('arrow-tip-line')
          .setPathColor('#fff')
          .setPathWidth(2)
          .moveTo(arrowTipLeftX, arrowTipTopY)
          .lineTo(arrowLineRightX, centreY)
          .lineTo(arrowTipLeftX, arrowTipBottomY)
          .render();
      }
    }
    return (
      <svg
        style={this.opacityHelper.buildStyle()}
        width={width}
        height={height}
        onMouseEnter={this.opacityHelper.onMouseEnter}
        onMouseLeave={this.opacityHelper.onMouseLeave}
        onClick={this.props.onClick}
      >
        {mainFill}
        {middleLine}
        {arrowTipLine}
      </svg>
    );
  }

}