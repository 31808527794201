// const colorRange = require('../color/colorRange');
const scoreTypes = require('./scoreTypes');
const scoreTypesToLegacyTypes = require('./scoreTypesToLegacyTypes');
const scoreTypesToDefinitions = require('./scoreTypesToDefinitions');

const scoreUtil = {};

scoreUtil.getScoreDefinitionFromType = function(scoreType) {
  // console.log(`Getting score definition of "${scoreType}"...`);
  let scoreDefinition = scoreTypesToDefinitions[scoreType];
  if (!scoreDefinition) {
    // console.log(`No score definition found for ${scoreType}.`);
    const currentTypes = Object.keys(scoreTypes);
    for (const currentType of currentTypes) {
      // console.log(`Checking legacy types of ${currentType}...`);
      const legacyTypes = scoreTypesToLegacyTypes[currentType];
      if (legacyTypes) {
        for (const legacyType of legacyTypes) {
          // console.log(' * getting score definition of legacy type ', legacyType);
          if (legacyType === scoreType) {
            scoreDefinition = scoreTypesToDefinitions[currentType];
            if (scoreDefinition) {
              // console.log(`Returning score definition ${scoreDefinition.name}...`);
              return scoreDefinition;
            }  
          }
        }  
      } else {
        throw new Error('Legacy score type mapping for "' + currentType + '" is not iterable.');
      }  
    }
  }
  // console.log(`Returning score definition? ${scoreDefinition ? `true (${scoreDefinition.name})` : 'false'}`);
  return scoreDefinition;
};

scoreUtil.denormaliseAssessmentItem = function(assessmentItem) {
  const denormalisedAssessmentItem = {
    innapplicable: assessmentItem.innapplicable,
    hasScore: false,
    scorePercent: -1,
    annotation: ''
  };
  if (assessmentItem) {
    if (assessmentItem.innapplicable) {
      denormalisedAssessmentItem.hasScore = false;
    } else {
      denormalisedAssessmentItem.annotation = assessmentItem.annotation ? assessmentItem.annotation.trim() : '';
      if (assessmentItem.score === undefined || isNaN(assessmentItem.score)) {
        if (assessmentItem.annotation.trim()) {
          denormalisedAssessmentItem.hasScore = true;
          denormalisedAssessmentItem.scorePercent = 0;
        } else {
          denormalisedAssessmentItem.hasScore = false;
        }
      } else {
        denormalisedAssessmentItem.hasScore = true;
        denormalisedAssessmentItem.scorePercent = assessmentItem.score;
      }
    }
  } else {
    denormalisedAssessmentItem.hasScore = false;
  }
  return denormalisedAssessmentItem;
};

scoreUtil.hasScore = function(assessmentInfo) {
  if (assessmentInfo) {
    if (assessmentInfo.innapplicable) {
      return false;
    } else {
      if (assessmentInfo.score === undefined || isNaN(assessmentInfo.score)) {
        if (assessmentInfo.annotation.trim()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  } else {
    return false;
  }
};

scoreUtil.emojiTextOfScore = function(scoreValue) {
  if (scoreValue <= 20) {
    return '😟';
  } else if (scoreValue <= 40) {
    return '😕';
  } else if (scoreValue <= 60) {
    return '😐';
  } else if (scoreValue <= 80) {
    return '🙂';
  } else if (scoreValue <= 100) {
    return '😀';
  }
  return '😟';
};



scoreUtil.computeColor = function(scorePercent, colorRange) {
  const factorFromStart = scorePercent / 100;
  const color = colorRange.computeColor(factorFromStart);
  return color;
};

module.exports = scoreUtil;
