import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import connectJavascriptApi from '../../integration/ConnectJavascriptApi';
import { ExternalLinkIcon } from '../../icon/NamedIcons';
import navUtil from '../../nav/NavUtil';
import ToolbarCenter from '../../../components/widget/toolbar/ToolbarCenter';
import ToolbarItem from '../../../components/widget/toolbar/ToolbarItem';

export default class DefinitionTitleLink extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      title: props.title,
      definitionUuid: props.definitionUuid,
      definitionName: props.definitionName
    };
  };

  onVisitDefinitionClick = (definitionUuid, definitionName) => {
    const url = navUtil.buildDefinitionUrl(definitionUuid, definitionName);
    window.top.location.href = url;
    const AP = connectJavascriptApi.getAP();
    if (AP) {
      // TODO: ??? future support from AP.navigator.go(); ???
    }
  };

  renderPlainTitle = (title) => {
    return (
      <h3>{title}</h3>
    );
  };

  render() {
    const url = navUtil.buildDefinitionUrl(this.state.definitionUuid, this.state.definitionName);
    return (
      <ToolbarCenter>
        <ToolbarItem>
          <a href={url}>
            {this.renderPlainTitle(this.state.title)}
          </a>
        </ToolbarItem>
        <ToolbarItem style={{ marginLeft: '0px' }}>
          <Button
            appearance="link"
            iconAfter={<ExternalLinkIcon />}
            isDisabled={false}
            onClick={() => this.onVisitDefinitionClick(this.state.definitionUuid, this.state.definitionName)}
          >
          </Button>
        </ToolbarItem>
      </ToolbarCenter>
    );
  };

}
