import DriveFolderBuilder from './DriveFolderBuilder';

class DriveDefinitions {

  defaultNewFolderName = 'New folder';
  rootFolderUuid = 'root-folder';
  rootFolderPath = '/' + this.rootFolderUuid;
  rootFolderName = 'My Drive';
  rootFolder = new DriveFolderBuilder()
    .setUuid(this.rootFolderUuid)
    .setIsRoot(true)
    .setParentPath('')
    .setName(this.rootFolderName)
    .build();
}

export default new DriveDefinitions();
