import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Switch, Route, withRouter } from 'react-router';
import { HashRouter } from 'react-router-dom';
import App from './App';
import appNavInfo from '../components/nav/AppNavInfo';
import WithUser from "../components/auth/WithUser";
import withPageViewTracker from '../../shared/model/analytics/WithPageViewTracker';

export default class MainRouter extends PureComponent {

  mounted = false;

  constructor() {
    super();
    this.state = {
      navOpenState: {
        isOpen: true,
        width: 304,
      }
    };
  }

  UNSAFE_componentWillMount() {
    // console.log('MainRouter.componentWillMount...');
  }

  componentDidMount() {
    // console.log('MainRouter.componentDidMount...');
    this.mounted = true;
  }

  componentWillUnmount() {
    // console.log('MainRouter.componentWillUnmount...');
    this.mounted = false;
  }

  getChildContext () {
    return {
      navOpenState: this.state.navOpenState,
    };
  }

  onNavResize = (navOpenState) => {
    this.setState({
      navOpenState,
    });
  };

  render() {
    // console.log('MainRouter.render...');
    const navInfo = appNavInfo.getNavItems();
    const appRoutes = navInfo.map((nav) => {
      const NavComponent = nav.component;
      const Component = withRouter(({ history, path, text }) => (
        <WithUser allowAnonymous={nav.allowAnonymous}>
          <NavComponent history={history}/>
        </WithUser>
      ));
      // if (nav.path.indexOf('/ass/') >= 0) {
      //   debugger;
      // }
      return (
        <Route
          exact={nav.exact}
          key={nav.url ? nav.url : nav.path}
          path={nav.url ? nav.url : nav.path}
          component={Component}
        />
      );
    });
    return (
      <HashRouter>
        <Switch>
          <Route
            component={
              withPageViewTracker(
                (props) => (
                  <App
                    onNavResize={this.onNavResize}
                    {...props}
                  >
                    {appRoutes}
                  </App>
                ),
                { /* additional attributes */ }
              )
            }
          />
        </Switch>
      </HashRouter>
    );
  }

}

MainRouter.childContextTypes = {
  navOpenState: PropTypes.object,
};
