import React from 'react';
import { CheckboxSelect } from '@atlaskit/select';
import rubricUtil from "../../../shared/model/rubric/RubricUtil";
import Tag from '@atlaskit/tag';
import util from "../../../shared/util/Util";

class RubricRenderingUtil {

  renderStatementLabels = (definition, statement, allowedLabelsOrAll?: boolean) => {
    const statementLabels = rubricUtil.getStatementLabels(definition, statement.uuid);
    return this.renderResolvedStatementLabels(definition, statementLabels, allowedLabelsOrAll)
  };

  renderResolvedStatementLabels = (definition, statementLabels, allowedLabelsOrAll?: boolean) => {
    const filteredLabels = allowedLabelsOrAll ?
      rubricUtil.filterLabels(statementLabels, allowedLabelsOrAll) : statementLabels;
    return this.renderFilteredStatementLabels(definition, filteredLabels);
  };

  renderFilteredStatementLabels = (definition, filteredLabels) => {
    const usedLabels = {};
    const renderedTags = filteredLabels.map((label) => {
      if (usedLabels[label]) {
        return null;
      } else {
        usedLabels[label] = true;
        return (
          <Tag
            key={label}
            isRemovable={false}
            text={label}
            color="greyLight"
            appearance="rounded"
          />
        );
      }
    });
    return (
      <React.Fragment>
        {renderedTags}
      </React.Fragment>
    );
  };

  renderDefinitionLabelsFilter = (definition, selectedLabels, onLabelsChange) => {
    return this._renderLabelsSelector(definition, selectedLabels, onLabelsChange);
  };

  renderStatementLabelsInput = (definition, statement, onStatementLabelsChange) => {
    const statementLabels = rubricUtil.getStatementLabels(definition, statement.uuid);
    const onLabelsChange = (selectedItems) => {onStatementLabelsChange(statement, selectedItems)};
    return this._renderLabelsSelector(definition, statementLabels, onLabelsChange);
  };

  _selectedItemsToLabels = (selectedItems) => {
    const labels: string[] = [];
    for (let i = 0; i < selectedItems.length; i++) {
      const selectedItem = selectedItems[i];
      const label = selectedItem.label;
      labels.push(label);
    }
    return labels;
  };

  _renderLabelsSelector = (definition, selectedLabels, onLabelsChange) => {
    const options: any[] = [];
    let defaultValue: any[] = [];
    const definitionLabels = rubricUtil.getDefinitionLabels(definition);
    for (let i = 0; i < definitionLabels.length; i++) {
      const label = definitionLabels[i];
      const option = {
        label: label,
        value: label
      };
      options.push(option);
      if (util.isInArray(label, selectedLabels)) {
        defaultValue.push(option);
      }
    }
    return (
      <CheckboxSelect
        className="checkbox-select"
        classNamePrefix="select"
        placeholder="Labels"
        options={options}
        value={defaultValue}
        onChange={(selectedItems) => {
          const labels = this._selectedItemsToLabels(selectedItems);
          onLabelsChange(labels);
        }}
      />
    );
  };

}

export default new RubricRenderingUtil();
