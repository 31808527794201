import React, { PureComponent } from "react";
import { ArrowDownIcon, ArrowUpIcon, TrashIcon } from '../../icon/NamedIcons';
import Button from '@atlaskit/button';
import InlineEdit from '@atlaskit/inline-edit';
import SafeInlineButton from '../../widget/SafeInlineButton';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import Tooltip from "@atlaskit/tooltip";
import { gridSize, fontSize } from '@atlaskit/theme';
import ToolbarLeft from '../../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

export default class CustomLevelsEditRow extends PureComponent {

  constructor(props) {
    super(props);
    this.iconButtonColumnWidth = '36px';
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      uuid: props.uuid,
      levelIndex: props.levelIndex,
      levelCount: props.levelCount,
      abbreviation: props.abbreviation,
      tooltip: props.tooltip
    };
  };

  onAbbreviationChange = (abbreviation) => {
    this.setState({
      abbreviation: abbreviation
    });
    const level = {
      uuid: this.state.uuid,
      label: abbreviation,
      tooltip: this.state.tooltip
    };
    this.props.onChange(level);
  };

  onTooltipChange = (tooltip) => {
    this.setState({
      tooltip: tooltip
    });
    const level = {
      uuid: this.state.uuid,
      label: this.state.abbreviation,
      tooltip: tooltip
    };
    this.props.onChange(level);
  };

  renderMoveButton = (icon, handler, handlerArgument) => {
    return (
      <Button
        iconBefore={icon}
        onClick={() => handler(handlerArgument)}
      >
      </Button>
    );
  };

  render() {
    const ReadViewContainer = styled.div`
      display: flex;
      font-size: ${fontSize()}px;
      line-height: ${(gridSize() * 2.5) / fontSize()};
      min-width: 50px;
      max-width: 100%;
      min-height: ${(gridSize() * 2.5) / fontSize()}em;
      padding: ${gridSize()}px ${gridSize() - 2}px;
      word-break: break-word
    `;
    return (
      <React.Fragment>
        <td style={{width: this.iconButtonColumnWidth, padding: '1px'}}>
          <SafeInlineButton
            normalStateLabel=""
            cancelLabel=""
            iconBefore={<TrashIcon/>}
            onConfirmation={() => this.props.onDeleteLevelByUuid(this.state.uuid)}
          />
        </td>
        <td style={{width: this.iconButtonColumnWidth, padding: '1px'}}>
          {this.state.levelIndex > 0 ? this.renderMoveButton(<ArrowUpIcon/>, this.props.onMoveLevelUpByUuid, this.state.uuid) : null}
        </td>
        <td style={{width: this.iconButtonColumnWidth, padding: '1px'}}>
          {this.state.levelIndex < (this.state.levelCount - 1) ? this.renderMoveButton(<ArrowDownIcon/>, this.props.onMoveLevelDownByUuid, this.state.uuid) : null}
        </td>
        <td style={{width: '50px'}}>
          <InlineEdit
            defaultValue={this.state.abbreviation}
            label={undefined}
            editView={fieldProps => <Textfield {...fieldProps} autoFocus />}
            readView={() => (
              <Tooltip content="Abbreviation or blank for an emoji">
                <ReadViewContainer>
                  {this.state.abbreviation || '[emoji]'}
                </ReadViewContainer>
              </Tooltip>
            )}
            onConfirm={value => this.onAbbreviationChange(value)}
          />
        </td>
        <td>
          <InlineEdit
            defaultValue={this.state.tooltip}
            label={undefined}
            editView={fieldProps => <Textfield {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {this.state.tooltip || '[none]'}
              </ReadViewContainer>
            )}
            onConfirm={value => this.onTooltipChange(value)}
          />
        </td>
      </React.Fragment>
    );
  }

  renderOLD() {
    return (
      <ToolbarLeft>
        <ToolbarItem style={{width: '40px'}}>
          {this.state.abbreviation}
        </ToolbarItem>
        <ToolbarItem>
          {this.state.tooltip}
        </ToolbarItem>
      </ToolbarLeft>
    );
  }

}
