import SelectOption from '../widget/SelectOption';
import { Discoverability } from './definition/Definition';

interface DiscoverabilityOption extends SelectOption {
  value: Discoverability,
}

class DiscoverabilityDefinitions {

  publicDiscoverabilityOption: DiscoverabilityOption = {
    // value: Discoverability.public,
    value: 'public',
    label: 'Public'
  };
  emailDomainDiscoverabilityOption: DiscoverabilityOption = {
    // value: Discoverability.emailDomain,
    value: 'emailDomain',
    label: 'Email domain'
  };
  linkDiscoverabilityOption: DiscoverabilityOption = {
    // value: Discoverability.link,
    value: 'link',
    label: 'Link'
  };
  privateDiscoverabilityOption: DiscoverabilityOption = {
    // value: Discoverability.private,
    value: 'private',
    label: 'Private'
  };
  options: DiscoverabilityOption[] = [
    this.publicDiscoverabilityOption,
    this.emailDomainDiscoverabilityOption,
    this.linkDiscoverabilityOption,
    this.privateDiscoverabilityOption
  ];
  publicDiscoverabilityType: Discoverability = 'public';
  emailDomainDiscoverabilityType: Discoverability = 'emailDomain';
  linkDiscoverabilityType: Discoverability = 'link';
  privateDiscoverabilityType: Discoverability = 'private';
  typesToNames = [];


  constructor() {
    for (const option of this.options) {
      this.typesToNames[option.value] = option.label;
    }
    // this.typesToNames[this.publicDiscoverabilityType] = 'Public';
    // this.typesToNames[this.emailDomainDiscoverabilityType] = 'Email domain';
    // this.typesToNames[this.linkDiscoverabilityType] = 'Link';
    // this.typesToNames[this.privateDiscoverabilityType] = 'Private';    
  }

  getDiscoverability = (discoverabilityTypeOrName: string): undefined | Discoverability => {
    for (const option of this.options) {
      if (option.value === discoverabilityTypeOrName || option.label === discoverabilityTypeOrName) {
        return option.value as Discoverability;
      }
    }
    return undefined;
  }

  getOptions = (): DiscoverabilityOption[] => {
    return this.options;
  }

  getDefaultOption = (): DiscoverabilityOption => {
    return this.publicDiscoverabilityOption;
  };

  isPrivate = (discoverabilityType) => {
    return !discoverabilityType || discoverabilityType === this.privateDiscoverabilityType;
  };

  // buildDiscoverabilityTypeEnumItemsContext = (currentType) => {
  //   const publicDiscoverabilityItem: SelectItem = {
  //     label: this.typesToNames[this.publicDiscoverabilityType],
  //     value: this.publicDiscoverabilityType
  //   };
  //   const emailDomainDiscoverabilityItem: SelectItem = {
  //     label: this.typesToNames[this.emailDomainDiscoverabilityType],
  //     value: this.emailDomainDiscoverabilityType
  //   };
  //   const linkItem: SelectItem = {
  //     label: this.typesToNames[this.linkDiscoverabilityType],
  //     value: this.linkDiscoverabilityType
  //   };
  //   const privateItem: SelectItem = {
  //     label: this.typesToNames[this.privateDiscoverabilityType],
  //     value: this.privateDiscoverabilityType
  //   };
  //   const items: SelectItem[] = [
  //     publicDiscoverabilityItem,
  //     emailDomainDiscoverabilityItem,
  //     linkItem,
  //     privateItem];
  //   const selectItems: SelectItemsGroup[] = [{
  //     items: items
  //   }];
  //   let selectedItem: undefined | SelectItem = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: selectItems,
  //     selectedItem: selectedItem
  //   };
  // };

  // getDefaultType = () => {
  //   return this.publicDiscoverabilityType;
  // };

}

export default new DiscoverabilityDefinitions();
