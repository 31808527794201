import React, { PureComponent } from "react";
import Label from '../../widget/Label';

export default class ScoreMarkersView extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      scoreMeta: props.scoreMeta
    };
  };

  render() {
    const markers = this.state.scoreMeta.getMarkers();
    const renderedMarkers = markers.map((marker, markerIndex) => {
      return (
        <tr key={'marker-' + markerIndex}>
          <td>
            {marker.score}
          </td>
          <td>
            {marker.label}
          </td>
        </tr>
      );
    });
    return (
      <div style={{marginLeft: '26px'}}>
        <table style={{width: 'auto'}}>
          <tbody style={{borderBottom: '0px'}}>
            <tr>
              <td>
                <Label text="Score" paddingTop="0px"/>
              </td>
              <td>
                <Label text="Label" paddingTop="0px"/>
              </td>
            </tr>
            {renderedMarkers}
          </tbody>
        </table>
      </div>
    );
  }

}
