import React, { PureComponent } from "react";
import adg from '../../../../commonbase/adg';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import util from '../../../../shared/util/Util';
import widgetUtil from '../../../components/widget/WidgetUtil';

export default class ScoreSvg extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  componentDidUpdate() {
    widgetUtil.scheduleTooltipRebuild();
  }

  buildStateFromProps = (props) => {
    if (!props.scoreMeta) {
      throw new Error('props.scoreMeta not defined')
    }
    let text = undefined;
    let tooltip = undefined;
    const scoreMeta = props.scoreMeta;
    const scorePercent = scoreUtil.assessmentItemToScore(props.assessmentItem);
    const radius = props.radius ? props.radius : 8;
    const scoreIsValid = scoreUtil.hasScore(props.assessmentItem);
    const score = scoreIsValid ? scoreMeta.percentToScore(scorePercent) : undefined;
    const color = props.color ? props.color : scoreIsValid ? scoreMeta.assessmentItemToColour(props.assessmentItem) : adg.adgGray;
    if (scorePercent === undefined) {
      text = '-';
    } else {
      const scoreTextInfo = scoreMeta.computeScoreTextInfo(scorePercent);
      text = scoreTextInfo.label;
      tooltip = scoreTextInfo.tooltip;
    }
    return {
      tooltip: tooltip,
      scoreMeta: scoreMeta,
      scorePercent: scorePercent,
      score: score,
      assessmentItem: props.assessmentItem,
      centreX: props.centreX === undefined ? radius : props.centreX,
      centreY: props.centreY === undefined ? radius : props.centreY,
      scoreIsValid: scoreIsValid,
      radius: radius,
      color: color,
      text: text
    };
  };

  onClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    widgetUtil.scheduleTooltipRebuild();
    const radius = this.state.radius;
    const className = this.props.onClick ? 'clickable' : undefined;
    let fontSize = 1.2 * radius;
    let textFitLength = undefined;
    let letterSpacing = undefined;
    const minScalingTextLength = 1;
    const maxScalingTextLength = 6;
    const text = this.state.text;
    let fontWeight = undefined;
    if (text.length > minScalingTextLength) {
      const charactersToScale = Math.min(text.length, maxScalingTextLength) - minScalingTextLength;
      const maxScale = 1.0;
      const minScale = 0.5;
      const scaleDownFactor = charactersToScale / (maxScalingTextLength - minScalingTextLength);
      let fontScaleFactor = util.interpolate(maxScale, minScale, scaleDownFactor);
      // increase font size based on radius for some reason...
      fontSize = fontScaleFactor * fontSize;
      const maxFontSizeBeforeScalingIncrease = 13;
      const minFontSizeBeforeScalingIncrease = 6;
      const smallFontScalingRange = maxFontSizeBeforeScalingIncrease - minFontSizeBeforeScalingIncrease;
      if (fontSize < maxFontSizeBeforeScalingIncrease) {
        let fontDiffToMax = maxFontSizeBeforeScalingIncrease - fontSize;
        if (fontDiffToMax > smallFontScalingRange) {
          fontDiffToMax = smallFontScalingRange;
        }
        const increaseFactor = util.interpolate(1.5, 1, fontDiffToMax / smallFontScalingRange);
        fontSize = increaseFactor * fontSize;
      }
      const normalLetterSpacing = 0;
      const mostCondensedLetterSpacing = -0.2;
      letterSpacing = util.interpolate(normalLetterSpacing, mostCondensedLetterSpacing, scaleDownFactor) + 'rem';
      fontWeight = undefined;
    };

    const foregroundColor = '#fff';
    const renderedEmoji = this.state.scoreMeta.shouldRenderEmojiForAssessmentItem(this.state.assessmentItem) ?
      this.state.scoreMeta.renderEmoji(
        this.state.assessmentItem,
        this.state.radius,
        this.state.centreX,
        this.state.centreY,
        foregroundColor,
        this.state.color) : null;
    const renderedText = renderedEmoji ? null : (
      <text
        className={className}
        textAnchor="middle"
        dominantBaseline="central"
        textLength={textFitLength}
        x={this.state.centreX}
        y={this.state.centreY}
        style={{fill: foregroundColor, fontSize: fontSize, fontWeight: fontWeight, letterSpacing: letterSpacing}}
        onClick={this.onClick}
      >
        {this.state.text}
      </text>
    );
    return (
      <React.Fragment>
        <g 
          className={className}
          data-tip={this.state.tooltip}
          onClick={this.onClick}
        >
          <circle
            className={className}
            cx={this.state.centreX}
            cy={this.state.centreY}
            r={radius}
            fill={this.state.color}
            onClick={this.onClick}
          />
          {renderedEmoji}
          {renderedText}
        </g>
      </React.Fragment>
    );
  }

}
