import persistenceFactory from "./PersistenceFactory";

export class SubscriptionsDAO {

  constructor() {
    this.subscriptionsPersistence = persistenceFactory.subscriptionsPersistence;
  }

  addSubscription = (planType, userId, paymentProviderToken, paymentProviderType) => {
    return this.subscriptionsPersistence.addSubscription(
      planType, userId, paymentProviderToken, paymentProviderType);
  };

  getSubscription = (userId) => {
    return this.subscriptionsPersistence.getSubscription(userId);
  };

  addTrialInfo = (userId, expiryTimestamp) => {
    return this.subscriptionsPersistence.addTrialInfo(userId, expiryTimestamp);
  };

  getTrialInfo = (userId) => {
    return this.subscriptionsPersistence.getTrialInfo(userId);
  };

}

export default new SubscriptionsDAO();
