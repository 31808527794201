import React, { PureComponent } from 'react';
import scoreMetaDefinitions from '../../../../shared/model/rubric/score/ScoreMetaDefinitions';
import SelectOption from '../../../../shared/model/widget/SelectOption';
import SingleSelect from '../../widget/SingleSelect';

interface Props {
  scoreMetaType: string
  onChange: (scoreMetaType: string) => void
}

interface State {
  dirty?: boolean
  scoreMetaType: string
}

export default class ScoreMetaSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      scoreMetaType: props.scoreMetaType === undefined ? this.state.scoreMetaType : props.scoreMetaType
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      scoreMetaType: selection.value
    });
    this.props.onChange(selection.value);
  };

  render() {
    const options: SelectOption[] = scoreMetaDefinitions.getOptions();
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === this.state.scoreMetaType) {
        selectedOption = option;
      }
    }
    return (
      <div style={{width: '400px'}}>
        <SingleSelect
          label="Scoring"
          options={options}
          defaultSelected={selectedOption}
          placeholder={undefined}
          noMatchesFound={undefined}
          hasAutocomplete
          appearance="default"
          onSelected={this.onChange}
        />
      </div>
    );
  };

}
