import React, { PureComponent } from 'react';
import Spinner from '@atlaskit/spinner';

interface Props {
  visible: boolean
}

interface State {

}

export default class RoobrickSpinner extends PureComponent<Props, State> {

  render() {
    if (this.props.visible) {
      return (
        <div
          style={{
            width: '30px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Spinner
            size="medium"
            delay={0}
          />
        </div>
      );
    } else {
      return null;
    }
  }

}