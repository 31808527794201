import analytics from '../../shared/model/analytics/Analytics';
import firebaseApp from '../firebase/FirebaseApp';

export default class PreferencesDatabasePersistence {

  analyticsEntityName = 'user-preferences';

  constructor() {
    this.preferencesPath = 'user-preferences';
  }

  setLastSelectedAssessmentUuid = (definitionUuid, user, assessmentUuid) => {
    const key = this._buildDefinitionPreferences(definitionUuid, user) + '/lastSelectedAssessmentUuid';
    return this._setDefinitionPreferenceNode(key, assessmentUuid)
  };

  setVisibleAssessmentSummaries = (definitionUuid, user, visibleAssessmentSummaries) => {
    const key = this._buildDefinitionPreferences(definitionUuid, user) + '/visibleAssessmentSummaries';
    return this._setDefinitionPreferenceNode(key, visibleAssessmentSummaries)
  };

  getDefinitionPreferences = (definitionUuid, user, defaultDefinitionPreferences) => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildDefinitionPreferences(definitionUuid, user);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const snapShotVal = snapshot.val();
        const value = snapShotVal || defaultDefinitionPreferences;
        return value;
      });
  };

  removeDefinitionPreference = (definitionUuid, user) => {
    const key = this._buildDefinitionPreferences(definitionUuid, user);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .remove();
  };

  setDefinitionPreferences = (definitionUuid, user, definitionPreferences) => {
    const key = this._buildDefinitionPreferences(definitionUuid, user);
    return this._setDefinitionPreferenceNode(key, definitionPreferences);
  };

  _setDefinitionPreferenceNode = (key, node) => {
    if (node) {
      analytics.databaseWrite(this.analyticsEntityName);
      return firebaseApp
        .getDatabase()
        .ref(key)
        .set(node);
    } else {
      analytics.databaseDelete(this.analyticsEntityName);
      return firebaseApp
        .getDatabase()
        .ref(key)
        .remove();
    }
  };

  _buildDefinitionPreferences = (definitionUuid, user) => {
    return this.preferencesPath + '/' + user.getId() + '/definition-' + definitionUuid;
  };

  getReportPreferences = (user, defaultReportPreferences) => {
    const key = this._buildReportPreferences(user);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const snapShotVal = snapshot.val();
        const value = snapShotVal || defaultReportPreferences;
        return value;
      });
  };

  setReportPreferences = (user, reportPreferences) => {
    const key = this._buildReportPreferences(user);
    return this._setDefinitionPreferenceNode(key, reportPreferences)
  };

  _buildReportPreferences = (user) => {
    return this.preferencesPath + '/' + user.getId() + '/report';
  };

  getEmailPreference = (user, defaultEmailPreference) => {
    const key = this._buildEmailPreference(user);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const snapshotVal = snapshot.val();
        return snapshotVal ? snapshotVal : defaultEmailPreference;
      });
  };

  setEmailPreference = (user, reportPreferences) => {
    const key = this._buildEmailPreference(user);
    return this._setDefinitionPreferenceNode(key, reportPreferences)
  };

  _buildEmailPreference = (user) => {
    return this.preferencesPath + '/' + user.getId() + '/email';
  };

  setPreference = (user, key, preference) => {
    const storageKey = this._buildPreferenceKey(user, key);
    return this._setDefinitionPreferenceNode(storageKey, preference)
  };

  getPreference = (user, key, defaultPreference) => {
    const storageKey = this._buildPreferenceKey(user, key);
    return firebaseApp
      .getDatabase()
      .ref(storageKey)
      .once('value')
      .then((snapshot) => {
        const snapShotVal = snapshot.val();
        const value = snapShotVal || defaultPreference;
        return value;
      });
  };

  _buildPreferenceKey = (user, key) => {
    return this.preferencesPath + '/' + user.getId() + '/' + key;
  };

  // getPreferences = (user) => {
  //   const storageKey = this.preferencesPath + '/' + user.getId();
  //   return firebaseApp
  //     .getDatabase()
  //     .ref(storageKey)
  //     .once('value')
  //     .then((snapshot) => {
  //       const snapShotVal = snapshot.val();
  //       const value = snapShotVal || {};
  //       debugger;
  //       return value;
  //     });
  // };

};
