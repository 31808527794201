import assessmentDeletionNotifier from '../../shared/model/rubric/AssessmentDeletionNotifier';
import permissionUtil from '../../shared/model/auth/PermissionUtil';
import persistenceFactory from "./PersistenceFactory";
import session from '../../shared/model/auth/Session';

class NotificationDAO {

  constructor() {
    this.notificationsPersistence = persistenceFactory.notificationsPersistence;
    assessmentDeletionNotifier.registerListener(this._onAssessmentDeleted);
  }

  _onAssessmentDeleted = (assessmentUuid, definitionUuid) => {
    this.deleteAssessmentNotifications(assessmentUuid, definitionUuid);
  };

  saveNotification = (notification) => {
    if (!permissionUtil.canWriteNotification(notification)) {
      throw new Error('No permission to save shared entity ' + notification.title);
    }
    return this.notificationsPersistence.saveNotification(notification);
  };

  getSharedAssessments = (definitionUuid, shareState) => {
    const user = session.getCurrentUser();
    return this.notificationsPersistence.getSharedAssessments(user, definitionUuid, shareState);
  };

  getCurrentUserNotifications = () => {
    const user = session.getCurrentUser();
    return this.notificationsPersistence.getCurrentUserNotifications(user);
  };

  deleteAssessmentNotifications = (assessmentUuid, definitionUuid) => {
    return this.notificationsPersistence.deleteAssessmentNotifications(assessmentUuid, definitionUuid);
  };

  doesNotificationExist = (fromUserId, targetUserEmail, entityType, entityUuid) => {
    return this.notificationsPersistence.doesNotificationExist(fromUserId, targetUserEmail, entityType, entityUuid);
  };

  searchNotificationsByTypeAndTitle = (entityType, title) => {
    return this.notificationsPersistence.searchNotificationsByTypeAndTitle(entityType, title)
  };

}

export default new NotificationDAO();
