import React, { PureComponent } from 'react';
import aspectGroupingDefinitions from "../../../../shared/model/rubric/AspectGroupingDefinitions";
import Definition from '../../../../shared/model/rubric/definition/Definition';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';

interface Props {
  definition: Definition
  aspectGrouping?: string
  text?: string
}

interface State {
  title?: string;
}

export default class ChartLabel extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    let title = props.text;
    if (!title) {
      let aspectPart = 'Aspect';
      let statementPart = 'Statement';
      if (props.definition) {
        aspectPart = rubricUtil.getAspectHeader(props.definition);
        statementPart = rubricUtil.getStatementLabel(props.definition);
      }
      if (props.aspectGrouping === aspectGroupingDefinitions.summaryAspectGroupingType) {
        title = 'Assessment Summary';
      } else if (props.aspectGrouping === aspectGroupingDefinitions.noneAspectGroupingType) {
        title = `Assessment Scores by ${aspectPart}`;
      } else if (props.aspectGrouping === aspectGroupingDefinitions.statementAspectGroupingType) {
        title = `Assessment Scores by ${statementPart}`;
      } else if (props.aspectGrouping === aspectGroupingDefinitions.labelAspectGroupingType) {
        title = 'Assessment Scores by Label';
      } else if (props.aspectGrouping === aspectGroupingDefinitions.pillarAspectGroupingType) {
        title = 'Assessment Scores by Pillar';
      } else {
        throw new Error('Unexpected aspect grouping: ' + props.aspectGrouping);
      }
      }
    return {
      title: title
    };
  };

  render() {
    return (
      <div className="centredContent image-caption">
        {this.state.title}
      </div>
    );
  };

}
