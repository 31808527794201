import React, { PureComponent } from 'react';
import discoverabilityDefinitions from '../../../shared/model/rubric/DiscoverabilityDefinitions';
import SelectOption from '../../../shared/model/widget/SelectOption';
import SingleSelect from '../widget/SingleSelect';
import ToolbarItem from '../widget/toolbar/ToolbarItem';
import ToolbarLeft from '../widget/toolbar/ToolbarLeft';

interface Props {
  discoverabilityType: string
  includeExplanation: boolean
  onChange: (discoverabilityType: string) => void
}

interface State {
  dirty?: boolean
  discoverabilityType: string
}

export default class DiscoverabilitySelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      discoverabilityType: props.discoverabilityType === undefined ? this.state.discoverabilityType : props.discoverabilityType
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      discoverabilityType: selection.value
    });
    this.props.onChange(selection.value);
  };

  renderDiscoverabilityExplanation = () => {
    let message: string = '';
    if (this.state.discoverabilityType === discoverabilityDefinitions.privateDiscoverabilityType) {
      message = `Only you can view this rubric and it won't appear in search results..`;
    } else if (this.state.discoverabilityType === discoverabilityDefinitions.linkDiscoverabilityType) {
      message = `Anyone with the link can view this rubric, but it won't appear in search results.`;
    } else if (this.state.discoverabilityType === discoverabilityDefinitions.emailDomainDiscoverabilityType) {
      message = `Only people who are logged in with an account with an email address with the same domain as yours can view this rubric. The rubric won't appear in search results.`;
    } else if (this.state.discoverabilityType === discoverabilityDefinitions.publicDiscoverabilityType) {
      message = `Anyone can view this rubric and it may appear in search results.`;
    }
    if (message) {
      return (
        <span>
          {message}
        </span>
      );
    } else {
      return null;
    }
  }

  renderSelection = () => {
    const options: SelectOption[] = discoverabilityDefinitions.getOptions();
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === this.state.discoverabilityType) {
        selectedOption = option;
      }
    }
    return (
      <div style={{ width: '160px' }}>
        <SingleSelect
          label="Discoverability"
          options={options}
          defaultSelected={selectedOption}
          placeholder={undefined}
          noMatchesFound={undefined}
          hasAutocomplete
          appearance="default"
          onSelected={this.onChange}
        />
      </div>
    );
  }

  render() {
    if (this.props.includeExplanation) {
      return (
        <ToolbarLeft style={{ alignItems: 'end' }}>
          <ToolbarItem style={{ flexGrow: 0, flexShrink: 0}}>
            {this.renderSelection()}
          </ToolbarItem>
          <ToolbarItem style={{ flexGrow: 10, flexShrink: 10 }}>
            {this.renderDiscoverabilityExplanation()}
          </ToolbarItem>
        </ToolbarLeft>
      );
    } else {
      return this.renderSelection();
    }
  };

}
