import analytics from '../../shared/model/analytics/Analytics';
import dataStats from './DataStats';
import firebaseApp from '../firebase/FirebaseApp';

export default class MomentFirestorePersistence {

  analyticsEntityName = 'moment';

  constructor() {
    this.parentMomentsCollectionKey = 'momentscollections';
    this.userMomentsCollectionKey = 'moments';
  }

  saveMomentToCollection = (user, moment) => {
    const userId = user.getId();
    dataStats.logFirestoreReadWrite();
    analytics.databaseWrite(this.analyticsEntityName);
    return firebaseApp
      .getFirestore()
      .collection(this.parentMomentsCollectionKey)
      .doc(userId)
      .collection(this.userMomentsCollectionKey)
      .doc(moment.uuid)
      .set(moment)
      .then(() => {
        return moment;
      })
      .catch((error) => {
        console.error("Error writing moment: ", error);
        // debugger;
      });
  };

  saveMomentToAssessmentCollection = (user, assessmentUuid, moment) => {
    const assessmentsCollectionKey = 'assessments';
    dataStats.logFirestoreReadWrite();
    analytics.databaseWrite(this.analyticsEntityName);
    return firebaseApp
      .getFirestore()
      .collection(assessmentsCollectionKey)
      .doc(assessmentUuid)
      .collection(this.userMomentsCollectionKey)
      .doc(moment.uuid)
      .set(moment)
      .then(() => {
        return moment;
      })
    ;
  };

}
