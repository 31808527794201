import React, { PureComponent } from 'react';
import Label from '../widget/Label';
import networkGraph from './NetworkGraph';
import Select from '@atlaskit/select';
import networkUtil from '../../../shared/model/network/NetworkUtil';
import SelectOption from '../../../shared/model/widget/SelectOption';
import Contact from '../../../shared/model/network/Contact';

interface Props {
  label?: string
  hideLabel: boolean
  selectedContactEmail: undefined | string
  context: any
  allowContact?: (contact: Contact, context: any) => boolean
  onChange: (contact: Contact, context: any) => void
}

interface State {
  hideLabel: boolean
  dirty?: boolean
  selectedContactEmail: undefined | string
}

export default class ContactPicker extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      hideLabel: props.hideLabel,
      selectedContactEmail: props.selectedContactEmail
    };
  };

  buildSelectContext = () => {
    const items: any[] = [];
    const selectContext: any = {
      items: items,
      selectedItem: undefined
    };
    networkGraph.iterateContacts((contact: Contact) => {
      if (!this.props.allowContact || this.props.allowContact(contact, this.props.context)) {
        const item = {
          content: contact.name,
          label: contact.name,
          value: contact.email
        };
        const selected =
          this.state.selectedContactEmail &&
          networkUtil.equalEmails(this.state.selectedContactEmail, contact.email);
        if (selected) {
          selectContext.selectedItem = item;
        }
        items.push(item);
      }
    });
    return selectContext;
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      selectedContactEmail: selection.value
    });
    const contact = networkGraph.getContactByEmail(selection.value);
    if (contact) {
      this.props.onChange(contact, this.props.context);
    }
  };

  renderLabel = () => {
    return (
      <Label text={this.props.label ? this.props.label : 'Contact'}/>
    );
  };

  render() {
    const selectContext = this.buildSelectContext();
    return (
      <React.Fragment>
        {this.props.hideLabel ? null: this.renderLabel()}
        <div style={{minWidth: '200px'}}>
          <Select
            className="single-select"
            classNamePrefix="react-select"
            placeholder="Choose a contact"
            options={selectContext.items}
            defaultValue={selectContext.selectedItem}
            onChange={this.onChange}
          />
        </div>
      </React.Fragment>
    );
  };

  // xxrender() {
  //   const selectContext = this.buildSelectContext();
  //   return (
  //     <div>
  //       <SingleSelect
  //         label={this.props.hideLabel ? undefined: 'Contact'}
  //         items={selectContext.items}
  //         defaultSelected={selectContext.selectedItem}
  //         placeholder={undefined}
  //         noMatchesFound={undefined}
  //         hasAutocomplete
  //         appearance="default"
  //         onSelected={this.onChange}
  //       />
  //     </div>
  //   );
  // };

}
