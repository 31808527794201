import DefinitionPreferencesUpdateContext from '../model/rubric/preference/DefinitionPreferencesUpdateContext';
import AssessmentContext from '../model/rubric/score/AssessmentContext';
import ListenerGroup from '../util/ListenerGroup';

export class Actions {

  removeDefinitionReferencesActionId = 'remove-definition-references';
  removeAssessmentReferencesActionId = 'remove-assessment-references';
  addFlagActionId = 'add-flag';
  removeFlagActionId = 'remove-flag';
  visitBoardActionId = 'visit-board';
  visitDefinitionActionId = 'visit-definition';
  viewAssessmentActionId = 'view-assessment';
  definitionChangedActionId = 'definition-changed';
  definitionRenamedActionId = 'definition-name-changed';
  assessmentChangeActionId = 'assessment-changed';
  assessmentRenamedActionId = 'assessment-name-changed';
  assessmentLeaveActionId = 'assessment-leave';
  momentSavedActionId = 'moment-saved';
  newRubricActionId = 'new-rubric';
  launchNewDecisionWizardActionId = 'launch-new-decision-wizard';
  launchNewCompetitiveAnalysisWizardActionId = 'launch-new-competitive-analysis-wizard';
  visitDashboardActionId = 'visit-dashboard';
  visitDriveActionId = 'visit-drive';
  collapseDrawerActionId = 'collapse-drawer';
  sidebarVisibilityChangeActionId = 'sidebar-visibility-change';
  definitionPreferencesUpdateContextId = 'definition-preferences-update';
  scrollToElementActionId = 'scroll-to-element';
  onMainBodyClickActionId = 'main-body-click';
  onNavBarClickActionId = 'nav-bar-click';

  listenerGroup = new ListenerGroup('Actions');

  removeDefinitionReferences = (definitionUuid) => {
    this._notifyListeners(this.removeDefinitionReferencesActionId, definitionUuid);
  };

  removeAssessmentReferences = (assessmentUuid) => {
    this._notifyListeners(this.removeAssessmentReferencesActionId, assessmentUuid);
  };

  addFlag = (flag) => {
    this._notifyListeners(this.addFlagActionId, flag);
  };

  removeFlag = (flagId) => {
    this._notifyListeners(this.removeFlagActionId, flagId);
  };

  onVisitDefinition = (definition) => {
    this._notifyListeners(this.visitDefinitionActionId, definition);
  };

  onViewAssessment = (assessmentContext: AssessmentContext) => {
    this._notifyListeners(this.viewAssessmentActionId, assessmentContext);
  };

  onDefinitionChanged = (definition) => {
    this._notifyListeners(this.definitionChangedActionId, definition);
  };

  onDefinitionRenamed = (definition) => {
    this._notifyListeners(this.definitionRenamedActionId, definition);
  };

  onAssessmentRenamed = (assessmentContext) => {
    this._notifyListeners(this.assessmentRenamedActionId, assessmentContext);
  };

  onAssessmentChange = (assessmentContext) => {
    this._notifyListeners(this.assessmentChangeActionId, assessmentContext);
  };

  onAssessmentLeave = (assessmentContext) => {
    this._notifyListeners(this.assessmentLeaveActionId, assessmentContext);
  };

  onMomentSaved = () => {
    this._notifyListeners(this.momentSavedActionId);
  };

  onNewRubric = () => {
    this._notifyListeners(this.newRubricActionId);
  };

  launchNewDecisionWizard = () => {
    this._notifyListeners(this.launchNewDecisionWizardActionId);
  };

  launchNewCompetitiveAnalysisWizard = () => {
    this._notifyListeners(this.launchNewCompetitiveAnalysisWizardActionId);
  };

  onCollapseDrawer = () => {
    this._notifyListeners(this.collapseDrawerActionId);
  };

  onVisitBoardPage = () => {
    this._notifyListeners(this.visitBoardActionId);
  };

  onVisitDashboardPage = () => {
    this._notifyListeners(this.visitDashboardActionId);
  };

  onVisitDrivePage = () => {
    this._notifyListeners(this.visitDriveActionId);
  };

  onSidebarVisibilityChange = () => {
    this._notifyListeners(this.sidebarVisibilityChangeActionId);
  };

  onDefinitionPreferencesUpdate = (definitionPreferencesUpdateContext: DefinitionPreferencesUpdateContext) => {
    this._notifyListeners(this.definitionPreferencesUpdateContextId, definitionPreferencesUpdateContext);
  };

  onScrollToElement = (rowUuid) => {
    this._notifyListeners(this.scrollToElementActionId, rowUuid);
  };

  onMainBodyClick = () => {
    this._notifyListeners(this.onMainBodyClickActionId);
  }

  onNavBarClick = (action: any) => {
    this._notifyListeners(this.onNavBarClickActionId);
  }

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = (actionId: string, context?: any) => {
    this.listenerGroup.notifyListeners(actionId, context);
  };

}

export default new Actions();
