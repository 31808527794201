
import React, { FormEvent, PureComponent } from "react";
import Button from '@atlaskit/button';
import firebaseApp from '../../../backend/firebase/FirebaseApp';
import notificationUtil from '../../../shared/model/email/NotificationUtil';
import SingleSelect from '../widget/SingleSelect';
import SelectOption from '../../../shared/model/widget/SelectOption';
import FormlessTextField from '../widget/FormlessTextField';

interface Props {

}

interface State {
  selectedBlogId?: string
  blogIdsToTitles?: string
  email: string
}

export default class AdminView extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  componentDidMount() {
    this.getBlogIdsToTitles();
  }

  buildStateFromProps = (props: Props): State => {
    return {
      email: '',
    };
  };

  getBlogIdsToTitles = () => {
    const functions = firebaseApp.getFunctions();
    const payload = {
      callType: 'getBlogIdsToTitles'
    };
    return functions.httpsCallable('consolidatedCallHandler')(payload)
      .then((result) => {
        // debugger;
        this.setState({
          blogIdsToTitles: result.data
        });
        return true;
      })
      .catch((error) => {
        // debugger;
        console.warn(error);
        return false;
      });
  };

  onSendBlogEmailClick = () => {
    if (this.state.selectedBlogId) {
      // const blogId = 'b523';
      notificationUtil.sendBlogNotification(this.state.selectedBlogId, this.state.email);
    }
  };

  onBlogSelectionChange = (selection: SelectOption) => {
    this.setState({
      selectedBlogId: selection.value
    });
  };

  renderBlogIdsToTitles = () => {
    if (!this.state.blogIdsToTitles) {
      return null;
    }
    return (
      <pre>{(JSON.stringify(this.state.blogIdsToTitles, null, 2))}</pre>
    );
  };

  renderBlogSelector = () => {
    if (!this.state.blogIdsToTitles) {
      return null;
    }
    const options: SelectOption[] = [];
    const ids = Object.keys(this.state.blogIdsToTitles);
    for (const id of ids) {
      const title = this.state.blogIdsToTitles[id];
      options.push({
        label: title,
        value: id
      });
    }
    // console.log('Blog selection context:', itemsContext);
    return (
      <SingleSelect
        label="Blog"
        options={options}
        defaultSelected={undefined}
        placeholder={undefined}
        noMatchesFound={undefined}
        hasAutocomplete
        appearance="default"
        onSelected={this.onBlogSelectionChange}
      />
    );
  };

  onEmailChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      email: event.currentTarget.value
    });
  };

  renderEmailField = () => {
    return (
      <FormlessTextField
        name="email"
        label="Email"
        value={this.state.email}
        onChange={this.onEmailChange}
      />
    );
  };

  renderSendBlogButton = () => {
    if (this.state.selectedBlogId === null) {
      return null;
    }
    return (
      <Button
        appearance="primary"
        onClick={this.onSendBlogEmailClick}
      >
        Send blog email
      </Button>
    );
  };

  render() {
    return (
      <div className="newMainSection">
        <hr/>
        <div className="newMainSection">
          {this.renderBlogIdsToTitles()}
        </div>
        <div className="newMainSection">
          {this.renderBlogSelector()}
        </div>
        <div className="newMainSection">
          {this.renderEmailField()}
        </div>
        <div className="newMainSection">
          {this.renderSendBlogButton()}
        </div>
      </div>
    );
  }

}
