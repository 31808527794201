
const util = {};

util.htmlToText = function(text) {
  text = text.replace(/<style([\s\S]*?)<\/style>/gi, '');
  text = text.replace(/<script([\s\S]*?)<\/script>/gi, '');
  text = text.replace(/<\/div>/ig, '\n');
  text = text.replace(/<\/li>/ig, '\n');
  text = text.replace(/<li>/ig, '  *  ');
  text = text.replace(/<\/ul>/ig, '\n');
  text = text.replace(/<\/p>/ig, '\n');
  // text = text.replace(/<br\s*[\/]?>/gi, "\n");
  text = text.replace(/<br\s*[/]?>/gi, "\n");
  text = text.replace(/<[^>]+>/ig, '');
  return text;
};

util.csvToArray = function(csv, forceUniqueness) {
  if (csv && csv.length) {
    const consumedItems = {};
    const items = [];
    const splitItems = csv.split(',');
    for (const splitItem of splitItems) {
      const item = splitItem.trim();
      if (item) {
        if (!forceUniqueness || !consumedItems[item]) {
          items.push(item);
          consumedItems[item] = true;
        }
      }
    }
    return items;
  } else {
    return [];
  }
};

util.arrayToCsv = function(items) {
  if (items && items.length) {
    return items.join(',');
  } else {
    return '';
  }
};

module.exports = util;
