import React, { PureComponent } from 'react';
import aspectGroupingDefinitions from '../../../../shared/model/rubric/AspectGroupingDefinitions';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import SelectOption from '../../../../shared/model/widget/SelectOption';
import SingleSelect from '../../widget/SingleSelect';

interface Props {
  definition: Definition
  aspectGroupingType?: string
  isGroupingAllowed: (type: string) => boolean
  onChange: (selectedOption: SelectOption) => void
}

interface State {
  aspectGroupingType?: string
  dirty?: boolean
}

export default class AspectGroupingSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      aspectGroupingType: props.aspectGroupingType === undefined ? this.state.aspectGroupingType : props.aspectGroupingType
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      aspectGroupingType: selection.value
    });
    this.props.onChange(selection);
  };

  render() {
    // const label = "Aspect grouping";
    const label = undefined;
    const options = aspectGroupingDefinitions.buildOptions(this.props.isGroupingAllowed, this.props.definition);
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === this.state.aspectGroupingType) {
        selectedOption = option;
      }
    }
    return (
      <div>
        <SingleSelect
          label={label}
          options={options}
          defaultSelected={selectedOption}
          placeholder={undefined}
          noMatchesFound={undefined}
          hasAutocomplete
          appearance="default"
          onSelected={this.onChange}
        />
      </div>
    );
  };

}
