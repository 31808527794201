import ScoreMeta from '../../../../shared/model/rubric/score/ScoreMeta';

export interface CardDragInfo {
  cardAssessmentUuid: string
}

export interface ListDragInfo extends CardDragInfo {
  fromListId: string
}

export interface ICard {
  definitionUuid: string
  assessmentUuid: string
  cardTitle: string
  cardSubtitle: string
  description: string
  scoreMeta: ScoreMeta
  assessmentStatusId: string
  assessmentScore: number
  allowDrag: boolean
}

export interface IList {
  id: string
  cards: ICard[]
}

export interface IBoard {
  lists: IList[]
}

class BoardState {

  dragInfo: undefined | ListDragInfo = undefined;

  getDragInfo = (): undefined | ListDragInfo => {
    return this.dragInfo;
  };

  setDragInfo = (dragInfo: ListDragInfo) => {
    this.dragInfo = dragInfo;
  }

}

export default new BoardState();
