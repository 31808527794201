import React, { PureComponent } from 'react';
import adg from '../../../../commonbase/adg';
import allTheThings from '../../../images/all-the-things.png';
import browserType from '../../../../shared/model/browser/BrowserType';
import Button from '@atlaskit/button';
import cannedData from '../../../../backend/data/canned/CannedData';
import constants from '../../../../shared/model/Constants';
import definitionCreator from '../../../components/rubric/definition/DefinitionCreator';
import actions from '../../../../shared/actions/Actions';
import ContainerDimensions from 'react-container-dimensions';
import ContentWrapper from '../../../components/widget/ContentWrapper';
import currentRubric from '../../../../shared/model/rubric/CurrentRubric';
import featureFlags from '../../../../shared/model/feature/FeatureFlags';
import navUtil from '../../../components/nav/NavUtil';
import session from '../../../../shared/model/auth/Session';
import { statusDefinitions, StatusProvider } from '../../../../shared/model/status/Status';
import ToolbarLeft from '../../../components/widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

export default class StartView extends PureComponent {

  constructor(props) {
    super(props);
    this.statusProvider = new StatusProvider('StartView');
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
    this.statusProvider.setActive(true);
    this.statusProvider.setCurrentStatus(
      statusDefinitions.statuses.mayNeedWork,
      '');
  };

  componentWillUnmount() {
    this.statusProvider.setActive(false);
  };

  buildStateFromProps = (props) => {
    return {
      mainActionItems: this.buildMainActionItems(),
      alternateActionItems: this.buildAlternateActionItems()
    };
  };

  buildMainActionItems = () => {
    return [{
      text: 'Employee development',
      reference: cannedData.employeeDevelopmentReference,
      enabled: true
    }, {
      text: 'Conduct a competitive analysis',
      reference: cannedData.competitiveAnalysisTemplateReference,
      action: this.onConductACompetitiveAnalysisClick,
      enabled: true
    }, {
      text: 'Prioritise our projects',
      //reference: cannedData.xxxxxxx,
      enabled: true
    }, {
      text: 'Give feedback to my manager',
      reference: cannedData.googlesManagerFeedbackSurveyReference,
      enabled: true
    }, {
      text: 'Assess the health of my team',
      reference: cannedData.atlassianProjectTeamHealthMonitorReference,
      enabled: true
    }, {
      text: 'Decide which startup we should invest in',
      reference: cannedData.uluStartupEvaluationReference,
      enabled: true
    }, {
      text: 'Turn a moment into a "multiplying" situation',
      reference: cannedData.multipliersVsDiminishersReference,
      enabled: false
    }, {
      text: 'Determine whether my OKR is written well',
      reference: cannedData.okrWritingGuideReference,
      enabled: true
    }, {
      text: 'Assess how well a mission statement is written',
      reference: cannedData.missionStatementWritingGuideReference,
      enabled: false
    }, {
      text: 'Determine how well a job description is written',
      reference: cannedData.jobDescriptionWritingGuideReference,
      enabled: false
    }, {
      text: 'Make a decision',
      reference: cannedData.decisonMakingTemplateReference,
      action: this.onMakeADecisionClick,
      enabled: true
    }, {
      text: 'Create a new kind of assessment',
      // reference: cannedData.xxxxxxx,
      enabled: true
    }];
  };

  buildAlternateActionItems = () => {
    const items = [{
      text: 'Create a rubric',
      action: () => {
        // actions.onNewRubric();
        definitionCreator.onNewExampleRubric();
      },
      enabled: true
    }, {
      text: 'View my assessment board',
      action: () => {
        actions.onVisitBoardPage();
      },
      enabled: true
    }, {
      text: 'Revisit a rubric from my dashboard',
      action: () => {
        actions.onVisitDashboardPage();
      },
      enabled: featureFlags.isDashboardEnabled()
    }, {
      text: `View my ${constants.appTitle} drive`,
      action: () => {
        actions.onVisitDrivePage();
      },
      enabled: true
    }];

    return items;
  };

  startViewStyles = `
  .start-page {
    height: calc(100% - 80px);
  }

  .start-wrapper {
    margin-top: 40px;
  }

  .start-view {
    font-size: 28px;
    margin-left: auto;
    margin-right: auto;
  }

  .start-view p {
    
  }

  .action-link {
    color: ${adg.adgPrimaryBlue};
  }

  .start-view ul {
    list-style-type: none;
  }

  .start-view ul li {
    padding-left: 20px;
    margin-left: -10px;
  }

  .start-view ul li:hover {
    background: url(/img/icon/tick.png) no-repeat 0px 20px;
  }
  `;

  onItemLeave = (item) => {
    this.statusProvider.setCurrentStatus(
      statusDefinitions.statuses.problemsExist,
      'Click an action to get started.');
  };

  onItemEnter = (item) => {
    this.statusProvider.setCurrentStatus(
      statusDefinitions.statuses.allOK,
      '');
  };

  onMakeADecisionClick = (item) => {
    const currentUser = session.getCurrentUser();
    if (currentUser && featureFlags.createDecisionWizardEnabled()) {
      actions.launchNewDecisionWizard();
    } else {
      // fallback...
      this.onItemReferenceClick(item);
    }
  };

  onItemReferenceClick = (item) => {
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      currentRubric.setRubricReference(item.reference);
    } else {
      // Just change the URL to the page where the user will be prompted to log in.
      if (this.props.history) {
        const hash = navUtil.buildDefinitionHash(item.reference.definitionUuid, item.reference.definitionName);
        this.props.history.push(hash);
      } else {
        const href = navUtil.buildDefinitionUrl(item.reference.definitionUuid, item.reference.definitionName);
        window.location.href = href;
      }
    }
  };

  onItemClick = (item) => {
    if (item.action) {
      item.action(item);
    } else if (item.reference) {
      this.onItemReferenceClick(item);
    } else {
      // this should not be reached
    }
  };

  renderAction = (actionItem, index) => {
    if (actionItem && actionItem.enabled && (actionItem.reference || actionItem.action)) {
      return (
        <li
          key={'action-' + index}
        >
          <Button
            appearance="subtle-link"
            isDisabled={false}
            onMouseEnter={browserType.isMobile() ? undefined : () => this.onItemEnter(actionItem)}
            onMouseLeave={browserType.isMobile() ? undefined : () => this.onItemLeave(actionItem)}
            onClick={() => this.onItemClick(actionItem)}
          >
            <div className="action-link">
              {actionItem.text + '...'}
            </div>
          </Button>
        </li>
      );
    } else {
      return null;
    }
  }

  renderActions = (introText, actionItems) => {
    if (!actionItems || !actionItems.length) {
      return null;
    }
    const renderedActions = actionItems.map((item, index) => {
      return this.renderAction(item, index);
    });
    return (
      <div>
        <p>
          {introText}
        </p>
        <ul style={{paddingLeft: '20px'}}>
          {renderedActions}
        </ul>
      </div>
    );
  };

  renderIllustration = (containerWidth) => {
    const thresholdWidth = 700;
    const imageThresholdWidth = 231;
    if (containerWidth < thresholdWidth) {
      return null;
    }
    const imageWidth = imageThresholdWidth + containerWidth - thresholdWidth;
    const imageSizePx = imageWidth + 'px';
    return (
      <div
        style={{
          width: imageSizePx,
          height: imageSizePx,
          position: 'absolute',
          top: 0,
          right: 0
        }}
      >
        <img
          src="/img/illustrations/background-rubric.svg"
          alt="Roobrick illustration"
          width={imageSizePx}
          height={imageSizePx}
        />
      </div>
    );
  };

  renderByline = () => {
    return (
      <ToolbarLeft>
        <ToolbarItem>
          <p className="any-byline">
            {constants.appTitle + ': ' + constants.appByline.toLowerCase()}
          </p>
        </ToolbarItem>
        <ToolbarItem style={{margin: '0px'}}>
          <img
            src={allTheThings}
            width={40}
            alt={constants.appByline}
          />
        </ToolbarItem>
      </ToolbarLeft>
    );
  };

  render() {
    return (
      <div className="start-page">
        <style dangerouslySetInnerHTML={{__html: this.startViewStyles}} />
        <div className="start-page">
          <ContentWrapper>
            <ContainerDimensions>
              { ({ width, height }) =>
                <div>
                  {this.renderIllustration(width)}
                  <div
                    className="start-wrapper"
                  >
                    <div className="start-view">
                      {this.renderByline()}
                      <div className="mainContentSection">
                        &nbsp;
                      </div>
                      {this.renderActions('Create an assessment:', this.state.mainActionItems)}
                      <div className="mainContentSection">
                        &nbsp;
                      </div>
                      {this.renderActions('Alternatively:', this.state.alternateActionItems)}
                      <p>
                      </p>
                    </div>
                  </div>
                </div>
              }
            </ContainerDimensions>
          </ContentWrapper>
        </div>
      </div>
    );
  };

}
