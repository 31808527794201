import colorRange from '../../../../commonbase/color/customLevelsColorRange';
import ScoreMeta from './ScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';
import scoreUtil from './ScoreUtil';

export default class CustomLevelsScoreMeta extends ScoreMeta {

  hasAnyMarkerLabels: boolean

  constructor(label, markers) {
    super(scoreTypes.customLevels, label, markers);
    this.displayLabelsInsteadOfScores = true;

    this.hasAnyMarkerLabels = false;
    for (const marker of this.markers) {
      if (marker.label) {
        this.hasAnyMarkerLabels = true;
      }
    }
  }

  roundInputScorePercent = (scorePercent) => {
    const rounded = Math.round(scorePercent);
    if (rounded < 0) {
      return 0;
    } else if (rounded > 100) {
      return 100;
    } else {
      return rounded;
    }
  };

  scoreToPercent = (score) => {
    const scorePercent = Math.round(score);
    return scorePercent;
  };

  percentToScore = (scorePercent) => {
    const score = Math.round(scorePercent);
    return score;
  };

  shouldRenderEmojiForAssessmentItem = (assessmentItem) => {
    const scorePercent = scoreUtil.assessmentItemToScore(assessmentItem);
    const score = this.percentToScore(scorePercent);
    if (this.hasAnyMarkerLabels) {
      for (const marker of this.markers) {
        if (!marker.label && marker.score === score) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  getMarkerByIndex = (index) => {
    if (this.markers && index < this.markers.length) {
      const marker = this.markers[index];
      return marker;
    }
    return undefined;
  };

  getColorRange = () => {
    return colorRange;
  }

}

