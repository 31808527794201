import React, { PureComponent } from "react";
import adg from '../../../../commonbase/adg';
import { DeselectedIcon, SelectedCircleIcon } from '../../icon/NamedIcons';
export default class ScoreTag extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      appearance: props.appearance,
      inputMode: props.inputMode,
      selected: props.selected,
      text: props.text,
      color: props.color ? props.color : adg.adgGray,
      clickable: props.clickable,
      onClick: props.onClick
    };
  };

  render() {
    let className = 'tag-button';
    const style = {
      backgroundColor: this.state.color
    };
    if (this.props.marginLeft) {
      style.marginLeft = this.props.marginLeft;
    }
    if (this.state.appearance && this.state.appearance === 'rounded') {
      style.borderRadius = '6px';
    }
    if (this.state.inputMode) {
      className += ' clickable';
      if (this.state.selected === false) {
        //style.opacity = 0.5;
        className += ' tag-button-deselected';
      } else {
        className += ' tag-button-selected';
      }
    }
    return (
      <div
        className={className}
        style={style}
        onClick={this.state.onClick}
      >
        {this.state.inputMode && this.state.selected === true ? (<SelectedCircleIcon size="small" primaryColor="white" secondaryColor={this.state.color} />) : null}
        {this.state.inputMode && this.state.selected === false ? (<DeselectedIcon size="small" primaryColor="white" secondaryColor={this.state.color} />) : null}
        {this.state.text}
      </div>
    );
  }

}
