import React, { PureComponent } from 'react';
import AdminView from '../components/admin/AdminView';
import BackgroundImageSelector from '../components/widget/media/BackgroundImageSelector';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import constants from '../../shared/model/Constants';
import ContentWrapper from '../components/widget/ContentWrapper';
import currentUserPlan from '../../backend/payment/CurrentUserPlan';
import { dmUserId } from '../../shared/model/auth/AuthConstants';
import emailNotificationDefinitions from '../../shared/model/email/EmailNotificationDefinitions';
import LiftedLabel from '../components/widget/LiftedLabel';
import InfoMessageBanner from '../components/widget/banner/InfoMessageBanner';
import LiftedPanel from '../components/widget/LiftedPanel';
import LiftedPageTitle from '../components/widget/LiftedPageTitle';
import planDefinitions from '../../shared/model/payment/PlanDefinitions';
import preferencesDAO from '../../backend/data/PreferencesDAO';
import rubricPageHelper from './RubricPageHelper';
import session from '../../shared/model/auth/Session';
import SignUpButton from '../payment/SignUpButton';
import IUser from '../../shared/model/auth/IUser';
import backgroundImageUtil from '../components/widget/media/BackgroundImageUtil';
import featureFlags from '../../shared/model/feature/FeatureFlags';

interface Props {

}

interface State {
  preferenceToSet: any
  planDefinition: any
  emailPreferences: any
  preferredBackgroundImageId: undefined | string
  preferenceAppliedFromUrlTime: any
  preferenceAppliedFromUrlMessage?: string
  user?: IUser
}

export default class ProfilePage extends PureComponent<Props, State> {

  mounted = false;
  preferencePropertyNamesToConfigs: any;

  constructor(props: Props) {
    super(props);
    const preferencePropertyNamesToConfigs = {};
    preferencePropertyNamesToConfigs[emailNotificationDefinitions.definitionLikeEmailsAllowed] = {
      label: `Allow notifications of when someone likes a rubric you created`
    };
    preferencePropertyNamesToConfigs[emailNotificationDefinitions.newBlogEmailsAllowedType] = {
      label: `Subscribe to new ${constants.appTitle} blog posts`
    };
    this.preferencePropertyNamesToConfigs = preferencePropertyNamesToConfigs;
    const planDefinition = currentUserPlan.planDefinition;
    this.state = {
      planDefinition: planDefinition,
      emailPreferences: undefined,
      preferredBackgroundImageId: undefined,
      preferenceAppliedFromUrlTime: 0,
      preferenceToSet: undefined
    }
  }

  UNSAFE_componentWillMount = () => {
    this.mounted = true;
    this.setUser(session.getCurrentUser());
    session.registerListener(this.onSessionChange);
    currentUserPlan.registerListener(this.onUserPlanChange);
  };

  componentDidMount() {
    rubricPageHelper.updateTitle('Profile');

    // const currentUser = session.getCurrentUser();
    // const currentUserId = currentUser.getId();
    // if (currentUserId === dmUserId) {
    //   setTimeout(() => {
    //     notificationUtil.sendTestNotification();
    //   }, 1000);  
    // }
  }

  componentWillUnmount() {
    this.mounted = false;
    session.unregisterListener(this.onSessionChange);
    currentUserPlan.unregisterListener(this.onUserPlanChange);
  };

  applyPreferenceSettingFromUrl = (): Promise<void> => {
    let promise: any = null;
    if (this.state.preferenceToSet) {
      if (this.state.preferenceToSet.key === 'definitionLikeEmailsAllowed') {
        const emailPreferences = {
          definitionLikeEmailsAllowed: this.state.preferenceToSet.value
        };
        const user = session.getCurrentUser();
        promise = preferencesDAO.setEmailPreference(user, emailPreferences)
          .then(() => {
            this.setState({
              preferenceAppliedFromUrlTime: new Date().getTime(),
              preferenceAppliedFromUrlMessage: 'Your email notification preference has been updated.'
            });
            return null;
          });
      }
    }
    if (!promise) {
      promise = new Promise((resolve, reject) => {
        resolve(undefined);
      });
    }
    return promise;
  };

  onSessionChange = (user) => {
    this.setUser(user);
  };

  onUserPlanChange = (planDefinition) => {
    this.setState({
      planDefinition: planDefinition
    });
  };

  getEmailPreferenceByPropertyName = (preferencePropertyName) => {
    // A value of undefined defaults to true...
    return this.state.emailPreferences[preferencePropertyName] !== false;
  };

  onAllowNotificationsChange = (event, preferencePropertyName) => {
    const currentValue = this.getEmailPreferenceByPropertyName(preferencePropertyName);
    const newValue = !currentValue;
    const emailPreferences = Object.assign(this.state.emailPreferences, {});
    emailPreferences[preferencePropertyName] = newValue;
    this.setState(emailPreferences);
    const user = session.getCurrentUser();
    preferencesDAO.setEmailPreference(user, emailPreferences);
  };

  onPreferredBackgroundImageChange = async (backgroundImageId) => {
    const user = session.getCurrentUser();
    if (user) {
      await preferencesDAO.setPreferredBackgroundImageIdPreference(user, backgroundImageId);
      this.setState({
        preferredBackgroundImageId: backgroundImageId
      });
      backgroundImageUtil.setBackgroundImageBasedOnUserPreferences();
    }
  }

  onSignOutButtonClick = () => {
    session.signOut();
  }

  setUser = (user) => {
    if (user) {
      this.applyPreferenceSettingFromUrl()
        .then(() => {
          preferencesDAO.getEmailPreference(user)
            .then((emailPreferences) => {
              this.setState({
                emailPreferences: emailPreferences
              });
            });
          preferencesDAO.getPreferredBackgroundImageIdPreference(user)
            .then((imageId) => {
              this.setState({
                preferredBackgroundImageId: imageId
              });
            });
        });
    } else {
      this.setState({
        emailPreferences: undefined
      });
    }
    this.setState({
      user: user
    });
  };

  renderPreferenceAppliedMessage = () => {
    if (this.state.preferenceAppliedFromUrlMessage) {
      return (
        <InfoMessageBanner>
          {this.state.preferenceAppliedFromUrlMessage}
        </InfoMessageBanner>
      );
    } else {
      return null;
    }
  };

  renderSignOutPanel = () => {
    return (
      <>
        <div style={{ float: 'right', marginTop: '-60px' }}>
          <LiftedPanel>
            <Button
              appearance="warning"
              onClick={this.onSignOutButtonClick}
            >
              Sign out
          </Button>
          </LiftedPanel>
        </div>
        <div style={{clear: 'both'}}>
        </div>
      </>
    );
  }

  renderDisplayName = () => {
    return (
      <div>
        <LiftedLabel
          text="Display name"
        />
        <LiftedPanel>
          {this.state.user ? this.state.user.getDisplayName() : null}
        </LiftedPanel>
      </div>
    );
  };

  renderEmail = () => {
    return (
      <div>
        <LiftedLabel
          text="Email"
        />
        <LiftedPanel>
          {this.state.user ? this.state.user.getEmail() : null}
        </LiftedPanel>
      </div>
    );
  };

  renderUpgradeButton = () => {
    return (
      <SignUpButton
        appearance="primary"
        label={`Upgrade to the ${planDefinitions.proPlanName} plan`}
      />
    );
  };

  renderPlan = () => {
    return null;
    // const planType = this.state.planDefinition.type;
    // return (
    //   <div>
    //     <LiftedLabel
    //       text="Plan"
    //     />
    //     <LiftedPanel>
    //       {planDefinitions.typesToDefinitions[planType].name}
    //       {planType === planDefinitions.liteType ? this.renderUpgradeButton() : null}
    //     </LiftedPanel>
    //   </div>
    // );
  };

  renderEmailPreferenceCheckbox = (preferencePropertyName, label) => {
    const currentValue = this.getEmailPreferenceByPropertyName(preferencePropertyName);
    return (
      <div key={preferencePropertyName}>
        <Checkbox
          isChecked={currentValue}
          label={label}
          name={preferencePropertyName}
          crossOrigin={undefined}
          onChange={(event) => this.onAllowNotificationsChange(event, preferencePropertyName)}
        />
      </div>
    );
  };

  renderEmailPreferences = () => {
    const preferencePropertyNames = Object.keys(this.preferencePropertyNamesToConfigs);
    const renderedCheckboxes = preferencePropertyNames.map((preferencePropertyName) => {
      const config = this.preferencePropertyNamesToConfigs[preferencePropertyName];
      return this.renderEmailPreferenceCheckbox(preferencePropertyName, config.label)
    });
    return (
      <div>
        <LiftedLabel text="Email preferences"/>
        <LiftedPanel>
          {renderedCheckboxes}
        </LiftedPanel>
      </div>
    );
  };

  renderPreferredBackgroundImageSelector = () => {
    return (
      <div className="newMinorSection">
        <LiftedLabel text="Preferred background image" />
        <LiftedPanel>
          <BackgroundImageSelector
            selectedBackgroundImageId={this.state.preferredBackgroundImageId}
            showLabel={false}
            onChange={this.onPreferredBackgroundImageChange}
          />
        </LiftedPanel>
      </div>
    );
  }

  render() {
    const currentUser = session.getCurrentUser();
    return (
      <ContentWrapper>
        <LiftedPageTitle>Profile</LiftedPageTitle>
        <div className="newMainSection"></div>
        {this.renderPreferenceAppliedMessage()}
        <div className="newMainSection"></div>
        {this.renderSignOutPanel()}
        <div className="newMainSection"></div>
        {this.renderDisplayName()}
        <div className="newMainSection"></div>
        {this.renderEmail()}
        <div className="newMainSection"></div>
        {this.renderPlan()}
        <div className="newMainSection"></div>
        {featureFlags.enableBackgroundImages() ? this.renderPreferredBackgroundImageSelector() : null}
        <div className="newMainSection"></div>
        {this.state.emailPreferences === undefined ? null : this.renderEmailPreferences()}
        <div className="newMainSection"></div>
        {currentUser && currentUser.getId() === dmUserId ? <AdminView /> : null}
      </ContentWrapper>
    );
  }
}
