import Definition from './Definition';
import DefinitionReference from './DefinitionReference';
import DefinitionSearchResult from './DefinitionSearchResult';

export default class ResolvedDefinitionSearchResult implements DefinitionSearchResult {

  definitionUuid: string = '';
  definitionName: string = '';
  resolutionAttempted: boolean = false;
  resolvedDefinition: undefined | Definition = undefined;
  definitionResolver: () => Promise<undefined | Definition>;

  constructor(definitionUuid: string, definitionName: string, definitionResolver: () => Promise<undefined | Definition>) {
    this.definitionUuid = definitionUuid;
    this.definitionName = definitionName;
    this.definitionResolver = definitionResolver;
  }

  getDefinitionReference = (): DefinitionReference => {
    const definitionReference: DefinitionReference = {
      definitionUuid: this.definitionUuid,
      definitionName: this.definitionName
    };
    return definitionReference;
  }

  resolveDefinition = async (): Promise<undefined | Definition> => {
    if (!this.resolutionAttempted) {
      this.resolvedDefinition = await this.definitionResolver();
      this.resolutionAttempted = true;
    }
    return this.resolvedDefinition;
  }

}