import React, { PureComponent } from 'react';
import decisionStatusDefinitions from './DecisionStatusDefinitions';
import SingleSelect from '../../widget/SingleSelect';
import SelectOption from '../../../../shared/model/widget/SelectOption';

interface Props {
  statusType?: string
  onChange: (statusType: string) => void
}

interface State {
  statusType?: string
  dirty?: boolean
}

export default class DecisionStatusSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      statusType: props.statusType === undefined ?
        this.state.statusType : props.statusType
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      statusType: selection.value
    });
    this.props.onChange(selection.value);
  };

  render() {
    const options: SelectOption[] = decisionStatusDefinitions.getOptions();
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === this.state.statusType) {
        selectedOption = option;
      }
    }
    return (
      <div>
        <SingleSelect
          label="Status"
          options={options}
          defaultSelected={selectedOption}
          placeholder={undefined}
          noMatchesFound={undefined}
          hasAutocomplete
          appearance="default"
          onSelected={this.onChange}
        />
      </div>
    );
  };

}
