import connectJavascriptApi from '../ConnectJavascriptApi';
import urlUtil from '../../../../shared/util/UrlUtil';
import promiseUtil from '../../../../commonbase/util/promiseUtil';

class ConfluenceConnectUtil {

  getCurrentContentId = () => {
    return new Promise((resolve, reject) => {
      const AP = connectJavascriptApi.getAP();
      AP.context.getContext((context) => {
        if (context && context.confluence) {
          if (context.confluence.content) {
            const contentId = context.confluence.content.id;
            resolve(contentId);
          } else {
            reject(new Error('Not in a content context'));
          }
        } else {
          reject(new Error('Not in a Confluence context'));
        }
      });
    });
  };

  saveMacroDataInContent = async (contentId: string, macroDataUuid: string, data: any): Promise<void> => {
    const propertyKey = `roobrick-macro-data-${macroDataUuid}`;
    return new Promise((resolve, reject) => {
      return this.setContentProperty(contentId, propertyKey, data)
        .then(() => {
          resolve();
        });
    });
  }

  getMacroDataFromContent = async (contentId: string, macroDataUuid: string): Promise<any> => {
    const propertyKey = `roobrick-macro-data-${macroDataUuid}`;
    return new Promise((resolve, reject) => {
      return this.getContentProperty(contentId, propertyKey)
        .then((data: any) => {
          resolve(data);
        });
    });
  }

  /**
   * Use this when AP.context is not supported. Relies on being primed with ensureContentIdContentPropertyIsSet.
   */
  getCurrentContentIdUsingContentProperty = async (): Promise<undefined | string> => {
    return new Promise((resolve, reject) => {
      const AP = connectJavascriptApi.getAP();
      AP.navigator.getLocation((locationData) => {
        if (locationData && locationData.context && locationData.context.contentId) {
          const contentId = locationData.context.contentId;
          resolve(contentId);
        } else {
          resolve(undefined);
        }
      });
    });

  };

  ensureContentIdContentPropertyIsSet = () => {
    return promiseUtil.promiseReturning(undefined);
  };

  getMacroBody = (contentId, contentVersion, macroId) => {
    // Assumes: contentId={page.id}&contentVersion={page.version}&macroId{macro.id}
    return new Promise((resolve, reject) => {
      // const contentId = this.getStringMacroParameter("contentId");
      // const contentVersion = this.getStringMacroParameter("contentVersion");
      // const macroId = this.getStringMacroParameter("macroId");
      const AP = connectJavascriptApi.getAP();
      AP.request({
        url: `/rest/api/content/${contentId}/history/${contentVersion}/macro/id/${macroId}`,
        success: (response) => {
          const macro = JSON.parse(response);
          resolve(macro.body);
        },
        error: (xhr, statusText, errorThrown) => {
          // console.log(`getContentProperty: Error getting glance status:`);
          // console.log(` * xhr:`, xhr);
          // console.log(` * statusText:`, statusText);
          // console.log(` * errorThrown:`, errorThrown);
          if (xhr && xhr.status === 404) {
            resolve(undefined);
          } else {
            reject(errorThrown);
          }
        }
      });
    });
  }

  getContentProperty = (contentId, propertyKey): Promise<any> => {
    // https://developer.atlassian.com/cloud/confluence/rest/api-group-content-properties/#api-api-content-id-property-get
    // console.log(`Getting content property:`);
    // console.log(` * contentId: ${contentId}`);
    // console.log(` * propertyKey: ${propertyKey}`);
    return new Promise((resolve, reject) => {
      const AP = connectJavascriptApi.getAP();
      AP.request(`/rest/api/content/${contentId}/property/${propertyKey}`, {
        type: 'GET',
        contentType: 'application/json',
        success: (responseJson) => {
          // console.log(`getContentProperty: responseJson:`, responseJson);
          const propertyValue = JSON.parse(responseJson);
          // console.log(`getContentProperty: propertyValue:`, propertyValue);
          if (propertyValue.value) {
            resolve(propertyValue.value);
          } else {
            resolve(undefined);
          }
        },
        error: (xhr, statusText, errorThrown) => {
          // console.log(`getContentProperty: Error getting content property:`);
          // console.log(` * xhr:`, xhr);
          // console.log(` * statusText:`, statusText);
          // console.log(` * errorThrown:`, errorThrown);
          if (xhr && xhr.status === 404) {
            resolve(undefined);
          } else {
            reject(errorThrown);
          }
        }
      });
    });
  };

  getContentProperties = (contentId: string, prefix: string): Promise<any[]> => {
    return this._getContentProperties(contentId).then((allResults) => {
      const results: any[] = [];
      for (const result of allResults) {
        results.push(result.value);
      }
      return results;
    });
  };

  _getContentProperties = (contentId: string): Promise<any[]> => {
    const allResults: any[] = [];
    let start = 0;
    let limit = 100; // TODO: fix
    return this._getContentPropertiesReentrant(contentId, start, limit, allResults);
  };

  _getContentPropertiesReentrant = (contentId: string, start: number, limit: number, allResults: any[]): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      this._getAndAddContentPropertiesPage(contentId, start, limit, allResults)
        .then((endReached: boolean) => {
          if (endReached) {
            resolve(allResults);
          } else {
            return this._getContentPropertiesReentrant(contentId, start + limit, limit, allResults);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _getAndAddContentPropertiesPage = (contentId: string, start: number, limit: number, allResults: any[]): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this._fetchContentPropertiesPage(contentId, start, limit)
        .then((pageResults: any[]) => {
          for (const result of pageResults) {
            allResults.push(result);
          }
          const endReached = pageResults.length < limit
          resolve(endReached);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  _fetchContentPropertiesPage = (contentId: string, start: number, limit: number): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      try {
        const AP = connectJavascriptApi.getAP();
        AP.request(`/rest/api/content/${contentId}/property/?start=${start}&limit=${limit}`, {
          type: 'GET',
          contentType: 'application/json',
          success: (responseJson) => {
            // console.log(`_getContentPropertiesPage: responseJson:`, responseJson);
            const propertyValues = JSON.parse(responseJson);
            // console.log(`_getContentPropertiesPage: propertyValue:`, propertyValues);
            const results = propertyValues.results;
            resolve(results);
          },
          error: (xhr, statusText, errorThrown) => {
            // console.log(`_getContentPropertiesPage: Error getting content property version:`);
            // console.log(` * xhr:`, xhr);
            // console.log(` * statusText:`, statusText);
            // console.log(` * errorThrown:`, errorThrown);
            reject(errorThrown);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  getContentPropertyVersion = (contentId, propertyKey): Promise<undefined | number> => {
    // https://developer.atlassian.com/cloud/confluence/rest/api-group-content-properties/#api-api-content-id-property-get
    // console.log(`Getting content property version:`);
    // console.log(` * contentId: ${contentId}`);
    // console.log(` * propertyKey: ${propertyKey}`);
    return new Promise((resolve, reject) => {
      const AP = connectJavascriptApi.getAP();
      AP.request(`/rest/api/content/${contentId}/property/${propertyKey}`, {
        type: 'GET',
        contentType: 'application/json',
        success: (responseJson) => {
          // console.log(`getContentPropertyVersion: responseJson:`, responseJson);
          const propertyValue = JSON.parse(responseJson);
          // console.log(`getContentPropertyVersion: propertyValue:`, propertyValue);
          if (propertyValue.version) {
            resolve(propertyValue.version.number);
          } else {
            resolve(undefined);
          }
        },
        error: (xhr, statusText, errorThrown) => {
          // console.log(`getContentProperty: Error getting content property version:`);
          // console.log(` * xhr:`, xhr);
          // console.log(` * statusText:`, statusText);
          // console.log(` * errorThrown:`, errorThrown);
          if (xhr && xhr.status === 404) {
            resolve(0);
          } else {
            reject(errorThrown);
          }
        }
      });
    });
  };

  setContentProperty = async (contentId, propertyKey, value): Promise<void> => {
    return this.getContentPropertyVersion(contentId, propertyKey)
      .then((version: undefined | number) => {
        // https://developer.atlassian.com/cloud/confluence/rest/api-group-content-properties/#api-api-content-id-property-key-post
        const newVersion = version ? version + 1 : 1;
        const payload = {
          version: {
            number: newVersion,
            minorEdit: true
          },
          value: value
        };
        // console.log(`Setting content property:`);
        // console.log(` * contentId: ${contentId}`);
        // console.log(` * propertyKey: ${propertyKey}`);
        // console.log(` * new version: ${newVersion}`);
        // console.log(` * value: ${value}`);
        return new Promise((resolve, reject) => {
          const AP = connectJavascriptApi.getAP();
          AP.request(`/rest/api/content/${contentId}/property/${propertyKey}`, {
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(payload),
            success: (responseJson) => {
              // console.log(`setContentProperty: responseJson:`, responseJson);
              resolve(responseJson);
            },
            error: (xhr, statusText, errorThrown) => {
              // console.log(`setContentProperty: Error setting content property:`);
              // console.log(` * xhr:`, xhr);
              // console.log(` * statusText:`, statusText);
              // console.log(` * errorThrown:`, errorThrown);
              reject(errorThrown);
            }
          });
        });
      });
  };

  getStringMacroParameter = (macroParams, parameterName, defaultValue) => {
    const urlParamValue = urlUtil.getQueryParameter(parameterName);
    if (urlParamValue) {
      return urlParamValue;
    } else {
      if (macroParams) {
        return macroParams[parameterName];
      } else {
        return defaultValue;
      }
    }
  };

  getBooleanMacroParameter = (macroParams, parameterName, defaultValue) => {
    const urlParamValue = urlUtil.getQueryParameter(parameterName);
    if (urlParamValue) {
      return urlParamValue === 'true';
    } else {
      if (macroParams) {
        const providedValue = macroParams[parameterName];
        if (providedValue === undefined) {
          return defaultValue;
        } else {
          return providedValue === 'true';
        }
      } else {
        return defaultValue;
      }
    }
  };

}

export default new ConfluenceConnectUtil();