import storageDAO, { QueryOptions } from '../../../../backend/data/StorageDAO';

export default (url: string, options?: any): any => {
  const methodLower = options.type ? options.type.toLowerCase() : 'get';
  // const contentPropertiesUrl = "/rest/api/content/dummy-content-id/property/?start=0&limit=100";
  const contentPropertiesRe = /\/rest\/api\/content\/(.*)\/property\/(.*)/;
  const contentPropertiesMatch = url.match(contentPropertiesRe);
  const issuePropertiesRe = /\/rest\/api\/3\/issue\/([A-Z]+-\d+)\/properties\/(.*)/;
  const issuePropertiesMatch = url.match(issuePropertiesRe);
  if (contentPropertiesMatch && contentPropertiesMatch.length) {
    const contentId = contentPropertiesMatch[1];
    const param2 = contentPropertiesMatch[2];
    if (param2 && param2.startsWith('?')) {
      const queryOptions: QueryOptions = {
        isKeyOfInterest: (key: string) => {
          return key.indexOf(contentId) >= 0;
        }
      }
      // const queryParts = param2.substring(1).split('&');
      // for (const queryPart of queryParts) {
      //   const keyPairParts = queryPart.split('=');
      //   if (keyPairParts.length === 2) {
      //     const key = keyPairParts[0];
      //     const value = keyPairParts[1];
      //     if (key === 'start') {
      //       // 
      //     } else if (key === 'limit') {
      //       // 
      //     }
      //   }
      // }
      return storageDAO.getItems(queryOptions)
        .then((items) => {
          const results: any[] = [];
          const response = {
            results: results
          }
          for (const item of items) {
            const itemObject = JSON.parse(item);
            results.push(itemObject);
          }
          if (options.success) {
            options.success(JSON.stringify(response));
          } else {
            return response;
          }
        });
    } else {
      const propertyKey = param2;
      const storageKey = contentId + '--' + propertyKey;
      if (methodLower === 'get') {
        storageDAO.getString(storageKey)
          .then((responseString) => {
            if (responseString) {
              if (options.success) {
                options.success(responseString);
              }
            } else {
              if (options.error) {
                const xhr = {
                  status: 404
                };
                const statusText = 'Property not found';
                const errorThrown = undefined;
                options.error(xhr, statusText, errorThrown);
              }
            }
          });
      } else {
        const property = JSON.parse(options.data);
        const stringToStore = JSON.stringify(property);
        storageDAO.setString(storageKey, stringToStore)
          .then(() => {
            if (options.success) {
              options.success(undefined);
            }
          });
      }
    }
  } else if (issuePropertiesMatch && issuePropertiesMatch.length) {
    const issueKey = issuePropertiesMatch[1];
    const propertyKey = issuePropertiesMatch[2];
    const storageKey = issueKey + '--' + propertyKey;
    if (methodLower === 'get') {
      storageDAO.getString(storageKey)
        .then((responseString) => {
          if (responseString) {
            if (options.success) {
              options.success(responseString);
            }
          } else {
            if (options.error) {
              const xhr = {
                status: 404
              };
              const statusText = 'Property not found';
              const errorThrown = undefined;
              options.error(xhr, statusText, errorThrown);
            }
          }
        });
    } else {
      const property = {
        value: JSON.parse(options.data)
      };
      const stringToStore = JSON.stringify(property);
      storageDAO.setString(storageKey, stringToStore)
        .then(() => {
          if (options.success) {
            options.success(undefined);
          }
        });
    }
  } else {
    throw new Error(`MockRequestModule: Unable to handle request: ${url}`);
  }

}