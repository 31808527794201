import backgroundRetriever from '../../../../commonbase/media/backgroundRetriever';
import featureFlags from '../../../../shared/model/feature/FeatureFlags';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import preferencesDAO from '../../../../backend/data/PreferencesDAO';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import session from '../../../../shared/model/auth/Session';
import backgroundImages from '../../../../commonbase/media/backgroundImages';

export const defaultBackgroundImageId = 'white'

class BackgroundImageUtil {

  _enabled = false;
  _instructionId = 0;
  _currentBackgroundImageId: undefined | string = undefined;

  constructor() {
    const defaultBackgroundImage = this.getBackgroundImageById(defaultBackgroundImageId);
    if (!defaultBackgroundImage) {
      throw new Error('Unable to identify default background image');
    }
  }

  getBackgroundImageById = (backgroundImageId: string): undefined | any => {
    for (const backgroundImage of backgroundImages) {
      if (backgroundImage.id === backgroundImageId) {
        return backgroundImage;
      }
    }
    return undefined;
  }

  enable = (): void => {
    this._enabled = true;
  }

  setDefinitionBackgroundImage = (definition: undefined | Definition): void => {
    this._instructionId++;
    if (featureFlags.enableBackgroundImages()) {
      if (definition) {
        const image = rubricUtil.getDefinitionBackgroundImage(definition, defaultBackgroundImageId);
        this._setBackgroundImage(image);
      } else {
        this._setBackgroundImage(undefined);
      }
    }
  }

  setBackgroundImageBasedOnUserPreferences = () => {
    this._instructionId++;
    const thisInstructionId = this._instructionId;
    const user = session.getCurrentUser();
    if (user) {
      preferencesDAO.getPreferredBackgroundImageIdPreference(user)
        .then(backgroundImageId => {
          if (this._instructionId === thisInstructionId) {
            if (backgroundImageId) {
              this.setBackgroundByImageId(backgroundImageId);
            } else {
              this._setBackgroundImage(undefined);
            }
          }
        });
    } else {
      this.clearBackgroundImageAsync();
    }
  }

  setBackgroundByImageId = (backgroundImageId: string): void => {
    this._instructionId++;
    const image = backgroundRetriever(backgroundImageId);
    this._setBackgroundImage(image);
    this._currentBackgroundImageId = backgroundImageId;
  }

  clearBackgroundImageAsync = (): void => {
    this._instructionId++;
    const thisInstructionId = this._instructionId;
    const delayMillis = 500;
    setTimeout(() => {
      if (this._instructionId === thisInstructionId) {
        this._setBackgroundImage(undefined);
      }
    }, delayMillis);
  }

  _setBackgroundImage = (image: undefined | any) => {
    if (this._enabled) {
      if (image) {
        const url = `/img/bg/${image.filename}`;
        document.body.style.backgroundImage = `url('${url}')`;
      } else {
        document.body.style.backgroundImage = '';
        this._currentBackgroundImageId = undefined;
      }
      this._instructionId++;
    }
  }

}

export default new BackgroundImageUtil();
