// import ReactGA, { EventArgs, TrackerNames } from 'react-ga';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import analyticsDefinitions from "./AnalyticsDefinitions";

// STREAM URL: http://www.roobrick.org
// GA4 PROPERTY NAME (stream name): Roobrick - GA4
// PROPERTY ID: 312622372
// Tracking ID: UA-134705545-1
// Measurement ID: G-4KCMCJMM1J


// disable to avoid swamping other events
const databasEventsEnabled = false;

class Analytics {

  // Doco: https://github.com/PriceRunner/react-ga4

  initialize = () => {
    setTimeout(() => {
      ReactGA.initialize('G-4KCMCJMM1J');
      
      // No need to send a pageview event here since this is trigered by withPageViewTracker.
      // ReactGA.send("pageview");
    });
  };

  trackPage = (page: string, options?: any) => {
    // GoogleAnalytics.set({
    //   page,
    //   ...options,
    // });
    // // GoogleAnalytics.pageview(page);

    // ReactGA.set();
    ReactGA.send("pageview");
  }

  event = (options: UaEventOptions) => {
    const allowEvent = databasEventsEnabled || options.category !== analyticsDefinitions.categories.database;
    if (allowEvent) {
      ReactGA.event(options);
    }
  };

  databaseRead = (entityName: string, count?: number) => {
    this.event({
      category: analyticsDefinitions.categories.database,
      action: analyticsDefinitions.actions.read,
      label: entityName,
      value: count || 1,
      nonInteraction: true
    });
  };

  databaseWrite = (entityName: string, count?: number) => {
    this.event({
      category: analyticsDefinitions.categories.database,
      action: analyticsDefinitions.actions.write,
      label: entityName,
      value: count || 1,
      nonInteraction: true
    });
  };

  databaseDelete = (entityName: string, count?: number) => {
    this.event({
      category: analyticsDefinitions.categories.database,
      action: analyticsDefinitions.actions.delete,
      label: entityName,
      value: count || 1,
      nonInteraction: true
    });
  };

}

export default new Analytics();

export { analyticsDefinitions };
