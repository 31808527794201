import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import FolderSelector from './FolderSelector';
import ModalDialog from '@atlaskit/modal-dialog';
import ToolbarItem from '../../components/widget/toolbar/ToolbarItem';

export default class FolderSelectorDialog extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      open: props.open
    }
  };

  // onFolderSelection = (folder) => {
  //   this.setState({
  //     open: false
  //   });
  //   this.props.onFolderSelection(folder);
  // };

  onCancelClick = () => {
    this.setState({
      open: false
    });
  };

  render() {
    if (this.state.open) {
      return (
        <ModalDialog
          key="select-folder"
          heading={'Select folder'}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
          onClose={this.hideModal}
        >
          <FolderSelector
            allowFolderSelection={true}
            onFolderSelection={this.props.onFolderSelection}
          />
          <div className="centredContent" style={{marginBottom: '10px'}}>
            <ToolbarItem>
              <Button
                appearance="subtle"
                isDisabled={false}
                autoFocus={false}
                onClick={this.onCancelClick}
              >
                Cancel
              </Button>
            </ToolbarItem>
          </div>
        </ModalDialog>
      );
    } else {
      return null;
    }
  }

}
