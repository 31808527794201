import React, { PureComponent } from 'react';
import adg from '../../../../commonbase/adg';
import currentRubric from "../../../../shared/model/rubric/CurrentRubric";
import dashboardState from './DashboardState';
import LiftedLabel from '../../widget/LiftedLabel';
import LiftedPanel from '../../widget/LiftedPanel';
import session from "../../../../shared/model/auth/Session";
import Spinner from '@atlaskit/spinner';
import { AssessmentIcon, RubricIcon } from '../../icon/NamedIcons';
import widgetUtil from '../../widget/WidgetUtil';
import stringUtil from '../../../../commonbase/stringUtil';
import DashboardInfo from '../../../../shared/model/rubric/dashboard/DashboardInfo';
import ToolbarLeft from '../../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

interface Props {
  showHeading: boolean
  showAssessmentGroups: boolean
  allowSpinner: boolean
}

interface State {
  maxItemsPerGroup: number
  loading: boolean
  recentDefinitionInfos?: any[]
  myOtherDefinitionInfos?: any[]
  otherDefinitionInfos?: any[]
  recentAssessmentItems?: any[]
  myOtherAssessmentItems?: any[]
  otherAssessmentItems?: any[]
  recommendedDefinitionInfos?: any[]
}

export default class DashboardView extends PureComponent<Props, State> {

  mounted = false;

  constructor(props: Props) {
    super(props);
    const loading = !dashboardState.hasReceivedData();
    this.state = this.buildStateFromProps(props, loading);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props, this.state.loading));
  }

  UNSAFE_componentWillMount() {
    session.registerListener(this.onSessionChange);
    dashboardState.registerListener(this.onDashboardStateChange);
  }

  componentDidMount() {
    // console.log('DashboardView.componentDidMount...');
    this.mounted = true;
    this.updateState();
  }

  componentWillUnmount() {
    session.unregisterListener(this.onSessionChange);
    dashboardState.unregisterListener(this.onDashboardStateChange);
  }

  buildStateFromProps = (props: Props, loading: boolean): State => {
    return {
      maxItemsPerGroup: 12,
      loading: loading
    };
  };

  onSessionChange = (user) => {
    this.updateState();
  };

  onDashboardStateChange = (state) => {
    if (this.mounted) {
      this.updateState();
    }
  };

  updateState = () => {
    const state: DashboardInfo = dashboardState.getState();
    const recentDefinitionInfos: any = [];
    const myOtherDefinitionInfos: any = [];
    const otherDefinitionInfos: any = [];
    const currentUser = session.getCurrentUser();
    if (currentUser) {
      const currentUserId = currentUser.getId();
      for (const info of state.definitionInfoLog) {
        const ownerIsMe = info.definitionOwnerId === currentUserId || info.ownerIsMe; // second part is for backward compatibility
        if (recentDefinitionInfos.length < this.state.maxItemsPerGroup) {
          recentDefinitionInfos.push(info);
        } else if (ownerIsMe && myOtherDefinitionInfos.length < this.state.maxItemsPerGroup) {
          myOtherDefinitionInfos.push(info);
        } else if (otherDefinitionInfos.length < this.state.maxItemsPerGroup) {
          // if (info.definitionName.indexOf('empty') > 0) {
          //   debugger;
          // }
          otherDefinitionInfos.push(info);
        }
      }

      const recentAssessmentItems: any = [];
      const myOtherAssessmentItems: any = [];
      const otherAssessmentItems: any = [];
      for (const info of state.assessmentInfoLog) {
        const ownerIsMe = info.definitionOwnerId === currentUserId || info.ownerIsMe; // second part is for backward compatibility
        if (recentAssessmentItems.length < this.state.maxItemsPerGroup) {
          recentAssessmentItems.push(info);
        } else if (ownerIsMe && myOtherAssessmentItems.length < this.state.maxItemsPerGroup) {
          myOtherAssessmentItems.push(info);
        } else if (otherAssessmentItems.length < this.state.maxItemsPerGroup) {
          otherAssessmentItems.push(info);
        }
      }


      const receivedData = dashboardState.hasReceivedData();
      this.setState({
        loading: !receivedData,
        recentDefinitionInfos: recentDefinitionInfos,
        myOtherDefinitionInfos: myOtherDefinitionInfos,
        otherDefinitionInfos: otherDefinitionInfos,
        recentAssessmentItems: recentAssessmentItems,
        myOtherAssessmentItems: myOtherAssessmentItems,
        otherAssessmentItems: otherAssessmentItems,
        recommendedDefinitionInfos: state.recommendedDefinitionInfos
      });
    }
  };

  onDefinitionClick = (definitionUuid) => {
    const inEditMode = false;
    currentRubric.setDefinitionByUuid(definitionUuid, inEditMode);
  };

  onAssessmentClick = (definitionUuid, assessmentUuid) => {
    const inEditMode = false;
    currentRubric.setDefinitionAndAssessmentUuids(definitionUuid, assessmentUuid, inEditMode);
  };

  renderDefinitionsGroup = (title, infosName) => {
    const infos = this.state && this.state[infosName] ?
      this.state[infosName] : [];
    if (infos && infos.length) {
      return (
        <div>
          <LiftedLabel text={title} />
          {this.renderDefinitionInfos(infos)}
        </div>
      );
    } else {
      return null;
    }
  };

  renderAssessmentsGroup = (title, infosName) => {
    const infos = this.state && this.state[infosName] ?
      this.state[infosName] : [];
    if (infos && infos.length) {
      return (
        <div>
          <LiftedLabel text={title}/>
          {this.renderAssessmentItems(infos)}
        </div>
      );
    } else {
      return null;
    }
  };

  renderDefinitionInfos = (infos) => {
    const renderedInfos = infos.map((definitionInfo, index) => {
      if (index >= this.state.maxItemsPerGroup) {
        return null;
      } else {
        return this.renderDefinition(definitionInfo);
      }
    });
    return (
      <div className="definitionsGroup">
        {renderedInfos}
      </div>
    );
  };

  renderAssessmentItems = (assessmentInfoLog) => {
    const renderedInfos = assessmentInfoLog.map((assessmentItem, index) => {
      if (index >= this.state.maxItemsPerGroup) {
        return null;
      } else {
        return this.renderAssessment(assessmentItem);
      }
    });
    return (
      <div className="definitionsGroup">
        {renderedInfos}
      </div>
    );
  };

  renderDefinition = (definitionInfo) => {
    let introMarkdown = definitionInfo.definitionDescription;
    if (introMarkdown) {
      const lineCount = stringUtil.countLines(introMarkdown);
      if (lineCount > 5 && introMarkdown.indexOf('#') === 0) {
        // Strip the first line because it's a heading
        const lineEndIndex = introMarkdown.indexOf('\n');
        introMarkdown = introMarkdown.substring(lineEndIndex).trim();
      }
    }
    if (!introMarkdown) {
      introMarkdown = 'This rubric has no description.'
    }
    const descriptionHtml = widgetUtil.markdownToHtml(introMarkdown);
    return (
      <div
        key={definitionInfo.definitionUuid}
        className="definition-panel clickable"
        onClick={() => this.onDefinitionClick(definitionInfo.definitionUuid)}
      >
        <LiftedPanel width="100%">
          <ToolbarLeft>
            <ToolbarItem style={{color: adg.adgBlue}}>
              <RubricIcon/>
            </ToolbarItem>
            <ToolbarItem>
              <span style={{fontWeight: 'bold'}}>
                {definitionInfo.definitionName}
              </span>
            </ToolbarItem>
          </ToolbarLeft>
          <p
            className="definition-summary-description"
            dangerouslySetInnerHTML={{__html: descriptionHtml}}
          >
          </p>
        </LiftedPanel>
      </div>
    );
  };

  renderAssessment = (assessmentItem) => {
    let descriptionHtml = '<b>' + assessmentItem.definitionName + '</b>';
    if (assessmentItem.definitionDescription) {
      descriptionHtml += ': ' + assessmentItem.definitionDescription;
    }
    return (
      <div
        key={assessmentItem.assessmentUuid}
        className="definition-panel clickable"
        onClick={() => this.onAssessmentClick(assessmentItem.definitionUuid, assessmentItem.assessmentUuid)}
      >
        <ToolbarLeft>
          <ToolbarItem style={{color: adg.adgBlue}}>
            <AssessmentIcon/>
          </ToolbarItem>
          <ToolbarItem>
            <span style={{fontWeight: 'bold'}}>
              {assessmentItem.assessmentName}
            </span>
          </ToolbarItem>
        </ToolbarLeft>
        <p
          className="definition-summary-description"
          dangerouslySetInnerHTML={{__html: descriptionHtml}}
        >
        </p>
      </div>
    );
  };

  renderClearPanel = () => {
    return (
      <div style={{clear:'both'}}>
      </div>
    );
  };

  renderHeading = () => {
    return (
      <h2>
        Dashboard
      </h2>
    );
  };

  renderSpinner = () => {
    const style = {
      opacity: 0.5
    };
    return (
      <div
        className="centredContent"
        style={style}
      >
        <Spinner size="xlarge"/>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.showHeading ? this.renderHeading() : null}
        {this.state.loading && this.props.allowSpinner ? this.renderSpinner() : null}
        {this.props.showAssessmentGroups ? this.renderAssessmentsGroup('Recent assessments', 'recentAssessmentItems') : null}
        {this.renderDefinitionsGroup('Recent rubrics', 'recentDefinitionInfos')}
        {this.props.showAssessmentGroups ? this.renderAssessmentsGroup('My other assessments', 'myOtherAssessmentItems') : null}
        {this.renderDefinitionsGroup('My other rubrics', 'myOtherDefinitionInfos')}
        {this.props.showAssessmentGroups ? this.renderAssessmentsGroup('Other assessments', 'otherAssessmentItems') : null}
        {this.renderDefinitionsGroup('Other rubrics', 'otherDefinitionInfos')}
        {this.renderDefinitionsGroup('Recommended rubrics', 'recommendedDefinitionInfos')}
      </div>
    );
  }
}
