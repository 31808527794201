import ContactBuilder from '../../../shared/model/network/ContactBuilder';
import ListenerGroup from '../../../shared/util/ListenerGroup';
import session from '../../../shared/model/auth/Session';
import networkConstants from '../../../shared/model/network/NetworkConstants';
import networkDAO from '../../../backend/data/NetworkDAO';
import networkUtil from '../../../shared/model/network/NetworkUtil';
import IUser from '../../../shared/model/auth/IUser';
import Contact from '../../../shared/model/network/Contact';

class NetworkGraph {

  currentUser: undefined | IUser;
  network: any;
  listenerGroup = new ListenerGroup('NetworkGraph');

  constructor() {
    this.currentUser = session.getCurrentUser();
    this.network = this._buildDefaultNetwork();
    session.registerListener(this._onSessionChange);
    this._buildState(this.currentUser);
  }

  iterateContacts = (callback: (contact: Contact) => void) => {
    for (let uuid in this.network.contactUuidsToContacts) {
      if (this.network.contactUuidsToContacts.hasOwnProperty(uuid)) {
        const contact = this.network.contactUuidsToContacts[uuid];
        callback(contact);
      }
    }
  };

  getNameByEmail = (email: string) => {
    const contact = this.getContactByEmail(email);
    return contact ? contact.name : undefined;
  };

  getContactByEmail = (email: string) => {
    if (email) {
      const user = session.getCurrentUser();
      if (user && networkUtil.equalEmails(user.getEmail(), email)) {
        return new ContactBuilder()
          .setUuid('me')
          .setUser(user)
          .build()
      } else {
        for (let uuid in this.network.contactUuidsToContacts) {
          if (this.network.contactUuidsToContacts.hasOwnProperty(uuid)) {
            const contact = this.network.contactUuidsToContacts[uuid];
            if (networkUtil.equalEmails(contact.email, email)) {
              return contact
            }
          }
        }
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  _addDefaultContacts = () => {
    let changed = false;
    const everyoneUuid = 'everyone';
    const everyoneEmail = networkConstants.everyoneEmailAddress;
    let everyoneContact = this.network.contactUuidsToContacts[everyoneUuid];

    if (!everyoneContact) {
      everyoneContact = new ContactBuilder()
        .setUuid(everyoneUuid)
        .setName('Everyone')
        .setEmail(everyoneEmail)
        .build();
      this.network.contactUuidsToContacts[everyoneUuid] = everyoneContact;
      changed = true;
    }

    if (this.currentUser) {
      const myEmailDomain = this.currentUser.getEmailDomain();
      const everyoneAtMyDomainUuid = 'everyone-at-my-domain';
      const everyoneAtMyDomainEmail = '*@' + myEmailDomain;
      let everyoneAtMyDomainContact = this.network.contactUuidsToContacts[everyoneAtMyDomainUuid];
      if (!everyoneAtMyDomainContact) {
        everyoneAtMyDomainContact = new ContactBuilder()
          .setUuid(everyoneAtMyDomainUuid)
          .setName('Everyone @' + myEmailDomain)
          .setEmail(everyoneAtMyDomainEmail)
          .build();
        this.network.contactUuidsToContacts[everyoneAtMyDomainUuid] = everyoneAtMyDomainContact;
        changed = true;
      }
    }
    if (changed) {
      const user = session.getCurrentUser();
      return networkDAO.saveNetwork(user, this.network).then((network) => {
        return network;
      }).catch((error) => {
        console.error(error);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(this.network);
      });
    }
  };

  addContact = (contact: Contact) => {
    if (contact) {
      if (!contact.name) {
        return new Promise((resolve, reject) => {
          reject(`All contacts must have a name.`);
        });
      }
      if (!contact.email) {
        return new Promise((resolve, reject) => {
          reject(`All contacts must have an email.`);
        });
      }
      if (this.findContactByEmail(contact.email)) {
        return new Promise((resolve, reject) => {
          reject(`A contact with email ${contact.email} already exists.`);
        });
      } else {
        this.network.contactUuidsToContacts[contact.uuid] = contact;
        const user = session.getCurrentUser();
        return networkDAO.saveNetwork(user, this.network);
      }
    } else {
      return new Promise((resolve, reject) => {
        reject(`No contact provided.`);
      });
    }
  };

  deleteContactByUuid = (contactId: string) => {
    delete this.network.contactUuidsToContacts[contactId];
    const user = session.getCurrentUser();
    return networkDAO.saveNetwork(user, this.network);
  };

  findContactByEmail = (email: string): undefined | Contact => {
    if (email) {
      let foundContact: undefined | Contact = undefined;
      this.iterateContacts((contact) => {
        const equal =
          contact.email &&
          (contact.email === email || contact.email.toLowerCase() === email.toLowerCase());
        if (equal) {
          foundContact = contact;
        }
      });
      return foundContact;
    } else {
      return undefined;
    }
  };

  _onSessionChange = (user: IUser) => {
    this.currentUser = user;
    this._buildState(user);
  };
  
  _buildState = (user: undefined | IUser) => {
    if (user) {
      networkDAO.getNetwork(user)
        .then((network) => {
          if (network) {
            this.network = network;
          } else {
            this._createInitialNetwork(user);
          }
          return this._addDefaultContacts().then(() => {
            this._notifyListeners();
            return this.network;
          });
        }).catch((error) => {
          console.error(error);
        });
    } else {
      this.network = this._buildDefaultNetwork();
      this._notifyListeners();
    }
  };

  _buildDefaultNetwork = () => {
    return {
      contactUuidsToContacts: {}
    }
  };

  _createInitialNetwork = (user: IUser) => {
    if (user) {
      // Check the session user hasn't changed...
      const queriedUser = session.getCurrentUser();
      if (queriedUser && queriedUser.getId() === user.getId()) {
        const network = this._buildDefaultNetwork();
        networkDAO.saveNetwork(user, network)
          .then(() => {
            this.network = network;
            this._notifyListeners();
          });
      }
    }
  };

  _notifyListeners = () => {
    const newState = this.getState();
    this.listenerGroup.notifyListeners(newState);
  };

  // API

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  getState = () => {
    return {
      network: this.network
    }
  };

}

export default new NetworkGraph();
