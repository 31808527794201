import React, { PureComponent } from "react";
import LiftedPanel from './LiftedPanel';

interface Props {
  children?: React.ReactNode;
}

interface State {
}

export default class LiftedPageTitle extends PureComponent<Props, State> {

  render() {
    return (
      <LiftedPanel isText={true} width="min-content">
        <h1>{this.props.children}</h1>
      </LiftedPanel>
    );
  }

}
