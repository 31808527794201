import React, { PureComponent } from 'react';
import adg from '../../commonbase/adg';
import ContentWrapper from '../components/widget/ContentWrapper';
import currentNotifications from '../../backend/data/CurrentNotifications';
import currentNotificationsRefreshState from '../../backend/data/CurrentNotificationsRefreshState';
import IconButton from '../components/widget/IconButton';
import LiftedLabel from '../components/widget/LiftedLabel';
import LiftedPanel from '../components/widget/LiftedPanel';
import LiftedPageTitle from '../components/widget/LiftedPageTitle';
import currentRubric from "../../shared/model/rubric/CurrentRubric";
import permissionUtil from "../../shared/model/auth/PermissionUtil";
import rubricPageHelper from './RubricPageHelper';
import session from '../../shared/model/auth/Session';
import shareStateDefinitions from "../../shared/model/rubric/ShareStateDefinitions";
import { statusDefinitions, StatusProvider } from '../../shared/model/status/Status';
import Tooltip from "@atlaskit/tooltip";
import {
  AcceptNotificationIcon, AcceptNotificationCircleIcon,
  RejectNotificationIcon, RejectNotificationCircleIcon,
  RefreshIcon,
  ViewRubricIcon, ViewRubricFilledIcon
} from '../components/icon/NamedIcons';
import ToolbarJustify from '../components/widget/toolbar/ToolbarJustify';
import ToolbarItem from '../components/widget/toolbar/ToolbarItem';
import ToolbarRight from '../components/widget/toolbar/ToolbarRight';

export default class NotificationsPage extends PureComponent {

  constructor(props) {
    super(props);
    this.statusProvider = new StatusProvider('NotificationsPage');
  }

  UNSAFE_componentWillMount() {
    this.statusProvider.setActive(true);
    // console.log('NotificationsPage.componentWillMount...');
    session.registerListener(this.onSessionChange);
    currentNotificationsRefreshState.registerListener(this.onCurrentNotificationsRefreshStateChange);
    currentNotifications.registerListener(this.onCurrentNotificationsChange);
    const notifications = currentNotifications.getNotifications();
    this.setState({
      notifications: notifications
    });
    this.updateStatus(notifications);
    // this.updateLastRetrievalTime();
  }

  componentDidMount() {
    rubricPageHelper.updateTitle('Notifications');
    // console.log('NotificationsPage.componentDidMount...');
    this.mounted = true;
    this.updateLastRetrievalTime();
  }

  componentWillUnmount() {
    this.statusProvider.setActive(false);
    // console.log('NotificationsPage.componentWillUnmount...');
    session.unregisterListener(this.onSessionChange);
    currentNotificationsRefreshState.unregisterListener(this.onCurrentNotificationsRefreshStateChange);
    currentNotifications.unregisterListener(this.onCurrentNotificationsChange);
    this.mounted = false;
  }

  updateLastRetrievalTime = () => {
    const lastRetrievalTime = currentNotificationsRefreshState.getLastRetrievalTime();
    const currentTime = new Date().getTime();
    const secondsSinceLastRetrieval = (currentTime - lastRetrievalTime) / 1000;
    if (this.mounted) {
      this.setState({
        lastRetrievalTime: lastRetrievalTime,
        secondsSinceLastRetrieval: secondsSinceLastRetrieval
      });
    } else if (this.state) {
      // Ignore: fetch status during next mount.
    }
  };

  updateStatus = (notifications) => {
    const outstandingCount = this.countNotificationsInState(notifications, shareStateDefinitions.outstandingShareState, false);
    if (outstandingCount === 0) {
      this.statusProvider.setCurrentStatus(
        statusDefinitions.statuses.allOK,
        'You have no unacknowledged notifications');
    } else {
      const message = outstandingCount === 1 ?
        'You have one unacknowledged notification' :
        'You have ' + outstandingCount + ' unacknowledged notifications';
      this.statusProvider.setCurrentStatus(
        statusDefinitions.statuses.mayNeedWork, message);
    }
  };

  onSessionChange = (user) => {
  };

  onRefreshNotificationsClick = () => {
    currentNotifications.rebuildCurrentNotifications();
  };

  onCurrentNotificationsRefreshStateChange = () => {
    this.updateLastRetrievalTime();
  };

  onCurrentNotificationsChange = (notifications) => {
    if (this.mounted) {
      this.setState({
        notifications: notifications
      });
      this.updateStatus(notifications);
      this.forceUpdate();
    } else {
      // Ignore: fetch status during next mount.
    }
  };

  onAcknowledgeNotification = (notification) => {
    currentNotifications.acknowledgeNotification(notification);
  };

  onRejectNotification = (notification) => {
    currentNotifications.rejectNotification(notification);
  };

  onVisitNotification = (notification) => {
    if (notification.definitionUuid && notification.definitionName) {
      const inEditMode = false;
      if (notification.assessmentUuid) {
        currentRubric.setDefinitionAndAssessmentUuids(
          notification.definitionUuid, notification.assessmentUuid, inEditMode);
      } else {
        currentRubric.setDefinitionByUuid(notification.definitionUuid, inEditMode);
      }
    }
  };

  renderAcknowledgeNotificationButton = (notification) => {
    if (permissionUtil.canAcknowledgeNotification(notification)) {
      return (
        <div
          style={{
            float: 'right',
            width: '24px',
            marginTop: '-8px'
          }}
        >
          <Tooltip content="Acknowledge this notification">
            <IconButton
              normalIcon={<AcceptNotificationIcon/>}
              hoverIcon={<AcceptNotificationCircleIcon/>}
              normalColor={undefined}
              hoverColor={adg.adgGreen}
              onClick={() => this.onAcknowledgeNotification(notification)}
            />
          </Tooltip>
        </div>
      );
    } else {
      return null;
    }
  };

  renderRejectNotificationButton = (notification) => {
    if (permissionUtil.canRejectNotification(notification)) {
      return (
        <div
          style={{
            float: 'right',
            width: '24px',
            marginTop: '-8px'
          }}
        >
          <Tooltip content="Reject this notification">
            <IconButton
              normalIcon={<RejectNotificationIcon/>}
              hoverIcon={<RejectNotificationCircleIcon/>}
              normalColor={undefined}
              hoverColor={adg.adgRed}
              onClick={() => this.onRejectNotification(notification)}
            />
          </Tooltip>
        </div>
      );
    } else {
      return null;
    }
  };

  renderViewNotificationButton = (notification) => {
    return (
      <div
        style={{
          float: 'right',
          width: '24px',
          marginTop: '-8px'
        }}
      >
        <Tooltip content="View the rubric">
          <IconButton
            normalIcon={<ViewRubricIcon/>}
            hoverIcon={<ViewRubricFilledIcon/>}
            normalColor={undefined}
            hoverColor={adg.adgBlue}
            onClick={() => this.onVisitNotification(notification)}
          />
        </Tooltip>
      </div>
    );
  };

  countNotificationsInState = (notifications, shareState, outbound) => {
    const currentUser = session.getCurrentUser();
    let count = 0;
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      const matches = this.matchesState(currentUser, notification, shareState, outbound);
      if (matches) {
        count++;
      }
    }
    return count;
  };

  matchesState = (currentUser, notification, shareState, outbound) => {
    let matches = true;
    if (outbound) {
      const currentUserId = currentUser.getId();
      matches = matches && notification.fromUserId === currentUserId;
    } else {
      const currentUserEmail = currentUser.getEmail();
      matches = matches && notification.targetUserEmail === currentUserEmail;
    }
    if (shareState) {
      matches = matches && notification.shareState === shareState;
    }
    return matches;
  };

  renderNotifications = (shareState, outbound, isViewable) => {
    const currentUser = session.getCurrentUser();
    const renderedNotifications = [];
    for (let i = 0; i < this.state.notifications.length; i++) {
      const notification = this.state.notifications[i];
      // let matches = true;
      // if (outbound) {
      //   const currentUserId = currentUser.getId();
      //   matches = matches && notification.fromUserId === currentUserId;
      // } else {
      //   const currentUserEmail = currentUser.getEmail();
      //   matches = matches && notification.targetUserEmail === currentUserEmail;
      // }
      // if (shareState) {
      //   matches = matches && notification.shareState === shareState;
      // }
      const matches = this.matchesState(currentUser, notification, shareState, outbound);
      if (matches) {
        const renderedNotification = (
          <div
            key={notification.uuid}
            className="definition-panel"
          >
            <LiftedPanel>
              {this.renderAcknowledgeNotificationButton(notification)}
              {this.renderRejectNotificationButton(notification)}
              {isViewable ? this.renderViewNotificationButton(notification) : null}
              <span
                style={{
                  fontWeight: 'bold'
                }}
              >
                {notification.title}
              </span>
              <p className="definition-summary-description">
                {notification.message}
              </p>
            </LiftedPanel>
          </div>
        );
        renderedNotifications.push(renderedNotification);
      }
    }
    return renderedNotifications;
  };

  renderNotificationsPanel = (title, shareState, outbound, isViewable) => {
    const renderedNotifications = this.renderNotifications(shareState, outbound, isViewable);
    if (renderedNotifications && renderedNotifications.length) {
      return (
        <div>
          <LiftedLabel text={title} />
          <div className="definitionsGroup">
            {renderedNotifications}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  renderRefreshNotificationsToolbarItem = () => {
    const lastRetrievalTime = currentNotifications.getLastRetrievalTime();
    const currentTime = new Date().getTime();
    const millisecondsSinceLastRetrieval = currentTime - lastRetrievalTime;
    let color = undefined;
    if (millisecondsSinceLastRetrieval > currentNotificationsRefreshState.millisecondToRefreshHighlighting) {
      color = adg.adgRed;
    }
    return (
      <ToolbarItem>
        <Tooltip content="Check for notifications" position="left">
          <IconButton
            normalIcon={<RefreshIcon/>}
            hoverIcon={<RefreshIcon/>}
            normalColor={color}
            hoverColor={color}
            onClick={this.onRefreshNotificationsClick}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderPageToolbar = () => {
    return (
      <ToolbarRight>
        {this.renderRefreshNotificationsToolbarItem()}
      </ToolbarRight>
    );
  };

  renderPageTitleBar = () => {
    return (
      <ToolbarJustify>
        <div>
          <LiftedPageTitle>Notifications</LiftedPageTitle>
        </div>
        <div>
          {this.renderPageToolbar()}
        </div>
      </ToolbarJustify>
    );
  };

  renderNotificationsPage = () => {
    return (
      <ContentWrapper>
        {this.renderPageTitleBar()}
        {this.renderNotificationsPanel('New notifications', shareStateDefinitions.outstandingShareState, false, true)}
        <div style={{clear: 'both'}}>
        </div>
        {this.renderNotificationsPanel('Acknowledged notifications', shareStateDefinitions.acceptedShareState, false, true)}
        <div style={{clear: 'both'}}>
        </div>
        {this.renderNotificationsPanel('Rejected notifications', shareStateDefinitions.rejectedShareState, false, false)}
        <div style={{clear: 'both'}}>
        </div>
        {this.renderNotificationsPanel('Sent notifications', null, true, true)}
      </ContentWrapper>
    );
  };

  render() {
    const user = session.getCurrentUser();
    if (user) {
      return this.renderNotificationsPage();
    } else {
      return null;
    }
  }
}
