
class BrowserType {

  mobile: boolean;

  constructor() {
    this.mobile = false;
    if (typeof window.orientation !== "undefined") {
      this.mobile = true;
    } else {
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf('IEMobile') !== -1) {
        this.mobile = true;
      }
    }
  }

  isMobile = () => {
    return this.mobile;
  };

}

export default new BrowserType();
