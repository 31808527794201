import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import constants from '../../shared/model/Constants';
import cellEditingImage from '../images/screenshot/screenshot-cell-editing.png';
import navConstants from '../components/nav/NavConstants';
import PageTitle from '../components/widget/PageTitle';
import rubricPageHelper from './RubricPageHelper';
import RubricLogo from '../images/roobrick-logo-v4.svg';
import SignInWithGoogleButton from '../components/widget/SignInWithGoogleButton';
import session from '../../shared/model/auth/Session';
import TextPanel from '../components/widget/TextPanel';

export default class AboutPage extends PureComponent {

  constructor(props) {
    super(props);
    const user = session.getCurrentUser();
    this.state = {
      loggedIn: user !== null
    }
  }

  UNSAFE_componentWillMount() {
    session.registerListener(this.onSessionChange);
  }

  componentDidMount() {
    rubricPageHelper.updateTitle('About');
  }

  componentWillUnmount() {
    session.unregisterListener(this.onSessionChange);
  }

  onSessionChange = (user) => {
    this.setState({
      loggedIn: user !== null
    });
  };

  renderSignInButton = () => {
    return (
      <SignInWithGoogleButton
      />
    );
  };

  renderLoggedOutRightFloat = () => {
    return (
      <React.Fragment>
        <div>
          {this.renderLogo()}
        </div>

        <div className="newMainSection">
          <h3>Secure and trusted sign in</h3>
          <div className="newMinorSection">
            There's no need to create new credentials,
            simply <a href={'#' + navConstants.signInPageHash}>sign in</a> with
            your Google account.
          </div>
          <div className="newMinorSection" style={{textAlign: 'center'}}>
            {this.state.loggedIn ? null : this.renderSignInButton()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderLogo = () => {
    return (
      <React.Fragment>
        <img
          src={RubricLogo}
          alt="Roobrick"
          width="100%"
        />
      </React.Fragment>
    );
  };

  renderLoggedInRightFloat = () => {
    return this.renderLogo();
  };

  renderRightFloat = () => {
    return (
      <div style={{float: 'right', width: '300px', marginLeft: '40px', marginBottom: '20px'}}>
        {this.state.loggedIn ? this.renderLoggedInRightFloat() : this.renderLoggedOutRightFloat()}
      </div>
    );
  };

  renderPanel = () => {

  };

  renderExplainer = () => {
    const sizeFactor = 1.8;
    const width = 560 * sizeFactor;
    const height = 315 * sizeFactor;
    return (
      <React.Fragment>
        <div style={{
          marginTop: '40px',
          marginBottom: '40px',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: `${width}px`
        }}>
          <iframe
            title={`{constants.appTitle} explainer`}
            width={width}
            height={height}
            src="https://www.youtube.com/embed/OV7aExzHfSY"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
        <div style={{clear: 'both'}}></div>
      </React.Fragment>
    );
  }

  renderWhy = () => {
    return (
      <React.Fragment>
        {this.renderRightFloat()}

        <h3>Why {constants.appTitle}?</h3>

        <div>
          <TextPanel>
            <p>
              {constants.appTitle} is a solution enabling people to <strong>methodically</strong> make <strong>assessments</strong> of just about <strong>anything.</strong>
            </p>
          </TextPanel>

          <TextPanel>
            <div>
              <p>
                Making <b>assessments</b> is a common activity:
              </p>
              <ul>
                <li>
                  Talent acquisition
                </li>
                <li>
                  Descision making
                </li>
                <li>
                  Staff reviews
                </li>
                <li>
                  Academic assignments
                </li>
                <li>
                  Issue severity
                </li>
              </ul>
            </div>
          </TextPanel>

          <TextPanel>
            <p>
              Unfortunately, <b>poor assessments</b> are equally common:
            </p>
            <ul>
              <li>
                Unclear grading criteria
              </li>
              <li>
                Inconsistent interpretations of criteria
              </li>
              <li>
                Incompleteness against criteria
              </li>
              <li>
                Incorporation of non-criteria
              </li>
              <li>
                Cognitive biases
              </li>
            </ul>
          </TextPanel>

          <TextPanel>
            <p>
              Using {constants.appTitle} results in <b>improved assessments</b>:
            </p>
            <ul>
              <li>
                Increased <b>rigor</b> of assessments:
                <ul>
                  <li>
                    structure
                  </li>
                  <li>
                    guided scoring
                  </li>
                  <li>
                    visualizations
                  </li>
                </ul>
              </li>
              <li>
                Greater <b>efficiency</b>:
                <ul>
                  <li>
                    Custom solution
                  </li>
                  <li>
                    Collaboration
                  </li>
                </ul>
              </li>
            </ul>
          </TextPanel>
        </div>
      </React.Fragment>
    );
  };

  renderWhat = () => {
    return (
      <React.Fragment>
        <h3>What is a rubric?</h3>
        <p>
          Rubrics provide a structured way to assess all kinds of things.
          Rubrics are organised as grids where the columns generally identify levels of achievement
          and the rows usually define criteria.
        </p>
        <p>
          Here are some different kinds uses for rubrics:
        </p>
        <ul>
          <li>
            <b>School assignments</b>: Teachers often define assignment expectations in the form of <i>analytic</i> rubrics, although
            sometimes <i>single-point</i> or <i>holistic</i> rubrics are used.
          </li>
          <li>
            <b>Level based performance</b>: There are many cases where a discipline is defined in terms of a sequence of <i>levels</i>.
            Examples include job levels in an organisation and achievement levels in a clubs such as the boy scouts.
          </li>
          <li>
            <b>Decisions</b>: Making decisions involves identifying options and weighing up the pros and cons of each aspect
            of each option.
          </li>
          <li>
            <b>Task lists</b>: Outside of {constants.appTitle}, rubrics are not typically used for managing lists of tasks.
            With rubric, however, it's easy to add criteria and mark progress against them in the form of an assessment.
          </li>
        </ul>
        <p>
          The <a href={'#' + navConstants.rubricCreationuidePageHash}>rubric creation guide</a> is
          a great place for an introduction to the various types of rubrics possible.
        </p>

        <h3>Assessments made easy</h3>
        <img
          style={{
            width: '317px',
            float: 'right',
            marginLeft: '2    0px'
          }}
          alt="Rubric assessment"
          src={cellEditingImage}
        />
        <p>
          The secret sauce of {constants.appTitle} is in its assessment capabilities.
          Assessments can be made right against the criteria which provides full context
          about the scores and comments made.
          Scoring is as simple as dragging a slider against any criteria statement.
          Individual statement scores roll up to an average score for the column (achievement level).
        </p>
      </React.Fragment>
    );
  };

  render() {
    return (
      <ContentWrapper>
        <PageTitle>About {constants.appTitle}</PageTitle>
        <div style={{ marginTop: '20px' }}>
          {this.renderExplainer()}
          {this.renderWhy()}
          {this.renderWhat()}
        </div>
      </ContentWrapper>
    );
  }
}
