import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import BoardView from '../components/rubric/board/BoardView';
import rubricPageHelper from './RubricPageHelper';

export default class BoardPage extends PureComponent {

  componentDidMount() {
    rubricPageHelper.updateTitle('Assessment board');
  }

  render() {
    return (
      <ContentWrapper>
        <BoardView showHeading={true} allowSpinner={true}/>
      </ContentWrapper>
    );
  }

}
