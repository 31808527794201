const customLevelsColorRange = require('../color/customLevelsColorRange');

class CustomLevelsScoreMetaInfoBuilder {

  constructor() {
    this.info = {
      label: '',
      levels: []
    }
  }

  setLabel(label) {
    if (label !== undefined) {
      this.info.label = label;
    }
    return this;
  }

  addLevel(level) {
    this.info.levels.push(level);
    return this;
  }

  build() {
    const markers = [];
    const scoreRangePerLevel = 100 / (this.info.levels.length - 1);
    let score = 0;
    for (let i = 0; i < this.info.levels.length; i++) {
      const level = this.info.levels[i];
      const factorFromStart = score / 100;
      const color = customLevelsColorRange.computeColor(factorFromStart);
      markers.push({
        score: Math.round(score),
        label: level.label,
        tooltip: level.tooltip,
        color: color
      });
      score += scoreRangePerLevel;
    }
    return {
      label: this.info.label,
      markers: markers
    }
  }

}

module.exports = CustomLevelsScoreMetaInfoBuilder;