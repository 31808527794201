const adg = {

  // https://www.atlassian.design/foundations/color

  transparent: 'transparent',
  none: 'none',
  white: '#fff',
  veryLightGrey: '#f5f5f5',
  extremelyLightGrey: '#fafafa',
  placeholderColor: '#777',
  widgetBackgroundColorHover: '#EBECF0',

  globalSidebarColor: '#0747A6',

  // Core colours
  adgRed: '#d04437',
  adgNavy: '#205081',
  adgLightGrey: '#f5f5f5',
  adgCharcoal: '#333333',
  adgBlue: '#3572b0',
  adgPaleBlue: '#ebf2f9',
  adgMediumGray: '#707070',
  adgGreen: '#14892c',
  adgYellow: '#f6c342',

  // Primary colours - codes
  adgN800: '#172B4D',
  adgB400: '#0052CC',
  adgNO: '#FFFFFF',

  // Secondary colours - codes
  adgR300: '#FF5630',
  adgY300: '#FFAB00',
  adgG300: '#36B37E',
  adgT300: '#00B8D9',
  adgP300: '#6554C0',

  // Secondary colours - names
  adgRosie: '#FFEBE5',
  adgGray: '#999999',
  adgAshGray: '#cccccc',
  adgSilver: '#e9e9e9',
  adgBrown: '#815b3a',
  adgCheetoOrange: '#f79232',
  adgTan: '#f1a257',
  adgLightBrown: '#d39c3f',
  adgCyan: '#59afe1',
  adgSlate: '#4a6785',
  adgCoolBlue: '#84bbc6',
  adgLimeGreen: '#8eb021',
  adgMidGreen: '#67ab49',
  adgViolet: '#654982',
  adgMauve: '#ac707a',
  adgBrightPink: '#f15c75',

  // Greens
  G500: '#006644', // Keen Gren
  G400: '#00875A', // Slime
  G300: '#36B37E', // Our Kellie
  G200: '#57D9A3', // Green Tea
  G100: '#79F2C0', // Cloverleaf
  G75: '#ACF5D2',  // Mintie
  G50: '#E3FCEF',  // The smell

  // Blues
  B500: '#0747A6', // Chore Coat
  B400: '#0052CC', // Pacific Bridge
  B300: '#0065FF', // Sodium Explosion
  B200: '#2684FF', // Coogee
  B100: '#4C9AFF', // Arvo Breeze
  B75: '#B3D4FF',  // Schwag
  B50: '#DEEBFF',  // Pixie Dust

  // Purples
  P500: '#403294', // Prince
  P400: '#5243AA', // Snozzberry
  P300: '#6554C0', // Da' juice
  P200: '#8777D9', // Pastelli
  P100: '#998DD9', // Herky jerky
  P75: '#C0B6F2',  // Phantom mist
  P50: '#EAE6FF',  // Lavender secret

  // Yellows
  adgY100: '#FFE380',
  adgY75: '#FFF0B3',
  adgY50: '#FFFAE6',
}

// Primary colours - named
adg.adgPrimaryBlue = adg.adgB400;
adg.adgSuccess = adg.adgG300;
adg.adgInfo = adg.adgT300; // ???
adg.adgWarning = adg.adgY300;
adg.adgError = adg.adgR300;

adg.primaryColors = [
  adg.adgRed,
  adg.adgNavy,
  adg.adgLightGrey,
  adg.adgCharcoal,
  adg.adgBlue,
  adg.adgPaleBlue,
  adg.adgMediumGray,
  adg.adgGreen,
  adg.adgYellow
];

adg.getPrimaryColors = function() {
  return adg.primaryColors;
};

adg.getSecondaryColors = function() {
  return [
    adg.adgRosie,
    adg.adgBrown,
    adg.adgCheetoOrange,
    adg.adgTan,
    adg.adgLightBrown,
    adg.adgCyan,
    adg.adgGray,
    adg.adgSlate,
    adg.adgCoolBlue,
    adg.adgLimeGreen,
    adg.adgSilver,
    adg.adgAshGray,
    adg.adgMidGreen,
    adg.adgViolet,
    adg.adgMauve,
    adg.adgBrightPink];
};

adg.getWhiteContrastColors = function() {
  return [
    adg.adgRed,
    adg.adgBlue,
    adg.adgGreen,
    adg.adgRosie,
    adg.adgBrown,
    adg.adgCheetoOrange,
    adg.adgTan,
    adg.adgNavy,
    adg.adgLightBrown,
    adg.adgCyan,
    adg.adgCharcoal,
    adg.adgGray,
    adg.adgSlate,
    adg.adgCoolBlue,
    adg.adgLimeGreen,
    adg.adgAshGray,
    adg.adgMidGreen,
    adg.adgMediumGray,
    adg.adgViolet,
    adg.adgMauve,
    adg.adgBrightPink];
};

adg.getAnyPrimaryColorByIndex = function(index) {
  const count = adg.primaryColors.length;
  const lookupIndex = index % count;
  return adg.primaryColors[lookupIndex];
};


module.exports = adg;


