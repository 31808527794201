import React, { PureComponent } from 'react';
import adg from '../../../commonbase/adg';
import PathBuilder from '../svg/PathBuilder';
import OpacityHelper from './OpacityHelper';

export default class AddRowIcon extends PureComponent {

  constructor(props) {
    super(props);
    this.opacityHelper = new OpacityHelper(this);
  }

  render() {
    const width = this.props.width;
    const height = this.props.height;
    const arrowLeftX = width / 2;
    const plusTop = height / 4;
    const plusBottom = plusTop + height / 2;
    const plusLeft = width / 6;
    const plusRight = plusLeft + width / 2;
    const plusMiddle = (plusLeft + plusRight) / 2;
    const shape = new PathBuilder('main-fill')
      .setPathColor(adg.adgGreen)
      .setFill(adg.adgGreen)
      .setPathWidth(2)
      .moveTo(0, 0)
      .lineTo(arrowLeftX, 0)
      .lineTo(width, height/2)
      .lineTo(arrowLeftX, height)
      .lineTo(0, height)
      .lineTo(0, 0)
      .close()
      .render();
    const plusLine1 = new PathBuilder('line-a')
      .setPathColor('#fff')
      .setPathWidth(2)
      .moveTo(plusMiddle, plusTop)
      .lineTo(plusMiddle, plusBottom)
      .render();
    const plusLine2 = new PathBuilder('line-b')
      .setPathColor('#fff')
      .setPathWidth(2)
      .moveTo(plusLeft, height / 2)
      .lineTo(plusRight, height / 2)
      .render();
    return (
      <svg
        style={this.opacityHelper.buildStyle()}
        width={width}
        height={height}
        onMouseEnter={this.opacityHelper.onMouseEnter}
        onMouseLeave={this.opacityHelper.onMouseLeave}
        onClick={this.props.onClick}
      >
        {shape}
        {plusLine1}
        {plusLine2}
      </svg>
    );
  }

}