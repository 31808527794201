// import React, { PureComponent } from "react";
// import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
// import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
// import widgetUtil from '../../../../commonbase/util/widgetUtil';
// import FeatureFlags from "../../../../shared/model/feature/FeatureFlags";

export default class ConfluenceStorageBuilder {

  storage = '';

  html = (content) => {
    this.storage = this.storage + `\n${content}`;
    return this;
  }

  panel = (content) => {
    this.storage = this.storage + `\n<ac:structured-macro ac:name="info" ac:schema-version="1" ac:macro-id="419007b6-040a-4bdb-bbe4-86395e0bdc0e">
  <ac:rich-text-body>
    ${content}
  </ac:rich-text-body>
</ac:structured-macro>`;
    return this;
  }

  h2 = (content) => {
    this.storage = this.storage + `\n<h2>${content}</h2>`;
    return this;
  }

  h3 = (content) => {
    this.storage = this.storage + `\n<h3>${content}</h3>`;
    return this;
  }

  h4 = (content) => {
    this.storage = this.storage + `\n<h4>${content}</h4>`;
    return this;
  }

  p = (content) => {
    this.storage = this.storage + `\n<p>${content}</p>`;
    return this;
  }

  // purpleText = (text) => {
  //   return `<span style="color: rgb(101,84,192);">${text}</span>`;
  // }

  expand = (title, content) => {
    this.storage += `<ac:structured-macro ac:name="expand" ac:schema-version="1" ac:macro-id="841d7a80-062f-4499-8735-3eb399854320"><ac:parameter ac:name="title">${title}</ac:parameter><ac:rich-text-body>${content}</ac:rich-text-body></ac:structured-macro>`;
    return this;
  }

  openExpand = (title) => {
    this.storage += `<ac:structured-macro ac:name="expand" ac:schema-version="1" ac:macro-id="841d7a80-062f-4499-8735-3eb399854320"><ac:parameter ac:name="title">${title}</ac:parameter><ac:rich-text-body>`;
    return this;
  }

  closeExpand = () => {
    this.storage += `</ac:rich-text-body></ac:structured-macro>`;
    return this;
  }

  tr = (cellContentItems, possibleOptions) => {
    const options = possibleOptions ? possibleOptions : {};
    const headerCellRowSpan = options.headerCellRowSpan ? options.headerCellRowSpan : 1;
    this.append(`\n    <tr>`);
    const cellContentWrapper = options.headerRow ? ['<strong>', '</strong>'] : ['', ''];
    cellContentItems.map((cellContentItem, index) => {
      const cellOptions = index === 0 && headerCellRowSpan > 1 ? {rowspan: headerCellRowSpan} : {};
      if (options.headerRow) {
        this.th(`${cellContentWrapper[0]}${cellContentItem}${cellContentWrapper[1]}`, cellOptions);
      } else {
        this.td(`${cellContentWrapper[0]}${cellContentItem}${cellContentWrapper[1]}`, cellOptions);
      }
      return undefined;
    });
    this.append(`\n    </tr>`);
    return this;
  }

  th = (content, possibleOptions) => {
    const options = possibleOptions ? possibleOptions : {};
    const rowspanText = options.rowspan ? ` rowspan="${options.rowspan}"` : '';
    this.storage = this.storage + `\n      <th${rowspanText}>${content}</th>`;
    return this;
  }

  td = (content, possibleOptions) => {
    const options = possibleOptions ? possibleOptions : {};
    const rowspanText = options.rowspan ? ` rowspan="${options.rowspan}"` : '';
    this.storage = this.storage + `\n      <td${rowspanText}>${content}</td>`;
    return this;
  }

  image = (fileName) => {
    this.storage += `<ac:image ac:align="center" ac:layout="center" ac:original-height="272" ac:original-width="2090">
    <ri:attachment ri:filename="${fileName}" /></ac:image>`;
    return this;
  };

  status = (text: string, color: string): ConfluenceStorageBuilder => {
    this.storage += `<ac:structured-macro 
      ac:name="status" 
      ac:schema-version="1" 
      ac:macro-id="d0639124-6179-41f3-bd78-e1ce7b44e281"
      >
        <ac:parameter ac:name="title">${text}</ac:parameter>
        <ac:parameter ac:name="colour">${color}</ac:parameter>
      </ac:structured-macro>`;
    return this;
  }

  append = (content) => {
    this.storage = this.storage + `${content}`;
    return this;
  }

  build = () => {
    return this.storage.trim();
  }

}

