import assessmentDeletionNotifier from "../../shared/model/rubric/AssessmentDeletionNotifier";
import emailNotificationDefinitions from '../../shared/model/email/EmailNotificationDefinitions';
import rubricDeletionNotifier from "../../shared/model/rubric/RubricDeletionNotifier";
import session from "../../shared/model/auth/Session";
import persistenceFactory from './PersistenceFactory';
import DefinitionPreferences from '../../shared/model/rubric/preference/DefinitionPreferences';
import IUser from '../../shared/model/auth/IUser';
import { defaultBackgroundImageId } from '../../frontend/components/widget/media/BackgroundImageUtil';

export class PreferencesDAO {

  preferencesPersistence: any;
  defaultEmailPreference: any;

  constructor() {
    this.preferencesPersistence = persistenceFactory.preferencesPersistence;
    this.defaultEmailPreference = {};
    this.defaultEmailPreference[emailNotificationDefinitions.definitionLikeEmailsAllowedType] = true;
    this.defaultEmailPreference[emailNotificationDefinitions.newBlogEmailsAllowedType] = true;
    assessmentDeletionNotifier.registerListener(this._onAssessmentDeleted);
    rubricDeletionNotifier.registerListener(this._onRubricDeleted);
  }

  _onAssessmentDeleted = (assessmentUuid: string, definitionUuid: string): void => {
    const user = session.getCurrentUser();
    if (user) {
      this.getLastSelectedAssessmentUuid(definitionUuid, user).then((lastSelectedAssessmentUuid) => {
        if (lastSelectedAssessmentUuid === assessmentUuid) {
          this.setLastSelectedAssessmentUuid(definitionUuid, user, undefined);
        }
      });
    }
  };

  _onRubricDeleted = (definitionUuid: string) => {
    const user = session.getCurrentUser();
    this.setLastSelectedAssessmentUuid(definitionUuid, user, undefined);
    this.setVisibleAssessmentSummaries(definitionUuid, user, undefined);
  };

  getLastSelectedAssessmentUuid = (definitionUuid: string, user: IUser, defaultAssessmentUuid?: string): Promise<DefinitionPreferences> => {
    const defaultDefinitionPreferences = undefined;
    return this.preferencesPersistence.getDefinitionPreferences(definitionUuid, user, defaultDefinitionPreferences)
      .then((definitionPreferences) => {
        if (definitionPreferences && definitionPreferences.lastSelectedAssessmentUuid) {
          return definitionPreferences.lastSelectedAssessmentUuid;
        } else {
          return defaultAssessmentUuid;
        }
      });
  };

  setLastSelectedAssessmentUuid = (definitionUuid: string, user: undefined | IUser, assessmentUuid?: string) => {
    return this.preferencesPersistence.setLastSelectedAssessmentUuid(definitionUuid, user, assessmentUuid);
  };

  getVisibleAssessmentSummaries = (definitionUuid: string, user: IUser, defaultVisibleAssessmentSummaries: any) => {
    const defaultDefinitionPreferences = undefined;
    return this.preferencesPersistence.getDefinitionPreferences(definitionUuid, user, defaultDefinitionPreferences)
      .then((definitionPreferences) => {
        if (definitionPreferences && definitionPreferences.lastSelectedAssessmentUuid) {
          return definitionPreferences.visibleAssessmentSummaries;
        } else {
          return defaultVisibleAssessmentSummaries;
        }
      });
  };

  setVisibleAssessmentSummaries = (definitionUuid: string, user, visibleAssessmentSummaries) => {
    return this.preferencesPersistence.setVisibleAssessmentSummaries(definitionUuid, user, visibleAssessmentSummaries);
  };

  getDefinitionPreferences = (definitionUuid: string, user: IUser, defaultDefinitionPreferences?: DefinitionPreferences): Promise<DefinitionPreferences> => {
    return this.preferencesPersistence.getDefinitionPreferences(definitionUuid, user, defaultDefinitionPreferences)
      .then((retrievedDefinitionPreferences: undefined | DefinitionPreferences) => {
        if (retrievedDefinitionPreferences) {
          return retrievedDefinitionPreferences;
        } else if(defaultDefinitionPreferences) {
          // return promiseUtil.promiseReturning(defaultDefinitionPreferences);
          return defaultDefinitionPreferences;
        } else {
          // promiseUtil.promiseReturning({} as DefinitionPreferences);
          return {} as DefinitionPreferences;
        }
      });
  };

  removeDefinitionPreference = (definitionUuid, user) => {
    return this.preferencesPersistence.removeDefinitionPreference(definitionUuid, user);
  };

  setDefinitionPreferences = (definitionUuid, user, definitionPreferences) => {
    return this.preferencesPersistence.setDefinitionPreferences(definitionUuid, user, definitionPreferences);
  };

  getReportPreferences = (user, defaultReportPreferences) => {
    return this.preferencesPersistence.getReportPreferences(user, defaultReportPreferences)
  };

  setReportPreferences = (user, definitionPreferences) => {
    return this.preferencesPersistence.setReportPreferences(user, definitionPreferences);
  };

  getEmailPreference = (user) => {
    return this.preferencesPersistence.getEmailPreference(user, this.defaultEmailPreference)
  };

  setEmailPreference = (user, emailPreferences) => {
    return this.preferencesPersistence.setEmailPreference(user, emailPreferences);
  };

  getPreferredBackgroundImageIdPreference = (user) => {
    return this.getPreference(user, 'preferred-background-image-id', defaultBackgroundImageId)
  };

  setPreferredBackgroundImageIdPreference = (user, backgroundImageId) => {
    return this.setPreference(user, 'preferred-background-image-id', backgroundImageId);
  };

  getPreference = (user, key, defaultPreference) => {
    return this.preferencesPersistence.getPreference(user, key, defaultPreference)
  };

  setPreference = (user, key, preference) => {
    return this.preferencesPersistence.setPreference(user, key, preference);
  };

  // getPreferences = (user) => {
  //   return this.preferencesPersistence.getPreferences(user)
  // }

}

export default new PreferencesDAO();
