import React, { PureComponent } from "react";
import Label from './Label';
import LiftedPanel from './LiftedPanel';

interface Props {
  text: string
}

interface State {
}

export default class LiftedLabel extends PureComponent<Props, State> {

  render() {
    return (
      <LiftedPanel className="lifted-label-container" isText={true} width="min-content">
        <Label text={this.props.text} additionalClassNames="lifted-label"/>
      </LiftedPanel>
    );
  }

}
