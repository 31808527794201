import DashboardInfo from '../../shared/model/rubric/dashboard/DashboardInfo';
import persistenceFactory from "./PersistenceFactory";

export class DashboardDAO {

  dashboardPersistence: any;

  constructor() {
    this.dashboardPersistence = persistenceFactory.dashboardPersistence;
  }

  saveState = (userId: string, state: DashboardInfo) => {
    return this.dashboardPersistence.saveState(userId, state);
  };

  getState = (userId: string): Promise<DashboardInfo> => {
    return this.dashboardPersistence.getState(userId);
  };

}

export default new DashboardDAO();
