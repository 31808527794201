import ListenerGroup from '../../util/ListenerGroup';

class AssessmentDeletionNotifier {

  listenerGroup = new ListenerGroup('AssessmentDeletionNotifier');

  notifyAssessmentDeletion = (assessmentUuid, definitionUuid) => {
    this._notifyListeners(assessmentUuid, definitionUuid);
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = (assessmentUuid, definitionUuid) => {
    this.listenerGroup.notifyListeners(assessmentUuid, definitionUuid);
  };

}

export default new AssessmentDeletionNotifier();
