import React from 'react';
import adg from '../../../../commonbase/adg';
import PathBuilder from '../../svg/PathBuilder';

class DemoRenderUtil {

  barColor = adg.adgCharcoal;
  buttonColor = adg.adgGray;
  symbolColor = adg.adgCharcoal;

  renderControlBar = (
      className,
      centreX, centreY, barHeight, paused, progressPercent,
      onPlayClick, onPauseClick, onFastForwardClick, onReverseClick) => {
    const buttonCount = 3;
    const buttonPaddingX = 0.3 * barHeight;
    const paddingY = 0.15 * barHeight;
    const progressBarHeight = 0.1 * barHeight;
    const buttonRadius = 0.5 * (barHeight - 3 * paddingY - progressBarHeight);
    const barWidth = buttonCount * 2 * buttonRadius + (buttonCount + 1) * buttonPaddingX;
    const barLeftX = centreX - barWidth / 2;
    const barTopY = centreY - barHeight / 2;
    const barCornerRadius = barHeight / 10;
    const barBottomY = barTopY + barHeight;
    const buttonCentreY = barTopY + paddingY + buttonRadius;
    const progressWidth = 0.8 * barWidth;
    const progressCentreY = barBottomY - paddingY - progressBarHeight / 2;
    const playOrPause = paused ?
      this.renderPlaySymbol(centreX, buttonCentreY, buttonRadius, onPlayClick) :
      this.renderPauseSymbol(centreX, buttonCentreY, buttonRadius, onPauseClick);
    const leftButtonCentreX = centreX - buttonRadius - buttonPaddingX - buttonRadius;
    const rightButtonCentreX = centreX + buttonRadius + buttonPaddingX + buttonRadius;
    const fastForward = this.renderFastForwardSymbol(
      rightButtonCentreX, buttonCentreY, buttonRadius, onFastForwardClick);
    const reverse = this.renderRewindSymbol(
      leftButtonCentreX, buttonCentreY, buttonRadius, onReverseClick);
    const progress = this.renderProgress(
      centreX, progressCentreY, progressWidth, progressBarHeight, progressPercent);
    return (
      <g className={className}>
        <rect
          strokeWidth={0}
          stroke={this.barColor}
          fill={this.barColor}
          x={barLeftX}
          y={barTopY}
          width={barWidth}
          height={barHeight}
          rx={barCornerRadius}
          ry={barCornerRadius}
        />
        {reverse}
        {playOrPause}
        {fastForward}
        {progress}
      </g>
    );
  };

  renderProgress = (centreX, centreY, width, height, progressPercent) => {
    const progressLeftX = centreX - width / 2;
    const progressX = progressLeftX + progressPercent * width / 100;
    return (
      <g>
        <rect
          strokeWidth={0}
          stroke={this.buttonColor}
          fill={this.buttonColor}
          x={centreX - width / 2}
          y={centreY - height / 2}
          width={width}
          height={height}
          rx={height / 2}
          ry={height / 2}
        />
        <circle
          strokeWidth={1}
          stroke={this.buttonColor}
          fill={this.symbolColor}
          cx={progressX}
          cy={centreY}
          r={height / 2 - 1}
        />
      </g>
    );
  };

  renderPlaySymbol = (centreX, centreY, circleRadius, onPlayClick) => {
    return (
      <g
        className="clickable"
        onClick={onPlayClick}
      >
        <circle
          strokeWidth={2}
          stroke={this.buttonColor}
          fill={this.buttonColor}
          cx={centreX}
          cy={centreY}
          r={circleRadius}
        />
        {this._renderPlayTriangle(centreX, centreY, circleRadius, 1)}
      </g>
    );
  };

  renderPauseSymbol = (centreX, centreY, circleRadius, onPauseClick) => {
    return (
      <g
        className="clickable"
        onClick={onPauseClick}
      >
        <circle
          strokeWidth={2}
          stroke={this.buttonColor}
          fill={this.buttonColor}
          cx={centreX}
          cy={centreY}
          r={circleRadius}
        />
        {this._renderPauseRectangles(centreX, centreY, circleRadius)}
      </g>
    );
  };

  renderRewindSymbol = (centreX, centreY, circleRadius, onFastForwardClick) => {
    return (
      <g
        className="clickable"
        onClick={onFastForwardClick}
      >
        <circle
          strokeWidth={2}
          stroke={this.buttonColor}
          fill={this.buttonColor}
          cx={centreX}
          cy={centreY}
          r={circleRadius}
        />
        {this._renderRewindTriangles(centreX, centreY, circleRadius)}
      </g>
    );
  };

  renderFastForwardSymbol = (centreX, centreY, circleRadius, onFastForwardClick) => {
    return (
      <g
        className="clickable"
        onClick={onFastForwardClick}
      >
        <circle
          strokeWidth={2}
          stroke={this.buttonColor}
          fill={this.buttonColor}
          cx={centreX}
          cy={centreY}
          r={circleRadius}
        />
        {this._renderFastForwardTriangles(centreX, centreY, circleRadius)}
      </g>
    );
  };

  _renderRewindTriangles = (centreX, centreY, circleRadius) => {
    const triangleOverlapWidth = circleRadius / 4;
    const leftTriangleX = centreX - 1.2 * triangleOverlapWidth;
    const rightTriangleX = centreX + 0.8 * triangleOverlapWidth;
    return (
      <React.Fragment>
        {this._renderBackwardPlayTriangle(leftTriangleX, centreY, 0.7 * circleRadius, 1.3)}
        {this._renderBackwardPlayTriangle(rightTriangleX, centreY, 0.7 * circleRadius, 1.3)}
      </React.Fragment>
    );
  };

  _renderFastForwardTriangles = (centreX, centreY, circleRadius) => {
    const triangleOverlapWidth = circleRadius / 4;
    const leftTriangleX = centreX - 0.8 * triangleOverlapWidth;
    const rightTriangleX = centreX + 1.2 * triangleOverlapWidth;
    return (
      <React.Fragment>
        {this._renderPlayTriangle(leftTriangleX, centreY, 0.7 * circleRadius, 1.3)}
        {this._renderPlayTriangle(rightTriangleX, centreY, 0.7 * circleRadius, 1.3)}
      </React.Fragment>
      );
  };

  _renderBackwardPlayTriangle = (centreX, centreY, circleRadius, verticalStretch) => {
    const cornerRadius = circleRadius / 5;
    const leftX = centreX - 0.7 * circleRadius;
    const rightX = centreX + 0.5 * circleRadius;
    const topY = centreY - verticalStretch * circleRadius / 1.5;
    const bottomY = centreY + verticalStretch * circleRadius / 1.5;
    return new PathBuilder()
      .setPathColor(this.symbolColor)
      .setFill(this.symbolColor)
      .moveTo(rightX, topY + cornerRadius)

      .lineTo(rightX             , bottomY - cornerRadius)
      .bezierCurveTo(
        rightX                   , bottomY - cornerRadius,
        rightX - cornerRadius / 6, bottomY - cornerRadius / 6,
        rightX - cornerRadius    , bottomY - 5 * cornerRadius / 8)

      .lineTo(leftX + cornerRadius, centreY + cornerRadius / 2)
      .bezierCurveTo(
        leftX + cornerRadius, centreY + cornerRadius / 2,
        leftX               , centreY,
        leftX + cornerRadius, centreY - cornerRadius / 2)

      .lineTo(rightX - cornerRadius, topY + 5 * cornerRadius / 8)
      .bezierCurveTo(
        rightX - cornerRadius    , topY + 5 * cornerRadius / 8,
        rightX - cornerRadius / 6, topY + cornerRadius / 6,
        rightX                   , topY + cornerRadius)

      .close()
      .render();
  };

  _renderPlayTriangle = (centreX, centreY, circleRadius, verticalStretch) => {
    const cornerRadius = circleRadius / 5;
    const leftX = centreX - 0.4 * circleRadius;
    const rightX = centreX + 0.75 * circleRadius;
    const topY = centreY - verticalStretch * circleRadius / 1.5;
    const bottomY = centreY + verticalStretch * circleRadius / 1.5;
    return new PathBuilder()
      .setPathColor(this.symbolColor)
      .setFill(this.symbolColor)
      .moveTo(leftX, topY + cornerRadius)

      .lineTo(leftX             , bottomY - cornerRadius)
      .bezierCurveTo(
        leftX                   , bottomY - cornerRadius,
        leftX + cornerRadius / 6, bottomY - cornerRadius / 6,
        leftX + cornerRadius    , bottomY - 5 * cornerRadius / 8)

      .lineTo(rightX - cornerRadius, centreY + cornerRadius / 2)
      .bezierCurveTo(
        rightX - cornerRadius, centreY + cornerRadius / 2,
        rightX               , centreY,
        rightX - cornerRadius, centreY - cornerRadius / 2)

      .lineTo(leftX + cornerRadius, topY + 5 * cornerRadius / 8)
      .bezierCurveTo(
        leftX + cornerRadius    , topY + 5 * cornerRadius / 8,
        leftX + cornerRadius / 6, topY + cornerRadius / 6,
        leftX                   , topY + cornerRadius)

      .close()
      .render();
  };

  _renderPauseRectangles = (centreX, centreY, circleRadius) => {
    const rectangleCornerRadius = circleRadius / 10;
    const rectangleWidth = circleRadius / 4;
    const rectangleHeight = circleRadius * 1.2;
    const leftRectangleLeft = centreX - 1.5 * rectangleWidth;
    const rightRectangleLeft = centreX + 0.5 * rectangleWidth;
    const leftRectangleTop = centreY - rectangleHeight / 2;
    const rightRectangleTop = leftRectangleTop;
    return (
      <React.Fragment>
        <rect
          strokeWidth={0}
          stroke={this.symbolColor}
          fill={this.symbolColor}
          x={leftRectangleLeft}
          y={leftRectangleTop}
          width={rectangleWidth}
          height={rectangleHeight}
          rx={rectangleCornerRadius}
          ry={rectangleCornerRadius}
        />
        <rect
          strokeWidth={0}
          stroke={this.symbolColor}
          fill={this.symbolColor}
          x={rightRectangleLeft}
          y={rightRectangleTop}
          width={rectangleWidth}
          height={rectangleHeight}
          rx={rectangleCornerRadius}
          ry={rectangleCornerRadius}
        />
      </React.Fragment>
    );
  };

}

export default new DemoRenderUtil()
