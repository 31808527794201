
class Environment {

  isLocalHost: boolean;
  isRunningLocally: boolean;
  offlineDevMode: boolean;

  constructor() {
    const location = window.location;
    const hostname = location.hostname;
    this.isLocalHost = hostname === 'localhost';
    this.isRunningLocally = this.isLocalHost || hostname.indexOf('ngrok') >= 0;
    this.offlineDevMode = false;
  };

  mockAuth = () => {
    return this.isLocalHost && this.offlineDevMode;
  };

  mockDatabase = () => {
    return this.isLocalHost && this.offlineDevMode;
  };

  mockPayments = () => {
    return this.isLocalHost && this.offlineDevMode;
  };

  mockData = () => {
    return this.isLocalHost && this.offlineDevMode;
  };

}

export default new Environment();
