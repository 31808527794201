import React, { PureComponent } from 'react';
import backgroundImages from '../../../../commonbase/media/backgroundImages';
import backgroundRetriever from '../../../../commonbase/media/backgroundRetriever';
import SingleSelect from '../../widget/SingleSelect';
import Toolbar from '../../crm/view/Toolbar';
import SelectOption from '../../../../shared/model/widget/SelectOption';

interface Props {
  selectedBackgroundImageId?: string
  showLabel?: boolean;
  onChange: (selectedBackgroundImageId: string) => void
}

interface State {
  selectedBackgroundImageId?: string
}

export default class BackgroundImageSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {};
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      selectedBackgroundImageId: props.selectedBackgroundImageId === undefined ? this.state.selectedBackgroundImageId : props.selectedBackgroundImageId,
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      selectedBackgroundImageId: selection.value
    });
    this.props.onChange(selection.value);
  };

  buildOptions = (): SelectOption[] => {
    const options: SelectOption[] = [];
    for (const backgroundImage of backgroundImages) {
      const option = {
        label: backgroundImage.label,
        value: backgroundImage.id
      };
      options.push(option);
    }
    return options;
  };

  renderPreview = (selectedBackgroundImageId: string) => {
    const image = backgroundRetriever(selectedBackgroundImageId);
    if (image) {
      const url = `/img/bg/${image.filename}`
      return (
        <div style={{maxWidth: '200px'}}>
          <img width="100%" src={url} alt={image.label}/>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const label = this.props.showLabel === false ? undefined : 'Background image';
    const selectedBackgroundImageId = this.state.selectedBackgroundImageId;
    const options: SelectOption[] = this.buildOptions();
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === this.state.selectedBackgroundImageId) {
        selectedOption = option;
      }
    }
    return (
      <Toolbar valign="top">
        <div style={{width: '300px'}}>
          <SingleSelect
            key={selectedOption ? selectedOption.value : 'none'}
            label={label}
            options={options}
            defaultSelected={selectedOption}
            placeholder={undefined}
            noMatchesFound={undefined}
            hasAutocomplete
            appearance="default"
            onSelected={this.onChange}
          />
        </div>
        {selectedBackgroundImageId ? this.renderPreview(selectedBackgroundImageId) : null}
      </Toolbar>
    );
  };

}
