import React, { FormEvent, PureComponent } from 'react';
import Button from '@atlaskit/button';
import constants from '../../../shared/model/Constants';
import ModalDialog from '@atlaskit/modal-dialog';
import networkGraph from './NetworkGraph';
import {SubmitNameIcon} from "../icon/NamedIcons";
import ContactBuilder from "../../../shared/model/network/ContactBuilder";
import networkUtil from "../../../shared/model/network/NetworkUtil";
import FormlessTextField from '../widget/FormlessTextField';
import ToolbarItem from '../widget/toolbar/ToolbarItem';

interface Props {
  onContactCreated: (contact: any) => void;
  onCancelClick: () => void
}

interface State {
  contact: any
  errorMessage: string
}

export default class AddContactModal extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    const contact = new ContactBuilder()
      .build();
    this.state = {
      contact: contact,
      errorMessage: ''
    };
  }

  // onSubmitContactBeingCreatedClick = () => {
  //   this.props.onContactCreated();
  // };

  onSubmitClick = () => {
    const errorMessage = networkUtil.validateContact(this.state.contact);
    if (errorMessage) {
      this.setState({
        errorMessage: errorMessage
      })
    } else {
      networkGraph.addContact(this.state.contact)
        .then(() => {
          this.props.onContactCreated(this.state.contact);
        })
        .catch((message) => {
          this.setState({
            errorMessage: message
          });
        });
    }
  };

  onContactBeingCreatedNameChange = (event: FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.value;
    const contact = new ContactBuilder()
      .setContact(this.state.contact)
      .setName(name)
      .build();
    this.setState({
      contact: contact
    });
  };

  onContactBeingCreatedEmailChange = (event: FormEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value;
    const contact = new ContactBuilder()
      .setContact(this.state.contact)
      .setEmail(email)
      .build();
    this.setState({
      contact: contact
    });
  };

  renderErrorBanner = (message) => {
    return (
      <div className="message-banner error-message-banner">
        {message}
      </div>
    );
  };

  render() {
    const errorMessage = this.state.errorMessage;
    return (
      <ModalDialog
        key="create-contact"
        heading={'Add contact'}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
      >
        {errorMessage ? this.renderErrorBanner(errorMessage) : null}
        <FormlessTextField
          name="name"
          label="Name"
          minLength={constants.minDriveFolderNameLength}
          maxLength={constants.maxDriveFolderNameLength}
          value={''}
          onChange={this.onContactBeingCreatedNameChange}
        />
        <FormlessTextField
          name="email"
          label="Email"
          minLength={constants.minDriveFolderNameLength}
          maxLength={constants.maxDriveFolderNameLength}
          value={''}
          onChange={this.onContactBeingCreatedEmailChange}
        />
        <div className="centredContent" style={{marginTop: '10px', marginBottom: '10px'}}>
          <ToolbarItem>
            <Button
              appearance="primary"
              iconBefore={<SubmitNameIcon label="submit"/>}
              isDisabled={false}
              onClick={this.onSubmitClick}
            >
              Create contact
            </Button>
            <Button
              appearance="subtle"
              isDisabled={false}
              onClick={this.props.onCancelClick}
            >
              Cancel
            </Button>
          </ToolbarItem>
        </div>
      </ModalDialog>
    );
  }

}
