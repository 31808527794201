import colorRange from '../../../../commonbase/color/redToGreenColorRange';
import ScoreMeta from './ScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';

class BadOkGoodScoreMeta extends ScoreMeta {

  constructor() {
    super(scoreTypes.badOkGood);
    this.displayLabelsInsteadOfScores = true;
  }

  shouldRenderEmojiForAssessmentItem = (assessmentItem) => {
    return true;
  };

  getColorRange = () => {
    return colorRange;
  }

}

export default new BadOkGoodScoreMeta();

