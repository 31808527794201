import lightBlueToDarkBlueColorRange from '../../../../commonbase/color/lightBlueToDarkBlueColorRange';
import measurementTypes from './MeasurementTypes';
import EmojiBuilder from "../../../../frontend/components/svg/EmojiBuilder";
import scoreTypesToDefinitions from '../../../../commonbase/score/scoreTypesToDefinitions';
import scoreUtil from './ScoreUtil';
import adg from '../../../../commonbase/adg';
import ScoreMarker from './ScoreMarker';

export default class ScoreMeta {

  displayLabelsInsteadOfScores: boolean = false;

  label: string;
  minimum: number;
  maximum: number;
  markers: ScoreMarker[];
  markerCount: number;
  percentBetweenMarkers: number;

  constructor(type: string, label?: string, markers?: ScoreMarker[]) {
    if (label === undefined) {
      this.label = scoreTypesToDefinitions[type].name;
    } else {
      this.label = label;
    }
    if (markers) {
      this.markers = markers;
    } else {
      this.markers = scoreTypesToDefinitions[type].markers;
    }
    this.minimum = this.markers[0].score;
    this.maximum = this.markers[this.markers.length - 1].score;
    this.markerCount = this.markers.length;
    this.percentBetweenMarkers = 100 / (this.markerCount - 1);
  }

  allowScoresBetweenMarkers = () => {
    return true;
  };

  roundInputScorePercent = (scorePercent) => {
    if (this.allowScoresBetweenMarkers()) {
      return this._roundInputScorePercentAllowingScoresBetweenMarkers(scorePercent);
    } else {
      return this._roundInputScorePercentDisallowingScoresBetweenMarkers(scorePercent);
    }
  }

  _roundInputScorePercentAllowingScoresBetweenMarkers = (scorePercent) => {
    const rounded = Math.round(scorePercent);
    if (rounded < 0) {
      return 0;
    } else if (rounded > 100) {
      return 100;
    } else {
      return rounded;
    }
  };

  _roundInputScorePercentDisallowingScoresBetweenMarkers = (scorePercent) => {
    const rounded = this.percentBetweenMarkers * Math.round(scorePercent / this.percentBetweenMarkers);
    if (rounded < 0) {
      return 0;
    } else if (rounded > 100) {
      return 100;
    } else {
      return rounded;
    }
  };

  scoreToPercent = (score) => {
    const scorePercent = score * this.percentBetweenMarkers;
    return scorePercent;
  };

  percentToScore = (scorePercent) => {
    const score = scorePercent / this.percentBetweenMarkers;
    return score;
  };

  shouldRenderEmojiForAssessmentItem = (assessmentItem) => {
    return false;
  };

  renderEmoji = (assessmentItem, radius, centreX, centreY, foregroundColor, backgroundColor, showHeadeOutline) => {
    const scorePercent = scoreUtil.assessmentItemToScore(assessmentItem);
    const happinessFactor = scorePercent === undefined ? undefined : scorePercent / 100;
    let emoji = new EmojiBuilder()
      .setRadius(radius)
      .setCentre(centreX, centreY)
      .setForegroundColor(foregroundColor)
      .setBackgroundColor(backgroundColor)
      .setHappinessFactor(happinessFactor);
    if (showHeadeOutline) {
      emoji = emoji.showHeadeOutline();
    }
    return emoji.render();
  };

  getMeasurementType = () => {
    return measurementTypes.rangeType;
  };

  getLabel = () => {
    return this.label;
  };

  scoreToMarker = (score) => {
    for (const marker of this.markers) {
      if (marker.score === score) {
        return marker;
      }
    }
    return undefined;
  };

  getColorRange = (): any => {
    return lightBlueToDarkBlueColorRange;
  }

  assessmentItemToColour = (assessmentItem) => {
    const scoreIsValid = scoreUtil.hasScore(assessmentItem);
    if (scoreIsValid) {
      const scorePercent = scoreUtil.assessmentItemToScore(assessmentItem);
      const factorFromStart = scorePercent === undefined ? 0 : scorePercent / 100;
      const colorRange = this.getColorRange();
      return colorRange.computeColor(factorFromStart);  
    } else {
      return adg.adgGray;
    }
  };

  getMinimum = () => {
    return this.minimum;
  };

  getMaximum = () => {
    return this.maximum;
  };

  getMarkers = () => {
    return this.markers;
  };

  _scoresEqual = (scoreA, scoreB) => {
    const difference = Math.abs(scoreA - scoreB);
    const maxMarker = this.markers[this.markers.length - 1];
    const maxScore = maxMarker.score;
    const ratio = difference / maxScore;
    return ratio < 0.05;
  };

  computeScoreTextInfo = (scorePercent) => {
    if (scorePercent === undefined) {
      return {
        label: '-',
        tooltip: ''
      }
    }
    let label = '';
    let tooltip = '';
    let found = false;
    const score = this.percentToScore(scorePercent);
    let markerBeneath: any = undefined;
    let markerAbove: any = undefined;
    for (const marker of this.markers) {
      if (this._scoresEqual(marker.score, score)) {
        label = marker.label === undefined ? marker.score.toString() : marker.label;
        tooltip = marker.tooltip ? marker.tooltip : label;
        found = true;
        break;
      }
      if (marker.score < score) {
        markerBeneath = marker;
      } else if (markerAbove === undefined && marker.score > score) {
        markerAbove = marker;
      }
    }
    if (!found && markerAbove !== undefined && markerBeneath !== undefined) {
      const markerBeneathLabel = this.getMarkerLabel(markerBeneath);
      const markerAboveLabel = this.getMarkerLabel(markerAbove);
      const markerBeneathTip = this.getMarkerTip(markerBeneath);
      const markerAboveTip = this.getMarkerTip(markerAbove);
      if (markerBeneathTip && markerAboveTip) {
        const diffBetweenMarkers = Math.abs(markerAbove.score - markerBeneath.score);
        const diffToMarkerBeneath = Math.abs(score - markerBeneath.score);
        const diffToMarkerAbove = Math.abs(markerAbove.score - score);
        if (diffToMarkerBeneath >= diffToMarkerAbove) {
          if (diffToMarkerAbove < diffBetweenMarkers / 4) {
            label = `< ${markerAboveLabel}`;
            tooltip = `Just below ${markerAboveTip}`;
          } else {
            label = `< ${markerAboveLabel}`;
            tooltip = `A bit below ${markerAboveTip}`;
          }
        } else {
          if (diffToMarkerBeneath < diffBetweenMarkers / 4) {
            label = `> ${markerBeneathLabel}`;
            tooltip = `Just above ${markerBeneathTip}`;
          } else {
            label = `> ${markerBeneathLabel}`;
            tooltip = `A bit above ${markerBeneathTip}`;
          }
        }
      } else if (markerAboveLabel) {
        label = `< ${markerAboveLabel}`;
        tooltip = `Less than ${markerAboveTip}`;
      } else if (markerBeneathLabel) {
        const maxMarker = this.markers[this.markers.length - 1];
        if (markerBeneath.score === maxMarker.score) {
          label = `${markerBeneathLabel}`;
          tooltip = `${markerBeneathTip}`;
        } else {
          label = `> ${markerBeneathLabel}`;
          tooltip = `Above ${markerBeneathTip}`;
        }
      }
    }
    if (!this.displayLabelsInsteadOfScores) {
      label = Math.round(score).toString();
    }
    return {
      label: label,
      tooltip: tooltip
    };
  };

  getMarkerLabel = (marker) => {
    if (marker) {
      if (marker.label) {
        return marker.label;
      }
    }
    return undefined;
  };

  getMarkerTip = (marker) => {
    if (marker) {
      if (marker.tooltip) {
        return '"' + marker.tooltip + '"';
      } else if (marker.label) {
        return marker.label;
      }
    }
    return undefined;
  };

}

