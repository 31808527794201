import React, { PureComponent } from 'react';
import appStyles from '../../modules/AppStyles';
import AspectBarChart from './chart/AspectBarChart';
import ChartLabel from './chart/ChartLabel';
import ChartModel from './chart/ChartModel';
import ContainerDimensions from 'react-container-dimensions';
import currentUserPlan from '../../../backend/payment/CurrentUserPlan';
import permissionUtil from "../../../shared/model/auth/PermissionUtil";
import rubricDAO from '../../../backend/data/RubricDAO';
import RubricViewer from './RubricViewer';
import session from '../../../shared/model/auth/Session';
import rubricUtil from '../../../shared/model/rubric/RubricUtil';
import util from "../../../shared/util/Util";
import collectionsUtil from '../../../commonbase/util/collectionsUtil';
import IUser from '../../../shared/model/auth/IUser';
import Definition from '../../../shared/model/rubric/definition/Definition';
import Assessment from '../../../shared/model/rubric/score/Assessment';
import AssessmentDAI from '../../../backend/data/AssessmentDAI';
import ScoreMeta from '../../../shared/model/rubric/score/ScoreMeta';

interface Props {
  definitionUuid: string
  assessmentDAO: AssessmentDAI
  visibleAssessmentUuids: string[]
  viewChart: boolean
  viewTable: boolean
  viewDefinitionHeader?: boolean
  viewStatus?: boolean
  viewNotes?: boolean
  resolveDefinition?: Function
  resolveAssessments?: Function
  onAssessmentChange?: () => void
  onDefinitionAndAssessmentsResolved?: (definition: Definition, assessments: Assessment[]) => void
}

interface State {
  loading?: boolean
  assessmentDAO: AssessmentDAI
  definitionUuid: string
  definition?: Definition
  chartModel?: ChartModel
  scoreMeta?: ScoreMeta
  currentUser: undefined | IUser
  visibleAssessmentUuids: string[]
  visibleAssessments?: Assessment[]
  viewChart: boolean
  viewTable: boolean
  viewDefinitionHeader: boolean
  viewStatus: boolean
  viewNotes: boolean
  statementUuidsToGuidanceVisibilities?: undefined | any
  canViewOrCreateAssessments?: boolean
  inSecondHalfOfTrialPeriod?: boolean
  hideCompletedAspects: boolean
  columnVisibilities: boolean[]
  hideZeroScores?: boolean
  hideColumnTotals?: boolean
}

export default class EmbeddedView extends PureComponent<Props, State> {

  state: State;
  mounted = false;
  shouldRenderChartLabel = false;

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
    this.state.statementUuidsToGuidanceVisibilities = {};
    this._possiblyLoadDefinition(props);
  }

  componentDidUpdate(prevProps: Props) {
    let updateRequired = false;
    if (!session.isCurrentUser(this.state.currentUser)) {
      updateRequired = true;
    }
    if (this.props.definitionUuid !== this.state.definitionUuid) {
      updateRequired = true;
    } else if (!collectionsUtil.equivalentArrays(
      this.props.visibleAssessmentUuids,
      this.state.visibleAssessmentUuids)) {
      
      updateRequired = true;
    }
    if (updateRequired) {
      this.setState(this.buildStateFromProps(this.props));
      this._possiblyLoadDefinition(this.props);
    }
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
  }

  componentDidMount() {
    this.mounted = false;
    session.registerListener(this._onSessionChange);
    currentUserPlan.registerListener(this.onCurrentUserPlanChange);
    const canViewOrCreateAssessments = permissionUtil.canViewOrCreateAssessments();
    this.setState({
      currentUser: session.getCurrentUser(),
      canViewOrCreateAssessments: canViewOrCreateAssessments
    });
  };

  componentWillUnmount() {
    session.unregisterListener(this._onSessionChange);
    currentUserPlan.unregisterListener(this.onCurrentUserPlanChange);
  };

  _onSessionChange = (user) => {
    const canViewOrCreateAssessments = permissionUtil.canViewOrCreateAssessments();
    this.setState({
      currentUser: user,
      canViewOrCreateAssessments: canViewOrCreateAssessments
    });
    if (this.state.definitionUuid) {
      if (user) {
        this.loadDefinitionAndAssessments(this.state.definitionUuid, this.state.visibleAssessmentUuids);
      } else {
        this.setState({
          definition: undefined,
          visibleAssessments: []
        });
      }
    }
  };

  onCurrentUserPlanChange = (planDefinition, context) => {
    const canViewOrCreateAssessments = permissionUtil.canViewOrCreateAssessments();
    this.setState({
      inSecondHalfOfTrialPeriod: context.inSecondHalfOfTrialPeriod,
      canViewOrCreateAssessments: canViewOrCreateAssessments
    });
  };

  getGuidanceVisibility = (statement) => {
    return this.state.statementUuidsToGuidanceVisibilities[statement.uuid];
  };

  onGuidanceExpanded = (statement) => {
    const statementUuidsToGuidanceVisibilities = util.shallowCloneObject(this.state.statementUuidsToGuidanceVisibilities);
    statementUuidsToGuidanceVisibilities[statement.uuid] = true;
    this.setState({
      statementUuidsToGuidanceVisibilities: statementUuidsToGuidanceVisibilities
    });
  };

  onGuidanceCollapsed = (statement) => {
    const statementUuidsToGuidanceVisibilities = util.shallowCloneObject(this.state.statementUuidsToGuidanceVisibilities);
    delete statementUuidsToGuidanceVisibilities[statement.uuid];
    this.setState({
      statementUuidsToGuidanceVisibilities: statementUuidsToGuidanceVisibilities
    });
  };

  buildStateFromProps = (props: Props): State => {
    // const assessmentDAO = props.assessmentDAO ? props.assessmentDAO : rubricDAO;
    if (!props.assessmentDAO) {
      throw new Error('props.assessmentDAO is not defined');
    }
    const assessmentDAO = props.assessmentDAO;
    const assessmentUuids = props.visibleAssessmentUuids ? props.visibleAssessmentUuids : [];
    const state: State = {
      assessmentDAO: assessmentDAO,
      currentUser: session.getCurrentUser(),
      hideCompletedAspects: false,
      columnVisibilities: [],
      definitionUuid: props.definitionUuid,
      visibleAssessmentUuids: assessmentUuids,
      viewChart: props.viewChart,
      viewTable: props.viewTable,
      viewDefinitionHeader: props.viewDefinitionHeader === undefined ? assessmentUuids.length > 1 : props.viewDefinitionHeader,
      viewStatus: props.viewStatus === undefined ? true : props.viewStatus,
      viewNotes: props.viewNotes === undefined ? true : props.viewNotes,
    };
    return state;
  };

  _possiblyLoadDefinition = (props) => {
    if (props.definitionUuid) {
      if (session.getCurrentUser()) {
        const assessmentUuids = props.visibleAssessmentUuids ? props.visibleAssessmentUuids : [];
        this.loadDefinitionAndAssessments(props.definitionUuid, assessmentUuids);
      }
    }
  };

  loadDefinitionAndAssessments = (definitionUuid, assessmentUuids) => {
    // console.log(`Roobrick: Loading definition ${definitionUuid}...`);
    if (this.mounted) {
      this.setState({
        loading: true
      });
    } else {
      const state = this.state;
      state.loading = true;
    }
    const definitionPromise = this.props.resolveDefinition ?
      this.props.resolveDefinition(definitionUuid) : rubricDAO.getDefinitionByUuid(definitionUuid);

    const querySemantics = true;
    // const assessmentDAO = this.state.assessmentDAO;
    // const myAssessmentsPromise = assessmentDAO.getAssessmentsByUuids(assessmentUuids, querySemantics);
    const myAssessmentsPromise = this.props.resolveAssessments ?
      this.props.resolveAssessments(assessmentUuids) :
      this.state.assessmentDAO.getAssessmentsByUuids(assessmentUuids, querySemantics);

    Promise.all([definitionPromise, myAssessmentsPromise]).then((results) => {
      //recentsDAO.discardRecentDefinitionAccessByUuid(definitionUuid);
      this.setState({
        loading: false
      });
      const definition = results[0];
      const canReadDefinition = permissionUtil.canReadDefinition(definition);
      if (canReadDefinition) {
        this.setState({
          definition: definition,
          hideCompletedAspects: definition && definition.options && definition.options.hideCompletedAspects,
          hideZeroScores: definition && definition.options && definition.options.hideZeroScores,
          hideColumnTotals: definition && definition.options && definition.options.hideColumnTotals
        });
      }
      if (canReadDefinition && definition) {
        const scoreMeta = rubricUtil.getScoreMeta(definition);
        const myAssessments = results[1];
        const columnVisibilities = rubricUtil.buildDefaultColumnVisibilities(definition);
        const visibleAssessments: Assessment[] = [];
        for (const myAssessment of myAssessments) {
          if (assessmentUuids && assessmentUuids.length) {
            for (const visibleAssessmentUuid of assessmentUuids) {
              if (myAssessment && myAssessment.uuid === visibleAssessmentUuid) {
                if (permissionUtil.canReadAssessment(myAssessment)) {
                  visibleAssessments.push(myAssessment);
                }
              }
            }
          } else {
            for (const visibleAssessmentUuid of assessmentUuids) {
              if (myAssessment && myAssessment.uuid === visibleAssessmentUuid) {
                if (permissionUtil.canReadAssessment(myAssessment)) {
                  visibleAssessments.push(myAssessment);
                }
              }
            }
          }
        }
        const visibleLabels = rubricUtil.getDefinitionLabels(definition);
        const chartModel = this.state.viewChart ? new ChartModel(
          definition,
          visibleAssessments,
          visibleLabels,
          columnVisibilities) : undefined;
        this.setState({
          definition: definition,
          visibleAssessments: visibleAssessments,
          columnVisibilities: columnVisibilities,
          scoreMeta: scoreMeta,
          chartModel: chartModel
        });
        if (this.props.onDefinitionAndAssessmentsResolved) {
          this.props.onDefinitionAndAssessmentsResolved(definition, visibleAssessments)
        }
      }
    });
  };

  onAssessmentChange = () => {
    if (this.props.onAssessmentChange) {
      this.props.onAssessmentChange();
    }
  };

  onColumnIndexToVisibilityChange = () => {
  };

  renderChartLabel = (aspectGrouping) => {
    if (this.state.definition) {
      return (
        <ChartLabel
          definition={this.state.definition}
          aspectGrouping={aspectGrouping}
        />
      );
    } else {
      return null;
    }
  };

  renderChart = () => {
    if (!this.state.chartModel) {
      return null;
    }
    const aspectGrouping = this.state.chartModel.getDefaultAspectGrouping();
    return (
      <React.Fragment>
        {this.shouldRenderChartLabel ? this.renderChartLabel(aspectGrouping) : null}
        <div className="centredContent newMinorSection" style={{width: '100%'}}>
          <ContainerDimensions>
            { ({ width, height }) =>
              <AspectBarChart
                scoreMeta={this.state.scoreMeta}
                width={width}
                chartModel={this.state.chartModel}
                aspectGrouping={aspectGrouping}
              />
            }
          </ContainerDimensions>
        </div>
      </React.Fragment>
    );
  };

  renderDefinition = () => {
    if (this.state.definition && this.state.scoreMeta && this.state.assessmentDAO) {
      const visibleLabels = rubricUtil.getDefinitionLabels(this.state.definition);
      const visibleAssessments = this.state.visibleAssessments ? this.state.visibleAssessments : [];
      const viewAssessmentsSideBySide = rubricUtil.shouldViewAssessmentsSideBySide(
        this.state.definition, visibleAssessments);
      return (
        <React.Fragment>
          <style dangerouslySetInnerHTML={{ __html: appStyles.getEmbeddedAppStyles() }} />
          <RubricViewer
            embedded={true}
            definition={this.state.definition}
            scoreMeta={this.state.scoreMeta}
            viewAssessmentsSideBySide={viewAssessmentsSideBySide}
            hideCompletedAspects={this.state.hideCompletedAspects}
            hideZeroScores={this.state.hideZeroScores}
            hideColumnTotals={this.state.hideColumnTotals}
            hideGeneralNotes={true}
            columnVisibilities={this.state.columnVisibilities}
            visibleLabels={visibleLabels}
            assessments={visibleAssessments}
            canViewOrCreateAssessments={this.state.canViewOrCreateAssessments}
            onAssessmentChange={this.onAssessmentChange}
            onColumnIndexToVisibilityChange={this.onColumnIndexToVisibilityChange}
            getGuidanceVisibility={this.getGuidanceVisibility}
            onGuidanceExpanded={this.onGuidanceExpanded}
            onGuidanceCollapsed={this.onGuidanceCollapsed}
            viewRowLinks={false}
            {...this.props}
            assessmentDAO={this.state.assessmentDAO}
            viewDefinitionHeader={this.state.viewDefinitionHeader}
            viewStatus={this.state.viewStatus}
            viewNotes={this.state.viewNotes}
          />
          <div style={{ height: '20px' }}></div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  render() {
    if (this.state.definition) {
      return (
        <div>
          {this.state.viewChart ? this.renderChart() : null}
          {this.state.viewTable ? this.renderDefinition() : null}
        </div>
      );
    } else {
      return null;
    }
  };

}
