import React, { PureComponent } from "react";
import adg from '../../commonbase/adg';
import Button from '@atlaskit/button';
import constants from '../../shared/model/Constants';
import navConstants from '../components/nav/NavConstants';
import storageDAO from '../../backend/data/StorageDAO';
import ToolbarCenter from '../components/widget/toolbar/ToolbarCenter';
import ToolbarItem from '../components/widget/toolbar/ToolbarItem';

interface Props {
  appTitle?: string
  height?: any
}

interface State {
  appTitle: string
  localStorageAvailable: boolean
  height: any
  cookiesAccepted?: undefined | number
}

export default class CookieBanner extends PureComponent<Props, State> {

  cookieAcceptanceKey = 'cookies-accepted';
  mounted = false;
  cookiesAccepted: undefined | number = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      appTitle: props.appTitle ? props.appTitle : constants.appTitle,
      localStorageAvailable: storageDAO.isLocalStorageAvailable(),
      height: props.height ? props.height : '10px',
      cookiesAccepted: undefined
    };
    const defaultValue = 0;
    storageDAO.getInt(this.cookieAcceptanceKey, defaultValue).then((cookiesAccepted) => {
      this.cookiesAccepted = cookiesAccepted;
      if (this.mounted) {
        this.setState({
          cookiesAccepted: cookiesAccepted
        });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      height: props.height ? props.height : '10px'
    });
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    this.setState({
      cookiesAccepted: this.cookiesAccepted
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  };

  onAccept= () => {
    storageDAO.setInt(this.cookieAcceptanceKey, 1).then(() => {
      this.setState({
        cookiesAccepted: 1
      });
    });
  };

  renderBanner = () => {
    const bannerHeight = 40;
    const toolbarItemStyle = {
      marginLeft: '10px',
      marginTop: '2px'
    };
    const privacyLink = `/#${navConstants.privacyPageHash}`;
    return (
      <div
        style={{
          width: '100%',
          height: `${bannerHeight}px`,
          // contentAlign: 'center',
          textAlign: 'center',
          backgroundColor: adg.adgYellow,
          zIndex: 1000,
          position: 'fixed',
          bottom: `0px`,
          left: 0,
          display: 'block',
        }}
      >
        <ToolbarCenter style={{marginTop:'2px'}}>
          <ToolbarItem>
            {this.state.appTitle} uses cookies to provide you
            with a great user experience.

            More details are in
            our <a href={privacyLink}>privacy policy</a>.
          </ToolbarItem>
          <ToolbarItem style={toolbarItemStyle}>
            <Button
              appearance="primary"
              onClick={this.onAccept}
            >
              OK, got it
            </Button>
          </ToolbarItem>
        </ToolbarCenter>
      </div>
    );
  };

  render() {
    if (this.state.localStorageAvailable) {
      if (this.state.cookiesAccepted) {
        return null;
      } else {
        return this.renderBanner();
      }
    } else {
      return null;
    }
  };

}
