import React from "react";
import ConnectAppIframe from '../ConnectAppIframe';
import ConfluenceMacroEditorPanel from './ConfluenceMacroEditorPanel';
import confluenceMacroUtil from './ConfluenceMacroUtil';
import storageDAO from '../../../../backend/data/StorageDAO';
import csvUtil from '../../../../commonbase/csvUtil';
import urlUtil from '../../../../shared/util/UrlUtil';
import { startCollapsedDefault, viewTitleDefault, viewChartDefault, viewTableDefault } from './ConfluenceMacroConstants';

/* global AP */

export default class ConfluenceMacroEditor extends ConnectAppIframe {

  mounted = false;

  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.mounted = true;
    this.setState(this.buildStateFromProps(this.props));
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    this.mounted = false;
  };

  componentDidMount() {
    super.componentDidMount();
    if (AP) {
      AP.require(["confluence", "dialog"], (confluence, dialog) => {
        dialog.getButton("submit").bind(() => this.onSave(confluence));
        this.loadMacroData(confluence);
      });
    } else {
      storageDAO.getData('mock-confluence-params', undefined).then((macroParams) => {
        if (!macroParams) {
          macroParams = this.getDefaultMacroParams();
        }
        const forcedDefinitionNameParam = urlUtil.getQueryParameter('definition-name');
        const forcedDefinitionName = forcedDefinitionNameParam ? decodeURIComponent(forcedDefinitionNameParam) : undefined;
        const forcedDefinitionUuid = urlUtil.getQueryParameter('definition-uuid');
        // console.log('Roobrick: ConfluenceMacroEditor.loadMacroData: forcedDefinitionNameParam:', forcedDefinitionNameParam);
        // console.log('Roobrick: ConfluenceMacroEditor.loadMacroData: forcedDefinitionName:', forcedDefinitionName);
        this.setState({
          macroParams: macroParams,
          forcedDefinitionName: forcedDefinitionName,
          forcedDefinitionUuid: forcedDefinitionUuid
        });
      });
    }
  }

  componentDidUpdate() {
    // this.sizeAppFrameToContent(0, 500, 5000);
    this.debouncedSizeAppFrameToContent();
  }

  buildStateFromProps = (props) => {
    return {};
  };

  loadMacroData = (confluence) => {
    confluence.getMacroData((macroParams) => {
      if (!macroParams) {
        macroParams = this.getDefaultMacroParams();
      }
      const forcedDefinitionNameParam = urlUtil.getQueryParameter('definition-name');
      const forcedDefinitionName = forcedDefinitionNameParam ? decodeURIComponent(forcedDefinitionNameParam) : undefined;
      const forcedDefinitionUuid = urlUtil.getQueryParameter('definition-uuid');
      // console.log('Roobrick: ConfluenceMacroEditor.loadMacroData: macroParams:', macroParams);
      // console.log('Roobrick: ConfluenceMacroEditor.loadMacroData: forcedDefinitionNameParam:', forcedDefinitionNameParam);
      // console.log('Roobrick: ConfluenceMacroEditor.loadMacroData: forcedDefinitionName:', forcedDefinitionName);
      // console.log('Roobrick: ConfluenceMacroEditor.loadMacroData: forcedDefinitionUuid:', forcedDefinitionUuid);
      // console.log('Roobrick: ConfluenceMacroEditor.loadMacroData: selectedAssessmentUuidsCsv:', macroParams.selectedAssessmentUuidsCsv);
      this.setState({
        macroParams: macroParams,
        forcedDefinitionName: forcedDefinitionName,
        forcedDefinitionUuid: forcedDefinitionUuid
      });
    });
  };

  getDefaultMacroParams = () => {
    return {
      selectedDefinitionUuid: undefined,
      selectedAssessmentUuid: undefined
    };
  };

  onParamsChange = (macroParams) => {
    // console.log('Roobrick: ConfluenceMacroEditor.onParamsChange: macroParams:', macroParams);
    if (AP) {
      this.updatedParams = macroParams;
    } else {
      storageDAO.setData('mock-confluence-params', macroParams).then(() => {
        this.updatedParams = macroParams;
      });
    }
    this.setState({
      macroParams: macroParams
    });
  };

  onSave = (confluence) => {
    // console.log('Roobrick: ConfluenceMacroEditor.onSave: this.updatedParams:', this.updatedParams);
    if (this.updatedParams) {
      confluence.saveMacro(this.updatedParams);
    }
    confluence.closeMacroEditor();
    this.setState({
      macroParams: this.updatedParams
    });
    return true;
  };

  renderConfluenceMacroEditorPanel = () => {
    const definitionUuid = this.state.forcedDefinitionUuid ? this.state.forcedDefinitionUuid : this.state.macroParams.selectedDefinitionUuid;
    const selectedAssessmentUuids = csvUtil.csvToArray(this.state.macroParams.selectedAssessmentUuidsCsv);
    return (
      <ConfluenceMacroEditorPanel
        definitionIsFixed={!!this.state.forcedDefinitionUuid}
        forcedDefinitionName={this.state.forcedDefinitionName}
        selectedDefinitionUuid={definitionUuid}
        selectedAssessmentUuids={selectedAssessmentUuids}
        startCollapsed={confluenceMacroUtil.getBooleanMacroParameter(this.state.macroParams, 'startCollapsed', startCollapsedDefault)}
        viewTitle={confluenceMacroUtil.getBooleanMacroParameter(this.state.macroParams, 'viewTitle', viewTitleDefault)}
        viewChart={confluenceMacroUtil.getBooleanMacroParameter(this.state.macroParams, 'viewChart', viewChartDefault)}
        viewTable={confluenceMacroUtil.getBooleanMacroParameter(this.state.macroParams, 'viewTable', viewTableDefault)}
        onParamsChange={this.onParamsChange}
      />
    );
  };

  renderConnectAppIframe = () => {
    return (
      <div>
        {this.state.macroParams ? this.renderConfluenceMacroEditorPanel() : null}
      </div>
    );
  };

}
