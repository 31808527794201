import React, { PureComponent } from 'react';
import backgroundImageUtil from '../components/widget/media/BackgroundImageUtil';
import session from '../../shared/model/auth/Session';
import IUser from '../../shared/model/auth/IUser';

interface Props {
}

interface State {
}

export default class BackgroundView extends PureComponent<Props, State> {

  _active = false;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this._active = true;
    session.registerListener(this.onSessionChange);
    this._updateBackgroundImage();
  }

  componentWillUnmount() {
    this._active = false;
    session.unregisterListener(this.onSessionChange);
    // backgroundImageUtil.clearBackgroundImageAsync();
  }

  onSessionChange = (user: undefined | IUser) => {
    this._updateBackgroundImage();
  };

  _updateBackgroundImage = () => {
    backgroundImageUtil.setBackgroundImageBasedOnUserPreferences();
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}
