import { SearchOptions } from './SearchOptions';
import Definition from '../definition/Definition';
import DefinitionSearchResult from '../definition/DefinitionSearchResult';

// type LastSearch = {
//   userId: string,
//   searchOptions: SearchOptions,
//   matchingDefinitions: Definition[]
// }

type LastSearchNew = {
  userId: string,
  searchOptions: SearchOptions,
  matchingDefinitionSearchResults: DefinitionSearchResult[]
}

class DefinitionSearch {

  _cachedDefinitions: Definition[] = [];
  _cachedDefinitionSearchResults: DefinitionSearchResult[] = [];
  _definitionSearchers: ((userId: string, searchOptions: SearchOptions) => Promise<Definition[]>)[] = [];
  _definitionSearchersNew: ((userId: string, searchOptions: SearchOptions) => Promise<DefinitionSearchResult[]>)[] = [];
  // _lastSearch: LastSearch = {
  //   userId: '',
  //   searchOptions: {
  //     searchString: 'ZZZ', // anything that it unlikely to be searched initially
  //     maxResults: 1
  //   },
  //   matchingDefinitions: []
  // };
  _lastSearchNew: LastSearchNew = {
    userId: '',
    searchOptions: {
      searchString: 'ZZZ', // anything that it unlikely to be searched initially
      maxResults: 1
    },
    matchingDefinitionSearchResults: []
  };

  // searchDefinitions = async (userId: string, searchOptions: SearchOptions): Promise<Definition[]> => {
  //   if (this._isRefinementOfLastSearch(userId, searchOptions)) {
  //     return this._searchCache(userId, searchOptions);
  //   } else {
  //     const searchPromises: Promise<any>[] = [];
  //     searchPromises.push(this._searchCache(userId, searchOptions));
  //     for (const definitionSearcher of this._definitionSearchers) {
  //       searchPromises.push(this._searchWithSearcher(definitionSearcher, userId, searchOptions));
  //     }
  //     return Promise.all(searchPromises).then((searchResultSets) => {
  //       const definitions = [];
  //       for (const searchResultSet of searchResultSets) {
  //         this._addSearchResults(searchResultSet, definitions, searchOptions);
  //       }
  //       const sortedDefinitions = this._sortDefinitions(definitions);
  //       const lastSearch: LastSearch = {
  //         userId: userId,
  //         searchOptions: searchOptions,
  //         matchingDefinitions: sortedDefinitions
  //       }
  //       this._lastSearch = lastSearch;
  //       return sortedDefinitions;
  //     });
  //   }
  // }

  searchDefinitionsNew = async (userId: string, searchOptions: SearchOptions): Promise<DefinitionSearchResult[]> => {
    if (this._isRefinementOfLastSearchNew(userId, searchOptions)) {
      return this._searchCacheNew(userId, searchOptions);
    } else {
      const searchPromises: Promise<any>[] = [];
      searchPromises.push(this._searchCacheNew(userId, searchOptions));
      for (const definitionSearcherNew of this._definitionSearchersNew) {
        searchPromises.push(this._searchWithSearcherNew(definitionSearcherNew, userId, searchOptions));
      }
      return Promise.all(searchPromises).then((searchResultSets) => {
        const definitionSearchResults = [];
        for (const searchResultSet of searchResultSets) {
          this._addSearchResultsNew(searchResultSet, definitionSearchResults, searchOptions);
        }
        const sortedDefinitionSearchResults = this._sortDefinitionSearchResults(definitionSearchResults);
        const lastSearchNew: LastSearchNew = {
          userId: userId,
          searchOptions: searchOptions,
          matchingDefinitionSearchResults: sortedDefinitionSearchResults
        }
        this._lastSearchNew = lastSearchNew;
        return sortedDefinitionSearchResults;
      });
    }
  }

  // _isRefinementOfLastSearch = (userId: string, searchOptions: SearchOptions) => {
  //   if (this._lastSearch.userId === userId) {
  //     if (this._lastSearch.searchOptions.maxResults >= searchOptions.maxResults) {
  //       if (this._lastSearch.searchOptions.searchString.length >= searchOptions.searchString.length) {
  //         if (searchOptions.searchString.indexOf(this._lastSearch.searchOptions.searchString) === 0) {
  //           return true;
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // };

  _isRefinementOfLastSearchNew = (userId: string, searchOptions: SearchOptions) => {
    if (this._lastSearchNew.userId === userId) {
      if (this._lastSearchNew.searchOptions.maxResults >= searchOptions.maxResults) {
        if (this._lastSearchNew.searchOptions.searchString.length >= searchOptions.searchString.length) {
          if (searchOptions.searchString.indexOf(this._lastSearchNew.searchOptions.searchString) === 0) {
            return true;
          }
        }
      }
    }
    return false;
  };

  uuidsToDefinitions = async (definitionUuids: string[]): Promise<Definition[]> => {
    return new Promise((resolve, reject) => {
      const definitions: Definition[] = [];
      const searchedDefinitionUuids = {};
      for (const definitionUuid of definitionUuids) {
        if (!searchedDefinitionUuids[definitionUuid]) {
          const cachedDefinition = this._findInCache(definitionUuid);
          if (cachedDefinition) {
            definitions.push(cachedDefinition);
          }
          searchedDefinitionUuids[definitionUuid] = true;
        }
      }
      resolve(definitions);
    });
  };

  _findInCache = (definitionUuid: string) => {
    for (const definition of this._cachedDefinitions) {
      if (definition.uuid === definitionUuid) {
        return definition;
      }
    }
    return undefined;
  }

  doesMatch = (definitionName: string, searchOptions: SearchOptions) => {
    const definitionNameLower = definitionName.toLowerCase();
    return definitionNameLower.indexOf(searchOptions.searchString.toLowerCase()) >= 0;
  }

  addSearcher = (definitionSearcher: (userId: string, searchOptions: SearchOptions) => Promise<DefinitionSearchResult[]>) => {
    this._definitionSearchersNew.push(definitionSearcher);
  };

  // _searchWithSearcher = (definitionSearcher: (userId: string, searchOptions: SearchOptions) => Promise<Definition[]>, userId: string, searchOptions: SearchOptions) => {
  //   return definitionSearcher(userId, searchOptions)
  //     .then((definitions) => {
  //       this._addToCache(definitions);
  //       return definitions;
  //     });
  // };

  _searchWithSearcherNew = (definitionSearcher: (userId: string, searchOptions: SearchOptions) => Promise<DefinitionSearchResult[]>, userId: string, searchOptions: SearchOptions) => {
    return definitionSearcher(userId, searchOptions)
      .then((definitionSearchResults) => {
        this._addToCacheNew(definitionSearchResults);
        return definitionSearchResults;
      });
  };

  // _addSearchResults = (searchResultSet: Definition[], definitions: Definition[], searchOptions: SearchOptions) => {
  //   for (const definition of searchResultSet) {
  //     if (!this._findDefinition(definition.uuid, definitions)) {
  //       definitions.push(definition);
  //     }
  //   }
  // }

  _addSearchResultsNew = (searchResultSet: DefinitionSearchResult[], definitionSearchResults: DefinitionSearchResult[], searchOptions: SearchOptions) => {
    for (const definitionSearchResult of searchResultSet) {
      if (!this._findDefinitionNew(definitionSearchResult.getDefinitionReference().definitionUuid, definitionSearchResults)) {
        definitionSearchResults.push(definitionSearchResult);
      }
    }
  }

  // _searchCache = async (userId: string, searchOptions: SearchOptions): Promise<Definition[]> => {
  //   return new Promise((resolve, reject) => {
  //     const searchStringLower = searchOptions.searchString.toLowerCase();
  //     const matchingDefinitions: Definition[] = [];
  //     for (const definition of this._cachedDefinitions) {
  //       const definitionNameLower = definition.name.toLowerCase();
  //       if (definitionNameLower.indexOf(searchStringLower) >= 0) {
  //         matchingDefinitions.push(definition);
  //       }
  //     }
  //     resolve(matchingDefinitions);
  //   });
  // }

  _searchCacheNew = async (userId: string, searchOptions: SearchOptions): Promise<DefinitionSearchResult[]> => {
    return new Promise((resolve, reject) => {
      const searchStringLower = searchOptions.searchString.toLowerCase();
      const matchingDefinitionSearchResults: DefinitionSearchResult[] = [];
      for (const definitionSearchResult of this._cachedDefinitionSearchResults) {
        const definitionNameLower = definitionSearchResult.getDefinitionReference().definitionName.toLowerCase();
        if (definitionNameLower.indexOf(searchStringLower) >= 0) {
          matchingDefinitionSearchResults.push(definitionSearchResult);
        }
      }
      resolve(matchingDefinitionSearchResults);
    });
  }

  // _addToCache = (definitions: Definition[]) => {
  //   for (const definition of definitions) {
  //     if (!this._findDefinition(definition.uuid, this._cachedDefinitions)) {
  //       this._cachedDefinitions.push(definition);
  //     }
  //   }
  // }

  _addToCacheNew = (definitionSearchResults: DefinitionSearchResult[]) => {
    for (const definitionSearchResult of definitionSearchResults) {
      if (!this._findDefinitionNew(definitionSearchResult.getDefinitionReference().definitionUuid, this._cachedDefinitionSearchResults)) {
        this._cachedDefinitionSearchResults.push(definitionSearchResult);
      }
    }
  }

  // _findDefinition = (definitionUuid: string, definitions: Definition[]): undefined | any => {
  //   for (const definition of definitions) {
  //     if (definition.uuid === definitionUuid) {
  //       return definition;
  //     }
  //   }
  //   return undefined;
  // }

  _findDefinitionNew = (definitionUuid: string, definitionSearchResults: DefinitionSearchResult[]): undefined | any => {
    for (const definitionSearchResult of definitionSearchResults) {
      if (definitionSearchResult.getDefinitionReference().definitionUuid === definitionUuid) {
        return definitionSearchResult;
      }
    }
    return undefined;
  }

  _sortDefinitions = (definitions: Definition[]): Definition[] => {
    return definitions.sort((definitionA: Definition, definitionB: Definition) => {
      const nameA = definitionA.name.toLowerCase();
      const nameB = definitionB.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  _sortDefinitionSearchResults = (definitionSearchResults: DefinitionSearchResult[]): DefinitionSearchResult[] => {
    return definitionSearchResults.sort((definitionSearchResultA: DefinitionSearchResult, definitionSearchResultB: DefinitionSearchResult) => {
      const nameA = definitionSearchResultA.getDefinitionReference().definitionName.toLowerCase();
      const nameB = definitionSearchResultB.getDefinitionReference().definitionName.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}

export default new DefinitionSearch();
