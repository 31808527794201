import constants from '../Constants';
import ValidationResult from '../validation/ValidationResult';
import DriveFolder from './DriveFolder';

class DriveUtil {

  getParentFolderUuids = (folder: DriveFolder) => {
    // "/root-folder/ecac5383-dab4-b6aa-ab20-57797eba9f2e"
    const uuids = folder.path.split('/');
    const folderUuids: string[] = [];
    for (let uuid of uuids) {
      if (uuid) {
        folderUuids.push(uuid);
      }
    }
    return folderUuids;
  };

  validateFolderName = (name: string) => {
    let error: null | string = null;
    if (!name) {
      error = 'The folder name must not be empty.';
    } else if (name.length < constants.minDriveFolderNameLength) {
      error = `The folder name must be at least ${constants.minDriveFolderNameLength} characters long.`;
    } else if (name.length > constants.maxDriveFolderNameLength) {
      error = `The folder name must not be longer than ${constants.maxDriveFolderNameLength} characters.`;
    } else {
      // eslint-disable-next-line no-useless-escape
      const validFilename = /([a-zA-Z0-9\s_\\.\-\(\):])+$/i.test(name);
      if (validFilename) {

      } else {
        error = `The folder name must contain only letters, numbers, spaces, dashes and dots.`;
      }
    }
    return new ValidationResult(error);
  };

}

export default new DriveUtil();
