import i18n from './i18n/I18N';

export class Constants {

  appDomain = 'www.roobrick.org';
  appBaseUrl = 'https://' + this.appDomain;
  appTitle = 'Roobrick';
  appByline = 'Assess all the things';

  companyName = 'Barefoot Australia Pty Ltd';

  maxMomentLength = 1666;
  maxDefinitionNameLength = 99;
  maxDefinitionDescriptionLength = 4999;
  maxDefinitionStatementLength = 2323;
  maxDefinitionAspectTitleLength = 99;
  maxDefinitionRowNameLength = 200;
  maxDefinitionColumnTitleLength = 99;
  maxDefinitionLabelsCsvLength = 444;
  maxAssessmentAnnotationLength = 2222;
  maxAssessmentGeneralNotesLength = 9888;
  maxGoogleClassroomSharingMessageLength = 1111;
  maxInAppSharingMessageLength = 222;
  maxEmailSharingMessageLength = 1000;

  minDriveFolderNameLength = 1;
  maxDriveFolderNameLength = 40;

  minContactUsMessageLength = 10;
  maxContactUsMessageLength = 1222;

  maxDisplayNameLength = 99;
  maxSubjectLength = 88;
  maxEmailLength = 111;

  // defaultRubricBorderColor = '#BF2600';
  // defaultRubricHoverColor = '#fff5fd';

  defaultRubricBorderColor = '#ccc';
  defaultRubricHoverColor = '#fafafa';

  monthNames = [
    i18n.January, i18n.February, i18n.March, i18n.April, i18n.May,
    i18n.June, i18n.July, i18n.August, i18n.September, i18n.October,
    i18n.November, i18n.December
  ];

  abbreviatedMonthNames = [
    i18n.Jan, i18n.Feb, i18n.Mar, i18n.Apr, i18n.May,
    i18n.Jun, i18n.Jul, i18n.Aug, i18n.Sep, i18n.Oct,
    i18n.Nov, i18n.Dec
  ];

  millisPerHour = 3600000;
  millisPerDay = 24 * 3600000;

}

export default new Constants();
