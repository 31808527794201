import React, { PureComponent } from 'react';
import AssessmentStatusSelector from './AssessmentStatusSelector';
import InlineEdit from '@atlaskit/inline-edit';
import Label from '../../widget/Label';
import { gridSize, fontSize } from '@atlaskit/theme';
import Lozenge from '@atlaskit/lozenge';
import permissionUtil from '../../../../shared/model/auth/PermissionUtil';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import styled from 'styled-components';
import Tooltip from "@atlaskit/tooltip";
import Definition from '../../../../shared/model/rubric/definition/Definition';
import Assessment from '../../../../shared/model/rubric/score/Assessment';

interface Props {
  definition: Definition
  assessment: Assessment
  label?: string
  statusIdsToConfigs: any
  onAssessmentStatusChange: (assessment: Assessment, newStatusId: string) => void
}

interface State {
  definition: Definition
  assessment: Assessment
  label: undefined | string
  statusIdsToConfigs: any
  currentStatusId: string
}

export default class AssessmentStatus extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    const currentStatusId: string = rubricUtil.getAssessmentStatus(props.assessment);
    const state: State = {
      definition: props.definition,
      assessment: props.assessment,
      statusIdsToConfigs: props.statusIdsToConfigs,
      label: props.label,
      currentStatusId: currentStatusId
    };
    return state;
  };

  onAssessmentStatusChange = (newStatusId: string) => {
    this.setState({
      currentStatusId: newStatusId
    });
    this.props.onAssessmentStatusChange(this.state.assessment, newStatusId);
  };

  onConfirm = (newStatus) => {
  };

  renderEditView = (fieldProps) => {
    return (
      <AssessmentStatusSelector 
        statusId={this.state.assessment.statusId}
        label={undefined}
        statusIdsToConfigs={this.state.statusIdsToConfigs}
        onStatusChange={this.onAssessmentStatusChange}
        {...fieldProps}
        autoFocus />
    );
  };

  renderStatus = () => {
    const config = rubricUtil.getCurrentStatusConfig(this.state.definition, this.state.assessment);
    if (config && config.text) {
      return (
        <Lozenge
          isBold={false}
          appearance={config ? config.appearance : undefined}>
          {config.text}
        </Lozenge>
      );
    } else {
      return ('[no status yet]');
    }
  }

  renderEditableStatus = () => {
    const ReadViewContainer = styled.div`
      display: flex;
      font-size: ${fontSize()}px;
      line-height: ${(gridSize() * 2.5) / fontSize()};
      min-width: 50px;
      max-width: 100%;
      min-height: ${(gridSize() * 2.5) / fontSize()}em;
      padding: ${gridSize()}px ${gridSize() - 2}px;
      word-break: break-word
    `;
    const renderedLabel = this.state.label ? <Label text={this.state.label}/> : null;
    return (
      <React.Fragment>
        {renderedLabel}
        <div style={{marginTop: '-8px'}}>
          <InlineEdit
            defaultValue={this.state.assessment.statusId}
            label={undefined}
            editView={fieldProps => this.renderEditView(fieldProps)}
            readView={() => (
              <Tooltip content={`Click to change the status of assessment ${this.state.assessment.name}`}>
                <ReadViewContainer>
                  {this.renderStatus()}
                </ReadViewContainer>
              </Tooltip>
            )}
            onConfirm={value => this.onConfirm(value)}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const editable = permissionUtil.canWriteAssessment(this.state.assessment);
    if (editable) {
      return this.renderEditableStatus();
    } else {
      return this.renderStatus();
    }
  }

}
