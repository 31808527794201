import React, { FormEvent, KeyboardEvent, PureComponent } from 'react';
import actions from '../../shared/actions/Actions';
import ContentWrapper from '../components/widget/ContentWrapper';
import currentRubric from '../../shared/model/rubric/CurrentRubric';
import RubricEditor from '../components/rubric/RubricEditor';
import DeleteRubricButton from "../components/rubric/DeleteRubricButton";
import IconButton from '../components/widget/IconButton';
import LiftedPanel from '../components/widget/LiftedPanel';
import LiftedPageTitle from '../components/widget/LiftedPageTitle';
import rubricDAO from "../../backend/data/RubricDAO";
import navConstants from "../components/nav/NavConstants";
import permissionUtil from "../../shared/model/auth/PermissionUtil";
import rubricPageHelper from './RubricPageHelper';
import { ViewRubricIcon, ViewRubricFilledIcon } from '../components/icon/NamedIcons';
import constants from "../../shared/model/Constants";
import { EditNameIcon, SubmitNameIcon } from '../components/icon/NamedIcons';
import { statusDefinitions, StatusProvider } from '../../shared/model/status/Status';
import util from '../../shared/util/Util';
import widgetUtil from '../components/widget/WidgetUtil';
import FormlessTextField from '../components/widget/FormlessTextField';
import Definition from '../../shared/model/rubric/definition/Definition';
import Tooltip from '@atlaskit/tooltip';
import ToolbarLeft from '../components/widget/toolbar/ToolbarLeft';
import ToolbarJustify from '../components/widget/toolbar/ToolbarJustify';
import ToolbarRight from '../components/widget/toolbar/ToolbarRight';
import ToolbarItem from '../components/widget/toolbar/ToolbarItem';

interface Props {
  history: any[]
}

interface State {
  editingTitle: boolean
  definition: undefined | Definition
}

export default class EditRubricPage extends PureComponent<Props, State> {

  statusProvider: any;
  onNameChangeDebounced: any;
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      editingTitle: false,
      definition: undefined
    };
    this.statusProvider = new StatusProvider('EditRubricPage');
    this.onNameChangeDebounced = util.debounce(this.onNameChange, 500, false);
  }

  componentDidMount() {
    rubricPageHelper.updateTitle('Edit');
    const uuidHashParameterIndex = 0;
    rubricPageHelper.resolveDefinitionContext(uuidHashParameterIndex, permissionUtil.canWriteDefinition)
      .then((definition: undefined | Definition) => {
        if (definition) {
          this.statusProvider.setCurrentStatus(statusDefinitions.statuses.workInProgress);
          this.setState({
            definition: definition
          });
          rubricPageHelper.updateTitle('Edit - ' + definition.name);
        } else {
          const inEditMode = false;
          currentRubric.setDefinition(undefined, inEditMode);
        }
      });
  }

  componentWillUnmount() {
    this.statusProvider.setActive(false);
  };

  onNameEditClick = () => {
    this.setState({
      editingTitle: true
    });
  };

  onSubmitNameClick = () => {
    this.setState({
      editingTitle: false
    });
  };

  onDeleteRubricFailed = () => {
  };

  onDeleteRubric = () => {
    this.props.history.push(navConstants.homePageHash);
  };

  onNameChangePreBounce = (event: FormEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    this.onNameChangeDebounced(newName);
  };

  onNameChange = (newName: string) => {
    if (this.state.definition) {
      const newDefinition = this.state.definition;
      if (newName && newName !== newDefinition.name) {
        newDefinition.name = newName;
        this.setState({
          definition: newDefinition
        });
        rubricPageHelper.updateTitle(newDefinition.name);
        // rubricDAO.debouncedSaveDefinition(newDefinition);
        rubricDAO.saveDefinition(newDefinition);
        this.onNameChangeComplete();
      }
    }
  };

  onNameChangeComplete = () => {
    this.forceUpdate();
    actions.onDefinitionRenamed(this.state.definition);
  };

  onViewRubric = () => {
    currentRubric.setDefinition(this.state.definition, false);
  };

  renderPageToolbar = () => {
    return (
      <ToolbarRight>
        <ToolbarItem>
          <Tooltip content="Stop editing and view rubric">
            <IconButton
              normalIcon={<ViewRubricIcon label="View rubric"/>}
              hoverIcon={<ViewRubricFilledIcon label="View rubric"/>}
              onClick={this.onViewRubric}
            />
          </Tooltip>
        </ToolbarItem>
        <ToolbarItem>
          <DeleteRubricButton
            definition={this.state.definition}
            renderAsIconButton={true}
            onRubricNotDeleted={this.onDeleteRubricFailed}
            onRubricDeleted={this.onDeleteRubric}
          />
        </ToolbarItem>
      </ToolbarRight>
    );
  };

  renderTitle = () => {
    if (this.state.editingTitle) {
      const nameLength = this.state.definition && this.state.definition.name ? this.state.definition.name.length : 0;
      const maxWidth = 500;
      const minWidth = 300;
      const minWidthCharCount = 20;
      const maxWidthCharCount = 50;
      let width = minWidth;
      if (nameLength <= minWidthCharCount) {
        width = minWidth;
      } else if (nameLength >= maxWidthCharCount) {
        width = maxWidth;
      } else {
        const pixelsPerChar = (maxWidth - minWidth) / (maxWidthCharCount - minWidthCharCount);
        width = minWidth + pixelsPerChar * (nameLength - minWidthCharCount);
      }
      return (
        <ToolbarLeft>
          <div
            style={{marginTop: '0px', width: width + 'px'}}
          >
            <FormlessTextField
              name="title"
              label={undefined}
              maxLength={constants.maxDefinitionNameLength}
              shouldFitContainer={true}
              value={this.state.definition ? this.state.definition.name : ''}
              onChange={this.onNameChangePreBounce}
              onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => widgetUtil.bindTextFieldEnterEvent(event, this.onSubmitNameClick)}
            />
          </div>
          <div
            style={{}}
            className="clickable"
            onClick={this.onSubmitNameClick}
          >
            <SubmitNameIcon label="title" size="large" />
          </div>
        </ToolbarLeft>
      );
    } else {
      return (
        <ToolbarLeft>
          <div>
            <span>{this.state.definition ? this.state.definition.name : ''}</span>
          </div>
          <div
            style={{marginLeft: '10px'}}
            className="clickable"
            onClick={this.onNameEditClick}
          >
            <EditNameIcon label="Edit name" size="large" primaryColor="#555"/>
          </div>
        </ToolbarLeft>
      );
    }
  };

  renderPageTitleBar = () => {
    return (
      <ToolbarJustify>
        <LiftedPageTitle>
          {this.renderTitle()}
        </LiftedPageTitle>
        <LiftedPanel>
          {this.state.definition ? this.renderPageToolbar() : null}
        </LiftedPanel>
      </ToolbarJustify>
    );
  };

  renderDefinitionEditor = (definition: Definition) => {
    return (
      <RubricEditor
        definition={definition}
        onNameChangeComplete={this.onNameChangeComplete}
        {...this.props}
      />
    );
  }

  render() {
    return (
      <ContentWrapper>
        {this.renderPageTitleBar()}
        <div className="newMajorSection">&nbsp;</div>
        <div className="newMajorSection">
          {this.state.definition ? this.renderDefinitionEditor(this.state.definition) : null}
       </div>
      </ContentWrapper>
    );
  }
}
