import React, { PureComponent } from 'react';
import Label from './Label';
import ResizingTextArea from './ResizingTextArea';
import widgetUtil from './WidgetUtil';

export default class EditableTextArea extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
    this.state.inEditMode = props.inEditMode === undefined ? false : props.inEditMode;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  buildStateFromProps = (props) => {
    return {
      value: props.value,
      label: props.label,
    };
  };

  onTextClick = () => {
    this.setState({
      inEditMode: true
    });
  };

  onBlur = (event) => {
    this.setState({
      inEditMode: false
    });
  };

  renderPlaceholderHtml = () => {
    const html = `<span class="placeholder-text">${this.props.placeholder}</span>`;
    return html;
  };

  renderText = () => {
    const html = this.state.value ? widgetUtil.markdownToHtml(this.state.value) : this.renderPlaceholderHtml();
    return (
      <div
        className="rich-text editorDerivedContent editableText"
        style={{minHeight: this.props.minHeight}}
        dangerouslySetInnerHTML={{__html: html}}
        onClick={this.onTextClick}
      >
      </div>
    );
  };

  render() {
    if (this.state.inEditMode) {
      return (
        <ResizingTextArea
          label={this.state.label}
          compact={this.props.compact}
          autoFocus={true}
          shouldFitContainer={this.props.shouldFitContainer}
          minimumRows={this.props.minimumRows}
          minHeight={this.props.minHeight}
          maxHeight={this.props.maxHeight}
          maxLength={this.props.maxLength}
          enableResize={this.props.enableResize}
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.props.onChange}
          onBlur={this.onBlur}
        />
      )
    } else {
      return (
        <div>
          {this.state.label ? (<Label text={this.state.label}/>) : null}
          {this.renderText()}
        </div>
      )
    }
  }

}