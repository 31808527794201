import React, { Component } from 'react';
import './Board.css';
import boardState, { IList, ICard, ListDragInfo } from './BoardState';
import List from './List';

interface Props {
  renderCard: (row: any) => any
  lists: IList[]
  onDragComplete: (card: ICard, toList: IList) => void
  onCardLinkClick: (card: ICard) => void
}

interface State {
  lists: IList[]
}

export default class Board extends Component<Props, State> {

  containerHeight: undefined | number = undefined;

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    this.containerHeight = undefined;
  }

  buildStateFromProps = (props: Props) => {
    return {
      lists: props.lists
    }
  };

  onCardDragStart = (dragInfo: ListDragInfo) => {
    boardState.setDragInfo(dragInfo);
  }

  onDragOverList = (dragOverListEvent) => {
    dragOverListEvent.preventDefault();
  }

  getListFromIndex = (listIndex) => {
    return this.state.lists[listIndex];
  };

  getListFromId = (listId: string): undefined | IList => {
    for (const list of this.state.lists) {
      if (list.id === listId) {
        return list;
      }
    }
    return undefined;
  };

  onDropCardOnList = (cardDropEvent, toListId) => {
    const lists: IList[] = [];
    for (const list of this.state.lists) {
      lists.push(list);
    }
    const parsedDragInfo = boardState.getDragInfo();
    if (parsedDragInfo) {
      const fromList = this.getListFromId(parsedDragInfo.fromListId);
      const toList = this.getListFromId(toListId);
      if (fromList && toList) {
        const cardsArray = fromList.cards;
        const card = cardsArray.find(card => card.assessmentUuid === parsedDragInfo.cardAssessmentUuid);
        if (card) {
          const indexOfCard = cardsArray.findIndex(card => card.assessmentUuid === parsedDragInfo.cardAssessmentUuid);
          fromList.cards.splice(indexOfCard, 1);
          toList.cards.push(card);
          this.setState({
            lists: lists
          });
          this.props.onDragComplete(card, toList);
        }
      }
    }
  }

  renderLists = (height: any) => {
    return this.state.lists.map((list, index) => {
      return (
        <li className="list-wrapper" key={index}>
          <List {...list}
            height={height}
            onDragStart={this.onCardDragStart}
            onDragOver={(dragOverListEvent) => this.onDragOverList(dragOverListEvent)}
            onDrop={(cardDropEvent) => { this.onDropCardOnList(cardDropEvent, `${list.id}`) }}
            onCardLinkClick={this.props.onCardLinkClick}
          />
        </li>
      )
    });
  }

  render() {
    return (
      <div className="board">
        <ul className="lists">
          {this.renderLists(window.innerHeight)}
        </ul>
      </div>
    );
  };

}




