import dashboardState from '../../rubric/dashboard/DashboardState';
import driveGraph from '../../drive/DriveGraph';
import ListenerGroup from '../../../../shared/util/ListenerGroup';
import selectUtil from '../../../../shared/util/SelectUtil';
import util from '../../../../shared/util/Util';

const defaultRubricOptionGroups = [];
const otherGroupLabel = 'Other';

class DefinitionSelectContext {

  listenerGroup = new ListenerGroup('DefinitionSelectContext');

  constructor() {
    this.definitionOptionGroups = util.deepCloneObject(defaultRubricOptionGroups);
    dashboardState.registerListener(this._onDashboardStateChange);
    driveGraph.registerListener(this._onDriveGraphChange);
    setTimeout(this._loadOptions, 1);
  }

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  getCurrentOptions = () => {
    return this.definitionOptionGroups;
  };

  _loadOptions = () => {
    if (dashboardState.hasReceivedData()) {
      this._onDashboardStateChange(dashboardState.getState());
    }
  };

  _onDashboardStateChange = () => {
    // debugger;
    if (this.driveGraphLoaded) {
      const definitionOptionGroups = util.deepCloneObject(this.getCurrentOptions());
      dashboardState.iterateDefinitionInfos((definitionInfo) => {
        this._addDefinition(definitionOptionGroups, definitionInfo);
      });
      const sortedOptions = selectUtil.sortOptions(definitionOptionGroups);
      this.definitionOptionGroups = sortedOptions;
      this._notifyListeners();
    }
  };

  _onDriveGraphChange = (state) => {
    // debugger;
    const definitionOptionGroups = util.deepCloneObject(this.getCurrentOptions());
    driveGraph.iterateFileReferences((fileReference) => {
      const folder = driveGraph.findFolderContainingByDefinitionUuid(fileReference.definitionUuid);
      const groupName = folder ? folder.name : undefined;
      this._addDefinition(definitionOptionGroups, fileReference, groupName);
    });
    const sortedOptions = selectUtil.sortOptions(definitionOptionGroups);
    this.definitionOptionGroups = sortedOptions;
    if (!this.driveGraphLoaded) {
      this.driveGraphLoaded = true;
      setTimeout(this._onDashboardStateChange, 1);
    } else {

      setTimeout(this._onDashboardStateChange, 1);


    }
  };

  _addDefinition = (definitionOptionGroups, definitionReference, groupName) => {
    const existingOption = selectUtil.findOption(definitionOptionGroups, definitionReference.definitionUuid);
    if (existingOption) {
      existingOption.label = definitionReference.definitionName;
    } else {
      const groupLabel = groupName ? groupName : otherGroupLabel;
      let group = selectUtil.findGroup(definitionOptionGroups, groupLabel);
      if (!group) {
        group = {
          label: groupLabel,
          options: []
        };
        definitionOptionGroups.push(group);
      }
      const option = {
        label: definitionReference.definitionName,
        value: definitionReference.definitionUuid
      };
      group.options.push(option);
    }
  };
  
  _notifyListeners = () => {
    this.listenerGroup.notifyListeners(this.definitionOptionGroups);
  };

}

export default new DefinitionSelectContext();
