import DemoBuilder from '../DemoBuilder';
import {
  DemoDefinitionBuilder,
  DemoDefinitionDelayInstructionBuilder,
  DemoDefinitionRestartInstructionBuilder
} from '../DemoDefinitionBuilder';
import demoContent from "./DemoContent";

export default class DemoSidebarBuilder extends DemoBuilder {

  build = () => {
    return new DemoDefinitionBuilder()
    .setDebugOn(false)
    .setRenderComputer(false)
    .setWidth(this.width)
    .setMarginLeft(200)
    .setMarginRight(200)
      .addInstructionGroupings([
        demoContent.addSidebarPartAInstructions,
        demoContent.addSidebarPartBInstructions,
        demoContent.addSidebarPartCInstructions,
      ])

    // Collapsed view...

    // Web view...

    // App view...

    // Wait and repeat...
    .addInstruction(
      new DemoDefinitionDelayInstructionBuilder()
        .setMilliseconds(4000)
        .build()
    )
    .addInstruction(
      new DemoDefinitionRestartInstructionBuilder()
      .build()
    )
    .build();
  }

}