import colorRange from '../../../../commonbase/color/redToGreenColorRange';
import ScoreMeta from './ScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';

class OneToFiveScoreMeta extends ScoreMeta {

  constructor() {
    super(scoreTypes.limitedToExcellent);
  }

  shouldRenderEmojiForAssessmentItem = (assessmentItem) => {
    return true;
  };

  getColorRange = () => {
    return colorRange;
  }

}

export default new OneToFiveScoreMeta();

