import persistenceFactory from './PersistenceFactory';

class NetworkDAO {

  constructor() {
    this.networkPersistence = persistenceFactory.networkPersistence;
  }

  saveNetwork = (user, network) => {
    return this.networkPersistence.saveNetwork(user, network);
  };

  getNetwork = (user) => {
    return this.networkPersistence.getNetwork(user);
  };

}

export default new NetworkDAO();
