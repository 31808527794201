import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import actions from '../../shared/actions/Actions';
import appStyles from './AppStyles';
import appViewState from './AppViewState';
import '@atlaskit/css-reset';
import backgroundImageUtil from '../components/widget/media/BackgroundImageUtil';
import BackgroundView from '../pages/BackgroundView';
import CookieBanner from './CookieBanner';
import ContainerDimensions from 'react-container-dimensions';
import currentDialog from '../components/widget/CurrentDialog';
import currentRubric from '../../shared/model/rubric/CurrentRubric';
import dashboardState from '../components/rubric/dashboard/DashboardState';
import EmptyState from '@atlaskit/empty-state';
import emptyStateImages from '../components/widget/EmptyStateImages';
import featureFlags from '../../shared/model/feature/FeatureFlags';
import Flag, { FlagGroup } from '@atlaskit/flag';
import GlobalDirtyState from '../components/global/GlobalDirtyState';
import GlobalSpinner from '../components/global/GlobalSpinner';
import GlobalStatus from '../components/global/GlobalStatus';
import Modal from '@atlaskit/modal-dialog';
import NewDecisionWizard from '../components/rubric/decision/NewDecisionWizard';
import onlineStatus from '../../shared/model/browser/OnlineStatus';
import ReactTooltip from 'react-tooltip';
import TopNavigation from '../components/nav/TopNavigation';
import serviceWorkerClient from './ServiceWorkerClient';
import siteMapChangeNotifier from '../../shared/model/seo/SiteMapChangeNotifier';
import constants from '../../commonbase/commonConstants';

export default class App extends PureComponent {

  mounted = false;

  static contextTypes = {
    navOpenState: PropTypes.object,
    router: PropTypes.object,
  };

  static propTypes = {
    navOpenState: PropTypes.object,
    onNavResize: PropTypes.func,
  };

  static childContextTypes = {
    showModal: PropTypes.func,
    addFlag: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      online: onlineStatus.isOnline(),
      flags: [],
      currentDialogOptions: null,
      isInEditRubricMode: false,
      user: undefined,
      username: ''
    };
    if (featureFlags.enableBackgroundImages()) {
      backgroundImageUtil.enable();
    }
    setTimeout(() => {
      if (featureFlags.updateDetectionEnabledForAllUsers()) {
        // console.log(new Date(), 'App: initialising...');
        serviceWorkerClient.initialise();
      }
    }, 60000);
  }

  UNSAFE_componentWillMount() {
    // console.log('App.componentWillMount...');
    dashboardState.enable();
    siteMapChangeNotifier.enable();
    actions.registerListener(this._onAction);
    currentRubric.registerListener(this.onCurrentRubricChange);
    currentDialog.registerListener(this.onCurrentDialogChange);
    onlineStatus.registerListener(this.onOnlineStatusChange);
  }

  componentDidMount() {
    // console.log('App.componentDidMount...');
    this.mounted = true;
  }

  componentWillUnmount() {
    // console.log('App.componentWillUnmount...');
    this.mounted = false;
    dashboardState.disable();
    siteMapChangeNotifier.disable();
    actions.unregisterListener(this._onAction);
    currentRubric.unregisterListener(this.onCurrentRubricChange);
    currentDialog.unregisterListener(this.onCurrentDialogChange);
    onlineStatus.unregisterListener(this.onOnlineStatusChange);
  }

  allowCookieBanner = () => {
    return true;
  };

  getAppTitle = () => {
    return constants.appTitle;
  }

  onCurrentRubricChange = (state) => {
    this.setState({
      isInEditRubricMode: state.inEditMode
    });
  };

  onCurrentDialogChange = (component) => {
    if (this.mounted) {
      this.setState({
        currentDialogOptions: component
      });
    } else {
      const state = this.state;
      state.currentDialogOptions = component;
    }
  };

  onCurrentUserChange = (user) => {
    if (this.mounted) {
      this.setState({
        user: user
      });
    } else {
      const state = this.state;
      state.user = user;
      this.setState({
        user: user
      });
    }
  };

  onOnlineStatusChange = (online) => {
    if (this.mounted) {
      this.setState({
        online: online
      });
    } else {
      const state = this.state;
      state.online = online;
    }
  };

  getChildContext() {
    return {
      showModal: this.showModal,
      addFlag: this.addFlag,
    };
  }

  showModal = () => {
    this.setState({ currentDialogOptions: true });
  };

  hideModal = () => {
    this.setState({ currentDialogOptions: null });
  };

  addFlag = (flag) => {
    const allFlags = [flag].concat(this.state.flags);
    this.setState({
      flags: allFlags
    });
  };

  _onAction = (actionId, context) => {
    if (this.mounted) {
      if (actionId === actions.addFlagActionId) {
        this._addFlag(context);
      } else if (actionId === actions.removeFlagActionId) {
        this.removeFlagById(context);
      }
    }
  };

  _addFlag = (flag) => {
    const newFlags = [flag].concat(this.state.flags);
    this.applyFlagState(newFlags);
    if (flag.flagMessageTimeoutSeconds && flag.flagMessageTimeoutSeconds > 0) {
      const currentTime = new Date().getTime();
      const timeoutTime = currentTime + flag.flagMessageTimeoutSeconds * 1000;
      flag.timeoutTime = timeoutTime;
      this._timeoutFlag(flag, flag.flagMessageTimeoutSeconds * 1000);
    }
  };

  _timeoutFlag = (flag, delayMilliseconds) => {
    setTimeout(() => {
      const now = new Date().getTime();
      if (now >= flag.timeoutTime) {
        this.removeFlagById(flag.id);
      } else {
        const newDelayMilliseconds = flag.timeoutTime - now;
        this._timeoutFlag(flag, newDelayMilliseconds);
      }
    }, delayMilliseconds);
  };

  removeFlagById = (flagId) => {
    const currentFlags = this.state.flags;
    const newFlags = currentFlags.filter(flag => flag.id !== flagId);
    this.applyFlagState(newFlags);
  };

  onFlagDismissed = (dismissedFlagId) => {
    const newFlags = this.state.flags.filter(flag => flag.id !== dismissedFlagId);
    this.applyFlagState(newFlags);
  };

  applyFlagState = (flags) => {
    if (this.mounted) {
      this.setState({
        flags: flags
      });
    } else {
      const state = this.state;
      state.flags = flags;
    }
  };

  renderTopNavAppContent = () => {
    const renderedNavigation = <TopNavigation />;
    return (
      <div>
        {renderedNavigation}
        <div
          id="main-content-panel"
          className="below-top-nav main-content-panel"
          onClick={actions.onMainBodyClick}
        >
          <ContainerDimensions>
            {
              ({ width, height }) => {
                const scrollbarWidth = 25;
                const appContainerWidth = width;
                const appContainerLeftX = window.innerWidth - appContainerWidth - 12;
                appViewState.setAppContainerLeftX(appContainerLeftX);
                appViewState.setAppContainerWidth(appContainerWidth - scrollbarWidth - 4);
                return (
                  <React.Fragment>
                    {this.props.children}
                  </React.Fragment>
                );
              }
            }
          </ContainerDimensions>
        </div>
      </div>
    );
  };

  renderAppContent = () => {
    // return featureFlags.showHorizontalNav() ? this.renderTopNavAppContent() : this.renderLeftNavAppContent();
    return this.renderTopNavAppContent()
  };

  renderCurrentDialog = () => {
    const actions = this.state.currentDialogOptions.actions ?
      this.state.currentDialogOptions.actions : [];
    return (
      <Modal
        heading={this.state.currentDialogOptions.title}
        actions={actions}
        onClose={this.hideModal}
      >
        {this.state.currentDialogOptions.component}
      </Modal>
    );
  };

  renderOnline() {
    return (
      <React.Fragment>
        <style dangerouslySetInnerHTML={{__html: appStyles.getFullAppStyles()}} />
        {this.renderAppContent()}
        <GlobalStatus/>
        <GlobalSpinner/>
        <GlobalDirtyState/>
        <div className="online-view">
          <FlagGroup onDismissed={this.onFlagDismissed}>
            {
              this.state.flags.map((flag) => {
                return (
                  <React.Fragment key={flag.id}>
                    <Flag
                      {...flag}
                    />
                  </React.Fragment>
                );
              })
            }
          </FlagGroup>
          {this.state.currentDialogOptions && this.renderCurrentDialog()}
          {this.state.currentDialogOptions ? null : <NewDecisionWizard/>}
        </div>
      </React.Fragment>
    );
  };

  renderOfflinePrimaryAction = () => {
    return null;
  };

  renderOfflineTertiaryAction = () => {
    return null;
  };

  renderOffline() {
    const description = `It looks like you are offline!`;
    const size = 'wide';
    const dimensions = {width: 900, height: 200};
    return (
      <EmptyState
        header="Offline!"
        description={description}
        imageUrl={emptyStateImages.getAnyEmptyStateImage()}
        size={size}
        maxImageWidth={dimensions.width}
        maxImageHeight={dimensions.height}
        primaryAction={this.renderOfflinePrimaryAction()}
        tertiaryAction={this.renderOfflineTertiaryAction()}
      />
    );
  };

  renderApp = () => {
    if (this.state.online) {
      return this.renderOnline();
    } else {
      return this.renderOffline();
    }
  };

  render() {
    return (
      <React.Fragment>
        {featureFlags.enableBackgroundImages() ? <BackgroundView/> : null}
        {this.allowCookieBanner() ? <CookieBanner appTitle={this.getAppTitle()}/> : null}
        {this.renderApp()}
        <ReactTooltip className="tooltipLayer"/>
      </React.Fragment>
    );
  }

}
