import React, { PureComponent } from "react";
import globalSpinnerController from '../../../shared/model/GlobalSpinnerController';
import navState from '../../components/nav/NavState';
import Spinner from '@atlaskit/spinner';
import navConstants from '../nav/NavConstants';

export default class GlobalSpinner extends PureComponent {

  mounted = false;
  state = {
    spinning: false
  };

  UNSAFE_componentWillMount() {
    globalSpinnerController.registerListener(this.onSpinChange);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    globalSpinnerController.unregisterListener(this.onSpinChange);
    this.mounted = false;
  }

  onSpinChange = (spinning) => {
    if (this.mounted) {
      this.setState({
        spinning: spinning
      });
    } else {
      const state = this.state;
      state.spinning = spinning;
    }
  };

  renderSpinner = () => {
    const leftOffset = navState.isSidebarExpanded() ?
      navConstants.globalSidebarWidth + navConstants.expandedSecondarySidebarWidth :
      navConstants.globalSidebarWidth + navConstants.collapsedSecondarySidebarWidth;
    const spinnerWidth = 100;
    const centreOffset = (leftOffset - spinnerWidth) / 2;
    return (
      <div
        style={{
            zIndex: 1000,
            position: 'absolute',
            left: `calc(50% + ${centreOffset}px)`,
            top: '30%'
          }}
        >
        <div>
          <Spinner size="xlarge"/>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.spinning) {
      return this.renderSpinner();
    } else {
      return null;
    }
  }

}

