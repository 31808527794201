import React from 'react';
import constants from '../../shared/model/Constants';
import { UpdateReadyIcon } from '../components/icon/NamedIcons';
import adg from '../../commonbase/adg';
import actions from '../../shared/actions/Actions';
import util from '../../shared/util/Util';

class ServiceWorkerClient {

  initialised = false;
  newWorker = undefined;

  waitForUpdate = () => {
    return new Promise(function(resolve, reject) {
      if ('serviceWorker' in navigator && ['localhost', '127'].indexOf(window.location.hostname) === -1) {
        // register service worker file
        console.log(new Date(), 'ServiceWorkerClient: registering...');
        navigator.serviceWorker.register('/js/updateNotifierServiceWorker.js')
          .then(reg => {
            reg.onupdatefound = () => {
              console.log(new Date(), 'ServiceWorkerClient: onupdatefound...');
              this.newWorker = reg.installing;
              const installingWorker = reg.installing;
              installingWorker.onstatechange = () => {
                console.log(new Date(), 'ServiceWorkerClient: onstatechange...', installingWorker.state);
                switch (installingWorker.state) {
                  case 'installed':
                    if (navigator.serviceWorker.controller) {
                      // new update available
                      resolve(true);
                    } else {
                      // no update available
                      resolve(false);
                    }
                    break;
                  default:
                      break;
                }
              };
            };
          })
          .catch(err => console.error('[SW ERROR]', err));
      } else {
        resolve(false);
      }
    });
  };

  initialise = () => {
    if (!this.initialised) {
      this._initialise();
      this.initialised = true;
    }
  }

  onUserInstallationConfirmation = (newWorker) => {
    this._clientUpdateRequested = true;
    this._updateClient(newWorker);
  };

  _initialise = () => {
    if ('serviceWorker' in navigator && ['localhost', '127'].indexOf(window.location.hostname) === -1) {
      navigator.serviceWorker.register('/js/updateNotifierServiceWorker.js').then(reg => {
        reg.addEventListener('updatefound', (event) => {
          console.log(new Date(), 'ServiceWorkerClient: update found:', event);
          // A wild service worker has appeared in reg.installing!
          this.newWorker = reg.installing;
          // this.newWorker.addEventListener('statechange', () => {
          this.newWorker.onstatechange = () => {
            console.log(new Date(), 'ServiceWorkerClient: state change detected:', this.newWorker.state);
            // Has network.state changed?
            switch (this.newWorker.state) {
              case 'activating':
                console.log(new Date(), 'ServiceWorkerClient: Handling activating state change...');
                break;
              case 'activated':
                console.log(new Date(), 'ServiceWorkerClient: Handling activated state change...');
                if (this._clientUpdateRequested) {
                  window.location.reload();
                }
                break;
              case 'installed':
                console.log(new Date(), 'ServiceWorkerClient: Handling installed state change...');
                console.log(new Date(), 'ServiceWorkerClient: horay - installed! ', this.newWorker);
                // const doUpdate = this.newWorker.controller;
                const doUpdate = true;
                if (doUpdate) {
                  this._notifyUserOfNewVersion(this.newWorker);
                }
                // No update available

                // if (this._clientUpdateRequested) {
                //   alert('Reloading...');
                //   window.location.reload();
                // } else {
                //   this._notifyUserOfNewVersion(this.newWorker);
                // }

                break;
              default:
                break;
            }
          };
          this.newWorker.onerror = (error) => {
            console.log(new Date(), 'ServiceWorkerClient: Detected error:', error);
          }
        });
      this.checkUpdateTimer = setInterval(() => {
        if (this.lastUpdateNotificationTime) {
          if (this.checkUpdateTimer) {
            clearInterval(this.checkUpdateTimer);
          }
        } else {
          this._periodicallyCeckForUpdates(reg);
        }
      }, 5000);
    });
    }
  };

  _periodicallyCeckForUpdates = (reg) => {
    //console.log(new Date(), 'ServiceWorkerClient: checking for update...');
    reg.update();
  };

  _notifyUserOfNewVersion = (newWorker) => {
    if (this.lastUpdateNotificationTime) {
      console.log(new Date(), 'ServiceWorkerClient: NOT notifying user update since they have been previously notified...');
    } else {
      console.log(new Date(), 'ServiceWorkerClient: notifying user update...');
      // this.lastUpdateNotificationTime = new Date().getTime();
      // const flagActions = [{
      //   content: 'Reload',
      //   onClick: () => this.onUserInstallationConfirmation(newWorker)
      // }];
      // const flag = {
      //   id: util.uuid(),
      //   appearance: "success",
      //   icon: <UpdateReadyIcon label="Warning icon" secondaryColor={adg.adgGreen} />,
      //   title: 'New version detected',
      //   description: `Sorry to disturb you, but we thought you'd like to know there's a new version of ${constants.appTitle} available.`,
      //   flagMessageTimeoutSeconds: undefined,
      //   actions: flagActions
      // };
      // actions.addFlag(flag);
    }
  };

  _updateClient = (newWorker) => {
    console.log(new Date(), 'ServiceWorkerClient: posting skipWaiting...');
    this.newWorker.postMessage({
      action: 'skipWaiting' 
    });
    // this._waitAndReloadClient(newWorker);
  };

  // _waitAndReloadClient = (newWorker) => {
  //   let refreshing = false;
  //   console.log(new Date(), 'ServiceWorkerClient: waiting for controllerchange event...');
  //   newWorker.addEventListener('controllerchange', function (event) {
  //     console.log(new Date(), 'ServiceWorkerClient: NEW worker: received controllerchange event:', event);
  //     if (refreshing) {
  //       return;
  //     }
  //     alert('Reloading...');
  //     window.location.reload();
  //     refreshing = true;
  //   });
  // }

}

export default new ServiceWorkerClient();
