
export default class PermissionCheckResult {

  allowed: boolean;
  notAllowedMessage: string;

  constructor(allowed: boolean, notAllowedMessage: string) {
    this.allowed = allowed;
    this.notAllowedMessage = notAllowedMessage;
  }

  isAllowed = (): boolean => {
    return this.allowed;
  };

  getNotAllowedMessage = (): string => {
    return this.notAllowedMessage;
  };

}

export const falsePermissionCheckResult = new PermissionCheckResult(false, '');

