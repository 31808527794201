import React, { FormEvent, PureComponent } from 'react';
import AddColumnIcon from '../icon/AddColumnIcon';
import AddRowIcon from '../icon/AddRowIcon';
import adg from '../../../commonbase/adg';
import ArrowIcon, { directionUpType, directionDownType } from '../icon/ArrowIcon';
import BlankIcon from '../icon/BlankIcon';
import BackgroundImageSelector from '../widget/media/BackgroundImageSelector';
import backgroundImageUtil from '../widget/media/BackgroundImageUtil';
import browserType from '../../../shared/model/browser/BrowserType';
import { Checkbox } from '@atlaskit/checkbox';
import constants from '../../../shared/model/Constants';
import csvUtil from '../../../commonbase/csvUtil';
import CustomLevelsEditor from './score/CustomLevelsEditor';
import DiscoverabilitySelector from './DiscoverabilitySelector';
import editabilityDefinitions from '../../../shared/model/rubric/EditabilityDefinitions';
import EditableTextArea from '../widget/EditableTextArea';
import Expand from '../widget/Expand';
import GuidanceEditor from './guidance/GuidanceEditor';
import I18N from '../../../shared/model/i18n/I18N';
import IconButton from "../widget/IconButton";
import Label from '../widget/Label';
import LiftedPanel from '../widget/LiftedPanel';
import permissionUtil from '../../../shared/model/auth/PermissionUtil';
import RemoveColumnIcon from '../icon/RemoveColumnIcon';
import RemoveRowIcon from '../icon/RemoveRowIcon';
import ResizingTextArea from '../widget/ResizingTextArea';
import RowWeight from './RowWeight';
import rubricRenderingUtil from './RubricRenderingUtil';
import EditabilitySelector from './EditabilitySelector';
import rubricDAO from '../../../backend/data/RubricDAO';
import rubricUtil from '../../../shared/model/rubric/RubricUtil';
import SafeInlineButton from '../widget/SafeInlineButton';
import ScoreMarkersView from './score/ScoreMarkersView';
import scoreMetaDefinitions from '../../../shared/model/rubric/score/ScoreMetaDefinitions';
import ScoreMetaSelector from './score/ScoreMetaSelector';
import {statusDefinitions, StatusProvider} from '../../../shared/model/status/Status';
import Tooltip from '@atlaskit/tooltip';
import widgetUtil from '../widget/WidgetUtil';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  AddStatementIcon,
  ReadyIcon,
  CloseCellIcon,
  DeleteStatementIcon,
  WarningIcon }
  from '../icon/NamedIcons';
import Definition, { Discoverability, Editability } from '../../../shared/model/rubric/definition/Definition';
import FormlessTextField from '../widget/FormlessTextField';
import ToolbarJustify from '../widget/toolbar/ToolbarJustify';
import ToolbarItem from '../widget/toolbar/ToolbarItem';
import ToolbarLeft from '../widget/toolbar/ToolbarLeft';
import discoverabilityDefinitions from '../../../shared/model/rubric/DiscoverabilityDefinitions';
import FeatureFlags from '../../../shared/model/feature/FeatureFlags';

interface Props {
  definition: Definition
  onDescriptionChange?: Function
  onAspectHeaderNameChange?: Function
  onNameChangeComplete?: () => void
}

interface State {
  settingsVisible?: boolean
  markersVisible?: boolean
  enteredCellUuid?: string
  enteredColumnUuid?: string
  focusedRowUuid?: string
  aspectHeaderCellFocussed?: boolean
  focusedCellUuid?: string
  focusedColumnUuid?: string
  // recentlyAddedStatementUuid?: string
  definition: Definition
}

export default class RubricEditor extends PureComponent<Props, State> {

  mounted: boolean = false;
  changesMadeDuringMount: boolean = false;
  statusProvider: any;
  toolbarCellWidth: number;
  toolbarItemWidth: number;
  toolbarItemHeight: number;
  state: State;
  recentlyAddedStatementUuid: undefined | string = undefined;
  // recentlyAddedStatementUuid: string = '';
  tableElement: any = undefined;

  constructor(props) {
    super(props);
    this.statusProvider = new StatusProvider('RubricEditor');
    this.toolbarCellWidth = 20;
    this.toolbarItemWidth = 16;
    this.toolbarItemHeight = 16;
    this.state = this.buildStateFromProps(props);
    this.state.settingsVisible = false;
    this.state.markersVisible = false;
    // backgroundImageUtil.setDefinitionBackgroundImage(this.state.definition);
    // this.ensureBackgroundImageIsCorrect()
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
    backgroundImageUtil.setDefinitionBackgroundImage(props.definition);
    this.updateStatus(props.definition);
  }

  buildStateFromProps = (props: Props): State => {
    return {
      definition: props.definition
    };
  };

  UNSAFE_componentWillMount() {
    // this.setBackgroundImageBasedOnUserPreferences();
  }

  componentDidMount() {
    this.mounted = true;
    this.changesMadeDuringMount = false;
    this.statusProvider.setActive(true);
    document.addEventListener('keyup', this.onKeyUp, false);
    document.addEventListener('mousedown', this.onMouseDown, false);
    backgroundImageUtil.setDefinitionBackgroundImage(this.state.definition);
    this.ensureBackgroundImageIsCorrect();
  };

  componentWillUnmount() {
    this.mounted = false;
    // debugger;
    // if (this.changesMadeDuringMount) {
    //   // siteMapChangeNotifier._onDefinitionChanged(this.state.definition);
    //   actions.onDefinitionChanged(this.state.definition);
    // }
    this.statusProvider.setActive(false);
    backgroundImageUtil.setBackgroundImageBasedOnUserPreferences();
    document.removeEventListener('keyup', this.onKeyUp);
    document.removeEventListener('mousedown', this.onMouseDown);
  };

  ensureBackgroundImageIsCorrect = () => {
    setTimeout(() => {
      if (this.mounted) {
        backgroundImageUtil.setDefinitionBackgroundImage(this.state.definition);
      }
    }, 0);
  }

  setBackgroundImageBasedOnUserPreferences = () => {
    // backgroundImageUtil.setBackgroundImageBasedOnUserPreferences(() => { return this.mounted });
  }

  // setBackgroundImageBasedOnDefinition = (definition) => {
  //   backgroundImageUtil.setDefinitionBackgroundImage(definition);
  // }

  saveDefinition = (definition) => {
    backgroundImageUtil.setDefinitionBackgroundImage(definition);
    return this._saveDefinition(definition, false);
  };

  saveDefinitionAndForceUpdate = (definition) => {
    return this._saveDefinition(definition, true);
  };

  _saveDefinition = (definition, forceUpdate) => {
    return rubricDAO.saveDefinition(definition)
      .then((savedDefinition) => {
        this.setState({
          definition: savedDefinition
        });
        if (forceUpdate) {
          this.forceUpdate();
        }
        setTimeout(() => {
          this.onAfterDefinitionChanged(savedDefinition);
        }, 1);
        return savedDefinition;
    });
  };

  onAfterDefinitionChanged = (definition) => {
    if (this.mounted) {
      this.changesMadeDuringMount = true;
    }
    this.updateStatus(definition);
  };

  updateStatus = (definition) => {
    if (definition) {
      const context = {
        statementCount: 0,
        statementCountWithGuidance: 0
      };
      const callback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, context) => {
        context.statementCount++;
        if (statement.guidance) {
          context.statementCountWithGuidance++;
        }
      };
      rubricUtil.iterateStatements(definition, callback, context);
      if (context.statementCount) {
        if (context.statementCountWithGuidance < context.statementCount) {
          this.statusProvider.setCurrentStatus(
            statusDefinitions.statuses.workToBeDone,
            'Guidance is provided for some, but not all statements.');
        }
      } else {
        this.statusProvider.setCurrentStatus(
          statusDefinitions.statuses.workToBeDone,
          'This rubric has no statements yet');
      }
    } else {
      this.statusProvider.setCurrentStatus(
        statusDefinitions.statuses.nil,
        undefined);
    }
  };

  onMouseDown = (event) => {
    if (this.state.enteredCellUuid || this.state.enteredColumnUuid) {
      // ignore
    } else {
      this.deselectAllCellsAsync();
    }
  };

  onKeyUp = (event) => {
    const keyCode = event.keyCode;
    if (keyCode === 27) {
      this.deselectAllCellsAsync();
    }
  };

  onDescriptionChange = (event) => {
    const newDescription = event.currentTarget.value;
    if (newDescription !== undefined) {
      const newDefinition = this.state.definition;
      newDefinition.description = newDescription;
      this.saveDefinitionAndForceUpdate(newDefinition).then((savedDefinition) => {
        if (this.props.onDescriptionChange) {
          this.props.onDescriptionChange();
        }
      });
    }
  };

  onAspectHeaderNameChange = (event: FormEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    if (newName) {
      const newDefinition = this.state.definition;
      newDefinition.aspectHeaderName = newName;
      this.setState({
        definition: newDefinition
      });
      this.saveDefinitionAndForceUpdate(newDefinition)
        .then((savedDefinition) => {
          if (this.props.onAspectHeaderNameChange) {
            this.props.onAspectHeaderNameChange();
          }
        });
    }
  };

  onColumnHeaderEnter = (column) => {
    this.setState({
      enteredColumnUuid: column.uuid
    });
  };

  onColumnHeaderLeave = () => {
    this.setState({
      enteredColumnUuid: undefined
    });
  };

  onAspectHeaderClick = () => {
    this.setState({
      aspectHeaderCellFocussed: true
    });
    this.deselectColumnHeader();
    this.deselectCell();
    this.deselectRowHeader();
  };

  onColumnHeaderClick = (event, column, isFocused) => {
    const alreadyFocused = this.state.focusedColumnUuid === column.uuid;
    if (alreadyFocused) {
      // ignore
    } else {
      this.setState({
        focusedColumnUuid: column.uuid
      });
      this.deselectAspectHeader();
      this.deselectCell();
      this.deselectRowHeader();
    }
  };

  onRowNameClick = (event, row) => {
    const alreadyFocused = this.state.focusedRowUuid === row.uuid;
    if (alreadyFocused) {
      // ignore
    } else {
      this.setState({
        focusedRowUuid: row.uuid
      });
      this.deselectAspectHeader();
      this.deselectColumnHeader();
      this.deselectCell();
    }
  };

  onCloseColumnHeader = () => {
    this.deselectColumnHeader();
  };

  onCloseRowHeader = () => {
    this.deselectRowHeader();
  };

  onCellEnter = (cellUuid) => {
    // console.log('Changing active cell from', this.state.enteredCellUuid, 'to', cell.uuid);
    this.setState({
      enteredCellUuid: cellUuid
    });
  };

  onCellLeave = () => {
    this.setState({
      enteredCellUuid: undefined
    });
  };

  onCloseCell = () => {
    this.deselectCell();
  };

  onAddRow = (group, afterRowUuid) => {
    const addRowOptions = {
      afterRowUuid: afterRowUuid
    };
    rubricUtil.addRow(this.state.definition, group, addRowOptions);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onRemoveRow = (group, rowUuid) => {
    rubricUtil.removeRow(this.state.definition, group, rowUuid);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onAddColumn = (column, after) => {
    const addColumnOptions = {
      relativeToColumn: column,
      after: after
    };
    rubricUtil.addColumn(this.state.definition, addColumnOptions);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onRemoveColumn = (column) => {
    rubricUtil.removeColumn(this.state.definition, column);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onCellClick = (event, cell, isFocused) => {
    const alreadyFocused = this.state.focusedCellUuid === cell.uuid;
    if (alreadyFocused) {
      // ignore
    } else {
      this.setState({
        focusedCellUuid: cell.uuid
      });
      this.deselectAspectHeader();
      this.deselectColumnHeader();
      this.deselectRowHeader();
    }
  };

  onDeleteStatement = (cell, statement) => {
    rubricUtil.deleteCellStatementByUuid(this.state.definition, cell, statement.uuid);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  getCellAt = (groupIndex, rowIndex, cellIndex) => {
    // const renderedRowGroups = definition.groups.map((group, groupIndex) => {
    //   const renderedRows = group.rows.map((row, rowIndex) => {
    //     const renderedCells = row.cells.map((cell, cellIndex) => {
      // cell.statements.map

    const definition = this.state.definition;
    const group = definition.groups[groupIndex];
    const row = group.rows[rowIndex];
    const cell = row.cells[cellIndex];
    return cell;
  };

  getCellAbove = (groupIndex, rowIndex, cellIndex) => {
    let aboveCellGroupIndex = groupIndex;
    let aboveCellRowIndex = rowIndex - 1;
    const aboveCellCellIndex = cellIndex;
    if (aboveCellRowIndex < 0) {
      aboveCellGroupIndex--;
      const aboveCellGroup = this.state.definition.groups[aboveCellGroupIndex];
      aboveCellRowIndex = aboveCellGroup.rows.length - 1;
    }
    return this.getCellAt(aboveCellGroupIndex, aboveCellRowIndex, aboveCellCellIndex);
  };

  getCellBelow = (groupIndex, rowIndex, cellIndex) => {
    let belowCellGroupIndex = groupIndex;
    let belowCellRowIndex = rowIndex + 1;
    const belowCellCellIndex = cellIndex;
    const group = this.state.definition.groups[belowCellGroupIndex];
    const groupRowCount = group.rows.length;
    if (belowCellRowIndex >= groupRowCount) {
      belowCellGroupIndex++;
      belowCellRowIndex = 0;
    }
    return this.getCellAt(belowCellGroupIndex, belowCellRowIndex, belowCellCellIndex);
  };

  onMoveStatementUp = (cell, cellIndex, rowIndex, groupIndex, statement, statementIndex, statements) => {
    // dddddd
    if (statementIndex < 1) {
      const aboveCell = this.getCellAbove(groupIndex, rowIndex, cellIndex);
      aboveCell.statements.push(statement);
      // cell.statements = cell.statements.length === 1 ? [] : cell.statements.splice(0, 1);
      cell.statements.shift();
    } else {
      const prevStatement = statements[statementIndex - 1];
      statements[statementIndex - 1] = statement;
      statements[statementIndex] = prevStatement;  
    }
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onMoveStatementDown = (cell, cellIndex, rowIndex, groupIndex, statement, statementIndex, statements) => {
    const cellStatementCount = cell.statements.length;
    if (statementIndex >= cellStatementCount - 1) {
      const belowCell = this.getCellBelow(groupIndex, rowIndex, cellIndex);
      belowCell.statements.unshift(statement);
      // cell.statements = cellStatementCount === 1 ? [] : cell.statements.splice(cellStatementCount - 1, 1);
      cell.statements.pop();
    } else {
      const nextStatement = statements[statementIndex + 1];
      statements[statementIndex + 1] = statement;
      statements[statementIndex] = nextStatement;  
    }
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onAddStatementToCell = (cell) => {
    const addedStatement = rubricUtil.addStatementToCell(this.state.definition, cell);
    this.saveDefinitionAndForceUpdate(this.state.definition).then(() => {
      if (addedStatement) {
        this.cacheRecentlyAddedStatementUuid(addedStatement.uuid);
      }
    });
  };

  cacheRecentlyAddedStatementUuid = (statementUuid) => {
    this.recentlyAddedStatementUuid = statementUuid;
    if (this.recentlyAddedStatementUuid === statementUuid) {
      setTimeout(() => {
        if (this.recentlyAddedStatementUuid === statementUuid) {
          this.recentlyAddedStatementUuid = undefined;
        }
      }, 2000);
    }
  };

  onColumnHeaderChange = (event: FormEvent<HTMLInputElement>, column) => {
    column.name = event.currentTarget.value;
    this.saveDefinition(this.state.definition);
  };

  onRowNameChange = (event, row) => {
    row.name = event.currentTarget.value;
    this.saveDefinition(this.state.definition);
  };

  onRowWeightChange = (event: FormEvent<HTMLInputElement>, row) => {
    const rowWeight = parseFloat(event.currentTarget.value);
    if (!isNaN(rowWeight)) {
      rubricUtil.setRowWeight(row, rowWeight);
      this.saveDefinition(this.state.definition);
    }
  };

  onStatementChange = (event, cell, statement) => {
    rubricUtil.setStatementText(this.state.definition, statement.uuid, event.currentTarget.value);
    this.saveDefinition(this.state.definition);
  };

  onGuidanceChange = (statement, guidance) => {
    rubricUtil.setStatementGuidance(this.state.definition, statement, guidance);
    this.saveDefinition(this.state.definition);
  };

  onGuidanceMetadataChange = (statement, guidanceMetadata) => {
    rubricUtil.setStatementGuidanceMetadata(this.state.definition, statement, guidanceMetadata);
    this.saveDefinition(this.state.definition);
  };

  onEditabilityChange = (value: string) => {
    const editability: undefined | Editability = editabilityDefinitions.getEditability(value);
    if (editability) {
      rubricUtil.setEditability(this.state.definition, editability);
      this.saveDefinitionAndForceUpdate(this.state.definition);
    }
  };

  onDiscoverabilityChange = (value: string) => {
    const discoverability: undefined | Discoverability = discoverabilityDefinitions.getDiscoverability(value);
    if (discoverability) {
      rubricUtil.setDefinitionDiscoverability(this.state.definition, discoverability);
      this.saveDefinition(this.state.definition);
    }
  };

  onScoreMetaChange = (scoreMetaType: string) => {
    rubricUtil.setDefinitionScoreMetaType(this.state.definition, scoreMetaType);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onCustomLevelsScoreInfoChange = (customLevelsScoreInfo) => {
    rubricUtil.setCustomLevelsScoreInfo(this.state.definition, customLevelsScoreInfo);
    this.saveDefinition(this.state.definition);
  };

  onCsvLabelsChange = (event: FormEvent<HTMLInputElement>) => {
    const csvLabels: string = event.currentTarget.value;
    rubricUtil.setCsvLabels(this.state.definition, csvLabels);
    this.saveDefinition(this.state.definition);
  };

  onStatementLabelsChange = (statement, labels) => {
    rubricUtil.setStatementLabels(this.state.definition, statement.uuid, labels);
    this.forceUpdate();
    rubricDAO.debouncedSaveDefinition(this.state.definition).then((savedDefinition) => {
    });
  };

  onMoveRowUpOrDownByUuid = (group, rowUuid, directionType) => {
    let moved = false;
    const rowIndex = this.findRowIndex(group, rowUuid);
    if (rowIndex !== undefined) {
      if (directionType === directionUpType && rowIndex > 0) {
        const thisRow = group.rows[rowIndex];
        const aboveRow = group.rows[rowIndex - 1];
        group.rows[rowIndex - 1] = thisRow;
        group.rows[rowIndex] = aboveRow;
        moved = true;
      } else if (directionType === directionDownType && rowIndex < group.rows.length - 1) {
        const thisRow = group.rows[rowIndex];
        const belowRow = group.rows[rowIndex + 1];
        group.rows[rowIndex + 1] = thisRow;
        group.rows[rowIndex] = belowRow;
        moved = true;
      }
    }
    if (moved) {
      this.saveDefinitionAndForceUpdate(this.state.definition);
    }
  };

  findRowIndex = (group, rowUuid) => {
    for (let rowIndex = 0; rowIndex < group.rows.length; rowIndex++) {
      const row = group.rows[rowIndex];
      if (row.uuid === rowUuid) {
        return rowIndex;
      }
    }
    return undefined;
  };

  onShowAspectWeightsChange = (checkboxEvent) => {
    const showAspectWeights = checkboxEvent.currentTarget.checked;
    rubricUtil.setOptionEnableAspectWeights(this.state.definition, showAspectWeights);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onHideCompletedAspectsChange = (checkboxEvent) => {
    const hideCompletedAspects = checkboxEvent.currentTarget.checked;
    rubricUtil.setOptionHideCompletedAspects(this.state.definition, hideCompletedAspects);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onHideZeroScoresChange = (checkboxEvent) => {
    const hideZeroScores = checkboxEvent.currentTarget.checked;
    rubricUtil.setOptionHideZeroScores(this.state.definition, hideZeroScores);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onHideColumnTotalsChange = (checkboxEvent) => {
    const hideColumnTotals = checkboxEvent.currentTarget.checked;
    rubricUtil.setOptionHideColumnTotals(this.state.definition, hideColumnTotals);
    this.saveDefinitionAndForceUpdate(this.state.definition);
  };

  onSettingsVisibilityToggle = () => {
    this.setState({
      settingsVisible: !this.state.settingsVisible
    });
  };

  onMarkerVisibilityToggle = () => {
    this.setState({
      markersVisible: !this.state.markersVisible
    });
  };

  deselectAllCellsAsync = () => {
    setTimeout(this.deselectAllCells, 0);
  };

  deselectAllCells = () => {
    this.deselectAspectHeader();
    this.deselectColumnHeader();
    this.deselectRowHeader();
    this.deselectCell();
  };

  deselectAspectHeader = () => {
    this.setState({
      aspectHeaderCellFocussed: undefined
    });
  };

  deselectColumnHeader = () => {
    this.setState({
      focusedColumnUuid: undefined
    });
  };

  deselectRowHeader = () => {
    this.setState({
      focusedRowUuid: undefined
    });
  };

  deselectCell = () => {
    this.setState({
      focusedCellUuid: undefined
    });
  };

  onBackgroundImageChange = (backgroundImageId) => {
    rubricUtil.setDefinitionBackgroundImageId(this.state.definition, backgroundImageId);
    this.saveDefinition(this.state.definition);
  }

  onColorRangeChange = (colorRangeType) => {
    rubricUtil.setDefinitionColorRangeType(this.state.definition, colorRangeType);
    this.saveDefinition(this.state.definition);
  }

  renderDescription = () => {
    return (
      <div>
        <EditableTextArea
          label="Description"
          compact={false}
          autoFocus={false}
          shouldFitContainer={true}
          minHeight={40}
          maxHeight={1000}
          maxLength={constants.maxDefinitionDescriptionLength}
          minimumRows={3}
          enableResize={true}
          placeholder="Enter rubric description here..."
          value={this.state.definition.description}
          onChange={this.onDescriptionChange}
        />
      </div>
    );
  };

  renderStateSelector = () => {
    let color = '';
    let message = '';
    let icon: any = undefined;
    if (this.state.definition.editability === editabilityDefinitions.immutableEditabilityType) {
      color = adg.adgGreen;
      message = `Ready for assessing.`;
      icon = (<ReadyIcon label=""/>);
    } else {
      color = adg.adgRed;
      message = `When viewing this rubric, a warning will be displayed to indicate changes may occur.`;
      icon = (<WarningIcon label=""/>);
    }
    return (
      <ToolbarLeft
        style={{
          alignItems: 'flex-end',
          fontWeight: 'bold'
        }}
      >
        <ToolbarItem
          style={{
            marginLeft: '0px',
            textAlign: 'left',
            width: '200px'
          }}
        >
          <EditabilitySelector
            editabilityType={this.state.definition.editability}
            onChange={this.onEditabilityChange}
          />
        </ToolbarItem>
        <ToolbarItem
          style={{
            paddingBottom: '4px'
          }}
        >
          {icon}
        </ToolbarItem>
        <ToolbarItem
          style={{
            paddingBottom: '8px',
            color: color,
            textAlign: 'left'
          }}
        >
          {message}
        </ToolbarItem>
      </ToolbarLeft>
    );
  };

  renderDiscoverabilitySelector = () => {
    return (
      <DiscoverabilitySelector
        discoverabilityType={this.state.definition.discoverability}
        includeExplanation={true}
        onChange={this.onDiscoverabilityChange}
      />
    );
  };

  renderLabels = () => {
    const csvLabels = this.state.definition.labels ? csvUtil.arrayToCsv(this.state.definition.labels) : '';
    return (
      <FormlessTextField
        name="labels"
        label="Labels (comma separated tag list)"
        maxLength={constants.maxDefinitionLabelsCsvLength}
        value={csvLabels}
        onChange={this.onCsvLabelsChange}
      />
    );
  };

  renderBackgroundImageSelector = () => {
    if (FeatureFlags.enableBackgroundImages()) {
      const backgroundImageId = rubricUtil.getDefinitionBackgroundImageId(this.state.definition);
      return (
        <div className="newMinorSection">
          <BackgroundImageSelector
            selectedBackgroundImageId={backgroundImageId}
            onChange={this.onBackgroundImageChange}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderScoreMetaSelectionOption = () => {
    const scoreMetaType = rubricUtil.getScoreMetaType(this.state.definition);
    return (
      <div>
        <ScoreMetaSelector
          scoreMetaType={scoreMetaType}
          onChange={this.onScoreMetaChange}
        />
      </div>
    );
  };

  renderGuidanceContent = (scoreMeta) => {
    return (
      <ScoreMarkersView
        scoreMeta={scoreMeta}
      />
    );
  };

  renderScoreDetails = () => {
    const scoreMetaType = rubricUtil.getScoreMetaType(this.state.definition);
    if (scoreMetaType === scoreMetaDefinitions.customLevelsScoreMetaType) {
      return (
        <CustomLevelsEditor
          scoreMetaType={scoreMetaType}
          definition={this.state.definition}
          onChange={this.onCustomLevelsScoreInfoChange}
        />
      );
    } else {
      const scoreMeta = scoreMetaDefinitions.scoreMetaFromType(scoreMetaType);
      return (
        <Expand
          contentForeignKey={undefined}
          context={scoreMeta}
          text="Markers"
          tooltip="Click to show the markers"
          initiallyExpanded={this.state.markersVisible}
          contentRenderer={this.renderGuidanceContent}
          stopPropagation={true}
          onExpanded={this.onMarkerVisibilityToggle}
          onCollapsed={this.onMarkerVisibilityToggle}
        />
      );
    }
  };

  renderOptions = () => {
    const showAspectWeights = rubricUtil.getOptionEnableAspectWeights(this.state.definition);
    return (
      <div>
        <Label text="Options"/>

        <Tooltip content="Enable custom aspect weightings">
          <Checkbox
            label="Enable custom aspect weightings"
            value="enableCustomAspectWeightings"
            isChecked={showAspectWeights}
            crossOrigin={undefined}
            onChange={this.onShowAspectWeightsChange}
          />
        </Tooltip>

        <Tooltip content="Hide aspects with a score of 100">
          <Checkbox
            label="Hide completed aspects (can be overridden when viewing)"
            value="hideCompletedAspects"
            isChecked={this.state.definition.options && this.state.definition.options.hideCompletedAspects}
            crossOrigin={undefined}
            onChange={this.onHideCompletedAspectsChange}
          />
        </Tooltip>

        <Tooltip content="Hide scores with a value of 0">
          <Checkbox
            label="Hide scores with a value of 0 (can be overridden when viewing)"
            value="hideZeroScores"
            isChecked={this.state.definition.options && this.state.definition.options.hideZeroScores}
            crossOrigin={undefined}
            onChange={this.onHideZeroScoresChange}
          />
        </Tooltip>

        <Tooltip content="Hide column assessment scores">
          <Checkbox
            label="Hide column totals (can be overridden when viewing)"
            value="hideColumnTotals"
            crossOrigin={undefined}
            isChecked={this.state.definition.options && this.state.definition.options.hideColumnTotals}
            onChange={this.onHideColumnTotalsChange}
          />
        </Tooltip>
      </div>
    );
  };

  renderScoreSelectionAndOptions = () => {
    return (
      <ToolbarJustify style={{alignItems: 'baseline'}}>
        <ToolbarItem>
          {this.renderScoreMetaSelectionOption()}
          {this.renderScoreDetails()}
        </ToolbarItem>
        <ToolbarItem>
          {this.renderOptions()}
        </ToolbarItem>
      </ToolbarJustify>
    );
  };

  renderedStatementInputs = (cell, cellIndex, rowIndex, groupIndex, statement, statementIndex, statements) => {
    const statementCount = statements.length;
    const statementText = statement.text;
    const hasLabels = this.state.definition.labels && this.state.definition.labels.length;
    const columnCount = rubricUtil.countColumns(this.state.definition);
    const statementLabel = columnCount > 1 ? 'Statement' : 'Topic';
    const renderedDeleteStatementButton = statement.text ?
      (
        <SafeInlineButton
          normalStateLabel=""
          iconBefore={<DeleteStatementIcon label="Delete statement"/>}
          onConfirmation={() => this.onDeleteStatement(cell, statement)}
        />
      ) : (
        <IconButton
          normalIcon={<DeleteStatementIcon label="Delete statement"/>}
          hoverIcon={<DeleteStatementIcon label="Delete statement"/>}
          onClick={() => this.onDeleteStatement(cell, statement)}
        />
      );
    const renderedDeleteButton = permissionUtil.canRemoveItemsFromDefinition(this.state.definition) ?
      (
        <ToolbarItem style={{flexGrow: 0}}>
          <Tooltip content="Delete the statement above">
            {renderedDeleteStatementButton}
          </Tooltip>
        </ToolbarItem>
      ) : null;
    const allowMoveUp = groupIndex > 0 || rowIndex > 0 || statementIndex > 0;
    const renderedMoveUpButton = allowMoveUp ? (
      <ToolbarItem style={{flexGrow: 0}}>
        <Tooltip content="Move the statement up">
          <IconButton
            normalIcon={<ArrowUpIcon label="Up"/>}
            hoverIcon={<ArrowUpIcon label="Up"/>}
            onClick={() => this.onMoveStatementUp(cell, cellIndex, rowIndex, groupIndex, statement, statementIndex, statements)}
          />
        </Tooltip>
      </ToolbarItem>
    ) : null;
    const groups = this.state.definition.groups;
    const group = groups[groupIndex];
    const rows = group.rows
    const allowMoveDown = groupIndex < groups.length - 1 || rowIndex < rows.length - 1 || statementIndex < statementCount - 1;
    const renderedMoveDownButton = allowMoveDown ? (
      <ToolbarItem style={{flexGrow: 0}}>
        <Tooltip content="Move the statement down">
          <IconButton
            normalIcon={<ArrowDownIcon label="Down"/>}
            hoverIcon={<ArrowDownIcon label="Down"/>}
            onClick={() => this.onMoveStatementDown(cell, cellIndex, rowIndex, groupIndex, statement, statementIndex, statements)}
          />
        </Tooltip>
      </ToolbarItem>
    ) : null;
    const autoFocus = statement.uuid === this.recentlyAddedStatementUuid || statementIndex === 0;
    return (
      <div style={{marginTop: '12px', marginBottom: '10px'}}>
        <ResizingTextArea
          label={statementLabel}
          compact={false}
          autoFocus={autoFocus}
          shouldFitContainer={true}
          maxLength={constants.maxDefinitionStatementLength}
          minimumRows={2}
          enableResize={true}
          placeholder={statementLabel}
          value={statementText}
          onChange={(event) => this.onStatementChange(event, cell, statement)}
        />
        {hasLabels ? rubricRenderingUtil.renderStatementLabelsInput(
          this.state.definition, statement, this.onStatementLabelsChange) : null}
        <ToolbarLeft
          style={{alignItems: 'center'}}
        >
          {renderedDeleteButton}
          {renderedMoveUpButton}
          {renderedMoveDownButton}
          <ToolbarItem style={{flexGrow: 1}}>
            <GuidanceEditor
              initiallyExpanded={false}
              definition={this.state.definition}
              guidance={statement.guidance}
              guidanceMetadata={statement.guidanceMetadata}
              onGuidanceMetadataChange={(guidanceMetadata) => this.onGuidanceMetadataChange(statement, guidanceMetadata)}
              onGuidanceChange={(guidance) => this.onGuidanceChange(statement, guidance)}
            />
          </ToolbarItem>
        </ToolbarLeft>
      </div>
    );
  };

  renderStatementText = (cell, statement) => {
    const hasLabels = this.state.definition.labels && this.state.definition.labels.length;
    const statementText = statement.text;
    const statementHtml = widgetUtil.markdownToHtml(statementText);
    return (
      <span
        className="editorDerivedContent"
        style={{fontWeight: 400}}
      >
        <span dangerouslySetInnerHTML={{__html: statementHtml}}>
        </span>
        {hasLabels ? rubricRenderingUtil.renderStatementLabels(this.state.definition, statement) : null}
      </span>
    );
  };

  renderColumnTitleEditor = (column) => {
    return (
      <div style={{marginTop: '12px', marginBottom: '10px'}}>
        <FormlessTextField
          name="column-title"
          placeholder="Column title"
          label={undefined}
          autoFocus={true}
          maxLength={constants.maxDefinitionColumnTitleLength}
          value={column.name}
          onChange={(event) => this.onColumnHeaderChange(event, column)}
        />
      </div>
    );
  };

  renderColumnTitle = (column, inputMode) => {
    return (
      <span className="editorDerivedContent">
        {column.name}
      </span>
    );
  };

  renderCloseIcon = (onClick: any, floatRight?: boolean) => {
    const style: any = {
      color: adg.adgGreen
    };
    if (!floatRight) {
      style.float = 'right';
      style.width = '24px';
    }
    return (
      <Tooltip content="Close">
        <div
          style={style}
          className="clickable"
          onClick={onClick}
        >
          <CloseCellIcon label="Close" />
        </div>
      </Tooltip>
    );
  };

  renderCloseCellIcon = () => {
    return this.renderCloseIcon(this.onCloseCell);
  };

  renderCloseColumnIcon = () => {
    return this.renderCloseIcon(this.onCloseColumnHeader);
  };

  renderCloseRowIcon = () => {
    return this.renderCloseIcon(this.onCloseRowHeader);
  };

  renderCloseRowToolbarItem = () => {
    return (
      <ToolbarItem style={{maxWidth: '24px'}}>
        {this.renderCloseRowIcon()}
      </ToolbarItem>
    );
  };

  renderStatements = (cell, cellIndex, rowIndex, groupIndex, inputMode) => {
    return cell.statements.map((statement, index) => {
      const containerStyle = {
        display: 'block'
      };
      return (
        <div
          style={containerStyle}
          key={statement.uuid}
        >
          {inputMode ?
            this.renderedStatementInputs(cell, cellIndex, rowIndex, groupIndex, statement, index, cell.statements) :
            this.renderStatementText(cell, statement)}
        </div>
      );
    });
  };

  renderCellToolbar = (cell) => {
    const renderedAddStatementButton = permissionUtil.canAddItemsToDefinition(this.state.definition) ?
      (
        <ToolbarItem>
          <Tooltip content="Add a new statement">
            <IconButton
              normalIcon={<AddStatementIcon label="Add statement"/>}
              hoverIcon={<AddStatementIcon label="Add statement"/>}
              onClick={() => this.onAddStatementToCell(cell)}
            />
          </Tooltip>
        </ToolbarItem>
      ) : null;
    return (
      <div
        className="toolbar-justify"
      >
        {renderedAddStatementButton}
        <ToolbarItem>
          {this.renderCloseIcon(this.onCloseCell, false)}
        </ToolbarItem>
      </div>
    );
  };

  renderCell = (cell, cellIndex, rowIndex, groupIndex) => {
    const isFocused = this.state.focusedCellUuid !== undefined && this.state.focusedCellUuid === cell.uuid;
    let classNames = 'rubricCell borderedCell hoverCell';
    const renderedStatements = this.renderStatements(cell, cellIndex, rowIndex, groupIndex, isFocused);
    // const renderedCloseCellIcon = isFocused ? this.renderCloseCellIcon() : null;
    const cellStyle: any = {};
    if (this.state.definition && this.tableElement) {
      const headerColumnCount = 1;
      const columnCount = headerColumnCount + this.state.definition.columns.length;
      const tableWidth = this.tableElement.clientWidth;
      const cellWidth = tableWidth / (columnCount + 1);
      cellStyle.minWidth = cellWidth + 'px';
    }
    return (
      <td
        key={cell.uuid}
        style={cellStyle}
        className={classNames}
        onMouseEnter={browserType.isMobile() ? undefined : () => this.onCellEnter(cell.uuid)}
        onMouseLeave={browserType.isMobile() ? undefined : () => this.onCellLeave()}
        onClick={(event) => this.onCellClick(event, cell, isFocused)}
      >
        {renderedStatements}
        {isFocused ? this.renderCellToolbar(cell) : null}
      </td>
    );
  };

  renderAddRow = (group: string, afterRowUuid?: boolean) => {
    const content = permissionUtil.canAddItemsToDefinition(this.state.definition) ?
      (
        <AddRowIcon
          width={this.toolbarItemWidth}
          height={this.toolbarItemHeight}
          onClick={() => this.onAddRow(group, afterRowUuid)}
        />
      ) : null;
    return (
      <tr
        style={{
          height: '0px',
          display: 'table-caption',
          padding: '0px',
          border: 'none',
          marginTop: '-2px',
        }}
      >
        <td>
          <div
            key={'add-row-after-' + afterRowUuid}
            style={{
              position: 'relative',
              top: (-1 * this.toolbarItemHeight / 2 - 1) + 'px',
              left: ( 2 * this.toolbarItemHeight) + 'px'
            }}
            className="clickable"
          >
            {content}
          </div>
        </td>
      </tr>
    );
  };

  renderBlankIcon = () => {
    return (
      <BlankIcon
        width={this.toolbarItemWidth}
        height={this.toolbarItemHeight}
      />
    );
  };

  renderRowToolbarCell = (group, rowUuid, rowIndex, rowCount) => {
    const row = group ? group.rows[rowIndex] : undefined;
    const canRemoveItemsFromDefinition = permissionUtil.canRemoveItemsFromDefinition(this.state.definition);
    const renderedRemoveIcon = canRemoveItemsFromDefinition ? group && rowCount > 1 ?
      <RemoveRowIcon
        width={this.toolbarItemWidth}
        height={this.toolbarItemHeight}
        confirmationTitle="Confirm aspect deletion"
        confirmationMessage={`Are you sure you want to delete aspect "${row.name}"?`}
        onConfirmation={() => this.onRemoveRow(group, rowUuid)}
      />
      : this.renderBlankIcon() : null;
    const renderedMoveUpButton = canRemoveItemsFromDefinition ? group && rowIndex > 0 ?
      <ArrowIcon
        direction={directionUpType}
        width={this.toolbarItemWidth}
        height={this.toolbarItemHeight}
        onClick={() => this.onMoveRowUpOrDownByUuid(group, rowUuid, directionUpType)}
      />
      : this.renderBlankIcon() : null;
    const renderedMoveDownButton = canRemoveItemsFromDefinition ? group && rowIndex < rowCount - 1 ?
      <ArrowIcon
        direction={directionDownType}
        width={this.toolbarItemWidth}
        height={this.toolbarItemHeight}
        onClick={() => this.onMoveRowUpOrDownByUuid(group, rowUuid, directionDownType)}
      />
      : this.renderBlankIcon() : null;
    const toolbarItemCellStyle = {
      paddingTop: '4px',
      paddingBottom: '6px',
      marginLeft: '0px'
    };
    return (
      <td
        key={'row-toolbar-' + rowUuid}
        style={{
          paddingRight: '0px',
          width: 2 * this.toolbarItemHeight + this.toolbarCellWidth,
          textAlign: 'right',
          verticalAlign: 'middle'
        }}
        align="right"
        className="clickable"
      >
        <ToolbarLeft
          key={'remove-row-' + rowUuid}
          style={{
            position: 'relative',
            top: this.toolbarItemHeight / 3
          }}
        >
          <ToolbarItem style={toolbarItemCellStyle}>
            {renderedMoveUpButton}
          </ToolbarItem>
          <ToolbarItem style={toolbarItemCellStyle}>
            {renderedMoveDownButton}
          </ToolbarItem>
          <ToolbarItem style={toolbarItemCellStyle}>
            {renderedRemoveIcon}
          </ToolbarItem>
        </ToolbarLeft>
      </td>
    );
  };

  renderEmptyCell = (key) => {
    return (
      <td
        key={key}
      >
      </td>
    );
  };

  renderAspectHeaderCellTextField = () => {
    return (
      <FormlessTextField
        name="area"
        placeholder={I18N.Area_header}
        label={undefined}
        autoFocus={true}
        maxLength={constants.maxDefinitionAspectTitleLength}
        value={this.state.definition.aspectHeaderName}
        onChange={this.onAspectHeaderNameChange}
      />
    );
  };

  renderAspectHeaderCellText = () => {
    return (
      <React.Fragment>
        {this.state.definition.aspectHeaderName}
      </React.Fragment>
    );
  };

  renderAspectHeaderCell = () => {
    const isFocused = this.state.aspectHeaderCellFocussed;
    const content = isFocused ? this.renderAspectHeaderCellTextField() : this.renderAspectHeaderCellText();
    return (
      <th
        key={'aspect-header'}
        className="rubricCell borderedCell hoverCell"
        onMouseEnter={browserType.isMobile() ? undefined : () => this.onCellEnter('aspect-header')}
        onMouseLeave={browserType.isMobile() ? undefined : () => this.onCellLeave()}
        onClick={this.onAspectHeaderClick}
      >
        {content}
      </th>
    );
  };

  renderEditRowName = (row) => {
    return (
      <ToolbarItem>
        <ResizingTextArea
          label=""
          compact={false}
          autoFocus={true}
          shouldFitContainer={true}
          maxLength={constants.maxDefinitionRowNameLength}
          minimumRows={2}
          enableResize={true}
          placeholder="Name"
          value={row.name}
          onChange={(event) => this.onRowNameChange(event, row)}
        />
      </ToolbarItem>
    );
  };

  renderRowName = (row) => {
    return (
      <ToolbarItem>
        <span className="editorDerivedContent">
          {row.name}
        </span>
      </ToolbarItem>
    );
  };

  renderEditRowWeight = (row) => {
    const rowWeight = rubricUtil.getRowWeight(row);
    return (
      <ToolbarItem style={{maxWidth: '50px'}}>
        <FormlessTextField
          name="weight"
          label="Weight"
          maxLength={5}
          placeholder="Weight"
          value={rowWeight}
          onChange={(event: FormEvent<HTMLInputElement>) => this.onRowWeightChange(event, row)}
        />
      </ToolbarItem>
    );
  };

  renderRowWeight = (row) => {
    if (rubricUtil.getOptionEnableAspectWeights(this.state.definition)) {
      const rowWeight = rubricUtil.getRowWeight(row);
      return (
        <RowWeight weight={rowWeight}/>
      );
    } else {
      return null;
    }
  };

  renderRowNameCell = (row) => {
    const showRowWeight = rubricUtil.getOptionEnableAspectWeights(this.state.definition);
    const isFocused = this.state.focusedRowUuid !== undefined && this.state.focusedRowUuid === row.uuid;
    const rowNameCellContent = isFocused ? this.renderEditRowName(row) : this.renderRowName(row);
    const rowWeightCellContent = showRowWeight ? isFocused ? this.renderEditRowWeight(row) : this.renderRowWeight(row) : null;
    const renderedCloseCell = isFocused ? this.renderCloseRowToolbarItem() : null;
    return (
      <th
        key={'row-name-' + row.uuid}
        className="rubricCell borderedCell hoverCell"
        style={{minWidth: isFocused ? '100px' : undefined}}
        onMouseEnter={browserType.isMobile() ? undefined : () => this.onCellEnter(row.uuid)}
        onMouseLeave={browserType.isMobile() ? undefined : () => this.onCellLeave()}
        onClick={(event) => this.onRowNameClick(event, row)}
      >
        <ToolbarJustify>
          {rowNameCellContent}
          {renderedCloseCell}
        </ToolbarJustify>
        <ToolbarJustify>
          {rowWeightCellContent}
        </ToolbarJustify>
      </th>
    );
  };

  renderRowFromCells = (renderedCells, rowIndex, rowCount, options) => {
    const row = options.row;
    const isHeaderRow = options.rowUuid === undefined;
    // const rowCount = rubricUtil.countRows(this.state.definition);
    const cells: any = [];
    cells.push(this.renderRowToolbarCell(options.group, options.rowUuid, rowIndex, rowCount));
    const rowNameCell = isHeaderRow ? options.isRowToolbar ? this.renderEmptyCell('empty-row-name') : this.renderAspectHeaderCell() : this.renderRowNameCell(row);
    cells.push(rowNameCell);
    for (let i = 0; i < renderedCells.length; i++) {
      const renderedCell = renderedCells[i];
      cells.push(renderedCell);
    }
    return (
      <tr
        key={options.rowUuid}
        className="rubricRow"
        style={{
          minHeight: '30px'
        }}
      >
        {cells}
      </tr>
    );
  };

  renderAddColumnCell = (column, after) => {
    const style : any = {
      position: 'relative'
    };
    if (after) {
      style.left = 0;
    } else {
      style.right = this.toolbarItemWidth;
    }
    const keyPrefix = after ? 'add-column-after' : 'add-column-';
    const content = permissionUtil.canAddItemsToDefinition(this.state.definition) ?
      (
        <AddColumnIcon
          width={this.toolbarItemWidth}
          height={this.toolbarItemHeight}
          onClick={() => this.onAddColumn(column, after)}
        />
      ) : null;
    return (
      <div
        key={keyPrefix + column.uuid}
        style={style}
        className="clickable"
      >
        {content}
      </div>
    );
  };

  renderColumnToolbarRow = (rowCount) => {
    if (this.state.definition === undefined) {
      return;
    }
    const columnCount = this.state.definition.columns.length;
    const renderedCells: any[] = [];
    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
      const column = this.state.definition.columns[columnIndex];
      const leftControl = columnIndex === 0 ? (
        <div
          style={{
            float: 'left',
            width: 0
          }}
        >
          {this.renderAddColumnCell(column, false)}
        </div>
      ) : null;
      const renderedRemoveColumnIcon = permissionUtil.canRemoveItemsFromDefinition(this.state.definition) ?
        columnCount > 1 ?
          <RemoveColumnIcon
            width={this.toolbarItemWidth}
            height={this.toolbarItemHeight}
            confirmationTitle="Confirm column deletion"
            confirmationMessage="Are you sure you want to delete this column?"
            onConfirmation={() => this.onRemoveColumn(column)}
          />
          : null : null;
      const middleControl = (
        <span
          key={'remove-column-' + column.uuid}
          className="clickable"
        >
          {renderedRemoveColumnIcon}
        </span>
      );
      const rightControl = (
        <div
          style={{
            float: 'right',
            width: 0
          }}
        >
          {this.renderAddColumnCell(column, true)}
        </div>
      );
      const renderedCell = (
        <td
          key={'column-' + column.uuid}
        >
          <div
            style={{
              width: '100%',
              textAlign: 'center'
            }}
          >
            {leftControl}
            {middleControl}
            {rightControl}
          </div>
        </td>
      );
      renderedCells.push(renderedCell);
    }
    const rowRenderingOptions = {
      isRowToolbar: true
    };
    const rowIndex = undefined;
    return this.renderRowFromCells(renderedCells, rowIndex, rowCount, rowRenderingOptions);
  };

  renderRubric() {
    const definition = this.state.definition;
    const style = definition.style ? definition.style : {};
    const borderColor = style.borderColor ? style.borderColor : '#ccc';
    const hoverColor = style.hoverColor ? style.hoverColor : '#F9F9F9';
    const rowCount = rubricUtil.countRows(this.state.definition);
    const renderedHeaderCells = definition.columns.map((column, index) => {
      const isFocused = this.state.focusedColumnUuid !== undefined && this.state.focusedColumnUuid === column.uuid;
      const renderedColumnTitle = isFocused ? this.renderColumnTitleEditor(column) : this.renderColumnTitle(column, isFocused);
      const renderedCloseColumnIcon = isFocused ? this.renderCloseColumnIcon() : null;
      return (
        <th
          key={column.uuid}
          className="rubricCell borderedCell hoverCell"
          onMouseEnter={browserType.isMobile() ? undefined : () => this.onColumnHeaderEnter(column)}
          onMouseLeave={browserType.isMobile() ? undefined : () => this.onColumnHeaderLeave()}
          onClick={(event) => this.onColumnHeaderClick(event, column, isFocused)}
        >
          {renderedCloseColumnIcon}
          {renderedColumnTitle}
        </th>
      );
    });
    const rowRenderingOptions = {};
    const headerRowIndex = undefined;
    const renderedHeaderRow = this.renderRowFromCells(renderedHeaderCells, headerRowIndex, rowCount, rowRenderingOptions);
    const renderedRowGroups = definition.groups.map((group, groupIndex) => {
      const renderedRows = group.rows.map((row, rowIndex) => {
        const renderedCells = row.cells.map((cell, cellIndex) => {
          return this.renderCell(cell, cellIndex, rowIndex, groupIndex);
        });
        const rowRenderingOptions = {
          group: group,
          row: row,
          rowUuid: row.uuid
        };
        return (
          <tbody
            key={'row-' + row.uuid + '-of-group-' + group.uuid}
            style={{border: '0px'}}
          >
            {rowIndex === 0 ? this.renderAddRow(group) : null}
            {this.renderRowFromCells(renderedCells, rowIndex, rowCount, rowRenderingOptions)}
            {this.renderAddRow(group, row.uuid)}
          </tbody>
        );
      });
      return renderedRows;
    });
    // const marginTop = (24 + this.toolbarItemHeight) + 'px';
    const marginTop = '0px';
    return (
      <div
        style={{marginTop: marginTop}}
      >
        <style dangerouslySetInnerHTML={{__html: `
          .borderedCell {
            border: 1px solid ${borderColor};
          }
          .hoverCell:hover {
            background-color: ${hoverColor};
          }
        `}} />
        <table
          ref={(c) => this.tableElement = c}
        >
          <thead style={{border: '0px'}}>
          {this.renderColumnToolbarRow(rowCount)}
          {renderedHeaderRow}
          </thead>
          {renderedRowGroups}
        </table>
      </div>
    );
  };

  renderSettings() {
    return (
      <div>
        {this.renderDescription()}
        {this.renderStateSelector()}
        {this.renderDiscoverabilitySelector()}
        {this.renderLabels()}
        {this.renderBackgroundImageSelector()}
        {this.renderScoreSelectionAndOptions()}
      </div>
    );
  };

  renderSettingsContainer() {
    return (
      <Expand
        contentForeignKey={undefined}
        context={undefined}
        text="Settings"
        tooltip="Click to view and modify the rubric settings"
        initiallyExpanded={this.state.settingsVisible}
        contentRenderer={() => this.renderSettings()}
        stopPropagation={true}
        onExpanded={this.onSettingsVisibilityToggle}
        onCollapsed={this.onSettingsVisibilityToggle}
      />
    );
  };

  renderEditor() {
    return (
      <div>
        <LiftedPanel>
          {this.renderSettingsContainer()}
        </LiftedPanel>
        <div className="newMainSection"></div>
        <LiftedPanel>
          {this.renderRubric()}
          <div className="height20"></div>
        </LiftedPanel>
      </div>
    );
  }

  render() {
    if (this.state.definition) {
      return this.renderEditor();
    } else {
      return null;
    }
  }

}
