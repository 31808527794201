export default class OpacityHelper {

  constructor(widget) {
    this.widget = widget;
    this.normalOpacity = 0.3;
    this.state = {
      opacity: this.normalOpacity
    };
  }

  onMouseEnter = () => {
    this.state.opacity = 1;
    this.widget.setState({
      opacity: this.state.opacity
    });
  };

  onMouseLeave = () => {
    this.state.opacity = this.normalOpacity;
    this.widget.setState({
      opacity: this.state.opacity
    });
  };

  buildStyle = () => {
    return {
      opacity: this.state.opacity
    };
  };

}