import React, { PureComponent } from 'react';

export default class HelpViewItem extends PureComponent {

  render() {
    return (
      <div
        style={{
          marginBottom: '20px'
        }}
      >
        <h3>{this.props.title}</h3>
        {this.props.children}
      </div>
    );
  }

}