import standardScoreMeta from './PercentageScoreMeta';
import levelsScoreMeta from './LevelsScoreMeta';
import yesOrNoScoreMeta from './YesOrNoScoreMeta';
import passOrFailScoreMeta from './PassOrFailScoreMeta';
import unsatisfactoryToStrongScoreMeta from './UnsatisfactoryToStrongScoreMeta';
import poorToStrongScoreMeta from './PoorToStrongScoreMeta';
import badOkGoodScoreMeta from './BadOkGoodScoreMeta';
import limitedToExcellentScoreMeta from './LimitedToExcellentScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';
import scoreTypesToDefinitions from '../../../../commonbase/score/scoreTypesToDefinitions';
import zeroToTenScoreMeta from './ZeroToTenScoreMeta';
import SelectOption from '../../widget/SelectOption';

class ScoreMetaDefinitions {

  standardScoreMetaType = scoreTypes.standard;
  levelsScoreMetaType = scoreTypes.levels;
  yesOrNoScoreMetaType = scoreTypes.yesOrNo;
  passOrFailScoreMetaType = scoreTypes.passOrFail;
  unsatisfactoryToStrongScoreMetaType = scoreTypes.unsatisfactoryToStrong;
  poorToStrongScoreMetaType = scoreTypes.poorToStrong;
  badOkGoodScoreMetaType = scoreTypes.badOkGood;
  limitedToExcellentScoreMetaType = scoreTypes.limitedToExcellent;
  zeroToTenScoreMetaType = scoreTypes.zeroToTen;
  customLevelsScoreMetaType = scoreTypes.customLevels;
  standardScoreMetaOption: SelectOption = {
    value: this.standardScoreMetaType,
    label: standardScoreMeta.getLabel()
  };
  levelsScoreMetaOption: SelectOption = {
    value: this.levelsScoreMetaType,
    label: levelsScoreMeta.getLabel()
  };
  yesOrNoScoreMetaOption: SelectOption = {
    value: this.yesOrNoScoreMetaType,
    label: yesOrNoScoreMeta.getLabel()
  };
  passOrFailScoreMetaOption: SelectOption = {
    value: this.passOrFailScoreMetaType,
    label: passOrFailScoreMeta.getLabel()
  };
  unsatisfactoryToStrongScoreMetaOption: SelectOption = {
    value: this.unsatisfactoryToStrongScoreMetaType,
    label: unsatisfactoryToStrongScoreMeta.getLabel()
  };
  poorToStrongScoreMetaOption: SelectOption = {
    value: this.poorToStrongScoreMetaType,
    label: poorToStrongScoreMeta.getLabel()
  };
  badOkGoodScoreMetaOption: SelectOption = {
    value: this.badOkGoodScoreMetaType,
    label: badOkGoodScoreMeta.getLabel()
  };
  limitedToExcellentScoreMetaOption: SelectOption = {
    value: this.limitedToExcellentScoreMetaType,
    label: limitedToExcellentScoreMeta.getLabel()
  };
  zeroToTenScoreMetaOption: SelectOption = {
    value: this.zeroToTenScoreMetaType,
    label: zeroToTenScoreMeta.getLabel()
  };
  customLevelsScoreMetaOption: SelectOption = {
    value: this.customLevelsScoreMetaType,
    label: scoreTypesToDefinitions[scoreTypes.customLevels].name
  };
  options: SelectOption[] = [
    this.standardScoreMetaOption,
    this.levelsScoreMetaOption,
    this.yesOrNoScoreMetaOption,
    this.passOrFailScoreMetaOption,
    this.unsatisfactoryToStrongScoreMetaOption,
    this.poorToStrongScoreMetaOption,
    this.badOkGoodScoreMetaOption,
    this.limitedToExcellentScoreMetaOption,
    this.zeroToTenScoreMetaOption,
    this.customLevelsScoreMetaOption
  ];
  typesToNames = [];
  typesToMetas = [];

  constructor() {
    for (const option of this.options) {
      this.typesToNames[option.value] = option.label;
    }
    this.typesToMetas[this.standardScoreMetaType] = standardScoreMeta;
    this.typesToMetas[this.levelsScoreMetaType] = levelsScoreMeta;
    this.typesToMetas[this.yesOrNoScoreMetaType] = yesOrNoScoreMeta;
    this.typesToMetas[this.passOrFailScoreMetaType] = passOrFailScoreMeta;
    this.typesToMetas[this.unsatisfactoryToStrongScoreMetaType] = unsatisfactoryToStrongScoreMeta;
    this.typesToMetas[this.poorToStrongScoreMetaType] = poorToStrongScoreMeta;
    this.typesToMetas[this.badOkGoodScoreMetaType] = badOkGoodScoreMeta;
    this.typesToMetas[this.limitedToExcellentScoreMetaType] = limitedToExcellentScoreMeta;
    this.typesToMetas[this.zeroToTenScoreMetaType] = zeroToTenScoreMeta;

    // Backward compatibility
    this.typesToNames['oneToFourEdit'] = poorToStrongScoreMeta.getLabel();
    this.typesToNames['oneToFiveEdit'] = limitedToExcellentScoreMeta.getLabel();
    this.typesToNames['oneToTen'] = zeroToTenScoreMeta.getLabel();
    this.typesToMetas['oneToFourEdit'] = poorToStrongScoreMeta;
    this.typesToMetas['oneToFiveEdit'] = limitedToExcellentScoreMeta;
    this.typesToMetas['oneToTen'] = zeroToTenScoreMeta;
    this.typesToMetas['poorToStrongEdit'] = poorToStrongScoreMeta;
    this.typesToMetas['limitedToExcellentEdit'] = limitedToExcellentScoreMeta;
  }

  getOptions = (): SelectOption[] => {
    return this.options;
  }

  scoreMetaFromType = (scoreMetaType) => {
    let scoreMeta: undefined | any = undefined;
    if (scoreMetaType) {
      scoreMeta = this.typesToMetas[scoreMetaType];
    }
    if (!scoreMeta) {
      scoreMeta = standardScoreMeta
    }
    return scoreMeta;
  };

  // buildScoreMetaTypeEnumItemsContext = (currentType) => {
  //   const standardEditStateItem = {
  //     content: this.typesToNames[this.standardScoreMetaType],
  //     value: this.standardScoreMetaType
  //   };
  //   const levelsEditStateItem = {
  //     content: this.typesToNames[this.levelsScoreMetaType],
  //     value: this.levelsScoreMetaType
  //   };
  //   const yesOrNoEditStateItem = {
  //     content: this.typesToNames[this.yesOrNoScoreMetaType],
  //     value: this.yesOrNoScoreMetaType
  //   };
  //   const passOrFailEditStateItem = {
  //     content: this.typesToNames[this.passOrFailScoreMetaType],
  //     value: this.passOrFailScoreMetaType
  //   };
  //   const unsatisfactoryToStrongEditItem = {
  //     content: this.typesToNames[this.unsatisfactoryToStrongScoreMetaType],
  //     value: this.unsatisfactoryToStrongScoreMetaType
  //   };
  //   const poorToStrongEditItem = {
  //     content: this.typesToNames[this.poorToStrongScoreMetaType],
  //     value: this.poorToStrongScoreMetaType
  //   };
  //   const badOkGoodEditItem = {
  //     content: this.typesToNames[this.badOkGoodScoreMetaType],
  //     value: this.badOkGoodScoreMetaType
  //   };
  //   const limitedToExcellentEditItem = {
  //     content: this.typesToNames[this.limitedToExcellentScoreMetaType],
  //     value: this.limitedToExcellentScoreMetaType
  //   };
  //   const zeroToTenItem = {
  //     content: this.typesToNames[this.zeroToTenScoreMetaType],
  //     value: this.zeroToTenScoreMetaType
  //   };
  //   const customLevelsItem = {
  //     content: this.typesToNames[this.customLevelsScoreMetaType],
  //     value: this.customLevelsScoreMetaType
  //   };

  //   const selectItems = [{
  //     items: [
  //       standardEditStateItem, levelsEditStateItem, yesOrNoEditStateItem, 
  //       passOrFailEditStateItem, unsatisfactoryToStrongEditItem, poorToStrongEditItem, badOkGoodEditItem, limitedToExcellentEditItem, 
  //       zeroToTenItem, customLevelsItem,
  //     ]
  //   }];
  //   let selectedItem: undefined | any = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: selectItems,
  //     selectedItem: selectedItem
  //   };
  // };

  getDefaultType = () => {
    return this.standardScoreMetaType;
  };

}

export default new ScoreMetaDefinitions();
