import storageDAO from './StorageDAO';
import entityTypeDefinitions from "../../shared/model/rubric/EntityTypeDefinitions";
import rubricDAO from './RubricDAO';

export default class NotificationBrowserPersistence {

  notificationPrefix = 'notification-';

  saveNotification = (notification) => {
    return storageDAO.setData(this.notificationPrefix + notification.uuid, notification);
  };

  getSharedAssessments = (user, definitionUuid, shareState) => {
    const userEmail = user.getEmail();
    const matcher = (notification) => {
      let matches =
        notification.targetUserEmail === userEmail &&
        notification.entityType === entityTypeDefinitions.assessmentEntityType &&
        notification.definitionUuid === definitionUuid;
      if (matches && shareState) {
        matches = notification.shareState === shareState;
      }
      return matches;
    };
    return this._findNotifications(matcher).then((notifications) => {
      if (notifications.length) {
        const promises = [];
        for (let i = 0; i < notifications.length; i++) {
          const notification = notifications[i];
          const promise = rubricDAO.getAssessmentByUuid(notification.assessmentUuid);
          promises.push(promise);
        }
        return Promise.all(promises);
      } else {
        return [];
      }
    });
  };

  getCurrentUserNotifications = (user) => {
    const userEmail = user.getEmail();
    const matcher = (notification) => {
      let matches =
        notification.fromUserId === user.getId() ||
        notification.targetUserEmail === userEmail;
      return matches;
    };
    return this._findNotifications(matcher);
  };

  deleteAssessmentNotifications = (assessmentUuid, definitionUuid) => {
    const matcher = (notification) => {
      let matches =
        notification.entityType === entityTypeDefinitions.assessmentEntityType &&
        notification.definitionUuid === definitionUuid &&
        notification.assessmentUuid === assessmentUuid;
      return matches;
    };
    const requiredPrefix = this.notificationPrefix;
    const options = {
      isKeyOfInterest: (itemKey) => {
        return itemKey.indexOf(requiredPrefix) === 0;
      }, transformItem: (item) => {
        return JSON.parse(item);
      }, isItemOfInterest: (notification) => {
        return matcher(notification);
      }
    };
    return storageDAO.removeItems(options);
  };

  searchNotificationsByTypeAndTitle = (entityType, title) => {
    const matcher = (notification) => {
      const matches =
        notification.entityType === entityType &&
        notification.title === title;
      return matches;
    };
    return this._findNotifications(matcher);
  };

  //searchNotifications = (matcher) => {
  //  return this._findNotifications(matcher);
  //};

  doesNotificationExist = (fromUserId, targetUserEmail, entityType, entityUuid) => {
    const matcher = (notification) => {
      const matches =
        notification.fromUserId === fromUserId &&
        notification.targetUserEmail === targetUserEmail &&
        notification.entityType === entityType &&
        notification.entityUuid === entityUuid;
      return matches;
    };
    const requiredPrefix = this.notificationPrefix;
    const options = {
      isKeyOfInterest: (itemKey) => {
        return itemKey.indexOf(requiredPrefix) === 0;
      }, transformItem: (item) => {
        return JSON.parse(item);
      }, isItemOfInterest: (notification) => {
        return matcher(notification);
      }
    };
    return storageDAO
      .getItems(options)
      .then((items) => {
        return items && items.length > 0
      });
  };

  _findNotifications = (matcher) => {
    const requiredPrefix = this.notificationPrefix;
    const options = {
      isKeyOfInterest: (itemKey) => {
        return itemKey.indexOf(requiredPrefix) === 0;
      }, transformItem: (item) => {
        return JSON.parse(item);
      }, isItemOfInterest: (notification) => {
        return matcher(notification);
      }
    };
    return storageDAO.getItems(options);
  };

}
