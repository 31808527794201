import React, { PureComponent } from "react";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import definitionSearch from '../../../shared/model/rubric/search/DefinitionSearch';
import session from '../../../shared/model/auth/Session';
import { SearchOptions } from '../../../shared/model/rubric/search/SearchOptions';
import Button from '@atlaskit/button';
import DefinitionSearchResult from '../../../shared/model/rubric/definition/DefinitionSearchResult';

interface Props {
  searchString: string
  showSearchResults: boolean
  onResultClicked: Function
}

interface State {
  searchString: string
  showSearchResults: boolean
  results: DefinitionSearchResult[]
}

export default class TopNavigationSearchPanel extends PureComponent<Props, State> {

  searchDefinitionsDebounced: Function;
  searchInputRef: any

  state: State = {
    searchString: '',
    showSearchResults: false,
    results: [],
  };

  constructor(props: Props) {
    super(props);
    this.searchDefinitionsDebounced = AwesomeDebouncePromise(
      definitionSearch.searchDefinitionsNew, 250, { key: (fieldId, text) => fieldId });
    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    if (this.searchInputRef && this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState({
      showSearchResults: props.showSearchResults
    });
    if (props.searchString !== this.state.searchString) {
      this.setFilterText(props.searchString);
    }
  }

  setFilterText = async (searchString: string) => {
    this.setState({
      searchString: searchString
    });
    const options: SearchOptions = {
      searchString: searchString,
      maxResults: 20
    };
    const user = session.getCurrentUser();
    if (user) {
      const userId = user.getId();
      const matchingDefinitionSearchResults = await this.searchDefinitionsDebounced(userId, options);
      this.setState({
        results: matchingDefinitionSearchResults
      });
    }
  };

  onResultClicked = (result) => {
    this.props.onResultClicked(result);
    this.setFilterText('');
  };

  renderSearchResults = () => {
    const { results } = this.state;
    if (results && results.length) {
      return results.map((result) => {
        const definitionReference = result.getDefinitionReference();
        return (
          <div className="top-nav-search-result-container">
            <Button
              key={definitionReference.definitionUuid}
              appearance="link"
              onClick={() => this.props.onResultClicked(result)}
            >
              {definitionReference.definitionName}
            </Button>
          </div>
        );
      });
    } else {
      return (
        <p>
          No matching rubrics
        </p>
      );
    }
  };

  render() {
    if (this.state.showSearchResults && this.state.searchString.length) {
      return (
        <div className="top-nav-search-results-container">
          {this.renderSearchResults()}
        </div>
      );
    } else {
      return null;
    }
  }
}
