import React from 'react';

export default class PathBuilder {

  // https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths

  constructor(key) {
    this.attributes = {
      id: key,
      key: key,
      definition: '',
      stroke: 'transparent',
      fill: 'transparent',
      strokeWidth: 1,
      strokeLinecap: 'round',
      style: {},
      onClick: undefined
    };
  };

  setPathColor = (color) => {
    this.attributes.stroke = color;
    return this;
  };

  setFill = (fill) => {
    this.attributes.fill = fill;
    return this;
  };
  
  setPathWidth = (width) => {
    this.attributes.strokeWidth = width;
    return this;
  };

  setPathStyle = (style) => {
    this.attributes.style = style;
    return this;
  };

  moveTo = (x, y) => {
    this.attributes.definition += ` M ${x} ${y}`;
    return this;
  };

  // https://www.w3.org/TR/SVG2/paths.html#PathDataEllipticalArcCommands
  /**
   * Draws an elliptical arc from the current point to (endX, endY). The size and
   * orientation of the ellipse are defined by two radii (rx, ry) and an xAxisRotation,
   * which indicates how the ellipse as a whole is rotated, in degrees, relative
   * to the current coordinate system. The center (cx, cy) of the ellipse is
   * calculated automatically to satisfy the constraints imposed by the other
   * parameters. largeArcFlag and sweepFlag contribute to the automatic
   * calculations and help determine how the arc is drawn.
   */
  arcTo = (rx, ry, xAxisRotation, largeArcFlag, sweepFlag, endX, endY) => {
    this.attributes.definition += ` A ${rx} ${ry} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`;
    return this;
  };

  lineTo = (x, y) => {
    this.attributes.definition += ` L ${x} ${y}`;
    return this;
  };

  bezierCurveTo = (x1, y1, x2, y2, x3, y3) => {
    this.attributes.definition += ` C ${x1} ${y1} ${x2} ${y2} ${x3} ${y3}`;
    return this;
  };

  close = () => {
    this.attributes.definition += ` z`;
    return this;
  };

  setClassName = (className) => {
    this.attributes.className = className;
    return this;
  };

  setTooltip = (tooltip) => {
    this.attributes.tooltip = tooltip;
    return this;
  };

  setOnClick = (onClick) => {
    this.attributes.onClick = onClick;
    return this;
  };

  getAttributes = () => {
    return this.attributes;
  };

  render = () => {
    const attributes = this.attributes;
    return (
      <path
        key={attributes.key}
        id={attributes.id}
        d={attributes.definition.trim()}
        stroke={attributes.stroke}
        strokeWidth={attributes.strokeWidth}
        strokeLinecap={attributes.strokeLinecap}
        fill={attributes.fill}
        style={attributes.style}
        className={attributes.className}
        data-tip={attributes.tooltip}
        onClick={attributes.onClick}
      />
    );
  }
  
}