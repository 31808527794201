import React, { PureComponent } from "react";
import adg from '../../commonbase/adg';
import Button from '@atlaskit/button';
import Label from '../components/widget/Label';
import {CardElement, injectStripe} from 'react-stripe-elements';
import planDefinitions from '../../shared/model/payment/PlanDefinitions';
import Spinner from '@atlaskit/spinner';
import ToolbarItem from '../components/widget/toolbar/ToolbarItem';

class SignUpForm extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      label: props.label ? props.label : 'Sign up',
      processing: props.processing,
      paymentErrorMessage: props.paymentErrorMessage,
      paymentMadeSuccessfully: props.paymentMadeSuccessfully
    };
  };

  onSignUpButtonClick = () => {
    this.props.onSubmitForm(this.props.stripe);
  };

  onCloseForm = () => {
    this.props.onCloseForm();
  };

  renderPaymentMessage = (message, className, color) => {
    if (message) {
      return (
        <div style={{marginTop: '10px', marginBottom: '20px'}}>
          <p
            className={className}
            style={{
              padding: '10px',
              border: `1px solid ${color}`,
              borderRadius: '4px'
            }}
          >
            {message}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  renderSpinner = () => {
    if (this.state.processing) {
      return (
        <ToolbarItem>
          <Spinner
            size="medium"
            delay={0}
            isCompleting={false}
          />
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderDoneButton = () => {
    return (
      <ToolbarItem>
        <Button
          appearance="primary"
          onClick={this.onCloseForm}
        >
          Done
        </Button>
      </ToolbarItem>
    );
  };

  renderSubmitButton = () => {
    return (
      <ToolbarItem>
        <Button
          appearance="primary"
          isDisabled={this.state.processing}
          onClick={this.onSignUpButtonClick}
        >
          Sign up
        </Button>
      </ToolbarItem>
    );
  };

  renderCloseButton = () => {
    return (
      <ToolbarItem>
        <Button
          appearance="subtle"
          isDisabled={this.state.processing}
          onClick={this.onCloseForm}
        >
          Cancel
        </Button>
      </ToolbarItem>
    );
  };

  render() {
    return (
      <div className="checkout">
        <div
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <p>
            Sign up to the <b>{planDefinitions.proPlanName}</b> plan for
            just <b>${planDefinitions.typesToDefinitions[planDefinitions.proType].dollarsPerMonth}</b> per month.
            Enter your credit card details below.
          </p>
        </div>
        {this.renderPaymentMessage(this.state.paymentErrorMessage, 'error', adg.adgRed)}
        {this.state.paymentMadeSuccessfully ? this.renderPaymentMessage('Payment successful. Thank you!', 'success', adg.adgGreen) : null}
        <div style={{marginTop: '0px', marginBottom: '10px'}}>
            <Label text="Credit card details" />
        </div>
        <CardElement />
        <div
          className="toolbar-right"
          style={{
            marginTop: '40px',
            marginBottom: '10px',
          }}
        >
          {this.renderSpinner()}
          {this.state.paymentMadeSuccessfully ? this.renderDoneButton() : null}
          {this.state.paymentMadeSuccessfully ? null : this.renderSubmitButton()}
          {this.state.paymentMadeSuccessfully ? null : this.renderCloseButton()}
        </div>
      </div>
    );
  }

}

export default injectStripe(SignUpForm);
