import React, { PureComponent } from "react";
import Button from '@atlaskit/button';
import { SubmitIcon } from '../../components/icon/NamedIcons';

export default class SubmitButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      isDisabled: props.isDisabled,
      dirty: props.dirty,
      inNormalState: true,
      normalStateLabel: props.normalStateLabel,
      dirtyStateLabel: props.dirtyStateLabel ? props.dirtyStateLabel : props.normalStateLabel,
      normalStateAppearance: props.normalStateAppearance ? props.normalStateAppearance : 'default',
      dirtyStateAppearance: props.dirtyStateAppearance ? props.dirtyStateAppearance : 'primary'
    }
  };

  render() {
    return (
      <Button
        iconBefore={this.state.dirty ? <SubmitIcon /> : undefined }
        appearance={this.state.dirty ? this.state.dirtyStateAppearance : this.state.normalStateAppearance}
        isDisabled={this.state.isDisabled}
        onClick={this.props.onClick}
      >
        {this.state.dirty ? this.state.dirtyStateLabel : this.state.normalStateLabel}
      </Button>
    );
  }

}
