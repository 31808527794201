import React, { PureComponent } from "react";
import adg from '../../../../commonbase/adg';
import ScoreSvg from './ScoreSvg';
import ScoreTag from './ScoreTag';
// import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
// import scoreTypes from '../../../../shared/model/rubric/score/ScoreTypes';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import measurementTypes from '../../../../shared/model/rubric/score/MeasurementTypes';

export default class StatementScore extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    if (!props.scoreMeta) {
      throw new Error('props.scoreMeta not defined')
    }
    const radius = props.radius ? props.radius : 8;
    return {
      scoreMeta: props.scoreMeta,
      assessmentItem: props.assessmentItem,
      radius: radius,
      style: props.style ? props.style : undefined,
      dragDown: props.dragDown ? props.dragDown : 0,
      hideIfZero: props.hideIfZero
    };
  };

  renderSvgScore = () => {
    const scorePercent = scoreUtil.assessmentItemToScore(this.state.assessmentItem)
    if (this.state.hideIfZero && !scorePercent) {
      return null;
    }
    const radius = this.state.radius;
    const width = 2 * radius;
    const height = 2 * radius;
    const style = this.state.style ? this.state.style : this.state.dragDown ? {
      position: 'relative',
      bottom: -this.state.dragDown + 'px'
    } : undefined;
    if (this.props.marginLeft) {
      style.marginLeft = this.props.marginLeft;
    }
    return (
      <div
        style={style}
      >
        <svg width={width} height={height}>
          <ScoreSvg
            scoreMeta={this.state.scoreMeta}
            assessmentItem={this.state.assessmentItem}
            radius={this.state.radius}
          />
        </svg>
      </div>
    );
  };

  renderDiscreteScore = () => {
    const hasScore = scoreUtil.hasScore(this.state.assessmentItem)
    const scorePercent = scoreUtil.assessmentItemToScore(this.state.assessmentItem)
    const score = this.state.scoreMeta.percentToScore(scorePercent);
    const marker = this.state.scoreMeta.scoreToMarker(score);
    const text = hasScore ? marker ? marker.label : '?' : '-';
    return (
      <ScoreTag
        text={text}
        color={marker ? marker.color : adg.adgGrey}
        appearance="rounded"
        marginLeft={this.props.marginLeft}
      />
    );
  };

  render() {
    const measurementType = this.state.scoreMeta.getMeasurementType ? this.state.scoreMeta.getMeasurementType() : undefined;
    if (measurementType === measurementTypes.discreteType) {
      return this.renderDiscreteScore();
    } else {
      return this.renderSvgScore();
    }
  }

}
