
// All images hosted at /img/bg/{file-name}

const whiteBackgroundImageDefinition = {
  id: 'white',
  label: 'White',
  predominantlyLight: true,
  filename: 'white.png',
  attrubution: ''
};

const backgroundImages = [
  whiteBackgroundImageDefinition, {
    id: 'minimalist-trees',
    label: 'Minimalist trees',
    predominantlyLight: true,
    filename: 'minimalist-trees.jpg',
    attrubution: ''
  }, {
    id: 'river-mountain-sky',
    label: 'River mountain scene',
    predominantlyLight: true,
    filename: 'river-mountain-sky.jpg',
    attrubution: 'By Basile Morin - Own work, CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=92886338'
  }, {
    id: 'sunset-poppies',
    label: 'Sunset poppies',
    predominantlyLight: true,
    filename: 'sunset-poppies.jpg',
    attrubution: ''
  }, {
    id: 'cloudy-sunset',
    label: 'Cloudy sunset',
    predominantlyLight: false,
    filename: 'cloudy-sunset.jpg',
    attrubution: ''
  }, {
    id: 'plant',
    label: 'Plant',
    predominantlyLight: false,
    filename: 'plant.svg',
    attrubution: ''
  }, {
    id: 'development',
    label: 'Development',
    predominantlyLight: false,
    filename: 'development.svg',
    attrubution: ''
  }];

module.exports = backgroundImages;
