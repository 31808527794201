import AssessmentItem from './AssessmentItem';
import permissionUtil from '../../auth/PermissionUtil';
import Uuid from '../../../util/Uuid';
import scoreUtil from '../../../../commonbase/score/scoreUtil';

// TODO: remove
const invalidateScores = false;

class ScoreUtil {

  denormaliseAssessmentItem = (assessmentItem) => {
    return scoreUtil.denormaliseAssessmentItem(assessmentItem);
  };

  hasScore = (assessmentItem): boolean => {
    if (assessmentItem) {
      if (assessmentItem.innapplicable) {
        return false;
      } else {
        if (assessmentItem.score === undefined || isNaN(assessmentItem.score)) {
          if (assessmentItem.annotation.trim()) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  assessmentItemToScore = (assessmentItem) => {
    if (invalidateScores) {
      return undefined;
    } else if (assessmentItem) {
      if (this.hasScore(assessmentItem)) {
        return assessmentItem.score ? assessmentItem.score : 0;
      } else {
        return undefined;
      }  
    } else {
      return undefined;
    }
  };

  assessmentItemToAnnotation = (assessmentItem) => {
    if (invalidateScores) {
      return undefined;
    } else if (assessmentItem) {
      return assessmentItem.annotation;
    } else {
      return undefined;
    }
  };

  toggleStatementApplicability = (assessment, statementUuid) => {
    if (assessment) {
      if (!permissionUtil.canWriteAssessment(assessment)) {
        new Error('No permission to set assessment ' + assessment.name);
      }
      const info = this.getInternalAssessmentItem(assessment, statementUuid);
      if (info.innapplicable) {
        info.innapplicable = false;
      } else {
        info.innapplicable = true;
        delete info.score;
      }
      info.lastUpdateTime = new Date().getTime();
    } else {
      // do nothing
    }
  };

  setStatementScore = (assessment, statementUuid, value) => {
    if (assessment) {
      if (!permissionUtil.canWriteAssessment(assessment)) {
        new Error('No permission to set assessment ' + assessment.name);
      }
      const info = this.getInternalAssessmentItem(assessment, statementUuid);
      if (value === undefined) {
        delete info.score;
      } else {
        info.score = value;
      }
      info.lastUpdateTime = new Date().getTime();
    } else {
      // do nothing
    }
  };

  setStatementAnnotation = (assessment, statementUuid, annotation) => {
    if (assessment) {
      if (!permissionUtil.canWriteAssessment(assessment)) {
        new Error('No permission to set annotation ' + assessment.name);
      }
      const info = this.getInternalAssessmentItem(assessment, statementUuid);
      info.annotation = annotation;
      info.lastUpdateTime = new Date().getTime();
    } else {
      // do nothing
    }
  };

  getInternalAssessmentItem = (assessment, statementUuid : Uuid) : AssessmentItem => {
    let info : AssessmentItem;
    if (assessment) {
      if (!permissionUtil.canReadAssessment(assessment)) {
        new Error('No permission to read assessment ' + assessment.name);
      }
      let infoNeedsBinding = true;
      if (!assessment.statementUuidsToInfos) {
        assessment.statementUuidsToInfos = {};
      }
      const assessmentItem = assessment.statementUuidsToInfos[statementUuid];
      if (assessmentItem === undefined) {
        info = this._buildDefaultAssessmentItem();
      } else {
        info = assessmentItem;
        infoNeedsBinding = false;
      }
      if (infoNeedsBinding) {
        assessment.statementUuidsToInfos[statementUuid] = info;
      }
    } else {
      info = this._buildDefaultAssessmentItem();
    }
    if (invalidateScores) {
      info.innapplicable = true;
    }
    return info;
  };

  /**
   * @annotation 
   * @score: a number from 0 to 100.
   */
  buildAssessmentItemFromAnnotationAndScore = (annotation: string, score: number): AssessmentItem => {
    const item: AssessmentItem = {
      score: score,
      annotation: annotation,
      innapplicable: false,
      lastUpdateTime: 0
    };
    if (invalidateScores) {
      item.innapplicable = true;
    }
    return item;
  };

  /**
   * @score: a number from 0 to 100.
   */
  buildAssessmentItemFromScore = (score: undefined | number): AssessmentItem => {
    const item: AssessmentItem = {
      score: score,
      annotation: '',
      innapplicable: false,
      lastUpdateTime: 0
    };
    if (invalidateScores) {
      item.innapplicable = true;
    }
    return item;
  };

  _buildDefaultAssessmentItem = (): AssessmentItem => {
    const item = {
      // leave the score field absent to prevent Firestore complaining due to an undefined field
      // FirebaseError: Function DocumentReference.set() called with invalid data. Unsupported field value: undefined (found in field statementUuidsToInfos.`436bed1a-b1b7-ff40-52de-208305238e7f`.score)
      // score: undefined,

      annotation: '',
      innapplicable: false,
      lastUpdateTime: 0
    };
    if (invalidateScores) {
      item.innapplicable = true;
    }
    return item;
  };

}

export default new ScoreUtil();
