import ListenerGroup from '../../shared/util/ListenerGroup';
import permissionUtil from '../../shared/model/auth/PermissionUtil';
import session from '../../shared/model/auth/Session';
import notificationDAO from './NotificationDAO';
import shareStateDefinitions from "../../shared/model/rubric/ShareStateDefinitions";

class CurrentNotifications {

  listenerGroup = new ListenerGroup('CurrentNotifications');
  currentNotifications = [];
  lastRetrievalTime = 0;

  constructor() {
    session.registerListener(this.onSessionChange);
  }

  onSessionChange = (user) => {
    this.rebuildCurrentNotifications();
  };

  rebuildCurrentNotifications = () => {
    const user = session.getCurrentUser();
    if (user) {
      return notificationDAO.getCurrentUserNotifications()
        .then((currentNotifications) => {
          this.lastRetrievalTime = new Date().getTime();
          this._setCurrentNotifications(currentNotifications);
          return null;
        });
    } else {
      return new Promise((resolve, reject) => {
        this._setCurrentNotifications([]);
        resolve(null);
      });
    }
  };

  getSharedAssessments = (definitionUuid, shareState) => {
    return new Promise((resolve, reject) => {
      const matchingNotifications = [];
      for (const currentNotification of this.currentNotifications) {
        if (currentNotification.definitionUuid === definitionUuid) {
          if (currentNotification.shareState === shareState) {
            matchingNotifications.push(currentNotification);
          }
        }
      }
      resolve(matchingNotifications);
    });
  };

  _setCurrentNotifications = (currentNotifications) => {
    this.currentNotifications = currentNotifications;
    this._notifyListeners();
  };

  getLastRetrievalTime = () => {
    return this.lastRetrievalTime;
  };

  countUnacknowledgedNotifications = () => {
    return this.countNotifications(shareStateDefinitions.outstandingShareState);
  };

  countNotifications = (shareState) => {
    const user = session.getCurrentUser();
    const myEmail = user ? user.getEmail() : '';
    let count = 0;
    for (let i = 0; i < this.currentNotifications.length; i++) {
      const notification = this.currentNotifications[i];
      const unacknowledged =
        notification.shareState === shareState &&
        notification.targetUserEmail === myEmail;
      if (unacknowledged) {
        count++;
      }
    }
    return count;
  };

  acknowledgeNotification = (notification) => {
    return new Promise((resolve, reject) => {
      if (permissionUtil.canAcknowledgeNotification(notification)) {
        notification.shareState = shareStateDefinitions.acceptedShareState;
        notificationDAO.saveNotification(notification);
        this._notifyListeners();
        resolve();
      } else {
        reject(`Can not acknowledge this notification.`);
      }
    });
  };

  rejectNotification = (notification) => {
    return new Promise((resolve, reject) => {
      if (permissionUtil.canRejectNotification(notification)) {
        notification.shareState = shareStateDefinitions.rejectedShareState;
        notificationDAO.saveNotification(notification);
        this._notifyListeners();
        resolve();
      } else {
        reject(`Can not reject this notification.`);
      }
    });
  };

  getNotifications = () => {
    return this.currentNotifications;
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = () => {
    const unacknowledgedNotificationCount = this.countUnacknowledgedNotifications();
    this.listenerGroup.notifyListeners(this.currentNotifications, unacknowledgedNotificationCount);
  };

}

export default new CurrentNotifications();
