import React from "react";
import App from './App';
import appViewState from './AppViewState';
import '@atlaskit/css-reset';
import ContainerDimensions from 'react-container-dimensions';
import session from '../../shared/model/auth/Session';
import SignInWithGoogleButton from '../components/widget/SignInWithGoogleButton';
import constants from '../../shared/model/Constants';

export default class MobileApp extends App {

  constructor(props) {
    super(props);
    this.mounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.mounted = true;
    const state = this.buildStateFromProps(this.props);
    this.setState(state);
    session.registerListener(this._onSessionChange);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.mounted = false;
    session.unregisterListener(this._onSessionChange);
  }

  _onSessionChange = (user) => {
    if (this.mounted) {
      this.setState({
        user: user
      });
    }
  };

  buildStateFromProps = (props) => {
    return {
      user: session.getCurrentUser(),
      title: props.title,
      renderedContent: props.content
    };
  };

  renderLoggedInContent = () => {
    return this.state.renderedContent;
  };

  renderLoggedOutContent = () => {
    const title = this.state.title ? this.state.title : constants.appTitle;
    return (
      <div className="centredContent" style={{marginTop: '0px'}}>
        <div className="centredContent" style={{marginBottom: '30px'}}>
          <h2>{title}</h2>
        </div>
        <SignInWithGoogleButton/>
      </div>
    );
  };

  renderAppContent = () => {
    return (
      <ContainerDimensions>
        {
          ({ width, height }) => {
            appViewState.setAppContainerWidth(width);
            return (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <div style={{width: '375px', height: '667px', padding: '10px'}}>
                  {this.state.user ? this.renderLoggedInContent() : this.renderLoggedOutContent()}
                </div>
              </div>
            );
          }
        }
      </ContainerDimensions>
    );
  }

}
