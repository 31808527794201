import React, { PureComponent } from "react";
import adg from '../../../../commonbase/adg';
import ContainerDimensions from 'react-container-dimensions';
import ScoreSvg from './ScoreSvg';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import Tooltip from "@atlaskit/tooltip";
import util from '../../../../shared/util/Util';
import AssessmentItem from '../../../../shared/model/rubric/score/AssessmentItem';
import ScoreMeta from '../../../../shared/model/rubric/score/ScoreMeta';
import ScoreMarker from '../../../../shared/model/rubric/score/ScoreMarker';

interface Props {
  score: number
  editable: boolean
  assessmentItem: AssessmentItem
  scoreMeta: ScoreMeta
  containerWidth: number
  onScoreChange: (scorePercent: number) => void;
}

interface State {
  editable: boolean;
  assessmentItem: AssessmentItem;
  scoreMeta: ScoreMeta;
  scorePercent: number;
}

interface CachedScoreTextInfo {
  scorePercent: number
  scoreTextInfo: any
}

export default class ScoreSliderInput extends PureComponent<Props, State> {

  cachedScoreTextInfo: undefined | CachedScoreTextInfo = undefined;

  barBackgroundColor = '#fff';
  scoreCircleRadius = 12;
  markerCircleRadius = 8;
  circleTopBottomPadding = 2;
  circleDiameterWithPadding = 2 * (this.scoreCircleRadius + this.circleTopBottomPadding);
  svgHeight = 2 * (this.scoreCircleRadius + this.circleTopBottomPadding);
  barBorderWidth = 1.5;
  barOuterHeight = this.svgHeight / 3;
  barInnerHeight = this.barOuterHeight - 2 * this.barBorderWidth;
  barOuterOffsetTop = (this.svgHeight - this.barOuterHeight) / 2;
  barInnerOffsetTop = (this.svgHeight - this.barInnerHeight) / 2;
  barInnerLeftOffset = 0.5 * this.barBorderWidth;
  barInnerRightOffset = 0.5 * this.barBorderWidth;
  barCornerRadius = this.barOuterHeight / 2;
  scorePercent: number;
  debouncedNotifyScoreChange: () => void;
  circleMouseDownX?: number;
  circleMouseDownY?: number;
  width?: number;
  sliderBackgroundRectElement?: any;
  sliderSvgElement?: any;
  sliderScoreRectElement?: any;

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
    this.scorePercent = this.state.scorePercent;
    this.debouncedNotifyScoreChange = util.debounce(this.notifyScoreChange, 100, false) as () => void;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  componentDidUpdate() {
    // widgetUtil.scheduleTooltipRebuild();
  }

  buildStateFromProps = (props: Props): State => {
    const scoreMeta = props.scoreMeta;
    const score = props.score === undefined || isNaN(props.score) ? 0 : props.score;
    const newState: State = {
      editable: props.editable,
      scorePercent: score,
      assessmentItem: props.assessmentItem,
      scoreMeta: scoreMeta
    };
    const fullState = this.state ? Object.assign(this.state, newState) : newState;
    return fullState;
  };

  onClickWhenNotInEditMode = (): void => {

    return undefined;
  }

  onMarkerClick = (marker: ScoreMarker): void => {
    if (!this.state.editable) {
      return this.onClickWhenNotInEditMode();
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    const scorePercent = this.state.scoreMeta.scoreToPercent(marker.score);
    this.setScorePercent(scorePercent);
    this.circleMouseDownX = undefined;
    this.notifyScoreChange();
  };

  onClickAnywhere = (event): void => {
    if (!this.state.editable) {
      return this.onClickWhenNotInEditMode();
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    if (this.circleMouseDownX !== undefined) {
      return;
    }
    this.handleDragOrClick(event, true, true);
  };

  onCircleMouseDown = (event) => {
    this.circleMouseDownX = event.pageX;
  };

  onMouseMoveAnywhere = (event) => {
    if (!this.state.editable) {
      return;
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    if (this.circleMouseDownX === undefined) {
      return;
    }
    this.handleDragOrClick(event, false, false);
    event.preventDefault();
  };

  onMouseUpAnywhere = (event) => {
    if (!this.state.editable) {
      return;
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    if (this.circleMouseDownX === undefined) {
      return;
    }
    this.handleDragOrClick(event, false, true);
  };

  onMouseEnterAnywhere = (event) => {
    if (!this.state.editable) {
      return;
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    if (this.circleMouseDownX === undefined) {
      return;
    }
    // this.handleDragOrClick(event, false, false);
  };

  onMouseOutAnywhere = (event) => {
    if (!this.state.editable) {
      return;
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    if (this.circleMouseDownX === undefined) {
      return;
    }
    this.handleDragOrClick(event, false, true);
  };

  setScorePercent = (scorePercent) => {
    if (!this.state.editable) {
      return;
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    this.scorePercent = scorePercent;
    this.setState({
      scorePercent: scorePercent
    });
  };

  handleDragOrClick = (event, forceImmediateUiUpdate, finalise) => {
    if (!this.state.editable) {
      return;
    }
    if (this.state.assessmentItem.innapplicable) {
      return;
    }
    const eventTarget = event.target;
    let boundingClientRect = eventTarget.getBoundingClientRect();
    const gestureAgainstMarker = boundingClientRect.width < this.circleDiameterWithPadding;
    if (gestureAgainstMarker) {
      if (finalise) {
        this.circleMouseDownX = undefined;
      }
      // Marker clicks are processed by a separate handler.
      return;
    } else if (this.width) {
      const barLeft = this.scoreCircleRadius;
      const barRight = this.width - this.scoreCircleRadius;
      const barWidth = barRight - barLeft;
      const mouseX = event.clientX - boundingClientRect.left;
      let scorePercent = 100 * mouseX / barWidth;
      if (scorePercent < 0) {
        scorePercent = 0;
      } else if (scorePercent > 100) {
        scorePercent = 100;
      }
      scorePercent = this.state.scoreMeta.roundInputScorePercent(scorePercent);
      this.setScorePercent(scorePercent);
      if (finalise) {
        this.circleMouseDownX = undefined;
      } else {
        this.circleMouseDownX = mouseX;
      }
      
      if (forceImmediateUiUpdate) {
        this.notifyScoreChange();
      } else {
        this.debouncedNotifyScoreChange();
      }
    }
  };

  notifyScoreChange = () => {
    this.props.onScoreChange(this.scorePercent);
  };

  scorePercentToX = (scorePercent, width) => {
    const barLeft = this.scoreCircleRadius;
    const barRight = width - this.scoreCircleRadius;
    const barWidth = barRight - barLeft;
    const scoreX = barLeft + scorePercent * barWidth / 100;
    return scoreX;
  };

  renderMarkers = (width: number, markers: ScoreMarker[]) => {
    const showMarkersOnlyOnHover = true;
    const clickableClassName = this.state.editable ? 'clickable ' : '';
    const markerClassName = showMarkersOnlyOnHover ? `${clickableClassName} hover-item` : clickableClassName;
    return markers.map((marker: ScoreMarker) => {
      const scorePercent = this.state.scoreMeta.scoreToPercent(marker.score);
      const scoreX = this.scorePercentToX(scorePercent, width);
      const circleCentreY = this.svgHeight / 2;
      const assessmentItem = scoreUtil.buildAssessmentItemFromScore(scorePercent);
      return (
        <g
          key={'marker-' + marker.score}
          className=""
          onClick={() => this.onMarkerClick(marker)}
        >
          <g className={markerClassName}>
            <circle
              cx={scoreX}
              cy={circleCentreY}
              r={this.markerCircleRadius + 1}
              fill="#fff"
              onClick={() => this.onMarkerClick(marker)}
            />
            <ScoreSvg
              scoreMeta={this.state.scoreMeta}
              color={adg.adgGray}
              centreX={scoreX}
              centreY={circleCentreY}
              assessmentItem={assessmentItem}
              radius={this.markerCircleRadius}
              tooltip={marker.tooltip ? marker.tooltip : marker.label}
              onClick={() => this.onMarkerClick(marker)}
            />
          </g>
        </g>
      );
    });
  };

  renderSvg = (width: number) => {
    this.width = width;
    const clickableClassName = this.state.editable ? 'clickable ' : '';
    const showMarkers = width > this.state.scoreMeta.markers.length * this.circleDiameterWithPadding;
    const barLeft = this.scoreCircleRadius;
    const barRight = width - this.scoreCircleRadius;
    const barWidth = barRight - barLeft;
    const scorePercent = this.state.scorePercent;
    const scoreX = barLeft + scorePercent * barWidth / 100;
    const circleCentreY = this.svgHeight / 2;
    const markers = this.state.scoreMeta.getMarkers();
    const renderedMarkers = showMarkers ? this.renderMarkers(width, markers) : null;
    const assessmentItem = this.state.assessmentItem;
    const barForegroundColor = this.state.scoreMeta.assessmentItemToColour(assessmentItem);
    const barBorderColor = barForegroundColor;
    const scoreTextInfo = this.cachedScoreTextInfo && this.cachedScoreTextInfo.scorePercent === scorePercent &&
      this.cachedScoreTextInfo.scoreTextInfo ?
      this.cachedScoreTextInfo.scoreTextInfo : this.state.scoreMeta.computeScoreTextInfo(scorePercent);
    this.cachedScoreTextInfo = {
      scorePercent: scorePercent,
      scoreTextInfo: scoreTextInfo
    }
    let scoreBarWidth = scoreX - barLeft - this.barInnerLeftOffset - this.barInnerRightOffset;
    if (scoreBarWidth < 0) {
      scoreBarWidth = 0;
    }
    const tooltip = this.state.editable ? assessmentItem.innapplicable ?
      'Innapplicable' : 'Drag the circle to change the score' : scoreTextInfo.tooltip;
    return (
      <Tooltip content={tooltip}>
        <svg
          ref={input => {this.sliderSvgElement = input;}}
          className={`no-text-select has-hoverables ${clickableClassName}`}
          width={width}
          height={this.svgHeight}
          onMouseMove={this.onMouseMoveAnywhere}
          onMouseUp={this.onMouseUpAnywhere}
          onMouseLeave={this.onMouseOutAnywhere}
          onClick={this.onClickAnywhere}
        >
          <rect
            ref={input => {this.sliderBackgroundRectElement = input}}
            x={barLeft}
            y={this.barOuterOffsetTop}
            rx={this.barCornerRadius}
            ry={this.barCornerRadius}
            width={barWidth}
            height={this.barOuterHeight}
            stroke={barBorderColor}
            fill={this.barBackgroundColor}
            strokeWidth={this.barBorderWidth}
          />
          <rect
            ref={input => {this.sliderScoreRectElement = input}}
            x={barLeft + this.barInnerLeftOffset}
            y={this.barInnerOffsetTop}
            rx={this.barCornerRadius}
            ry={this.barCornerRadius}
            width={scoreBarWidth}
            height={this.barInnerHeight}
            stroke={barForegroundColor}
            fill={barForegroundColor}
            strokeWidth={this.barBorderWidth}
          />
          <g
            className={clickableClassName}
            onMouseDown={this.onCircleMouseDown}
          >
            {renderedMarkers}
            <circle cx={scoreX} cy={circleCentreY} r={this.scoreCircleRadius + 1} fill="#fff" />
            <ScoreSvg
              scoreMeta={this.state.scoreMeta}
              centreX={scoreX}
              centreY={circleCentreY}
              assessmentItem={assessmentItem}
              radius={this.scoreCircleRadius}
            />
          </g>
        </svg>
      </Tooltip>
    )
  };

  render() {
    if (this.props.containerWidth) {
      return this.renderSvg(this.props.containerWidth);
    } else {
      return (
        <ContainerDimensions>
          { ({ width, height }) =>
            <React.Fragment>
              {this.renderSvg(width)}
            </React.Fragment>
          }
        </ContainerDimensions>
      );
    }
  }

}
