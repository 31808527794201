import React, { PureComponent } from 'react';
import actions from '../../shared/actions/Actions';
import allTheThings from '../images/all-the-things.png';
import ContainerDimensions from 'react-container-dimensions';
import DashboardView from '../components/rubric/dashboard/DashboardView';
import DriveView from '../components/drive/DriveView';
import homePageIllustration from '../images/home-page-illustration-v3.png';
import navConstants from '../components/nav/NavConstants';
import ContentWrapper from '../components/widget/ContentWrapper';
import constants from '../../shared/model/Constants';
import currentUserPlan from '../../backend/payment/CurrentUserPlan';
import HighlightsPanel from '../components/rubric/help/HighlightsPanel';
import planDefinitions from '../../shared/model/payment/PlanDefinitions';
import rubricPageHelper from './RubricPageHelper';
import session from '../../shared/model/auth/Session';
import SignInWithGoogleButton from '../components/widget/SignInWithGoogleButton';
import SignUpButton from '../payment/SignUpButton';
import StartView from '../components/rubric/start/StartView';
import IUser from '../../shared/model/auth/IUser';
import ToolbarLeft from '../components/widget/toolbar/ToolbarLeft';
import ToolbarJustify from '../components/widget/toolbar/ToolbarJustify';
import ToolbarItem from '../components/widget/toolbar/ToolbarItem';

interface Props {

}

interface State {
  user?: IUser
  planDefinition: any
  newRubricOptionRecentlySelected: boolean
}

export default class HomePage extends PureComponent<Props, State> {

  tableWidth: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      user: session.getCurrentUser(),
      planDefinition: currentUserPlan.getCurrentPlanDefinition(),
      newRubricOptionRecentlySelected: false
    };
  }

  UNSAFE_componentWillMount() {
    // if (session.getCurrentUser()) {
    //   actions.onCollapseDrawer();
    // }
    session.registerListener(this.onSessionChange);
    currentUserPlan.registerListener(this.onCurrentUserPlanChange);
  }

  componentDidMount() {
    rubricPageHelper.updateTitle();
  }

  componentWillUnmount() {
    session.unregisterListener(this.onSessionChange);
    currentUserPlan.unregisterListener(this.onCurrentUserPlanChange);
  }

  onSessionChange = (user) => {
    this.setState({
      user: user
    });
  };

  onCurrentUserPlanChange = (planDefinition) => {
    this.setState({
      planDefinition: planDefinition
    });
  };

  onNewRubric = () => {
    this.setState({
      newRubricOptionRecentlySelected: true
    });
    actions.onNewRubric();
    setTimeout(() => {
      this.setState({
        newRubricOptionRecentlySelected: false
      });
    }, 5000);
  };

  renderDablerPanel = () => {
    return (
      <div className="panel">
        <div className="panel-header">
          Plan
        </div>
        <div className="panel-body centered">
          <p>
            You're currently in the {planDefinitions.litePlanName} plan.
          </p>
          <div className="panel-section">
            <SignUpButton
              appearance="primary"
              label={`Upgrade to the ${planDefinitions.proPlanName} plan`}
            />
          </div>
        </div>
      </div>
    );
  };

  renderProPanel = () => {
    return (
      <div className="panel">
        <div className="panel-header">
          Plan
        </div>
        <div className="panel-body centered">
          <p>
            Nice work! You're currently in
            the <a href={'/#' + navConstants.pricingPageHash}>{planDefinitions.proPlanName} plan</a>.
          </p>
        </div>
      </div>
    );
  };

  renderDashboardPanel = () => {
    return (
      <div className="panel">
        <div className="panel-header">
          Dashboard
        </div>
        <div className="panel-body centered">
          <p>
            Visit your <a href={'/#' + navConstants.defaultAppPageHash}>dashboard</a>.
          </p>
        </div>
      </div>
    );
  };

  renderRubricCreationGuidePanel = () => {
    return (
      <div className="panel">
        <div className="panel-header">
          Rubric creation guide
        </div>
        <div className="panel-body centered">
          <p>
            Read the <a href={'/#' + navConstants.rubricCreationuidePageHash}>rubric creation guide</a> to
            learn about the best practices for creating and using rubrics.
          </p>
        </div>
      </div>
    );
  };

  renderSignInPanel = () => {
    return (
      <div className="panel">
        <div className="panel-header">
          Sign in
        </div>
        <div className="panel-body centered">
          <p>
            <a href={'/#' + navConstants.signInPageHash}>Sign in</a> to {constants.appTitle}.
          </p>
        </div>
      </div>
    );
  };

  renderDrivePanel = (width) => {
    if (width > 800) {
      return (
        <ToolbarJustify>
          <ToolbarItem>
            <DriveView
              showHeading={true}
              showEmptyFoldersMessage={false}
              showEditTools={true}
            />
          </ToolbarItem>
          <ToolbarItem>
            {this.renderAllTheThings()}
          </ToolbarItem>
        </ToolbarJustify>
      );
    } else {
      return (<DriveView showHeading={true} showEmptyFoldersMessage={false}/>);
    }
  };

  renderAllTheThings = () => {
    return (
      <React.Fragment>
        <p className="any-byline big-byline">
          {constants.appByline}
        </p>
        <img
          style={{marginLeft: '20px'}}
          src={allTheThings}
          width={200}
          alt={constants.appByline}
        />
      </React.Fragment>
    );
  };

  renderLoggedOutBannerPanel = () => {
    return (
      <ToolbarJustify>
        <div style={{marginRight: '5%'}}>
          <div className="centredContent">
            <p className="home-page-product-title">
              {constants.appTitle}
            </p>
            <ToolbarLeft>
              {this.renderAllTheThings()}
            </ToolbarLeft>
            <div
              style={{marginTop: '20px'}}
            >
              {this.state.user ? null : (<SignInWithGoogleButton/>)}
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: '5%'
          }}
        >
          <img
            style={{
              maxWidth: '100%',
              maxHeight: '500px'
            }}
            src={homePageIllustration}
            alt=""
          />
        </div>
      </ToolbarJustify>
    );
  };

  renderPanels = (keysToRenderedItems) => {
    const keys = Object.keys(keysToRenderedItems);
    return (
      <div className="definitionsGroup">
        {keys.map((key) => {
          return (
            <React.Fragment key={key}>
              {keysToRenderedItems[key]}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  renderLoggedInView = () => {
    return (
      <ContainerDimensions>
      {
        ({ width, height }) => {
          this.tableWidth = width;
          return (
            <div className="logged-in-view">
              {this.renderDrivePanel(width)}
              <div style={{marginTop: '40px'}}>
                <DashboardView
                  showHeading={true}
                  showAssessmentGroups={false}
                  allowSpinner={true}
                />
              </div>
            </div>
          );}
        }
      </ContainerDimensions>
    );
  };

  renderLoggedOutView = () => {
    return (
      <div className="logged-out-view">
        {this.renderLoggedOutBannerPanel()}
        <HighlightsPanel />
      </div>
    );
  };

  renderOLD() {
    return (
      <ContentWrapper>
        <div>
          {this.state.user ? this.renderLoggedInView() : this.renderLoggedOutView()}
        </div>
      </ContentWrapper>
    );
  }

  renderXxxx() {
    return (
      <ContentWrapper>
        <ContainerDimensions>
          { ({ width, height }) =>
            <div
              style={{
                marginTop: '100px'
              }}
            >
              <StartView
                width={width}
                height={height}
                {...this.props}
              />
            </div>
          }
        </ContainerDimensions>
      </ContentWrapper>
    );
  }

  render() {
    return (
      <StartView
        {...this.props}
      />
    );
  }
}
