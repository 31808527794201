import DashboardInfo from '../../shared/model/rubric/dashboard/DashboardInfo';
import DashboardPersistence from './DashboardPersistence';
import storageDAO from './StorageDAO';

export default class DashboardBrowserPersistence implements DashboardPersistence {

  dashboardPath = 'dashboard';
  analyticsEntityName = 'dashboard-state';

  saveState = (userId: string, state: DashboardInfo) => {
    const key = this._buildKey(userId);
    return storageDAO.setData(key, state);
  };

  getState = (userId: string): Promise<undefined | DashboardInfo> => {
    const key = this._buildKey(userId);
    type NewType = DashboardInfo;

    return storageDAO.getData(key) as Promise<undefined | NewType>;
  };

  _buildKey = (userId: string) => {
    return this.dashboardPath + '/' + userId;
  };

};
