import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import LiftedPageTitle from '../components/widget/LiftedPageTitle';
import NetworkView from '../components/network/NetworkView';
import rubricPageHelper from './RubricPageHelper';

export default class NetworkPage extends PureComponent {

  componentDidMount() {
    rubricPageHelper.updateTitle('Network');
  }

  render() {
    return (
      <ContentWrapper>
        <LiftedPageTitle>Network</LiftedPageTitle>
        <NetworkView showHeading={false} showEmptyFoldersMessage={true}/>
      </ContentWrapper>
    );
  }

}
