// const snarkdown = require('snarkdown');
const { Remarkable } = require('remarkable');
const { linkify } = require('remarkable/linkify');

const md = new Remarkable({
  html:         false,        // Enable HTML tags in source
  xhtmlOut:     true,        // Use '/' to close single tags (<br />)
  breaks:       true,        // Convert '\n' in paragraphs into <br>
  langPrefix:   'language-',  // CSS language prefix for fenced blocks

  // Enable some language-neutral replacement + quotes beautification
  typographer:  false,

  // Double + single quotes replacement pairs, when typographer enabled,
  // and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
  quotes: '“”‘’',

  // Highlighter function. Should return escaped HTML,
  // or '' if the source string is not changed
  highlight: function (/*str, lang*/) { return ''; }
})
.use(linkify);

const util = {};

util.markdownToHtml = function(markdown) {
  return md.render(markdown);
}

/**
 * 
 * This method parses markdown and returns and object with two fields:
 *   * introHtml: Introduction HTML
 *   * entireHtml: Entire HTML
 */
util.markdownSplit = function(markdown, maxIntroCharacterLength, optionalCutoffTolerance) {
  const cutoffTolerance = optionalCutoffTolerance ? optionalCutoffTolerance : 0;
  let introHtml = undefined;
  let entireHtml = undefined;
  if (markdown) {
    entireHtml = util.markdownToHtml(markdown);

    let splitPoint = maxIntroCharacterLength;
    if (entireHtml.length <= splitPoint + cutoffTolerance) {
      introHtml = entireHtml;
    } else {

      // Avoid the split point being in the middle of a link..
      /* eslint-disable-next-line */
      const regexMdLinks = /\[([^\[]+)\](\(.*\))/gm;
      const matches = markdown.matchAll(regexMdLinks);
      for (const match of matches) {
        const linkMarkdown = match[0];
        const linkStartIndex = markdown.indexOf(linkMarkdown);
        if (linkStartIndex >= 0 && linkStartIndex < splitPoint) {
          const linkEndIndex = linkStartIndex + linkMarkdown.length;
          if (splitPoint <= linkEndIndex) {
            splitPoint = linkEndIndex + 1;
          }
        }
      }

      let trimmedMarkdown = markdown.substring(0, splitPoint);
      let lastNewLineIndex = trimmedMarkdown.lastIndexOf('\n');
      if (lastNewLineIndex === -1) {
        const firstNewLineIndex = trimmedMarkdown.indexOf('\n');
        if (firstNewLineIndex === -1) {
          lastNewLineIndex = 0;
        }
      }
      if (lastNewLineIndex >= 0) {
        const textAfterLastNewLine = trimmedMarkdown.substring(lastNewLineIndex).trim();
        if (textAfterLastNewLine.indexOf('# ') === 0 || textAfterLastNewLine.indexOf('## ') === 0 || textAfterLastNewLine.indexOf('### ') === 0 || textAfterLastNewLine.indexOf('#### ') === 0) {
          trimmedMarkdown = trimmedMarkdown.substring(0, lastNewLineIndex).trim();
        }
      }

      if (trimmedMarkdown) {
        introHtml = util.markdownToHtml(trimmedMarkdown);
      } else {
        introHtml = entireHtml;
      }
    }  
  } else {
    introHtml = '';
    entireHtml = '';
  }
  return {
    introHtml: introHtml.trim(),
    entireHtml: entireHtml.trim()
  }
}

module.exports = util;
