import React, { PureComponent } from "react";
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import IconButton from "./IconButton";
import { MoreIcon } from '../icon/NamedIcons';
import LiftedPanel from './LiftedPanel';
import Tooltip from "@atlaskit/tooltip";
import ToolbarItem from './toolbar/ToolbarItem';
import ToolbarRight from './toolbar/ToolbarRight';
import ToolbarLeft from './toolbar/ToolbarLeft';

interface Props {
  children?: React.ReactNode;
  name: string
  icon?: any
  alignRight: boolean
  expanded?: boolean
}

interface State {
  name: string
  icon?: any
  alignRight: boolean
  expanded: boolean
}

export default class ExpandingToolbar extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props) => {
    const expanded = (this.state && this.state.expanded) || (!this.state && props.expanded);
    return {
      name: props.name,
      icon: props.icon,
      alignRight: props.alignRight,
      expanded: expanded
    };
  };

  onCollapseButtonClick = (event) => {
    this.setState({
      expanded: false
    });
  };

  onExpandButtonClick = (event) => {
    this.setState({
      expanded: true
    });
  };

  renderExpanded = () => {
    const icon = this.state.alignRight ? <ChevronRightIcon label='expand' /> : <ChevronLeftIcon label='expand'/>;
    const iconItem = (
      <Tooltip content="Collapse">
        <ToolbarItem>
          <IconButton
            normalIcon={icon}
            hoverIcon={icon}
            onClick={this.onCollapseButtonClick}
          />
        </ToolbarItem>
      </Tooltip>
    );
    if (this.state.alignRight) {
      return (
        <LiftedPanel>
          <ToolbarRight>
            <ToolbarItem>
              {this.props.children}
            </ToolbarItem>
            {iconItem}
          </ToolbarRight>
        </LiftedPanel>
      );
    } else {
      return (
        <LiftedPanel>
          <ToolbarLeft>
            {iconItem}
            <ToolbarItem>
              {this.props.children}
            </ToolbarItem>
          </ToolbarLeft>
        </LiftedPanel>
      );
    }
  };

  renderMinimised = () => {
    const icon = this.state.icon ? this.state.icon : <MoreIcon label='more'/>;
    // const icon = <MoreIcon label='more'/>;
    return (
      <LiftedPanel>
        <Tooltip content={this.state.name}>
          <IconButton
            normalIcon={icon}
            hoverIcon={icon}
            onClick={this.onExpandButtonClick}
          />
        </Tooltip>
      </LiftedPanel>
    );
  };

  render() {
    if (this.props.children) {
      return this.state.expanded ? this.renderExpanded() : this.renderMinimised();
    } else {
      return null;
    }
  }

}
