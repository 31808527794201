import constants from '../../../commonbase/commonConstants';
import util from '../../../shared/util/Util';

const sceneFileName = `empty-state-scene.png`;
const noNotesFileName = `empty-state-no-notes.svg`;
const rainyCloudFileName = `empty-state-rainy-cloud.svg`;
const imageFileNames = [
  sceneFileName,
  noNotesFileName,
  rainyCloudFileName
];

export class EmptyStateImages {

  getAnyEmptyStateImage = () => {
    const index = util.getRandomInteger(0, imageFileNames.length - 1);
    const imageFileName = imageFileNames[index];
    return `${constants.appBaseUrl}/img/empty-state/${imageFileName}`;
  }

  getNoNotesEmptyStateImage = () => {
    return `${constants.appBaseUrl}/img/empty-state/${noNotesFileName}`;
  }

}

export default new EmptyStateImages();
