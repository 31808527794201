import React, { PureComponent } from 'react';
import { AsyncSelect } from '@atlaskit/select';
import definitionSearch from '../../../../shared/model/rubric/search/DefinitionSearch';
import definitionSelectContext from './DefinitionSelectContext';
import IconButton from '../../widget/IconButton';
import Label from '../../widget/Label';
import session from '../../../../shared/model/auth/Session';
import preferencesDAO from '../../../../backend/data/PreferencesDAO';
import selectUtil from '../../../../shared/util/SelectUtil';
// import Spinner from '@atlaskit/spinner';
import { SwitcherIcon } from '../../icon/NamedIcons';
import Tooltip from '@atlaskit/tooltip';
import promiseUtil from '../../../../commonbase/util/promiseUtil';
import Definition from '../../../../shared/model/rubric/definition/Definition';
import DefinitionResolver from '../../../../backend/data/DefinitionResolver';
import { SearchOptions } from '../../../../shared/model/rubric/search/SearchOptions';
import DefinitionSearchResult from '../../../../shared/model/rubric/definition/DefinitionSearchResult';
import ResolvedDefinitionSearchResult from '../../../../shared/model/rubric/definition/ResolvedDefinitionSearchResult';
import ToolbarJustify from '../../widget/toolbar/ToolbarJustify';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';

type Props = {
  label: string
  disabled?: boolean
  allowSwitcherIconToBeVisible?: boolean
  definitionResolver: DefinitionResolver
  initialDefinitionUuid: undefined | string
  autoFocus: boolean
  cacheKey?: string
  onSelect: (definition: Definition) => void
}

type State = {
  label: undefined | string
  showInitialDefinition?: boolean
  loadingOptions?: boolean
  allowSwitcherIconToBeVisible: boolean
  options?: any
  selectedDefinition?: Definition
  cacheKey?: string
}

export default class DefinitionSelect extends PureComponent<Props, State> {

  simpleMode = true;
  mounted = false;
  // cachedUuidsToDefinitions: any = {};
  cachedUuidsToDefinitionSearchResults: Map<string, DefinitionSearchResult> = new Map<string, DefinitionSearchResult>();
  state: any;
  selectElement: undefined | any = undefined;

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
    this.state.loadingOptions = false;
    this.state.showInitialDefinition = false;
    this.state.options = definitionSelectContext.getCurrentOptions();
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
    this.evaluateInitialDefinition(props);
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    this.evaluateInitialDefinition(this.props);
    definitionSelectContext.registerListener(this.onDefinitionSelectContextChange);
    const options = definitionSelectContext.getCurrentOptions();
    this.setState({
      options: options
    });
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focusOnSelect();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    definitionSelectContext.unregisterListener(this.onDefinitionSelectContextChange);
  };

  evaluateInitialDefinition = (props: Props) => {
    const initialDefinitionUuidPromise = this.state.cacheKey ?
      preferencesDAO.getPreference(session.getCurrentUser(), this.state.cacheKey, undefined) :
      promiseUtil.promiseReturning(props.initialDefinitionUuid);
    initialDefinitionUuidPromise.then((initialDefinitionUuid) => {
      if (initialDefinitionUuid === undefined) {
        return;
      } else if (this.state.initialDefinition && this.state.initialDefinition.uuid === initialDefinitionUuid) {
        return;
      }
      if (initialDefinitionUuid) {
        this.props.definitionResolver.getDefinitionByUuid(initialDefinitionUuid)
          .then((definition) => {
            this.applyState({
              showInitialDefinition: definition !== undefined,
              initialDefinition: definition
            });
          });
      }
    });
  };

  applyState = (stateDelta) => {
    if (this.mounted) {
      this.setState(stateDelta);
    }
  };

  focusOnSelect = () => {
    if (this.selectElement) {
      this.selectElement.focus();
    }
  };

  buildStateFromProps = (props: Props): State => {
    return {
      cacheKey: props.cacheKey,
      label: props.label ? props.label : undefined,
      allowSwitcherIconToBeVisible: props.allowSwitcherIconToBeVisible === undefined ? true : props.allowSwitcherIconToBeVisible
    };
  };

  loadOptions = async (searchText: string): Promise<any> => {
    const currentUser = session.getCurrentUser();
    if (!currentUser) {
      return promiseUtil.promiseReturning([]);
    }
    const userId = currentUser.getId();
    const searchOptions: SearchOptions = {
      searchString: searchText,
      maxResults: 20
    };
    // const loadingOptionsStartTime = new Date().getTime();
    this.setState({
      loadingOptions: true
    });
    // return definitionSearch.searchDefinitions(userId, searchOptions)
    //   .then((matchingDefinitions) => {
    //     const options: any = [];
    //     for (const definition of matchingDefinitions) {
    //       this.cachedUuidsToDefinitions[definition.uuid] = definition;
    //       const option = {label: definition.name, value: definition.uuid};
    //       options.push(option);
    //     }
    //     return options;
    //   })
    //   .finally(() => {
    //     // const loadingOptionsEndTime = new Date().getTime();
    //     // const millis = loadingOptionsEndTime - loadingOptionsStartTime;
    //     // console.log('millis:', millis);
    //     this.setState({
    //       loadingOptions: false
    //     });
    //   });

    try {
      const definitionSearchResults: DefinitionSearchResult[] = await definitionSearch.searchDefinitionsNew(userId, searchOptions);
      const options: any = [];
      for (const result of definitionSearchResults) {
        const definitionReference = result.getDefinitionReference();
        this.cachedUuidsToDefinitionSearchResults.set(definitionReference.definitionUuid, result);
        const option = {
          label: definitionReference.definitionName,
          value: definitionReference.definitionUuid
        };
        options.push(option);
      }
      return options;
    } catch (error) {
      console.error('Internal error whilst searching');
      debugger;
      this.setState({
        loadingOptions: false
      });
    }
  };

  addOption = (groups, groupLabel, option) => {
    const existingOption = selectUtil.findOption(groups, option.value);
    if (existingOption) {
      existingOption.label = option.label;
    } else {
      let group = selectUtil.findGroup(groups, groupLabel);
      if (!group) {
        group = {
          label: groupLabel,
          options: []
        };
        groups.push(group);
      }
      group.options.push(option);
    }
  };

  onDefinitionSelectContextChange = (rubricOptionGroups) => {
    if (this.mounted) {
      this.setState({
        options: rubricOptionGroups,
        loadingOptions: false
      });
    }
  };

  synthesiseClickToShowSelectDropdown = () => {
    // if (this.selectElement) {
    //   this.selectElement.click();
    // }
  }

  onSwitchModesButtonClick = () => {
    const hasInitialDefinition = this.state.initialDefinition;
    if (hasInitialDefinition) {
      this.setState({
        showInitialDefinition: !this.state.showInitialDefinition
      });
      setTimeout(this.synthesiseClickToShowSelectDropdown, 1000);
    } else {
      this.setState({
        showInitialDefinition: false
      });
    }
  };

  onChange = async (option): Promise<void> => {
    const definitionUuid = option.value as string;
    if (this.state.cacheKey) {
      const currentUser = session.getCurrentUser();
      preferencesDAO.setPreference(currentUser, this.state.cacheKey, definitionUuid);
    }
    if (this.props.onSelect) {
      // const definition = this.cachedUuidsToDefinitions[definitionUuid];
      const definitionSearchResult: undefined | DefinitionSearchResult =
        this.cachedUuidsToDefinitionSearchResults.get(definitionUuid);
      if (definitionSearchResult) {
        const definition = await definitionSearchResult.resolveDefinition();
        if (definition) {
          this.setState({
            selectedDefinition: definition
          });
          this.props.onSelect(definition);
        }
      } else {
        this.props.definitionResolver.getDefinitionByUuid(definitionUuid).then((definition) => {
          if (definition) {
            this.setState({
              selectedDefinition: definition
            });
            // this.cachedUuidsToDefinitions[definitionUuid] = definition;
            const definitionResolver = async (): Promise<undefined | Definition> => {
              return definition;
            }
            const definitionSearchResult = new ResolvedDefinitionSearchResult(definition.uuid, definition.name, definitionResolver);
            this.cachedUuidsToDefinitionSearchResults.set(definition.uuid, definitionSearchResult);
            this.props.onSelect(definition);
          }
        });
      }
    }
  };

  getOptionByDefinitionUuid = (definitionUuid, options) => {
    if (definitionUuid && options && options.length) {
      for (const optionGroup of options) {
        if (optionGroup.value === definitionUuid) {
          return optionGroup;
        } else {
          for (const option of optionGroup.options) {
            if (option.value === definitionUuid) {
              return option;
            }
          }    
        }
      }
    }
    return undefined;
  };

  renderSpinner = () => {
    return null;
    // return (
    //   <div style={{
    //     position: 'relative',
    //     zIndex: 1000,
    //     top: '12px',
    //     height: '0px',
    //     left: 'calc(100% - 50px)'
    //   }}>
    //     <Spinner size="small"/>
    //   </div>
    // );
  }

  renderSelect = () => {
    let value = undefined;
    if (this.state.selectedDefinition) {
      value = this.getOptionByDefinitionUuid(this.state.selectedDefinition.uuid, this.state.options);
    } else if (this.state.initialDefinition) {
      value = this.getOptionByDefinitionUuid(this.state.initialDefinition.uuid, this.state.options);
    }
        // key={`defs-for-${currentUser ? currentUser.getId() : ''}`}
    return (
      <AsyncSelect
        ref={ref => {
          this.selectElement = ref;
        }}
        openMenuOnClick={true}
        label={this.state.label}
        isDisabled={this.props.disabled}
        placeholder="Type the name of a rubric to select it..."
        className="async-select-with-callback"
        classNamePrefix="react-select"
        cacheOptions={false}
        defaultOptions={this.state.options}
        loadOptions={this.loadOptions}
        options={this.state.options}
        value={value}
        onChange={this.onChange}
      />
    );
  };

  renderSelectAndSpinner = () => {
    // if (this.props.disabled) {
    //   return null;
    // }
    return (
      <div>
        {this.state.loadingOptions ? this.renderSpinner() : null}
        {this.renderSelect()}
      </div>
    );
  }

  renderToggleModesToolbar = () => {
    if (this.props.disabled) {
      return null;
    }
    const showItem = this.state.allowSwitcherIconToBeVisible && this.state.initialDefinition;
    // const showItem = this.state.allowSwitcherIconToBeVisible && this.state.initialDefinitionUuid;
    if (showItem) {
      let tooltip = '';
      if (this.state.showInitialDefinition) {
        tooltip = 'Search for a rubric';
      } else {
        tooltip = 'Stay with last selected rubric';
      }
      return (
        <ToolbarItem>
          <Tooltip content={tooltip}>
            <IconButton
              disabled={this.props.disabled}
              normalIcon={<SwitcherIcon label="switch"/>}
              hoverIcon={<SwitcherIcon label="switch"/>}
              onClick={this.onSwitchModesButtonClick}
            />
          </Tooltip>
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderText = (text: string, opacity: number) => {
    return (
      <div style={{display: 'inline-block', height: '40px', position: 'relative', width: '100%'}}>
        <div style={{position: 'absolute', bottom: '10px', opacity: opacity}}>
          {text}
        </div>
      </div>
    );
  };

  renderInitialDefinition = () => {
    const opacity = this.props.disabled ? 0.6 : 1;
    return this.renderText(this.state.initialDefinition.name, opacity);
  };

  renderToolbar = () => {
    const hasInitialDefinition = this.state.initialDefinition;
    if (hasInitialDefinition) {
      const classes = this.state.showInitialDefinition ? `clickable` : undefined;
      const onClick = this.state.showInitialDefinition ? this.onSwitchModesButtonClick : undefined;
      const showSelect = !this.props.disabled && (!this.state.allowSwitcherIconToBeVisible || !this.state.showInitialDefinition);
      return (
        <ToolbarJustify className="widget-container">
          <ToolbarItem
            className={classes}
            style={{ flexGrow: 1, marginRight: '5px' }}
            onClick={onClick}
          >
            {showSelect ? this.renderSelectAndSpinner() : this.renderInitialDefinition()}
          </ToolbarItem>
          {this.renderToggleModesToolbar()}
        </ToolbarJustify>
      );
    } else {
      return this.renderSelectAndSpinner();
    }
  };

  renderSimpleSelect = () => {
    return this.renderSelectAndSpinner();

    // return this.state.showInitialDefinition ? this.renderInitialDefinition() : this.renderSelectAndSpinner();

  }

  render() {
    const content = this.simpleMode ? this.renderSimpleSelect() : this.renderToolbar();
    return (
      <div>
        <Label text={this.state.label} />
        {content}
      </div>
    );
  };

}
