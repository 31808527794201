import React, { PureComponent, ReactNode } from 'react';
import adg from '../../../../commonbase/adg';
import Board from '../../widget/kanban/Board';
import dashboardState from '../dashboard/DashboardState';
import currentRubric from "../../../../shared/model/rubric/CurrentRubric";
import DriveView from '../../drive/DriveView';
import EmptyState from '@atlaskit/empty-state';
import emptyStateImages from '../../widget/EmptyStateImages';
import globalSpinnerController from '../../../../shared/model/GlobalSpinnerController';
import IconButton from '../../widget/IconButton';
import InfoMessageBanner from '../../widget/banner/InfoMessageBanner';
import LiftedPageTitle from '../../widget/LiftedPageTitle';
import session from '../../../../shared/model/auth/Session';
import statusDefinitions from '../../../../shared/model/rubric/status/StatusDefinitions';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import Tooltip from '@atlaskit/tooltip';
import util from '../../../../shared/util/Util';
import { HasPermissionIcon, NoPermissionIcon } from '../../icon/NamedIcons';
import ToolbarJustify from '../../widget/toolbar/ToolbarLeft';
import ToolbarItem from '../../widget/toolbar/ToolbarItem';
import ToolbarRight from '../../widget/toolbar/ToolbarRight';
import { IBoard, ICard, IList } from '../../widget/kanban/BoardState';

interface Props {
  showHeading?: boolean
  size?: 'narrow' | 'wide'
}

interface State {
  board?: IBoard
  maxItemsPerGroup?: number
  showNonWritableAssessments?: boolean
  totalCardCount?: number
}

export default class BoardView extends PureComponent<Props, State> {

  myGlobalSpinnerUuid = util.uuid();
  mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  UNSAFE_componentWillMount() {
    session.registerListener(this.onSessionChange);
    dashboardState.registerListener(this.onDashboardStateChange);
  }

  componentDidMount() {
    this.mounted = true;
    if (!dashboardState.hasReceivedData()) {
      globalSpinnerController.startSpinning(this.myGlobalSpinnerUuid);
    }
    this.updateState(this.state);
  }

  componentWillUnmount() {
    this.mounted = false;
    globalSpinnerController.stopSpinning(this.myGlobalSpinnerUuid);
    session.unregisterListener(this.onSessionChange);
    dashboardState.unregisterListener(this.onDashboardStateChange);
  }

  buildStateFromProps = (props: Props): State => {
    const state: State = {
      maxItemsPerGroup: 12
    };
    if (!this.state) {
      state.showNonWritableAssessments = true;
    }
    return state;
  };

  onSessionChange = (user) => {
    this.updateState(this.state);
  };

  onDashboardStateChange = (stateFromDashboard) => {
    globalSpinnerController.stopSpinning(this.myGlobalSpinnerUuid);
    if (this.mounted) {
      this.updateState(this.state);
    }
  };

  onTogleShowNonWritableAssessmentsClick = () => {
    const stateDelta: State = {
      showNonWritableAssessments: !this.state.showNonWritableAssessments
    }
    this.updateState(this.state, stateDelta);
  };

  onCardLinkClick = (card: ICard): void => {
    this.onAssessmentClick(card.definitionUuid, card.assessmentUuid)
  }

  renderCard = (row: any) => {
    return (
      <div>
        <div>
          <div>Name:</div>
          <div>{row.name}</div>
        </div>
        <div>
          <div>Name:</div>
          <div>{row.name}</div>
        </div>
        <div>
          <div>Description:</div>
          <div>{row.description}</div>
        </div>
      </div>
    );
  };

  updateState = (originalState: State, stateDelta?: State) => {
    if (!this.mounted) {
      return;
    }
    const delta = stateDelta ? stateDelta : {};
    const state = Object.assign({}, originalState, delta);
    const receivedData = dashboardState.hasReceivedData();
    const lists: IList[] = [];
    const board: IBoard = {
      lists: lists
    };
    let totalCardCount = 0;
    if (receivedData) {
      const user = session.getCurrentUser();
      if (user) {
        const stateFromDashboard = dashboardState.getState();
        const statusIdsToLists = {};
        const statusIdsToConfigs = rubricUtil.getStatusIdsToConfigs(null, null);
        const statusIds = Object.keys(statusIdsToConfigs);
        for (const statusId of statusIds) {
          const config = statusIdsToConfigs[statusId];
          const list = {
            id: statusId,
            title: config.text,
            cards: []
          };
          lists.push(list);
          statusIdsToLists[statusId] = list;
        }
        for (const assessmentItem of stateFromDashboard.assessmentInfoLog) {
          if (assessmentItem.hasWriteAssessmentPermission || state.showNonWritableAssessments) {
            let statusId = assessmentItem.assessmentStatusId;
            if (!statusId) {
              statusId = statusDefinitions.getDefaultStatusId();
            }
            const list = statusIdsToLists[statusId];
            const assessmentScoreDetails = dashboardState.getAssessmentScoreDetails(assessmentItem.definitionUuid, assessmentItem.assessmentUuid);
            if (assessmentScoreDetails) {
              const scoreMeta = rubricUtil.getScoreMetaFromTypeAndInfo(
                assessmentScoreDetails.scoreMetaType, assessmentScoreDetails.customLevelsScoreInfo);
              const card: ICard = {
                definitionUuid: assessmentItem.definitionUuid,
                assessmentUuid: assessmentItem.assessmentUuid,
                cardTitle: assessmentItem.definitionName,
                cardSubtitle: assessmentItem.assessmentName,
                description: assessmentItem.definitionDescription,
                scoreMeta: scoreMeta,
                assessmentStatusId: assessmentItem.assessmentStatusId,
                assessmentScore: assessmentItem.assessmentScore,
                allowDrag: assessmentItem.hasWriteAssessmentPermission,
              };
              list.cards.push(card);
              totalCardCount++;
            } else {
              // TODO ????
            }
          }
        }
      }
    }
    state.board = board;
    state.totalCardCount = totalCardCount;
    this.setState(state);
  };

  onAssessmentClick = (definitionUuid, assessmentUuid) => {
    const inEditMode = false;
    currentRubric.setDefinitionAndAssessmentUuids(definitionUuid, assessmentUuid, inEditMode);
  };

  // setDefinitionAndAssessmentUuids = (definitionUuid: string, assessmentUuid: string, inEditMode: boolean, assessmentLoader: AssessmentLoader) => {
  onCardDragEnd = (board: IBoard, card: ICard, source, destination) => {
    // Check user can update the assessment.
  };

  onDragComplete = (card: ICard, toList: IList) => {
    const definitionUuid = card.definitionUuid;
    const assessmentUuid = card.assessmentUuid;
    const toStatusId = toList.id;
    if (toStatusId !== card.assessmentStatusId) {
      globalSpinnerController.startSpinning(this.myGlobalSpinnerUuid);
      dashboardState.setAssessmentStatus(definitionUuid, assessmentUuid, toStatusId);  
    }
  };

  renderEmptyState = (headerMessage: string, renderPrimaryAction?: ReactNode, renderTertiaryAction?: ReactNode) => {
    const description = ``;
    const size = this.props.size ? this.props.size : 'wide';
    const dimensions = size === 'narrow' ? {width: 450, height: 100} : {width: 900, height: 200};
    return (
      <EmptyState
        header={headerMessage}
        description={description}
        imageUrl={emptyStateImages.getAnyEmptyStateImage()}
        size={size}
        maxImageWidth={dimensions.width}
        maxImageHeight={dimensions.height}
        primaryAction={renderPrimaryAction}
        tertiaryAction={renderTertiaryAction}
      />
    );
  };

  renderKickstartAssessentCreationView = () => {
    return (
      <div>
        <InfoMessageBanner>
          Select a rubric from your drive, then create an assessment against it.
        </InfoMessageBanner>
        <DriveView
          showHeading={false}
          showEmptyFoldersMessage={false}
          showEditTools={false}
        />
      </div>
    );
  }

  renderBoard = () => {
    if (this.state.board && this.state.board.lists && this.state.board.lists.length) {
      if (this.state.totalCardCount) {
        return (
          <Board
            renderCard={this.renderCard}
            lists={this.state.board.lists}
            onDragComplete={this.onDragComplete}
            onCardLinkClick={this.onCardLinkClick}
          />
        );
      } else {
        return this.renderEmptyState('You don\'t have any assessments yet...', this.renderKickstartAssessentCreationView());
      }
    } else {
      return this.renderEmptyState('Loading your board...');
    }
  }

  renderToggleNonWritableAssessmentsButton = () => {
    const tooltip = `Click to ${this.state.showNonWritableAssessments ? 'hide' : 'show'} assessments that you can't update`;
    return (
      <Tooltip content={tooltip}>
        <div className="">
          <IconButton
            normalIcon={this.state.showNonWritableAssessments ? <HasPermissionIcon label="no permission"/> : <NoPermissionIcon label="no permission"/>}
            hoverIcon={this.state.showNonWritableAssessments ? <NoPermissionIcon label="has permission"/> : <HasPermissionIcon label="has permission"/>}
            normalColor={this.state.showNonWritableAssessments ? adg.adgBlue : adg.adgRed}
            hoverColor={this.state.showNonWritableAssessments ? adg.adgRed : adg.adgBlue}
            onClick={this.onTogleShowNonWritableAssessmentsClick}
          />
        </div>
      </Tooltip>
    )
  };

  renderToolbar = () => {
    return (
      <ToolbarRight>
        <ToolbarItem>
          {this.renderToggleNonWritableAssessmentsButton()}
        </ToolbarItem>
      </ToolbarRight>
    );
  };

  renderHeading = () => {
    const listBorderWidth = '4px';
    return (
      <div style={{marginLeft: listBorderWidth}}>
        <LiftedPageTitle>Assessment board</LiftedPageTitle>
      </div>
    );
  };

  render() {
    return (
      <div>
        <ToolbarJustify>
          <ToolbarItem>
            {this.props.showHeading ? this.renderHeading() : null}
          </ToolbarItem>
          <ToolbarItem>
            {this.renderToolbar()}
          </ToolbarItem>
        </ToolbarJustify>
        {this.renderBoard()}
      </div>
    );
  }
}
