import React from "react";
import IconButton from './IconButton';
import SafeWidget from './SafeWidget';

export default class SafePopupButton extends SafeWidget {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    const parentState = this.buildSafeWidgetStateFromProps(props);
    const thisState = {
      iconBefore: props.iconBefore,
    };
    return Object.assign(parentState, thisState);
  };

  renderNormalState = () => {
    return (
      <IconButton
        normalIcon={this.state.iconBefore}
        hoverIcon={this.state.iconBefore}
        onClick={this.onEnterDangerState}
      />
    );
  };

}
