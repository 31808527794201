import analytics from '../../shared/model/analytics/Analytics';
import collectionsUtil from '../../commonbase/util/collectionsUtil';
import commonConstants from '../../commonbase/commonConstants';
import firebaseApp from '../firebase/FirebaseApp';
import dataStats from './DataStats';
import discoverabilityDefinitions from '../../shared/model/rubric/DiscoverabilityDefinitions';
import roleDefinitions from '../../shared/model/auth/RoleDefinitions';
import session from '../../shared/model/auth/Session';
import Definition from '../../shared/model/rubric/definition/Definition';
import Assessment from '../../shared/model/rubric/score/Assessment';
import I18N from '../../shared/model/i18n/I18N';
import RubricPersistence from './RubricPersistence';
import DeletionResult from './DeletionResult';
import { SearchOptions } from '../../shared/model/rubric/search/SearchOptions';
import AssessmentSnapshot from '../../shared/model/rubric/score/AssessmentSnapshot';
import DefinitionSearchResult from '../../shared/model/rubric/definition/DefinitionSearchResult';
import ResolvedDefinitionSearchResult from '../../shared/model/rubric/definition/ResolvedDefinitionSearchResult';

export const dataSourceId = 'firestore-definitions-collection';

export default class RubricFirestorePersistence implements RubricPersistence {

  definitionAnalyticsEntityName = 'definition';
  assessmentAnalyticsEntityName = 'assessment';
  assessmentSnapshotAnalyticsEntityName = 'assessment-snapshot';

  definitionsCollectionKey: string;
  assessmentsCollectionKey: string;
  assessmentSnapshotsCollectionKey: string;

  constructor() {
    this.definitionsCollectionKey = 'definitions';
    this.assessmentsCollectionKey = 'assessments';
    this.assessmentSnapshotsCollectionKey = 'snapshots';
  }

  getDataSourceId = (): string => {
    return dataSourceId;
  }

  getDataSourceName = (): string => {
    return commonConstants.appTitle;
  }

  saveDefinition = (definition) => {
    dataStats.logFirestoreReadWrite();
    analytics.databaseWrite(this.definitionAnalyticsEntityName);
    const clonedDefinition: Definition = {
      uuid: definition.uuid,
      editability: definition.editability,
      name: definition.name.trim(),
      nameLower: definition.name.toLowerCase(),
      aspectHeaderName: definition.aspectHeaderName ? definition.aspectHeaderName : I18N.Area,
      description: definition.description,
      ownerId: definition.ownerId,
      ownerEmailDomain: definition.ownerEmailDomain,
      allowForking: definition.allowForking,
      discoverability: definition.discoverability,
      labels: definition.labels ? definition.labels : [],
      statementUuidsToLabels: definition.statementUuidsToLabels ? definition.statementUuidsToLabels : {},
      options: definition.options,
      metadata: definition.metadata ? definition.metadata : {},
      style: definition.style,
      columns: definition.columns,
      groups: definition.groups,
      updateTimestamp: definition.updateTimestamp
    };
    if (definition.customLevelsScoreInfo) {
      clonedDefinition.customLevelsScoreInfo = definition.customLevelsScoreInfo;
    }
    return firebaseApp
      .getFirestore()
      .collection(this.definitionsCollectionKey)
      .doc(definition.uuid)
      .set(clonedDefinition)
      .then(() => {
        return definition;
      })
      .catch((error) => {
        console.error("Error writing definition: ", error);
        // debugger;
      });
  };

  deleteDefinition = (definition) => {
    dataStats.logFirestoreDelete();
    analytics.databaseDelete(this.definitionAnalyticsEntityName);
    return firebaseApp
      .getFirestore()
      .collection(this.definitionsCollectionKey)
      .doc(definition.uuid)
      .delete();
  };

  getDefinitionByUuid = (definitionUuid: string): Promise<undefined | Definition> => {
    return firebaseApp
      .getFirestore()
      .collection(this.definitionsCollectionKey)
      .doc(definitionUuid)
      .get()
      .then((doc) => {
        dataStats.logFirestoreRead();
        analytics.databaseRead(this.definitionAnalyticsEntityName);
        if (doc.exists) {
          const definition = doc.data() as Definition;
          // console.log("definition:", definition);
          return definition;
        } else {
          // console.log('Definition with UUID', definitionUuid, 'not found.');
          return undefined;
        }
      })
      .catch((error) => {
        console.error("Error reading definition: ", error);
        // debugger;
      }) as Promise<undefined | Definition>;
  };

  // TODO: moveThis
  // saveAssessment = (assessment) => {
  //   dataStats.logFirestoreReadWrite();
  //   analytics.databaseWrite(this.assessmentAnalyticsEntityName);
  //   return firebaseApp
  //     .getFirestore()
  //     .collection(this.assessmentsCollectionKey)
  //     .doc(assessment.uuid)
  //     .set(assessment)
  //     .then(() => {
  //       //console.log("Assessment successfully written!");
  //       return assessment;
  //     })
  //     .catch((error) => {
  //       console.error("Error writing assessment: ", error);
  //       // debugger;
  //     });
  // };

  // TODO: moveThis
  saveAssessmentSnapshot = (assessmentSnapshot: AssessmentSnapshot): Promise<AssessmentSnapshot> => {
    dataStats.logFirestoreReadWrite();
    analytics.databaseWrite(this.assessmentSnapshotAnalyticsEntityName);
    const assessmentUuid = assessmentSnapshot.uuid;
    const timestamp = assessmentSnapshot.updateTimestamp;
    // debugger;
    return firebaseApp
      .getFirestore()
      .collection(this.assessmentsCollectionKey)
      .doc(assessmentUuid)
      .collection(this.assessmentSnapshotsCollectionKey)
      .doc('ts-' + timestamp)
      .set(assessmentSnapshot)
      .then(() => {
        //console.log("Assessment successfully written!");
        return assessmentSnapshot as AssessmentSnapshot;
      });
      // .catch((error) => {
      //   console.error("Error writing assessmentSnapshot: ", error);
      //   // debugger;
      // });
  };

  getAssessmentSnapshots = (assessmentUuid: string): Promise<AssessmentSnapshot[]> => {
    const user = session.getCurrentUser();
    if (!user) {
      throw new Error('User must be logged in');
    }
    const email = user.getEmail();
    const emailDomain = user.getEmailDomain();
    const anyReaderRole = '*>>>>' + roleDefinitions.readerRoleType;
    const domainReaderRole = '*@' + emailDomain + '>>>>' + roleDefinitions.readerRoleType;
    const exactReaderRole = email + '>>>>' + roleDefinitions.readerRoleType;
    const maxResults = 100;
    return firebaseApp
      .getFirestore()
      .collection(this.assessmentsCollectionKey)
      .doc(assessmentUuid)
      .collection(this.assessmentSnapshotsCollectionKey)
      .where('uuid', '==', assessmentUuid)
      .where('roles', 'array-contains-any', [anyReaderRole, domainReaderRole, exactReaderRole])
      .orderBy('updateTimestamp', 'asc')
      .limit(maxResults)
      .get()
      .then((querySnapshot: any) => {
        const assessmentSnapshots: AssessmentSnapshot[] = [];
        if (!querySnapshot.empty) {
          dataStats.logFirestoreReads(querySnapshot.length);
          querySnapshot.forEach(function(doc) {
            if (assessmentSnapshots.length < maxResults) {
              const assessmentSnapshot = doc.data();
              assessmentSnapshots.push(assessmentSnapshot as AssessmentSnapshot);
            }
          });
        }
        return assessmentSnapshots;
      });
  };

  deleteAssessment = (assessment: Assessment): Promise<DeletionResult> => {
    dataStats.logFirestoreDelete();
    analytics.databaseDelete(this.assessmentAnalyticsEntityName);
    const payload = {
      assessmentUuid: assessment.uuid
    };
    const functions = firebaseApp.getFunctions();
    const deleteFn: (payload: any) => Promise<any> = functions.httpsCallable('recursiveDeleteAssessment');
    return deleteFn(payload)
      .then((result) => {
        //console.log('Delete success: ' + JSON.stringify(result));
        return {deleted: true};
      })
      .catch((error) => {
        console.error('Delete failed:', error);
        // debugger;
        console.warn(error);
        return {deleted: false};
      });
  };

  getAssessmentByUuid = (assessmentUuid: string, trapError?: boolean): Promise<undefined | Assessment> => {
    // debugger;
    return firebaseApp
      .getFirestore()
      .collection(this.assessmentsCollectionKey)
      .doc(assessmentUuid)
      .get()
      .then((doc) => {
        dataStats.logFirestoreRead();
        analytics.databaseRead(this.assessmentAnalyticsEntityName);
        return this._assessmentDocToAssessment(doc);
      })
      .catch((error) => {
        if (trapError) {
          console.warn("Error reading assessment: ", error);
          // debugger;
        } else {
          throw error;
        }
      });
  };

  getAssessmentsByUuids = (assessmentUuids, querySemantics): Promise<undefined | Assessment []> => {
    // Can't use .where('uuid', 'in', assessmentUuids) because it can't
    // be combined with array-contains-any.
    if (assessmentUuids && assessmentUuids.length) {
      const promises: Promise<undefined | Assessment>[] = [];
      for (const assessmentUuid of assessmentUuids) {
        // If using querySemantics we set trapError to true so that the inability to retrieve
        // one assessment (e.g. due to being missing or change of permissions) doesn't affect 
        // the returning of other assessments.
        const trapError = querySemantics;
        const promise = this.getAssessmentByUuid(assessmentUuid, trapError);
        promises.push(promise);
      }
      return Promise.all(promises)
        .then((assessments) => {
          return collectionsUtil.filterNilsFromArray(assessments);
        });
    } else {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  };

  _assessmentDocToAssessment = (doc) => {
    if (doc.exists) {
      const assessment = doc.data();
      //console.log("assessment:", assessment);
      return assessment;
    } else {
      console.warn("No such assessment!");
      return null;
    }
  };

  // getAssessmentsByDefinitionUuidAndUser = (definitionUuid, user, includeArchivedAssessments) => {
  //   // const user = session.getCurrentUser();
  //   const email = user.getEmail();
  //   const emailDomain = user.getEmailDomain();
  //   const anyReaderRole = '*>>>>' + roleDefinitions.readerRoleType;
  //   const domainReaderRole = '*@' + emailDomain + '>>>>' + roleDefinitions.readerRoleType;
  //   const exactReaderRole = email + '>>>>' + roleDefinitions.readerRoleType;
  //   const rolesToSearch = [anyReaderRole, domainReaderRole, exactReaderRole];
  //   let query = firebaseApp
  //     .getFirestore()
  //     .collection(this.assessmentsCollectionKey)
  //     .where('definitionUuid', '==', definitionUuid)
  //     .where('roles', 'array-contains-any', rolesToSearch);
  //   if (!includeArchivedAssessments) {
  //     query = query.where('archivedTimestamp', '==', 0);
  //   }
  //   return query
  //     .get()
  //     .then((querySnapshot) => {
  //       const assessments: undefined | Assessment [] = [];
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         const assessment = doc.data();
  //         // debugger;
  //         assessments.push(assessment as Assessment);
  //       });
  //       dataStats.logFirestoreReads(assessments.length);
  //       analytics.databaseRead(this.assessmentAnalyticsEntityName, assessments.length);
  //       return assessments;
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       // debugger;
  //       throw error;
  //     });
  // };

  searchDefinitions = async (currentUserId: string, canReadDefinition: (definition: Definition) => boolean, searchOptions: SearchOptions) => {
    const searchString = searchOptions && searchOptions.searchString ? searchOptions.searchString : '';
    const maxResults = searchOptions.maxResults > 0 ? searchOptions.maxResults : 10;
    let minString: undefined | string = undefined;
    let maxString: undefined | string = undefined;
    if (searchString) {
      const searchStringLower = searchString.toLowerCase();
      minString = searchStringLower;
      const lastChar = searchStringLower.charAt(searchStringLower.length - 1);
      const lastCharCode = lastChar.charCodeAt(0);
      const nextChar = String.fromCharCode(lastCharCode + 1);
      maxString = searchStringLower.substring(0, searchStringLower.length - 1) + nextChar;
    }
    if (searchOptions && searchOptions.domainVisible) {
      const publicDefinitionsPromise = this._searchDefinitions(
        currentUserId, canReadDefinition, minString, maxString, this._addIsPublicOption, maxResults);
      const domainDefinitionsPromise = this._searchDefinitions(
        currentUserId, canReadDefinition, minString, maxString, this._addIsSameEmailDomainOption, maxResults);
      return this._combineDefinitionsOf2Results(publicDefinitionsPromise, domainDefinitionsPromise);
    } else {
      const ownerDefinitionsPromise = this._searchDefinitions(
        currentUserId, canReadDefinition, minString, maxString, this._addIsOwnerOption, maxResults);
      const publicDefinitionsPromise = this._searchDefinitions(
        currentUserId, canReadDefinition, minString, maxString, this._addIsPublicOption, maxResults);
      const domainDefinitionsPromise = this._searchDefinitions(
        currentUserId, canReadDefinition, minString, maxString, this._addIsSameEmailDomainOption, maxResults);
      return this._combineDefinitionsOf3Results(ownerDefinitionsPromise, publicDefinitionsPromise, domainDefinitionsPromise);
    }
  };

  searchDefinitionsNew = async (currentUserId: string, canReadDefinition: (definition: Definition) => boolean, searchOptions: SearchOptions): Promise<DefinitionSearchResult[]> => {
    const definitions: Definition[] = await this.searchDefinitions(currentUserId, canReadDefinition, searchOptions);
    const results: DefinitionSearchResult[] = [];
    for (const definition of definitions) {
      const definitionResolver = async (): Promise<undefined | Definition> => {
        return definition;
      }
      const result: DefinitionSearchResult = new ResolvedDefinitionSearchResult(
        definition.uuid, definition.name, definitionResolver
      );
      results.push(result);
    }
    return results;
  };

  searchRecommendedDefinitions = (currentUserId, canReadDefinition, maxResults) => {
    const minString = undefined;
    const maxString = undefined;
    return this._searchDefinitions(
      currentUserId, canReadDefinition, minString, maxString, this._addIsPublicOption, maxResults);
  };

  _combineDefinitionsOf2Results = (definitionsAPromise, definitionsBPromise) => {
    return Promise.all([definitionsAPromise, definitionsBPromise])
      .then((results) => {
        const definitionsA = results[0];
        const definitionsB = results[1];
        const definitionsAB = this._combineDefinitions(definitionsA, definitionsB);
        return definitionsAB;
      });
  };

  _combineDefinitionsOf3Results = (definitionsAPromise, definitionsBPromise, definitionsCPromise) => {
    return Promise.all([definitionsAPromise, definitionsBPromise, definitionsCPromise])
      .then((results) => {
        const definitionsA = results[0];
        const definitionsB = results[1];
        const definitionsC = results[2];
        const definitionsAB = this._combineDefinitions(definitionsA, definitionsB);
        const definitionsABC = this._combineDefinitions(definitionsAB, definitionsC);
        return definitionsABC;
      });
  };

  _combineDefinitions = (definitionsA, definitionsB): Definition[] => {
    const allDefinitions: Definition[] = [];
    const addedUuids = {};
    for (const definition of definitionsA) {
      if (!addedUuids[definition.uuid]) {
        allDefinitions.push(definition);
        addedUuids[definition.uuid] = true;
      }
    }
    for (const definition of definitionsB) {
      if (!addedUuids[definition.uuid]) {
        allDefinitions.push(definition);
        addedUuids[definition.uuid] = true;
      }
    }
    return allDefinitions;
  };

  _addIsOwnerOption = (query, currentUserId) => {
    return query.where('ownerId', '==', currentUserId);
  };

  _addIsPublicOption = (query, currentUserId) => {
    return query.where('discoverability', '==', discoverabilityDefinitions.publicDiscoverabilityType);
  };

  _addIsSameEmailDomainOption = (query, currentUserId) => {
    const currentUser = session.getCurrentUser();
    if (!currentUser) {
      throw new Error('User must be logged in');
    }
    const emailDomain = currentUser.getEmailDomain();
    return query
      .where('ownerEmailDomain', '==', emailDomain)
      .where('discoverability', '==', discoverabilityDefinitions.emailDomainDiscoverabilityType);
  };

  _searchDefinitions = (currentUserId, canReadDefinition, minString, maxString, addOrOption, maxResults) => {
    let query: any = firebaseApp
      .getFirestore()
      .collection(this.definitionsCollectionKey);
    if (minString !== undefined) {
      query = query.where('nameLower', '>=', minString);
    }
    if (maxString !== undefined) {
      query = query.where('nameLower', '<', maxString);
    }
    if (addOrOption) {
      query = addOrOption(query, currentUserId);
    }
    return query
      .limit(maxResults)
      .get()
      .then((querySnapshot: any) => {
        // console.log('querySnapshot.query:',querySnapshot.query);
        // console.log('querySnapshot.metadata:',querySnapshot.metadata);
        const definitions: Definition[] = [];
        if (querySnapshot.empty) {

        } else {
          dataStats.logFirestoreReads(querySnapshot.length);
          querySnapshot.forEach(function(doc) {
            if (maxResults > 0 && definitions.length < maxResults) {
              const definition = doc.data();
              let matches = canReadDefinition(definition);
              if (matches) {
                definitions.push(definition);
              }
            }
          });
        }
        return definitions;
      });
  };

};
