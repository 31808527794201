import sessionStorage from './SessionStorage';
import userConstants from '../../../backend/data/canned/UserConstants';

export default class BrowserAuth {

  onAuthStateChanged: Function;

  constructor(onAuthStateChanged: Function) {
    this.onAuthStateChanged = onAuthStateChanged;
    sessionStorage.getUser().then((user) => {
      if (user) {
        // this.setCurrentUser(user);
        this.onAuthStateChanged(user)
      }
    });
  }

  getMockUsers = () => {
    return userConstants.users;
  };

  onMockUserLogin = (user) => {
    return this.setCurrentUser(user).then(this.onAuthStateChanged(user));
  };

  onLoginWithGoogleButtonClick = (): Promise<undefined> => {
    return new Promise((resolve, reject) => {
      const user = userConstants.fooUser;
      this.setCurrentUser(user);
      this.onAuthStateChanged(user);
      resolve(undefined);
    });
  };

  signOut = () => {
    return this.setCurrentUser(null).then(this.onAuthStateChanged(null));
  };

  setCurrentUser = (currentUser) => {
    return sessionStorage.setUser(currentUser);
  };

  getCurrentUser = () => {
    return sessionStorage.getUser();
  };

}
