import React, { FormEvent, KeyboardEvent, PureComponent } from 'react';
import actions from '../../shared/actions/Actions';
import adg from '../../commonbase/adg';
import AspectBarChart from '../components/rubric/chart/AspectBarChart';
import AssessmentSelector from '../components/rubric/assessment/AssessmentSelector';
import assessmentUtil from '../../shared/model/rubric/score/AssessmentUtil';
import backgroundImageUtil from '../components/widget/media/BackgroundImageUtil';
import Button from '@atlaskit/button';
import ChartModel from '../components/rubric/chart/ChartModel';
import ChartLabel from '../components/rubric/chart/ChartLabel';
import { CheckboxSelect } from '@atlaskit/select';
import { Checkbox } from '@atlaskit/checkbox';
import CollaboratorsView from '../components/auth/CollaboratorsView';
import ContentWrapper from '../components/widget/ContentWrapper';
import ContainerDimensions from 'react-container-dimensions';
import currentDialog from '../components/widget/CurrentDialog';
import currentReport from '../../shared/model/rubric/CurrentReport';
import currentRubric from '../../shared/model/rubric/CurrentRubric';
import currentUserPlan from '../../backend/payment/CurrentUserPlan';
import currentNotifications from '../../backend/data/CurrentNotifications';
import dateUtil from '../../commonbase/util/dateUtil';
import RubricReferenceBuilder from '../../shared/model/rubric/RubricReferenceBuilder';
import DeleteRubricButton from '../components/rubric/DeleteRubricButton';
import EmptyState from '@atlaskit/empty-state';
import ExpandingText from '../components/widget/ExpandingText';
import ExpandingToolbar from '../components/widget/ExpandingToolbar';
import BigErrorModal2x from '../images/BigErrorModal2x.png';
import discoverabilityDefinitions from '../../shared/model/rubric/DiscoverabilityDefinitions';
import driveGraph from '../components/drive/DriveGraph';
import featureFlags from '../../shared/model/feature/FeatureFlags';
import FolderSelectorDialog from '../components/drive/FolderSelectorDialog';
import firestoreAssessmentDAO from '../../backend/data/FirestoreAssessmentDAO';
import HistoryChart from '../components/rubric/chart/HistoryChart';
import i18n from '../../shared/model/i18n/I18N';
import IconButton from '../components/widget/IconButton';
import Label from '../components/widget/Label';
import MultiPieChart from '../components/rubric/chart/MultiPieChart';
import navConstants from '../components/nav/NavConstants';
import urlUtil from '../../shared/util/UrlUtil';
import notificationUtil from '../../shared/model/email/NotificationUtil';
import LiftedPageTitle from '../components/widget/LiftedPageTitle';
import LiftedPanel from '../components/widget/LiftedPanel';
import permissionUtil from "../../shared/model/auth/PermissionUtil";
import preferencesDAO from '../../backend/data/PreferencesDAO';
import rubricDAO from '../../backend/data/RubricDAO';
import rubricPageHelper from './RubricPageHelper';
import RubricViewer from '../components/rubric/RubricViewer';
import rubricViewerPreferences from '../components/rubric/RubricViewerPreferences';
import rubricRenderingUtil from "../components/rubric/RubricRenderingUtil";
import rubricUtil from "../../shared/model/rubric/RubricUtil";
import roleDefinitions from "../../shared/model/auth/RoleDefinitions";
import session from '../../shared/model/auth/Session';
import SafePopupButton from "../components/widget/SafePopupButton";
import scrollToComponent from 'react-scroll-to-component';
import ShareAssessmentForm from '../components/rubric/share/ShareAssessmentForm';
import ShareRubricForm from '../components/rubric/share/ShareRubricForm';
import shareStateDefinitions from "../../shared/model/rubric/ShareStateDefinitions";
import Spinner from '@atlaskit/spinner';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { statusDefinitions, StatusProvider } from '../../shared/model/status/Status';
import util from '../../shared/util/Util';
import Tooltip from '@atlaskit/tooltip';
import widgetUtil from '../components/widget/WidgetUtil';
import {
  AddAssessmentIcon,
  ChartIcon,
  CollaboratorsIcon,
  BoardIcon,
  EditRubricFilledIcon,
  EditRubricIcon,
  InfoIcon,
  CopyRubricIcon,
  SubmitNameIcon,
  EditNameIcon,
  EditNameFilledIcon,
  FolderIcon,
  FolderFilledIcon,
  LikeIcon,
  // LabelIcon,
  PieChartIcon,
  BarChartIcon,
  TimeChartIcon,
  PreferencesIcon,
  PrivateIcon,
  PublicIcon,
  ShareAssessmentIcon,
  ShareRubricIcon,
  StarIcon,
  StarFilledIcon,
  ViewAssessmentIcon,
  DeleteAssessmentIcon,
  VisibilityIcon,
  VisibilityFilledIcon,
  WarningIcon,
  SuccessIcon
}
from '../components/icon/NamedIcons';
import UserOnboarding from '../components/onboarding/UserOnboarding';
import Definition from '../../shared/model/rubric/definition/Definition';
import Assessment from '../../shared/model/rubric/score/Assessment';
import RubricAssessmentBuilder from '../../shared/model/rubric/RubricAssessmentBuilder';
import ConfluenceExport from '../components/integration/confluence/export/ConfluenceExport';
import Toolbar from '../components/crm/view/Toolbar';
import AssessmentDAI from '../../backend/data/AssessmentDAI';
import FormlessTextField from '../components/widget/FormlessTextField';
import AspectGroupingSelector from '../components/rubric/chart/AspectGroupingSelector';
import SelectOption from '../../shared/model/widget/SelectOption';
import Contact from '../../shared/model/network/Contact';
import DefinitionPreferences from '../../shared/model/rubric/preference/DefinitionPreferences';
import DefinitionPreferencesUpdateContext from '../../shared/model/rubric/preference/DefinitionPreferencesUpdateContext';
import I18N from '../../shared/model/i18n/I18N';
import DriveFolder from '../../shared/model/drive/DriveFolder';
import ToolbarJustify from '../components/widget/toolbar/ToolbarJustify';
import ToolbarItem from '../components/widget/toolbar/ToolbarItem';

interface Props {
  size?: 'narrow' | 'wide'
  history?: any
}

interface State {
  loading?: boolean,
  selectingDriveFolder?: boolean,
  assessmentOperationsVisible?: boolean,
  showDefinitionPreferences?: boolean,
  assessmentCollaboratorsPanelVisible?: boolean,
  autoChartShowingEvaluationComplete?: boolean,
  chartsEnabled?: boolean,
  showCharts?: boolean,
  showHistoryChart?: boolean,
  chartTypeIsPie?: boolean,
  definition?: any,
  scoreMeta?: any,
  chartModel?: any,
  aspectGrouping?: any,
  selectedAssessment?: any,
  selectedAssessmentName?: string,
  readableAssessments?: any[],
  editableAssessments?: any[],
  definitionPreferences: DefinitionPreferences,
  columnVisibilities?: any[],
  visibleColumnCount?: number,
  visibleLabels?: string[],
  visibleAssessments: Assessment[],
  viewAssessmentsSideBySide?: boolean,
  assessmentChangeCount?: number,
  statementUuidsToGuidanceVisibilities?: any,
  inSecondHalfOfTrialPeriod?: boolean
  canViewOrCreateAssessments?: boolean
  hideCompletedAspects?: boolean
  hideZeroScores?: boolean
  hideColumnTotals?: boolean
  driveFolderFileType?: any
  inEditAssessmentNameMode?: boolean
}

export default class RubricPage extends PureComponent<Props, State> {

  assessmentDAO: AssessmentDAI = firestoreAssessmentDAO;
  statusProvider: StatusProvider;
  assessmentNameInputRef: any;
  allowAssessmentToolbarHiding: boolean;
  outstandingChartModelUpdates: number;
  _assessmentChangeSubscriptions: undefined | any[];
  assessmentChangeRegistrationTime: undefined | number;
  lastAssessmentChangeFlagTimestamp: undefined | number;
  flagId: undefined | any;
  definitionPreferencesElement: undefined | any;
  newAssessmentName: undefined | string;

  constructor(props) {
    super(props);
    this.statusProvider = new StatusProvider('RubricPage');
    this.assessmentNameInputRef = React.createRef();
    this.allowAssessmentToolbarHiding = false;
    this.state = {
      loading: true,
      selectingDriveFolder: false,
      assessmentOperationsVisible: true,
      showDefinitionPreferences: false,
      assessmentCollaboratorsPanelVisible: false,
      autoChartShowingEvaluationComplete: false,
      chartsEnabled: false,
      showCharts: false,
      showHistoryChart: false,
      chartTypeIsPie: false,
      definition: undefined,
      chartModel: undefined,
      aspectGrouping: undefined,
      selectedAssessment: undefined,
      selectedAssessmentName: undefined,
      readableAssessments: [],
      editableAssessments: [],
      definitionPreferences: {},
      columnVisibilities: [],
      visibleColumnCount: 0,
      visibleLabels: [],
      visibleAssessments: [],
      viewAssessmentsSideBySide: false,
      assessmentChangeCount: 0,
      statementUuidsToGuidanceVisibilities: {}
    };
    this.outstandingChartModelUpdates = 0;
  }

  UNSAFE_componentWillMount() {
    const inSecondHalfOfTrialPeriod = currentUserPlan.isInSecondHalfOfTrialPeriod();
    const canViewOrCreateAssessments = permissionUtil.canViewOrCreateAssessments();
    this.setState({
      inSecondHalfOfTrialPeriod: inSecondHalfOfTrialPeriod,
      canViewOrCreateAssessments: canViewOrCreateAssessments
    });
    this.changeCurrentDefinition(false);
  }

  componentDidMount() {
    rubricPageHelper.updateTitle('Rubric');
    this.statusProvider.setActive(true);
    currentRubric.registerListener(this.onCurrentRubricChange);
    session.registerListener(this.onSessionChange);
    window.addEventListener('hashchange', this.onHashChange, false);
    currentUserPlan.registerListener(this.onCurrentUserPlanChange);
  };

  componentWillUnmount() {
    backgroundImageUtil.setBackgroundImageBasedOnUserPreferences();
    this.statusProvider.setActive(false);
    currentRubric.unregisterListener(this.onCurrentRubricChange);
    session.unregisterListener(this.onSessionChange);
    currentUserPlan.unregisterListener(this.onCurrentUserPlanChange);
    window.removeEventListener('hashchange', this.onHashChange, false);
    this._unregisterRemoteAssessmentChangeListeners();
    if (this.state.visibleAssessments) {
      for (const assessment of this.state.visibleAssessments) {
        const assessmentScore = rubricUtil.computeOverallAssessmentScore(this.state.definition, assessment);
        const assessmentContext = {
          definition: this.state.definition,
          assessment: assessment,
          assessmentScore: assessmentScore
        };
        actions.onAssessmentLeave(assessmentContext);
      }
    }
  };

  _registerRemoteAssessmentChangeListeners = (assessments) => {
    this._unregisterRemoteAssessmentChangeListeners();
    for (const assessment of assessments) {
      // const assessmentChangeSubscription = rubricDAO.registerRemoteAssessmentChangeListener(
      //   assessment.uuid, this.onRemoteAssessmentChange);
      const assessmentChangeSubscription = this.assessmentDAO.registerRemoteAssessmentChangeListener(
        assessment.uuid, this.onRemoteAssessmentChange);
      if (this._assessmentChangeSubscriptions) {
        this._assessmentChangeSubscriptions.push(assessmentChangeSubscription);
      }
    }
    this.assessmentChangeRegistrationTime = new Date().getTime();
  };

  _unregisterRemoteAssessmentChangeListeners = () => {
    if (this._assessmentChangeSubscriptions) {
      for (const assessmentChangeSubscription of this._assessmentChangeSubscriptions) {
        // rubricDAO.unregisterRemoteAssessmentChangeListener(assessmentChangeSubscription);
        this.assessmentDAO.unregisterRemoteAssessmentChangeListener(assessmentChangeSubscription);
      }
    }
    this._assessmentChangeSubscriptions = [];
  };

  onHashChange = (event) => {
    const newURL = event.newURL;
    if (newURL) {
      if (newURL !== event.oldURL) {
        window.location.href = newURL;
        this.changeCurrentDefinition(true);
      }
    }
  };

  getGuidanceVisibility = (statement) => {
    return this.state.statementUuidsToGuidanceVisibilities[statement.uuid];
  };

  onGuidanceExpanded = (statement) => {
    const statementUuidsToGuidanceVisibilities = util.shallowCloneObject(this.state.statementUuidsToGuidanceVisibilities);
    statementUuidsToGuidanceVisibilities[statement.uuid] = true;
    this.setState({
      statementUuidsToGuidanceVisibilities: statementUuidsToGuidanceVisibilities
    });
  };

  onGuidanceCollapsed = (statement) => {
    const statementUuidsToGuidanceVisibilities = util.shallowCloneObject(this.state.statementUuidsToGuidanceVisibilities);
    delete statementUuidsToGuidanceVisibilities[statement.uuid];
    this.setState({
      // rubricViewerKeyVersion: rubricViewerKeyVersion,
      statementUuidsToGuidanceVisibilities: statementUuidsToGuidanceVisibilities
    });
  };

  changeCurrentDefinition = (urlChanged) => {
    let definitionPromise: undefined | Promise<undefined | Definition> = undefined;
    let definitionUuid: undefined | string = undefined;
    if (!urlChanged) {
      definitionUuid = currentRubric.getDefinitionUuid();
    }
    if (!definitionUuid) {
      definitionUuid = urlUtil.getHashParameter(0);
    }

    let currentDefinition: undefined | Definition = currentRubric.getDefinition();
    if (currentDefinition && currentDefinition.uuid === definitionUuid) {
      definitionPromise = new Promise((resolve, reject) => {
        resolve(currentDefinition);
      });
    } else if (definitionUuid) {
      definitionPromise = rubricDAO.getDefinitionByUuid(definitionUuid);
    }

    // let assessmentPromise: undefined | any = undefined;
    // const assessmentUuid = currentRubric.getAssessmentUuid();
    // if (assessmentUuid) {
    //   assessmentPromise = rubricDAO.getAssessmentByUuid(assessmentUuid);
    // } else {
    //   assessmentPromise = new Promise((resolve, reject) => {
    //     resolve(undefined);
    //   });
    // }

    if (definitionUuid && definitionPromise) {
      let assessmentPromise: undefined | any = undefined;
      const assessmentUuid = currentRubric.getAssessmentUuid();
      if (assessmentUuid) {
        assessmentPromise = rubricDAO.getAssessmentByUuid(assessmentUuid);
      } else {
        assessmentPromise = new Promise((resolve, reject) => {
          resolve(undefined);
        });
      }

      const setResults = (definition, assessment) => {
        try {
          if (definition) {
            const inEditMode = urlUtil.isOnPage(navConstants.editPageHash);
            if (assessment) {
              currentRubric.setDefinitionAndAssessment(definition, assessment, inEditMode);
            } else {
              currentRubric.setDefinition(definition, inEditMode);
            }
          } else {
            actions.removeDefinitionReferences(definitionUuid);
            this.setState({
              loading: false
            });
          }
        } catch (error) {
          actions.removeDefinitionReferences(definitionUuid);
        }
      };
      if (assessmentUuid) {
        Promise
          .all([definitionPromise, assessmentPromise])
          .then((results) => {
            const definition = results[0];
            const assessment = results[1];
            setResults(definition, assessment);
          }).catch(() => {
            actions.removeDefinitionReferences(definitionUuid);
          });
      } else {
        definitionPromise.then((definition) => {

            // debugger;
            // console.log('--------- Setting definition:\n' + JSON.stringify(definition, null, 2));

            setResults(definition, undefined);
          }).catch(() => {
            actions.removeDefinitionReferences(definitionUuid);
          });
        }
    } else {
      this.setState({
        loading: true,
        definition: undefined,
        chartModel: undefined,
        aspectGrouping: undefined,
        selectedAssessment: undefined,
        selectedAssessmentName: undefined,
        assessmentCollaboratorsPanelVisible: false,
        readableAssessments: [],
        editableAssessments: [],
        definitionPreferences: {},
        columnVisibilities: [],
        visibleColumnCount: 0,
        visibleLabels: [],
        visibleAssessments: [],
        viewAssessmentsSideBySide: false
      });
      backgroundImageUtil.setBackgroundImageBasedOnUserPreferences();
    }
  };

  onRemoteAssessmentChange = (assessment: Assessment): void => {
    if (this.assessmentChangeRegistrationTime) {
      const now = new Date().getTime();
      const millisecondsSinceRegistration = now - this.assessmentChangeRegistrationTime;
      if (millisecondsSinceRegistration < 5000) {
        // Not sure why this happens occasionally even when there are no remote updates - gnore
        return;
      }
    }
    const selectedAssessment = assessment && this.state.selectedAssessment ?
      util.replaceItemInArray(assessment, [this.state.selectedAssessment], 'uuid')[0] :
      undefined;
    if (assessment) {
      const readableAssessments = util.replaceItemInArray(assessment, this.state.readableAssessments, 'uuid');
      const editableAssessments = util.replaceItemInArray(assessment, this.state.editableAssessments, 'uuid');
      const visibleAssessments = util.replaceItemInArray(assessment, this.state.visibleAssessments, 'uuid');
      const viewAssessmentsSideBySide = rubricUtil.shouldViewAssessmentsSideBySide(
        this.state.definition, visibleAssessments);
      this.setState({
        selectedAssessment: selectedAssessment,
        selectedAssessmentName: selectedAssessment.name,
        assessmentCollaboratorsPanelVisible: false,
        readableAssessments: readableAssessments,
        editableAssessments: editableAssessments,
        visibleAssessments: visibleAssessments,
        viewAssessmentsSideBySide: viewAssessmentsSideBySide
      });
      if (this.state.definition) {
        const chartModel = new ChartModel(
          this.state.definition,
          this.state.visibleAssessments,
          this.state.visibleLabels ? this.state.visibleLabels : [],
          this.state.columnVisibilities);
        this.setState({
          chartModel: chartModel
        });
      }
      this.showAssessmentChangedFlag(assessment);
    }
  };

  showAssessmentChangedFlag = (assessment: Assessment) => {
    const timeoutSeconds = 5;
    if (this.lastAssessmentChangeFlagTimestamp) {
      if (new Date().getTime() - this.lastAssessmentChangeFlagTimestamp < 1000 * timeoutSeconds) {
        return;
      }
    }
    const newFlagId = util.uuid();
    let hideFlagPromise: undefined | Promise<any> = undefined;
    if (this.flagId) {
      actions.removeFlag(this.flagId);
      hideFlagPromise = new Promise((resolve: (value: any) => void, reject: (value: any) => void) => {
        setTimeout(() => {
          resolve(undefined);
        },1000)
      });
    } else {
      hideFlagPromise = new Promise((resolve, reject) => {
        resolve(undefined);
      });
    }
    hideFlagPromise.then(() => {
      this.flagId = newFlagId;
      const description = this.state.visibleAssessments && this.state.visibleAssessments.length ?
        `Assessment "${assessment.name}" was updated by another user.` :
        'The assessment was updated by another user.';
      const flag = {
        id: this.flagId,
        // appearance: "info",
        icon: <InfoIcon label="info" primaryColor={adg.globalSidebarColor} secondaryColor={adg.white}/>,
        title: 'Assessment updated',
        description: description,
        flagMessageTimeoutSeconds: timeoutSeconds,
        actions: []
      };
      actions.addFlag(flag);
      this.lastAssessmentChangeFlagTimestamp = new Date().getTime();
    });
  };

  updateChartModelAsync = () => {
    this.outstandingChartModelUpdates++;
    const delay = 500;
    setTimeout(() => {
      this.outstandingChartModelUpdates--;
      if (this.outstandingChartModelUpdates === 0) {
        let chartModel: undefined | ChartModel = undefined;
        if (this.state.definition) {
          chartModel = new ChartModel(
            this.state.definition,
            this.state.visibleAssessments,
            this.state.visibleLabels ? this.state.visibleLabels : [],
            this.state.columnVisibilities);
        }
        this.setState({
          chartModel: chartModel
        });
      }
    }, delay);
  };

  onSessionChange = (user) => {
    this.forceUpdate();
  };

  onCurrentUserPlanChange = (planDefinition, context) => {
    const canViewOrCreateAssessments = permissionUtil.canViewOrCreateAssessments();
    this.setState({
      inSecondHalfOfTrialPeriod: context.inSecondHalfOfTrialPeriod,
      canViewOrCreateAssessments: canViewOrCreateAssessments
    });
    this.updateChartModelAsync();
  };

  onViewAssessmentsSideBySideChange = (checkboxEvent) => {
    const viewAssessmentsSideBySide = checkboxEvent.currentTarget.checked;
    this.setState({
      viewAssessmentsSideBySide: viewAssessmentsSideBySide
    });
    this.updateChartModelAsync();
  };

  onHideCompletedAspectsChange = (checkboxEvent) => {
    const hideCompletedAspects = checkboxEvent.currentTarget.checked;
    this.setState({
      hideCompletedAspects: hideCompletedAspects
    });
    this.updateChartModelAsync();
  };

  onHideZeroScoresChange = (checkboxEvent) => {
    const hideZeroScores = checkboxEvent.currentTarget.checked;
    this.setState({
      hideZeroScores: hideZeroScores
    });
  };

  onHideColumnTotalsChange = (checkboxEvent) => {
    const hideColumnTotals = checkboxEvent.currentTarget.checked;
    this.setState({
      hideColumnTotals: hideColumnTotals
    });
  };

  mergeEntityArrays = (entitiesA, entitiesB) => {
    const entities: any = [];
    const visitedUuids = {};
    for (let i = 0; i < entitiesA.length; i++) {
      const entity = entitiesA[i];
      entities.push(entity);
      visitedUuids[entity.uuid] = true;
    }
    for (let i = 0; i < entitiesB.length; i++) {
      const entity = entitiesB[i];
      if (!visitedUuids[entity.uuid]) {
        entities.push(entity);
        visitedUuids[entity.uuid] = true;
      }
    }
    return entities;
  };

  findAssessmentByUuid = (uuid, assessments) => {
    for (let i = 0; i < assessments.length; i++) {
      const assessment = assessments[i];
      if (assessment.uuid === uuid) {
        return assessment;
      }
    }
    return null;
  };

  buildVisibleAssessmentsDetails = (visibleAssessmentSummaries, allAssessments) => {
    const visibleAssessments: any[] = [];
    for (let i = 0; i < visibleAssessmentSummaries.length; i++) {
      const visibleAssessmentSummary = visibleAssessmentSummaries[i];
      const assessment = this.findAssessmentByUuid(visibleAssessmentSummary.value, allAssessments);
      if (assessment) {
        visibleAssessments.push(assessment);
      }
    }
    const descending = false;
    util.sortObjectsByField(visibleAssessments, 'name', descending);
    return {
      visibleAssessments: visibleAssessments
    }
  };

  updateStatus = (readableAssessments, visibleAssessments) => {
    if (readableAssessments.length === 0 && permissionUtil.canCreateAssessment()) {
      this.statusProvider.setCurrentStatus(
        statusDefinitions.statuses.mayNeedWork,
        'This rubric has no assessments yet');
    } else {
      if (visibleAssessments.length === 0) {
        this.statusProvider.setCurrentStatus(
          statusDefinitions.statuses.mayNeedWork,
          'No assessments are currently visible');
      } else {
        const maxColumnTotal = rubricUtil.computeMaxColumnTotal(this.state.definition, visibleAssessments, this.state.viewAssessmentsSideBySide);
        if (maxColumnTotal === undefined) {
          this.statusProvider.setCurrentStatus(
            statusDefinitions.statuses.mayNeedWork,
            'No scores have been provided');
        } else if (maxColumnTotal < 25) {
          this.statusProvider.setCurrentStatus(
            statusDefinitions.statuses.problemsExist,
            'The maximum column score is ' + Math.round(maxColumnTotal));
        } else if (maxColumnTotal < 50) {
          this.statusProvider.setCurrentStatus(
            statusDefinitions.statuses.workToBeDone,
            'The maximum column score is ' + Math.round(maxColumnTotal));
        } else if (maxColumnTotal < 75) {
          this.statusProvider.setCurrentStatus(
            statusDefinitions.statuses.mayNeedWork,
            'The maximum column score is ' + Math.round(maxColumnTotal));
        } else {
          const overridingMessage = undefined;
          this.statusProvider.setCurrentStatus(statusDefinitions.statuses.allOK, overridingMessage);
        }
      }
    }
  };

  showDefinition = (definition, optionalAssessmentToShow?: Assessment) => {
    const definitionUuid = definition.uuid;
    this.setState({
      loading: true
    });
    this._unregisterRemoteAssessmentChangeListeners();
    const overridingMessage = undefined;
    this.statusProvider.setCurrentStatus(statusDefinitions.statuses.nil, overridingMessage);
    const definitionPromise = new Promise((resolve, reject) => {
      resolve(definition);
    });
    const includeArchivedAssessments = false;
    const myAssessmentsPromise = rubricDAO.getCurrentUserAssessmentsByDefinitionUuid(definitionUuid, includeArchivedAssessments);
    const rejectedAssessmentsPromise = currentNotifications.getSharedAssessments(definitionUuid, shareStateDefinitions.rejectedShareState);
    const definitionPreferencesPromise = rubricViewerPreferences.getDefinitionPreferences(definitionUuid);
    const promise = Promise
        .all([definitionPromise, myAssessmentsPromise, rejectedAssessmentsPromise, definitionPreferencesPromise])
        .then((results) => {
      this.setState({
        loading: false
      });
      const definition: any = results[0];
      const canReadDefinition = permissionUtil.canReadDefinition(definition);
      if (canReadDefinition) {
        const scoreMeta = rubricUtil.getScoreMeta(definition);
        // const definitionColumnCount = rubricUtil.countColumns(definition);
        this.setState({
          definition: definition,
          scoreMeta: scoreMeta,
          hideCompletedAspects: definition && definition.options && definition.options.hideCompletedAspects,
          hideZeroScores: definition && definition.options && definition.options.hideZeroScores,
          hideColumnTotals: definition && definition.options && definition.options.hideColumnTotals
        });
        backgroundImageUtil.setDefinitionBackgroundImage(definition);
        rubricPageHelper.updateTitle(definition.name);
      } else {
        rubricPageHelper.updateTitle('rubric not found');
      }
      if (canReadDefinition && definition) {
        const myAssessmentsUnchecked = results[1];
        const rejectedAssessments = results[2];
        const definitionPreferences = results[3] as DefinitionPreferences;
        const myAssessmentsNotRejected = util.filterOut(myAssessmentsUnchecked, rejectedAssessments, 'uuid');
        const readableAssessments = permissionUtil.filterAssessmentsWithViewPermission(myAssessmentsNotRejected);
        const editableAssessments = permissionUtil.filterAssessmentsWithWritePermission(myAssessmentsNotRejected);
        const visibleAssessmentSummaries = definitionPreferences ? definitionPreferences.visibleAssessmentSummaries || [] : [];
        const descending = false;
        util.sortObjectsByField(readableAssessments, 'name', descending);
        this.determineSelectedAssessment(readableAssessments).then((selectedAssessment) => {
          const visibleAssessmentsDetails = this.buildVisibleAssessmentsDetails(
            visibleAssessmentSummaries, readableAssessments
          );

          // Temporary migration of data...
          for (let k = 0; k < readableAssessments.length; k++) {
            const readableAssessment = readableAssessments[k];
            rubricUtil.updateAssessmentSchema(readableAssessment);
          }

          const visibleLabels = rubricUtil.getDefinitionLabels(definition);
          const visibleAssessments = visibleAssessmentsDetails.visibleAssessments;
          const columnVisibilities = rubricUtil.buildDefaultColumnVisibilities(definition);
          const visibleColumnCount = this.computeVisibleColumnCount(columnVisibilities);
          const chartModel = new ChartModel(
            definition,
            visibleAssessments,
            visibleLabels,
            columnVisibilities);
          const viewAssessmentsSideBySide = rubricUtil.shouldViewAssessmentsSideBySide(
            definition, visibleAssessments);
          const aspectGrouping = chartModel.getDefaultAspectGrouping();
          this.updateStatus(readableAssessments, visibleAssessments);
          this.setState({
            chartModel: chartModel,
            aspectGrouping: aspectGrouping,
            selectedAssessment: selectedAssessment,
            selectedAssessmentName: selectedAssessment ? selectedAssessment.name : '',
            assessmentCollaboratorsPanelVisible: false,
            readableAssessments: readableAssessments,
            editableAssessments: editableAssessments,
            definitionPreferences: definitionPreferences,
            columnVisibilities: columnVisibilities,
            visibleColumnCount: visibleColumnCount,
            visibleLabels: visibleLabels,
            visibleAssessments: visibleAssessments,
            viewAssessmentsSideBySide: viewAssessmentsSideBySide,
            assessmentOperationsVisible: editableAssessments.length > 0
          });
          if (!this.state.autoChartShowingEvaluationComplete) {
            setTimeout(() => {
              this.evaluateIfChartsShouldBeVisible(definition, visibleAssessments);
            }, 1);
          }
          this._registerRemoteAssessmentChangeListeners(visibleAssessments);
          //this.forceUpdate();
          actions.onVisitDefinition(definition);

          if (optionalAssessmentToShow) {
            this.selectAssessment(optionalAssessmentToShow);
            this._setAssessmentVisibility(optionalAssessmentToShow, [], true);
            this.fireViewAssessmentAction(definition, optionalAssessmentToShow, chartModel);
          } else {
            for (const visibleAssessment of visibleAssessments) {
              this.fireViewAssessmentAction(definition, visibleAssessment, chartModel);
            }
          }
        });
      } else {
        // The rubric has been deleted or the user doesn't have read permission
        this.setState({
          selectedAssessment: undefined,
          selectedAssessmentName: undefined,
          assessmentCollaboratorsPanelVisible: false,
          readableAssessments: [],
          editableAssessments: [],
          definitionPreferences: {},
          columnVisibilities: [],
          visibleColumnCount: 0,
          visibleLabels: [],
          visibleAssessments: [],
          viewAssessmentsSideBySide: false
        });
        backgroundImageUtil.setBackgroundImageBasedOnUserPreferences();
      }
    }).catch((error) => {
      console.error('Unexpected error: ', error);
      this.setState({
        loading: false
      });
    });
    return promise;
  };

  fireViewAssessmentAction = (definition, assessment, chartModel) => {
    const assessmentContext: any = {
      definition: definition,
      assessment: assessment
    };
    if (chartModel) {
      const assessmentScore = chartModel.getOverallScoreByAssessmentUuid(assessment.uuid);
      assessmentContext.assessmentScore = assessmentScore;
    }
    actions.onViewAssessment(assessmentContext);
  };

  shouldChartsBeVisible = (definition, visibleAssessments): boolean => {
    let visible = false;
    for (const assessment of visibleAssessments) {
      const snapshotData = rubricUtil.computeAssessmentSnapshotData(definition, assessment);
      if (snapshotData && snapshotData.averageScore > 0) {
        visible = true;
        break;
      }
    }
    return visible;
  };

  evaluateIfChartsShouldBeVisible = (definition, visibleAssessments) => {
    this.setState({
      showCharts: this.shouldChartsBeVisible(definition, visibleAssessments),
      autoChartShowingEvaluationComplete: true
    });
  };

  computeVisibleColumnCount = (optionalColumnVisibilities) => {
    const columnVisibilities = optionalColumnVisibilities ?
      optionalColumnVisibilities : this.state.columnVisibilities;
    let count = 0;
    for (const columnVisibility of columnVisibilities) {
      if (columnVisibility) {
        count++;
      }
    }
    return count;
  };

  onAssessmentSelected = async (assessmentUuid: string) => {
    const user = session.getCurrentUser();
    if (user && this.state.definition) {
      await preferencesDAO.setLastSelectedAssessmentUuid(this.state.definition.uuid, user, assessmentUuid);
    }
  }

  determineSelectedAssessment = (assessments: Assessment[]): Promise<any> => {
    const user = session.getCurrentUser();
    if (user && this.state.definition) {
      const defaultAssessmentUuid = undefined;
      return preferencesDAO.getLastSelectedAssessmentUuid(this.state.definition.uuid, user, defaultAssessmentUuid)
        .then((lastSelectedAssessmentUuid) => {
          if (lastSelectedAssessmentUuid) {
            for (let i = 0; i < assessments.length; i++) {
              const assessment = assessments[i];
              if (assessment.uuid === lastSelectedAssessmentUuid) {
                return assessment;
              }
            }
            if (assessments.length) {
              return assessments[0];
            }
          } else {
            for (let i = 0; i < assessments.length; i++) {
              const assessment = assessments[i];
              if (permissionUtil.canWriteAssessment(assessment)) {
                return assessment;
              }
            }
            for (let i = 0; i < assessments.length; i++) {
              const assessment = assessments[i];
              if (permissionUtil.canReadAssessment(assessment)) {
                return assessment;
              }
            }
            if (assessments.length) {
              return assessments[0];
            }
          }
          return undefined;
        });
  } else {
      return new Promise((resolve, reject) => {
        resolve(undefined);
      });
    }
  };

  ensureAssessmentIsSelected = (assessmentUuid) => {
    if (this.state && this.state.visibleAssessments) {
      for (const visibleAssessment of this.state.visibleAssessments) {
        if (visibleAssessment.uuid === assessmentUuid) {
          // The assessment is already visible so there's nothing to do. If there
          // are other selected assessments, leave them selected.
        }
      }
    }
    if (this.state && this.state.readableAssessments) {
      for (const readableAssessment of this.state.readableAssessments) {
        if (readableAssessment.uuid === assessmentUuid) {
          // The assessment has been loaded, but is not currently selected so we should
          // make it visible and deselect existing assessments that may be visible.
          return this.selectAssessment(readableAssessment);
        }
      }
    }
    return this.loadAndSelectAssessmentbyUuid(assessmentUuid);
  };

  loadAndSelectAssessmentbyUuid = (assessmentUuid) => {
    return rubricDAO.getAssessmentByUuid(assessmentUuid).then((assessment) => {
      this.selectAssessment(assessment);
      this.forceUpdate();
    });
  };

  selectAssessment = (assessment) => {
    this.setState({
      selectedAssessment: assessment,
      selectedAssessmentName: assessment.name,
      assessmentCollaboratorsPanelVisible: false
    });
    const user = session.getCurrentUser();
    preferencesDAO.setLastSelectedAssessmentUuid(this.state.definition.uuid, user, assessment.uuid);
  };

  onDeleteRubricFailed = () => {
  };

  onDeleteRubric = () => {
    this.setState({
      definition: undefined,
      chartModel: undefined,
      aspectGrouping: undefined,
      selectedAssessment: undefined,
      selectedAssessmentName: undefined,
      assessmentCollaboratorsPanelVisible: false,
      readableAssessments: [],
      editableAssessments: []
    });
    this.props.history.push(navConstants.homePageHash);
    this.updateChartModelAsync();
    backgroundImageUtil.setBackgroundImageBasedOnUserPreferences();
  };

  onCurrentRubricChange = (state) => {
    if (state.definition) {
      const sameDefinition = this.state.definition && this.state.definition.uuid === state.definition.uuid;
      if (sameDefinition) {
        const assessmentUuid = currentRubric.getAssessmentUuid();
        if (assessmentUuid) {
          this.ensureAssessmentIsSelected(assessmentUuid);
        }
      } else {
        this.showDefinition(state.definition, state.assessment);
      }
    } else {
      console.error('No definition is selected');
    }
  };

  onForkDefinition = () => {
    rubricDAO.forkDefinition(this.state.definition).then((forkedDefinition) => {
      const inEditMode = true;
      currentRubric.setDefinition(forkedDefinition, inEditMode);
    });
  };

  onLikeToggle = () => {
    const like = this.state.definitionPreferences.like === true;
    const newLikeValue = !like;
    const newDefinitionPreferences = Object.assign({}, this.state.definitionPreferences) as DefinitionPreferences;
    newDefinitionPreferences.like = newLikeValue;
    if (newDefinitionPreferences.likeToggleCount === undefined) {
      newDefinitionPreferences.likeToggleCount = 0;
    }
    newDefinitionPreferences.likeToggleCount++;
    rubricViewerPreferences.setDefinitionPreferences(this.state.definition.uuid, newDefinitionPreferences)
      .then(() => {
        this.setState({
          definitionPreferences: newDefinitionPreferences
        });
        // Send notifications asynchronously since they don't have to be done straight 
        // away...
        setTimeout(() => {
          const definitionPreferencesUpdateContext: DefinitionPreferencesUpdateContext = {
            definitionUuid: this.state.definition.uuid,
            definitionPreferences: newDefinitionPreferences
          }
          actions.onDefinitionPreferencesUpdate(definitionPreferencesUpdateContext);
          if (featureFlags.rubricLikingEnabled()) {
            if (newLikeValue) {
              if (newDefinitionPreferences.likeToggleCount && newDefinitionPreferences.likeToggleCount < 2) {
                notificationUtil.notifyDefinitionLike(this.state.definition);
              };
            }
          }
        }, 500);
        const likeCountIncrease = newLikeValue ? 1 : -1;
        return rubricUtil.adjustDefinitionLikeCount(this.state.definition, likeCountIncrease)
          .then((result: any) => {
            if (result && result.data && result.data.ok) {
              this.forceUpdate();
            }
          });
      });
  };

  onStarToggle = () => {
    const starred = this.state.definitionPreferences.starred === true;
    const newDefinitionPreferences: DefinitionPreferences = Object.assign({}, this.state.definitionPreferences) as DefinitionPreferences;
    newDefinitionPreferences.starred = !starred;
    rubricViewerPreferences.setDefinitionPreferences(this.state.definition.uuid, newDefinitionPreferences)
      .then(() => {
        this.setState({
          definitionPreferences: newDefinitionPreferences
        });
        const definitionPreferencesUpdateContext: DefinitionPreferencesUpdateContext = {
          definitionUuid: this.state.definition.uuid,
          definitionPreferences: newDefinitionPreferences
        }
        actions.onDefinitionPreferencesUpdate(definitionPreferencesUpdateContext);
      });
  };

  onSaveRubricToFolderSelection = async (folder: DriveFolder) => {
    this.setState({
      selectingDriveFolder: false
    });
    let fileReferenceBuilder = new RubricReferenceBuilder()
      .setDefinitionUuid(this.state.definition.uuid)
      .setDefinitionName(this.state.definition.name);
    if (this.state.driveFolderFileType === 'assessment') {
      fileReferenceBuilder = fileReferenceBuilder
        .setAssessmentUuid(this.state.selectedAssessment.uuid)
        .setAssessmentName(this.state.selectedAssessment.name);
    }
    const fileReference = fileReferenceBuilder.build();
    driveGraph.addFileReferenceToFolder(folder, fileReference);
    await driveGraph.save();
    const subjectName = this.state.driveFolderFileType === 'assessment' ? I18N.assessment : I18N.rubric;
    const flag = {
      id: util.uuid(),
      icon: <SuccessIcon label="Success" primaryColor={adg.adgGreen} secondaryColor="#fff" />,
      title: 'Saved',
      description: `This ${subjectName} has been saved to the ${folder.name} folder in your drive.`,
      flagMessageTimeoutSeconds: 10
    };
    actions.addFlag(flag);
  };

  onSaveRubricToDriveClick = () => {
    this.setState({
      selectingDriveFolder: true,
      driveFolderFileType: 'definition'
    });
  };

  onSaveAssessmentToDriveClick = () => {
    this.setState({
      selectingDriveFolder: true,
      driveFolderFileType: 'assessment'
    });
  };

  onChartsEnablementToggle = () => {
    if (this.state.chartsEnabled) {
      this.setState({
        chartsEnabled: false
      });
    } else {
      this.setState({
        chartsEnabled: true,
        showCharts: true
      });
    }
  }

  onDefinitionPreferencesToggle = () => {
    const showDefinitionPreferences = !this.state.showDefinitionPreferences;
    this.setState({
      showDefinitionPreferences: showDefinitionPreferences
    });
    if (showDefinitionPreferences) {
      setTimeout(() => {
        const element = this.definitionPreferencesElement;
        if (element) {
          scrollToComponent(element, { offset: 0, align: 'middle', duration: 500, ease:'inCirc'});
        }
      }, 100);
    }
  };

  onToggleChartVisibility = () => {
    this.setState({
      showCharts: !this.state.showCharts
    });
  };

  onToggleShowHideHistoryChart = () => {
    this.setState({
      showHistoryChart: !this.state.showHistoryChart
    });
  };

  onChartGroupingSelectChange = (selectedOption: SelectOption) => {
    this.setState({
      aspectGrouping: selectedOption.value
    });
  };

  onToggleChartType = () => {
    this.setState({
      chartTypeIsPie: !this.state.chartTypeIsPie,
      // showHistoryChart: false
    });
  };

  onToggleAssessmentOperationsVisibility = () => {
    this.setState({
      assessmentOperationsVisible: !this.state.assessmentOperationsVisible
    });
  };

  onEditAssessmentName = () => {
    this.newAssessmentName = this.state.selectedAssessment.name;
    this.setState({
      inEditAssessmentNameMode: true,
      selectedAssessmentName: this.state.selectedAssessment.name
    });
  };

  onAssessmentNameChangeSaved = () => {
    if (this.state.editableAssessments) {
      for (let i = 0; i < this.state.editableAssessments.length; i++) {
        const assessment = this.state.editableAssessments[i];
        if (assessment.uuid === this.state.selectedAssessment.uuid) {
          assessment.name = this.newAssessmentName;
        }
      }
    }
    const descending = false;
    util.sortObjectsByField(this.state.readableAssessments, 'name', descending);
    this.setState({
      inEditAssessmentNameMode: false
    });
    this.newAssessmentName = undefined;
    // this.forceUpdate();

    this.setState({
      selectedAssessmentName: this.newAssessmentName
    });

    const assessmentContext = {
      definition: this.state.definition,
      assessment: this.state.selectedAssessment
    };
    actions.onAssessmentRenamed(assessmentContext);
  };

  onAssessmentNameEdit = (event: FormEvent<HTMLInputElement>) => {
    if (this.state.inEditAssessmentNameMode) {
      const name = event.currentTarget.value;
      if (name && this.state.selectedAssessment) {
        this.newAssessmentName = name;
        const visibleAssessmentSummaries = this.state.definitionPreferences.visibleAssessmentSummaries || [];
        if (visibleAssessmentSummaries) {
          for (let i = 0; i < visibleAssessmentSummaries.length; i++) {
            const visibleAssessmentSummary = visibleAssessmentSummaries[i];
            if (visibleAssessmentSummary.value === this.state.selectedAssessment.uuid) {
              visibleAssessmentSummary.label = name;
            }
          }
          // this.onAssessmentVisibilityChange(visibleAssessmentSummaries);
        }
        // this.onAssessmentNameChangeSaved(name);
      }
      this.setState({
        selectedAssessmentName: name
      });
    }
  };

  onAssessmentChange = () => {
    this.updateStatus(this.state.readableAssessments, this.state.visibleAssessments);
    this.setState({
      assessmentChangeCount: this.state.assessmentChangeCount ? this.state.assessmentChangeCount + 1 : 1
    });
    this.updateChartModelAsync();
    // const assessmentContext = {
    //   definition: this.state.definition,
    //   assessment: this.state.selectedAssessment
    // };
    // actions.onAssessmentChange(assessmentContext);
  };

  onSubmitAssessmentName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (this.newAssessmentName) {
      const selectedAssessment = this.state.selectedAssessment;
      selectedAssessment.name = this.newAssessmentName;
      assessmentUtil.saveAssessment(this.assessmentDAO, selectedAssessment)
        .then(() => {
          this.onAssessmentNameChangeSaved();
        })
        .catch((error) => {
          console.error("Error renaming assessment: ", error);
        });
    }
  };

  onDeleteAssessment = (assessment) => {
    if (assessment) {
      rubricDAO.deleteAssessment(assessment).then(() => {
        this.showDefinition(this.state.definition);
        this._setAssessmentVisibility(assessment, this.state.visibleAssessments, false);
      });
    }
  };

  _setAssessmentVisibility = (assessmentToChange, assessments, visible) => {
    const visibleAssessments: Assessment[] = [];

    const visibleAssessmentSummaries: any[] = [];
    for (let i = 0; i < assessments.length; i++) {
      const assessment = assessments[i];
      if (!visible && assessmentToChange.uuid === assessment.uuid) {
        // don't add
      } else {
        visibleAssessments.push(assessment);
        visibleAssessmentSummaries.push({
          label: assessment.name,
          value: assessment.uuid
        });
      }
    }

    if (visible) {
      visibleAssessments.push(assessmentToChange);
      visibleAssessmentSummaries.push({
        label: assessmentToChange.name,
        value: assessmentToChange.uuid
      });
    }

    const descending = false;
    util.sortObjectsByField(visibleAssessments, 'name', descending);

    const definitionPreferences = Object.assign({}, this.state.definitionPreferences);
    definitionPreferences.visibleAssessmentSummaries = visibleAssessmentSummaries;
    const viewAssessmentsSideBySide = rubricUtil.shouldViewAssessmentsSideBySide(
      this.state.definition, visibleAssessments);
    this.setState({
      definitionPreferences: definitionPreferences,
      visibleAssessments: visibleAssessments,
      viewAssessmentsSideBySide: viewAssessmentsSideBySide
    });

    this.onAssessmentVisibilityChange(visibleAssessmentSummaries);
  };

  onAddAssessment = () => {
    const assessmentName = `Assessment ${dateUtil.formatConciseDate(new Date())}`;
    const builderActions = (assessmentBuilder: RubricAssessmentBuilder): RubricAssessmentBuilder => {
      assessmentBuilder.setName(assessmentName)
      // assessmentBuilder.setArchivedTimestamp(new Date().getTime())
      return assessmentBuilder;
    };
    // rubricDAO.createAssessment(this.state.definition, assessmentName, builderActions).then((createdAssessment) => {
    assessmentUtil.createAndStoreAssessment(this.assessmentDAO, this.state.definition, builderActions).then((createdAssessment) => {
      const readableAssessments: Assessment[] = [];
      if (this.state.readableAssessments) {
        for (let i = 0; i < this.state.readableAssessments.length; i++) {
          readableAssessments.push(this.state.readableAssessments[i]);
        }
      }
      readableAssessments.push(createdAssessment);

      const editableAssessments: Assessment[] = [];
      if (this.state.editableAssessments) {
        for (let i = 0; i < this.state.editableAssessments.length; i++) {
          editableAssessments.push(this.state.editableAssessments[i]);
        }
      }
      editableAssessments.push(createdAssessment);

      const descending = false;
      util.sortObjectsByField(readableAssessments, 'name', descending);
      this.setState({
        selectedAssessment: createdAssessment,
        selectedAssessmentName: createdAssessment.name,
        assessmentCollaboratorsPanelVisible: false,
        inEditAssessmentNameMode: true,
        readableAssessments: readableAssessments,
        editableAssessments: editableAssessments
      });
      this.newAssessmentName = createdAssessment.name;
      const user = session.getCurrentUser();
      preferencesDAO.setLastSelectedAssessmentUuid(this.state.definition.uuid, user, createdAssessment.uuid)
        .then(() => {
          this._setAssessmentVisibility(createdAssessment, this.state.visibleAssessments, true);
          this.forceUpdate();
          setTimeout(() => {
            const assessmentNameInputElement = this.assessmentNameInputRef.current;
            if (assessmentNameInputElement && assessmentNameInputElement.input) {
              assessmentNameInputElement.input.focus();
              assessmentNameInputElement.input.select();
              assessmentNameInputElement.input.value = '';
            }
          }, 1);
          this.updateStatus(readableAssessments, this.state.visibleAssessments);
          this.updateChartModelAsync();
        });
    })
    .catch((error) => {
      console.error("Error creating assessment: ", error);
    });
  };

  onReportScore = () => {
    currentReport.setAssessment(this.state.selectedAssessment);
  };

  onShareRubric = () => {
    const shareComponent = (
      <ShareRubricForm
        definition={this.state.definition}
        onDone={() => {currentDialog.clearDialogOptions()}}
        onCancel={() => {currentDialog.clearDialogOptions()}}
      />
    );
    const actions = null;
    const dialogOptions = {
      title: 'Share this rubric',
      component: shareComponent,
      actions: actions
    };
    currentDialog.setDialogOptions(dialogOptions);
  };

  onShareAssessmentDone = (contact: undefined | Contact) => {
    currentDialog.clearDialogOptions();
    const assessment = this.state.selectedAssessment;
    if (contact) {
      const email = contact.email;
      const emailDomain = util.extractDomainFromEmail(email);
      if (!rubricUtil.getAssessmentRole(assessment, contact.email, emailDomain)) {
        rubricUtil.setAssessmentRole(assessment, contact.email, roleDefinitions.readerRoleType);
      }
      this._saveAssessment(assessment, false)
    }
  };

  onShareAssessment = () => {
    const shareComponent = (
      <ShareAssessmentForm
        definition={this.state.definition}
        assessment={this.state.selectedAssessment}
        onDone={this.onShareAssessmentDone}
        onCancel={currentDialog.clearDialogOptions}
      />
    );
    const actions = null;
    const dialogOptions = {
      title: 'Share this assessment',
      component: shareComponent,
      actions: actions
    };
    currentDialog.setDialogOptions(dialogOptions);
  };

  onAssessmentVisibilityChange = (visibleAssessmentSummaries) => {
    const descending = false;
    util.sortObjectsByField(visibleAssessmentSummaries, 'label', descending);
    const visibleAssessmentsDetails = this.buildVisibleAssessmentsDetails(
      visibleAssessmentSummaries, this.state.readableAssessments);
    const visibleAssessments = visibleAssessmentsDetails.visibleAssessments;
    const definitionUuid = this.state.definition.uuid;
    const definitionPreferences = Object.assign({}, this.state.definitionPreferences);
    definitionPreferences.visibleAssessmentSummaries = visibleAssessmentSummaries;
    rubricViewerPreferences.setDefinitionPreferences(definitionUuid, definitionPreferences)
      .then(() => {
        const definitionPreferences = Object.assign({}, this.state.definitionPreferences);
        definitionPreferences.visibleAssessmentSummaries = visibleAssessmentSummaries;
        const viewAssessmentsSideBySide = rubricUtil.shouldViewAssessmentsSideBySide(
          this.state.definition, visibleAssessments);
        this.setState({
          definitionPreferences: definitionPreferences,
          visibleAssessments: visibleAssessments,
          viewAssessmentsSideBySide: viewAssessmentsSideBySide
        });
        if (visibleAssessments.length === 1) {
          // Also make the selected asessment the same to avoid confusion...
          this.selectAssessment(visibleAssessments[0]);
        }
        for (const visibleAssessment of visibleAssessments) {
          this.fireViewAssessmentAction(this.state.definition, visibleAssessment, null);
        }
      });
    this.updateStatus(this.state.visibleAssessments, visibleAssessments);
    this.updateChartModelAsync();
  };

  onEditRubric = () => {
    currentRubric.setDefinition(this.state.definition, true);
  };

  isAssessmentVisible = (assessment) => {
    if (this.state.visibleAssessments && this.state.visibleAssessments.length) {
      for (let i = 0; i < this.state.visibleAssessments.length; i++) {
        const visibleAssessment = this.state.visibleAssessments[i];
        if (visibleAssessment.uuid === assessment.uuid) {
          return true;
        }
      }
    }
    return false;
  };

  renderDeleteRubricButton = () => {
    if (permissionUtil.canDeleteDefinition(this.state.definition)) {
      return (
        <ToolbarItem>
          <Tooltip content="Delete this rubric">
            <DeleteRubricButton
              definition={this.state.definition}
              renderAsIconButton={true}
              onRubricNotDeleted={this.onDeleteRubricFailed}
              onRubricDeleted={this.onDeleteRubric}
            />
          </Tooltip>
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderShareRubricButton = () => {
    return (
      <ToolbarItem>
        <Tooltip content="Share rubric">
          <IconButton
            normalIcon={<ShareRubricIcon label="share"/>}
            hoverIcon={<ShareRubricIcon label="share"/>}
            onClick={this.onShareRubric}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderEditRubricButton = () => {
    if (permissionUtil.canWriteDefinition(this.state.definition)) {
      return (
        <ToolbarItem>
          <Tooltip content="Edit rubric">
            <IconButton
              normalIcon={<EditRubricIcon label="edit"/>}
              hoverIcon={<EditRubricFilledIcon label="edit"/>}
              onClick={this.onEditRubric}
            />
          </Tooltip>
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderForkDefinitionToolbarItem = () => {
    if (permissionUtil.canForkDefinition(this.state.definition)) {
      return (
        <ToolbarItem>
          <Tooltip content="Copy this rubric" position="left">
            <IconButton
              normalIcon={<CopyRubricIcon label="copy"/>}
              hoverIcon={<CopyRubricIcon label="copy"/>}
              onClick={this.onForkDefinition}
            />
          </Tooltip>
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderLikeCountToolbarItem = () => {
    // return null;

    const likeCount = rubricUtil.getDefinitionLikeCount(this.state.definition);
    if (likeCount === 0) {
      return null;
    }
    const tooltip = likeCount === 1 ? '1 person likes this rubric' : `${likeCount} people like this rubric`;
    return (
      <ToolbarItem>
        <Tooltip content={tooltip} position="left">
          <span>{likeCount}</span>
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderLikeToolbarItem = () => {
    const definitionPreferences = this.state.definitionPreferences;
    const like = definitionPreferences.like === true;
    const normalIcon = <LikeIcon label="like"/>;
    const hoverIcon = <LikeIcon label="like"/>;
    const normalColor = like ? adg.adgYellow : undefined;
    const hoverColor = like ? '#333' : adg.adgYellow;
    const tooltip = like ? 'Unlike this rubric' : 'Like this rubric';
    return (
      <ToolbarItem>
        <Tooltip content={tooltip} position="left">
          <IconButton
            normalIcon={normalIcon}
            hoverIcon={hoverIcon}
            normalColor={normalColor}
            hoverColor={hoverColor}
            onClick={this.onLikeToggle}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderStarToolbarItem = () => {
    const definitionPreferences = this.state.definitionPreferences;
    const starred = definitionPreferences.starred === true;
    const normalIcon = starred ? <StarFilledIcon label="star" /> : <StarIcon label="star"/>;
    const hoverIcon = starred ? <StarIcon label="star" /> : <StarFilledIcon label="star"/>;
    const normalColor = starred ? adg.adgYellow : undefined;
    const hoverColor = starred ? '#333' : adg.adgYellow;
    const tooltip = starred ? 'Un-star this rubric' : 'Star this rubric';
    return (
      <ToolbarItem>
        <Tooltip content={tooltip} position="left">
          <IconButton
            normalIcon={normalIcon}
            hoverIcon={hoverIcon}
            normalColor={normalColor}
            hoverColor={hoverColor}
            onClick={this.onStarToggle}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderSaveRubricToDriveToolbarItem = () => {
    return (
      <ToolbarItem>
        <Tooltip content="Add this rubric to your drive" position="left">
          <IconButton
            normalIcon={<FolderIcon label="folder"/>}
            hoverIcon={<FolderFilledIcon label="folder"/>}
            onClick={this.onSaveRubricToDriveClick}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderToggleChartsEnablementToolbarItem = () => {
    const tooltip = this.state.chartsEnabled ? 'Hide charts' : 'Show charts';
    return (
      <ToolbarItem>
        <Tooltip content={tooltip} position="left">
          <IconButton
            normalIcon={<BarChartIcon label="charts" />}
            hoverIcon={<BarChartIcon label="charts" />}
            onClick={this.onChartsEnablementToggle}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderDefinitionPreferencesToolbarItem = () => {
    return (
      <ToolbarItem>
        <Tooltip content="Preferences" position="left">
          <IconButton
            normalIcon={<PreferencesIcon label="preferences"/>}
            hoverIcon={<PreferencesIcon label="preferences"/>}
            onClick={this.onDefinitionPreferencesToggle}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderAssessmentNotVisibleWarningToolbarItem = () => {
    return (
      <ToolbarItem key="assessment-not-visible-warning">
        <Tooltip content="This assessment is not currently visible" position="left">
          <IconButton
            normalIcon={<WarningIcon label="warning"/>}
            hoverIcon={<WarningIcon label="warning"/>}
            normalColor={adg.adgCheetoOrange}
            hoverColor={adg.adgCheetoOrange}
            onClick={() => {}}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderAssessmentSelector = () => {
    if (this.state.readableAssessments) {
      return (
        <ToolbarItem key="assessment-selector" style={{width: '300px'}}>
          <AssessmentSelector
            label={i18n.Assessment}
            selectedAssessment={this.state.selectedAssessment}
            assessments={this.state.readableAssessments}
            onAssessmentSelected={this.onAssessmentSelected}
          />
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderAssessmentName = () => {
    return (
      <ToolbarItem key="score-name" style={{fontSize: '18px'}}>
        {this.state.selectedAssessment.name}
      </ToolbarItem>
    );
  };

  renderAssessmentNameTextField = () => {
    return (
      <ToolbarItem key="score-name-field">
        <Tooltip content="Change the name of an assessment">
          <FormlessTextField
            name="name"
            ref={this.assessmentNameInputRef}
            placeholder="Assessment name"
            value={this.state.selectedAssessmentName ? this.state.selectedAssessmentName : undefined}
            onChange={this.onAssessmentNameEdit}
            onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => widgetUtil.bindTextFieldEnterEvent(event, this.onSubmitAssessmentName)}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderSubmitScoreNameTextField = () => {
    return (
      <ToolbarItem key="submit-score-name">
        <IconButton
          normalIcon={<SubmitNameIcon label="submit"/>}
          hoverIcon={<SubmitNameIcon label="submit"/>}
          onClick={this.onSubmitAssessmentName}
        />
      </ToolbarItem>
    );
  };

  renderEditScoreNameIcon = () => {
    return (
      <ToolbarItem key="edit-score-name">
        <Tooltip content="Change the name of this assessment">
          <IconButton
            normalIcon={<EditNameIcon label="edit"/>}
            hoverIcon={<EditNameFilledIcon label="edit"/>}
            onClick={this.onEditAssessmentName}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderNoAssessmentsExistItem = () => {
    return (
      <ToolbarItem key="no-assessments-yet" style={{color: adg.adgRed, fontWeight: 'bold'}}>
        You have no assessments yet! Add one here:
      </ToolbarItem>
    );
  };

  renderAddAssessmentIcon = (assessmentsExist) => {
    const color = assessmentsExist ? undefined : adg.adgRed;
    const tooltipText = assessmentsExist ? 'Add another assessment' : 'Add an assessment';
    return (
      <ToolbarItem key="add-score">
        <Tooltip content={tooltipText}>
          <IconButton
            normalIcon={<AddAssessmentIcon/>}
            hoverIcon={<AddAssessmentIcon/>}
            normalColor={color}
            hoverColor={color}
            onClick={this.onAddAssessment}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  onToggleAssessmentPanelVisibility = () => {
    this.setState({
      assessmentCollaboratorsPanelVisible: !this.state.assessmentCollaboratorsPanelVisible
    });
  };

  onToggleAssessmentDiscoverability = () => {
    if (!this.state.selectedAssessment) {
      return null;
    }
    const canAdminister = permissionUtil.canAdministerAssessment(this.state.selectedAssessment);
    if (!canAdminister) {
      return null;
    }
    const assessment = this.state.selectedAssessment;
    const discoverability = assessment.discoverability;
    const isPublic = discoverability === discoverabilityDefinitions.publicDiscoverabilityType;
    const newDiscoverability = isPublic ?
      discoverabilityDefinitions.privateDiscoverabilityType :
      discoverabilityDefinitions.publicDiscoverabilityType;
    rubricUtil.setAssessmentDiscoverability(assessment, newDiscoverability);
    return this._saveAssessment(assessment, true);    
  };

  onRemoveCollaboratorByEmail = (assessment, email) => {
    rubricUtil.removeAssessmentRole(assessment, email);
    return this._saveAssessment(assessment, true);
  };

  onCollaboratorAddition = (assessment, email, role) => {
    rubricUtil.setAssessmentRole(assessment, email, role);
    return this._saveAssessment(assessment, true);
  };

  onCollaboratorUpdate = (assessment, email, role) => {
    rubricUtil.setAssessmentRole(assessment, email, role);
    return this._saveAssessment(assessment, true);
  };

  // _saveSelectedAssessment = (forceUpdate) => {
  //   this._saveAssessment(this.state.selectedAssessment, forceUpdate);
  // };

  _saveAssessment = (assessment, forceUpdate) => {
    assessmentUtil.saveAssessment(this.assessmentDAO, assessment)
      .then((savedAssessment) => {
        if (this.state.selectedAssessment && this.state.selectedAssessment.uuid === assessment.uuid) {
          this.setState({
            selectedAssessment: savedAssessment,
            selectedAssessmentName: savedAssessment.name
          });
        }
        if (forceUpdate) {
          this.forceUpdate();
        }
      });
  };

  renderAssessmentDiscoverabilityIcon = () => {
    if (!this.state.selectedAssessment) {
      return null;
    }
    const canAdminister = permissionUtil.canAdministerAssessment(this.state.selectedAssessment);
    if (!canAdminister) {
      return null;
    }
    const discoverability = this.state.selectedAssessment.discoverability;
    const isPublic = discoverability === discoverabilityDefinitions.publicDiscoverabilityType;
    const normalIcon = isPublic ? <PublicIcon label="" /> : <PrivateIcon label=""/>;
    const hoverIcon = isPublic ? <PrivateIcon label="" /> : <PublicIcon label=""/>;
    const normalColor = isPublic ? adg.adgRed : adg.adgGreen;
    const hoverColor = isPublic ? adg.adgGreen : adg.adgRed;
    const tooltip = isPublic ?
      'Assessment is public discoverable. Change to private.' :
      'Change assessment discoverability to public';
    return (
      <ToolbarItem key="change-assessment-discoverability">
        <Tooltip content={tooltip}>
          <IconButton
            normalIcon={normalIcon}
            hoverIcon={hoverIcon}
            normalColor={normalColor}
            hoverColor={hoverColor}
            invertColors={false}
            invertIcons={false}
            onClick={() => this.onToggleAssessmentDiscoverability()}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderToggleAssessmentCollaboratorsPanelIcon = () => {
    if (!this.state.selectedAssessment) {
      return null;
    }
    const canAdminister = permissionUtil.canAdministerAssessment(this.state.selectedAssessment);
    if (!canAdminister) {
      return null;
    }
    const isVisible = this.state.assessmentCollaboratorsPanelVisible;
    const normalColor = isVisible ? adg.adgPrimaryBlue : undefined;
    const hoverColor = undefined;
    const tooltip = isVisible ?
      'Hide collaborators' :
      'Show collaborators';
    return (
      <ToolbarItem key="change-assessment-collaborators-panel-visibility">
        <Tooltip content={tooltip}>
          <IconButton
            normalIcon={<CollaboratorsIcon label=""/>}
            hoverIcon={<CollaboratorsIcon label=""/>}
            normalColor={normalColor}
            hoverColor={hoverColor}
            invertColors={false}
            invertIcons={!isVisible}
            onClick={() => this.onToggleAssessmentPanelVisibility()}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderSaveAssessmentToDriveToolbarItem = () => {
    return (
      <ToolbarItem key="save-assessment-to-drive">
        <Tooltip content="Add this assessment to your drive" position="left">
          <IconButton
            normalIcon={<FolderIcon label=""/>}
            hoverIcon={<FolderFilledIcon label=""/>}
            onClick={this.onSaveAssessmentToDriveClick}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderShareAssessmentIcon = () => {
    return (
      <ToolbarItem key="share-assessment">
        <Tooltip content="Share this assessment with someone">
          <IconButton
            normalIcon={<ShareAssessmentIcon label=""/>}
            hoverIcon={<ShareAssessmentIcon label=""/>}
            onClick={this.onShareAssessment}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderReportScoreIcon = () => {
    return (
      <ToolbarItem key="export-score">
        <Tooltip content="View this assessment as a report">
          <IconButton
            normalIcon={<ViewAssessmentIcon label=""/>}
            hoverIcon={<ViewAssessmentIcon label=""/>}
            onClick={this.onReportScore}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderDeleteScoreIcon = () => {
    return (
      <ToolbarItem key="delete-score">
        <Tooltip content="Delete this assessment">
          <SafePopupButton
            confirmationMessage={`Are you sure you want to delete assessment "${this.state.selectedAssessment.name}"?`}
            normalStateLabel=""
            iconBefore={<DeleteAssessmentIcon label="delete"/>}
            onConfirmation={() => this.onDeleteAssessment(this.state.selectedAssessment)}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderAssessmentLabel = () => {
    return (
      <ToolbarItem key="assessment">
        <Label
          key="assessment"
          paddingTop="0px"
          paddingBottom="0px"
          text={i18n.Assessment + ':'}
        />
      </ToolbarItem>
    );
  };

  renderChartVisibilityToolbarItem = () => {
    const chartsTooltip = this.state.showCharts ? 'Hide chart' : 'Show chart';
    return (
      <ToolbarItem key="chart-visibility">
        <Tooltip content={chartsTooltip} position="left">
          <IconButton
            normalIcon={<ChartIcon label=""/>}
            hoverIcon={<ChartIcon label=""/>}
            invertColors={this.state.showCharts}
            onClick={this.onToggleChartVisibility}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderShowHideHistoryChartToolbarItem = () => {
    const chartsTooltip = this.state.showCharts ? 'Hide history chart' : 'Show history chart';
    return (
      <ToolbarItem key="history-chart-visibility">
        <Tooltip content={chartsTooltip} position="left">
          <IconButton
            normalIcon={<TimeChartIcon label=""/>}
            hoverIcon={<TimeChartIcon label=""/>}
            invertColors={this.state.showHistoryChart}
            onClick={this.onToggleShowHideHistoryChart}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderChartGroupingSelectToolbarItem = () => {
    const chartModel = this.state.chartModel;
    if (chartModel && this.state.definition) {
      return (
        <React.Fragment
          key="chart-grouping"
        >
          <ToolbarItem style={{ minWidth: '200px' }}>
            <AspectGroupingSelector
              definition={this.state.definition}
              aspectGroupingType={this.state.aspectGrouping}
              isGroupingAllowed={chartModel.isGroupingAllowed}
              onChange={this.onChartGroupingSelectChange}
            />
          </ToolbarItem>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  // renderChartGroupingSelectToolbarItem = () => {
  //   const chartModel = this.state.chartModel;
  //   if (!chartModel) {
  //     return null;
  //   }
  //   const selectContext = aspectGroupingDefinitions.buildAspectGroupingTypeEnumItemsContext(
  //     this.state.aspectGrouping, chartModel.isGroupingAllowed, this.state.definition);
  //   return (
  //     <React.Fragment
  //       key="chart-grouping"
  //     >
  //       <div style={{minWidth: '200px'}}>
  //         <Select
  //           className="single-select"
  //           classNamePrefix="react-select"
  //           placeholder="Chart aspect grouping"
  //           options={selectContext.items}
  //           defaultValue={selectContext.selectedItem}
  //           onChange={this.onChartGroupingSelectChange}
  //         />
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  renderToggleChartTypeToolbarItem = () => {
    const tooltip = this.state.chartTypeIsPie ? 'Switch to bar chart' : 'Switch to pie chart';
    return (
      <ToolbarItem key="chart-type">
        <Tooltip content={tooltip} position="left">
          <IconButton
            normalIcon={<BarChartIcon label=""/>}
            hoverIcon={<PieChartIcon label=""/>}
            invertIcons={this.state.chartTypeIsPie}
            invertColors={false}
            onClick={this.onToggleChartType}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderToggleAssessmentOperationsVisibilityToolbarItem = () => {
    if (this.allowAssessmentToolbarHiding) {
      const tooltip = this.state.assessmentOperationsVisible ? 'Hide assessment operations' : 'Show assessment operations';
      return (
        <ToolbarItem
          key="chart-type"
        >
          <Tooltip content={tooltip} position="left">
            <IconButton
              normalIcon={<VisibilityFilledIcon label=""/>}
              hoverIcon={<VisibilityIcon label=""/>}
              invertIcons={this.state.assessmentOperationsVisible}
              invertColors={!this.state.assessmentOperationsVisible}
              onClick={this.onToggleAssessmentOperationsVisibility}
            />
          </Tooltip>
        </ToolbarItem>
      );
    } else {
      return null;
    }
  };

  renderAssessmentOperationsToolbarContent = () => {
    const multipleScores = this.state.selectedAssessment && this.state.readableAssessments && this.state.readableAssessments.length > 1;
    const inEditAssessmentNameMode = this.state.inEditAssessmentNameMode;
    const toolbarItems: any[] = [];
    toolbarItems.push(this.renderToggleAssessmentOperationsVisibilityToolbarItem());
    if (!this.allowAssessmentToolbarHiding || this.state.assessmentOperationsVisible) {
      if (inEditAssessmentNameMode) {
        if (permissionUtil.canWriteAssessment(this.state.selectedAssessment)) {
          toolbarItems.push(this.renderAssessmentNameTextField());
          toolbarItems.push(this.renderSubmitScoreNameTextField());
        }
      } else {
        if (this.state.selectedAssessment) {
          const selectedAssessmentIsVisible = util.isInArray(this.state.selectedAssessment, this.state.visibleAssessments, 'uuid');
          if (!selectedAssessmentIsVisible) {
            toolbarItems.push(this.renderAssessmentNotVisibleWarningToolbarItem());
          }
        }
        if (multipleScores) {
          toolbarItems.push(this.renderAssessmentSelector());
        } else if (this.state.selectedAssessment) {
          toolbarItems.push(this.renderAssessmentName());
        } else {
          //
        }
        if (this.state.selectedAssessment) {
          if (permissionUtil.canWriteAssessment(this.state.selectedAssessment)) {
            toolbarItems.push(this.renderEditScoreNameIcon());
          }
          toolbarItems.push(this.renderReportScoreIcon());
          toolbarItems.push(this.renderSaveAssessmentToDriveToolbarItem());
          if (permissionUtil.canShareAssessment(this.state.selectedAssessment)) {
            toolbarItems.push(this.renderShareAssessmentIcon());
          }
          if (permissionUtil.canDeleteAssessment(this.state.selectedAssessment)) {
            toolbarItems.push(this.renderDeleteScoreIcon());
          }
        }
        if (permissionUtil.canCreateAssessment()) {
          const assessmentsExist = this.state.readableAssessments && this.state.readableAssessments.length > 0;
          if (!assessmentsExist) {
            toolbarItems.push(this.renderNoAssessmentsExistItem());
          }
          toolbarItems.push(this.renderAddAssessmentIcon(assessmentsExist));
        }
        if (this.state.selectedAssessment) {
          if (permissionUtil.canAdministerAssessment(this.state.selectedAssessment)) {
            toolbarItems.push(this.renderAssessmentDiscoverabilityIcon());
            toolbarItems.push(this.renderToggleAssessmentCollaboratorsPanelIcon());
          }
        }
      }
    }
    return (
      <ToolbarItem
        key="assessment-operations-toolbar"
        style={{
          flexShrink: 0,
          flexGrow: undefined // was 1 when on left
        }}
      >
        <LiftedPanel>
          <Label
            key="assessment-operations"
            paddingTop="0px"
            paddingBottom="0px"
            text={i18n.Assessment + ' operations:'}
          />
          <Toolbar>
            {toolbarItems}
          </Toolbar>
        </LiftedPanel>
      </ToolbarItem>
    );
  };

  renderAssessmentOperationsToolbar = (): any => {
    return this.renderAssessmentOperationsToolbarContent();
    // return (
    //   <ExpandingToolbar name="Assessment operations" alignRight={true}>
    //     {this.renderAssessmentOperationsToolbarContent()}
    //   </ExpandingToolbar>
    // );
  };

  renderChartsToolbarContent = (): any => {
    const toolbarItems: any[] = [];
    if (this.state.visibleAssessments && this.state.visibleAssessments.length) {
      toolbarItems.push(this.renderChartVisibilityToolbarItem());
      if (this.state.showCharts) {
        toolbarItems.push(this.renderChartGroupingSelectToolbarItem());
        toolbarItems.push(this.renderToggleChartTypeToolbarItem());
        const hasLabels = this.state.definition.labels && this.state.definition.labels.length;
        if (hasLabels) {
          // toolbarItems.push(this.renderToggleChartGroupingToolbarItem());
        }
        toolbarItems.push(this.renderShowHideHistoryChartToolbarItem());
      }
    }
    if (toolbarItems.length === 0) {
      return null;
    }
    return (
      <ToolbarItem
        key="chart-toolbar"
        style={{
          flexShrink: 0,
          flexGrow: 1
        }}
      >
        <LiftedPanel>
          <Label
            paddingTop="10px"
            paddingBottom="0px"
            text="Charts:"
          />
          <Toolbar>
            {toolbarItems}
          </Toolbar>
        </LiftedPanel>
      </ToolbarItem>
    );
  };

  renderChartsToolbar = (): any => {
    return (
      <ExpandingToolbar
        key="charts-toolbar"
        name="Chart controls"
        expanded={true}
        icon={<ChartIcon label="chart" />}
        alignRight={false}
      >
        {this.renderChartsToolbarContent()}
      </ExpandingToolbar>
    );  
  };

  renderAssessmentVisibilitySelector = (): any => {
    const items: any[] = [];
    const value: any[] = [];
    if (this.state.readableAssessments) {
      for (let i = 0; i < this.state.readableAssessments.length; i++) {
        const assessment = this.state.readableAssessments[i];
        const item = {
          label: assessment.name,
          value: assessment.uuid
        };
        items.push(item);
        if (this.isAssessmentVisible(assessment)) {
          value.push(item);
        }
      }
    }
    const validationState = items.length > 0 && value.length === 0 ? 'error' : 'default';
    return (
      <div
        key="assessment-visibility-selector"
        style={{
          flexShrink: 1,
          flexGrow: 2,
          minWidth: '250px'
        }}
      >
        <CheckboxSelect
          className="checkbox-select"
          classNamePrefix="select"
          options={items}
          value={value}
          placeholder="Select assessments to view"
          noOptionsMessage={() => {return "No assessments"}}
          appearance="default"
          validationState={validationState}
          onChange={this.onAssessmentVisibilityChange}
        />
      </div>
    );
  };

  onVisibleLabelsChange = (labels) => {
    this.setState({
      visibleLabels: labels
    });
    this.updateChartModelAsync();
  };

  onColumnIndexToVisibilityChange = (columnVisibilities) => {
    const visibleColumnCount = this.computeVisibleColumnCount(columnVisibilities);
    this.setState({
      columnVisibilities: columnVisibilities,
      visibleColumnCount: visibleColumnCount
    });
  };

  renderLabelVisibilityToolbarItem = (): any => {
    return (
      <ToolbarItem
        key="label-visibility-toolbar-item"
        style={{ minWidth: '150px' }}
      >
        <Label
          key="label-visibility"
          paddingTop="0px"
          paddingBottom="0px"
          text={i18n.Label + ' visibility:'}
        />
        {rubricRenderingUtil.renderDefinitionLabelsFilter(
          this.state.definition, this.state.visibleLabels, this.onVisibleLabelsChange)}
      </ToolbarItem>
    );
  };

  renderLabelVisibilityToolbarContent = (): any => {
    return (
      <Toolbar key="label-visibility">
        {this.renderLabelVisibilityToolbarItem()}
      </Toolbar>
    );
  };

  renderLabelVisibilityToolbar = (): any => {
    return (
      <ExpandingToolbar key="labels-toolbar" name="Labels" alignRight={true}>
        {this.renderLabelVisibilityToolbarContent()}
      </ExpandingToolbar>
    );  
  };

  renderAssessmentVisibilityToolbarItemContent = (): any => {
    const spotlightTargetName = 'assessment-visibility-selector';
    const hasVisibleAssessments = this.state.visibleAssessments && this.state.visibleAssessments.length;
    const hasReadableAssessments = this.state.readableAssessments && this.state.readableAssessments.length;
    const userOnboardingEnabled = !hasVisibleAssessments && hasReadableAssessments;
    return (
      <LiftedPanel
        key="assessment-visibility-toolbar-item"
        className="toolbar-item"
      >
        <Label
          key="assessment-visibility"
          paddingTop="0px"
          paddingBottom="0px"
          text={`Show ${i18n.assessments}:`}
        />
        <Toolbar>
          <SpotlightManager>
            <SpotlightTarget name={spotlightTargetName}>
              {this.renderAssessmentVisibilitySelector()}
            </SpotlightTarget>
            <SpotlightTransition>
              <UserOnboarding
                preferenceKey={spotlightTargetName}
                target={spotlightTargetName}
                dialogPlacement="top left"
                heading="Select assessments here"
                content={`Use this select or to choose the assessments to view.`}
                enabled={userOnboardingEnabled}
              />
            </SpotlightTransition>
          </SpotlightManager>
        </Toolbar>
      </LiftedPanel>
    );
  };

  renderAssessmentVisibilityToolbarItem = (): any => {
    return this.renderAssessmentVisibilityToolbarItemContent();
    // return (
    //   <ExpandingToolbar name="Assessment visibility" alignRight={false}>
    //     {this.renderAssessmentVisibilityToolbarItemContent()}
    //   </ExpandingToolbar>
    // );
  };

  renderAssessmentToolbarSpacer = (): any => {
    return (
      <div
        key="assessment-toolbar-separator"
        style={{width: '20px'}}>
      </div>
    );
  };

  renderDescription = () => {
    if (this.state.definition && this.state.definition.description) {
      return (
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <LiftedPanel isText={true}>
            <ExpandingText
              introHeight={95}
              cutoffTolerance={30}
              seeLessText='see less of the description'
              seeMoreText='see more of the description'
              markdown={this.state.definition.description}
            />
          </LiftedPanel>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: '10px' }}>
        </div>
      );
    }
  }

  renderPreferenceCheckbox = (label, message, value, isChecked, onChange) => {
    return (
      <div key={value} style={{marginBottom: '20px'}}>
        <Tooltip content={message}>
          <Checkbox
            label={label}
            value={value}
            crossOrigin={undefined}
            isChecked={isChecked}
            onChange={onChange}
          />
        </Tooltip>
      </div>
    )
  };

  renderDefinitionPreferences = () => {
    const columnCount = rubricUtil.countColumns(this.state.definition);
    return (
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <LiftedPanel>
          <div
            ref={(definitionPreferencesElement) => {this.definitionPreferencesElement = definitionPreferencesElement}}
          >
            <Label text="Preferences" paddingTop="0px"/>
            {columnCount === 1 ? this.renderPreferenceCheckbox(
              'View assessments side by side',
              'View assessments side by side',
              'viewAssessmentsSideBySide',
              this.state.viewAssessmentsSideBySide,
              this.onViewAssessmentsSideBySideChange
            ) : null}
            {this.renderPreferenceCheckbox(
              'Hide completed aspects',
              'Hide aspects with a score of 100',
              'hideCompletedAspects',
              this.state.hideCompletedAspects,
              this.onHideCompletedAspectsChange
            )}
            {this.renderPreferenceCheckbox(
              'Hide scores with a value of 0',
              'Hide scores with a value of 0',
              'hideZeroScores',
              this.state.hideZeroScores,
              this.onHideZeroScoresChange
            )}
            {this.renderPreferenceCheckbox(
              'Hide column assessment scores',
              'Hide column assessment scores',
              'hideColumnTotals',
              this.state.hideColumnTotals,
              this.onHideColumnTotalsChange
            )}
          </div>
        </LiftedPanel>
      </div>
    );
  };

  renderAssessmentToolbar = () => {
    const toolbarItems: any[] = [];
    toolbarItems.push(this.renderAssessmentVisibilityToolbarItem());
    toolbarItems.push(this.renderAssessmentToolbarSpacer());
    toolbarItems.push(this.renderAssessmentOperationsToolbar());
    return (
      <ToolbarJustify>
        {toolbarItems}
      </ToolbarJustify>
    );
  };

  renderViewToolbar = () => {
    const toolbarItems: any[] = [];
    const hasLabels = this.state.definition.labels && this.state.definition.labels.length;
    if (hasLabels || this.state.chartsEnabled) {
      if (this.state.chartsEnabled) {
        toolbarItems.push(this.renderChartsToolbar());
      } else {
        toolbarItems.push((<div key="no-charts"></div>));
      }
      if (hasLabels) {
        toolbarItems.push(this.renderAssessmentToolbarSpacer());
        toolbarItems.push(this.renderLabelVisibilityToolbar());
      }
      return (
        <ToolbarJustify style={{ marginTop: '10px' }}>
          {toolbarItems}
        </ToolbarJustify>
      );
    } else {
      return null;
    }
  };

  onRebuildNewPascalRubric = () => {
    const newUuidsToInfos = {
      '3032a153-eb62-b442-85ac-39c878a2b3b2': {
        oldUuid: 'pascal-p',
        columnToLevelMatcher: (columnIndex, columnName) => {
          let label = columnName;
          const openBracketIndex = label.indexOf('(');
          const closeBracketIndex = label.indexOf(')');
          if (openBracketIndex > 0 && closeBracketIndex > openBracketIndex + 1) {
            label = label.substring(openBracketIndex + 1, closeBracketIndex);
          }
          return label;
        }
      },
      '8d8d92bc-38ae-dc61-f117-e017022e11a9': { // Engineering M-levels
        oldUuid: 'ff238265-8057-861c-89b9-c712746c3052',
        columnToLevelMatcher: (columnIndex, columnName) => {
          const level = columnIndex + 3;
          return 'M' + level;
        }
      },
      'a2f72b4b-e27e-673d-5346-77d19dea1f19': {
        oldUuid: 'cb21f472-cf6c-1a44-080a-e506ac1107de', // PM P-levels
        columnToLevelMatcher: (columnIndex, columnName) => {
          if (columnIndex === 4) {
            return 'P7P8';
          } else if (columnIndex === 5) {
            return 'P9';
          } else {
            const level = columnIndex + 3;
            return 'P' + level;
          }
        }
      },
      '2c4a6686-4bde-d8e7-d775-c2cef38da244': {
        oldUuid: '46fb64b8-52ee-b904-4a39-7771b810967b', // PM M-levels
        columnToLevelMatcher: (columnIndex, columnName) => {
          if (columnIndex === 0) {
            return 'M4P5';
          } else if (columnIndex === 1) {
            return 'M6';
          } else if (columnIndex === 2) {
            return 'M7+';
          }
          return 'M?';
        }
      }
    };
    const info = newUuidsToInfos[this.state.definition.uuid];
    const oldUuid = info.oldUuid;
    rubricDAO.getDefinitionByUuid(oldUuid).then((oldDefinition) => {
      // //console.log('Found oldDefinition:', oldDefinition);
      // //debugger;
      const newDefinition = this.state.definition;
      rubricUtil.convertLevelsDefinitionToGuidanceDefinition(
        oldDefinition, newDefinition, info.columnToLevelMatcher);
      this.setState({
        definition: newDefinition
      });
      backgroundImageUtil.setDefinitionBackgroundImage(newDefinition);
      setTimeout(this.forceUpdate, 500);
      // this.forceUpdate();
    });
  };

  renderRebuildNewPascalToolbarItem = () => {
    const definitionPreferences = this.state.definitionPreferences;
    const starred = definitionPreferences.starred === true;
    const normalIcon = starred ? <StarFilledIcon label="" /> : <StarIcon label=""/>;
    const hoverIcon = starred ? <StarIcon label="" /> : <StarFilledIcon label=""/>;
    return (
      <ToolbarItem>
        <Tooltip content="Rebuild new pascal rubric" position="left">
          <IconButton
            normalIcon={normalIcon}
            hoverIcon={hoverIcon}
            normalColor={adg.adgYellow}
            hoverColor={adg.adgRed}
            onClick={this.onRebuildNewPascalRubric}
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

  renderPageToolbar = () => {
    const showRebuildButton = false;
    return (
      <Toolbar className="toolbar-right">
        {showRebuildButton ? this.renderRebuildNewPascalToolbarItem() : null}
        {featureFlags.rubricLikingEnabled() ? this.renderLikeCountToolbarItem() : null}
        {featureFlags.rubricLikingEnabled() ? this.renderLikeToolbarItem() : null}
        {this.renderStarToolbarItem()}
        {this.renderSaveRubricToDriveToolbarItem()}
        {this.renderToggleChartsEnablementToolbarItem()}
        {this.renderDefinitionPreferencesToolbarItem()}
        {this.renderForkDefinitionToolbarItem()}
        {this.renderShareRubricButton()}
        {this.renderEditRubricButton()}
        {this.renderDeleteRubricButton()}
      </Toolbar>
    );
  };

  renderPageTitleBar = () => {
    return (
      <ToolbarJustify>
        <div style={{ flexShrink: 2, flexGrow: 2, overflowX: 'hidden', marginRight: '10px'}}>
          <LiftedPageTitle>{this.state.definition.name}</LiftedPageTitle>
        </div>
        <div style={{ flexShrink: 0, flexGrow: 0}}>
          <LiftedPanel>
            {this.state.definition ? this.renderPageToolbar() : null}
          </LiftedPanel>
        </div>
      </ToolbarJustify>
    );
  };

  renderInTrialWarningOLD = () => {
    return null;
  };

  renderInTrialWarning = () => {
    const warningBoxStyle: any = {
      border: `0px solid ` + adg.adgRed,
      backgroundColor: adg.globalSidebarColor,
      color: '#fff',
      fontWeight: 'bold',
      borderRadius: '6px',
      padding: '10px',
      marginTop: '20px',
      marginBottom: '20px',
      maxWidth: '400px',
      minWidth: '150px'
    };
    return (
      <div className="centredContent">
        <div style={warningBoxStyle}>
          You're on a trial plan which means some features will disappear at the end.
          But no trouble, you can re-join to get them all back.
        </div>
      </div>
    );
  };

  renderCharts = () => {
    const renderedCharts: any[] = [];
    const renderedAssessmentsChart = this.state.chartTypeIsPie ? this.renderAspectPieChart() : this.renderAspectBarChart();
    const renderedHistoryChart = this.state.showHistoryChart ? this.renderHistoryChart() : null;
    if (renderedAssessmentsChart) {
      renderedCharts.push(renderedAssessmentsChart);
    }
    if (renderedHistoryChart) {
      if (renderedAssessmentsChart) {
        renderedCharts.push(<div style={{ height: '20px' }}></div>);
      }
      renderedCharts.push(renderedHistoryChart);
    }
    return (
      <div style={{ marginTop: '10px' }}>
        <LiftedPanel autoScrollX={true}>
          {renderedCharts}
        </LiftedPanel>
      </div>
    );
  };

  renderCollaboratorsView = (assessment) => {
    const visible = this.state.selectedAssessment && this.state.assessmentCollaboratorsPanelVisible;
    if (visible) {
      const user = session.getCurrentUser();
      const canAdminister = permissionUtil.canAdministerAssessment(assessment);
      if (canAdminister) {
        return (
          <LiftedPanel>
            <Toolbar className="toolbar-right">
              <CollaboratorsView
                title="Collaborators"
                owner={user}
                flattenedRolesArray={assessment.roles}
                onRemoveCollaboratorByEmail={(email) => this.onRemoveCollaboratorByEmail(assessment, email)}
                onCollaboratorAddition={(email, role) => this.onCollaboratorAddition(assessment, email, role)}
                onCollaboratorUpdate={(email, role) => this.onCollaboratorUpdate(assessment, email, role)}
              />
            </Toolbar>
          </LiftedPanel>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  renderRubric = () => {
    const selectedAssessment = this.state.selectedAssessment;
    const canViewOrCreateAssessments = this.state.canViewOrCreateAssessments;
    const definition = this.state.definition;
    const assessments = canViewOrCreateAssessments ? this.state.visibleAssessments : [] as Assessment[];
    // const chartsVisible = this.shouldChartsBeVisible(definition, this.state.visibleAssessments);
    const chartsVisible = this.state.chartsEnabled && this.state.showCharts;
    return (
      <div>
        {this.renderPageTitleBar()}
        {this.state.inSecondHalfOfTrialPeriod ? this.renderInTrialWarning() : null}
        {this.renderDescription()}
        {this.state.showDefinitionPreferences ? this.renderDefinitionPreferences() : null}
        {canViewOrCreateAssessments ? this.renderAssessmentToolbar() : null}
        {selectedAssessment ? this.renderCollaboratorsView(selectedAssessment) : null}
        {canViewOrCreateAssessments ? this.renderViewToolbar() : null}
        {chartsVisible ? this.renderCharts() : null}
        <FolderSelectorDialog
          open={this.state.selectingDriveFolder}
          allowFolderSelection={true}
          onFolderSelection={this.onSaveRubricToFolderSelection}
        />
        <div style={{ marginTop: '10px' }}>
          <LiftedPanel>
            <RubricViewer
              embedded={false}
              definition={definition}
              scoreMeta={this.state.scoreMeta}
              viewAssessmentsSideBySide={this.state.viewAssessmentsSideBySide}
              hideCompletedAspects={this.state.hideCompletedAspects}
              hideZeroScores={this.state.hideZeroScores}
              hideColumnTotals={this.state.hideColumnTotals}
              columnVisibilities={this.state.columnVisibilities}
              visibleLabels={this.state.visibleLabels}
              assessments={assessments}
              readableAssessments={this.state.readableAssessments}
              editableAssessments={this.state.editableAssessments}
              canViewOrCreateAssessments={canViewOrCreateAssessments}
              onAssessmentChange={this.onAssessmentChange}
              onColumnIndexToVisibilityChange={this.onColumnIndexToVisibilityChange}
              getGuidanceVisibility={this.getGuidanceVisibility}
              onGuidanceExpanded={this.onGuidanceExpanded}
              onGuidanceCollapsed={this.onGuidanceCollapsed}
              {...this.props}
            />
          </LiftedPanel>
        </div>
      </div>
    );
  };

  onVisitBoardClick = () => {
    this.props.history.push(navConstants.boardPageHash);
  };

  renderEmptyStatePrimaryAction = () => {
    return (
      <Button
        iconBefore={<BoardIcon label="" />}
        appearance="primary"
        onClick={this.onVisitBoardClick}
      >
        Visit your board
      </Button>
    );
  };

  renderEmptyStateTertiaryAction = () => {
    return null;
  };

  renderAspectBarChart = () => {
    if (this.state.chartTypeIsPie) {
      return null;
    }
    if (!this.state.visibleAssessments || this.state.visibleAssessments.length === 0) {
      return null;
    }
    if (!this.state.chartModel) {
      return null;
    }
    if (!this.state.scoreMeta) {
      throw new Error('this.state.scoreMeta not defined')
    }
    return (
      <div key={`bar-chart-${new Date().getTime()}`}>
        <ChartLabel definition={this.state.definition} aspectGrouping={this.state.aspectGrouping}/>
        <div className="centredContent newMinorSection" style={{width: '100%'}}>
          <ContainerDimensions>
            {({ width, height }) =>
              <div>
                <AspectBarChart
                  key={`bar-chart-${width}`}
                  scoreMeta={this.state.scoreMeta}
                  width={width}
                  chartModel={this.state.chartModel}
                  aspectGrouping={this.state.aspectGrouping}
                />
              </div>
            }
          </ContainerDimensions>
        </div>
      </div>
    );
  };

  renderAspectPieChart = () => {
    if (!this.state.chartTypeIsPie) {
      return null;
    }
    if (!this.state.visibleAssessments || this.state.visibleAssessments.length === 0) {
      return null;
    }
    if (!this.state.chartModel) {
      return null;
    }
    return (
      <div key="pie-chart">
        <ChartLabel definition={this.state.definition} aspectGrouping={this.state.aspectGrouping}/>
        <div className="centredContent newMinorSection">
          <MultiPieChart
            scoreMeta={this.state.scoreMeta}
            chartModel={this.state.chartModel}
            aspectGrouping={this.state.aspectGrouping}
          />
        </div>
      </div>
    );
  };

  renderHistoryChart = () => {
    if (!this.state.showHistoryChart) {
      return null;
    }
    if (!this.state.visibleAssessments || this.state.visibleAssessments.length < 1) {
      return null;
    }
    if (!this.state.aspectGrouping) {
      return null;
    }
    const chartHeight = 450;
    return (
      <React.Fragment key="history-chart">
        <ChartLabel definition={this.state.definition} text="Assessment history" />
        <div className="centredContent newMinorSection" style={{ width: '100%' }}>
          <ContainerDimensions>
            {({ width, height }) =>
              <HistoryChart
                scoreMeta={this.state.scoreMeta}
                width={width}
                height={chartHeight}
                chartModel={this.state.chartModel}
                aspectGrouping={this.state.aspectGrouping}
              />
            }
          </ContainerDimensions>
        </div>
      </React.Fragment>
    );
  };

  renderNoRubric = () => {
    const description = ``;
    const size: 'narrow' | 'wide' = this.props.size ? this.props.size : 'wide';
    const dimensions = size === 'narrow' ? {width: 450, height: 100} : {width: 900, height: 200};
    return (
      <EmptyState
        header="Mmm... Failed to load the rubric. it may have been deleted or restricted, but try reloading the page!"
        description={description}
        imageUrl={BigErrorModal2x}
        size={size}
        maxImageWidth={dimensions.width}
        maxImageHeight={dimensions.height}
        primaryAction={this.renderEmptyStatePrimaryAction()}
        tertiaryAction={this.renderEmptyStateTertiaryAction()}
      />
    );
  };

  renderConfluenceExport = () => {
    if (!this.state.definition) {
      return;
    }
    return (
      <div>
        <ConfluenceExport
          definition={this.state.definition}
          assessments={this.state.visibleAssessments ? this.state.visibleAssessments : []}
        />
      </div>
    );
  }

  render() {
    // TODO: extract this and from DashboardView
    const style: any = {
      opacity: 0.5,
      zIndex: 1000,
      position: 'absolute',
      left: 'calc(50% - 50px)',
      top: '20%'
    };
    if (this.state.loading) {
      return (
        <div
          className="centredContent"
          style={style}
        >
          <Spinner size="xlarge"/>
        </div>
      );
    } else {
      return (
        <ContentWrapper>
          {this.state.definition ? this.renderRubric() : this.renderNoRubric()}
        </ContentWrapper>
      );
    }
  }

}
