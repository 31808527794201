import ListenerGroup from '../../util/ListenerGroup';

class CurrentReport {

  listenerGroup = new ListenerGroup('CurrentReport');
  state = {
    assessmentUuid: '',
    assessmentName: ''
  };

  setAssessment = (assessment) => {
    this.setAssessmentUuid(assessment.uuid, assessment.name);
  };

  setAssessmentUuid = (assessmentUuid, assessmentName) => {
    this.state.assessmentUuid = assessmentUuid;
    this.state.assessmentName = assessmentName;
    this._notifyListeners();
  };

  getAssessmentUuid = () => {
    return this.state.assessmentUuid;
  };

  getAssessmentName = () => {
    return this.state.assessmentName;
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = () => {
    this.listenerGroup.notifyListeners(this.state);
  };

}

export default new CurrentReport();
