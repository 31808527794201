
class NavState {

  sidebarExpanded: boolean = true;

  setSidebarExpanded = (sidebarExpanded) => {
    this.sidebarExpanded = sidebarExpanded;
  };

  isSidebarExpanded = () => {
    return this.sidebarExpanded;
  }

}

export default new NavState();
