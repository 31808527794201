import '@atlaskit/css-reset';
// import adg from "../../commonbase/adg";
import adg from '../../commonbase/adg';

// import featureFlags from '../../shared/model/feature/FeatureFlags';
import featureFlags from '../../shared/model/feature/FeatureFlags';

// const liftedPanelTextDarkBgColor = 'rgba(0, 0, 0, .85)';
const liftPanelPaddingPx = 3;
const liftedPanelLightBgColor = 'rgba(255, 255, 255, 1)';
const liftedPanelTextLightBgColor = 'rgba(255, 255, 255, .92)';

class AppStyles {

  getFullAppStyles = () => {
    if (featureFlags.enableBackgroundImages()) {
      return this.newPageBackgroundStyles + this.coreStyles;
    } else {
      return this.pageBackgroundStyles + this.coreStyles;
    }
  };

  getEmbeddedAppStyles = () => {
    return this.coreStyles;
  };

  pageBackgroundStyles = `
  /* Background */

  @keyframes animatedBackground {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
  }
  
  body { 
    width: 100%; 
    height: 110px; 
    background-image: url(/img/bg-clouds-white.png);
    background-position: 0px 0px;
    background-repeat: repeat-x;
    animation: animatedBackground 100s linear infinite;
  }
  `;

  newPageBackgroundStyles = `
  
  html, body {
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }

  div.lifted-panel {
    padding: ${liftPanelPaddingPx}px;
    border-radius: 3px;
    opacity: 1;
  }

  .lifted-panel-on-dark-bg {
    background-color: #000;
  }

  .lifted-panel-on-light-bg {
    background-color: ${liftedPanelLightBgColor};
    -webkit-box-shadow: 1px 1px 1px 1px ${liftedPanelLightBgColor};  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    1px 1px 1px 1px ${liftedPanelLightBgColor};  /* Firefox 3.5 - 3.6 */
    box-shadow:         1px 1px 1px 1px ${liftedPanelLightBgColor};  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }

  .lifted-panel-text-on-light-bg {
    background-color: ${liftedPanelTextLightBgColor};
    -webkit-box-shadow: 1px 1px 1px 1px ${liftedPanelTextLightBgColor};  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    1px 1px 1px 1px ${liftedPanelTextLightBgColor};  /* Firefox 3.5 - 3.6 */
    box-shadow:         1px 1px 1px 1px ${liftedPanelTextLightBgColor};  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }

  `;
  
  coreStyles = `
  
/* General */

.icon-button {
  cursor: pointer;
  color: ${adg.adgMediumGray};
  width: '24px';
}

.icon-button:hover {
  color: ${adg.adgBlue};
}

.error {
  color: ${adg.adgRed};
}

.success {
  color: ${adg.adgGreen};
}

.warning {
  color: ${adg.adgCheetoOrange};
}

.attention {
  font-weight: bold;
}

.deemphasized {
  opacity: 0.3;
}

.emphasized {
  opacity: 1;
}

.widget-container {
  background-color: #F4F5F7;
  borderColor: #F4F5F7;
  borderRadius: 3px;
  borderStyle: solid;
  border-width: 2px;
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
}

.statement-text {
  font-weight: bold;
  color: #555;
}

div.rich-text {
  white-space: unset !important;
}

.rich-text h2 {
  margin-bottom: 10px;
}

.editorDerivedContent {
  white-space: unset !important; /* */
}

.editableText {
  cursor: text;
  /* padding: 10px; */
  border-radius: 3px;
}

.editableText:hover {
  background-color: ${adg.adgLightGrey};
}

.responsive {
  max-width: 100%;
  height: auto;
}

.image-caption {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.fadeable-player-control-bar {
  opacity: 0;
  transition: 2s;
}

.demo-area:hover .fadeable-player-control-bar {
  opacity: 1.0;
  transition: 0.2s;
}

.tooltipLayer {
  z-index: 1000;
}

/* */

div.left-right-choice-container-left-active > label > div {
  background-color: ${adg.B400};
}

div.left-right-choice-container-left-active > label > div:hover {
  background-color: ${adg.B200};
}

div.left-right-choice-container-right-active > label > div {
  background-color: ${adg.P400};
}

div.left-right-choice-container-right-active > label > div:hover {
  background-color: ${adg.P200};
}

/* Home page and panels */

.home-page-product-title {
  font-size: 50px;
  color: ${adg.globalSidebarColor};
  font-weight: bold;
}

.caption {
  color: ${adg.adgMediumGray};
  font-weight: bold;
}

.any-byline {
  color: ${adg.adgMediumGray};
}

.big-byline {
  font-size: 36px;
}

div.panel {
  width: 400px;
  border: 1px solid ${adg.adgAshGray};
  border-radius: 6px;
  margin-bottom: 20px;
}
div.panel div.panel-header {
  background-color: ${adg.adgAshGray};
  color: ${adg.adgCharcoal};
  font-weight: bold;
  padding: 10px;
}
div.panel div.panel-body {
  padding: 10px;
}
div.panel-section {
  margin-top: 10px;
}
div.hero-panel {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.centered {
  text-align: center;
}

/* Dashboard definition panel */

.definitionsGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.definition-panel {
  width: 300px;
  /*margin-left: 20px;*/
  margin-right: 0px;
  margin-bottom: 20px;
  padding: 0px;
  border: 0px solid ${adg.adgBlue};
  border-radius: 4px;
}

.definition-panel:first-child {
  margin-left: 0px;
}

.definition-panel > div.lifted-panel-on-light-bg:hover {
  background-color: rgba(222,235,255,0.9);
  box-shadow: 1px 1px 1px 1px rgba(222,235,255,0.9);
}

/* Definition summary */

.definition-summary-description {
  display: -webkit-box;
  overflow: hidden;
  line-height: 1.2em;
  height: 7.2em; /* 6 * 1.2 */
  -webkit-line-clamp: 6; /* 6 * 1.0 */
  -webkit-box-orient: vertical;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
// .definition-summary-description:before {
//   content: '...';
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }
// .definition-summary-description:after {
//   content: '';
//   position: absolute;
//   right: 0;
//   width: 1em;
//   height: 1em;
//   margin-top: 0.2em;
//   background: white;
// }

/* Resizing text area */

.resizing-textarea-container {
  position: relative;
}

.resizing-textarea {
  background-color: transparent;
  font-size: 14px;
  min-height: 20px;
  padding: 4px;
  box-shadow: none;
  display: block;
  border-width: 0px;
  border-radius: 5px;
  overflow: hidden;  /* Removes scrollbar */
  transition: height 0.2s ease;
}

.resizing-textarea--ghost {
  opacity: 0.3;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word; 
  color: green;
  padding: 4px;
   
  /*  Uncomment below to hide the ghost div... */ 
  visibility: hidden;
  position: absolute;
  top: 0;
}

  `;

}

export default new AppStyles();
