import SelectOption from '../widget/SelectOption';

class RoleDefinitions {

  readerRoleType = 'reader';
  writerRoleType = 'writer';
  adminRoleType = 'admin';
  readerRoleOption: SelectOption = {
    value: this.readerRoleType,
    label: 'reader'
  };
  writerRoleOption: SelectOption = {
    value: this.writerRoleType,
    label: 'Email domain'
  };
  adminRoleOption: SelectOption = {
    value: this.adminRoleType,
    label: 'Link'
  };
  options: SelectOption[] = [
    this.readerRoleOption,
    this.writerRoleOption,
    this.adminRoleOption
  ];
  typesToNames = [];
  typesToSuperiorityIndex = {};

constructor() {
    this.typesToNames[this.readerRoleType] = 'Reader';
    this.typesToNames[this.writerRoleType] = 'Writer';
    this.typesToNames[this.adminRoleType] = 'Administrator';
    this.typesToSuperiorityIndex[this.adminRoleType] = 0;
    this.typesToSuperiorityIndex[this.writerRoleType] = 1;
    this.typesToSuperiorityIndex[this.readerRoleType] = 2;
}
  
  getOptions = (): SelectOption[] => {
    return this.options;
  }

  getDefaultOption = (): SelectOption => {
    return this.readerRoleOption;
  };

  getNextImpliedRoleType = (roleType) => {
    if (roleType === this.adminRoleType) {
      return this.writerRoleType;
    } else if (roleType === this.writerRoleType) {
      return this.readerRoleType;
    } else {
      return null;
    }
  };

  isReader = (roleType) => {
    return roleType === this.readerRoleType || this.isWriter(roleType);
  };

  isWriter = (roleType) => {
    return roleType === this.writerRoleType || this.isAdmin(roleType);
  };

  isAdmin = (roleType) => {
    return roleType === this.adminRoleType;
  };

  // buildRoleTypeEnumItemsContext = (currentType) => {
  //   const readerDiscoverabilityItem = {
  //     content: this.typesToNames[this.readerRoleType],
  //     value: this.readerRoleType
  //   };
  //   const writerItem = {
  //     content: this.typesToNames[this.writerRoleType],
  //     value: this.writerRoleType
  //   };
  //   const adminItem = {
  //     content: this.typesToNames[this.adminRoleType],
  //     value: this.adminRoleType
  //   };
  //   const selectItems = [{
  //     items: [readerDiscoverabilityItem, writerItem, adminItem]
  //   }];
  //   let selectedItem: undefined | any = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: selectItems,
  //     selectedItem: selectedItem
  //   };
  // };

  getDefaultType = () => {
    return this.readerRoleType;
  };

}

export default new RoleDefinitions();
