import React, { PureComponent } from 'react';
import { CheckboxSelect } from '@atlaskit/select';
import Select from '@atlaskit/select';

interface Props {
  key?: string
  label: string
  selectedColumnUuid: string
  columnInfos: any
  onChange: (selectedColumnUuids: string[]) => void
}

interface State {
  label: string
  options?: any
  selectedOption: any
  selectedColumnUuids?: any[]
  dirty?: boolean
}

export default class ColumnSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    const options: any[] = [];
    let selectedOption: any = undefined;
    for (let i = 0; i < props.columnInfos.length; i++) {
      const columnInfo = props.columnInfos[i];
      const uuid = columnInfo.uuid;
      const name = columnInfo.name;
      const option = {
        label: name,
        value: uuid,
        selected: columnInfo.selected
      };
      options.push(option);
      if (props.selectedColumnUuid && props.selectedColumnUuid === uuid) {
        selectedOption = option;
      }
    }
    if (selectedOption === undefined && options.length) {
      selectedOption = options[0];
    }
    return {
      label: props.label,
      selectedOption: selectedOption,
      options: options
    };
  };

  onChange = (selectedOptions) => {
    // selectedOptions looks like [{label: , value: , selected: }];
    const newOptions = selectedOptions;
    const selectedColumnUuids: string[] = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      const selectedOption = selectedOptions[i];
      selectedColumnUuids.push(selectedOption.value);
    }
    this.setState({
      options: newOptions,
      selectedColumnUuids: selectedColumnUuids
    });
    this.props.onChange(selectedColumnUuids);
  };

  // isOptionInArray = (value, options) => {
  //   for (let i = 0; i < options.length; i++) {
  //     if (options[i].value === value) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  render() {
    const options = this.state.options;
    if (options && options.length > 0) {
      const value: any[] = [];
      for (let i = 0; i < this.state.options.length; i++) {
        const option = this.state.options[i];
        if (option.selected) {
          value.push(option);
        }
      }
      const validationState = options.length > 0 && value.length === 0 ? 'error' : 'default';
      return (
        <div
          key={this.props.key ? this.props.key : 'column-selector'}
        >
          <CheckboxSelect
            className="checkbox-select"
            classNamePrefix="select"
            options={options}
            value={value}
            placeholder="Select columns"
            appearance="default"
            validationState={validationState}
            onChange={this.onChange}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  renderOLD() {
    const options = this.state.options;
    if (options && options.length > 0) {

      return (
        <div
          key={this.props.key ? this.props.key : 'column-selector'}
        >
          <Select
            label={this.state.label}
            className="single-select"
            classNamePrefix="react-select"
            placeholder={undefined}
            options={this.state.options}
            onChange={this.onChange}
          />
        </div>
      );
    } else {
      return null;
    }
  };

}
