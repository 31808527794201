import util from '../../util/Util';
import DriveFolder from './DriveFolder';

export default class DriveFolderBuilder {

  folderOptions: DriveFolder;
  parentPath = '';

  constructor() {
    this.folderOptions = {
      uuid: util.uuid(),
      path: '',
      name: 'New folder',
      childFolders: [],
      isRoot: false
    };
  }

  setUuid = (uuid: string) => {
    this.folderOptions.uuid = uuid;
    return this;
  };

  setIsRoot = (isRoot: boolean) => {
    this.folderOptions.isRoot = isRoot;
   return this;
  };

  setParentFolder = (parent: DriveFolder) => {
    this.parentPath = parent.path;
    return this;
  };

  setParentPath = (parentPath: string) => {
    this.parentPath = parentPath;
    return this;
  };

  setName = (name: string) => {
    this.folderOptions.name = name;
    return this;
  };

  addChildFolder = (childFolder: DriveFolder) => {
    this.folderOptions.childFolders.push(childFolder);
    return this;
  };

  setChildFolders = (childFolders: DriveFolder[]) => {
    this.folderOptions.childFolders = childFolders;
    return this;
  };

  build = (): DriveFolder => {
    const folder: DriveFolder = {
      uuid: this.folderOptions.uuid,
      path: this.parentPath + '/' + this.folderOptions.uuid,
      name: this.folderOptions.name,
      childFolders: this.folderOptions.childFolders,
      isRoot: false
    };
    if (this.folderOptions.isRoot) {
      folder.isRoot = true;
    }
    return folder;
  }

}
