const colorRange = {};

colorRange.computeColor = function(factorFromStart) {
  return colorRange._computeColorInRange(colorRange.startColorRgb, colorRange.endColorRgb, factorFromStart);
};

colorRange._setColorBounds = function(startColor, endColor) {
  colorRange.startColorRgb = colorRange._colorToRgb(startColor);
  colorRange.endColorRgb = colorRange._colorToRgb(endColor);
  colorRange.colorBoundsSet = true;
}

colorRange._computeColorInRange = (startColorRgb, endColorRgb, factorFromStart) => {
  const red = Math.round(startColorRgb.red + factorFromStart * (endColorRgb.red - startColorRgb.red));
  const green = Math.round(startColorRgb.green + factorFromStart * (endColorRgb.green - startColorRgb.green));
  const blue = Math.round(startColorRgb.blue + factorFromStart * (endColorRgb.blue - startColorRgb.blue));
  const color = '#' + colorRange._toHex(red) + colorRange._toHex(green) + colorRange._toHex(blue);
  return color;
};

colorRange._colorToRgb = function(colorHex) {
  const redHex = colorHex.substring(1, 3);
  const greenHex = colorHex.substring(3, 5);
  const blueHex = colorHex.substring(5, 7);
  const redComponent = parseInt(redHex, 16);
  const greenComponent = parseInt(greenHex, 16);
  const blueComponent = parseInt(blueHex, 16);
  return {
    red: redComponent,
    green: greenComponent,
    blue: blueComponent
  }
};

colorRange._toHex = function(colorComponentInteger) {
  let hexString = colorComponentInteger.toString(16);
  if (colorComponentInteger < 16) {
    hexString = '0' + hexString;
  }
  return hexString;
};

// if (!colorRange.colorBoundsSet) {
//   const startColor = '#4C9AFF'; // Arvo Breeze
//   const endColor = '#0747A6'; // Chore Coat
//   // const startColor = '#DE350B'; // Dirt red
//   // const endColor = '#00875A'; // Slime
//   colorRange._setColorBounds(startColor, endColor);
// }

module.exports = colorRange;
