import React, { PureComponent } from "react";
import widgetUtil from '../../components/widget/WidgetUtil';

export default class StatementAnnotation extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    const html = widgetUtil.markdownToHtml(props.text);
    return {
      scoreMeta: props.scoreMeta,
      // score: props.score,
      assessmentItem: props.assessmentItem,
      text: props.text,
      html: html
    };
  };

  computeColor = () => {
    // return this.state.scoreMeta.scorePercentToColour(this.state.score);
    return this.state.scoreMeta.assessmentItemToColour(this.state.assessmentItem);
  };

  render() {
    const style = {
      whiteSpace: 'pre-line',
      display: 'inline-block',
      marginLeft: this.props.marginLeft ? this.props.marginLeft : '0.3em',
      fontStyle: 'italic',
      fontWeight: 550,
      fontSize: '0.9em',
      color: this.computeColor()
    };
    return (
      <div
        className="rich-text"
        style={style}
        dangerouslySetInnerHTML={{__html: this.state.html}}
      >
      </div>
    );
  }

}
