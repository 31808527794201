const constants = require('../commonConstants');

const navUtil = {};

navUtil.buildStaticDefinitionLink = function(definitionUuid, definitionName) {
  const url = navUtil.buildStaticDefinitionURL(definitionUuid, definitionName);
  return navUtil.link(url, definitionName);
};

navUtil.buildStaticDefinitionURL = function(definitionUuid, definitionName) {
  const encodedName = encodeURIComponent(definitionName);
  return `${constants.appBaseUrl}/view/${encodedName}/${definitionUuid}`;
};

navUtil.buildInterativeDefinitionLink = function(definitionUuid, definitionName) {
  const url = navUtil.buildInterativeDefinitionURL(definitionUuid, definitionName);
  return navUtil.link(url, definitionName);
};

navUtil.buildInterativeDefinitionURL = function(definitionUuid, definitionName) {
  const encodedDefinitionName = encodeURIComponent(definitionName);
  return `${constants.appBaseUrl}/#/view/:${definitionUuid}/:${encodedDefinitionName}`;
};

navUtil.buildInterativeAssessmentURL = function(definitionUuid, definitionName, assessmentUuid, assessmentName) {
  const encodedDefinitionName = encodeURIComponent(definitionName);
  const encodedAssessmentName = encodeURIComponent(definitionName);
  return `${constants.appBaseUrl}/#/ass/:${assessmentUuid}/:${encodedAssessmentName}`;
};

navUtil.buildStaticAssessmentURL = function(definitionUuid, definitionName, assessmentUuid, assessmentName) {
  const encodedDefinitionName = encodeURIComponent(definitionName);
  const encodedAssessmentName = encodeURIComponent(assessmentName);
  return `${constants.appBaseUrl}/review/${encodedAssessmentName}/${encodedDefinitionName}/${definitionUuid}/${assessmentUuid}`;
};

navUtil.buildStaticAssessmentsURL = function(definitionUuid, definitionName, assessmentUuidsToNames) {
  let assessmentNames = '';
  let assessmentUuidsCsv = '';
  let nextNameSeparator = '';
  let nextCsvSeparator = '';
  const assessmentUuids = Object.keys(assessmentUuidsToNames);
  for (const assessmentUuid of assessmentUuids) {
    const assessmentName = assessmentUuidsToNames[assessmentUuid];
    assessmentNames += nextNameSeparator + assessmentName;
    assessmentUuidsCsv += nextCsvSeparator + assessmentUuid;
    nextNameSeparator = ' vs ';
    nextCsvSeparator = ',';
  }
  const encodedDefinitionName = encodeURIComponent(definitionName);
  const encodedAssessmentNames = encodeURIComponent(assessmentNames);
  return `${constants.appBaseUrl}/review/${encodedAssessmentNames}/${encodedDefinitionName}/${definitionUuid}/${assessmentUuidsCsv}`;
};

navUtil.buildBlogURL = function(blogId, blogTitle, keyValuePairs) {
  const encodedTitle = encodeURIComponent(blogTitle);
  let url = `${constants.appBaseUrl}/blog/${encodedTitle}/${blogId}`;
  url = navUtil._addKeyValuePairs(url, keyValuePairs);
  return url;
};

navUtil.link = function(url, text) {
  const linkText = text ? text : url;
  return `<a href="${url}">${linkText}</a>`;
};

navUtil._addKeyValuePairs = (url, keyValuePairs) => {
  if (keyValuePairs) {
    let nextSeparator = url.indexOf('?') > 0 ? '&' : '?';
    const keys = Object.keys(keyValuePairs);
    for (const key of keys) {
      const value = keyValuePairs[key];
      url += nextSeparator + key + '=' + encodeURIComponent(value);
      nextSeparator = '&';
    }
  }
  return url;
};

module.exports = navUtil;
