import analytics from '../../shared/model/analytics/Analytics';
import firebaseApp from '../firebase/FirebaseApp';

export default class DriveDatabasePersistence {

  analyticsEntityName = 'drive-state';

  constructor() {
    this.drivePath = 'drive';
  }

  saveFolder = (user, folder) => {
    analytics.databaseWrite(this.analyticsEntityName);
    const key = this._buildFolderKey(user, folder.path);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .set(folder);
  };

  getFolder = (user, folderPath) => {
    analytics.databaseRead(this.analyticsEntityName);
    const key = this._buildFolderKey(user, folderPath);
    return firebaseApp
      .getDatabase()
      .ref(key)
      .once('value')
      .then((snapshot) => {
        const folder = snapshot.val();
        // Ensure the childFolders array exists...
        if (folder && !folder.childFolders) {
          folder.childFolders = [];
        }
        return folder;
      });
  };

  _buildFolderKey = (user, folderPath) => {
    const userId = user.getId();
    return this.drivePath + '/' + userId + folderPath;
  };

}
