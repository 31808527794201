
const util = {};

util.janMonthIndex = 0;
util.febMonthIndex = 1;
util.marMonthIndex = 2;
util.aprMonthIndex = 3;
util.mapMonthIndex = 4;
util.junMonthIndex = 5;
util.julMonthIndex = 6;
util.augMonthIndex = 7;
util.sepMonthIndex = 8;
util.octMonthIndex = 9;
util.novMonthIndex = 10;
util.decMonthIndex = 11;

util.indexedDayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

util.indexedMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

util.indexedMonthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

util.getDayName = function(date) {
  const day = date.getDay();
  return util.indexedDayNames[day];
}

util.getTwoDigitYear = function(date) {
  const fourDigitYear = date.getFullYear();
  return fourDigitYear.toString().substring(2);
};

util.getMonthName = function(date) {
  return util.indexedMonthNames[date.getMonth()];
};

util.getMonthShortName = function(date) {
  return util.indexedMonthShortNames[date.getMonth()];
};

util.parseDate = function(dateISOText, dateFormat) {
  const timestamp = Date.parse(dateISOText);
  const date = new Date(timestamp);
  return date;
};

util.parseAndFormatDate = function(dateISOText, dateFormat) {
  const timestamp = Date.parse(dateISOText);
  const date = new Date(timestamp);
  return util.formatDate(date);
};

util.dateFormat = 'MMM DD, YYYY';

util.formatDate = function(date) {
  return `${util.getMonthName(date)} ${date.getDate()}, ${date.getFullYear()}`;
};

util.formatDateRange = function(firstDate, lastDate) {
  // return `${util.formatDate(firstDate)} to ${util.formatDate(lastDate)}`;

  const firstDayOfMonth = firstDate.getDate();
  const lastDayOfMonth = lastDate.getDate();
  const firstMonthName = util.getMonthName(firstDate);
  const lastMonthName = util.getMonthName(lastDate);
  const firstYear = firstDate.getFullYear();
  const lastYear = lastDate.getFullYear();

  if (firstYear === lastYear) {
    if (firstMonthName === lastMonthName) {
      return `${firstDayOfMonth} to ${lastDayOfMonth} of ${firstMonthName}, ${firstYear}`;
    } else {
      return `${firstDayOfMonth} ${firstMonthName} to ${lastDayOfMonth} ${lastMonthName}, ${firstYear}`;
    }
  } else {
    return `${util.formatDate(firstDate)} to ${util.formatDate(lastDate)}`;
  }
};

util.conciseFormatDateRange = function(firstDate, lastDate) {
  return `${util.formatConciseDate(firstDate)} to ${util.formatConciseDate(lastDate)}`;
};

util.formatConciseDate = function(date) {
  return `${date.getDate()}-${util.getMonthShortName(date)}-${util.getTwoDigitYear(date)}`;
};

util.formatAs_MMM_YYYY = function(optionalDate) {
  const date = optionalDate ? optionalDate : new Date();
  return `${util.getMonthName(date)}-${date.getFullYear()}`;
}

util.formatAs_M_YYYY = function(optionalDate) {
  const date = optionalDate ? optionalDate : new Date();
  return `${util.getMonthShortName(date)}-${date.getFullYear()}`;
}

util.formatAsRSSDate = function(optionalDate) {
  const date = optionalDate ? optionalDate : new Date();
  return date.toUTCString();
}

util.isSameTime = function(dateA, dateB) {
  if (dateA && dateB) {
    return dateA.getTime() === dateB.getTime();
  } else if (dateA) {
    return false;
  } else {
    // both undefined
    return true;
  }
}

util.isSameDay = function(dateA, dateB) {
  const yearA = dateA.getFullYear();
  const yearB = dateB.getFullYear();
  const monthA = dateA.getMonth();
  const monthB = dateB.getMonth();
  const dayOfMonthA = dateA.getDate();
  const dayOfMonthB = dateB.getDate();
  const sameDay = dayOfMonthA === dayOfMonthB && monthA === monthB && yearA === yearB;
  return sameDay;
}

util.rollDayForwards = function(date) {
  const nextDay = new Date(date.getTime());
  const nextDayOfMonth = date.getDate() + 1;
  nextDay.setDate(nextDayOfMonth);
  return nextDay;
}

util.rollDayBackwards = function(date) {
  const prevDay = new Date(date.getTime());
  const prevDayOfMonth = date.getDate() - 1;
  prevDay.setDate(prevDayOfMonth);
  return prevDay;
}

util.rollWeeks = function(date, weeksOffset) {
  const newDate = new Date(date.getTime());
  newDate.setDate(date.getDate() + 7 * weeksOffset);
  return newDate;
}

util.rollMonths = function(date, monthsOffset) {
  const newDate = new Date(date.getTime());
  newDate.setMonth(date.getMonth() + monthsOffset);
  return newDate;
}

util.unionOfDates = (a, b) => {
  const aStartTime = a[0].getTime();
  const aEndTime = a[a.length - 1].getTime();
  const bStartTime = b[0].getTime();
  const bEndTime = b[b.length - 1].getTime();
  const startTime = Math.min(aStartTime, bStartTime);
  const endTime = Math.max(aEndTime, bEndTime);
  let union = [];
  let date = new Date(startTime);
  while (date.getTime() <= endTime) {
    union.push(date);
    date = util.rollDayForwards(date);
  }
  return union;
}

util.getDatesOfWeek = function(optionalDateWithinWeek) {
  const datesOfWeek = [];
  const weekDateRange = util.getWeekDateRange(optionalDateWithinWeek);
  const firstDayOfWeek = weekDateRange[0];
  const lastDayOfWeek = weekDateRange[1];
  let dayOfWeek = firstDayOfWeek;
  while (dayOfWeek.getTime() <= lastDayOfWeek.getTime()) {
    datesOfWeek.push(dayOfWeek);
    dayOfWeek = util.rollDayForwards(dayOfWeek);
  }
  // console.log(`dateUtil.getDatesOfWeek: ${datesOfWeek[0]} to ${datesOfWeek[datesOfWeek.length - 1]}`);
  return datesOfWeek;
}

util.getDatesOfMonth = function(optionalDateWithinWeek) {
  const datesOfMonth = [];
  const monthDateRange = util.getMonthDateRange(optionalDateWithinWeek);
  const firstDayOfMonth = monthDateRange[0];
  const lastDayOfMonth = monthDateRange[1];
  let dayOfMonth = firstDayOfMonth;
  while (dayOfMonth.getTime() <= lastDayOfMonth.getTime()) {
    datesOfMonth.push(dayOfMonth);
    dayOfMonth = util.rollDayForwards(dayOfMonth);
  }
  // console.log(`dateUtil.getDatesOfMonth: ${datesOfMonth[0]} to ${datesOfMonth[datesOfMonth.length - 1]}`);
  return datesOfMonth;
}

util.getWeekDateRange = function(optionalDateWithinWeek) {
  const dateWithinWeek = optionalDateWithinWeek ? optionalDateWithinWeek : new Date();
  // Sunday = 0, Saturday = 6
  const dayOfWeekIndex = dateWithinWeek.getDay();

  // console.log('dateUtil.getWeekDateRange: dateWithinWeek:', dateWithinWeek);
  // console.log('dateUtil.getWeekDateRange: dateWithinWeek.getMonth():', dateWithinWeek.getMonth());
  const startOfWeek = new Date(dateWithinWeek.getTime());
  // console.log('dateUtil.getWeekDateRange: A startOfWeek:', startOfWeek);
  startOfWeek.setFullYear(dateWithinWeek.getFullYear());
  // console.log('dateUtil.getWeekDateRange: B startOfWeek:', startOfWeek);
  startOfWeek.setMonth(dateWithinWeek.getMonth());
  // console.log('dateUtil.getWeekDateRange: C startOfWeek:', startOfWeek);
  startOfWeek.setDate(dateWithinWeek.getDate() - dayOfWeekIndex);
  startOfWeek.setHours(0);
  startOfWeek.setMinutes(0);
  startOfWeek.setSeconds(0);
  startOfWeek.setMilliseconds(0);
  // console.log('dateUtil.getWeekDateRange: D startOfWeek:', startOfWeek);
  // console.log('dateUtil.getWeekDateRange: startOfWeek.getMonth():', startOfWeek.getMonth());

  const endOfWeek = new Date(dateWithinWeek.getTime());
  endOfWeek.setFullYear(dateWithinWeek.getFullYear());
  endOfWeek.setMonth(dateWithinWeek.getMonth());
  endOfWeek.setDate(dateWithinWeek.getDate() + 6 - dayOfWeekIndex);
  endOfWeek.setHours(23);
  endOfWeek.setMinutes(59);
  endOfWeek.setSeconds(59);
  // endOfWeek.setMilliseconds(999);
  endOfWeek.setMilliseconds(0);

  // const millisInWeek = endOfWeek.getTime() - startOfWeek.getTime();

  return [startOfWeek, endOfWeek];
}

util.getMonthDateRange = function(optionalDateWithinMonth) {
  const dateWithinMonth = optionalDateWithinMonth ? optionalDateWithinMonth : new Date();
  // console.log('dateUtil.getMonthDateRange: dateWithinMonth:', dateWithinMonth);
  // console.log('dateUtil.getMonthDateRange: dateWithinMonth.getMonth():', dateWithinMonth.getMonth());
  const startOfMonth = new Date(dateWithinMonth.getTime());
  // console.log('dateUtil.getMonthDateRange: A startOfMonth:', startOfMonth);
  startOfMonth.setFullYear(dateWithinMonth.getFullYear());
  // console.log('dateUtil.getMonthDateRange: B startOfMonth:', startOfMonth);
  startOfMonth.setMonth(dateWithinMonth.getMonth());
  // console.log('dateUtil.getMonthDateRange: C startOfMonth:', startOfMonth);
  startOfMonth.setDate(1);
  startOfMonth.setHours(0);
  startOfMonth.setMinutes(0);
  startOfMonth.setSeconds(0);
  startOfMonth.setMilliseconds(0);
  // console.log('dateUtil.getMonthDateRange: D startOfMonth:', startOfMonth);
  // console.log('dateUtil.getMonthDateRange: startOfMonth.getMonth():', startOfMonth.getMonth());

  const endOfMonth = new Date(dateWithinMonth.getTime());
  endOfMonth.setFullYear(dateWithinMonth.getFullYear());
  endOfMonth.setMonth(dateWithinMonth.getMonth() + 1);
  endOfMonth.setDate(0); // this sets it to the end
  endOfMonth.setHours(23);
  endOfMonth.setMinutes(59);
  endOfMonth.setSeconds(59);
  // endOfMonth.setMilliseconds(999);
  endOfMonth.setMilliseconds(0);
  // console.log('dateUtil.getMonthDateRange: endOfMonth:', endOfMonth);

  // Special case to handle when optionalDateWithinMonth is the last day of the month (e.g. 31 Oct)
  if (endOfMonth.getMonth() !== startOfMonth.getMonth()) {
    endOfMonth.setMonth(startOfMonth.getMonth());
    endOfMonth.setDate(endOfMonth.getDate() + 1);
  }

  // console.log('startOfMonth:', startOfMonth);
  // console.log('endOfMonth:', endOfMonth);
  // debugger;

  return [startOfMonth, endOfMonth];
}

module.exports = util;
