import IUser from './IUser';

class UserUtil {

  guessFirstName = (user: IUser) => {
    const displayName = user.getDisplayName();
    if (displayName) {
      const nameParts = displayName.split(' ');
      if (nameParts && nameParts.length > 1) {
        const firstName = nameParts[0].trim();
        if (firstName) {
          return firstName;
        } else {
          return displayName;
        }
      } else {
        return displayName;
      }
    } else {
      return '';
    }
  };

}

export default new UserUtil();
