import entityTypeDefinitions from '../../../shared/model/rubric/EntityTypeDefinitions';
import rubricDAO from '../RubricDAO';
import notificationDAO from '../NotificationDAO';
import storageDAO from '../StorageDAO';
import NotificationBuilder from '../../../shared/model/rubric/NotificationBuilder';
import paymentProviderDefinitions from '../../../shared/model/payment/PaymentProviderDefinitions';
import planDefinitions from "../../../shared/model/payment/PlanDefinitions";
import persistenceFactory from '../PersistenceFactory';
import shareStateDefinitions from "../../../shared/model/rubric/ShareStateDefinitions";
import userConstants from './UserConstants';

export default class MockDataCreator {

  create = () => {
    const mockDataSetup = 'mock-data-setup';
    return storageDAO.getString(mockDataSetup, 'false').then((value) => {
      if (value === 'true') {
        return false;
      } else {
        return this._createMockData().then(() => {
          storageDAO.setString(mockDataSetup, 'true');
          return true;
        });
      }
    });
  };

  _createMockData = () => {
    const promises = [];
    const mockUsers = userConstants.users;
    for (let i = 0; i < mockUsers.length; i++) {
      const mockUser = mockUsers[i];
      this._setupDataForUser(mockUser, promises);
      // if (mockUser.isPro()) {
      //   promises.push(this._createSubscription(mockUser));
      // }
    }
    return Promise.all(promises);
  };

  _createSubscription = (mockUser) => {
    const userId = mockUser.getId();
    return persistenceFactory.subscriptionsPersistence.getSubscription(userId).then((subscriptionData) => {
      if (subscriptionData) {

      } else {
        return persistenceFactory.subscriptionsPersistence.addSubscription(
          planDefinitions.proType,
          userId,
          'dummy-payment-provider-token',
          paymentProviderDefinitions.stripePaymentProviderType);
      }
    });
  };

  _setupDataForUser = (currentUser, promises) => {
    const definitionUuid = 'bookReview';
    const definitionName = 'Book review';

    const mockUsers = userConstants.users;
    for (let i = 0; i < mockUsers.length; i++) {
      const mockUser = mockUsers[i];
      if (mockUser.getId() !== currentUser.getId()) {
        this._setupDefinitionNotification(definitionUuid, definitionName, currentUser, mockUser);
      }
    }

    const assessmentOwners = [userConstants.fooUser, userConstants.barUser];
    for (let j = 0; j < assessmentOwners.length; j++) {
      const assessmentOwner = assessmentOwners[j];
      if (currentUser.getId() !== assessmentOwner.getId()) {
        const promise = rubricDAO.getAssessmentsByDefinitionUuidAndUser(
            definitionUuid, assessmentOwner).then((assessments) => {
          for (let i = 0; i < assessments.length; i++) {
            const assessment = assessments[i];
            const entityTitle = `${assessment.name} of ${definitionName}`;
            const notificationPromise = notificationDAO.searchNotificationsByTypeAndTitle(
              entityTypeDefinitions.assessmentEntityType, entityTitle
            )
              .then((notifications) => {
                if (notifications.length === 0) {
                  const message = `${assessmentOwner.getDisplayName()} has shared assessment "${assessment.name}" of "${definitionName}" with you.`;
                  const sharedAssessment = new NotificationBuilder()
                    .setFromUserId(assessmentOwner.getId())
                    .setTargetUserEmail(currentUser.getEmail())
                    .setShareState(shareStateDefinitions.outstandingShareState)
                    .setTitle(entityTitle)
                    .setMessage(message)
                    .setDefinitionUuid(definitionUuid)
                    .setDefinitionName(definitionName)
                    .setAssessmentUuid(assessment.uuid)
                    .setAssessmentName(assessment.name)
                    .setEntityType(entityTypeDefinitions.assessmentEntityType)
                    .build();
                  const savePromise = notificationDAO.saveNotification(sharedAssessment);
                  promises.push(savePromise);
                }
              });
            promises.push(notificationPromise);
          }
        });
        promises.push(promise);
      }
    }
  };

  _setupDefinitionNotification = (definitionUuid, definitionName, fromUser, toUser) => {
    return notificationDAO.searchNotificationsByTypeAndTitle(
      entityTypeDefinitions.definitionEntityType, definitionName
    )
      .then((notifications) => {
        if (notifications.length === 0) {
          const message = `${fromUser.getDisplayName()} has shared rubric "${definitionName}" with you.`;
          const notification = new NotificationBuilder()
            .setFromUserId(fromUser.getId())
            .setTargetUserEmail(toUser.getEmail())
            .setShareState(shareStateDefinitions.acceptedShareState)
            .setTitle(definitionName)
            .setMessage(message)
            .setDefinitionUuid(definitionUuid)
            .setDefinitionName(definitionName)
            .setEntityType(entityTypeDefinitions.definitionEntityType)
            .build();
          notificationDAO.saveNotification(notification);
        }
      });
  };

}
