import constants from '../../../shared/model/Constants';
import navConstants from './NavConstants';
import urlUtil from '../../../shared/util/UrlUtil';

class NavUtil {

  setPath = (history, pageHash, arg1, arg2) => {
    const newPathname = this.makePath(pageHash, arg1, arg2);
    const currentPathname = history.location;
    if (newPathname === currentPathname) {
      // do nothing
    } else {
      history.push(newPathname);
    }
  };

  makePath = (pageHash: string, arg1?: string, arg2?: string) => {
    let newPathname = pageHash;
    if (arg1) {
      newPathname = newPathname + '/:' + arg1;
    }
    if (arg2) {
      newPathname = newPathname + '/:' + arg2;
    }
    return newPathname;
  };

  encodeURIComponent = (component) => {
    // let encoded = component.replace(' ', '+');
    // encoded = encodeURIComponent(encoded);
    let encoded = encodeURIComponent(component);
    encoded = encoded.replace('%20', '+');
    return encoded;
  };

  buildDefinitionHash = (definitionUuid: string, definitionName: string, optionalRowAnchorId?: string) => {
    let hash = navConstants.viewPageHash + '/:' + definitionUuid + '/' + this.encodeURIComponent(definitionName);
    if (optionalRowAnchorId) {
      hash += '/:' + urlUtil.anchorPrefix + optionalRowAnchorId;
    }
    return hash;
  };

  buildDefinitionUrl = (definitionUuid: string, definitionName: string, optionalRowAnchorId?: string) => {
    const hash = this.buildDefinitionHash(definitionUuid, definitionName, optionalRowAnchorId);
    return constants.appBaseUrl + '/#' + hash;
  };

  buildAssessmentUrl = (assessmentUuid: string, assessmentName?: string) => {
    let hash = navConstants.assessmentEntryPointPageHash + '/' + assessmentUuid;
    if (assessmentName) {
      hash += '/' + this.encodeURIComponent(assessmentName);
    }
    return constants.appBaseUrl + '/#' + hash;
  };

  buildSeoDefinitionEntryPointUrl = (definitionUuid: string, definitionName: string) => {
    const path = 'view/' + encodeURIComponent(definitionName) + '/' + definitionUuid;
    return constants.appBaseUrl + '/' + path;
  };

  buildSeoAssessmentEntryPointUrl = (definitionUuid: string, definitionName: string, assessmentUuid: string, assessmentName: string) => {
    const assessmentNames = encodeURIComponent(assessmentName);
    const path = 'review/' + assessmentNames + '/' + definitionName + '/' + definitionUuid + '/' + assessmentUuid;
    return constants.appBaseUrl + '/' + path;
  };

  buildPageUrl = (pageHash) => {
    return constants.appBaseUrl + '/#' + pageHash;
  };

  computeViewDimensions = (sidebarExpanded) => {
    const globalSidebarWidth = navConstants.globalSidebarWidth;
    const collapsedSecondarySidebarWidth = navConstants.collapsedSecondarySidebarWidth;
    const expandedSecondarySidebarWidth = navConstants.expandedSecondarySidebarWidth;
    const sidebarWidth = sidebarExpanded ? globalSidebarWidth + expandedSecondarySidebarWidth : globalSidebarWidth + collapsedSecondarySidebarWidth;
    const centerX = sidebarWidth / 2 + window.innerWidth / 2;
    const viewWidth = window.innerWidth - sidebarWidth;
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      sidebarWidth: sidebarWidth,
      centerX: centerX,
      viewWidth: viewWidth
    }
  };

}

export default new NavUtil();
