import React, { PureComponent } from 'react';
import { Spotlight } from '@atlaskit/onboarding';
import session from '../../../shared/model/auth/Session';
import preferencesDAO from '../../../backend/data/PreferencesDAO';

export default class UserOnboarding extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  componentDidUpdate = () => {
    if (this.props.preferenceKey && !this.state.evaluated) {
      const user = session.getCurrentUser();
      if (user) {
        const preferenceKey = `onboarding-spotlight-${this.props.preferenceKey}`;
        preferencesDAO.getPreference(user, preferenceKey, undefined)
          .then((preference) => {
            this.setState({
              evaluated: true
            });
            if (preference) {

            } else {
              this.setState({
                active: true
              });
              setTimeout(() => {this.markAsShown(user, preferenceKey)}, 1000);
            }
          });
      }  
    }
  }

  markAsShown = (user, preferenceKey) => {
    preferencesDAO.setPreference(user, preferenceKey, 'shown');
  };

  buildStateFromProps = (props) => {
    const active = false;
    // const dismissed = false;
    const state = {
      // key: props.preferenceKey,
      enabled: props.enabled,
      // dismissed: props.dismissed,
      active: active,
      spotlightTarget: props.spotlightTarget,
      dialogPlacement: props.dialogPlacement,
      heading: props.heading,
      content: props.content
    };
    return state;
  };

  onCloseSpotlightClick = () => {
    this.setState({
      active: false
    });
  };

  renderSpotlight = () => {
    return (
      <Spotlight
        key={this.props.preferenceKey}
        target={this.props.target}
        dialogPlacement={this.props.dialogPlacement}
        heading={this.props.heading}
        actions={[{ onClick: this.onCloseSpotlightClick, text: 'Got it' }]}
      >
        {this.state.content}
      </Spotlight>
    );
  }

  render() {
    if (this.state.active) {
      return this.renderSpotlight();
    } else {
      return null;
    }
  };

}
