
export type StatusConfig = {
  text: string,
  appearance: 'default' | 'inprogress' | 'moved' | 'new' | 'removed' | 'success'
};

class StatusDefinitions {

  _defaultStatusId = 'draft';
  _defaultStatusConfig: StatusConfig = {
    text: 'Draft',
    appearance: 'default'
  };
  _statusIdsToConfigs = {
    draft: this._defaultStatusConfig,
    inProgress: {
      text: 'In progress',
      appearance: 'inprogress'
    },
    done: {
      text: 'Done',
      appearance: 'success'
    },
    obsolete: {
      text: 'Obsolete',
      appearance: 'moved'
    }
  }

  getDefaultStatusId = () => {
    return this._defaultStatusId;
  };

  getDefaultStatusConfig = (): StatusConfig => {
    return this._defaultStatusConfig;
  };

  getStatusIdsToConfigs = () => {
    return this._statusIdsToConfigs;
  };

}

export default new StatusDefinitions();
