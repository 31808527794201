import globalStatusController from './GlobalStatusController';
import statusDefinitions from './StatusDefinitions';

export default class StatusProvider {

  active: boolean = false;
  key: any;
  currentStatus: any;
  overridingMessage: undefined | string = undefined;

  constructor(key) {
    this.key = key;
    this.currentStatus = statusDefinitions.statuses.nil;
  }

  setCurrentStatus = (currentStatus, overridingMessage?: undefined | string) => {
    this.currentStatus = currentStatus;
    this.overridingMessage = overridingMessage;
    if (this.active) {
      globalStatusController.recomputeCurrentStatusInfo();
    }
  };

  getCurrentStatusInfo = () => {
    return {
      status: this.currentStatus,
      overridingMessage: this.overridingMessage
    }
  };

  // getCurrentStatus = () => {
  //   return this.currentStatus;
  // };
  //
  // getOverridingMessage = () => {
  //   return this.overridingMessage;
  // };

  setActive = (active: boolean) => {
    this.active = active;
    if (active) {
      globalStatusController.addProvider(this);
    } else {
      globalStatusController.removeProvider(this);
      globalStatusController.recomputeCurrentStatusInfo();
    }
  };

  isActive = () => {
    return this.active;
  }

}
