import MockDataCreator from './MockDataCreator';
import SystemDataCreator from './SystemDataCreator';
import environment from "../../../shared/model/Environment";

class CannedDataCreator {

  create = () => {
    const systemDataCreator = new SystemDataCreator();
    return systemDataCreator.create().then(() => {
      if (environment.mockData()) {
        const mockDataCreator = new MockDataCreator();
        mockDataCreator.create();
      }
    });
  };

}

export default new CannedDataCreator();
