import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import HelpView from '../components/rubric/help/HelpView';
import LiftedPanel from '../components/widget/LiftedPanel';
import PageTitle from '../components/widget/PageTitle';
import rubricPageHelper from './RubricPageHelper';

interface Props {

}

interface State {
}

export default class HelpPage extends PureComponent<Props, State> {

  componentDidMount() {
    rubricPageHelper.updateTitle('Help');
  }

  render() {
    return (
      <ContentWrapper>
        <div style={{ marginTop: '10px' }}>
          <LiftedPanel>
            <PageTitle>Help</PageTitle>
          </LiftedPanel>
        </div>
        <div style={{ marginTop: '10px' }}>
          <LiftedPanel>
            <HelpView/>
          </LiftedPanel>
        </div>
      </ContentWrapper>
    );
  }

}
