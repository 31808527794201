import environment from '../../../shared/model/Environment';
import scriptLoader from '../../../shared/util/ScriptLoader.ts';
import mockJavascriptAPI from './acjs/MockJavascriptAPI';

class ConnectJavascriptApi {

  dummyAP = {};
  cachedAP = this.dummyAP;

  moduleKeyOf = (key) => {
    const baseUrlLower = window.location.href.toLowerCase();
    const runningLocally = baseUrlLower.indexOf('ngrok') >= 0 || baseUrlLower.indexOf('localhost') >= 0;
    const keyPrefix = runningLocally ? 'local-' : '';
    return `${keyPrefix}${key}`;
  };

  loadAllJs = (options) => {
    // console.log('Roobrick: loading all.js...');
    return scriptLoader.loadScript('https://connect-cdn.atl-paas.net/all.js', options)
      .then(() => {
        // console.log('Roobrick: all.js loaded');
        this.cachedAP = window.AP;
        return undefined;
      });
  }

  getAP = () => {
    if (this.cachedAP) {
      return this.cachedAP;
    } else if (window.AP) {
      this.cachedAP = window.AP;
      return this.cachedAP;
    } else if (environment.isLocalHost) {
      this.cachedAP = mockJavascriptAPI.buildMockAP();
      return this.cachedAP;
    } else {
      // return undefined;
      return this.dummyAP;
    }
  }
  
}

export default new ConnectJavascriptApi();
