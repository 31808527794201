import React, { PureComponent } from 'react';
import roleDefinitions from '../../../shared/model/auth/RoleDefinitions';
import SelectOption from '../../../shared/model/widget/SelectOption';
import SingleSelect from '../widget/SingleSelect';

interface Props {
  hideLabel: boolean
  roleType: string
  onChange: (selectedRoleType: string) => void
}

interface State {
  hideLabel: boolean
  dirty?: boolean
  roleType: string
}

export default class RoleSelector extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    return {
      hideLabel: props.hideLabel,
      roleType: props.roleType === undefined ?
        this.state.roleType : props.roleType
    };
  };

  onChange = (selection: SelectOption) => {
    this.setState({
      dirty: true,
      roleType: selection.value
    });
    this.props.onChange(selection.value);
  };

  render() {
    const label = this.state.hideLabel ? undefined : 'Role';
    const options: SelectOption[] = roleDefinitions.getOptions();
    let selectedOption: undefined | SelectOption = undefined;
    for (let option of options) {
      if (option.value === this.state.roleType) {
        selectedOption = option;
      }
    }
    return (
      <div style={{ minWidth: '120px'}}>
        <SingleSelect
          label={label}
          options={options}
          defaultSelected={selectedOption}
          placeholder={undefined}
          noMatchesFound={undefined}
          hasAutocomplete
          appearance="default"
          onSelected={this.onChange}
        />
      </div>
    );
  };

}
