import aspectGroupingDefinitions from "../../../../shared/model/rubric/AspectGroupingDefinitions";
import Definition from '../../../../shared/model/rubric/definition/Definition';
import Statement from '../../../../shared/model/rubric/definition/Statement';
import rubricUtil from '../../../../shared/model/rubric/RubricUtil';
import Assessment from '../../../../shared/model/rubric/score/Assessment';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';

export default class ChartModel {

  definition: Definition;
  assessments: Assessment[];
  visibleLabels: string[];
  columnVisibilities: any;
  aspectGroupingsToData: any;
  allowedGroupingTypes: string[];
  statements: Statement[];
  definitionLabels: string[];
  pillars: string[];
  aspectGrouping: any = undefined;

  constructor(definition: Definition, assessments: Assessment[], visibleLabels: string[], columnVisibilities: any) {
    this.definition = definition;
    this.assessments = assessments;
    this.visibleLabels = visibleLabels;
    this.columnVisibilities = columnVisibilities;
    this.aspectGroupingsToData = {};
    this.allowedGroupingTypes = [];
    this.statements = rubricUtil.getDefinitionStatements(this.definition);
    const columnCount = rubricUtil.countColumns(this.definition);
    const statementCount = this.statements.length;

    let statementAspectGroupingAdded = false;
    const defaultToStatementGrouping = columnCount < 2;
    if (defaultToStatementGrouping) {
      // Add the statement aspect grouping first so that this is the default selection...
      if (statementCount > 1 && statementCount < 10) {
        this.allowedGroupingTypes.push(aspectGroupingDefinitions.statementAspectGroupingType);
        statementAspectGroupingAdded = true;
      }
    }

    if (this.assessments.length > 1) {
      this.allowedGroupingTypes.push(aspectGroupingDefinitions.summaryAspectGroupingType);
    }

    this.allowedGroupingTypes.push(aspectGroupingDefinitions.noneAspectGroupingType);

    this.definitionLabels = rubricUtil.getDefinitionLabels(this.definition);
    if (this.definitionLabels && this.definitionLabels.length > 1) {
      this.allowedGroupingTypes.push(aspectGroupingDefinitions.labelAspectGroupingType);
    }

    this.pillars = rubricUtil.getDefinitionPillars(this.definition);
    if (this.pillars && this.pillars.length > 1) {
      this.allowedGroupingTypes.push(aspectGroupingDefinitions.pillarAspectGroupingType);
    }

    if (!statementAspectGroupingAdded) {
      // Add last so it's not the default...
      if (statementCount > 1) {
        this.allowedGroupingTypes.push(aspectGroupingDefinitions.statementAspectGroupingType);
      }
    }
  }

  getOverallScoreByAssessmentUuid = (assessmentUuid, optionalAspectGrouping) => {
    const aspectGrouping = optionalAspectGrouping ? optionalAspectGrouping : aspectGroupingDefinitions.noneAspectGroupingType;
    for (const assessment of this.assessments) {
      if (assessment.uuid === assessmentUuid) {
        const data = this._getAspectGroupng([assessment], aspectGrouping);
        return data.overallScore;
      }
    }
    return undefined;
  };

  getDefaultAspectGrouping = () => {
    return this.allowedGroupingTypes[0];
  };

  isGroupingAllowed = (groupingType) => {
    for (const allowedGroupingType of this.allowedGroupingTypes) {
      if (allowedGroupingType === groupingType) {
        return true;
      }
    }
    return false;
  };

  getSeparatedAssessmentData = (optionalAspectGrouping) => {
    const assessmentUuidsToData = {};
    let segmentCount = 0;
    for (const assessment of this.assessments) {
      const data = this._getAspectGroupng([assessment], optionalAspectGrouping);
      assessmentUuidsToData[assessment.uuid] = data;
      if (!segmentCount) {
        segmentCount = data.segmentCount;
      }
    }
    return {
      segmentCount: segmentCount,
      assessmentUuidsToData: assessmentUuidsToData
    };
  };

  getCombinedAssessmentData = (optionalAspectGrouping) => {
    if (optionalAspectGrouping) {
      this._setAspectGroupng(this.assessments, optionalAspectGrouping);
    } else if (this.aspectGrouping) {
      // no change
    } else {
      this._setAspectGroupng(this.assessments, aspectGroupingDefinitions.noneAspectGroupingType);
    }
    return this.aspectGroupingsToData[this.aspectGrouping];
  };

  _setAspectGroupng = (assessments, aspectGrouping) => {
    this.aspectGrouping = aspectGrouping;
    if (this.aspectGroupingsToData[aspectGrouping]) {
      // do nothing
    } else {
      const data = this._getAspectGroupng(assessments, aspectGrouping);
      this.aspectGroupingsToData[aspectGrouping] = data;
    }
  };

  _getAspectGroupng = (assessments, aspectGrouping): any => {
    let data: any;
    if (aspectGrouping === aspectGroupingDefinitions.summaryAspectGroupingType) {
      const segmentCount = assessments.length;
      const segmentInfos = this._computeSummarySegmentInfos(assessments);
      data = {
        segmentCount: segmentCount,
        segmentInfos: segmentInfos
      }
    } else if (aspectGrouping === aspectGroupingDefinitions.noneAspectGroupingType) {
      const segmentCount = rubricUtil.countRows(this.definition);
      const segmentInfos = this._computeAspectSegmentInfos(assessments);
      data = {
        segmentCount: segmentCount,
        segmentInfos: segmentInfos
      }
    } else if (aspectGrouping === aspectGroupingDefinitions.statementAspectGroupingType) {
      const segmentCount = this.statements.length;
      const segmentInfos = this._computeStatementSegmentInfos(assessments);
      data = {
        statements: this.statements,
        segmentCount: segmentCount,
        segmentInfos: segmentInfos
      }
    } else if (aspectGrouping === aspectGroupingDefinitions.labelAspectGroupingType) {
      const segmentCount = this.definitionLabels.length;
      const segmentInfos = this._computeLabelSegmentInfos(assessments);
      data = {
        definitionLabels: this.definitionLabels,
        segmentCount: segmentCount,
        segmentInfos: segmentInfos
      }
    } else if (aspectGrouping === aspectGroupingDefinitions.pillarAspectGroupingType) {
      const segmentCount = this.pillars.length;
      const segmentInfos = this._computePillarSegmentInfos(assessments);
      data = {
        pillars: this.pillars,
        segmentCount: segmentCount,
        segmentInfos: segmentInfos
      }
    } else {
      throw new Error('Unexpected aspect grouping: ' + aspectGrouping);
    }
    data.overallScore = this._computeOverallScore(data.segmentInfos);
    return data;
  };

  _computeSummarySegmentInfos = (assessments) => {
    const segmentInfos: any[] = [];
    for (const assessment of assessments) {
      const score = rubricUtil.computeOverallAssessmentScore(
        this.definition,
        assessment,
        this.visibleLabels);
      const weight = 1;
      const row = undefined;
      const segmentInfo = {
        uuid: assessment.uuid,
        row: row,
        targetUuid: undefined,
        name: '',
        score: score,
        scoreCount: 1,
        weight: weight,
        weightedScore: score === undefined ? undefined : weight * score
      };
      segmentInfos.push(segmentInfo);
    }
    return segmentInfos;
  };

  _computeAspectSegmentInfos = (assessments) => {
    const segmentInfos: any[] = [];
    const callback = (row, group, groupIndex, rowIndex) => {
      const score = rubricUtil.computeAspectScore(
        this.definition,
        assessments,
        groupIndex,
        rowIndex,
        this.visibleLabels,
        this.columnVisibilities);
      const weight = rubricUtil.getRowWeight(row);
      const segmentInfo = {
        uuid: row.uuid,
        row: row,
        targetUuid: row.uuid,
        name: row.name,
        score: score,
        scoreCount: 1,
        weight: weight,
        weightedScore: score === undefined ? undefined : weight * score
      };
      segmentInfos.push(segmentInfo);
    };
    rubricUtil.iterateRows(this.definition, callback);
    return segmentInfos;
  };

  _computeStatementSegmentInfos = (assessments) => {
    const segmentInfos: any = [];
    const statementUuidsToSegmentInfos = {};
    for (const statement of this.statements) {
      const segmentInfo = {
        uuid: statement.uuid,
        targetUuid: statement.uuid,
        name: statement.text,
        totalScore: 0,
        score: 0,
        scoreCount: 0,
        weight: 1,
        weightedScore: 0
      };
      segmentInfos.push(segmentInfo);
      statementUuidsToSegmentInfos[statement.uuid] = segmentInfo;
    }

    const callback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, label, labelIndex, assessmentItem, context) => {
      const segmentInfo = statementUuidsToSegmentInfos[statement.uuid];
      if (segmentInfo) {
        const weight = rubricUtil.getRowWeight(row);
        segmentInfo.weight = weight;
        if (scoreUtil.hasScore(assessmentItem)) {
          const score = scoreUtil.assessmentItemToScore(assessmentItem);
          segmentInfo.totalScore += score;
          segmentInfo.scoreCount++;
        }
      }
    };
    const context = undefined;
    rubricUtil.iterateVisibleAssessments(
      this.definition,
      assessments,
      this.visibleLabels,
      this.columnVisibilities,
      callback,
      context);

    for (const segmentInfo of segmentInfos) {
      const scoreCount = segmentInfo.scoreCount;
      if (scoreCount) {
        const averageScore = segmentInfo.totalScore / scoreCount;
        segmentInfo.score = averageScore;
        segmentInfo.weightedScore = segmentInfo.weight * averageScore;
      } else {
        segmentInfo.score = undefined;
        segmentInfo.weightedScore = undefined;
      }
    }
    return segmentInfos;
  };

  _computeLabelSegmentInfos = (assessments) => {
    const definitionLabels = this.definition.labels;
    const context = {
      labelsToScoreInfos: {}
    };
    const callback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, label, labelIndex, assessmentItem, context) => {
      let scoreInfo = context.labelsToScoreInfos[label];
      if (!scoreInfo) {
        scoreInfo = {
          scoreTotal: 0,
          scoreCount: 0
        };
        context.labelsToScoreInfos[label] = scoreInfo;
      }
      if (scoreUtil.hasScore(assessmentItem)) {
        const score = scoreUtil.assessmentItemToScore(assessmentItem);
        scoreInfo.scoreTotal += score;
        scoreInfo.scoreCount++;
      }
    };
    rubricUtil.iterateVisibleAssessments(
      this.definition,
      assessments,
      this.visibleLabels,
      this.columnVisibilities,
      callback,
      context);

    const segmentInfos: any = [];
    for (const definitionLabel of definitionLabels) {
      const segmentInfo: any = {
        uuid: definitionLabel,
        targetUuid: undefined,
        name: definitionLabel,
        score: undefined,
        scoreCount: 0,
        weight: 1,
        weightedScore: undefined
      };
      const assessmentItem = context.labelsToScoreInfos[definitionLabel];
      if (assessmentItem) {
        let score = 0;
        if (assessmentItem.scoreCount) {
          score = assessmentItem.scoreTotal / assessmentItem.scoreCount;
          segmentInfo.score = score;
          segmentInfo.weightedScore = score;
          segmentInfo.scoreCount = 1;
        } else {
          segmentInfo.score = undefined;
          segmentInfo.weightedScore = undefined;
        }
        segmentInfos.push(segmentInfo);
      }
    }
    return segmentInfos;
  };

  _computePillarSegmentInfos = (assessments) => {
    const pillarsToScoreInfos: any = {};
    const context = {
      pillars: [],
      pillarsToScoreInfos: pillarsToScoreInfos
    };
    const callback = (row, group, groupIndex, rowIndex, columnIndex, statement, statementIndex, label, labelIndex, assessmentItem, context) => {
      const pillar = rubricUtil.extractPillarFromRowName(row.name);
      if (pillar) {
        let scoreInfo = context.pillarsToScoreInfos[pillar];
        if (!scoreInfo) {
          scoreInfo = {
            scoreTotal: 0,
            scoreCount: 0
          };
          context.pillars.push(pillar);
          context.pillarsToScoreInfos[pillar] = scoreInfo;
        }
        if (scoreUtil.hasScore(assessmentItem)) {
          const score = scoreUtil.assessmentItemToScore(assessmentItem);
          scoreInfo.scoreTotal += score;
          scoreInfo.scoreCount++;
        }
      }
    };
    rubricUtil.iterateVisibleAssessments(
      this.definition,
      assessments,
      this.visibleLabels,
      this.columnVisibilities,
      callback,
      context);

    const segmentInfos: any = [];
    for (let pillarIndex = 0; pillarIndex < context.pillars.length; pillarIndex++) {
      const pillar = context.pillars[pillarIndex];
      const segmentInfo: any = {
        uuid: pillar,
        targetUuid: undefined,
        name: pillar,
        score: undefined,
        scoreCount: 0,
        weight: 1,
        weightedScore: undefined
      };
      const assessmentItem = context.pillarsToScoreInfos[pillar];
      const scoreIsValid = assessmentItem && assessmentItem.scoreCount;
      if (scoreIsValid) {
        const score = assessmentItem.scoreTotal / assessmentItem.scoreCount;
        segmentInfo.score = score;
        segmentInfo.weightedScore = score;
        segmentInfo.scoreCount = 1;
      }
      segmentInfos.push(segmentInfo);
    }
    return segmentInfos;
  };

  _computeOverallScore = (segmentInfos) => {
    let totalWeight = 0;
    let totalWeightedScore = 0;
    let count = 0;
    for (const segmentInfo of segmentInfos) {
      const weightedScore = segmentInfo.weightedScore;
      if (typeof weightedScore === 'number' && segmentInfo.scoreCount) {
        totalWeight += segmentInfo.weight;
        totalWeightedScore += segmentInfo.weightedScore;
        count++;
      }
    }
    if (count > 0) {
      const averageWeight = totalWeight / count;
      const averageWeightedScore = totalWeightedScore / count;
      const average = averageWeightedScore / averageWeight;
      return average;
    } else {
      return undefined;
    }
  };

}