import User from "../../../shared/model/auth/User";

export class UserConstants {

  constructor() {
    this.systemUserId = 'system-user';
    this.fooUserId = 'foo';
    this.barUserId = 'bar';
    this.liteUserId = 'lite';

    this.systemUser = this._newUser(this.systemUserId, 'system', 'nobody@systemusernoneemail.com', undefined, true);

    this.dugaldUser = this._newUser('46WxPElWQuRJAXUqcXWYiy7bODj1', 'Dugald Morrow', 'dugald.morrow@gmail.com', undefined, true);
    this.dugaldAtlassianUser = this._newUser('pS3T9UoafLYbWL39H5xpThfNP3T2', 'Dugald Morrow', 'dmorrow@atlassian.com', undefined, true);

    this.fooUser = this._newUser(this.fooUserId, 'Foo', 'foo@gmail.com', undefined, true);
    this.barUser = this._newUser(this.barUserId, 'Bar', 'bar@gmail.com', undefined, true);
    this.liteUser = this._newUser(this.liteUserId, 'Lite', 'lite@gmail.com', undefined, false);
    this.users = [
      this.dugaldUser,
      this.fooUser,
      this.barUser,
      this.liteUser
    ];
  }

  _newUser = (id, displayName, email, photoURL, pro) => {
    return new User(id, displayName, email, photoURL);
  }

}

export default new UserConstants();
