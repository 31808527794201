import colorRange from '../../../../commonbase/color/redToGreenColorRange';
import BinaryScoreMeta from './BinaryScoreMeta';
import scoreTypes from '../../../../commonbase/score/scoreTypes';

class PassOrFailScoreMeta extends BinaryScoreMeta {

  constructor() {
    super(scoreTypes.passOrFail);
  }

  getColorRange = () => {
    return colorRange;
  }

}

export default new PassOrFailScoreMeta();

