import React, { PureComponent } from 'react';
import Tag from '@atlaskit/tag';
import Tooltip from "@atlaskit/tooltip";
import ToolbarItem from '../../components/widget/toolbar/ToolbarItem';

export default class RowWeight extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props) => {
    return {
      weight: props.weight
    };
  };

  render() {
    return (
      <ToolbarItem>
        <Tooltip content="Row weight">
          <Tag
            isRemovable={false}
            text={this.state.weight}
            color="blueLight"
            appearance="rounded"
          />
        </Tooltip>
      </ToolbarItem>
    );
  };

}
