const scoreTypes = require('./scoreTypes');

const scoreTypesToLegacyTypes = {}
scoreTypesToLegacyTypes[scoreTypes.standard] = [];
scoreTypesToLegacyTypes[scoreTypes.levels] = [];
scoreTypesToLegacyTypes[scoreTypes.yesOrNo] = [];
scoreTypesToLegacyTypes[scoreTypes.passOrFail] = [];
scoreTypesToLegacyTypes[scoreTypes.poorToStrong] = ['oneToFourEdit', 'poorToStrongEdit'];
scoreTypesToLegacyTypes[scoreTypes.limitedToExcellent] = ['oneToFiveEdit', 'limitedToExcellentEdit'];
scoreTypesToLegacyTypes[scoreTypes.zeroToTen] = ['oneToTen'];
scoreTypesToLegacyTypes[scoreTypes.customLevels] = [];

module.exports = scoreTypesToLegacyTypes;
