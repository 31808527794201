
class ShareStateDefinitions {

  outstandingShareState = 'outstanding';
  acceptedShareState = 'accepted';
  rejectedShareState = 'rejected';
  typesToNames = [];

constructor() {
    this.typesToNames[this.outstandingShareState] = 'Outstanding';
    this.typesToNames[this.acceptedShareState] = 'Accepted';
    this.typesToNames[this.rejectedShareState] = 'Rejected';
  }

  // buildShareStateEnumItemsContext = (currentType) => {
  //   const outstandingShareStateItem: SelectItem = {
  //     label: this.typesToNames[this.outstandingShareState],
  //     value: this.outstandingShareState
  //   };
  //   const acceptedItem: SelectItem = {
  //     label: this.typesToNames[this.acceptedShareState],
  //     value: this.acceptedShareState
  //   };
  //   const rejectedItem: SelectItem = {
  //     label: this.typesToNames[this.rejectedShareState],
  //     value: this.rejectedShareState
  //   };
  //   const items: SelectItem[] = [
  //     outstandingShareStateItem,
  //     acceptedItem,
  //     rejectedItem];
  //   const selectItems: SelectItemsGroup[] = [{
  //     items: items
  //   }];
  //   let selectedItem: undefined | SelectItem = undefined;
  //   for (let i = 0; i < selectItems[0].items.length; i++) {
  //     const item = selectItems[0].items[i];
  //     if (item.value === currentType) {
  //       selectedItem = item;
  //     }
  //   }
  //   return {
  //     items: selectItems,
  //     selectedItem: selectedItem
  //   };
  // };

  getDefaultType = () => {
    return this.outstandingShareState;
  };

}

export default new ShareStateDefinitions();
