import adg from '../../../../commonbase/adg';
import ScoreMeta from './ScoreMeta';
import measurementTypes from './MeasurementTypes';
import scoreUtil from './ScoreUtil';

export default class BinaryScoreMeta extends ScoreMeta {

  getScoreType = () => {
    return measurementTypes.discreteType;
  };

  allowScoresBetweenMarkers = () => {
    return false;
  };

  roundInputScorePercent = (scorePercent) => {
    const rounded = 100 * Math.round(scorePercent / 100);
    return rounded;
  };

  scoreToPercent = (score) => {
    const scorePercent = score * 100;
    return scorePercent;
  };

  percentToScore = (scorePercent) => {
    const score = Math.round(scorePercent / 100);
    return score;
  };

  assessmentItemToColour = (assessmentItem) => {
    const scoreIsValid = scoreUtil.hasScore(assessmentItem);
    if (scoreIsValid) {
      const scorePercent = scoreUtil.assessmentItemToScore(assessmentItem);
      if (scorePercent) {
        if (scorePercent >= 50) {
          return adg.adgGreen;
        } else {
          return adg.adgRed;
        }
      } else {
        return adg.adgRed;
      }
    } else {
      return adg.adgGray;
    }
  };

}

