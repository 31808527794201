import React, { PureComponent } from 'react';
import actions from '../../../../shared/actions/Actions';
import adg from '../../../../commonbase/adg';
import PathBuilder from '../../svg/PathBuilder';
import ScoreSvg from '../score/ScoreSvg';
import scoreUtil from '../../../../shared/model/rubric/score/ScoreUtil';
import util from '../../../../shared/util/Util';
import widgetUtil from '../../../components/widget/WidgetUtil';

const twoPi = 2 * Math.PI;

export default class AspectPieChart extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = this.buildStateFromProps(props);
    this.setState(newState);
  }

  componentDidUpdate() {
    widgetUtil.scheduleTooltipRebuild();
  }

  buildStateFromProps = (props) => {
    const chartModel = props.chartModel;
    const data = chartModel.getCombinedAssessmentData(props.aspectGrouping);
    const segmentCount = data.segmentCount;
    const segmentAngle = twoPi / segmentCount;
    const scoreSvgRadius = 12;
    const centralRadius = 32;
    const minRadius = 100;
    const padding = 2;
    let radius = minRadius;
    if (segmentCount > 4) {
      // const radius = 100;
      /*
                r          |
          a                | y
      ---------------------|
      y = r * sin(a)
      r = y / sin(a)
      */
      const sinSegmentAngle = Math.sin(segmentAngle);
      const computedRadius = (3 * scoreSvgRadius) / sinSegmentAngle;
      const maxRadius = 400;
      radius = Math.min(maxRadius, Math.max(computedRadius, minRadius));
    }
    const centreX = padding + radius;
    const centreY = padding + radius;
    const barHeight = 32;
    const barPadding = 6;
    const chartWidth = 2 * radius;
    const chartHeight = 2 * radius;
    const xLeftIndent = padding;
    const xRightIndent = padding;
    const yTopIndent = padding;
    const yBottomIndent = padding;
    const svgWidth = chartWidth + xLeftIndent + xRightIndent;
    const svgHeight = chartHeight + yTopIndent + yBottomIndent;
    const state = {
      scoreMeta: props.scoreMeta,
      scoreSvgRadius: scoreSvgRadius,
      centralRadius: centralRadius,
      radius: radius,
      padding: padding,
      segmentAngle: segmentAngle,
      centreX: centreX,
      centreY: centreY,
      barHeight: barHeight,
      barPadding: barPadding,
      width: svgWidth,
      height: svgHeight,
      chartModel: chartModel
    };
    const renderContext = this.buildRenderContext(state);
    state.renderContext = renderContext;
    return state;
  };

  buildRenderContext = (state) => {
    const maxPossibleScore = 100;
    const segmentIndexToSegmentAngleStart = (segmentIndex, renderContext) => {
      return segmentIndex * this.state.segmentAngle;
    };
    const segmentIndexToSegmentAngleMiddle = (segmentIndex, renderContext) => {
      return segmentIndex * this.state.segmentAngle + this.state.segmentAngle / 2;
    };
    const segmentIndexToSegmentAngleEnd = (segmentIndex, renderContext) => {
      return (segmentIndex + 1) * this.state.segmentAngle;
    };
    const scoreToRadius = (score) => {
      const factor = score / maxPossibleScore;
      return state.centralRadius + factor * (state.radius - state.centralRadius);
    };
    const context = {
      valueLabelFontSize: 12,
      valueAxisLabelFontSize: 14,
      valueTickFontSize: 14,
      timeTickFontSize: 14,
      aspectBarFontSize: 14,
      fontFamily: 'Verdana',
      startXsToEffortPersonYOffsets: {},
      segmentIndexToSegmentAngleStart: segmentIndexToSegmentAngleStart,
      segmentIndexToSegmentAngleMiddle: segmentIndexToSegmentAngleMiddle,
      segmentIndexToSegmentAngleEnd: segmentIndexToSegmentAngleEnd,
      formatDate: util.formatDate,
      scoreToRadius: scoreToRadius
    };
    return context;
  };

  onSegmentClick = (segmentInfo) => {
    if (segmentInfo && segmentInfo.targetUuid) {
      actions.onScrollToElement(segmentInfo.targetUuid);
    }
  };

  renderAssessmentSegments = (segmentInfos, renderContext) => {
    const renderedSegments = [];
    const scoreSvgRadius = this.state.scoreSvgRadius;
    const scoreSvgDistanceFromCentre = this.state.radius - scoreSvgRadius - 4;
    for (let segmentIndex = 0; segmentIndex < segmentInfos.length; segmentIndex++) {
      const segmentInfo = segmentInfos[segmentIndex];
      const barAngleStart = renderContext.segmentIndexToSegmentAngleStart(segmentIndex, renderContext);
      const barAngleMiddle = renderContext.segmentIndexToSegmentAngleMiddle(segmentIndex, renderContext);
      const barAngleEnd = renderContext.segmentIndexToSegmentAngleEnd(segmentIndex, renderContext);
      const scorePercent = segmentInfo.score;
      const assessmentItem = scoreUtil.buildAssessmentItemFromScore(scorePercent);
      const scoreIsValid = scoreUtil.hasScore(assessmentItem);
      // const scoreIsValid = scorePercent !== undefined;
      let scoreRadius = 0;
      if (scoreIsValid) {
        scoreRadius = renderContext.scoreToRadius(scorePercent);
      } else {
        scoreRadius = renderContext.scoreToRadius(0);
      }
      // const scoreColor = this.state.scoreMeta.scorePercentToColour(scorePercent);
      const scoreColor = this.state.scoreMeta.assessmentItemToColour(assessmentItem);
      const cosBarAngleStart = Math.cos(barAngleStart);
      const sinBarAngleStart = Math.sin(barAngleStart);
      const cosBarAngleMiddle = Math.cos(barAngleMiddle);
      const sinBarAngleMiddle = Math.sin(barAngleMiddle);
      const cosBarAngleEnd = Math.cos(barAngleEnd);
      const sinBarAngleEnd = Math.sin(barAngleEnd);
      const frameX1 = this.state.centreX + this.state.radius * cosBarAngleStart;
      const frameY1 = this.state.centreY - this.state.radius * sinBarAngleStart;
      const frameX3 = this.state.centreX + this.state.radius * cosBarAngleEnd;
      const frameY3 = this.state.centreY - this.state.radius * sinBarAngleEnd;
      const scoreX1 = this.state.centreX + scoreRadius * cosBarAngleStart;
      const scoreY1 = this.state.centreY - scoreRadius * sinBarAngleStart;
      const scoreX3 = this.state.centreX + scoreRadius * cosBarAngleEnd;
      const scoreY3 = this.state.centreY - scoreRadius * sinBarAngleEnd;
      const midX = this.state.centreX + scoreSvgDistanceFromCentre * cosBarAngleMiddle;
      const midY = this.state.centreY - scoreSvgDistanceFromCentre * sinBarAngleMiddle;
      const framePath = new PathBuilder('frame-' + segmentInfo.uuid)
        .setPathWidth(1)
        .setPathColor(adg.adgGray)
        .moveTo(this.state.centreX, this.state.centreY)
        .lineTo(frameX1, frameY1)
        .arcTo(this.state.radius, this.state.radius, 0, 0, 0, frameX3, frameY3)
        .close()
        .setOnClick(() => {this.onSegmentClick(segmentInfo)})
        .setClassName('clickable')
        .setTooltip(segmentInfo.name)
        .render();
      const scorePath = new PathBuilder('segment-' + segmentInfo.uuid)
        .setPathWidth(0)
        .setPathColor(adg.adgGray)
        .setFill(scoreColor)
        .moveTo(this.state.centreX, this.state.centreY)
        .lineTo(scoreX1, scoreY1)
        .arcTo(scoreRadius, scoreRadius, 0, 0, 0, scoreX3, scoreY3)
        .close()
        .render();
      const renderedSegment = (
        <g key={'assessment-' + segmentIndex}>
          {scorePath}
          {framePath}
          <circle
            r={scoreSvgRadius}
            stroke="#fff"
            strokeWidth="4"
            cx={midX}
            cy={midY}
          />
          <ScoreSvg
            scoreMeta={this.state.scoreMeta}
            assessmentItem={assessmentItem}
            radius={scoreSvgRadius}
            centreX={midX}
            centreY={midY}
          />
        </g>
      );
      renderedSegments.push(renderedSegment);
    }
    return renderedSegments;
  };

  render() {
    const chartModel = this.state.chartModel;
    const data = chartModel.getCombinedAssessmentData();
    const renderContext = this.state.renderContext;
    renderContext.legendContext = {
      x: this.state.xLeftIndent + 10,
      lastY: this.state.yTopIndent + 10
    };
    const segmentInfos = data.segmentInfos;
    const overallScore = data.overallScore;
    const renderedSegments = this.renderAssessmentSegments(segmentInfos, renderContext);
    const assessmentItem = scoreUtil.buildAssessmentItemFromScore(overallScore);
    return (
      <svg width={this.state.width} height={this.state.height}>
        <circle
          r={this.state.radius}
          stroke={adg.veryLightGrey}
          fill={adg.veryLightGrey}
          strokeWidth="0"
          cx={this.state.centreX}
          cy={this.state.centreY}
        />
        {renderedSegments}
        <circle
          r={this.state.centralRadius}
          stroke={adg.veryLightGrey}
          strokeWidth="4"
          cx={this.state.centreX}
          cy={this.state.centreY}
        />
        <ScoreSvg
          scoreMeta={this.state.scoreMeta}
          assessmentItem={assessmentItem}
          radius={this.state.centralRadius}
          centreX={this.state.centreX}
          centreY={this.state.centreY}
        />
        <circle
          key="outer-circle"
          r={this.state.radius}
          stroke={adg.adgGray}
          fill={adg.none}
          strokeWidth="1"
          cx={this.state.centreX}
          cy={this.state.centreY}
        />
      </svg>
    );
  };

}