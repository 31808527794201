import ListenerGroup from '../../../shared/util/ListenerGroup';

class CurrentDialog {

  listenerGroup = new ListenerGroup('CurrentNotifications');
  state = {
    component: null
  };

  setDialogOptions = (dialogOptions) => {
    this.state.dialogOptions = dialogOptions;
    this._notifyListeners();
  };

  clearDialogOptions = () => {
    this.state.dialogOptions = null;
    this._notifyListeners();
  };

  getDialogOptions = () => {
    return this.state.dialogOptions;
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = () => {
    this.listenerGroup.notifyListeners(this.state.dialogOptions);
  };

}

export default new CurrentDialog();
