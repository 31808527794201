import React, { PureComponent, SyntheticEvent } from "react";
import adg from '../../../commonbase/adg';
import browserType from '../../../shared/model/browser/BrowserType';
import Spinner from '@atlaskit/spinner';
import ToolbarItem from './toolbar/ToolbarItem';
import ToolbarLeft from './toolbar/ToolbarLeft';

interface Props {
  label?: string
  invertColors?: boolean
  normalIcon?: any
  hoverIcon?: any
  invertIcons?: boolean
  normalColor?: string
  hoverColor?: string
  disabled?: boolean
  normalBackgroundColor?: string
  hoverBackgroundColor?: string
  paddingLeft?: string
  padding?: string
  borderRadius?: string
  onMouseDown?: Function
  onClick: Function
}

interface State {
  label?: null | string
  disabled?: boolean
  spinning?: boolean
  hovered?: boolean
  normalIcon?: any
  hoverIcon?: any
  normalColor?: string
  hoverColor?: string
  normalBackgroundColor?: string
  hoverBackgroundColor?: string
  borderRadius?: string
  paddingLeft?: string
  padding?: string
}

export default class IconButton extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.buildStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps = (props: Props): State => {
    const invertColors = props.invertColors;
    const normalIcon = props.normalIcon;
    const hoverIcon = props.hoverIcon ? props.hoverIcon : props.normalIcon;
    const normalColor = props.normalColor ? props.normalColor : '#333';
    const hoverColor = props.hoverColor ? props.hoverColor : adg.adgBlue;
    return {
      hovered: false,
      disabled: !!props.disabled,
      label: props.label === undefined ? null : props.label,
      normalIcon: props.invertIcons ? hoverIcon : normalIcon,
      hoverIcon: props.invertIcons ? normalIcon : hoverIcon,
      normalColor: invertColors ? hoverColor : normalColor,
      hoverColor: invertColors ? normalColor : hoverColor,
      normalBackgroundColor: props.normalBackgroundColor ? props.normalBackgroundColor : undefined,
      hoverBackgroundColor: props.hoverBackgroundColor === undefined ? '#f2f2f2' : props.hoverBackgroundColor,
      padding: props.padding === undefined ? undefined : props.padding,
      paddingLeft: this.props.paddingLeft === undefined ? undefined : this.props.paddingLeft,
      borderRadius: props.borderRadius === undefined ? '4px' : props.borderRadius
    };
  };

  onEnter = () => {
    this.setState({
      hovered: true
    });
  };

  onLeave = () => {
    this.setState({
      hovered: false
    });
  };

  onMouseDown = (event: SyntheticEvent) => {
    if (this.props.onMouseDown) {
      this.props.onMouseDown(event);
    }
  };

  onClick = (event: SyntheticEvent) => {
    if (this.state.disabled) {
      return;
    }
    const possiblePromise = this.props.onClick(event);
    if (possiblePromise instanceof Promise) {
      this.setState({
        spinning: true
      });
      possiblePromise
        .finally(() => {
          this.setState({
            spinning: false
          });
        });
    }
  };

  renderButton = () => {
    const icon = this.state.hovered ? this.state.hoverIcon : this.state.normalIcon;
    const color = this.state.hovered ? this.state.hoverColor : this.state.normalColor;
    const backgroundColor = this.state.hovered ? this.state.hoverBackgroundColor : this.state.normalBackgroundColor;
    const style: any = {
      fontWeight: 'bold',
      color: color,
      backgroundColor: backgroundColor,
      borderRadius: this.state.borderRadius
    };
    if (!this.state.disabled) {
      style.cursor = 'pointer';
    }
    if (this.state.padding) {
      style.padding = this.state.padding;
    } else {
      style.paddingTop = '4px';
    }
    if (this.state.paddingLeft) {
      style.paddingLeft = this.state.paddingLeft;
    }
    const classes = this.state.disabled ? undefined : 'clickable';
    return (
      <ToolbarLeft
        className={classes}
        style={style}
        onMouseEnter={browserType.isMobile() ? undefined : () => this.onEnter()}
        onMouseLeave={browserType.isMobile() ? undefined : () => this.onLeave()}
        onMouseDown={this.onMouseDown}
        onClick={this.onClick}
      >
        <ToolbarItem>
          {icon}
        </ToolbarItem>
        {this.state.label ? (
          <ToolbarItem>
            {this.state.label}
          </ToolbarItem>
        ) : null}
      </ToolbarLeft>
    );
  };

  renderSpinner = () => {
    return (
      <Spinner size="xsmall" />
    );
  };

  render() {
    if (this.state.spinning) {
      return this.renderSpinner();
    } else {
      return this.renderButton();
    }
  }

}
