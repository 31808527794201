
import React, { PureComponent } from "react";
import adg from '../../../commonbase/adg';
import { InfoIcon } from '../../components/icon/NamedIcons'
import ToolbarLeft from './toolbar/ToolbarLeft';
import ToolbarItem from './toolbar/ToolbarItem';

export default class TextPanel extends PureComponent {

  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <ToolbarLeft
        style={{
          alignItems: 'flex-start',
          marginTop: '20px',
          marginLeft: '-10px !important',
          padding: '10px',
          borderRadius: '6px',
          color: '#333',
          backgroundColor: adg.B50
        }}
      >
        <ToolbarItem
          style={{
            width: '24px',
            marginLeft: '0px !important',
            marginRight: '0px !important',
            flexShrink: 1,
          }}>
          <InfoIcon primaryColor={adg.B300}/>
        </ToolbarItem>
        <ToolbarItem
          style={{
            flexShrink: 10,
          }}
        >
          <span>
            {this.props.children}
          </span>
        </ToolbarItem>
      </ToolbarLeft>
    );
  }

}
