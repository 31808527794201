import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import LiftedPageTitle from '../components/widget/LiftedPageTitle';
import DriveView from '../components/drive/DriveView';
import rubricPageHelper from './RubricPageHelper';
import InfoMessageBanner from '../components/widget/banner/InfoMessageBanner';

export default class DrivePage extends PureComponent {

  componentDidMount() {
    rubricPageHelper.updateTitle('Drive');
  }

  render() {
    return (
      <ContentWrapper>
        <LiftedPageTitle>Drive</LiftedPageTitle>
        <InfoMessageBanner>
          Use your drive to organise rubrics into folders so you can easily find them.
        </InfoMessageBanner>
        <DriveView
          showHeading={false}
          showEmptyFoldersMessage={true}
          showEditTools={true}
        />
      </ContentWrapper>
    );
  }

}
