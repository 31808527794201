// import userConstants from "./UserConstants";
// import EconomicsEssayBuilder from "../../../shared/model/rubric/templates/EconomicsEssayBuilder";
// import RubricDefinitionBuilder from "../../../shared/model/rubric/RubricDefinitionBuilder";
// import BiologyResearchBuilder from "../../../shared/model/rubric/templates/BiologyResearchBuilder";
// import OralPresentationBuilder from "../../../shared/model/rubric/templates/OralPresentationBuilder";
// import BookReviewBuilder from "../../../shared/model/rubric/templates/BookReviewBuilder";
// import CreditCardComparisonBuilder from "../../../shared/model/rubric/templates/CreditCardComparisonBuilder";
// import discoverabilityDefinitions from "../../../shared/model/rubric/DiscoverabilityDefinitions";
// import PascalPLevelsBuilder from '../../../shared/model/rubric/templates/PascalPLevelsBuilder';
import rubricUtil from "../../../shared/model/rubric/RubricUtil";
import rubricDAO from "../RubricDAO";
import session from '../../../shared/model/auth/Session';
// import X7Builder from '../../../shared/model/rubric/templates/X7Builder';

export default class SystemDataCreator {

  create = () => {
    return new Promise((resolve, reject) => {
      // Disabling due to bug in not being logged in
      // this._savePrebuiltDefinitions();
      // resolve();

      this._create(resolve, reject);
    });
  };

  _create = (resolve, reject) => {
    const user = session.getCurrentUser();
    if (user) {
      this._savePrebuiltDefinitions();
      resolve();
    } else {
      setTimeout(() => {
        this._create(resolve, reject);
      }, 1000);
    }
  };

  _savePrebuiltDefinitions = () => {
    this._buildData();
    const promises = [];
    const definitionUuids = Object.keys(this.uuidsToDefinitions);
    // alert('Saving: ' + uuids);
    for (let i = 0; i < definitionUuids.length; i++) {
      const definitionUuid = definitionUuids[i];
      const getDefinitionPromise = rubricDAO.getDefinitionByUuid(definitionUuid).then((definition) => {
        if (definition) {
          // do nothing
        } else {
          const prebuiltDefinition = this.uuidsToDefinitions[definitionUuid];
          rubricUtil.denormaliseDefinition(prebuiltDefinition);
          // Call _saveDefinition to circumvent the permission system...
          const saveDefinitionPromise = rubricDAO._saveDefinition(prebuiltDefinition);
          promises.push(saveDefinitionPromise);
        }
      });
      promises.push(getDefinitionPromise);
    }

    const assessmentUuids = Object.keys(this.uuidsToAssessments);
    for (let i = 0; i < assessmentUuids.length; i++) {
      const assessmentUuid = assessmentUuids[i];
      const getAssessmentPromise = rubricDAO.getAssessmentByUuid(assessmentUuid).then((assessment) => {
        if (assessment) {
          // do nothing
        } else {
          const prebuiltAssessment = this.uuidsToAssessments[assessmentUuid];
          // rubricUtil.denormaliseDefinition(prebuiltAssessment);
          // Call _saveAssessment to circumvent the permission system...
          const saveAssessmentPromise = rubricDAO._saveAssessment(prebuiltAssessment);
          promises.push(saveAssessmentPromise);
        }
      });
      promises.push(getAssessmentPromise);
    }
    return Promise.all(promises);
  };

  _setStatementAssessment = (score, columnIndex, rowIndex) => {

  };

  _buildData = () => {
    this.uuidsToDefinitions = {};
    this.uuidsToAssessments = {};
    // const myPublicRubricFoo = new RubricDefinitionBuilder()
    //   .setUuid('basic-public-rubric-foo')
    //   .setName('Foo\'s basic public rubric')
    //   .setDescription(`This rubric is owned by the user 'Foo'. It's discoverability is set to 'public' so it should show up in search results of other users.`)
    //   .setOwner(userConstants.fooUser)
    //   .setDiscoverability(discoverabilityDefinitions.publicDiscoverabilityType)
    //   .build();
    //
    // const myLinkRubricFoo = new RubricDefinitionBuilder()
    //   .setUuid('basic-link-rubric-foo')
    //   .setName('Foo\'s basic link rubric')
    //   .setDescription(`This rubric is owned by the user 'Foo'. It's discoverability is set to 'link' so it shouldn't show up in search results of other users.`)
    //   .setOwner(userConstants.fooUser)
    //   .setDiscoverability(discoverabilityDefinitions.linkDiscoverabilityType)
    //   .build();
    //
    // const myPrivateRubricFoo = new RubricDefinitionBuilder()
    //   .setUuid('basic-private-rubric-foo')
    //   .setName('Foo\'s basic private rubric')
    //   .setDescription(`This rubric is owned by the user 'Foo'. It's discoverability is set to 'private' so it shouldn't show up in search results of other users.`)
    //   .setOwner(userConstants.fooUser)
    //   .setDiscoverability(discoverabilityDefinitions.privateDiscoverabilityType)
    //   .build();
    //
    // const myPublicRubricBar = new RubricDefinitionBuilder()
    //   .setUuid('basic-public-rubric-bar')
    //   .setName('Bar\'s basic public rubric')
    //   .setDescription(`This rubric is owned by the user 'Bar'. It is set as not forkable.  It's discoverability is set to 'public' so it should show up in search results of other users.`)
    //   .setOwner(userConstants.barUser)
    //   .setAllowForking(false)
    //   .setDiscoverability(discoverabilityDefinitions.publicDiscoverabilityType)
    //   .build();
    //
    // const myLinkRubricBar = new RubricDefinitionBuilder()
    //   .setUuid('basic-link-rubric-bar')
    //   .setName('Bar\'s basic link rubric')
    //   .setDescription(`This rubric is owned by the user 'Bar'. It's discoverability is set to 'link' so it shouldn't show up in search results of other users.`)
    //   .setOwner(userConstants.barUser)
    //   .setDiscoverability(discoverabilityDefinitions.linkDiscoverabilityType)
    //   .build();
    //
    // const myPrivateRubricBar = new RubricDefinitionBuilder()
    //   .setUuid('basic-private-rubric-bar')
    //   .setName('Bar\'s basic private rubric')
    //   .setDescription(`This rubric is owned by the user 'Bar'. It's discoverability is set to 'private' so it shouldn't show up in search results of other users.`)
    //   .setOwner(userConstants.barUser)
    //   .setDiscoverability(discoverabilityDefinitions.privateDiscoverabilityType)
    //   .build();
    //
    // const demo = new RubricDefinitionBuilder()
    //   .setUuid('demo')
    //   .setName('Demo')
    //   .setDescription(`This rubric demonstrated the ability to apply styles.`)
    //   .setOwner(userConstants.fooUser)
    //   .setColumnNames(['Bad', 'OK', 'Good'])
    //   .setRowCount(4)
    //   .setDiscoverability(discoverabilityDefinitions.publicDiscoverabilityType)
    //   .setBorderColor('#BF2600')
    //   .setHoverColor('#fff5fd')
    //   .build();
    //
    // const creditCardComparisonWithScore = new CreditCardComparisonBuilder()
    //   .setUuid('creditCardComparison')
    //   .buildWithAssessment();
    // const creditCardComparison = creditCardComparisonWithScore.definition;
    // const creditCardComparisonAssessments = creditCardComparisonWithScore.assessments;
    //
    // const bookReviewWithScore = new BookReviewBuilder()
    //   .setUuid('bookReview')
    //   .buildWithAssessment();
    // const bookReview = bookReviewWithScore.definition;
    // const bookReviewAssessments = bookReviewWithScore.assessments;
    //
    // const oralPresentation = new OralPresentationBuilder()
    //   .setUuid('OralPresentation')
    //   .build();
    //
    // const economicsEssayBuilder = new EconomicsEssayBuilder()
    //   .setUuid('economics-essay')
    //   .build();
    //
    // const biologyResearchBuilder = new BiologyResearchBuilder()
    //   .setUuid('biology-research')
    //   .build();

    // const pascalPTrack = new PascalPLevelsBuilder()
    //   .setUuid('pascal-p-xxxx')
    //   .build();

    // const x7 = new X7Builder()
    //   .setUuid('x7')
    //   .build();

    // this.uuidsToDefinitions[myPublicRubricFoo.uuid] = myPublicRubricFoo;
    // this.uuidsToDefinitions[myLinkRubricFoo.uuid] = myLinkRubricFoo;
    // this.uuidsToDefinitions[myPrivateRubricFoo.uuid] = myPrivateRubricFoo;
    //
    // this.uuidsToDefinitions[myPublicRubricBar.uuid] = myPublicRubricBar;
    // this.uuidsToDefinitions[myLinkRubricBar.uuid] = myLinkRubricBar;
    // this.uuidsToDefinitions[myPrivateRubricBar.uuid] = myPrivateRubricBar;
    //
    // this.uuidsToDefinitions[demo.uuid] = demo;
    // this.uuidsToDefinitions[creditCardComparison.uuid] = creditCardComparison;
    // for (let i = 0; i < creditCardComparisonAssessments.length; i++) {
    //   const creditCardComparisonAssessment = creditCardComparisonAssessments[i];
    //   this.uuidsToAssessments[creditCardComparisonAssessment.uuid] = creditCardComparisonAssessment;
    // }
    // this.uuidsToDefinitions[bookReview.uuid] = bookReview;
    // for (let i = 0; i < bookReviewAssessments.length; i++) {
    //   const bookReviewAssessment = bookReviewAssessments[i];
    //   this.uuidsToAssessments[bookReviewAssessment.uuid] = bookReviewAssessment;
    // }
    // this.uuidsToDefinitions[oralPresentation.uuid] = oralPresentation;
    // this.uuidsToDefinitions[economicsEssayBuilder.uuid] = economicsEssayBuilder;
    // this.uuidsToDefinitions[biologyResearchBuilder.uuid] = biologyResearchBuilder;
    // this.uuidsToDefinitions[pascalPTrack.uuid] = pascalPTrack;
    // this.uuidsToDefinitions[x7.uuid] = x7;
    // this.uuidsToDefinitions[xxxxxxx.uuid] = xxxxxxx;
  }

}
